import React from 'react';

import { Field, Text } from 'components/service';
import { RESTAURANT_ORDER_PICKUP_TYPE } from 'constants/index';
import { RadioList } from 'components/form/elements';
import {
  PICKUP_OPTION,
  PICKUP_FOOT_NOTE,
  CAR_PICKUP_OPTION,
  CAR_PICKUP_FOOT_NOTE,
  BOTH_OPTION,
  BOTH_FOOT_NOTE,
} from 'constants/translations';

const PickupTypeRadioList = () => {
  const noteClassName = 'text-xs text-gray-700 mx-6 px-1';
  return (
    <div className="pb-2">
      <Field
        type="text"
        name="pickupOrdersType"
        component={RadioList}
        testId="pickup-type"
        items={[
          {
            title: <Text key="pickup" value={PICKUP_OPTION} />,
            value: RESTAURANT_ORDER_PICKUP_TYPE.STORE_PICKUP,
            note: <Text value={PICKUP_FOOT_NOTE} className={noteClassName} />,
          },
          {
            title: <Text key="carPickup" value={CAR_PICKUP_OPTION} />,
            value: RESTAURANT_ORDER_PICKUP_TYPE.CAR_PICKUP,
            note: <Text value={CAR_PICKUP_FOOT_NOTE} className={noteClassName} />,
          },
          {
            title: <Text key="both" value={BOTH_OPTION} />,
            value: RESTAURANT_ORDER_PICKUP_TYPE.BOTH,
            note: <Text value={BOTH_FOOT_NOTE} className={noteClassName} />,
          },
        ]}
      />
    </div>
  );
};

export default PickupTypeRadioList;
