export const ORDER_RIDER_STATUS = {
  SUBMITTED: 'submitted',
  PENDING: 'pending',
  ASSIGNED: 'assigned',
  DECLINED: 'declined',
  PUSHED: 'pushed',
  ACCEPTED: 'accepted',
  CANCELED: 'canceled',
  DISPATCHED: 'dispatched',
  DELIVERED: 'delivered',
};
