import { gql } from '@apollo/client';

export const GET_LAYOUT = gql`
  query getSettings($storeId: String!) {
    settings(restaurantId: $storeId) {
      id
      restaurantId
      theme {
        palette {
          headerBackgroundColor
          primaryColor
        }
      }
      storeUi {
        menuSectionLayout
        menuItemsLayout
        fontShape
        logoRounded
        buttonRounded
      }
    }
  }
`;
export const UPDATE_LAYOUT = gql`
  mutation(
    $storeId: String!
    $menuItemsLayout: Int
    $menuSectionLayout: Int
    $primaryColor: String
    $fontShape: String
    $logoRounded: Boolean
    $buttonRounded: Boolean
  ) {
    updateSettings(
      restaurantId: $storeId
      menuItemsLayout: $menuItemsLayout
      menuSectionLayout: $menuSectionLayout
      primaryColor: $primaryColor
      fontShape: $fontShape
      logoRounded: $logoRounded
      buttonRounded: $buttonRounded
    ) {
      id
      restaurantId
      storeUi {
        menuSectionLayout
        menuItemsLayout
        fontShape
        logoRounded
        buttonRounded
      }
      theme {
        palette {
          headerBackgroundColor
          primaryColor
        }
      }
    }
  }
`;

export const UPDATE_RESTAURANT = gql`
  mutation updateRestaurant($id: String!, $preferredLanguage: String) {
    updateRestaurant(id: $id, preferredLanguage: $preferredLanguage) {
      id
      preferredLanguage
    }
  }
`;

export const GET_RESTAURANT = gql`
  query($storeId: String!) {
    restaurant(id: $storeId) {
      defaultProductPrepTime
      preferredLanguage
    }
  }
`;
