export const ORDER_STATUS = {
  ALL: 'all',
  PENDING: 'pending',
  SUBMITTED: 'submitted',
  ASSIGNED: 'assigned',
  ACCEPTED: 'accepted',
  READY: 'ready',
  DECLINED: 'declined',
  DISPATCHED: 'dispatched',
  DELIVERED: 'delivered',
  CANCELED: 'canceled',
  FULFILLED: 'fulfilled',
  WAITING_FOR_PAYMENT: 'waiting_for_payment',
  PAYMENT_FAILED: 'payment_failed',
  PAYMENT_EXPIRED: 'payment_expired',
  REFUND_PENDING: 'refund_pending',
  REFUND_FAILED: 'refund_failed',
  PAID: 'paid',
  REDIRECT_URL: 'redirect_url',
  IFRAME_URL: 'iframe_url',
  COMPLETED: 'completed',
  PUSHED: 'pushed',
  PUSH_FAILED: 'push_failed',
  MANUALLY_ORDERED: 'manually_ordered',
};
