import React, { useContext } from "react"
import cx from "classnames"
import { Error } from "components/form/generic"
import { context as localeContext } from "context/locale"

export default ({ items, onChange, value, name, size = "base", error }) => {
  const { lang, direction } = useContext(localeContext)
  return (
    <div>
      <div className="flex flex-wrap items-center">
        {items.map((item, i) => {
          const isSelected = item.value === value

          return (
            <label
              key={i}
              className={cx(
                "mt-2 inline-flex items-center cursor-pointer border hover:text-primary-base",
                sizes[size],
                lang === "en" ? "mr-3" : "ml-3",
                isSelected ? "border-primary-base" : "border-gray-300"
              )}
              onClick={() =>
                onChange({
                  target: {
                    name,
                    value: item.value,
                  },
                })
              }
            >
              <div
                className={cx(
                  "w-3 h-3 flex-shrink-0 rounded-full mr-2 border-2 flex items-center justify-center",
                  lang === "en" ? "mr-2" : "ml-2",
                  isSelected
                    ? "bg-primary-base border-transparent"
                    : "border-gray-200"
                )}
              >
                {isSelected && (
                  <div className="w-1 h-1 rounded-full bg-white"></div>
                )}
              </div>
              {item.title}
            </label>
          )
        })}
      </div>
      {error && <Error direction={direction}>{error}</Error>}
    </div>
  )
}

const sizes = {
  xs: "py-1 px-2 text-xs",
  sm: "py-1 px-3 text-sm",
  base: "py-2 px-4 text-sm",
  mobile: "py-2 px-4 text-sm w-full"
}
