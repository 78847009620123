const convertMultiLanguagesFromObjectToArray = multiLangObject => [multiLangObject.en, multiLangObject.ar];

export const copyToClipboard = value => {
  const textArea = document.createElement('textarea');
  textArea.value = value;
  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand('Copy');
  textArea.remove();
};

export default convertMultiLanguagesFromObjectToArray;
