import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { context as userContext } from 'context/user';
import { Provider } from './context';
import Deliverect from './Deliverect';

const DeliverectContainer = ({ courier, changeSettings, closeable, onCancel, defaultStep }) => {
  const { selectedStore, pos } = useContext(userContext);

  useEffect(() => {
    closeable(false);
  }, []);

  return (
    <Provider defaultValues={{ storeId: selectedStore.id, defaultTaxType: pos?.isTaxInclusive }}>
      <Deliverect
        courier={courier}
        changeSettings={changeSettings}
        closeable={closeable}
        onCancel={onCancel}
        defaultStep={defaultStep}
      />
    </Provider>
  );
};

DeliverectContainer.propTypes = {
  courier: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    authenticationType: PropTypes.string.isRequired,
    integrationData: PropTypes.shape({
      courierBasicInfo: PropTypes.shape({
        title: PropTypes.shape({
          en: PropTypes.string.isRequired,
          ar: PropTypes.string.isRequired,
        }).isRequired,
        description: PropTypes.shape({
          en: PropTypes.string.isRequired,
          ar: PropTypes.string.isRequired,
        }).isRequired,
      }),
      integrationsConstraints: PropTypes.arrayOf(PropTypes.string),
      csOnly: PropTypes.bool,
      courierIntegrationInfo: PropTypes.arrayOf(
        PropTypes.shape({
          header: PropTypes.shape({
            ar: PropTypes.string.isRequired,
            en: PropTypes.string.isRequired,
          }),
          body: PropTypes.shape({
            ar: PropTypes.string.isRequired,
            en: PropTypes.string.isRequired,
          }),
        }),
      ),
      learnMore: PropTypes.shape({
        ar: PropTypes.string.isRequired,
        en: PropTypes.string.isRequired,
      }),
      courierDynamicSection: PropTypes.arrayOf(
        PropTypes.shape({
          body: PropTypes.shape({
            template: PropTypes.string,
          }),
        }),
      ),
    }),
  }),
  changeSettings: PropTypes.func.isRequired,
  closeable: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  defaultStep: PropTypes.number.isRequired,
};

export default DeliverectContainer;
