// @ts-nocheck
import React, { useContext } from 'react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';

import { Label, Row } from 'components/form/generic';
import { context as localeContext } from 'context/locale';
import { Field, Text } from 'components/service';
import { Input } from 'components/form/elements';
import { context } from '../context';

const SecondStep = ({ courierDynamicSection, setOcimsValues }) => {
  const { lang } = useContext(localeContext);
  const { ocimsValues } = useContext(context);
  const handleSubmitValue = (key, value) => {
    setOcimsValues(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };
  return (
    <div>
      {courierDynamicSection?.length &&
        courierDynamicSection.map(courierSection => {
          const {
            title,
            inputField: { backendName, hintAr, hintEn, type },
          } = courierSection;

          const initialValues = { [backendName]: ocimsValues[backendName] };
          return (
            <Formik initialValues={initialValues}>
              <Form>
                <Row className="py-2">
                  <Label title={<Text value={[title.en, title.ar]} />}>
                    <Field
                      name={`${backendName}`}
                      type={type}
                      component={Input}
                      placeholder={lang === 'en' ? hintEn : hintAr}
                      onChange={e => handleSubmitValue(backendName, e.target.value)}
                    />
                  </Label>
                </Row>
              </Form>
            </Formik>
          );
        })}
    </div>
  );
};

SecondStep.propTypes = {
  courierDynamicSection: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.shape({
        ar: PropTypes.string.isRequired,
        en: PropTypes.string.isRequired,
      }),
      inputField: PropTypes.shape({
        backendName: PropTypes.string.isRequired,
        hintAr: PropTypes.string.isRequired,
        hintEn: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
      }),
    }),
  ),
  setOcimsValues: PropTypes.func.isRequired,
};
export default SecondStep;
