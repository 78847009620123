import React from "react"
import { navigate } from "@reach/router"
import { useMutation } from "@apollo/client"
import { replaceParams } from "utils/index"
import { ReactComponent as StockAi } from "assets/emptyStock.svg"
import { Button, CreationBox } from "components/kit"
import { Text } from "components/service"
import * as translations from "constants/translations"
import * as paths from "paths.js"
import * as schemas from "./schemas"
import { useSelectedStore } from "hooks/index"

export default ({}) => {
  const storeId = useSelectedStore()

  const [enableStock, { loading: isStillUpdating }] = useMutation(
    schemas.ENABLE_STOCK
  )

  return (
    <div className="flex flex-col mx-auto max-w-sm items-center ">
      <StockAi className="w-full" />
      <CreationBox
        title={<Text value={translations.ENABLE_STOCK_TITLE} />}
        description={<Text value={translations.ENABLE_STOCK_DESC} />}
        actions={
          <Button
            onClick={async data => {
              let variables = { storeId: storeId, isStockEnabled: true }
              await enableStock({ variables: variables }).then(res => {
                navigate(
                  replaceParams(paths.stockManagement, {
                    storeId: storeId,
                  })
                )
              })
            }}
            kind="primary"
          >
            <Text value={translations.ENABLE_STOCK_BTN} />
          </Button>
        }
      />
    </div>
  )
}
