import React, { useContext, useState, useEffect, useRef } from "react"
import { useMutation, useQuery } from "@apollo/client"
import { Formik, Form } from "formik"
import * as yup from "yup"
import cx from "classnames"
import { useSelectedStore } from "hooks"
import { Field, Text } from "components/service"
import {
  Button,
  SpinnerAlt,
  Spinner,
  More,
  ModalConfirmBoxBody,
  Uploader,
} from "components/kit"
import { Label, Container, Row, Footer } from "components/form/generic"
import { Input, MultiCheckbox, UploadDnD } from "components/form/elements"
import { ADD_CATEGORY, UPDATE_CATEGORY, SINGLE_CATEGORY } from "./schemas"
import * as translations from "constants/translations"
import { context as localeContext } from "context/locale"
import { context as userContext } from "context/user"
import { context as modal } from "context/modal"
import { context as notificationsContext } from "context/notifications"
import * as utils from "./utils"
import { uploadCategoryImage, deleteCategoryImage } from "rest.js"
import DeleteCategory from "./DeleteCategoryModal"
import { useMobile } from "hooks/index"

const CategoryForm = ({
  reset: resetState,
  id: categoryId,
  open: openModal,
  onCancel,
  close,
  emptyState = false,
  isMenu = false,
  topPanel,
  handleConcatenatingCategories,
  handleUpdatingCategories,
  handleDeletingCategories,
  title = !categoryId ? (
    <Text value={translations.ADD_CATEGORY} />
  ) : (
    <Text value={translations.EDIT_CATEGORY} />
  ),
}) => {
  const storeId = useSelectedStore()
  const { data, loading: loadingCategory } = useQuery(SINGLE_CATEGORY, {
    variables: {
      storeId,
      menuSectionId: categoryId,
    },
    fetchPolicy: "cache-and-network",
  })

  const [menuSectionData, setMenuSectionData] = useState(null)
  const [initialImage, setInitialImage] = useState(null)
  const [initialValues, setInitialValues] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [selectedCatId, setSelectedCatId] = useState(categoryId)
  const { lang } = useContext(localeContext)
  const { branches } = useContext(userContext)
  const notifications = useContext(notificationsContext)
  const { setDivRef } = useContext(modal)
  const isMobile = useMobile()
  const ref = useRef()
  useEffect(() => {
    setDivRef(ref)
  }, [])

  useEffect(() => {
    if (!categoryId) {
      setInitialValues(null);
    }
  }, [categoryId]);

  useEffect(() => {
    if (data) {
      setMenuSectionData(data.menuSection)
      setInitialValues(data.menuSection)
    }
    setInitialImage(data ? data.menuSection.photoUrl : null)
    setIsLoading(loadingCategory)
  }, [data, categoryId, loadingCategory, initialValues])

  const direction = lang === "ar" ? "rtl" : "ltr"

  const handleErrorMessage = utils.handleErrors(msg =>
    notifications.show(msg, "error")
  )

  const [addCategory, { loading: addCategoryLoading }] = useMutation(
    ADD_CATEGORY,
    {
      onError: handleErrorMessage,
      onCompleted: res => {
        let newItemMap = new Map()
        let newCategory = [
          {
            id: res?.createMenuSection?.id,
            titleEn: res?.createMenuSection?.titleEn,
            titleAr: res?.createMenuSection?.titleAr,
            position: 0,
          },
        ]
        newItemMap.set(res.createMenuSection.id, [])
        handleConcatenatingCategories(newItemMap, newCategory)
        if (isMobile || emptyState) {
          onCancel()
        }
        resetState && resetState()
        notifications.show(
          <Text
            value={translations.CATEGORY_CREATED}
            payload={
              lang === "en"
                ? res?.createMenuSection?.titleEn
                : res?.createMenuSection?.titleAr
            }
          />
        )
        if (emptyState) {
          window.location.reload()
        }
      },
    }
  )
  const [updateCategory, { loading: updateCategoryLoading }] = useMutation(
    UPDATE_CATEGORY,
    {
      onError: handleErrorMessage,
      onCompleted: res => {
        let updatedCategory = {
          id: res?.updateMenuSection?.id,
          titleEn: res?.updateMenuSection?.titleEn,
          titleAr: res?.updateMenuSection?.titleAr,
        }

        handleUpdatingCategories(updatedCategory)
        if (isMobile) {
          onCancel()
        }
        resetState && resetState()
        notifications.show(
          <Text
            value={translations.CATEGORY_UPDATED}
            payload={
              lang === "en"
                ? res?.updateMenuSection?.titleEn
                : res?.updateMenuSection?.titleAr
            }
          />
        )
      },
    }
  )

  const branchesData = branches.map(branch => ({
    id: branch.id,
    title: <Text value={branch} />,
  }))

  const validationSchema = yup.object().shape({
    titleEn: yup
      .string()
      .required(<Text value={translations.TITLE_EN_IS_REQ} />),
    titleAr: yup
      .string()
      .required(<Text value={translations.TITLE_AR_IS_REQ} />),
  })

  const onSubmit = async values => {
    if (selectedCatId) {
      await updateCategory({
        variables: {
          storeId,
          id: selectedCatId,
          ...values,
        },
      }).then(async res => {
        if (values.image) {
          await uploadCategoryImage(storeId, selectedCatId, values.image)
        } else if (menuSectionData?.photoUrl && !initialImage) {
          await deleteCategoryImage(storeId, selectedCatId)
        }
      })
    } else {
      await addCategory({
        variables: {
          storeId,
          position: 0,
          ...values,
        },
      }).then(async res => {
        let resId = res.data.createMenuSection.id
        setSelectedCatId(resId)
        if (values.image) {
          await uploadCategoryImage(storeId, resId, values.image)
        } else if (menuSectionData?.photoUrl && !initialImage) {
          await deleteCategoryImage(storeId, resId)
        }
      })
    }
    resetState && resetState()
  }
  const container = useRef(null)
  return (
    <div className="w-full" ref={container}>
      <div
        className={cx(isMenu && topPanel && "fixed")}
        style={{
          width: container.current ? container.current.offsetWidth : 0,
        }}
      >
        {isLoading ? (
          <div
            className="mt-3"
            style={{
              width: container.current ? container.current.offsetWidth : 0,
            }}
          >
            <Spinner />
          </div>
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            onCancel={isMobile ? onCancel : resetState}
          >
            {({ values, setFieldValue, ...rest }) => {
              return (
                <Form style={{ direction }}>
                  {!isMobile && !emptyState && (
                    <div className="flex items-center justify-between px-4 mb-4 pt-4 py-4 border-b border-gray-300">
                      <div className="flex">
                        <div
                          className={cx(lang == "ar" ? "ml-2" : "mr-2")}
                          onClick={e => {
                            e.preventDefault()
                            openModal({
                              testId: "reset-side-panel",
                              title: (
                                <Text
                                  value={translations.CONFIRM_CANCELLATION}
                                  className="text-xl"
                                />
                              ),
                              body: (
                                <ModalConfirmBoxBody
                                  areYouSureText={translations.DISCLAMER}
                                  onClick={() => {
                                    resetState()
                                    close()
                                  }}
                                  onCancel={close}
                                />
                              ),
                            })
                          }}
                        >
                          <i className="material-icons flex items-center h-full w-full justify-center text-gray-700 font-bold cursor-pointer">
                            close
                          </i>
                        </div>
                        <div className="font-semibold">
                          <span
                            className="block w-full text-lg"
                            style={{ direction }}
                          >
                            {title}
                          </span>
                        </div>
                      </div>
                      <div className="flex">
                        {categoryId ? (
                          <>
                            <Button
                              data-testid="edit-save-category-btn"
                              kind="secondary"
                            >
                              {updateCategoryLoading && (
                                <span className="mr-2">
                                  <SpinnerAlt color="primary-50" />
                                </span>
                              )}
                              <Text value={translations.SAVE} />
                            </Button>
                            <div className="ml-2">
                              <More
                                testIdBtn="list-products-more-btn"
                                size={"option"}
                                iconSize="2xl"
                                items={[
                                  {
                                    testId: "delete-category-item",
                                    title: (
                                      <Text
                                        value={translations.DELET_CATEGORY}
                                      />
                                    ),
                                    onClick: () => {
                                      openModal({
                                        testId: "delete-category-modal",
                                        size: "max-w-md",
                                        title: (
                                          <Text
                                            value={translations.DELET_CATEGORY}
                                            className="text-xl"
                                          />
                                        ),
                                        body: (
                                          <DeleteCategory
                                            titleEn={values.titleEn}
                                            titleAr={values.titleAr}
                                            storeId={storeId}
                                            catId={menuSectionData.id}
                                            resetState={resetState}
                                            onCancel={close}
                                            handleDeletingCategories={
                                              handleDeletingCategories
                                            }
                                          />
                                        ),
                                      })
                                    },
                                  },
                                ]}
                              />
                            </div>
                          </>
                        ) : (
                          <Button
                            data-testid="add-category-btn"
                            kind="secondary"
                          >
                            {addCategoryLoading && (
                              <span className="mr-2">
                                <SpinnerAlt color="primary-50" />
                              </span>
                            )}
                            <Text value={translations.ADD} />
                          </Button>
                        )}
                      </div>
                    </div>
                  )}
                  <div
                    ref={ref}
                    className="px-4 my-4 w-full overflow-y-scroll mb-8 h-600 lg:h-800 lg:pb-lg"
                  >
                    <Container>
                      <Text
                        value={translations.TITLE}
                        className={cx(
                          lang === "en" ? "text-left" : "text-right",
                          "-mb-4 font-medium"
                        )}
                      />
                      <div className="flex flex-col justify-between items-start">
                        <div
                          className={cx(
                            "w-full mb-4 md:mb-0",
                            lang === "en" ? "md:mr-4" : "md:ml-4"
                          )}
                        >
                          <Label title={<Text value={translations.ENGLISH} />}>
                            <Field
                              data-testid={
                                !categoryId
                                  ? "add-category-title-en"
                                  : "edit-category-title-en"
                              }
                              key="en"
                              type="text"
                              name="titleEn"
                              placeholder={
                                lang === "en"
                                  ? translations.CATEGORY_TITLE[0]
                                  : translations.CATEGORY_TITLE[1]
                              }
                              component={Input}
                              spacing="px-4"
                            />
                          </Label>
                        </div>
                        <div className="w-full lg:mt-2">
                          <Label title={<Text value={translations.ARABIC} />}>
                            <Field
                              data-testid={
                                !categoryId
                                  ? "add-category-title-ar"
                                  : "edit-category-title-ar"
                              }
                              key="ar"
                              direction="rtl"
                              type="text"
                              name="titleAr"
                              placeholder={translations.CATEGORY_TITLE[1]}
                              component={Input}
                              spacing="px-4"
                            />
                          </Label>
                        </div>
                      </div>
                      <Row className="mt-1" spacing="0">
                        <Field
                          title={<Text value={translations.PUBLISHED_IN} />}
                          subtitle={
                            <Text
                              className="text-gray-700"
                              value={translations.DESC_SELECT_LOCATION}
                            />
                          }
                          name="publishedBranchIds"
                          font
                          placeholder={
                            lang === "en"
                              ? translations.PLEASE_SELECT_LOCATION[0]
                              : translations.PLEASE_SELECT_LOCATION[1]
                          }
                          items={branchesData}
                          component={MultiCheckbox}
                          testIdOpenList={
                            !categoryId
                              ? "add-publishIn-branches"
                              : "edit-publishIn-branches"
                          }
                          testIdOptionsList={
                            !categoryId
                              ? "add-publishIn-options"
                              : "edit-publishIn-options"
                          }
                          testIdClearAll={
                            !categoryId
                              ? "add-clear-all-branches"
                              : "edit-clear-all-branches"
                          }
                          testIdSelectAll={
                            !categoryId
                              ? "add-select-all-branches"
                              : "edit-select-all-branches"
                          }
                        />
                      </Row>
                      <Row>
                        <div className="w-full">
                          <Field
                            component={Uploader}
                            productCatalog
                            categoryImage
                            name="image"
                            imageUrl={initialImage && initialImage}
                            onRemove={() => {
                              setInitialImage(null)
                              setFieldValue("image", null)
                            }}
                            size="bigger"
                            type="squared"
                            info={
                              <Text
                                className="text-zyda-black-100"
                                value={translations.UPLOAD_DRAG_IMAGE}
                              />
                            }
                            format={
                              <Text
                                className="text-gray-500"
                                value={translations.UPLOAD_DRAG_IMAGE_FORMATS}
                              />
                            }
                            justify="start"
                            onUpload={image => setFieldValue("image", image)}
                            onUpdateImageMutation={() => deleteCategoryImage(storeId, selectedCatId)}
                            close={close}
                            open={openModal}
                            accept="image/*"
                            testId_uploadImg="photo-url"
                          />
                        </div>
                      </Row>
                      <Row>
                        {(isMobile || categoryId) && (
                          <Footer isMenu>
                            <div className="w-full flex items-center">
                              <div className="flex flex-col w-full">
                                {isMobile &&
                                  (categoryId ? (
                                    <>
                                      <Button
                                        data-testid="edit-save-category-btn"
                                        kind="primary"
                                        size="full"
                                      >
                                        {updateCategoryLoading && (
                                          <span className="mr-2">
                                            <SpinnerAlt color="primary-50" />
                                          </span>
                                        )}
                                        <Text value={translations.SAVE} />
                                      </Button>
                                      <div>
                                        <Button
                                          data-testid="editCategory-delete-btn"
                                          kind="borderlessButton"
                                          textColor="text-black"
                                          size="full"
                                          onClick={e => {
                                            e.preventDefault()
                                            openModal({
                                              testId: "delete-category-modal",
                                              size: "max-w-md",
                                              title: (
                                                <Text
                                                  className="text-xl pt-2"
                                                  value={
                                                    translations.DELET_CATEGORY
                                                  }
                                                />
                                              ),
                                              body: (
                                                <DeleteCategory
                                                  titleEn={values.titleEn}
                                                  titleAr={values.titleAr}
                                                  storeId={storeId}
                                                  catId={menuSectionData.id}
                                                  resetState={resetState}
                                                  onCancel={close}
                                                  handleDeletingCategories={
                                                    handleDeletingCategories
                                                  }
                                                />
                                              ),
                                            })
                                          }}
                                        >
                                          <Text
                                            value={translations.DELETE_CATEGORY}
                                          />
                                        </Button>
                                      </div>
                                    </>
                                  ) : (
                                    <Button
                                      data-testid="add-category-btn"
                                      kind="primary"
                                    >
                                      {addCategoryLoading && (
                                        <span className="mr-2">
                                          <SpinnerAlt color="primary-50" />
                                        </span>
                                      )}
                                      <Text value={translations.ADD} />
                                    </Button>
                                  ))}
                              </div>
                            </div>
                          </Footer>
                        )}
                        <div>
                          {emptyState && !isMobile && (
                            <Button
                              data-testid="add-category-btn"
                              kind="primary"
                            >
                              {addCategoryLoading && (
                                <span className="mr-2">
                                  <SpinnerAlt color="primary-50" />
                                </span>
                              )}
                              <Text value={translations.ADD} />
                            </Button>
                          )}
                        </div>
                      </Row>
                    </Container>
                  </div>
                </Form>
              )
            }}
          </Formik>
        )}
      </div>
    </div>
  )
}
export default CategoryForm
