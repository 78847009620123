import { css } from 'styled-components';

export default {
  wrapper: () => css`
    display: flex;
    justify-content: start;
    width: 50%;
  `,
  actionCard: () => css`
    display: flex;
    justify-content: space-between;
    width: 100%;
  `,
};
