import { DELIVERY_BUSY_MODE } from 'components/common/branch/Busy/utils';

export const SLIDE_OVER_COMPONENTS_ENUMS = {
  CUSTOMER_INFO: 'customerInfo',
  CREATE_CUSTOMER: 'createCustomer',
  CUSTOMER_DELIVERY_TYPE: 'customerDeliveryType',
  CUSTOMER_ADDRESS: 'customerAddress',
  SF_WEBVIEW: 'sfWebview',
};

export const CUSTOMER_INFO_ENUMS = {
  PHONE_NUMBER: 'phoneNumber',
  USER_NAME: 'userName',
};

export const handleErrorMessages = (err, notifications, formikRef) => {
  const body = err?.graphQLErrors[0]?.extensions?.exception?.body;
  Object.keys(body).forEach(key => {
    notifications.show(`${body[key]}`, 'error');
  });
  formikRef.current.setSubmitting(false);
};

export const storefrontOrderMode = branches => {
  const isOneOfBranchesHasDelivery = branches.some(branch => branch.deliveryOrdersEnabled === true);
  return isOneOfBranchesHasDelivery ? DELIVERY_BUSY_MODE.DELIVERY : DELIVERY_BUSY_MODE.PICKUP;
};
