import { gql } from "@apollo/client"

export const CREATE_PRODUCT = gql`
  mutation createMenuItem(
    $restaurantId: String!
    $menuSectionIds: [String]!
    $publishedBranchIds: [String]
    $titleEn: String!
    $titleAr: String!
    $descriptionEn: String
    $descriptionAr: String
    $externalId: String
    $price: String
  ) {
    createMenuItem(
      restaurantId: $restaurantId
      menuSectionIds: $menuSectionIds
      publishedBranchIds: $publishedBranchIds
      titleAr: $titleAr
      titleEn: $titleEn
      descriptionAr: $descriptionAr
      descriptionEn: $descriptionEn
      externalId: $externalId
      price: $price
    ) {
      id
    }
  }
`
