import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { Formik } from 'formik';
import { navigate } from '@reach/router';
import PropTypes from 'prop-types';

import { Wizard } from 'components/kit';
import { context as notificationsContext } from 'context/notifications';
import { useSelectedStore, useReplaceParams } from 'hooks';
import * as paths from 'paths.js';
import { camelCaseKeys, handleSubmissionErrors } from './utils';
import * as schemas from './schemas';
import Step from './Step';

const GatewayModal = ({ paymentGateway, modalClose }) => {
  const selectedStoreId = useSelectedStore();
  const replace = useReplaceParams();
  const notifications = useContext(notificationsContext);

  const [addPaymentCredential] = useMutation(schemas.ADD_PAYMENT_CREDENTIALS, {
    variables: {
      restaurantId: selectedStoreId.toString(),
      paymentGatewayId: paymentGateway.id,
    },
  });

  const initialValues = {};

  paymentGateway?.extraData?.steps &&
    paymentGateway.extraData.steps.forEach(
      step =>
        step?.fields &&
        step.fields.forEach(field => {
          initialValues[field.name] = '';
        }),
    );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={variables => {
        addPaymentCredential({ variables: camelCaseKeys(variables) })
          .then(() => {
            modalClose();
            navigate(replace(paths.paymentGateways, { selectedStoreId }));
          })
          .catch(error => notifications.show(handleSubmissionErrors(error), 'error'));
      }}
    >
      {({ errors, setFieldValue, setFieldError, validateForm, submitForm, setFieldTouched }) => (
        <Wizard isModal>
          {paymentGateway?.extraData?.steps?.map((step, i) => (
            <Step
              title={[step?.title?.en, step?.title?.ar]}
              step={step}
              stepKey={i}
              key={step?.title?.en}
              setFieldValue={setFieldValue}
              setFieldError={setFieldError}
              setFieldTouched={setFieldTouched}
              errors={errors}
              validateForm={validateForm}
              submitForm={submitForm}
            />
          ))}
        </Wizard>
      )}
    </Formik>
  );
};

GatewayModal.propTypes = {
  paymentGateway: PropTypes.shape({
    id: PropTypes.string,
    extraData: PropTypes.shape({
      steps: PropTypes.arrayOf(
        PropTypes.shape({
          fields: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string,
            }),
          ),
        }),
      ),
    }),
  }).isRequired,
  modalClose: PropTypes.func.isRequired,
};

export default GatewayModal;
