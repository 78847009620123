import { gql } from 'graphql-request';

const createDeliveryZoneMutation = gql`
  mutation($input: CreateDeliveryZoneInput!) {
    createDeliveryZone(input: $input) {
      deliveryZone {
        id
        branchId
        zoneName
      }
      clientMutationId
    }
  }
`;

export default createDeliveryZoneMutation;
