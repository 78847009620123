// @ts-nocheck
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { Label, Row } from 'components/form/generic';
import { Field, Text } from 'components/service';
import { Form, Formik } from 'formik';
import * as translations from 'constants/translations';
import { Input } from 'components/form/elements';
import { context } from '../context';

const FirstStep = ({ setOcimsValues }) => {
  const { ocimsValues } = useContext(context);
  const initialValues = {
    username: ocimsValues.username,
    password: ocimsValues.password,
  };

  const handleSubmitValue = (key, value) => {
    setOcimsValues(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };

  return (
    <Formik initialValues={initialValues}>
      <Form>
        <Row>
          <Label title={<Text value={translations.NAME} />}>
            <Field
              name="username"
              type="text"
              component={Input}
              autocomplete="new-name"
              autoFocus
              onChange={e => handleSubmitValue('username', e.target.value)}
            />
          </Label>
        </Row>
        <Row>
          <Label title={<Text value={translations.PASSWORD} />}>
            <Field
              name="password"
              type="password"
              autocomplete="new-password"
              component={Input}
              onChange={e => handleSubmitValue('password', e.target.value)}
            />
          </Label>
        </Row>
      </Form>
    </Formik>
  );
};

FirstStep.propTypes = {
  setOcimsValues: PropTypes.func.isRequired,
};
export default FirstStep;
