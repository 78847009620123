import React, { useContext } from "react"
import { useSelectedStore } from "hooks"
import { useMutation } from "@apollo/client"
import { navigate } from "@reach/router"
import { replaceParams } from "utils"
import * as paths from "paths.js"
import { Field, Text } from "components/service"
import { Button, Stack } from "components/kit"
import { Label, Container, Row, Footer } from "components/form/generic"
import * as translations from "constants/translations"
import { context as localeContext } from "context/locale"
import * as schemas from "../Table/schemas"
import { context as notificationsContext } from "context/notifications"

export default ({ onClose, updateZone }) => {
  const { lang, direction } = useContext(localeContext)

  return (
    <>
      <div className="px-6">
        <Container>
          <Row>
            <Text value={translations.ARE_YOU_SURE_UNPUBLISH_ZONE} />
          </Row>
        </Container>
      </div>
      <Footer modalFooter>
        <div className="w-full flex items-center">
          <div className="ml-auto">
            <Row>
              <Button onClick={onClose} type="button" kind="tertiary">
                <Text value={translations.CANCEL} />
              </Button>
              <Button
                onClick={updateZone}
                kind="secondaryError"
              >
                <Text value={translations.YES} />
              </Button>
            </Row>
          </div>
        </div>
      </Footer>
    </>
  )
}
