import { gql } from "@apollo/client"

export const OPTION_GROUPS = gql`
  query($restaurantId: String!, $productId: String) {
    propertySections(restaurantId: $restaurantId, productId: $productId) {
      id
      variantIds
      titleEn
      titleAr
      minQuantity
      maxQuantity
      multipleAddOnsEnabled
      propertyValues {
        id
        titleEn
        titleAr
        price
        externalId
      }
      variantProperties {
        id
        minQuantity
        maxQuantity
        multipleAddOnsEnabled
      }
      menuItems {
        id
        titleEn
        titleAr
      }
    }
  }
`

export const OPTIONS = gql`
  query($restaurantId: String!, $propertySectionId: String) {
    propertyValues(
      restaurantId: $restaurantId
      propertySectionId: $propertySectionId
    ) {
      id
      titleEn
      titleAr
      price
      externalId
    }
  }
`

export const CREATE_OPTION = gql`
  mutation(
    $restaurantId: String
    $propertyId: String
    $titleEn: String
    $titleAr: String
    $price: Float
    $externalId: String
  ) {
    createOption(
      restaurantId: $restaurantId
      propertyId: $propertyId
      titleEn: $titleEn
      titleAr: $titleAr
      price: $price
      externalId: $externalId
    ) {
      id
      titleEn
      titleAr
      price
      externalId
    }
  }
`

export const UPDATE_OPTION = gql`
  mutation(
    $restaurantId: String
    $propertyId: String
    $titleEn: String
    $titleAr: String
    $price: Float
    $externalId: String
    $id: String
  ) {
    updateOption(
      restaurantId: $restaurantId
      propertyId: $propertyId
      titleEn: $titleEn
      titleAr: $titleAr
      price: $price
      externalId: $externalId
      id: $id
    ) {
      id
      titleEn
      titleAr
      price
      externalId
    }
  }
`

export const DELETE_OPTION = gql`
  mutation($restaurantId: String, $propertyId: String, $id: String) {
    deleteOption(
      restaurantId: $restaurantId
      propertyId: $propertyId
      id: $id
    ) {
      id
    }
  }
`
