export const BEHAVIOR_BREAKDOWN_TABS = {
  DELIVERY_TYPE: 'delivery_type',
  PAYMENT_METHOD: 'payment_method',
  NEW_VS_RETURNING: 'new_vs_returning',
};

export const DATA_SELECTION = {
  total_sales: 'salesData',
  avg_order_value: 'avgOrderData',
  total_orders: 'ordersData',
  total_customers: 'customersData',
};
