import React from "react"
import * as yup from "yup"
import { Text } from "components/service"
import * as translations from "constants/translations"

export const validationSchema = yup.object().shape({
  name: yup.string().required(<Text value={translations.FULL_NAME_IS_REQ} />),
  email: yup
    .string()
    .email(<Text value={translations.INVALID_EMAIL_FORMAT} />)
    .required(<Text value={translations.EMAIL_IS_REQ} />),
  phoneNumber: yup
    .string()
    .required(<Text value={translations.PHONE_IS_REQ} />),
  role: yup.string().required(<Text value={translations.ROLE_IS_REQ} />),
  branches: yup.array().of(yup.string().required()),
})
