import React, { useEffect, useRef, useContext } from "react"
import { isInViewport } from "utils/index"
import { context as localeContext } from "context/locale"
import cx from "classnames"
export default ({ children, direction }) => {
  const ref = useRef()
  const inViewport = ref.current && isInViewport(ref.current)
  const { lang } = useContext(localeContext)
  useEffect(() => {
    const scrollView = document.getElementById("scroll-view")
    let id

    if (!inViewport && scrollView) {
      id = setTimeout(() => {
        scrollView.scrollTo(0, ref.current.offsetTop - 300)
      }, 300)
    }

    return () => clearTimeout(id)
  }, [inViewport])

  return (
    <p ref={ref} className="text-red-600 text-xs my-2 flex items-center">
      <i
        className={cx(
          "material-icons text-red-600 text-sm px-2 ",
          lang == "ar" ? "pr-0" : "pl-0"
        )}
      >
        error
      </i>
      {children}
    </p>
  )
}
