import React from "react"
import { Text } from "components/service"
import { Button } from "components/kit"
import { Container, Row, Footer } from "components/form/generic"
import * as translations from "constants/translations"

export default ({ onClick, onClose, isDeleting, pageType }) => {
  return (
    <>
      <div className="px-6">
        <Container>
          <Row>
            <Text value={translations.ARE_YOU_SURE_DELETE_IMAGE} />
          </Row>
        </Container>
      </div>
      <Footer modalFooter>
        <div className="w-full flex items-center">
          <div className="ml-auto">
            <Row>
              <Button
                data-testid={`${pageType}-cancel-delete-variant-image`}
                onClick={onClose}
                type="button"
                kind="tertiary"
              >
                <Text value={translations.DISMISS} />
              </Button>
              <Button
                data-testid={`${pageType}-delete-variant-image`}
                onClick={onClick}
                isSpinning={isDeleting}
                kind="secondaryError"
              >
                <Text value={translations.DELETE} />
              </Button>
            </Row>
          </div>
        </div>
      </Footer>
    </>
  )
}
