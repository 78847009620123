import React, { useContext } from "react"

import { CreationBox, Button, Modal } from "components/kit"
import { Text } from "components/service"
import * as translations from "constants/translations"
import { context as userContext } from "context/user"
import { context as localeContext } from "context/locale"
import ImportCatalog from "../ImportCatalog"
import { context as notificationsContext } from "context/notifications"
import AddCategory from "../AddCategory"
import { ReactComponent as EmptyCategoryDesktop } from "assets/empty-category.svg"
import { ReactComponent as EmptyCategoryMobile } from "assets/empty-category-mob.svg"
import { useMobile } from "hooks"
import ImportFromFoodicsModal from "pages/menu/overview/List/ImportFromFoodicsModal"

export default ({ refetchProduct, refetchCat, setSelectedCat }) => {
  const { lang } = useContext(localeContext)
  const { branches, isPosCourier, pos } = useContext(userContext)
  const posName = lang === 'en' ? pos?.courierDetails?.name : pos?.courierDetails?.nameAr ;

  const notifications = useContext(notificationsContext)
  const isMobile = useMobile()

  return (
    <div className="flex flex-col mx-auto max-w-2xl items-center ">
      {isMobile ? (
        <EmptyCategoryMobile className="w-full" />
      ) : (
        <EmptyCategoryDesktop className="w-full" />
      )}
      <CreationBox
        title={<Text value={translations.CREATE_PRODUCT_CATALOG} />}
        description={
          <Text
            value={translations.START_ADDING_CATEGORIES_IMPORT_EXISTING_CATALOG}
          />
        }
        actions={[
          <Modal>
            {({ open, close }) => (
              <>
                <div className="mx-2 inline-block">
                  <Button
                    data-testid="add-category-btn"
                    onClick={() => {
                      if (branches === null) {
                        notifications.show(
                          <Text
                            value={
                              translations.YOU_HAVE_ADD_BUSINESS_LOCATION_FIRST
                            }
                          />,
                          "info"
                        )
                      } else {
                        open({
                          testId: "add-category-modal",
                          title: <Text value={translations.ADD_CATEGORY} />,
                          body: (
                            <AddCategory
                              displayPublish
                              onCancel={close}
                              refetch={refetchCat}
                              initialValues={{}}
                              setSelectedCat={setSelectedCat}
                              type="add"
                            />
                          ),
                        })
                      }
                    }}
                    kind="primary"
                  >
                    <Text value={translations.ADD_CATEGORY} />
                  </Button>
                </div>
                <div className="mx-2 inline-block">
                  <Button
                    data-testid="import-catalog-btn"
                    onClick={() => {
                      if (branches === null) {
                        notifications.show(
                          <Text
                            value={
                              translations.YOU_HAVE_ADD_BUSINESS_LOCATION_FIRST
                            }
                          />,
                          "info"
                        )
                      } else {
                        open({
                          testId: "import-catalog-modal",
                          title: <Text value={translations.IMPORT_CATALOG} />,
                          body: (
                            <ImportCatalog
                              onCancel={close}
                              refetchProduct={refetchProduct}
                              refetchCat={refetchCat}
                            />
                          ),
                        })
                      }
                    }}
                    kind="secondary"
                  >
                    <Text value={translations.IMPORT_CATALOG} />
                  </Button>
                </div>
                {(isPosCourier && pos?.isMenuPull && <div className="mx-2 inline-block">
                  <Button
                    data-testid="import-catalog-foodics-btn"
                    onClick={() => {
                      if (branches === null) {
                        notifications.show(
                          <Text
                            value={
                              translations.YOU_HAVE_ADD_BUSINESS_LOCATION_FIRST
                            }
                          />,
                          "info"
                        )
                      } else {
                        open({
                          testId: "list-products-sort-catalog-import-foodics-modal",
                          title: (
                            <Text value={translations.IMPORT_CATALOG_FROM_POS(posName)} />
                          ),
                          body: (
                            <ImportFromFoodicsModal onCancel={close}/> 
                          ),
                        })
                      }
                    }}
                    kind="secondary"
                  >
                    <Text value={translations.IMPORT_CATALOG_FROM_POS(posName)} />
                  </Button>
                </div>)}
              </>
            )}
          </Modal>
        ]}
      />
    </div>
  )
}
