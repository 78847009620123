import React, { useContext } from 'react';
import { Formik, Form } from 'formik';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { Row, Label } from 'components/form/generic';
import { Field, Text } from 'components/service';
import * as translations from 'constants/translations';
import { Input, Textarea, PhoneInput } from 'components/form/elements';
import { context as notificationsContext } from 'context/notifications';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import {
  initialValues as formInitialValues,
  validationSchema,
  webLinksInputsData,
  webLinksTypes,
} from 'components/common/WebLink/data';
import { DropDown } from 'components/kit';
import { LINK_TYPES } from 'common/components/LinkForm/constants';
import { handleSubmissionErrors } from './utils';

const LinkForm = ({
  initialValues,
  onSubmit,
  isSubmitting,
  linksTypes,
  renderBottomPane,
  renderTopPane,
  formClassName,
  isPartiallyDisabled,
}) => {
  const notifications = useContext(notificationsContext);
  const { lang, direction, translate } = useContext(localeContext);
  const { selectedStore } = useContext(userContext);

  return (
    <Formik
      initialValues={initialValues || {}}
      validationSchema={validationSchema}
      onSubmit={async formValues => {
        try {
          await onSubmit(formValues);
        } catch (err) {
          const message = handleSubmissionErrors(err);
          notifications.show(message, 'error');
        }
      }}
    >
      {/* TODO: We need to handle errors! */}
      {({ values: formValues, setFieldValue }) => (
        <>
          {renderTopPane && renderTopPane({ isSubmitting })}
          <Form style={{ direction }} className={formClassName}>
            <Row className="pb-6">
              <Row className="flex-col md:flex-row">
                <div className="flex flex-col md:flex-row justify-between items-start">
                  <div className={cx('w-full mb-6 md:mb-0', lang === 'en' ? 'mr-0 md:mr-4' : 'ml-0 md:ml-4')}>
                    <Label title={<Text value={translations.TITLE_ENGLISH} />}>
                      <Field
                        type="text"
                        name="titleEn"
                        placeholder={translations.EXAMPLE_FOLLOW_US[0]}
                        component={Input}
                        data-testid="business-location-name-en"
                      />
                    </Label>
                  </div>
                  <div className="w-full mb-0 md:mb-0">
                    <Label title={<Text value={translations.TITLE_ARABIC} />}>
                      <Field
                        type="text"
                        direction="rtl"
                        name="titleAr"
                        placeholder={translations.EXAMPLE_FOLLOW_US[1]}
                        component={Input}
                        data-testid="business-location-name-ar"
                      />
                    </Label>
                  </div>
                </div>
              </Row>
            </Row>
            <Row className="pb-6">
              <Label title={<Text value={translations.TYPE_URL} />}>
                <DropDown
                  isDisabled={isPartiallyDisabled}
                  name="linkType"
                  optionSelected={formValues.linkType}
                  onSelect={option => {
                    setFieldValue('linkType', option);
                    setFieldValue('url', undefined);
                  }}
                  data={linksTypes}
                  icon="keyboard_arrow_down"
                  wFull
                />
              </Label>
              {webLinksInputsData[formValues.linkType] ? (
                <Label title={webLinksInputsData[formValues.linkType].text}>
                  <Field
                    name="url"
                    prepend={webLinksInputsData[formValues.linkType].prependedText}
                    prependColor="text-black"
                    direction="ltr"
                    placeholder={translate(webLinksInputsData[formValues.linkType].placeholderText)}
                    component={Input}
                    disabled={isPartiallyDisabled}
                  />
                </Label>
              ) : (
                <Label title={<Text value={translations.PHONE_NUMBER_ORDER} />}>
                  <Field
                    type="text"
                    name="phoneNumber"
                    country={selectedStore.countryCode.toLowerCase()}
                    placeholder={translate(translations.MOBILE_NUMBER_PLACEHOLDER)}
                    component={PhoneInput}
                    testId="phone-number"
                  />
                </Label>
              )}
            </Row>
            {formValues.linkType === LINK_TYPES.SMS && (
              <Row className="pb-6">
                <Label title="SMS body">
                  <Field name="smsBody" rows={4} component={Textarea} />
                </Label>
              </Row>
            )}
            {renderBottomPane && renderBottomPane({ isSubmitting })}
          </Form>
        </>
      )}
    </Formik>
  );
};

LinkForm.propTypes = {
  initialValues: PropTypes.oneOfType([PropTypes.object]),
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  linksTypes: PropTypes.arrayOf(webLinksTypes[0]),
  renderBottomPane: PropTypes.func,
  renderTopPane: PropTypes.func,
  formClassName: PropTypes.string,
  isPartiallyDisabled: PropTypes.bool,
};

LinkForm.defaultProps = {
  initialValues: formInitialValues,
  formClassName: '',
  isPartiallyDisabled: false,
};

export default LinkForm;
