export const NORTHERNMOST_LATITUDE = 90;
export const SOUTHERNMOST_LATITUDE = -90;
export const EASTERNMOST_LONGITUDE = 180;
export const WESTERNMOST_LONGITUDE = -180;
export const RED_COLOR = { red: 255, green: 0, blue: 0 };
export const BLUE_COLOR = { red: 0, green: 0, blue: 255 };
export const MAGENTA_COLOR = { red: 255, green: 0, blue: 255 };
export const GREEN_COLOR = { red: 32, green: 177, blue: 122 };
export const YELLOW_COLOR = { red: 253, green: 192, blue: 46 };
export const ORANGE_COLOR = { red: 245, green: 127, blue: 22 };
export const CYAN_COLOR = { red: 0, green: 255, blue: 255 };
export const DODGER_BLUE = { red: 30, green: 144, blue: 255 };
export const GOOGLE_MAPS_LISTENER_EVENT_NAMES = {
  MOUSEOVER: 'mouseover',
  MOUSEOUT: 'mouseout',
};
