import React, { useContext, useState } from 'react';
import { useQuery } from '@apollo/client';
import cx from 'classnames';

import { useSelectedStore } from 'hooks/index';
import { context as localeContext } from 'context/locale';
import { Text } from 'components/service';
import { breadcrumbs } from 'constants/translations';
import { Layout, Breadcrumbs } from 'components/common/dashboard';
import * as translations from 'constants/translations';
import SEGMENTS from 'common/schemas/segmentSchema';
import { BILLING_AND_PLANS_LINKS } from 'constants/helperLinks';
import { INVOICE_STATUS } from 'constants/billing';
import CurrentSubscription from './CurrentSubscription';
import BillingActionCard from './BillingActionCards';
import deatailsStyle from './detailsStyles';
import SUBSCRIPTION_SCHEMA from './billingSchema';
import { CASHBACK, RESTAURANT } from './commissionSchema';
import INVOICE_SCHEMA from '../Invoices/invoiceSchema';

const Details = () => {
  const { direction, translate } = useContext(localeContext);
  const selectedStoreId = useSelectedStore();
  const [error, setError] = useState('');
  const [invoiceError, setInvoiceError] = useState('');
  const [isCurrentInvoiceUnpaid, setIsCurrentInvoiceUnpaid] = useState(false);

  const { data: subscriptionData } = useQuery(SUBSCRIPTION_SCHEMA, {
    fetchPolicy: 'network-only',
    variables: {
      storeId: selectedStoreId,
    },
    onCompleted: data => {
      const currentInvoice = data?.subscription?.invoices?.find(invoice => invoice.current === true);
      if (currentInvoice?.status === INVOICE_STATUS.UNPAID) setIsCurrentInvoiceUnpaid(true);
      else setIsCurrentInvoiceUnpaid(false);
    },
    onError: err => {
      setError(err?.graphQLErrors?.[0]?.extensions?.exception?.body?.error);
    },
  });

  const { data: segments } = useQuery(SEGMENTS, {
    variables: {
      storeId: selectedStoreId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const { data: cashback } = useQuery(CASHBACK, {
    variables: {
      restaurantId: selectedStoreId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const { data: restaurantData } = useQuery(RESTAURANT, {
    variables: {
      restaurantId: selectedStoreId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const invoices = useQuery(INVOICE_SCHEMA, {
    fetchPolicy: 'network-only',
    variables: { storeId: selectedStoreId },
    onError: err => {
      setInvoiceError(err?.graphQLErrors?.[0]?.extensions?.exception?.body?.error);
    },
  });

  return (
    <Layout
      top={
        <div>
          <Breadcrumbs helperLinks={BILLING_AND_PLANS_LINKS} path={breadcrumbs.Billing_and_Plans} />
        </div>
      }
    >
      {error ? (
        <div style={{ direction }} css={deatailsStyle.wrapper()}>
          {direction === 'ltr' ? <p>{error}</p> : <p>هذا المتجر ليس لهو </p>}
        </div>
      ) : (
        subscriptionData && (
          <>
            {restaurantData?.restaurant?.isCommissionBased ? (
              <div className="grid grid-cols-12 w-100 mb-8">
                <div
                  className={cx(
                    'col-span-12 xl:col-span-4 bg-gray-100 p-4 w-100 mb-3 rounded',
                    direction === 'rtl' ? 'mr-0 lg:ml-3' : 'ml-0',
                  )}
                >
                  <Text value={translations.TOTAL_CUSTOMERS} className="font-medium text-xs text-gray-600" />
                  <span className={cx('font-bold text-xl block', direction === 'rtl' ? 'text-right' : 'text-left')}>
                    {segments?.segments?.totalCustomerCount}
                  </span>
                </div>
                <div className="col-span-12 xl:col-span-4 rounded bg-gray-100 p-4 ml-0 w-100 mb-3">
                  <Text value={translations.TOTAL_SALES} className="font-medium text-xs text-gray-600" />
                  <span className={cx('font-bold text-xl block', direction === 'rtl' ? 'text-right' : 'text-left')}>
                    {cashback?.cashBack?.totalSales.toFixed(2)}
                  </span>
                </div>
                {restaurantData?.restaurant?.isCommissionBased && (
                  <div className="col-span-12 xl:col-span-4 rounded bg-gray-100 p-4 ml-0 w-100 mb-3">
                    <Text value={translations.TOTAL_CASHBACK} className="font-medium text-xs text-gray-600" />
                    <span className={cx('font-bold text-xl block', direction === 'rtl' ? 'text-right' : 'text-left')}>
                      {cashback?.cashBack?.totalCashbackAmount.toFixed(2)}
                    </span>
                  </div>
                )}
              </div>
            ) : (
              <>
                <div className="w-full mb-6" style={{ direction }}>
                  <CurrentSubscription
                    titleEn={subscriptionData?.subscription?.serviceData?.titleEn}
                    titleAr={subscriptionData?.subscription?.serviceData?.titleAr}
                    expiresAt={subscriptionData?.subscription?.expiresAt}
                    price={subscriptionData?.subscription?.total}
                    currencyEn={subscriptionData?.subscription?.restaurant?.currency?.titleEn}
                    currencyAr={subscriptionData?.subscription?.restaurant?.currency?.titleAr}
                    interval={subscriptionData?.subscription?.serviceData?.interval}
                    subscriptionStatus={subscriptionData?.subscription?.status}
                    cancelationRequest={
                      subscriptionData?.subscription?.cancelSubscriptionRequests[0] &&
                      subscriptionData?.subscription?.cancelSubscriptionRequests[0].status
                    }
                    isCurrentInvoiceUnpaid={isCurrentInvoiceUnpaid}
                    features={{
                      featuresEn: subscriptionData?.subscription?.countryPlan?.plan?.featuresEn || [],
                      featuresAr: subscriptionData?.subscription?.countryPlan?.plan?.featuresAr || [],
                    }}
                  />
                </div>
                <BillingActionCard
                  currency={[
                    subscriptionData?.subscription?.restaurant?.currency?.titleEn || '',
                    subscriptionData?.subscription?.restaurant?.currency?.titleAr || '',
                  ]}
                  translate={translate}
                  isLoadingInvoices={invoices.isLoading}
                  invoices={
                    invoices && invoices.data
                      ? invoices.data.storeInvoices.length > 3
                        ? invoices.data.storeInvoices.slice(0, 3)
                        : invoices.data.storeInvoices
                      : ''
                  }
                />
              </>
            )}
          </>
        )
      )}
    </Layout>
  );
};

export default Details;
