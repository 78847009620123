import * as paths from "paths.js"

export const redirectTo = (
  isAuthenticated,
  { isStoreCreated, storeId, blockTabs } = {}
) => {
  if (!isAuthenticated) {
    return paths.signIn
  }

  if (isStoreCreated && storeId) {
    return blockTabs ? "/" + storeId + "/billing/invoices" : "/" + storeId
  }

  if (!isStoreCreated) {
    return paths.onboarding.storeCreation
  }

  return "/"
}
