import React, { useContext } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import * as translations from 'constants/translations';
import { Text } from 'components/service';
import { context as localeContext } from 'context/locale';
import { PAYMENT_STATUS_ENUMS } from './enums';

const PaymentStatusSign = ({ status }) => {
  const { lang } = useContext(localeContext);

  switch (status) {
    case PAYMENT_STATUS_ENUMS.PENDING:
      return (
        <div className="flex items-center">
          <div className="w-2 h-2 rounded-full bg-zyda-yellow-300" />
          <div data-testid="status-pending-container">
            <Text
              testId="status-pending"
              value={translations.PENDING_ORDERS}
              className={cx('text-yellow-600', lang === 'en' ? 'ml-2' : 'mr-2')}
            />
          </div>
        </div>
      );
    case PAYMENT_STATUS_ENUMS.REJECTED:
      return (
        <div className="flex items-center">
          <div className="w-2 h-2 rounded-full bg-zyda-red-300" />
          <div data-testid="status-rejected-container">
            <Text
              testId="status-rejected"
              value={translations.REJECTED}
              className={cx('text-zyda-red-300', lang === 'en' ? 'ml-2' : 'mr-2')}
            />
          </div>
        </div>
      );
    case PAYMENT_STATUS_ENUMS.APPROVED:
      return (
        <div className="flex items-center">
          <div className="w-2 h-2 rounded-full bg-zyda-green-300" />
          <div data-testid="status-active-container">
            <Text
              testId="status-active"
              value={translations.ACCEPTED_ORDERS}
              className={cx('text-zyda-green-300', lang === 'en' ? 'ml-2' : 'mr-2')}
            />
          </div>
        </div>
      );
    default:
      return null;
  }
};

PaymentStatusSign.propTypes = {
  status: PropTypes.string.isRequired,
};

export default PaymentStatusSign;
