import React from "react"
import { Text, Link } from "components/service"
import * as translations from "constants/translations"
import { Button } from "components/kit"
import * as paths from "paths.js"
import { ReactComponent as Illustration } from "assets/vouchers.svg"

export default () => {
  return (
    <div className="flex flex-col items-center mt-24 text-center">
      <Illustration />
      <Text
        className="text-lg mb-1 font-semibold mt-4"
        value={translations.NO_VOUCHERS_YET}
      />
      <Text
        className="text-gray-700 mb-6"
        value={translations.DSCP_EMPTY_STATE_VOUCHERS}
      />
      <Link to={paths.createVoucher}>
        <Button kind="primary">
          <Text value={translations.ADD_NEW_VOUCHER} />
        </Button>
      </Link>
    </div>
  )
}
