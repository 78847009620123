/* global google */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, InfoWindow } from '@react-google-maps/api';
import { geoPath } from 'd3-geo';

import { Spinner } from 'components/kit';
import { MAP_FAILED_TO_LOAD } from 'constants/translations';
import { GOOGLE_MAPS_LISTENER_EVENT_NAMES, DELIVERY_ZONE_TEMPLATE_PROPERTIES } from 'constants/index';
import { Text } from 'components/service';
import { getGeometryCenterPosition } from '../utils';
import { colorsUtils } from '../Zones/utils';

const TemplatesMap = ({ zones, isLoaded, loadError }) => {
  const [infoWindowPosition, setInfoWindowPosition] = useState({ lat: 1, lng: 1 });
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [infoWindowContent, setInfoWindowContent] = useState({ cityId: '', areaId: '' });

  if (loadError) {
    return <Text value={MAP_FAILED_TO_LOAD} />;
  }

  return isLoaded ? (
    <GoogleMap
      onLoad={map => {
        const { data: dataLayer } = map;
        dataLayer.addListener(GOOGLE_MAPS_LISTENER_EVENT_NAMES.MOUSEOVER, item => {
          setInfoWindowPosition(getGeometryCenterPosition(item.feature.getGeometry()));
          setInfoWindowContent({
            cityId: item.feature.getProperty(DELIVERY_ZONE_TEMPLATE_PROPERTIES.CITY_ID),
            areaId: item.feature.getProperty(DELIVERY_ZONE_TEMPLATE_PROPERTIES.AREA_ID),
          });
          setShowInfoWindow(true);
        });
        dataLayer.addListener(GOOGLE_MAPS_LISTENER_EVENT_NAMES.MOUSEOUT, () => {
          setShowInfoWindow(false);
        });
        dataLayer.addGeoJson(zones);
        const jsonBounds = geoPath().bounds(zones);
        const mapBounds = new google.maps.LatLngBounds(
          {
            lat: jsonBounds[0][1],
            lng: jsonBounds[0][0],
          },
          {
            lat: jsonBounds[1][1],
            lng: jsonBounds[1][0],
          },
        );
        map.fitBounds(mapBounds);
        dataLayer.setStyle(feature => {
          const color =
            colorsUtils[feature.getProperty(DELIVERY_ZONE_TEMPLATE_PROPERTIES.CITY_ID) % colorsUtils.length];
          return /** @type {!google.maps.Data.StyleOptions} */ {
            fillColor: color,
            strokeColor: color,
            strokeWeight: 1,
          };
        });
      }}
      mapContainerClassName="h-screen w-full"
      options={{
        mapTypeControl: true,
        fullscreenControl: true,
        streetViewControl: false,
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_TOP,
        },
      }}
    >
      {showInfoWindow && (
        <InfoWindow position={infoWindowPosition}>
          <div className="p-2">
            <h1>City : {infoWindowContent.cityId}</h1>
            <h1>Area : {infoWindowContent.areaId}</h1>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  ) : (
    <div className="h-full w-full d-flex align-center justify-center">
      <Spinner />
    </div>
  );
};

TemplatesMap.propTypes = {
  zones: PropTypes.shape({
    type: PropTypes.string,
    properties: PropTypes.shape({ restaurantName: PropTypes.string }),
    features: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        properties: PropTypes.shape({
          area_id: PropTypes.number,
          city_id: PropTypes.number,
        }),
        geometry: PropTypes.shape({
          type: PropTypes.string,
          coordinates: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))),
        }),
      }),
    ),
  }).isRequired,
  isLoaded: PropTypes.bool,
  loadError: PropTypes.oneOf([
    PropTypes.shape({ name: PropTypes.string, message: PropTypes.string, stack: PropTypes.string }),
    undefined,
  ]),
};

export default TemplatesMap;
