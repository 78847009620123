import React, { useState, useContext } from "react"
import { context as localeContext } from "context/locale"
import { Text, Link } from "components/service"
import * as translations from "constants/translations"
import styled from "styled-components"
import { Button } from "components/kit"
import * as paths from "paths.js"
import cx from "classnames"
import { ReactComponent as WarningIcon } from "assets/icons-alert.svg"

export default () => {
  const { translate, lang } = useContext(localeContext)
  return (
    <div className="flex flex-col items-center mt-24 pb-32 px-10 text-center">
      <span className="rounded-full bg-primary-50">
        <WarningIcon className="w-10 h-10 m-4 fill-current text-red-600" />
      </span>

      <Text
        className="text-lg mb-1 font-semibold mt-4"
        value={translations.NOT_AVAILABLE_IN_YOUR_PLAN}
      />
      <Text
        className="text-gray-700 mb-6"
        value={translations.NOT_AVAILABLE_IN_YOUR_PLAN_DESC}
      />

      <Link className="w-48 mt-4" to={paths.billing}>
        <Button size="full" kind="primary">
          <Text value={translations.VIEW_PLANS} />
        </Button>
      </Link>
    </div>
  )
}
