import React, { useState, useRef } from "react"
import { Button} from "components/kit"
import cx from "classnames"
import useClickOutside from "hooks/useClickOutside"
import { Text } from "components/service"

export default ({
    disabled,
    type = "submit",
    kind = "action",
    size = "base",
    iconSize = "sm",
    weight = "semibold",
    isSpinning = false,
    isLink = false,
    full,
    children,
    icon,
    textColor,
    actionBtnText,
    actionBtnOnClick,
    items,
    dropBtnTestId,
    actionBtnTestId,
    containerClassName,
}) => {
    const [isOpened, setOpened] = useState(false)
    const ref = useRef()

    useClickOutside(ref, () => setOpened(false))
    return (
        <div className={cx("flex", full && "w-full", containerClassName)}>
            <Button
                onClick={actionBtnOnClick}
                data-testid={actionBtnTestId}
                kind={kind}
                type={type}
                size={size}
                iconSize={iconSize}
                weight={weight}
                isSpinning={isSpinning}
                isLink={isLink}
                full={full}
                children={children}
                icon={icon}
                textColor={textColor}
                disabled={disabled}
            >
                <Text full={false} value={actionBtnText} />
            </Button>
            <div className="relative" ref={ref}>
                <Button
                    onClick={() => setOpened(!isOpened)}
                    type="button"
                    kind={kind}
                    disabled={isSpinning}
                    icon="keyboard_arrow_down"
                    size="option"
                    iconSize={iconSize}
                    data-testid={dropBtnTestId}
                />
                {isOpened && (
                    <div
                        className={cx(
                            "z-10 absolute top-0 mt-12 w-32 shadow bg-white rounded py-2",
                            "right-0"
                        )}
                    >
                        {items.map((item, i) =>
                            item.type === "separator" ? (
                                <div className="h-px w-full bg-zyda-grey-50 my-2" />
                            ) : (
                                    <div
                                        key={i}
                                        onClick={() => {
                                            setOpened(false)
                                            item.onClick()
                                        }}
                                        className="py-1 px-2 border-t border-b border-transparent hover:border-gray-300 cursor-pointer text-xs"
                                        data-testid={item.testId}
                                    >
                                        {item.title}
                                    </div>
                                )
                        )}
                    </div>
                )}
            </div>

        </div>
    )
}