import React, { useContext } from "react"
import moment from "moment"
import { DropDown, DatePicker } from "components/kit"
import { context as localeContext } from "context/locale"
import { context as notificationsContext } from "context/notifications"
import { Text } from "components/service"
import { useQueryState } from "hooks/index"
import * as translations from "constants/translations"
import {
  getDefaultRange,
  parseRange,
  formatDate,
  dateQueryFormat,
  getRange,
} from "utils/date"

export default ({
  query = { ...query, range: JSON.stringify(getRange("all")) },
  setQuery,
  wFull,
  responsiveLabel,
}) => {
  const { direction } = useContext(localeContext)
  const notifications = useContext(notificationsContext)
  const range = parseRange(query.range)

  return (
    <>
      <DropDown
        dropdownClassName="w-64"
        noOverflow
        float={direction === "rtl" ? "right" : "left"}
        label={<Text value={translations.DATE_RANGE} />}
        optionSelected={query.date}
        onSelect={option =>
          setQuery({
            date: option,
            ...(option !== "custom" && {
              range: JSON.stringify(getRange(option)),
            }),
          })
        }
        data={[
          {
            id: "all",
            title: <Text value={translations.ALL} />,
          },
          {
            id: "custom",
            title: `${formatDate(range[0])} - ${formatDate(range[1])}`,
            body: close => (
              <DatePicker
                maxDate={new Date()}
                range={range.map(date =>
                  moment(date, dateQueryFormat).toDate()
                )}
                onChange={range => {
                  const error = validateRange(range)

                  if (error) {
                    return notifications.show(error, "error")
                  }

                  return (
                    !close() &&
                    setQuery({
                      date: "custom",
                      range: JSON.stringify(
                        range.map(date => moment(date).format(dateQueryFormat))
                      ),
                    })
                  )
                }}
              />
            ),
          },
        ]}
        icon="keyboard_arrow_down"
        // position={direction === "rtl" ? "left" : "right"}
        scrollable
        wFull
        responsiveLabel
      />
    </>
  )
}

const validateRange = range => {
  if (
    moment(range[0], dateQueryFormat).isAfter(moment(range[1], dateQueryFormat))
  ) {
    return <Text value={translations.DATES_CONFLICT} />
  }
}
