import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';

import * as translations from 'constants/translations';
import { context as localeContext } from 'context/locale';
import { Row, Label } from 'components/form/generic';
import { Button } from 'components/kit';
import { Field, Text } from 'components/service';
import { Stepper } from 'components/form/elements';
import { context as notificationsContext } from 'context/notifications';
import { useUpdateBulkDeliveryZone } from 'service/hooks';
import { useSelectedStore } from 'hooks';
import { convertedDeliveryTimeUtils, zonesInputNames } from 'pages/setup/delivery/Zones/utils';
import { convertTime } from 'utils';
import bulkValidationSchema from '../../BulkEdit/data';
import { bulkEditZones } from '../../utils';

const UpdateDeliveryTimeModal = ({ bulkZones, close, setZones, defaultProductPrepTime }) => {
  const selectedStoreId = useSelectedStore();
  const notifications = useContext(notificationsContext);
  const { translate } = useContext(localeContext);

  const initialValues = {
    needsDeliveryTime: true,
    deliveryTime: [0, 'mins'],
  };
  const [pending, setPending] = useState(false);

  const updateBulkDeliveryZones = useUpdateBulkDeliveryZone();

  const handleFormSubmit = async (values, setErrors) => {
    setPending(true);
    const convertedDeliveryTime = convertedDeliveryTimeUtils(
      values.deliveryTime[1],
      values.deliveryTime[0],
      convertTime,
    );

    const response = await updateBulkDeliveryZones({
      deliveryZones: bulkZones.map(bulkItem => ({
        restaurantReferenceId: parseFloat(selectedStoreId),
        id: bulkItem.properties.id,
        deliveryTime: convertedDeliveryTime,
      })),
    });

    if (!response.hasError) {
      setPending(false);
      if (response.updateBulkDeliveryZones) {
        const { deliveryZone, errors } = response.updateBulkDeliveryZones;
        // - All zones failed
        if (deliveryZone.length === 0 && errors.length >= 1) {
          const graphqlErrors = errors[0].errorMessage;
          const errorObj = {};
          if (graphqlErrors) {
            Object.entries(graphqlErrors).map(value => {
              if (value[0] === zonesInputNames.DELIVERY_TIME) {
                const time = value[1][0].match(/\(([^\\)]+)\)/g)[0];
                Object.assign(errorObj, { deliveryTime: translate(translations.DELIVERY_TIME_PREPARATION(time)) });
              }

              return null;
            });
          }
          setErrors(errorObj);
        }

        // - Some zones success and some failed
        if (deliveryZone.length >= 1 && errors.length >= 1) {
          notifications.show(
            <div className="flex flex-wrap">
              <p>{translate(translations.BULK_EDIT_ISSUE)} &nbsp;</p>
              <ul className="list-none flex flex-wrap">
                {errors.map(errorItem => (
                  <li key={errorItem.zoneName}>{errorItem.zoneName},&nbsp;</li>
                ))}
              </ul>
            </div>,
            'error',
          );
          bulkEditZones(setZones, deliveryZone, 'delivery_time', convertedDeliveryTime);
          close();
        }

        // - All zones success
        if (deliveryZone.length >= 1 && errors.length === 0) {
          notifications.show(<Text value={translations.BULK_EDIT_SUCCESS_MSG(deliveryZone.length)} />);
          bulkEditZones(setZones, deliveryZone, 'delivery_time', convertedDeliveryTime);
          close();
        }
      }
    } else {
      setPending(false);
      close();
      notifications.show(<Text value={translations.SOMETHING_WENT_WRONG} />, 'error');
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setErrors }) => handleFormSubmit(values, setErrors)}
      validationSchema={bulkValidationSchema}
    >
      <Form>
        <Row className="mt-2 mx-3">
          <Row className="mt-3">
            <Label
              textSize="text-xs"
              title={<Text value={translations.INCLUDE_PREP_TIME} payload={defaultProductPrepTime} />}
            >
              <Field
                min={0}
                step={1}
                name="deliveryTime"
                options={[
                  {
                    id: 'mins',
                    title: <Text value={translations.MINUTES} />,
                  },
                  {
                    id: 'hrs',
                    title: <Text value={translations.HOURS} />,
                  },
                ]}
                dropdown
                hideStepper
                component={Stepper}
              />
            </Label>
          </Row>
        </Row>

        <div className="flex items-center justify-end mt-4">
          <Button onClick={close} type="button" kind="tertiary" className="mx-4">
            <Text value={translations.DISMISS} />
          </Button>
          <Button kind="primary" type="submit" disabled={pending}>
            <Text value={translations.CONFIRM} />
          </Button>
        </div>
      </Form>
    </Formik>
  );
};

UpdateDeliveryTimeModal.propTypes = {
  bulkZones: PropTypes.arrayOf(
    PropTypes.shape({
      properties: PropTypes.shape({
        id: PropTypes.number,
      }),
    }),
  ),
  close: PropTypes.func.isRequired,
  setZones: PropTypes.func.isRequired,
  defaultProductPrepTime: PropTypes.number,
};

export default UpdateDeliveryTimeModal;
