import React, { useState, useEffect, useContext } from 'react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';

import { Container, Footer } from 'components/form/generic';
import { Button } from 'components/kit';
import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { context as localeContext } from 'context/locale';
import RefundOptions from '.';
import RefundedOrder from '../RefundOrder';
import { REFUND_TYPE_ENUM } from '../Table/Order/OrderDetails/OrderDetailsStatus/CancelOrderBody/data';
import { compensatedAmountValidationSchema } from './data';

const RefundOptionsModal = ({ lang, orderPaidThrough, currency, close, open, orderId, orderNumber, orderTotal }) => {
  const { translate } = useContext(localeContext);

  const [orderfastWalletRefundData, setOrderfastWalletRefundData] = useState({
    refundType: REFUND_TYPE_ENUM.ORDERFAST_WALLET,
    compensationAmount: null,
  });
  const orderTotalWithCurrecy = `${orderTotal} ${currency}`;
  const refundTo =
    orderfastWalletRefundData.refundType === REFUND_TYPE_ENUM.ORDERFAST_WALLET
      ? translate(translations.ORDERFAST_WALLET)
      : translate(translations.CREDIT_CARD);

  useEffect(() => {
    if (orderfastWalletRefundData.refundType !== REFUND_TYPE_ENUM.ORDERFAST_WALLET)
      setOrderfastWalletRefundData(prevState => ({
        ...prevState,
        compensationAmount: null,
      }));
  }, [orderfastWalletRefundData.refundType]);

  return (
    <Formik
      onSubmit={() =>
        open({
          title: (
            <Text value={translations.ORDER_REFUND_CONFIRM(orderNumber, refundTo)} className="text-black font-medium" />
          ),
          body: (
            <RefundedOrder
              onCancel={close}
              orderId={orderId}
              refundTo={refundTo}
              compensationAmount={orderfastWalletRefundData.compensationAmount}
              currency={currency}
              orderTotal={orderTotal}
              refundType={orderfastWalletRefundData.refundType}
            />
          ),
          size: 'max-w-sm',
        })
      }
      validationSchema={compensatedAmountValidationSchema}
    >
      <Form>
        <div className="px-4">
          <Container>
            <RefundOptions
              fromModal
              lang={lang}
              orderPaidThrough={orderPaidThrough}
              orderTotalWithCurrecy={orderTotalWithCurrecy}
              orderfastWalletRefundData={orderfastWalletRefundData}
              setOrderfastWalletRefundData={setOrderfastWalletRefundData}
              currency={currency}
              orderTotal={orderTotal}
            />
          </Container>
        </div>
        <Footer refund>
          <div className="ml-auto">
            <div className="w-full mt-5 flex items-center">
              <span className="mr-6">
                <Button onClick={close} type="button" kind="tertiary">
                  <Text value={translations.REFUND_LATER} />
                </Button>
              </span>
              <Button kind="secondaryError">
                <Text value={translations.PROCEED_REFUND} />
              </Button>
            </div>
          </div>
        </Footer>
      </Form>
    </Formik>
  );
};

RefundOptionsModal.propTypes = {
  lang: PropTypes.oneOf(['en', 'ar']),
  orderPaidThrough: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  open: PropTypes.func.isRequired,
  orderId: PropTypes.string.isRequired,
  orderNumber: PropTypes.string.isRequired,
  orderTotal: PropTypes.number.isRequired,
};

export default RefundOptionsModal;
