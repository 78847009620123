import { gql } from "@apollo/client"

export const CATEGORIES = gql`
  query($storeId: String!) {
    menuSections(restaurantId: $storeId) {
      menuSections {
        id
        titleAr
        titleEn
        photoUrl
        publishedBranchIds
        menuItemsCount
      }
    }
  }
`
export const ADD_CATEGORY = gql`
  mutation(
    $storeId: String!
    $titleEn: String!
    $titleAr: String!
    # $photoUrl: String
    $publishedBranchIds: [String!]
  ) {
    createMenuSection(
      restaurantId: $storeId
      titleEn: $titleEn
      titleAr: $titleAr
      # photoUrl: $photoUrl
      publishedBranchIds: $publishedBranchIds
    ) {
      id
    }
  }
`
export const UPDATE_CATEGORY = gql`
  mutation(
    $storeId: String!
    $titleEn: String!
    $titleAr: String!
    $id: String!
    $photoUrl: String
    $publishedBranchIds: [String]
  ) {
    updateMenuSection(
      restaurantId: $storeId
      titleEn: $titleEn
      titleAr: $titleAr
      menuSectionId: $id
      photoUrl: $photoUrl
      publishedBranchIds: $publishedBranchIds
    ) {
      id
    }
  }
`

export const DELETE_CATEGORY = gql`
  mutation($storeId: String!, $id: String!) {
    deleteMenuSection(restaurantId: $storeId, menuSectionId: $id) {
      id
    }
  }
`

export const PRODUCTS = gql`
  query(
    $storeId: String!
    $categoryId: String
    $productTitle: String
    $limit: Int
    $page: Int
    $lang: String
  ) {
    menuItems(
      restaurantId: $storeId
      menuSectionId: $categoryId
      productTitle: $productTitle
      limit: $limit
      page: $page
      lang: $lang
    ) {
      menuItems {
        id
        titleEn
        titleAr
        externalId
        photoUrl
        displayPrice
        unpublishedBranchesCount
        publishedBranchIds
        discountEnabled
      }
      totalCount
    }
  }
`

export const UPDATE_PRODUCT = gql`
  mutation(
    $storeId: String!
    $productId: String!
    $publishedBranchIds: [String]
  ) {
    updateMenuItem(
      restaurantId: $storeId
      menuItemId: $productId
      publishedBranchIds: $publishedBranchIds
    ) {
      id
    }
  }
`

export const UPDATE_PRODUCT_BY_BRANCH = gql`
  mutation(
    $storeId: String!
    $productId: String!
    $branchId: String!
    $published: Boolean
  ) {
    updateMenuItemForBranch(
      restaurantId: $storeId
      branchId: $branchId
      menuItemId: $productId
      published: $published
    ) {
      id
    }
  }
`

export const DELETE_PRODUCT = gql`
  mutation($storeId: String!, $id: String!) {
    deleteMenuItem(restaurantId: $storeId, menuItemId: $id) {
      id
    }
  }
`
export const SETTINGS = gql`
  query($storeId: String!) {
    settings(restaurantId: $storeId) {
      id
      menuItemSorting
    }
  }
`

export const UPDATE_SORTING_PRODUCT = gql`
  mutation($storeId: String!, $menuItemSorting: Int) {
    updateProductsSorting(
      restaurantId: $storeId
      menuItemSorting: $menuItemSorting
    ) {
      id
      menuItemSorting
    }
  }
`
