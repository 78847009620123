import React, { useContext } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Button } from '@zydalabs/zac-react';
import { Formik, Form } from 'formik';
import { lightenAndDarken } from '@zydalabs/web-utils';
import { context as notificationsContext } from 'context/notifications';
import { Text } from 'components/service';
import { context as localeContext } from 'context/locale';
import { useSelectedStore } from 'hooks/index';
import * as translations from 'constants/translations';

import { Layout, Breadcrumbs } from 'components/common/dashboard';
import { Spinner } from 'components/kit';
import { STORE_LAYOUT_LINKS } from 'constants/helperLinks';
import * as schemas from './schemas';
import LanguageSelector from './DefaultLanguage';
import FontSelector from './FontSelector';
import ColorSelector from './ColorSelector';
import LogoShapeSelector from './LogoShapeSelector';
import ButtonShape from './ButtonShape';
import HomeLayout from './HomeLayout';
import { HOME_LAYOUT_ENUMS } from './data';

const StoreTheme = () => {
  const storeId = useSelectedStore();
  const notifications = useContext(notificationsContext);
  const { direction } = useContext(localeContext);

  const [updateSettings, { loading: loadingChange }] = useMutation(schemas.UPDATE_LAYOUT, {
    onError: err => notifications.show(err.graphQLErrors[0].extensions.exception.body.store_ui[0], 'error'),
    onCompleted: () => notifications.show(<Text value={translations.STORE_LAYOUT_UPDATED} />),
  });

  const [updateRestaurantLanguage, { loading: loadingUpdateLanguage }] = useMutation(schemas.UPDATE_RESTAURANT, {
    onError: err => notifications.show(err.graphQLErrors[0].extensions.exception.body.store_ui[0], 'error'),
  });
  const { data: storeLayoutData, isLoadingStoreLayout } = useQuery(schemas.GET_LAYOUT, {
    variables: {
      storeId,
    },
  });

  const {
    data: {
      restaurant: { preferredLanguage },
    },
    loading: isLoadingStoreLanguage,
  } = useQuery(schemas.GET_RESTAURANT, {
    variables: {
      storeId,
    },
  });

  const initialValues = {
    preferredLanguage,
    menuItemsLayout: storeLayoutData && storeLayoutData.settings.storeUi.menuItemsLayout,
    menuSectionLayout: storeLayoutData && storeLayoutData.settings.storeUi.menuSectionLayout,
    primaryColor: storeLayoutData && storeLayoutData.settings.theme.palette.primaryColor?.toString(),
    fontShape: storeLayoutData && storeLayoutData.settings.storeUi.fontShape,
    isLogoRounded: storeLayoutData && storeLayoutData.settings.storeUi.logoRounded,
    isButtonRounded: storeLayoutData && storeLayoutData.settings.storeUi.buttonRounded,
  };
  return (
    <Formik
      enableReinitialize
      onSubmit={async data => {
        const primaryColor = lightenAndDarken(data.primaryColor, 20, 40);
        await updateSettings({
          variables: {
            storeId,
            menuSectionLayout: parseInt(data.menuSectionLayout),
            menuItemsLayout:
              data.menuSectionLayout === HOME_LAYOUT_ENUMS.ONE_PAGE ? HOME_LAYOUT_ENUMS.ONE_PAGE : data.menuItemsLayout,
            primaryColor,
            fontShape: data.fontShape,
            logoRounded: data.isLogoRounded,
            buttonRounded: data.isButtonRounded,
          },
        });

        await updateRestaurantLanguage({
          variables: {
            id: storeId,
            preferredLanguage: data.preferredLanguage,
          },
        });
      }}
      initialValues={initialValues}
    >
      {({ values, setFieldValue, submitForm }) => (
        <Layout
          top={
            <Breadcrumbs
              helperLinks={STORE_LAYOUT_LINKS}
              path={translations.breadcrumbs.SETUP_STORE_LAYOUT}
              right={
                <Button
                  text={<Text value={translations.SAVE} />}
                  rounded
                  variant="primary"
                  type="submit"
                  isLoading={loadingChange || loadingUpdateLanguage}
                  onClick={() => submitForm()}
                />
              }
            />
          }
        >
          {isLoadingStoreLayout || isLoadingStoreLanguage ? (
            <Spinner />
          ) : (
            <>
              <Form style={{ direction }}>
                <div className="grid grid-cols-12">
                  <div className="col-span-12 xl:col-span-8 w-full xl:px-6">
                    <LanguageSelector preferredLanguage={values.preferredLanguage} setFieldValue={setFieldValue} />
                    <FontSelector fontShape={values.fontShape} setFieldValue={setFieldValue} />
                    <ColorSelector />
                    <LogoShapeSelector logoShape={values.isLogoRounded} setFieldValue={setFieldValue} />
                    <ButtonShape buttonShape={values.isButtonRounded} setFieldValue={setFieldValue} />
                    <HomeLayout
                      menuItemsLayout={values.menuItemsLayout}
                      menuSectionLayout={values.menuSectionLayout}
                      setFieldValue={setFieldValue}
                    />
                  </div>
                  {/* <div className="hidden xl:col-span-4 bg-gray-200 h-screen w-full xl:block">Preview</div> */}
                </div>
              </Form>
            </>
          )}
        </Layout>
      )}
    </Formik>
  );
};
export default StoreTheme;
