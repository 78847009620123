import { gql } from 'graphql-request';

const suggestedDeliveryZonesQuery = gql`
  query($geoPoint: [Float!]!, $pageSize: Int, $pageNumber: Int) {
    suggestedZones(geoPoint: $geoPoint, pageSize: $pageSize, pageNumber: $pageNumber) {
      featureCollection
      meta
    }
  }
`;

export default suggestedDeliveryZonesQuery;
