import React, { useEffect, useContext } from 'react';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';

import { Row, Label } from 'components/form/generic';
import { Text, Field } from 'components/service';
import { context as localeContext } from 'context/locale';
import { Input } from 'components/form/elements';
import * as translations from 'constants/translations';

const MerchantAuthorization = ({ setIsDisabled, setAuthData }) => {
  const { lang } = useContext(localeContext);

  useEffect(() => {
    setIsDisabled(true);
  }, []);

  return (
    <Formik initialValues={{}}>
      <Form>
        <Row className="w-full">
          <Label title={<Text value={translations.API_KEY} />}>
            <Field
              type="text"
              name="apiKey"
              placeholder={lang === 'en' ? translations.API_KEY[0] : translations.API_KEY[1]}
              required
              component={Input}
              onChange={e => {
                e.target.value ? setIsDisabled(false) : setIsDisabled(true);
                e.target.value ? setAuthData({ apiKey: e.target.value }) : setAuthData({ apiKey: null });
              }}
            />
          </Label>
        </Row>
      </Form>
    </Formik>
  );
};

MerchantAuthorization.propTypes = {
  setIsDisabled: PropTypes.func.isRequired,
  setAuthData: PropTypes.func.isRequired,
};

export default MerchantAuthorization;
