import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';

import { context as userContext } from 'context/user';
import { Text } from 'components/service';
import { breadcrumbs } from 'constants/translations';
import { Layout, Breadcrumbs } from 'components/common/dashboard';
import * as translations from 'constants/translations';
import { exportInvoices } from 'rest.js';
import { Table } from 'components/form/elements';
import { Button } from 'components/kit';
import INVOICE_SCHEMA from './invoiceSchema';
import InvoicesRowTable from './InvoicesRowTable';
import InvoicesFirstColumn from './InvoicesFirstColumn';

const Invoices = () => {
  const { selectedStore } = useContext(userContext);
  const { currency, id: selectedStoreId } = selectedStore;

  const { data: invoices } = useQuery(INVOICE_SCHEMA, {
    fetchPolicy: 'network-only',
    variables: {
      storeId: selectedStoreId,
    },
  });

  return (
    <div>
      {invoices && (
        <Layout
          top={
            <Breadcrumbs
              path={breadcrumbs.Invoices_History}
              right={
                <div className="px-4 flex justify-end self-end">
                  <Button
                    onClick={() => (window.location.href = exportInvoices(selectedStoreId))}
                    type="button"
                    kind="secondry"
                  >
                    <Text value={translations.EXPORT_ALL} />
                  </Button>
                </div>
              }
            />
          }
        >
          <div>
            <div className="pb-20 flex flex-col">
              <div className="w-full">
                <Table
                  dataHeader={[
                    {
                      key: 'invoiceId',
                      name: <Text value={translations.INVOICE_NO} className="inline" />,
                      sort: false,
                    },
                    {
                      key: 'invoiceStartDate',
                      name: <Text value={translations.INVOICE_START_DATE} className="inline" />,
                      sort: false,
                    },
                    {
                      key: 'invoicePlan',
                      name: <Text value={translations.TYPE_URL} className="inline" />,
                      sort: false,
                    },

                    {
                      key: 'invoiceAmount',
                      name: <Text value={translations.INOICE_AMOUNT} className="inline" />,
                      sort: false,
                    },
                    {
                      key: 'action',
                      name: <Text value="" className="inline" />,
                      sort: false,
                    },
                  ]}
                  dataBody={invoices.storeInvoices}
                  customCol={(invoice, i) => (
                    <InvoicesFirstColumn
                      index={i}
                      key={invoice.number}
                      count={invoices.storeInvoices.length}
                      item={invoice}
                    />
                  )}
                  customRow={(item, i) => (
                    <InvoicesRowTable
                      count={invoices.storeInvoices.length}
                      key={item.number}
                      index={i}
                      item={item}
                      lowStock={invoices.storeInvoices}
                      currency={currency}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </Layout>
      )}
    </div>
  );
};

export default Invoices;
