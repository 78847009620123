import React from "react"

export default ({
  cond,
  children,
  component: Component,
  fallback: Fallback = Identity,
  ...props
}) => {
  const element = typeof children === "function" ? children() : children

  return !cond ? (
    <Fallback children={element} {...props} />
  ) : (
    <Component children={element} {...props} />
  )
}

const Identity = ({ children }) => children || false
