export const RESTAURANT_COURIER_AUTHENTICATION_TYPE = {
  BRANCH_KEY: 'branch_key',
  SINGLE_KEY: 'single_key',
  OAUTH2: 'oauth2',
};

export const RESTAURANT_ORDER_PICKUP_TYPE = {
  STORE_PICKUP: 'store_pickup',
  CAR_PICKUP: 'car_pickup',
  BOTH: 'store_and_car_pickup',
};

export const RESTAURANT_COUNTRY_CODE = {
  EGYPT: 'EG',
  KUWAIT: 'KW',
};
