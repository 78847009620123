import { useDeliveryZoneMsToken } from 'hooks';
import { Country, QueryCitiesArgs } from '../../types/generated';
import { useQuery } from '../../utils';
import { QueryHookResponse } from '../../types/internal';
import { countriesListQuery } from '../../queries/deliveryZones';

type useFetchCountriesListType = (args: QueryCitiesArgs) => QueryHookResponse<Country>;

const useFetchCountriesList: useFetchCountriesListType = args => {
  const deliveryZoneMsToken = useDeliveryZoneMsToken();
  return useQuery<Country>({
    query: countriesListQuery,
    variables: { ...args },
    requestHeaders: { token: deliveryZoneMsToken },
    handler: (res: any) => res.countries,
    config: { revalidateOnMount: true },
  });
};

export default useFetchCountriesList;
