import React from 'react';

import * as paths from 'paths.js';
import { Button } from 'components/kit';
import * as translations from 'constants/translations';
import { Text, Link } from 'components/service';

const EmptyState = () => (
  <>
    <div className="w-full flex flex-col items-center text-center mt-24">
      <Text className="text-lg mb-1 font-semibold" value={translations.BUSINESS_LOCATIONS_EMPTY_TITLE} />
      <Text className="text-gray-700 mb-6" value={translations.BUSINESS_LOCATIONS_EMPTY_DESC} />
      <Link to={paths.branchCreation}>
        <Button kind="primary">
          <Text value={translations.ADD_BUSINESS_LOCATION} />
        </Button>
      </Link>
    </div>
  </>
);

export default EmptyState;
