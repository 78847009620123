import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Polygon } from '@react-google-maps/api';

import LocalInfoBox from './LocalInfoBox';
import { selectedZoneIdUtils } from '../../utils';

const PolygonItem = ({ item, zoneController, setZoneController, handleViewPort }) => {
  const [polygon, setPolygon] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  const handleSelectedPolygon = () => {
    if (zoneController.enableDrawerMode && !zoneController.showDrawerMode && !zoneController.isBulk) {
      setIsEdit(true);
      setZoneController({
        ...zoneController,
        enableDrawerMode: false,
        isEdit: true,
        shapeRef: polygon,
        selectedZone: item,
        showZoneTemplates: false,
        prevZone: {
          paths: item.paths,
          center: null,
          radius: null,
        },
      });
      handleViewPort(item.paths);
    }
  };

  useEffect(() => {
    if (!zoneController.isEdit) {
      setIsEdit(false);
    }
  }, [zoneController]);

  const onMouseOver = () => {
    if (zoneController.enableDrawerMode && zoneController.drawerMode === null) {
      setShowInfo(true);
    }
  };

  const onMouseOut = () => {
    setShowInfo(false);
  };

  useEffect(() => {
    if (polygon) {
      const selectedItemId = JSON.parse(localStorage.getItem(selectedZoneIdUtils.SELECTEDZONEID));
      if (selectedItemId) {
        if (selectedItemId.id === item.properties.id) {
          setIsEdit(true);
          setZoneController({
            ...zoneController,
            enableDrawerMode: false,
            isEdit: true,
            shapeRef: polygon,
            selectedZone: { ...item, properties: selectedItemId },
            prevZone: {
              paths: item.paths,
              center: null,
              radius: null,
            },
          });
          handleViewPort(item.paths);
        }
      }
    }
  }, [polygon]);

  return (
    <>
      {showInfo && <LocalInfoBox item={item} />}

      <Polygon
        onLoad={ref => setPolygon(ref)}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        editable={isEdit}
        paths={item.paths}
        onClick={handleSelectedPolygon}
        options={{
          strokeColor: item.properties.fillColor,
          strokeOpacity: item.properties.strokeOpacity,
          strokeWeight: 2,
          fillColor: item.properties.fillColor,
          fillOpacity: item.properties.fillOpacity,
          zIndex: -99999,
          visible: item.properties.visibility,
        }}
      />
    </>
  );
};

PolygonItem.propTypes = {
  item: PropTypes.shape({
    properties: PropTypes.shape({
      fillColor: PropTypes.string,
      strokeOpacity: PropTypes.number,
      fillOpacity: PropTypes.number,
      id: PropTypes.number,
      visibility: PropTypes.bool,
    }),
    paths: PropTypes.arrayOf(
      PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number,
      }),
    ),
  }).isRequired,
  zoneController: PropTypes.shape({
    isEdit: PropTypes.bool,
    enableDrawerMode: PropTypes.bool,
    showDrawerMode: PropTypes.bool,
    drawerMode: PropTypes.string,
    isBulk: PropTypes.bool,
  }).isRequired,
  setZoneController: PropTypes.func.isRequired,
  handleViewPort: PropTypes.func.isRequired,
};

export default PolygonItem;
