import React, { useContext, useState } from "react"
import { Formik, Form } from "formik"
import { Mutation } from "@apollo/client/react/components"
import { Link } from "@reach/router"
import { Field, Text } from "components/service"
import { Input } from "components/form/elements"
import { Title, AuthWrapper, Wrap } from "components/common/auth"
import { Label, Container, Alert } from "components/form/generic"
import { Button } from "components/kit"
import { context as notificationsContext } from "context/notifications"
import { context as localeContext } from "context/locale"
import * as translations from "constants/translations"
import * as paths from "paths.js"
import * as schemas from "./schemas"
import * as data from "./data"
import * as utils from "./utils"
import ZYDA_Background from "assets/zyda-image.png"
import ZYDA from "assets/zyda-logo.svg"
import ZYDA_MOBILE from "assets/zyda-logo-black.svg"
import { useMobile } from "hooks/index"
import { ReactComponent as Envelop } from "assets/login-envelop.svg"
import ForgetPasswordAi from "assets/reset-pass-illustration.svg"

export default () => {
  const notifications = useContext(notificationsContext)
  const { lang, direction } = useContext(localeContext)
  const isMobile = useMobile()
  const [error, setError] = useState()

  return (
    <Mutation
      mutation={schemas.REQUEST_PASSWORD_RESET}
      onCompleted={res => notifications.show(res.requestPasswordReset)}
      onError={utils.handleErrors(setError)}
      context={{
        headers: {
          appversion: 2,
        },
      }}
    >
      {(requestReset, { loading }) => (
        <AuthWrapper image={ZYDA_Background} logo={ZYDA} lang={lang}>
          <Formik
            validationSchema={data.validationSchema}
            initialValues={data.initialValues}
            onSubmit={variables => requestReset({ variables })}
          >
            <Form
              style={{ direction }}
              className="bg-white py-8 max-w-md w-full"
            >
              <Container>
                {isMobile && (
                  <img className="absolute -mt-12" src={ZYDA_MOBILE} alt="" />
                )}
                <Label
                  title={
                    <Title>
                      <Text
                        value={translations.FORGET_PASSWORD}
                        className="text-2xl"
                      />
                    </Title>
                  }
                  subtitle={<Text value={translations.ENTER_THE_EMAIL} />}
                />
                {error && (
                  <Alert onClose={() => setError()}>
                    {error === "user_not_found" ? (
                      <Text
                        value={translations.THIS_EMAIL_NOT_REGISTERED}
                        className="inline"
                      />
                    ) : (
                      <Text value={translations.SOMETHING_WENT_WRONG} />
                    )}
                  </Alert>
                )}
                <Label
                  htmlFor="email"
                  title={
                    <Text
                      value={translations.YOUR_EMAIL_ADDRESS}
                      className="text-black"
                    />
                  }
                >
                  <Field
                    data-testid="forget-password-email"
                    type="text"
                    name="email"
                    placeholder="Ex. exm@exm.com"
                    icon={<Envelop />}
                    component={Input}
                  />
                </Label>

                <div className="w-full">
                  <Button
                    disabled={loading}
                    full
                    kind="primary"
                    type="submit"
                    data-testid="send-reset-instructions-btn"
                  >
                    <Text value={translations.SEND_RESET_INSTRUCTIONS} />
                  </Button>
                </div>
                <div className="mt-12 px-12">
                  <Link to={paths.signIn}>
                    <Button
                      full
                      kind="secondary"
                      type="button"
                      data-testid="back-to-login-btn"
                    >
                      <Text value={translations.BACK_TO_LOGIN} />
                    </Button>
                  </Link>
                </div>
              </Container>
            </Form>
          </Formik>
        </AuthWrapper>
      )}
    </Mutation>
  )
}
