import React, { useContext } from "react"
import * as R from "ramda"
import { useQuery, useMutation } from "@apollo/client"
import { navigate } from "@reach/router"
import { Text, Link } from "components/service"
import { Layout, Breadcrumbs } from "components/common/dashboard"
import { useReplaceParams } from "hooks"
import { Button, Spinner, Modal } from "components/kit"
import * as translations from "constants/translations"
import { context as notificationsContext } from "context/notifications"
import { context as localeContext } from "context/locale"
import { context as userContext } from "context/user"
import { breadcrumbs } from "constants/translations"
import { Formik, Form } from "formik"
import { Footer, Row } from "components/form/generic"
import {
  BasicDetails,
  OptionGroups,
  Variants,
  ProductImages,
  Notes,
  PreparationTime,
} from "components/common/product"
import * as paths from "paths.js"
import * as schemas from "./schemas"
import * as data from "./data"
import * as utils from "./utils"
import cx from "classnames"

export default ({ productId, storeId }) => {
  const replace = useReplaceParams()
  const { lang, direction } = useContext(localeContext)
  const { selectedStore: selected } = useContext(userContext)
  const notifications = useContext(notificationsContext)
  const handleErrorMessage = utils.handleErrors(msg =>
    notifications.show(msg, "error")
  )

  const initial = useQuery(schemas.INITIAL, {
    variables: {
      restaurantId: storeId,
      id: productId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  })

  const [updateProduct] = useMutation(schemas.UPDATE_PRODUCT, {
    onError: handleErrorMessage,
  })
  return (
    <Modal>
      {({ open, close }) => (
        <Layout
          top={
            <Breadcrumbs
              links={[paths.setup, paths.products, paths.productEdition]}
              path={breadcrumbs.SETUP_PRODUCT_EDITION}
              // right={
              //   <Row>
              //     <Button>Save changes</Button>
              //   </Row>
              // }
            />
          }
        >
          <div className={cx("flex", lang === "ar" && "flex-row-reverse")}>
            <div className="w-full md:w-2/3" style={{ direction }}>
              {!initial.data ? (
                <Spinner />
              ) : (
                <Formik
                  initialValues={R.mergeDeepRight(data.initialValues, {
                    ...initial.data.menuItem,
                    price: initial.data.menuItem.displayPrice + "",
                    publishIn: initial.data.menuItem.publishedBranchIds,
                    categories: initial.data.menuItem.menuSectionIds || [],
                    storeEn: initial.data.menuItem.variantsTitleEn,
                    storeAr: initial.data.menuItem.variantsTitleAr,
                    externalId: initial.data.menuItem.externalId,
                    noteLabelEn:
                      initial.data.menuItem.menuItemNotesLabelOverrideEn,
                    noteLabelAr:
                      initial.data.menuItem.menuItemNotesLabelOverrideAr,
                    allowAttachNotes: !!(
                      initial.data.menuItem.menuItemNotesLabelOverrideEn ||
                      initial.data.menuItem.menuItemNotesLabelOverrideAr
                    ),
                    enablePrepTime: !initial.data.variants.every(
                      i =>
                        i.prepTime === selected.defaultProductPrepTime ||
                        i.prepTime < 0
                    ),
                  })}
                  validationSchema={data.validationSchema}
                  onSubmit={async (values, { setErrors, setSubmitting }) => {
                    if (values.allowAttachNotes) {
                      let obj = {}
                      if (!values.noteLabelAr) {
                        obj.noteLabelAr = (
                          <Text value={translations.NOTE_AR_REQ} />
                        )
                      }
                      if (!values.noteLabelEn) {
                        obj.noteLabelEn = (
                          <Text value={translations.NOTE_EN_REQ} />
                        )
                      }
                      if (!R.isEmpty(obj)) {
                        setErrors(obj)
                        return setSubmitting(false)
                      }
                    }
                    await updateProduct({
                      variables: {
                        restaurantId: storeId,
                        menuItemId: productId,
                        menuSectionIds: values.categories,
                        publishedBranchIds: values.publishIn,
                        titleEn: values.titleEn,
                        titleAr: values.titleAr,
                        externalId: values.externalId,
                        descriptionEn: values.descriptionEn,
                        descriptionAr: values.descriptionAr,
                        variantsTitleAr: values.storeAr,
                        variantsTitleEn: values.storeEn,
                        noteLabelAr: values.allowAttachNotes
                          ? values.noteLabelAr
                          : null,
                        noteLabelEn: values.allowAttachNotes
                          ? values.noteLabelEn
                          : null,
                      },
                    })

                    notifications.show(
                      <Text value={translations.PRODUCT_UPDATED} />
                    )
                    navigate(replace(paths.productsCatalog, { storeId }))
                  }}
                >
                  {({ values, ...rest }) => (
                    <Form className="mt-4">
                      <div className="flex m-3">
                        <div className="p-4 bg-white max-w-4xl w-full">
                          <div className="mb-4">
                            <BasicDetails
                              isError={rest.errors}
                              isDescriptionVisible={
                                !!(values.descriptionEn || values.descriptionAr)
                              }
                            />
                          </div>
                          <div className="mb-4">
                            <Variants
                              storeId={storeId}
                              productId={productId}
                              variants={initial.data.variants}
                              productLang={values.productLang}
                              query={schemas.INITIAL}
                              isError={rest.errors}
                              setErrorField={rest.setFieldError}
                              isSectionTitleVisible={
                                !!(
                                  values.variantsTitleEn ||
                                  values.variantsTitleAr
                                )
                              }
                            />
                          </div>
                          <div className="mb-4">
                            <ProductImages
                              storeId={storeId}
                              productId={productId}
                              variants={initial.data.variants}
                              query={schemas.INITIAL}
                              open={open}
                              closeModal={close}
                            />
                          </div>
                          <div className="mb-4">
                            <OptionGroups
                              storeId={storeId}
                              variants={initial.data.variants}
                              productId={productId}
                              open={open}
                              closeModal={close}
                            />
                          </div>
                          <div className="mb-4">
                            <PreparationTime
                              variants={initial.data.variants}
                              productId={productId}
                              storeId={storeId}
                              enablePrepTime={values.enablePrepTime}
                              setValueField={rest.setFieldValue}
                            />
                          </div>
                          <div className="mb-4">
                            <Notes
                              isError={rest.errors}
                              isAllowedToAttach={values.allowAttachNotes}
                            />
                          </div>
                          {/*<div className="mb-4">
                            <StockManagement />
                          </div>
                           */}

                          <Footer>
                            <div className="flex-2">
                              <Link to={paths.productsCatalog}>
                                <Button kind="secondary" size="base">
                                  <Text value={translations.CANCEL} />
                                </Button>
                              </Link>
                            </div>
                            <div className="flex-1 flex items-stretch flex-row justify-end">
                              {/* <div className="mr-3">
                            <Button kind="secondary" size="base">
                              <Text value={translations.PREVIEW} />
                            </Button>
                          </div> */}
                              <Button
                                isSpinning={rest.isSubmitting}
                                kind="primary"
                                size="base"
                              >
                                <Text value={translations.SAVE} />
                              </Button>
                            </div>
                          </Footer>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
            </div>
          </div>
        </Layout>
      )}
    </Modal>
  )
}
