import React, { useContext } from 'react';
import { context as localeContext } from 'context/locale';
import cx from 'classnames';
import { useMobile } from 'hooks';
const Box = ({
  title,
  subtitle,
  icon,
  body,
  innerBody,
  description,
  action,
  shadow,
  note,
  flexWrapClass = true,
  displayBorderBottom = true,
  isMenu,
}) => {
  const { lang, direction } = useContext(localeContext);
  return (
    <div style={{ direction }} className={cx('bg-white w-full', shadow ? 'shadow' : 'border border-gray-300')}>
      <div
        className={cx(
          'p-4 flex items-start',
          displayBorderBottom && 'border-b',
          flexWrapClass && 'flex-wrap',
          isMenu && 'flex-col',
        )}
      >
        <div className="flex flex-col">
          <div className="font-medium text-lg flex items-center">
            {icon} {title}
          </div>
          <div>{note}</div>
        </div>
        <div
          className={cx(lang === 'ar' ? (isMenu ? 'py-3 ml-auto' : 'pr-2 mr-auto') : isMenu ? 'py-3' : 'pl-2 ml-auto')}
        >
          {action}
        </div>
        {(subtitle || description) && (
          <div className="w-full">
            {subtitle && (
              <div className={cx('text-gray-600 text-xs', lang == 'ar' ? 'text-right' : 'text-left')}>{subtitle}</div>
            )}
            {description && <div className="text-sm">{description}</div>}
          </div>
        )}
        {innerBody}
      </div>
      {body && <div className="py-2">{body}</div>}
    </div>
  );
};

export default Box;
