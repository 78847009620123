import React, { useState, useContext } from 'react';
import { SortableElement } from 'react-sortable-hoc';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import cx from 'classnames';

import { context as localeContext } from 'context/locale';
import { Text } from 'components/service';
import * as translations from 'constants/translations';
import DeleteWebLink from 'pages/setup/webLinks/Delete';
import { Button, DragHandle, Modal } from 'components/kit';
import { ReactComponent as Edit } from 'assets/editable.svg';
import { ReactComponent as Trash } from 'assets/trash.svg';
import { LinkForm } from 'components/common/WebLink';
import { webLinksTypes } from 'components/common/WebLink/data';

const Item = ({ item, onEditClick, onDeleteClick }) => {
  const [isEditing, setIsEditing] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { direction } = useContext(localeContext);
  const disableDeletingItem = item.isDisabled || item.isPartiallyDisabled;

  const handleOnDeleteClick = ({ open, close }) =>
    open({
      title: <Text value={translations.DO_YOU_WANT_DELETE_LINK} />,
      body: <DeleteWebLink item={item} onDelete={() => onDeleteClick(item)} onClose={close} />,
      size: 'max-w-md',
    });

  return (
    <Modal>
      {({ open, close }) => (
        <div className="flex border border-gray-300 py-4 mb-4 flex-col rounded-lg" style={{ direction }}>
          <div className="flex px-4 items-center justify-between">
            <div className="flex items-center">
              <DragHandle />
              <div className="flex flex-col text-sm">
                <Text className="font-medium text-black" value={item} />
                <div className="text-gray-700 capitalize">{item.linkType}</div>
              </div>
            </div>
            {isEditing ? (
              <Button onClick={() => setIsEditing(!isEditing)} type="button" kind="link">
                <Text className="text-primary-base" value={translations.CANCEL} />
              </Button>
            ) : (
              <div className="flex items-center flex-row mx-2">
                <Edit
                  className={cx(!item.isDisabled && 'cursor-pointer', 'mx-2')}
                  onClick={() => !item.isDisabled && setIsEditing(!isEditing)}
                />
                <Trash
                  className={cx(!disableDeletingItem && 'cursor-pointer', 'mx-2')}
                  onClick={() => !disableDeletingItem && handleOnDeleteClick({ open, close })}
                />
              </div>
            )}
          </div>
          {isEditing && (
            <div className="border-gray-300 p-4 border-t mt-4">
              <LinkForm
                linksTypes={webLinksTypes}
                initialValues={{
                  titleEn: item.titleEn,
                  titleAr: item.titleAr,
                  ...(!item.phoneNumber ? R.omit(['phoneNumber'], item) : item),
                }}
                isPartiallyDisabled={item?.isPartiallyDisabled}
                isSubmitting={isSubmitting}
                renderBottomPane={() => (
                  <div className="flex flex-1 justify-between">
                    <Button kind="primary" isSpinning={isSubmitting}>
                      <Text value={translations.SAVE} />
                    </Button>
                  </div>
                )}
                onSubmit={async formData => {
                  setIsSubmitting(true);
                  await onEditClick(formData);
                  setIsEditing(!isEditing);
                  setIsSubmitting(false);
                }}
              />
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};

export const linkShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  linkType: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  smsBody: PropTypes.string.isRequired,
  snapchat: PropTypes.string.isRequired,
  twitter: PropTypes.string.isRequired,
  email: PropTypes.string,
  facebook: PropTypes.string,
  instagram: PropTypes.string,
  titleAr: PropTypes.string,
  titleEn: PropTypes.string,
  url: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  isPartiallyDisabled: PropTypes.bool,
}).isRequired;

Item.propTypes = {
  item: linkShape,
  onEditClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
};

const SortableItem = SortableElement(Item);

export default SortableItem;
