import React from 'react';
import * as router from '@reach/router';
import { parse } from 'query-string';

import * as paths from 'paths.js';
import { Layout } from 'components/common/dashboard';
import { CreationBox, Button } from 'components/kit';
import { Text, Link } from 'components/service';
import * as translations from 'constants/translations';
import successIcon from 'assets/integration-success.svg';
import failureIcon from 'assets/integration-failure.svg';
import MenuImport from './MenuImport';

const icons = {
  success: successIcon,
  failure: failureIcon,
};

const statuses = {
  success: {
    title: <Text value={translations.SUCCESS} />,
    description: <Text value={translations.SUCCESS_CONNECTION} />,
    actions: [
      <Link to={paths.integrations}>
        <Button kind="secondary">
          <Text value={translations.BACK_TO_INTEGRATIONS} />
        </Button>
      </Link>,
      <MenuImport />,
    ],
  },
  failure: {
    title: <Text value={translations.FAILED} />,
    description: <Text value={translations.CONNECTION_FAILED} />,
    actions: [
      <router.Link to="/">
        <Button kind="secondary">
          <Text value={translations.GO_TO_HOMEPAGE} />
        </Button>
      </router.Link>,
    ],
  },
};

const IntegrationsStatus = () => {
  const { value = 'success' } = parse(document.location.search);

  return (
    <Layout hideSidebar>
      <div className="mt-12">
        <img className="mb-6 w-32 ml-auto mr-auto" src={icons[value]} alt="" />
        <CreationBox {...statuses[value]} />
      </div>
    </Layout>
  );
};

export default IntegrationsStatus;
