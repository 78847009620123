import React, { useState, useContext } from "react"
import {
  GoogleMap,
  useLoadScript,
  StandaloneSearchBox,
  Marker,
  Polygon,
} from "@react-google-maps/api"
import { Spinner } from "components/kit"
import _ from "lodash"
import { Input } from "components/form/elements"
import { context as localeContext } from "context/locale"
import * as translations from "constants/translations"

const { REACT_APP_GOOGLE_MAPS_KEY } = process.env

const libraries = ["places"]

export default props => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: REACT_APP_GOOGLE_MAPS_KEY,
    libraries: libraries,
  })
  const { lang } = useContext(localeContext)

  const [searchBox, setSearchBox] = useState()
  const [maps, setMaps] = useState()

  const [bounds, setBounds] = useState()
  const [center, setCenter] = useState({ lat: 41.9, lng: -87.624 })
  const [markers, setMarkers] = useState([])

  const onBoundsChanged = () => {
    setBounds(maps.getBounds())
    setCenter(maps.getCenter())
  }

  const onPlacesChanged = () => {
    if (searchBox != null) {
      const places = searchBox.getPlaces()
      const bounds = new window.google.maps.LatLngBounds()

      places.forEach(place => {
        if (place.geometry.viewport) {
          bounds.union(place.geometry.viewport)
        } else {
          bounds.extend(place.geometry.location)
        }
      })

      const nextMarkers = places.map(place => ({
        position: place.geometry.location,
      }))

      const nextCenter = _.get(nextMarkers, "0.position", center)

      setCenter(nextCenter)
      setMarkers(nextMarkers)
      // refs.map.fitBounds(bounds);
    }
  }

  const drawCircle = (point, radius, dir) => {
    var d2r = Math.PI / 180 // degrees to radians
    var r2d = 180 / Math.PI // radians to degrees
    var earthsradius = 3963 // 3963 is the radius of the earth in miles

    var points = 32

    // find the raidus in lat/lon
    var rlat = (radius / earthsradius) * r2d
    var rlng = rlat / Math.cos(point.lat() * d2r)

    var extp = new Array()
    if (dir == 1) {
      var start = 0
      var end = points + 1
    } // one extra here makes sure we connect the
    else {
      var start = points + 1
      var end = 0
    }
    for (var i = start; dir == 1 ? i < end : i > end; i = i + dir) {
      var theta = Math.PI * (i / (points / 2))
      var ey = point.lng() + rlng * Math.cos(theta) // center a + radius x * cos(theta)
      var ex = point.lat() + rlat * Math.sin(theta) // center b + radius y * sin(theta)
      extp.push(new window.google.maps.LatLng(ex, ey))
      const bounds = new window.google.maps.LatLngBounds()
      bounds.extend(extp[extp.length - 1])
    }
    return extp
  }

  const renderMap = () => {
    const LatLng = new window.google.maps.LatLng(props.center)
    const paths = [
      drawCircle(LatLng, props.distance ? props.distance[0] : 0, 1),
      drawCircle(LatLng, props.distance ? props.distance[1] : 3, -1),
    ]

    const optionsPolygon = {
      fillColor: "#c6d0fc",
      fillOpacity: 0.7,
      strokeColor: "#3852CA",
      strokeOpacity: 0.3,
      strokeWeight: 1,
      clickable: false,
      draggable: false,
      editable: false,
      geodesic: false,
      zIndex: 1,
    }

    return (
      <GoogleMap
        center={center}
        onBoundsChanged={() => onBoundsChanged()}
        {...props}
        onLoad={ref => setMaps(ref)}
      >
        {props.marker ? (
          <>
            <Marker position={props.marker} />
            <Polygon paths={paths} options={optionsPolygon} />
          </>
        ) : (
          props.searchBox && (
            <>
              <StandaloneSearchBox
                onPlacesChanged={() => onPlacesChanged()}
                //   ref={onSearchBoxMounted}
                onLoad={ref => setSearchBox(ref)}
                bounds={bounds}
                controlPosition={window.google.maps.ControlPosition.TOP_LEFT}
              >
                <div
                  className="rounded bg-white absolute"
                  style={{
                    left: "25%",
                    top: "2%",
                  }}
                >
                  <Input
                    type="text"
                    icon="search"
                    width="64"
                    wrapperHeight
                    placeholder={
                      lang === "en"
                        ? translations.SEARCH_PLACES[0]
                        : translations.SEARCH_PLACES[1]
                    }
                  />
                </div>
              </StandaloneSearchBox>
              {markers.map((marker, index) => (
                <Marker key={index} position={marker.position} />
              ))}
            </>
          )
        )}
      </GoogleMap>
    )
  }

  if (loadError) {
    return <div> Map cannot be loaded right now, sorry.</div>
  }

  return isLoaded ? renderMap() : <Spinner />
}
