import React from 'react';
import PropTypes from 'prop-types';

import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { Button } from 'components/kit';
import { SLIDE_OVER_COMPONENTS_ENUMS } from '../utils';
import CustomerCard from './CustomerCard';

const CustomerDeliveryType = ({ selectedStorefrontUser, setSlideOverComponent }) => {
  const { userName } = selectedStorefrontUser;

  const handleAddingNewAddress = e => {
    e.preventDefault();
    setSlideOverComponent({ component: SLIDE_OVER_COMPONENTS_ENUMS.CUSTOMER_ADDRESS });
  };

  return (
    <>
      <CustomerCard userName={userName} />
      <div className="p-4">
        <div className="border-b border-gray-200 pb-6">
          <Text value={translations.DELIVERY} className="text-xl font-semibold py-4" />
          <Button kind="secondary" isRounded onClick={handleAddingNewAddress}>
            <Text value={translations.ADD_NEW_ADDRESS} />
          </Button>
        </div>
        <Text value={translations.PICKUP} className="text-xl font-semibold py-4" />
        <Button kind="secondary" isRounded>
          <Text value={translations.SELECT_BRANCH} />
        </Button>
      </div>
    </>
  );
};

CustomerDeliveryType.propTypes = {
  selectedStorefrontUser: PropTypes.shape({
    phoneNumber: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
  }),
  setSlideOverComponent: PropTypes.func.isRequired,
};

export default CustomerDeliveryType;
