import React, { useContext, useMemo, useState, useEffect } from "react"
import cx from "classnames"
import { useMobile } from "hooks/index"
import { context as localeContext } from "context/locale"
import { DropDown } from "components/kit"
import { Text } from "components/service"
import * as translations from "constants/translations"
import { Input } from "components/form/elements"
import { ReactComponent as SearchIcon } from "assets/search.svg"
import { debounce } from "lodash"
import { CUSTOMERS_SEARCH_BY_OPTIONS } from "constants/customers"

const SearchFilter = ({ query, setQuery }) => {
  const [searchState, setSearchState] = useState({
    searchValue: query.searchValue,
    searchBy: query.searchBy,
  })
  const { lang } = useContext(localeContext)
  const isMobile = useMobile()
  const phoneNumberRegex = /^\+?[0-9]*$/i
  // \u0621 represents ء and \u064A represents ي, This allows arabic characters in regex
  const userNameRegex = /([A-Za-z\u0621-\u064A] ?)+/i
  const [isValid, setIsValid] = useState(true)
  const handleSearch = value => {
    const trimmedValue = value.trim()
    let isValid = false
    if (searchState.searchBy === CUSTOMERS_SEARCH_BY_OPTIONS.PHONE_NUMBER)
      isValid = phoneNumberRegex.test(trimmedValue)
    if (searchState.searchBy === CUSTOMERS_SEARCH_BY_OPTIONS.USER_NAME)
      isValid = userNameRegex.test(trimmedValue)
    if (isValid || trimmedValue === "") {
      setSearchState({ ...searchState, searchValue: trimmedValue })
      setIsValid(true)
    } else {
      setIsValid(false)
    }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useMemo(() => debounce(handleSearch, 600), [
    searchState.searchBy,
    searchState.searchValue,
  ])

  useEffect(() => {
    let isMounted = true
    if (
      isMounted &&
      (query.searchBy !== searchState.searchBy ||
        query.searchValue !== searchState.searchValue)
    ) {
      setQuery({
        searchValue: searchState.searchValue.trim(),
        searchBy: searchState.searchBy,
        page: 1,
      })
    }
    return () => {
      debounceSearch.cancel()
      isMounted = false
    }
  }, [searchState.searchValue, searchState.searchBy]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="grid">
      <div
        className={cx(
          "w-92 flex mb-6",
          lang === "ar" && "flex-row-reverse",
          !isMobile && (lang === "ar" ? "ml-5" : "mr-5"),
          "border rounded-none border-gray-300"
        )}
      >
        <DropDown
          float={"left"}
          optionSelected={searchState.searchBy}
          onSelect={option =>
            setSearchState({ searchValue: "", searchBy: option })
          }
          testId_openDropDown="select-search-type"
          testId_selectedData="selected-search-type"
          testId_listData="search-type-list"
          data={[
            {
              id: CUSTOMERS_SEARCH_BY_OPTIONS.PHONE_NUMBER,
              title: <Text value={translations.PHONE} />,
            },
            {
              id: CUSTOMERS_SEARCH_BY_OPTIONS.USER_NAME,
              title: <Text value={translations.CUSTOMER_NAME} />,
            },
          ]}
          icon="keyboard_arrow_down"
          position={lang === "ar" ? "right" : "left"}
          noBorder
          bg
        />

        <Input
          type="text"
          data-testid="search-text"
          noBorder
          search
          pattern={
            query.searchBy === CUSTOMERS_SEARCH_BY_OPTIONS.PHONE_NUMBER
              ? phoneNumberRegex
              : userNameRegex
          }
          icon={<SearchIcon />}
          height="h-full"
          value={query.searchValue}
          inputPadding="px-2"
          iconPadding="p-0"
          onChange={e => debounceSearch(e.target.value)}
          placeholder={
            lang === "en"
              ? translations.SEARCH_CUSTOMERS[0]
              : translations.SEARCH_CUSTOMERS[1]
          }
        />
      </div>
      {!isValid && (
        <div>
          <p className="pt-2 text-red-500">
            {query.searchBy === CUSTOMERS_SEARCH_BY_OPTIONS.PHONE_NUMBER
              ? lang === "en"
                ? translations.PHONE_IS_INVALID[0]
                : translations.PHONE_IS_INVALID[1]
              : lang === "en"
              ? translations.CUSTOMER_NAME_IS_INVALID[0]
              : translations.CUSTOMER_NAME_IS_INVALID[1]}
          </p>
        </div>
      )}
    </div>
  )
}

export default SearchFilter
