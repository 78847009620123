import React from "react"
import { Wrap } from "components/common/onboarding"

export default () => {
  return (
    <Wrap currentStep={1}>
      <div className="w-full flex flex-col m-4 max-w-4xl w-full bg-white shadow-md rounded px-8 py-6">
        <div className="mb-4 pb-3 text-2xl font-medium border-b border-gray-300">
          Getting started
        </div>
        <>
          <Item
            title="Add Your Products"
            description="Easily add all your products, set prices, upload photos and customize with variants and extras."
          />
          <Item
            title="Add Locations & Delivery Areas"
            description="Add your locations and configure delivery areas, time and fees."
          />
          <Item
            title="Add custom domain"
            description="Register or bring your own domain easily for more branded experience."
          />
          <Item
            title="Activate Online Payment"
            description="Offer the choice of online payment to your customers with KNET, MADA, Visa and Master Card"
          />
        </>
      </div>
    </Wrap>
  )
}

const Item = ({ title, description }) => {
  return (
    <div className="mb-5">
      <div className="mb-1 font-semibold text-lg text-blue-700">{title}</div>
      <div className="">{description}</div>
    </div>
  )
}
