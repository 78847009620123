import moment from 'moment';
import DATE_RANGES from 'constants/date';

export const dateQueryFormat = 'YYYY-MM-DD';

export const parseRange = (queryRange, startDate) => {
  const defaultRange = !queryRange && getRange(DATE_RANGES.ALL, startDate);

  try {
    const range = JSON.parse(decodeURIComponent(queryRange));

    /**
     * Checking that range from query string is having correct format. Otherwise
     * returning default range.
     */
    if (range.length !== 2 || range.some(item => !moment(item, dateQueryFormat).isValid())) {
      return defaultRange;
    }

    return range;
  } catch (err) {
    return defaultRange;
  }
};

export const stringifyRange = range => encodeURIComponent(JSON.stringify(range));

export const formatDate = date => moment(date, dateQueryFormat).format('MMM D, YYYY');

export const getRange = (value, startDate) => {
  const [amount, unit] = value.split('_');
  if (value === DATE_RANGES.TODAY) {
    return [
      moment()
        .startOf('day')
        .format('YYYY-MM-DD'),
      moment().format('YYYY-MM-DD'),
    ];
  } else if (value === DATE_RANGES.ALL_TIME || value === DATE_RANGES.ALL) {
    return [moment(startDate).format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
  } else {
    return [
      moment()
        .subtract(amount - 1, unit)
        .format(dateQueryFormat),
      moment().format(dateQueryFormat),
    ];
  }
};

export const toRangeVariable = range => ({
  start: range[0],
  end: range[1],
});
