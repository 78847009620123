import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { context as userContext } from 'context/user';
import { context as localeContext } from 'context/locale';
import { COUNTRY_CODES_ENUM } from 'utils/enums';
import { generateAddressElements } from './helpers';

const Address = ({ avenue, block, building, floor, street, unitNo, unitType, area, zoneName, cityName, areaName }) => {
  const { lang } = useContext(localeContext);
  const {
    selectedStore: { countryCode },
  } = useContext(userContext);
  const isEgyptOrSaudi = countryCode === COUNTRY_CODES_ENUM.EGYPT || countryCode === COUNTRY_CODES_ENUM.SAUDI_ARABIA;
  const addressElements = generateAddressElements({
    ...(!isEgyptOrSaudi && { avenue }),
    block,
    building,
    floor,
    street,
    unitNo,
    unitType,
    lang,
    area,
    zoneName,
    cityName,
    areaName,
  });
  return addressElements.map(element => <p>{element}</p>);
};

Address.propTypes = {
  avenue: PropTypes.string,
  block: PropTypes.string,
  building: PropTypes.string,
  floor: PropTypes.string,
  street: PropTypes.string,
  unitNo: PropTypes.string,
  unitType: PropTypes.string,
  area: PropTypes.shape({
    titleEn: PropTypes.string,
    titleAr: PropTypes.string,
    cityTitleAr: PropTypes.string,
    cityTitleEn: PropTypes.string,
  }),
  zoneName: PropTypes.string,
  cityName: PropTypes.string,
  areaName: PropTypes.string,
};

export default Address;
