import React from 'react';
import * as yup from 'yup';

import { Text } from 'components/service';
import * as translations from 'constants/translations';

export const CUSTOMER_ADDRESS_ENUMS = {
  HOUSE: 'house',
  APARTMENT: 'apartment',
  OFFICE: 'office',
};

export const CUSTOMER_ADDRESS_FIELDS = {
  SELECTED_PLACE: 'selectedPlace',
  AREA: 'area',
  STREET: 'street',
  BLOCK: 'block',
  AVENUE: 'avenue',
  BUILDING: 'building',
  FLOOR: 'floor',
  SPECIAL_DIRECTION: 'specialDirection',
};
export const initialValues = {
  selectedPlace: CUSTOMER_ADDRESS_ENUMS.HOUSE,
  area: '',
  street: '',
  block: '',
  avenue: '',
  building: '',
  floor: '',
  specialDirection: '',
};

export const validationSchema = yup.object().shape({
  selectedPlace: yup
    .string()
    .oneOf([CUSTOMER_ADDRESS_ENUMS.HOUSE, CUSTOMER_ADDRESS_ENUMS.APARTMENT, CUSTOMER_ADDRESS_ENUMS.OFFICE]),
  area: yup.string().required(<Text value={translations.AREA_IS_REQUIRED} />),
  street: yup.string().required(<Text value={translations.STREET_IS_REQUIRED} />),
  block: yup.string().required(<Text value={translations.BLOCK_IS_REQUIRED} />),
  avenue: yup.string().nullable(),
  building: yup.string().required(<Text value={translations.BUILDING_IS_REQUIRED} />),
  floor: yup.string().required(<Text value={translations.FLOOR_IS_REQUIRED} />),
  specialDirection: yup.string().nullable(),
});
