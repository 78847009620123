import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { zoneNotificationsTypeUtils } from '../../utils';

const ZoneNotification = ({ title, type, setZoneController }) => {
  useEffect(() => {
    setTimeout(() => {
      setZoneController(prevState => ({
        ...prevState,
        deleteZoneNotify: false,
        updateZoneNotify: false,
        addZoneNotify: false,
      }));
    }, 2000);
  }, []);

  return (
    <div
      className={`flex justify-between align-center bg-white p-4 mb-4 shadow ${
        type === zoneNotificationsTypeUtils.ERROR ? 'text-red-600' : 'text-green-600'
      }`}
    >
      {title}
      <span className="material-icons">check_circle</span>
    </div>
  );
};

ZoneNotification.propTypes = {
  title: PropTypes.element.isRequired,
  type: PropTypes.string.isRequired,
  setZoneController: PropTypes.func.isRequired,
};

export default ZoneNotification;
