import React, { useState, useContext } from "react"
import { context as localeContext } from "context/locale"
import { context as userContext } from "context/user"
import { breadcrumbs } from "constants/translations"
import { Layout, Breadcrumbs } from "components/common/dashboard"
import { useQuery } from "@apollo/client"
import { Text, Link } from "components/service"
import * as translations from "constants/translations"
import { Button, Stack, Spinner } from "components/kit"
import { usePriceFormat, useSelectedStore } from "hooks"
import * as paths from "paths.js"
import cx from "classnames"
import { ReactComponent as Gears } from "assets/gears.svg"
import { ReactComponent as CampaignMessage } from "assets/campaign-message.svg"
import { ReactComponent as Reward } from "assets/reward.svg"
import * as schemas from "./schemas"
import { purchasePackage } from "../../../../rest"
import AccessDenied from "../AccessDenied"
import { CAMPAIGN_PERMISSIONS } from 'constants/users';

export default () => {
  const { direction } = useContext(localeContext)
  const { campaignPermission } = useContext(userContext)
  const selectedStoreId = useSelectedStore()
  
  const isSupportedPlan =
    campaignPermission !== CAMPAIGN_PERMISSIONS.NOT_SUPPORTED_PLAN && campaignPermission !== CAMPAIGN_PERMISSIONS.FALSE;

  const { data, loading } = useQuery(schemas.SMS_PACKAGES, {
    variables: { storeId: selectedStoreId },
  })

  const boxShadow = "0 5px 40px 0 rgba(56, 82, 202, 0.1)"

  return (
    <Layout
      top={
        <Breadcrumbs
          links={[paths.marketing, paths.campaigns]}
          path={breadcrumbs.MARKETING_CAMPAIGNS}
        />
      }
    >
      <div style={{ direction }}>
        {!isSupportedPlan ? (
          <AccessDenied />
        ) : (
          <div className="flex flex-col items-center px-4 pt-6 pb-20">
            <div className="w-full flex flex-col mb-12">
              <Text
                value={translations.START_YOUR_FIRST_CAMPAIGN}
                className="font-semibold text-2xl mb-2"
              />
              <Text
                value={translations.YOU_CAN_START_ADDING_CAMPAING}
                className="text-gray-700 text-sm mb-6"
              />
              <Stack direction="wrap" spacing="6">
                <div
                  style={{ boxShadow, minWidth: "250px", minHeight: "160px" }}
                  className="rounded p-6 flex flex-col "
                >
                  <Gears className="fill-current text-primary-base mb-2" />
                  <Text
                    value={translations.SETUP_YOUR_CAMPAIGN}
                    className="font-semibold mb-2"
                  />
                  <Text
                    value={translations.SETUP_YOUR_CAMPAIN_DESC}
                    className="text-gray-700 text-sm"
                  />
                </div>
                <div
                  style={{ boxShadow, minWidth: "250px", minHeight: "160px" }}
                  className="rounded p-6 flex flex-col"
                >
                  <CampaignMessage className="fill-current text-primary-base mb-2" />
                  <Text
                    value={translations.ADD_MARKETING_MESSAGE}
                    className="font-semibold mb-2"
                  />
                  <Text
                    value={translations.ADD_MARKETING_MESSAGE_DESC}
                    className="text-gray-700 text-sm"
                  />
                </div>
                <div
                  style={{ boxShadow, minWidth: "250px", minHeight: "160px" }}
                  className="rounded p-6 flex flex-col"
                >
                  <Reward className="fill-current text-primary-base mb-2" />
                  <Text
                    value={translations.RETAIN_ENGAGE}
                    className="font-semibold mb-2"
                  />
                  <Text
                    value={translations.RETAIN_ENGAGE_DESC}
                    className="text-gray-700 text-sm"
                  />
                </div>
              </Stack>
            </div>
            <div className="flex flex-col w-full">
              <Text
                value={translations.SELECT_PACKAGE_SMS}
                className="font-semibold text-lg mb-6"
              />
              {loading ? (
                <Spinner />
              ) : (
                <Stack
                  direction="wrap"
                  spacing="3"
                  flexGrow="grow md:flex-grow-0"
                >
                  {data?.smsPackages.map(item => (
                    <SMSPackage {...item} />
                  ))}
                </Stack>
              )}

              <Link
                className={cx(
                  "mt-4",
                  direction === "ltr" ? "ml-auto" : "mr-auto"
                )}
                to={paths.campaigns}
              >
                <Text
                  value={translations.SKIP}
                  full
                  postfix=">"
                  className="text-gray-700 font-semibold"
                />
              </Link>
            </div>
          </div>
        )}
      </div>
    </Layout>
  )
}

const SMSPackage = ({ id, price, total, credit, kind }) => {
  const selectedStoreId = useSelectedStore()
  const formatPrice = usePriceFormat()
  return (
    <div
      className="flex flex-col items-center py-8 rounded border border-gray-300 w-auto md:w-60"
      style={{ minWidth: "200px" }}
    >
       <span className="text-gray-500 line-through mb-2">
        {formatPrice(price)}
      </span>
      <span className="text-primary-base mb-2 text-lg">
        {formatPrice(total)}
      </span>
      <div className="text-2xl font-bold mb-4">
        {credit}
        <Text
          value={translations.CREDITS}
          className="text-xs text-gray-700 mx-1 inline"
        />
      </div>
      <Button
        kind={id === 3 ? "primary" : "secondary"}
        onClick={() =>
          (window.location.href = purchasePackage(selectedStoreId, id))
        }
      >
        <Text value={translations.PURCHASE} />
      </Button>
    </div>
  )
}
