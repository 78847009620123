import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@zydalabs/zac-react';
import { FileIcon } from '@zydalabs/zac-icons-react';

import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { Spinner } from 'components/kit';
import { context as customersContext } from 'context/customers';
import { context as localeContext } from 'context/locale';

import CampaignContactsImport from './CampaignContactsImport';
import CampaignContactsFileValidation from './CampaignContactsFileValidation';
import { fileControllerUtils } from './utils';

const CampaignContacts = ({ setFieldValue }) => {
  const { translate } = useContext(localeContext);
  const { fileController, setFileController } = useContext(customersContext);

  return (
    <div className="bg-white relative">
      {fileController.uploadingFileLoading && (
        <div className="flex flex-col items-center justify-center text-center p-12">
          <Spinner />
          <Text value={translations.UPLOADING_FILE_NOTE} className="text-zyda-grey-500 mt-4" />
        </div>
      )}

      {!fileController.uploadingFileLoading && !fileController.uploadingFile && (
        <CampaignContactsImport setFileController={setFileController} />
      )}

      {!fileController.uploadingFileLoading && fileController.uploadingFile && !fileController.isFileValidated && (
        <CampaignContactsFileValidation setFieldValue={setFieldValue} />
      )}

      {fileController.isFileValidated && (
        <div className="p-4">
          <div className="bg-gray-100 p-3 flex items-center justify-between">
            <div className="flex items-start">
              <FileIcon width="22" color="rgba(31, 31, 31, 0.35)" />
              <p className="mx-2">{fileController.uploadingFile.name}</p>
            </div>
            <p>{fileController.uploadingFileSize}</p>
          </div>

          <div className="flex justify-end mt-12">
            <Button
              onClick={() => {
                setFileController(fileControllerUtils);
              }}
              type="button"
              variant="tertiary"
              size="medium"
              rounded
              text={translate(translations.UPLOAD_ANOTHER_FILE)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

CampaignContacts.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
};

export default CampaignContacts;
