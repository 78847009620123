import React, { useContext, useState, useEffect } from "react"
import { SortableContainer, SortableElement } from "react-sortable-hoc"
import { useQuery, useMutation } from "@apollo/client"
import arrayMove from "array-move"
import cx from "classnames"
import { useSelectedStore } from "hooks/index"
import productPlaceholder from "assets/product-place-holder.svg"
import { context as localeContext } from "context/locale"
import { context as userContext } from "context/user"
import { useMobile } from "hooks/index"
import { Input } from "components/form/elements"
import { Text, When } from "components/service"
import { Spinner, DragHandle, LoadingContainer, Button } from "components/kit"
import * as translations from "constants/translations"
import * as schemas from "./schemas"

export default ({
  children,
  items,
  selectedCategoryId,
  selectedBranchId,
  onSelect,
  onSort,
  langSelected,
}) => {
  const [search, setSearch] = useState("")
  const [localItems, setLocalItems] = useState(items)
  const [isSavingSorting, setSavingSorting] = useState(false)
  const [isSorting, setSorting] = useState(false)
  const { lang, translate, direction } = useContext(localeContext)
  const storeId = useSelectedStore()
  const isMobile = useMobile()

  useEffect(() => setLocalItems(items), [items])

  const { data } = useQuery(schemas.PRODUCTS, {
    variables: {
      storeId,
    },
    fetchPolicy: "cache-and-network",
  })
  const [sort] = useMutation(schemas.SORT_CATEGORIES, {
    variables: {
      storeId,
    },
  })

  return (
    <div
      className={cx(
        "flex mx-3 border-gray-300",
        !isMobile && "border rounded",
        lang === "ar" && "flex-row-reverse"
      )}
    >
      <div
        className={cx(
          "hidden md:block w-1/3 py-6 flex-shrink-0",
          lang === "en" ? "border-r border-gray-300" : "border-l"
        )}
      >
        {!data || !localItems ? (
          <Spinner />
        ) : (
          <>
            <div
              className="flex items-center mb-4 px-4 text-lg font-semibold h-8"
              style={{ direction }}
              data-testid="count-categories"
            >
              <Text
                value={translations.CATEGORIES_COUNT}
                payload={localItems.length}
              />
              {!isSorting && (
                <div className="ml-auto">
                  <Button onClick={() => setSorting(true)} kind="secondary">
                    <Text value={translations.SORT} />
                  </Button>
                </div>
              )}
            </div>
            <div className="px-3 mb-4">
              {isSorting ? (
                <div className="flex justify-between">
                  <div className="w-full px-1">
                    <Button
                      size="full"
                      onClick={() => {
                        setLocalItems(items)
                        setSorting(false)
                      }}
                    >
                      <Text value={translations.CANCEL} />
                    </Button>
                  </div>
                  <div className="w-full px-1">
                    <Button
                      kind="primary"
                      size="full"
                      isSpinning={isSavingSorting}
                      onClick={async () => {
                        onSort(localItems)

                        const positions = toPositions(localItems)
                        setSavingSorting(true)

                        await sort({
                          variables: {
                            positions,
                          },
                        })

                        setSavingSorting(false)
                        setSorting(false)
                      }}
                    >
                      <Text value={translations.SAVE} />
                    </Button>
                  </div>
                </div>
              ) : (
                <Input
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                  placeholder={translate(translations.SEARCH_CATEGORIES)}
                  data-testid="search-categories"
                  search
                />
              )}
            </div>
            <div className="border border-gray-300 rounded m-2 md:rounded-none md:border-none md:m-0 cursor-pointer overflow-auto max-h-screen">
              <Item
                isSelected={selectedCategoryId === "all"}
                title={
                  <Text
                    selectedLang={langSelected}
                    value={translations.ALL_CATEGORIES}
                  />
                }
                desc={`${data.menuItems.totalCount} ${translate(
                  translations.PRODUCTS,
                  langSelected
                )}`}
                onClick={() => onSelect("all")}
                langSelected={langSelected}
                testId="all-categories"
              />
              <LoadingContainer isLoading={isSavingSorting}>
                <When
                  cond={isSorting}
                  component={SortableItems}
                  fallback={Items}
                  isSorting={isSorting}
                  items={localItems}
                  search={search}
                  langSelected={langSelected}
                  selectedCategoryId={selectedCategoryId}
                  selectedBranchId={selectedBranchId}
                  useDragHandle
                  onSelect={onSelect}
                  onSortEnd={async ({ oldIndex, newIndex }) => {
                    const sorted = arrayMove(localItems, oldIndex, newIndex)
                    setLocalItems(sorted)
                  }}
                />
              </LoadingContainer>
            </div>
          </>
        )}
      </div>
      <div className="w-full">{children}</div>
    </div>
  )
}

const Items = ({
  isSorting,
  items,
  search,
  selectedCategoryId,
  selectedBranchId,
  onSelect,
  langSelected,
}) => {
  const { translate, lang } = useContext(localeContext)
  const { branches } = useContext(userContext)

  return (
    <div>
      {items
        .filter(item =>
          new RegExp(search.replace(/[\^$\\.*+?()[\]{}|]/g, "\\$&"), "i").test(
            lang === "en" ? item.titleEn : item.titleAr
          )
        )
        .map((item, i) => (
          <When
            cond={isSorting}
            component={SortableItem}
            fallback={Item}
            testId={`category-list-item-${i}`}
            key={item.id}
            index={i}
            isDraggable={isSorting && !search}
            langSelected={langSelected}
            lang={lang}
            onClick={() => onSelect(item.id)}
            isSelected={selectedCategoryId === item.id}
            title={<Text value={item} selectedLang={langSelected} />}
            photoUrl={item.photoUrl || productPlaceholder}
            desc={`${item.menuItemsCount} ${translate(
              translations.PRODUCTS,
              langSelected
            )} • ${published(
              selectedBranchId,
              branches.length,
              item.publishedBranchIds,
              translate,
              langSelected
            )}`}
          />
        ))}
    </div>
  )
}

const Item = ({
  title,
  desc,
  isSelected,
  photoUrl,
  isDraggable,
  onClick,
  langSelected,
  lang,
  testId,
}) => {
  const direction = langSelected === "ar" ? "rtl" : "ltr"
  return (
    <div
      className={cx(
        "flex items-center w-full px-4 py-4",
        isSelected ? "bg-primary-75" : "hover:bg-gray-200",
        langSelected === "ar" && "flex-row-reverse"
      )}
      onClick={onClick}
      data-testid={testId}
      name={
        title.props.value instanceof Array
          ? title.props.value[langSelected === "en" ? 0 : 1]
          : langSelected === "en"
          ? title.props.value.titleEn
          : title.props.value.titleAr
      }
    >
      {isDraggable && <DragHandle />}
      {photoUrl && (
        <img
          className={cx("w-10 h-10", langSelected === "ar" ? "ml-3" : "mr-3")}
          alt=""
          src={photoUrl}
        />
      )}
      <div>
        <span
          className={cx(
            "w-full block font-semibold",
            langSelected === "en" && lang === "ar" && "text-left",
            langSelected === "ar" && lang === "en" && "text-right"
          )}
        >
          {title}
        </span>
        <span className="block text-xs text-gray-700">
          <span
            data-testid="category-count-status"
            className="w-full flex"
            style={{ direction }}
          >
            {desc}
          </span>
        </span>
      </div>
    </div>
  )
}

const SortableItem = SortableElement(Item)
const SortableItems = SortableContainer(Items)

const published = (
  selectedBranchId,
  branchCount,
  publishedIds,
  translate,
  langSelected
) => {
  if (selectedBranchId !== "all") {
    return translate(
      publishedIds.includes(selectedBranchId)
        ? translations.PUBLISHED
        : translations.UNPUBLISHED
    )
  }

  if (branchCount === publishedIds.length)
    return translate(translations.PUBLISHED, langSelected)

  return translate(
    translations.UNPUBLISHED_IN_BUSINESS_LOCATION,
    langSelected,
    branchCount - publishedIds.length
  )
}

const toPositions = items =>
  items.map((item, i) => ({
    id: item.id,
    position: i,
  }))
