import React from 'react';
import { InfoIcon } from '@zydalabs/zac-icons-react';
import PropTypes from 'prop-types';

import { Text } from 'components/service';
import { ORDERFAST_WALLET_DISCLAMER, REFUND_STATMENT } from 'constants/translations';

const RefundDisclamer = ({ isWallet }) => (
  <div className="flex flex-row bg-yellow-100 border-yellow-200 text-black rounded border p-3">
    <InfoIcon width="20" height="20" color="#B48A00" />
    <Text value={isWallet ? ORDERFAST_WALLET_DISCLAMER : REFUND_STATMENT} className="px-3 text-sm" />
  </div>
);

RefundDisclamer.propTypes = {
  isWallet: PropTypes.bool.isRequired,
};
export default RefundDisclamer;
