import React, { useContext } from 'react';
import cx from 'classnames';

import { context as localeContext } from 'context/locale';

const LangSwitch = () => {
  const { lang, direction, changeLang } = useContext(localeContext);
  const itemClassName = 'w-1/2 flex items-center justify-center py-1 px-2 rounded text-xs items';

  return (
    <div style={{ direction }} className="w-full flex p-1 rounded bg-gray-300 items-center">
      <div className="w-full rounded bg-gray-300 flex cursor-pointer">
        <span
          className={cx(itemClassName, lang === 'en' && 'bg-white')}
          onClick={() => {
            changeLang('en');
          }}
          data-testid="switch-language-english"
        >
          English
        </span>
        <span
          className={cx(itemClassName, lang === 'ar' && 'bg-white')}
          onClick={() => {
            changeLang('ar');
          }}
          data-testid="switch-language-arabic"
        >
          عربى
        </span>
      </div>
    </div>
  );
};
export default LangSwitch;
