import React, { useContext } from "react"
import { Button, Stack, More, Modal } from "components/kit"
import * as translations from "constants/translations"
import { context as localeContext } from "context/locale"
import { breadcrumbs } from "constants/translations"
import { Breadcrumbs } from "components/common/dashboard"
import { Text, Link } from "components/service"
import { getBackUrl } from "utils/history"
import * as paths from "paths.js"
import cx from "classnames"
import DeleteZone from "./DeleteZone"

export default ({ branchId, storeId, zoneId, isSubmitting, next, prev }) => {
  const { lang, direction } = useContext(localeContext)
  return (
    <Modal>
      {({ open, close }) => (
        <div
          className={cx(
            "flex flex-wrap items-center z-10 sticky top-0 bg-white",
            lang === "ar" ? "-mr-3 flex-row-reverse" : "-ml-3"
          )}
        >
          <Breadcrumbs
            links={[paths.setup, paths.deliveryList]}
            path={breadcrumbs.SETUP_DELIVERY_EDITION}
            customTitle={
              <Text
                className="text-2xl"
                value={translations.ZONE}
                postfix={"#" + zoneId}
              />
            }
          />
          <Stack
            className={cx(
              lang === "ar" ? "mr-auto flex-row-reverse" : "ml-auto"
            )}
            expandItem={false}
            direction="wrap"
            spacing="2"
          >
            <Button kind="primary" isSpinning={isSubmitting}>
              <Text value={translations.SAVE_CHANGE} />
            </Button>
            <Link to={getBackUrl() || paths.deliveryList} omitQuery>
              <Button type="button" kind="secondary">
                <Text value={translations.CANCEL} />
              </Button>
            </Link>
            <More
              items={[
                {
                  title: <Text value={translations.DELETE} />,
                  onClick: () =>
                    open({
                      title: (
                        <Text
                          value={translations.ZONE}
                          postfix={"#" + zoneId}
                        />
                      ),
                      body: (
                        <DeleteZone
                          branchId={branchId}
                          storeId={storeId}
                          zoneId={zoneId}
                          onClose={close}
                          refetch={false}
                        />
                      ),
                      size: "max-w-md",
                    }),
                },
              ]}
            />
          </Stack>
        </div>
      )}
    </Modal>
  )
}
