import React from 'react';
import PropTypes from 'prop-types';

import { Text } from 'components/service';
import * as translations from 'constants/translations';

const PhoneNumberList = ({ phoneNumbersList, handleOnSelectingUser }) => (
  <div className="py-2">
    <div className="flex px-6">
      <Text value={translations.CUSTOMERS} className="text-gray-400 text-base" />
    </div>
    {phoneNumbersList.map(user => {
      const { phoneNumber, userName } = user || {};
      return (
        phoneNumber &&
        userName && (
          <div
            role="presentation"
            className="py-3 border-b border-gray-300 hover:bg-gray-100 cursor-pointer"
            onClick={() => handleOnSelectingUser(user)}
          >
            <div className="px-6">
              <div className="flex text-base leading-6 font-normal">{userName}</div>
              <div className="flex pb-1 text-sm leading-4 font-extralight text-gray-500">{phoneNumber}</div>
            </div>
          </div>
        )
      );
    })}
  </div>
);

PhoneNumberList.propTypes = {
  phoneNumbersList: PropTypes.arrayOf(PropTypes.string),
  handleOnSelectingUser: PropTypes.func.isRequired,
};
export default PhoneNumberList;
