import React, { useContext, useState, useEffect } from "react"
import axios from "axios"
import { Field, Text } from "components/service"
import * as translations from "constants/translations"
import { Input, InfoCheckbox } from "components/form/elements"
import { Uploader, Modal } from "components/kit"
import { Label, Row, Container } from "components/form/generic"
import { removePaymentLicense, removePaymentAuthorizedSignatory } from "rest.js"

export default ({
  values,
  lang,
  initialValues,
  close,
  open,
  storeId,
  setFieldValue,
  handleChange,
}) => {
  const licenseTokenSource = axios.CancelToken.source()
  const authorizedSignatoryTokenSource = axios.CancelToken.source()

  return (
    <div className="mb-6">
      <Field
        title={<Text value={translations.LICENSED_BUSINESS} />}
        description={<Text value={translations.ENABLE_LICENCE} />}
        testId="payments-register-enableLicense"
        component={InfoCheckbox}
        onChange={e => {
          setFieldValue("accountType", e.target.value ? "corp" : "ind")
          handleChange(e)
        }}
        name="isLicensed"
        body={
          values.isLicensed && (
            <div className="px-4 pb-3">
              <Container>
                <Row className="flex-col md:flex-row">
                  <Label title={<Text value={translations.COMPANY_NAME} />}>
                    <Field
                      type="text"
                      name="name"
                      data-testid="payment-register-companyName"
                      placeholder={
                        translations.ENTER_YOUR_COMPANY_NAME[
                          lang === "en" ? 0 : 1
                        ]
                      }
                      component={Input}
                    />
                  </Label>
                  <Label title={<Text value={translations.LICENCE_NUM} />}>
                    <Field
                      type="text"
                      name="licenseNumber"
                      data-testid="payment-register-licenseNumber"
                      placeholder={translations.EX_444[lang === "en" ? 0 : 1]}
                      component={Input}
                    />
                  </Label>
                </Row>
                <Row className="flex-col md:flex-row">
                  <Label title={<Text value={translations.LICENCE_COPY} />}>
                    <Field
                      name="LicenseCopy"
                      component={Uploader}
                      imageUrl={values.LicenseCopy}
                      size="small"
                      direction="row"
                      onRemove={() => setFieldValue("LicenseCopy", "")}
                      info={<Text value={translations.UPLOAD_DRAG} />}
                      justify="start"
                      onUpload={image => setFieldValue("LicenseCopy", image)}
                      onCancel={() => licenseTokenSource.cancel()}
                      close={close}
                      testId_BtnUpload="payment-register-LicenseCopy"
                      open={open}
                      accept="image/*"
                    />
                  </Label>
                  <Label
                    title={<Text value={translations.AUTHORIZED_SIGNATORY} />}
                    subtitle={
                      <Text
                        value={translations.AUTHORIZED_SIGNATORY_SUBTITLE}
                      />
                    }
                  >
                    <Field
                      name="authorizedSignatoryCopy"
                      testId_BtnUpload="payment-register-authorizedSignatoryCopy"
                      component={Uploader}
                      imageUrl={values.authorizedSignatoryCopy}
                      size="small"
                      direction="row"
                      onRemove={() =>
                        setFieldValue("authorizedSignatoryCopy", "")
                      }
                      info={<Text value={translations.UPLOAD_DRAG} />}
                      justify="start"
                      onUpload={image =>
                        setFieldValue("authorizedSignatoryCopy", image)
                      }
                      onCancel={() => authorizedSignatoryTokenSource.cancel()}
                      close={close}
                      open={open}
                      accept="image/*"
                    />
                  </Label>
                </Row>
              </Container>
            </div>
          )
        }
      />
    </div>
  )
}
