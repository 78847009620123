import React, { useContext } from 'react';
import { Form, Formik } from 'formik';
import moment from 'moment';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { Label, Container, Footer } from 'components/form/generic';
import { Field, Text } from 'components/service';
import { Input, DatePicker, RadioList, RadioGroup } from 'components/form/elements';
import * as translations from 'constants/translations';
import { Button } from 'components/kit';
import { context as localeContext } from 'context/locale';
import { TIME_FORMAT } from '../../../../../components/common/branch/Busy/utils';
import validationSchema from './validationSchema';
import useAddProgram from '../../hooks/useAddProgram';
import CashbackProgram from '../CashbackProgram';
import logger from '../../../../../common/utils/logger';

const AddCashBack = ({ onCancel, onComplete }) => {
  const { lang, direction, translate } = useContext(localeContext);
  const [addProgram] = useAddProgram();

  moment.locale('en');

  return (
    <Formik
      initialValues={{
        percentage: '15',
        hasEndDate: false,
        startsAt: new Date(),
      }}
      validationSchema={validationSchema}
      onSubmit={async formData => {
        try {
          await addProgram(formData);
        } catch (e) {
          logger.error(e);
        }
        onComplete();
      }}
    >
      {({ values, setFieldValue }) => (
        <Form style={{ direction }}>
          <div className="px-4 my-4 w-full">
            <Container>
              <div className={cx('w-full mb-6 md:mb-0', lang === 'en' ? 'mr-0 md:mr-4' : 'ml-0 md:ml-4')}>
                <Label title={<Text value={translations.PROGRAME_NAME} />}>
                  <Field
                    type="text"
                    data-testid="cashback-program-add-name"
                    name="name"
                    component={Input}
                    placeholder={translate(translations.ENTER_CASH_BACK_PROGRAM_NAME)}
                  />
                </Label>
              </div>
              <div className="w-full mb-0 md:mb-0">
                <Label title={<Text value={translations.START_DATE} />}>
                  <Field
                    key="startDate"
                    name="startsAt"
                    minDate={new Date()}
                    component={DatePicker}
                    dateFormat={TIME_FORMAT.DATE_FORMAT}
                    containerClassName="flex-col w-full"
                    allowFutureDates
                  />
                </Label>
              </div>
              <div className="w-full mb-0 md:mb-0">
                <Field
                  name="hasEndDate"
                  component={RadioList}
                  size="sm"
                  onChange={({ target }) => {
                    setFieldValue('hasEndDate', target?.value);
                    if (target?.value === false) setFieldValue('endsAt', undefined);
                  }}
                  items={[
                    {
                      value: false,
                      title: <Text value={translations.RUN_PROGRAM_FOREVER} className="text-sm" />,
                    },
                    {
                      value: true,
                      title: <Text value={translations.CHOOSE_END_DATE} className="text-sm" />,
                    },
                  ]}
                />
              </div>
              {values?.hasEndDate && (
                <div className="w-full mb-0 md:mb-0">
                  <Label title={<Text value={translations.END_DATE} />}>
                    <Field
                      key="endsAt"
                      name="endsAt"
                      minDate={
                        values.startsAt
                          ? moment(values.startsAt)
                              .add(1, 'days')
                              .valueOf()
                          : new Date()
                      }
                      component={DatePicker}
                      dateFormat={TIME_FORMAT.DATE_FORMAT}
                      containerClassName="flex-col w-full"
                      allowFutureDates
                    />
                  </Label>
                </div>
              )}
              <div className="w-full mb-0 md:mb-0">
                <Field
                  key="percentage"
                  name="percentage"
                  component={RadioGroup}
                  items={['10', '15', '20', '25'].map(value => ({
                    value,
                    title: <Text value={[`${value} %`, ` ${Number(value).toLocaleString('ar-KW')} ٪`]} />,
                  }))}
                  containerClassName={`mb-4 ${lang === 'ar' ? 'ml-4' : 'mr-4'}`}
                />
              </div>
            </Container>
            <Text value={translations.SELECT_PERCENTAGE} className="text-xs text-zyda-grey-500 pt-2" />
          </div>
          <Footer modalFooter>
            <div className="flex flex-col w-full">
              <Text
                value={translations.PREVIOUS_CASHBACK_WILL_BE_DEACTIVATED}
                className="text-xs text-zyda-grey-500 mb-4"
              />
              <div className="flex justify-between">
                <Button data-testid="add-cancel-category-btn" type="button" kind="tertiary" onClick={onCancel}>
                  <Text value={translations.CANCEL} />
                </Button>
                <Button data-testid="add-optionGroup-btn" kind="primary" isSpinning={false} className="flex">
                  <Text value={translations.ADD} />
                </Button>
              </div>
            </div>
          </Footer>
        </Form>
      )}
    </Formik>
  );
};

CashbackProgram.propTypes = {
  onClick: PropTypes.func,
  onComplete: PropTypes.func,
};

export default AddCashBack;
