import React, { useContext, useState } from 'react';
import { parse } from 'query-string';
import { Formik, Form } from 'formik';
import { Mutation } from '@apollo/client/react/components';
import { navigate } from '@reach/router';

import { Field, Text } from 'components/service';
import { Input } from 'components/form/elements';
import { AuthWrapper, Title } from 'components/common/auth';
import { Label, Container, Alert } from 'components/form/generic';
import { Button } from 'components/kit';
import { context as localeContext } from 'context/locale';
import * as translations from 'constants/translations';
import ZYDA_Background from 'assets/zyda-image.png';
import ZYDA from 'assets/zyda-logo.svg';
import ZYDA_MOBILE from 'assets/zyda-logo-black.svg';
import { useMobile } from 'hooks/index';
import { ReactComponent as Eye } from 'assets/eye.svg';
import { forgetPassword } from 'paths';
import * as schemas from './schemas';
import * as utils from './utils';
import * as data from './data';

export default props => {
  const { lang, direction } = useContext(localeContext);

  const [error, setError] = useState();
  const isMobile = useMobile();
  const { email } = parse(props.location.search.replace(/%20/g, '%2B'));
  const [showNewPassword, setShowNewPassword] = useState('password');
  const [showConfirmedPassword, setShowConfirmedPassword] = useState('password');

  const showPasswordNew = () => {
    setShowNewPassword(state => (state === 'password' ? 'input' : 'password'));
  };

  const showPasswordConfirm = () => {
    setShowConfirmedPassword(state => (state === 'password' ? 'input' : 'password'));
  };

  return (
    <Mutation
      mutation={schemas.PERFORM_PASSWORD_RESET}
      onCompleted={res => navigate('/sign-in')}
      onError={utils.handleErrors(setError)}
    >
      {(reset, { loading }) => (
        <AuthWrapper image={ZYDA_Background} logo={ZYDA} lang={lang}>
          <Formik
            validationSchema={data.validationSchema}
            initialValues={data.initialValues}
            onSubmit={values => {
              error === 'RESET_TOKEN_EXPIRED'
                ? navigate(forgetPassword)
                : reset({
                    variables: {
                      ...values,
                      email,
                      resetToken: props.resetToken,
                    },
                  });
            }}
          >
            <Form style={{ direction }} className="bg-white py-8 max-w-md w-full">
              <Container>
                {isMobile && <img className="absolute -mt-12" src={ZYDA_MOBILE} alt="" />}
                <Label
                  title={
                    <Title>
                      <Text value={translations.RESET_PASSWORD} />
                    </Title>
                  }
                  subtitle={<Text value={translations.PLEASE_ENTER_NEW_PASSWORD} />}
                />
                {error && (
                  <Alert onClose={() => setError()}>
                    {error === 'EMAIL_NOT_REGISTERED' ? (
                      <>
                        <Text value={translations.THIS_EMAIL_NOT_REGISTERED} className="inline" />
                      </>
                    ) : error === 'RESET_TOKEN_EXPIRED' ? (
                      <>
                        <Text value={translations.RESET_TOKEN_HAS_EXPIRED} className="inline" />
                      </>
                    ) : error === 'EMAIL_IS_REQURIED' ? (
                      <>
                        <Text value={translations.EMAIL_IS_REQ} className="inline" />
                      </>
                    ) : (
                      <Text value={translations.SOMETHING_WENT_WRONG} />
                    )}
                  </Alert>
                )}
                <Label htmlFor="password" title={<Text value={translations.NEW_PASSWORD} />}>
                  <Field
                    type={showNewPassword}
                    data-testid="new-password"
                    placeholder={lang === 'en' ? translations.PASSWORD[0] : translations.PASSWORD[1]}
                    name="password"
                    icon={<Eye />}
                    after={true}
                    component={Input}
                    showPassword={showPasswordNew}
                  />
                </Label>
                <Label htmlFor="confirmation" title={<Text value={translations.REPEAT_NEW_PASSWORD} />}>
                  <Field
                    type={showConfirmedPassword}
                    data-testid="confirm-new-password"
                    name="passwordConfirmation"
                    icon={<Eye />}
                    after={true}
                    placeholder={
                      lang === 'en' ? translations.PASSWORD_CONFIRMATION[0] : translations.PASSWORD_CONFIRMATION[1]
                    }
                    component={Input}
                    showPassword={showPasswordConfirm}
                  />
                </Label>

                <div className="w-full">
                  <Button disabled={loading} full kind="primary" type="submit" data-testid="reset-password-btn">
                    <Text
                      value={
                        error === 'RESET_TOKEN_EXPIRED'
                          ? translations.RETURN_TO_RESET_PASSWORD
                          : translations.RESET_PASSWORD
                      }
                    />
                  </Button>
                </div>
              </Container>
            </Form>
          </Formik>
        </AuthWrapper>
      )}
    </Mutation>
  );
};
