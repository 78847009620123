import { useEffect } from 'react';
import { isNil } from 'ramda';
import useSWR, { useSWRConfig, SWRConfiguration } from 'swr';

import logger from 'common/utils/logger';
import { Handler, QueryHookResponse } from '../types/internal';
import { stringify } from './misc';
import { fetcher } from './fetch';

// TODO  :: Add an error handler

type UseQueryParams<Data, Error> = {
  query: string;
  variables: unknown;
  requestHeaders?: Record<string, string>;
  clientName?: string;
  handler?: Handler<Data>;
  initialData?: Data;
  config?: SWRConfiguration<Data, Error>;
};

export function useQuery<Data, Error = any>({
  query,
  variables,
  // TODO check if we need this default value or not
  requestHeaders = {},
  clientName,
  handler,
  config,
}: UseQueryParams<Data, Error>): QueryHookResponse<Data, Error> {
  // it Will make the call in case the variables is not null only
  let key;
  if (variables) {
    if (!isNil(requestHeaders)) {
      key = [query, stringify(variables), stringify(requestHeaders)];
    } else {
      key = [query, stringify(variables)];
    }
  } else {
    key = null;
  }

  const { revalidateOnMount, ...restConfig } = config || {};

  const { cache } = useSWRConfig();

  const { data, error, ...rest } = useSWR<Data, Error>(
    key,
    (q: string, v: string) =>
      fetcher({
        query: q,
        variables: v,
        clientName,
        requestHeaders,
        handler,
      }),
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      revalidateOnMount: revalidateOnMount || cache.get(key) !== undefined,
      ...restConfig,
    },
  );
  useEffect(() => {
    if (error) {
      const err = JSON.stringify({ ...error, requestSchema: query, variables }, undefined, 2);
      // TODO  :: Replace with ErrorHandler
      const parsedErr = JSON.parse(err);
      const errorResponse = parsedErr?.response;
      logger.error({
        error: errorResponse,
        extraData: { requestError: parsedErr, error: errorResponse, technicalIssue: true },
      });
    }
  }, [error]);

  return { data, error, isLoading: variables && isNil(data), ...rest };
}
