import React from 'react';
import cx from 'classnames';

import { useMobile } from 'hooks';

const InvoicesFirstColumn = ({ item, count, index }) => {
  const isMobile = useMobile();
  const maxWidth = isMobile ? '10rem' : '20rem';

  return (
    <tr key={item.number}>
      <td
        className={cx('px-4 border-b border-gray-300 truncate', count - 1 === index ? 'pt-3 pb-8' : 'py-3')}
        style={{ maxWidth }}
      >
        <div className="cursor-default flex items-center border border-transparent w-40  px-2 -mx-2 h-10 ">
          {item.number}
        </div>
      </td>
    </tr>
  );
};

export default InvoicesFirstColumn;
