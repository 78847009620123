import React, { useEffect } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { Text, Field } from 'components/service';
import { Label, Row } from 'components/form/generic';
import { RadioChaine } from 'components/form/elements';
import * as translations from 'constants/translations';
import ItemTrackingTable from 'components/common/product/ItemTracking/ItemTrackingTable';
import { handleTrackingItemsDisplayButtons } from './utils';

const ItemTrackingType = ({
  lang,
  isMobile,
  setFieldValue,
  trackingType,
  descriptionType,
  dataBranches,
  selectedId,
  variants,
  productId,
  createMenuItem,
  updateProduct,
  titleEn,
  titleAr,
}) => {
  const className = cx(isMobile && 'text-xs');
  useEffect(() => {
    if (!productId && trackingType !== 'none') {
      createMenuItem();
    }
  }, [trackingType, productId]);

  const handleChangeTrackingType = async e => {
    if (productId) {
      await updateProduct({
        variables: {
          restaurantId: selectedId,
          titleEn,
          titleAr,
          menuItemId: productId,
          trackingType: e.target.value,
        },
      });
    }
    setFieldValue('trackingType', e.target.value);
  };
  return (
    <div>
      <Row className="pt-4">
        <Text
          value={translations.ITEM_TRACKING}
          className={cx('font-medium text-base', lang === 'ar' && 'text-right')}
        />
      </Row>
      <Row>
        <Label htmlFor="primaryColor" trackedItem>
          <Field
            onChange={e => handleChangeTrackingType(e)}
            direction="col"
            name="trackingType"
            value={trackingType}
            items={handleTrackingItemsDisplayButtons(Text, className)}
            trackedItem
            component={RadioChaine}
            size="flex_center"
          />
        </Label>
      </Row>
      <Row className={cx('text-gray-700 pt-2 pb-4', isMobile && 'text-sm')}>
        <Text value={descriptionType} />
      </Row>
      <Row className="font-normal">
        <Text value={translations.BRANCH_PER_VARIANT} />
      </Row>
      {dataBranches?.branches.map((branch, branchIndex) => (
        <Row className="py-2">
          <div
            key={branch.id}
            className="rounded-none bg-white w-full border border-gray-300 inline-block last:border-b-0"
          >
            <div className="p-4 flex flex-wrap items-start border-b border-gray-300">
              <div className="flex items-center font-medium">{lang === 'en' ? branch.titleEn : branch.titleAr}</div>
            </div>
            <ItemTrackingTable
              branchIndex={branchIndex}
              branch={branch}
              storeId={selectedId}
              variants={variants}
              productId={productId}
              trackingType={trackingType}
            />
          </div>
        </Row>
      ))}
    </div>
  );
};

ItemTrackingType.propTypes = {
  lang: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  trackingType: PropTypes.string.isRequired,
  descriptionType: PropTypes.string.isRequired,
  dataBranches: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        titleAr: PropTypes.string.isRequired,
        titleEn: PropTypes.string.isRequired,
      }),
    ),
  ).isRequired,
  selectedId: PropTypes.string.isRequired,
  variants: PropTypes.arrayOf(
    PropTypes.shape({
      barCode: PropTypes.string,
      discountEnabled: PropTypes.bool.isRequired,
      discountedPrice: PropTypes.number,
      externalId: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      images: PropTypes.arrayOf(PropTypes.string).isRequired,
      prepTime: PropTypes.number.isRequired,
      price: PropTypes.number.isRequired,
      propertySections: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.object)),
      sku: PropTypes.string,
      titleAr: PropTypes.string.isRequired,
      titleEn: PropTypes.string.isRequired,
    }),
  ).isRequired,
  productId: PropTypes.string.isRequired,
  createMenuItem: PropTypes.func.isRequired,
  updateProduct: PropTypes.func.isRequired,
  titleEn: PropTypes.string.isRequired,
  titleAr: PropTypes.string.isRequired,
};

export default ItemTrackingType;
