import React, { useState, useRef, useContext } from 'react';
import { Formik } from 'formik';
import { useMutation } from '@apollo/client';

import { useSelectedStore } from 'hooks';
import { context as notificationsContext } from 'context/notifications';
import { initialValues, validationSchema } from './data';
import SlideOverWizard from './SlideOverWizard';
import { CREATE_STOREFRONT_USERS } from './schemas';
import { SLIDE_OVER_COMPONENTS_ENUMS, handleErrorMessages } from './utils';

const ManualOrder = () => {
  const storeId = useSelectedStore();
  const notifications = useContext(notificationsContext);
  const formikRef = useRef();

  const [slideOverComponent, setSlideOverComponent] = useState({
    component: SLIDE_OVER_COMPONENTS_ENUMS.CUSTOMER_INFO,
  });

  const [selectedStorefrontUser, setSelectedStorefrontUser] = useState(null);

  const [createCustomer, { loading: isCreatingUser }] = useMutation(CREATE_STOREFRONT_USERS, {
    onCompleted: data => {
      if (data && data.createStorefrontUser) {
        setSelectedStorefrontUser(data.createStorefrontUser);
      }
      setSlideOverComponent({ component: SLIDE_OVER_COMPONENTS_ENUMS.SF_WEBVIEW });
    },
    onError: err => handleErrorMessages(err, notifications, formikRef),
  });

  const onSubmit = async data => {
    const { phoneNumber, userName } = data || {};
    await createCustomer({
      variables: {
        storeId,
        phoneNumber,
        userName,
      },
    });
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} ref={formikRef}>
      {({ values, setFieldValue, submitForm }) => (
        <SlideOverWizard
          slideOverComponent={slideOverComponent}
          setSlideOverComponent={setSlideOverComponent}
          phoneNumber={values.phoneNumber}
          userName={values.userName}
          setFieldValue={setFieldValue}
          submitForm={submitForm}
          isCreatingUser={isCreatingUser}
          selectedStorefrontUser={selectedStorefrontUser}
          setSelectedStorefrontUser={setSelectedStorefrontUser}
        />
      )}
    </Formik>
  );
};

export default ManualOrder;
