import React from 'react';
import {
  ERROR_MSG_GOOGLE_ANALYTICS,
  ERROR_MSG_FACEBOOK_PIXELS,
  ERROR_MSG_SNAPCHAT_PIXELS,
  ERROR_MSG_GTM,
  LEARN_HOW_TO_GET_THIS_CODE_HERE,
} from 'constants/translations';

import { Text } from 'components/service';
import * as yup from 'yup';
import PostfixLink from './PostfixLink';

const trackingAndSocialMediaValidationSchema = yup.object().shape({
  gaTrackId: yup
    .string()
    .matches(
      /^(UA-\d+-\d+|G-\w+)$/i,
      <Text
        value={ERROR_MSG_GOOGLE_ANALYTICS}
        postfixElement={
          <PostfixLink
            url="https://support.google.com/analytics/answer/10269537?hl=en"
            urlAr="https://support.google.com/analytics/answer/10269537?hl=ar"
            text={LEARN_HOW_TO_GET_THIS_CODE_HERE}
          />
        }
      />,
    ),
  fbadTrackId: yup.string().matches(/^[\d]{15,16}$/i, <Text value={ERROR_MSG_FACEBOOK_PIXELS} />),
  snapTrackId: yup
    .string()
    .matches(
      /^[a-z0-9]{8}-+[a-z0-9]{4}-+[a-z0-9]{4}-+[a-z0-9]{4}-+[a-z0-9]{12}$/,
      <Text value={ERROR_MSG_SNAPCHAT_PIXELS} />,
    ),
  gtmTrackId: yup.string().matches(/^[A-Z]{3}-+[A-Z0-9]{7}$/, <Text value={ERROR_MSG_GTM} />),
});

export default trackingAndSocialMediaValidationSchema;
