import { gql } from '@apollo/client';

const SALES_BREAKDOWN = gql`
  query(
    $storeId: String!
    $date: AnalyticsPrecision!
    $range: AnalyticsRange!
    $page: Int!
    $limit: Int!
    $sort: String!
  ) {
    salesBreakdown(restaurantId: $storeId, date: $date, range: $range, page: $page, limit: $limit, sort: $sort) {
      startDate
      endDate
      data {
        date
        totalSales
        totalOrders
        avgOrderValue
        totalCustomers
      }
      totalCount
      totalPages
    }
  }
`;

export default SALES_BREAKDOWN;
