import React, { useContext, useState } from "react"
import axios from "axios"
import * as R from "ramda"
import { Mutation } from "@apollo/client/react/components"
import { useQuery } from "@apollo/client"
import { context as userContext } from "context/user"
import { context as notificationsContext } from "context/notifications"
import { context as localeContext } from "context/locale"
import { Text } from "components/service"
import * as translations from "constants/translations"
import { Layout } from "components/common/dashboard"
import PaymentCredentialsForm from "components/common/PaymentCredentialsForm"
import { Modal } from "components/kit"
import { PAYMENT_ENUMS } from "../Root/enums"

import cx from "classnames"
import { Spinner } from "components/kit"
import * as schemas from "./schemas"
import { uploadPaymentImages } from "rest.js"
import TopPane from "./TopPane.js"
import { useReplaceParams, useSelectedStore } from "hooks"
import { navigate } from "@reach/router"
import * as paths from "paths.js"

const frontTokenSource = axios.CancelToken.source()
const backTokenSource = axios.CancelToken.source()
const licenseTokenSource = axios.CancelToken.source()
const authorizedSignatoryTokenSource = axios.CancelToken.source()

export default ({ paymentId }) => {
  // const [paymentCredentialsId, setPaymentCredentialsId] = useState("")

  const [loadingImages, setLoadingImages] = useState(false)
  const { lang } = useContext(localeContext)
  const notifications = useContext(notificationsContext)
  const { selectedStore } = useContext(userContext)
  const storeId = useSelectedStore()
  const replace = useReplaceParams()

  const { data: paymentData, loading: loadingPaymentCredentials } = useQuery(
    schemas.PAYMENT_CREDENTIALS,
    {
      variables: { storeId: selectedStore.id },
      fetchPolicy: "network-only",
    }
  )
  const { paymentCredentials } = !loadingPaymentCredentials && paymentData
  const pushedToGateway = !loadingPaymentCredentials && paymentCredentials.merchantId
  const isIntegrationFromGateway = !loadingPaymentCredentials && paymentCredentials.paymentGateway?.integrationFromGateway
  const CreditCardId = PAYMENT_ENUMS['CREDIT_CARD']
  const paymentMethodReferenceId = isIntegrationFromGateway && paymentCredentials.restaurantPaymentMethods.find(method => method.id === CreditCardId )?.referenceId

  let updateVariables = (data) => {
    if (isIntegrationFromGateway) {
      return {
        merchantId: data.merchantId,
        apiKey: data.apiKey,
        iframeId: data.iframeId,
        cardIntegrationId: data.cardIntegrationId
      }
    }
    if (pushedToGateway) {
      return {
        iban: data.iban,
        bankName: data.bankName,
        paymentGatewayId: data.paymentGatewayId,
        bankId: parseInt(data.bankId),
        paymentDepositTermId: parseInt(data.paymentDepositTermId),
        beneficiaryAddress: data.beneficiaryAddress,
        bankAddress: data.bankAddress,
        swiftCode: data.swiftCode,
        accountName: data.accountName,
        accountNumber: data.accountNumber
      }
    }

    return {
      iban: data.iban,
      bankName: data.bankName,
      bankId: parseInt(data.bankId),
      paymentDepositTermId: parseInt(data.paymentDepositTermId),
      paymentGatewayId: data.paymentGatewayId,
      beneficiaryAddress: data.beneficiaryAddress,
      bankAddress: data.bankAddress,
      swiftCode: data.swiftCode,
      accountName: data.accountName,
      accountNumber: data.accountNumber,
      profileName: data.profileName,
      firstName: data.firstName,
      lastName: data.lastName,
      emailAddress: data.emailAddress,
      password: data.password,
      mobileNumber: data.mobileNumber,
      idNumber: data.idNumber,
      name: data.name,
      licenseNumber: data.licenseNumber,
      accountType: data.accountType,
      isLicensed: data.isLicensed,
      nationalFrontCopyUrl:
        data.nationalFrontId instanceof File
          ? null
          : data.nationalFrontCopyUrl,
      nationalBackCopyUrl:
        data.nationalBackId instanceof File
          ? null
          : data.nationalBackCopyUrl,
      licenseCopyUrl:
        data.LicenseCopy instanceof File
          ? null
          : data.licenseCopyUrl,
      authorizedSignatoryCopyUrl:
        data.authorizedSignatoryCopy instanceof File
          ? null
          : data.authorizedSignatoryCopyUrl,
    }
  }
  return (
    <Modal>
      {({ open, close }) => (
        <Mutation mutation={schemas.UPDATE_PAYMENT_CREDENTIALS}>
          {(updatePaymentCredentials, { loading }) => (
            <Layout>
              <div
                className={cx(
                  "pb-6 flex",
                  lang === "ar" && "flex-row-reverse"
                )}
              >
                <div className="w-full md:w-2/3">
                  {loadingPaymentCredentials && !paymentCredentials ? (
                    <Spinner />
                  ) : (
                    <PaymentCredentialsForm
                      renderTopPane={({ isSubmitting }) => (
                        <TopPane
                          storeId={selectedStore.id}
                          isSubmitting={isSubmitting || loadingImages}
                          paymentCredentialsId={paymentCredentials.id}
                        />
                      )}
                      pushedToGateway={pushedToGateway}
                      isIntegrationFromGateway={isIntegrationFromGateway}
                      isSubmitting={loading}
                      open={open}
                      close={close}
                      paymentCredentialsId={paymentCredentials.id}
                      initialValues={{
                        profileName: paymentCredentials.profileName,
                        firstName: paymentCredentials.contactInfo.firstName,
                        lastName: paymentCredentials.contactInfo.lastName,
                        emailAddress:
                          paymentCredentials.contactInfo.emailAddress,
                        password: paymentCredentials.contactInfo.password,
                        mobileNumber:
                          paymentCredentials.contactInfo.mobileNumber,
                        idNumber: paymentCredentials.contactInfo.idNumber,
                        iban: paymentCredentials.bankAccountInfo.iban,
                        bankName: paymentCredentials.bankAccountInfo.bankName,
                        bankId: paymentCredentials.bankAccountInfo.bankId,
                        paymentDepositTermId: paymentCredentials.paymentDepositTerm?.id,
                        paymentGatewayId: paymentCredentials.paymentGateway.id,
                        accountNumber:
                          paymentCredentials.bankAccountInfo.accountNumber,
                        bankAddress:
                          paymentCredentials.bankAccountInfo.bankAddress,
                        beneficiaryAddress:
                          paymentCredentials.bankAccountInfo.beneficiaryAddress,
                        swiftCode: paymentCredentials.bankAccountInfo.swiftCode,
                        accountName:
                          paymentCredentials.bankAccountInfo.accountName,
                        accountType: paymentCredentials.accountType,
                        isLicensed: paymentCredentials.isLicensed
                          ? true
                          : false,
                        name: paymentCredentials.corporateInfo.name,
                        licenseNumber:
                          paymentCredentials.corporateInfo.licenseNumber,
                        paymentMethods: paymentCredentials.paymentMethods,
                        nationalFrontId: paymentCredentials.idFrontCopyUrl,
                        nationalBackId: paymentCredentials.idBackCopyUrl,
                        LicenseCopy: paymentCredentials.licenseCopyUrl,
                        authorizedSignatoryCopy:
                          paymentCredentials.authorizedSignatoryCopyUrl,
                        isKW: selectedStore.countryCode !== "KW",
                        hasIBAN: selectedStore.countryCode !== "EG",
                        merchantId: paymentCredentials.merchantId,
                        apiKey: paymentCredentials.apiKey,
                        iframeId: paymentCredentials.iframeId,
                        cardIntegrationId: paymentMethodReferenceId
                      }}
                      onSubmit={data => {
                        updatePaymentCredentials({
                          variables: {
                            ...updateVariables(data),
                            restaurantId: selectedStore.id.toString(),
                            id: paymentCredentials.id,
                          },
                        })
                          .then(res => {
                            if (!pushedToGateway) {
                              setLoadingImages(true)
                              Promise.all(
                                [
                                  data.nationalBackId instanceof File &&
                                    uploadPaymentImages(
                                      storeId,
                                      paymentCredentials.id,
                                      { back: data.nationalBackId },
                                      backTokenSource.token
                                    ),
                                  data.nationalFrontId instanceof File &&
                                    uploadPaymentImages(
                                      storeId,
                                      paymentCredentials.id,
                                      { front: data.nationalFrontId },
                                      frontTokenSource.token
                                    ),
                                  data.LicenseCopy instanceof File &&
                                    uploadPaymentImages(
                                      storeId,
                                      paymentCredentials.id,
                                      { license: data.LicenseCopy },
                                      licenseTokenSource.token
                                    ),
                                  data.authorizedSignatoryCopy instanceof File &&
                                    uploadPaymentImages(
                                      storeId,
                                      paymentCredentials.id,
                                      {
                                        authorizedSignatory:
                                          data.authorizedSignatoryCopy,
                                      },
                                      authorizedSignatoryTokenSource.token
                                    ),
                                ].filter(R.identity)
                              )
                                .then(res => {
                                  setLoadingImages(false)
                                  notifications.show(
                                    <Text
                                      value={
                                        translations.STORE_INFORMATION_UPDATED
                                      }
                                    />
                                  )
                                  navigate(
                                    replace(paths.paymentGateways, { storeId })
                                  )
                                })
                                .catch(err => console.log("err : ", err))
                              } else {
                                notifications.show(
                                  <Text
                                    value={
                                      translations.STORE_INFORMATION_UPDATED
                                    }
                                  />
                                )
                                navigate(
                                  replace(paths.paymentGateways, { storeId })
                                )
                              }
                          })
                          .catch(err => {
                            Object.keys(
                              err.graphQLErrors[0].extensions.exception.body
                            ).forEach(error => {
                              const value =
                                err.graphQLErrors[0].extensions.exception.body[
                                  error
                                ]

                              notifications.show(
                                `${error} ${value[0]}`,
                                "error"
                              )
                            })
                          })
                      }}
                    />
                  )}
                </div>
              </div>
            </Layout>
          )}
        </Mutation>
      )}
    </Modal>
  )
}
