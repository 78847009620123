import { gql } from '@apollo/client';

export const CONNECTED_POS_BRANCHES = gql`
  query($storeId: String!) {
    connectedPosBranches(storeId: $storeId) {
      branchInternalId
      branchExternalId
      partnerName
    }
  }
`;

export const BRANCHES = gql`
  query Branches($restaurantId: String!) {
    branches(restaurantId: $restaurantId) {
      id
      titleEn
      titleAr
    }
  }
`;

export const INITIATE_DELIVERECT_BRANCHES_LINKING = gql`
  mutation initiateDeliverectBranchesLinking($storeId: String!, $branches: [DeliverectBranches]) {
    initiateDeliverectBranchesLinking(storeId: $storeId, branches: $branches) {
      message
    }
  }
`;

export const UPDATE_DELIVERECT_CONFIGURATION = gql`
  mutation updateDeliverectConfiguration($storeId: String!, $isTaxInclusive: Boolean) {
    updateDeliverectConfiguration(storeId: $storeId, isTaxInclusive: $isTaxInclusive) {
      isTaxInclusive
    }
  }
`;
