import React, { useContext } from 'react';
import cx from 'classnames';

import { context as localeContext } from 'context/locale';
import { ReactComponent as AlertCircle } from 'assets/Icons_Warning.svg';
import { FREE_DURING_BETA } from 'constants/translations';

const BetaAlert = ({ onClose }) => {
  const { lang } = useContext(localeContext);

  return (
    <div className="w-full bg-zyda-yellow-20 flex" dir={lang === 'en' ? 'ltr' : 'rtl'}>
      <p className="h-10 text-sm pl-4 leading-9 align-middle flex-grow">
        <span>
          <AlertCircle className="text-zyda-orange-200 fill-current inline mr-2" style={{ transform: 'scale(0.75)' }} />
        </span>
        {lang === 'en' ? FREE_DURING_BETA[0] : FREE_DURING_BETA[1]}
      </p>
      <button
        onClick={onClose}
        type="button"
        className={cx(lang === 'en' ? 'float-right pr-3' : 'float-left pl-3', 'material-icons text-lg order-last')}
      >
        close
      </button>
    </div>
  );
};

export default BetaAlert;
