import { gql } from "@apollo/client"

export const BRANCHES = gql`
  query Branches($restaurantId: String!) {
    branches(restaurantId: $restaurantId) {
      id
      titleEn
      titleAr
      lat
      lng
    }
  }
`

export const REMAINING_AREAS = gql`
  query remainingAreas(
    $restaurantId: String!
    $countryId: Int!
    $from: Int
    $to: Int
    $center: String
    $branchId: String
    $filter: String
  ) {
    remainingAreas(
      restaurantId: $restaurantId
      countryId: $countryId
      from: $from
      to: $to
      center: $center
      branchId: $branchId
      filter: $filter
    ) {
      id
      titleEn
      titleAr
      lat
      lng
      cityId
      cityTitleEn
      cityTitleAr
      branchId
      deliveryZoneId
    }
  }
`
export const ASSIGNED_AREAS = gql`
  query assignedAreas($restaurantId: String!, $countryId: Int!) {
    assignedAreas(restaurantId: $restaurantId, countryId: $countryId) {
      id
      titleEn
      branchId
      deliveryZoneId
    }
  }
`

export const ALL_COUNTRY_AREAS = gql`
  query allCountryAreas(
    $restaurantId: String!
    $countryId: Int!
    $branchId: String
    $filter: String
  ) {
    allCountryAreas(
      restaurantId: $restaurantId
      countryId: $countryId
      branchId: $branchId
      filter: $filter
    ) {
      id
      titleEn
      titleAr
      lat
      lng
      cityId
      cityTitleEn
      cityTitleAr
      branchId
      deliveryZoneId
    }
  }
`
