import { SMS_BODY_TOOLBOX_TAGS } from './constants';

const GSM_7_BIT_CHARS =
  '@£$¥èéùìòÇ\\nØø\\rÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ !\\"#¤%&\'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà';
const GSM_7_BIT_EX_CHAR = '\\^{}\\\\\\[~\\]|€';
const GSM_7_BIT_REG_EXP = RegExp(`^[${GSM_7_BIT_CHARS}]*$`);
const GSM_7_BIT_EX_REG_EXP = RegExp(`^[${GSM_7_BIT_CHARS}${GSM_7_BIT_EX_CHAR}]*$`);
const GSM_7_BIT_EX_ONLY_REG_EXP = RegExp(`^[\\${GSM_7_BIT_EX_CHAR}]*$`);
const GSM_7BIT = 'GSM_7BIT';
const GSM_7BIT_EX = 'GSM_7BIT_EX';
const UTF16 = 'UTF16';
const DIRECT_LINK_EX = RegExp(SMS_BODY_TOOLBOX_TAGS.DIRECT_LINK, 'g');
const VOUCHER_CODE_EX = RegExp(SMS_BODY_TOOLBOX_TAGS.VOUCHER_CODE, 'g');
const VOUCHER_EXPIRY_EX = RegExp(SMS_BODY_TOOLBOX_TAGS.VOUCHER_EXPIRY, 'g');

const MESSAGE_LENGTH = {
  GSM_7BIT: 160,
  GSM_7BIT_EX: 160,
  UTF16: 70,
};

const MULTI_MESSAGE_LENGTH = {
  GSM_7BIT: 153,
  GSM_7BIT_EX: 153,
  UTF16: 67,
};

function detectEncoding(text) {
  if (text.match(GSM_7_BIT_REG_EXP)) {
    return GSM_7BIT;
  }

  if (text.match(GSM_7_BIT_EX_REG_EXP)) {
    return GSM_7BIT_EX;
  }

  return UTF16;
}

function countGsm7bitEx(text) {
  const chars = [];

  for (let characterIndex = 0, textLength = text.length; characterIndex < textLength; characterIndex++) {
    const char2 = text[characterIndex];
    if (char2.match(GSM_7_BIT_EX_ONLY_REG_EXP) !== null) {
      chars.push(char2);
    }
  }

  return chars.length;
}

function tagSwap(text) {
  // The tag is three characters long, the direct link is 20 to 23 characters long,
  // examples: https://is.gd/f7qZM2 https://cutt.ly/mR1opLm
  // We will use the max value making the difference is 20 characters
  const directLinkMatches = text.match(DIRECT_LINK_EX);
  const directLinkSwap = directLinkMatches ? directLinkMatches.length * 20 : 0;
  // Auto generated voucher code is 6 characters long with a difference of 3
  const voucherCodeMatches = text.match(VOUCHER_CODE_EX);
  const voucherCodeSwap = voucherCodeMatches ? voucherCodeMatches.length * 3 : 0;
  // Voucher exipry date is 10 characters long with a difference of 7
  // example: 2019-01-15
  const voucherExpiryMatches = text.match(VOUCHER_EXPIRY_EX);
  const voucherExpirySwap = voucherExpiryMatches ? voucherExpiryMatches.length * 7 : 0;
  return directLinkSwap + voucherCodeSwap + voucherExpirySwap;
}

function smsCount(text) {
  const encoding = detectEncoding(text);
  let { length } = text;

  if (encoding === GSM_7BIT_EX) {
    length += countGsm7bitEx(text);
  }

  length += tagSwap(text);

  let perMessage = MESSAGE_LENGTH[encoding];
  if (length > perMessage) {
    perMessage = MULTI_MESSAGE_LENGTH[encoding];
  }

  const messages = Math.ceil(length / perMessage);
  let remaining = perMessage * messages - length;

  if (remaining === 0 && messages === 0) {
    remaining = perMessage;
  }

  return {
    encoding,
    length,
    perMessage,
    perMessageMulti: MULTI_MESSAGE_LENGTH[encoding],
    remaining,
    messages,
  };
}

export default smsCount;
