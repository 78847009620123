import request from "axios"

const { REACT_APP_BACKEND_URL } = process.env

export default async input => {
  return (await request({
    ...input,
    url: REACT_APP_BACKEND_URL + input.url,
  })).data
}
