export const TRACKED_TYPES = [
  { id: "false", title: "Available" },
  { id: "true", title: "Not Available" },
]

export const handleVariantName = (branchVariant, lang) => {
  return lang === "en"
    ? branchVariant?.variantTitleEn.split("-")[0]
    : branchVariant?.variantTitleAr.split("-")[0]
}

export const handleTrackingType = trackingType => {
  return trackingType !== "none"
}

export const TRACKING_TYPE_ENUM = {
  DAILY_CAPACITY: "daily_capacity",
  STOCK: "stock",
  NONE: "none",
}
