import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { useMobile } from 'hooks';
import { Text } from 'components/service';
import { context as localeContext } from 'context/locale';
import { ReactComponent as CampaignCheckIcon } from 'assets/campaign_check.svg';

const SecondaryStepper = ({ steps, currentStepNumber }) => {
  const { lang } = useContext(localeContext);
  const isMobile = useMobile();

  const [stepperSteps, setStep] = useState([]);

  function updateStep(stepNumber, tempSteps) {
    const newSteps = [...tempSteps];

    let stepCounter = 0;
    while (stepCounter < newSteps.length) {
      // Current step
      if (stepCounter === stepNumber) {
        newSteps[stepCounter] = {
          ...newSteps[stepCounter],
          highlighted: true,
          selected: true,
          completed: false,
        };
        stepCounter++;
      }
      // Past step
      else if (stepCounter < stepNumber) {
        newSteps[stepCounter] = {
          ...newSteps[stepCounter],
          highlighted: false,
          selected: true,
          completed: true,
        };
        stepCounter++;
      }
      // Future steps
      else {
        newSteps[stepCounter] = {
          ...newSteps[stepCounter],
          highlighted: false,
          selected: false,
          completed: false,
        };
        stepCounter++;
      }
    }
    return newSteps;
  }

  useEffect(() => {
    const tempSteps = steps.map((step, index) => ({
      title: step.props.title,
      subtitle: step.props.subtitle,
      html: step,
      completed: false,
      highlighted: index === 0,
      selected: index === 0,
    }));
    const currentSteps = updateStep(currentStepNumber - 1, tempSteps);
    setStep(currentSteps);
  }, [steps, currentStepNumber]);

  const stepsDisplay = stepperSteps.map((step, index) => (
    <div key={step.title[0]} className={`flex w-1/${stepperSteps.length} justify-start`}>
      <div
        className={cx(
          'w-11/12 border-0 md:border-t-4',
          step.highlighted ? 'text-gray-900' : 'text-gray-400',
          step.selected ? 'border-primary-base' : 'border-gray-400',
        )}
      >
        <div className="mt-2 flex flex-col items-center md:flex-row md:items-start">
          <div
            className={cx(
              'w-6 h-6 rounded-full flex items-center justify-center md:mt-0 lg:mt-2',
              step.highlighted ? 'border-8' : 'border',
              step.completed || step.highlighted ? 'border-primary-base' : 'border-gray-300',
            )}
          >
            {step.completed ? (
              <div>
                <CampaignCheckIcon />
              </div>
            ) : step.highlighted ? null : (
              <p className="text-center text-xs text-gray-500">{index + 1}</p>
            )}
          </div>
          <div className={cx('flex flex-col mt-sm', lang === 'en' ? 'ml-2' : 'mr-2')}>
            <Text className="text-sm font-medium text-black" value={step.title} />
            {!isMobile && <Text className="text-xs text-gray-600" value={step.subtitle} />}
          </div>
        </div>
      </div>
    </div>
  ));

  const currentStep = stepperSteps.find(step => step.highlighted);

  return (
    <>
      <div className="flex justify-between items-start">{stepsDisplay}</div>
      <div className="mt-4">{currentStep?.html}</div>
    </>
  );
};

SecondaryStepper.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.element).isRequired,
  currentStepNumber: PropTypes.number.isRequired,
};

export default SecondaryStepper;
