import React from "react"
import { Field } from "formik"
import cx from "classnames"

export default ({ name, placeholder, domain }) => (
  <Field
    name={name}
    render={({ field, form: { errors, touched } }) => {
      const error = touched[field.name] && errors[field.name]

      return (
        <>
          <div
            className={cx(
              "flex shadow rounded focus-within:ring",
              error && "border border-red-500"
            )}
          >
            <input
              {...field}
              type="text"
              className="appearance-none border rounded-l w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
              placeholder={placeholder}
            />
            <div className="flex items-center bg-gray-200 shadow py-1 px-2 rounded-r text-sm border border-gray-400 -ml-px">
              {domain}
            </div>
          </div>
          {error && <p className="text-red-500 text-xs italic mt-3">{error}</p>}
        </>
      )
    }}
  />
)
