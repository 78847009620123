import React from 'react';
import moment from 'moment';

import { Text } from 'components/service';

export const formatData = (values, selectedStore, query) =>
  values.map(item => ({
    date:
      query.date === 'day'
        ? moment(new Date(item.date)).format('MMM DD, YYYY')
        : moment(new Date(item.date)).format('MMMM, YYYY'),
    totalSales: <Text value={selectedStore.currency} postfix={item.totalSales} />,
    avgOrderValue: <Text value={selectedStore.currency} postfix={item.avgOrderValue} />,
    totalOrders: item.totalOrders,
    totalCustomers: item.totalCustomers,
  }));

export const customSort = (key, query, setQuery) => {
  let sort = '';
  // for desc case
  if (!query.sort.startsWith('-') || query.sort === '') sort = `-${key}`;
  else sort = key; // For asc case
  setQuery({ sort });
};
const momentLanguage = lang => {
  if (lang === 'ar') return 'ar-kw';
  return 'en-gb';
};

export default momentLanguage;
