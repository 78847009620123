import React from 'react';
import * as translations from 'constants/translations';
import { Text } from 'components/service';

export const CANCEL_REASON = {
  CUSTOMER_CANCELLED: 0,
  ITEM_UNAVAILABLE: 1,
  UNREACHABLE: 2,
  OUT_OF_DELIVERY: 3,
  NOT_ACCEPTING_ORDERS: 4,
  NOT_DELIVERED: 5,
  PAYMENT_ISSUES: 6,
  NOT_ARRIVE: 7,
  OTHER: 8,
};

const cancel_text_design = 'text-base font-normal leading-none text-black';

export const CANCEL_OPTIONS = [
  {
    value: CANCEL_REASON.CUSTOMER_CANCELLED,
    title: <Text value={translations.CUSTOMER_CANCELLED} />,
    className: cancel_text_design,
  },
  {
    value: CANCEL_REASON.ITEM_UNAVAILABLE,
    title: <Text value={translations.ITEM_UNAVAILABLE} />,
    className: cancel_text_design,
  },
  {
    value: CANCEL_REASON.UNREACHABLE,
    title: <Text value={translations.UNREACHABLE} />,
    className: cancel_text_design,
  },
  {
    value: CANCEL_REASON.OUT_OF_DELIVERY,
    title: <Text value={translations.OUT_OF_DELIVERY} />,
    className: cancel_text_design,
  },
  {
    value: CANCEL_REASON.NOT_ACCEPTING_ORDERS,
    title: <Text value={translations.NOT_ACCEPTING_ORDERS} />,
    className: cancel_text_design,
  },
  {
    value: CANCEL_REASON.NOT_DELIVERED,
    title: <Text value={translations.NOT_DELIVERED} />,
    className: cancel_text_design,
  },
  {
    value: CANCEL_REASON.PAYMENT_ISSUES,
    title: <Text value={translations.PAYMENT_ISSUES} />,
    className: cancel_text_design,
  },
  {
    value: CANCEL_REASON.NOT_ARRIVE,
    title: <Text value={translations.NOT_ARRIVE} />,
    className: cancel_text_design,
  },
  {
    value: CANCEL_REASON.OTHER,
    title: <Text value={translations.OTHER} />,
    className: cancel_text_design,
  },
];
