import React, { useContext } from 'react';
import { context as localeContext } from 'context/locale';
import cx from 'classnames';
export default ({ title, subtitle, body, innerBody, description, action, category, zoneToggle }) => {
  const { lang } = useContext(localeContext);
  const direction = lang === 'ar' ? 'rtl' : 'ltr';
  return (
    <div style={{ direction }} className={cx('rounded-none bg-white w-full ', !category || !zoneToggle && 'border')}>
      <div
        className={cx(!zoneToggle && 'px-4 py-4','flex flex-wrap justify-between', category && 'border-gray-300')}
        data-testid="catalog-box-container"
      >
        <div className={'font-medium text-lg flex items-center'} data-testid="catalog-box-title">
          {title}
        </div>
        <div className={cx(lang === 'ar' ? 'pr-2 mr-auto' : 'pl-2 ml-auto')} data-testid="catalog-box-action">
          {action}
        </div>
        {(subtitle || description) && (
          <div className="w-full" data-testid="catalog-box-description">
            {subtitle && <div className="text-gray-600 text-xs ">{subtitle}</div>}
            {description && <div className="text-sm ">{description}</div>}
          </div>
        )}
        {innerBody}
      </div>
      {body && (
        <div className={cx(!category && 'p-4')} data-testid="catalog-box-body">
          {body}
        </div>
      )}
    </div>
  );
};
