import React, { useContext } from "react"
import { useMutation } from "@apollo/client"
import { context as localeContext } from "context/locale"
import { context as userContext } from "context/user"
import { More } from "components/kit"
import { Text, Link } from "components/service"
import { replaceParams, convertTime } from "utils/index"
import { withBackParam } from "utils/history"
import * as paths from "paths.js"
import cx from "classnames"
import * as translations from "constants/translations"
import DeleteZone from "../../Edition/DeleteZone"
import UnpublishZone from "../UnpublishZone"
import * as schemas from "./schemas"

export default ({
  currency,
  isMultiCountry,
  branches,
  zones,
  open,
  close,
  refetch,
}) => {
  const { selectedStore } = useContext(userContext)
  const { lang, direction } = useContext(localeContext)

  const headCellClassName =
    "text-justify font-semibold border-b border-gray-300 pb-3 px-5 whitespace-nowrap"
  const cellClassName = "py-3 px-4 border-b border-gray-300 whitespace-nowrap"

  const [publishDeliveryZone] = useMutation(
    schemas.UPDATE_PUBLISH_DELIVERY_ZONE,
    {
      onCompleted: () => {
        refetch()
      },
    }
  )

  return (
    <table
      style={{ direction }}
      className="w-full md:w-auto table-auto border-collapse border inline-block overflow-auto md:overflow-visible"
    >
      <thead>
        <tr className="bg-gray-200">
          <th
            className={cx(
              "font-semibold whitespace-nowrap pt-2",
              lang === "ar" ? "text-left pl-32" : "text-right pr-32"
            )}
            colSpan="8"
          >
            <Text
              value={translations.PROXIMITY_LOCATION}
              className="whitespace-nowrap inline-block"
            />
          </th>
        </tr>
        <tr className="bg-gray-200">
          <th className={headCellClassName}>
            <Text
              value={translations.BUSINESS_LOCATION}
              className="whitespace-nowrap inline-block"
            />
          </th>
          <th className={headCellClassName}>
            <Text
              value={translations.DELIVERY_FEES}
              className="whitespace-nowrap inline-block"
            />
          </th>
          <th className={headCellClassName}>
            <Text
              value={translations.DELIVERY_TIME}
              className="whitespace-nowrap inline-block"
            />
          </th>
          <th className={headCellClassName}>
            <Text
              value={translations.MINIMUM_ORDER}
              className="whitespace-nowrap inline-block"
            />
          </th>
          <th className={headCellClassName}>
            <Text
              prefix={"0 - 10"}
              value={translations.KM}
              className="ml-1 whitespace-nowrap inline-block"
            />
          </th>
          <th className={headCellClassName}>
            <Text
              prefix={"10 - 15"}
              value={translations.KM}
              className="ml-1 whitespace-nowrap inline-block"
            />
          </th>
          <th className={headCellClassName}>
            <Text
              prefix={"15"}
              value={translations.OR_MORE}
              className="ml-1 whitespace-nowrap inline-block"
            />
          </th>
        </tr>
      </thead>
      {Object.entries(zones).map((item, i) => (
        item[0] !== 'null' &&(
        <tbody key={i}>
          <tr>
            <td
              className="py-2 px-3 text-xs border-b border-gray-300 whitespace-nowrap"
              colSpan="8"
            >
              <Text
                value={branches.find(branch => branch?.id?.toString() === item[0])}
                className="whitespace-nowrap inline"
              />
              <span className="mx-1 text-gray-700">&#8226;</span>
              <div className="inline-block cursor-pointer">
                <Link
                  omitQuery
                  to={
                    replaceParams(paths.branchEdition, {
                      storeId: selectedStore.id,
                      branchId: item[0],
                    }) + `?${withBackParam()}`
                  }
                >
                  <Text
                    value={translations.EDIT_LOCATION}
                    className="whitespace-nowrap text-primary-base"
                  />
                </Link>
              </div>
            </td>
          </tr>
          {item[1].map((zone, i) => {
            const {
              zeroToTenKms,
              tenToFifteenKms,
              fifteenToMoreKms,
            } = zone.assignedAreasBasedOnDistance
              ? zone.assignedAreasBasedOnDistance
              : {}
            return (
              <tr key={i}>
                <td className={cellClassName}>
                  <Link
                    omitQuery
                    to={
                      replaceParams(paths.deliveryEdition, {
                        storeId: selectedStore.id,
                        branchId: item[0],
                        deliveryZoneId: zone.id,
                      }) + `?${withBackParam()}`
                    }
                  >
                    <Text
                      value={translations.ZONE}
                      postfix={"#" + zone.id}
                      className="whitespace-nowrap inline-block text-primary-base"
                    />
                  </Link>
                </td>
                <td className={cellClassName}>
                  <Text
                    prefix={zone.deliveryFee}
                    value={currency}
                    className="whitespace-nowrap inline-block"
                  />
                </td>
                <td className={cellClassName}>
                  {isMultiCountry ? (
                    <Text
                      prefix={convertTime.fromMinutesToDays(zone.deliveryTime)}
                      value={translations.DAYS}
                      className="ml-1 whitespace-nowrap inline-block"
                    />
                  ) : (
                    <Text
                      prefix={zone.deliveryTime}
                      value={translations.MINS}
                      className="ml-1 whitespace-nowrap inline-block"
                    />
                  )}
                </td>
                <td className={cellClassName}>
                  {zone.minimumOrder ? (
                    <Text
                      prefix={zone.minimumOrder}
                      value={currency}
                      className="whitespace-nowrap inline-block"
                    />
                  ) : (
                    "__"
                  )}
                </td>
                <td className={cellClassName}>
                  {zeroToTenKms ? (
                    <Text
                      prefix={zeroToTenKms}
                      value={translations.AREAS}
                      className="whitespace-nowrap inline-block"
                    />
                  ) : (
                    "__"
                  )}
                </td>
                <td className={cellClassName}>
                  {tenToFifteenKms ? (
                    <Text
                      prefix={tenToFifteenKms}
                      value={translations.AREAS}
                      className="whitespace-nowrap inline-block"
                    />
                  ) : (
                    "__"
                  )}
                </td>
                <td className={cellClassName}>
                  <div className="flex justify-between items-center">
                    <span className="">
                      {fifteenToMoreKms ? (
                        <Text
                          prefix={fifteenToMoreKms}
                          value={translations.AREAS}
                          className="whitespace-nowrap inline-block"
                        />
                      ) : (
                        "__"
                      )}{" "}
                    </span>
                    <span className="">
                      <More
                        kind="tertiary"
                        size="sm"
                        items={[
                          {
                            title: <Text value={translations.DELETE} />,
                            onClick: () =>
                              open({
                                title: (
                                  <Text
                                    value={translations.ZONE}
                                    postfix={"#" + zone.id}
                                  />
                                ),
                                body: (
                                  <DeleteZone
                                    branchId={item[0]}
                                    storeId={selectedStore.id}
                                    zoneId={zone.id}
                                    onClose={close}
                                    refetch={refetch}
                                  />
                                ),
                                size: "max-w-md",
                              }),
                          },
                          {
                            title: zone.published ? (
                              <Text value={translations.UNPUBLISH} />
                            ) : (
                              <Text value={translations.PUBLISH} />
                            ),
                            onClick: () =>
                              zone.published
                                ? open({
                                    title: (
                                      <div className="flex items-center">
                                        <span className="text-lg text-red-600">
                                          <Text
                                            value={translations.ZONE}
                                            postfix={"#" + zone.id}
                                          />
                                        </span>
                                      </div>
                                    ),
                                    body: (
                                      <UnpublishZone
                                        updateZone={() => {
                                          publishDeliveryZone({
                                            variables: {
                                              restaurantId: selectedStore.id.toString(),
                                              branchId: item[0],
                                              id: zone.id,
                                              published: !zone.published,
                                            },
                                          })
                                          close()
                                        }}
                                        onCancel={close}
                                      />
                                    ),

                                    size: "max-w-xs",
                                  })
                                : publishDeliveryZone({
                                    variables: {
                                      restaurantId: selectedStore.id.toString(),
                                      branchId: item[0],
                                      id: zone.id,
                                      published: !zone.published,
                                    },
                                  }),
                          },
                        ]}
                      />
                    </span>
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
          )
      ))}
    </table>
  )
}
