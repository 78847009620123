import React, { useContext } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { context as localeContext } from 'context/locale';
import { ReactComponent as CreditCardIcon } from 'assets/credit-card-blue.svg';
import { ReactComponent as MadaIcon } from 'assets/mada-icon.svg';
import { ReactComponent as KnetIcon } from 'assets/knet-icon.svg';
import { ReactComponent as BenefitIcon } from 'assets/benefit.svg';
import { ReactComponent as ApplePayIcon } from 'assets/apple-pay-icon.svg';
import { ReactComponent as MeezaIcon } from 'assets/meeza-icon.svg';

const PaymentMethodsSymbols = ({ methods }) => {
  const { lang } = useContext(localeContext);
  const classNames = lang === 'en' ? 'mr-2' : 'ml-2';

  const showPaymentMethodSymbol = id => {
    switch (id) {
      case 1:
        return <CreditCardIcon className={classNames} key={id} />;
      case 2:
        return <KnetIcon className={classNames} key={id} />;
      case 3:
        return <BenefitIcon className={classNames} key={id} />;
      case 4:
        return <MadaIcon className={classNames} key={id} />;
      case 5:
        return <ApplePayIcon className={classNames} key={id} />;
      case 6:
        return <MeezaIcon className={classNames} key={id} />;
      default:
        return <></>;
    }
  };

  return (
    <div className={cx('flex flex-col -mt-2 mb-2', lang === 'en' ? 'ml-4' : 'mr-4')}>
      <div className="font-medium text-lg flex items-center">
        {methods.map(method => showPaymentMethodSymbol(method.id))}
      </div>
    </div>
  );
};

PaymentMethodsSymbols.propTypes = {
  methods: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
};

export default PaymentMethodsSymbols;
