import * as schemas from "./schemas"

export const updateCache = (
  restaurantId,
  menuSectionId,
  productTitle,
  limit,
  page
) => (cache, { data: { deleteProduct } }) => {
  const { products } = cache.readQuery({
    query: schemas.PRODUCTS,
    variables: { restaurantId, menuSectionId, productTitle, limit, page },
  })

  cache.writeQuery({
    query: schemas.PRODUCTS,
    data: {
      products: products.filter(item => item.id !== deleteProduct.id),
    },
    variables: { restaurantId, menuSectionId, productTitle, limit, page },
  })
}

export const updateCategories = (client, query, variables, items) => {
  const data = client.readQuery({ query, variables })

  client.writeQuery({
    query,
    variables,
    data: {
      ...data,
      menuSections: {
        ...data.menuSections,
        menuSections: items,
      },
    },
  })
}
