import { gql } from 'graphql-request';

const deleteStoreDriverMutation = gql`
  mutation($id: ID!, $userId: Int!) {
    deleteStoreDriver(id: $id, userId: $userId) {
      ok
    }
  }
`;

export default deleteStoreDriverMutation;
