import React from "react"
import { navigate } from "@reach/router"
import cx from "classnames"
import { Link } from "@reach/router"
import { useReplaceParams } from "hooks/index"

export default ({ to, omitQuery, appendQuery, ...props }) => {
  const replace = useReplaceParams()

  return (
    <Link
      {...props}
      getProps={data => {
        // fix, to be able use `getProps` function along with styled-components
        const newProps = props.getProps ? props.getProps(data) : {}

        return {
          ...newProps,
          className: cx(newProps.className, props.className),
        }
      }}
      onClick={
        props.onClick ||
        (e => {
          e.preventDefault()
          navigate(replace(to, { appendQuery, omitQuery }))
        })
      }
      to={replace(to, { omitQuery: true })}
    />
  )
}
