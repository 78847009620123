import { gql } from "@apollo/client"

export const BRANCH_VARIANTS_STOCKS = gql`
  query($storeId: String!, $branchId: Int!, $variantIds: [String]) {
    branchVariantsStock(
      restaurantId: $storeId
      branchId: $branchId
      variantIds: $variantIds
    ) {
      branchVariantsStock {
        id
        branchId
        variantId
        stockCount
        initialCount
        variantTitleEn
        variantTitleAr
        status
        unavailable
      }
    }
  }
`
export const UPDATE_VARIANT_TRACKING = gql`
  mutation(
    $storeId: String!
    $id: String!
    $stockCount: Int
    $unavailable: Boolean
    $tracked: Boolean
  ) {
    updateStockStatus(
      restaurantId: $storeId
      id: $id
      unavailable: $unavailable
      stockCount: $stockCount
      tracked: $tracked
    ) {
      id
      unavailable
      stockCount
      tracked
    }
  }
`
