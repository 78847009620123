import React from "react"
import * as yup from "yup"
import { Text } from "components/service"
import * as translations from "constants/translations"

export const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required(<Text value={translations.FIRST_NAME_REQUIRED} />),
  lastName: yup
    .string()
    .required(<Text value={translations.LAST_NAME_REQUIRED} />),
  email: yup
    .string()
    .email(<Text value={translations.INVALID_EMAIL_FORMAT} />)
    .required(<Text value={translations.EMAIL_IS_REQ} />),
  password: yup
    .string()
    .min(8, <Text value={translations.PASSWORD_8_CHARACTERS} />)
    .required(<Text value={translations.PASSWORD_REQUIRED} />),
})

export const initialValues = {
  name: "",
  lastName: "",
  email: "",
  password: "",
}
