import React from "react"
import * as yup from "yup"
import * as hoursUtils from "components/form/elements/Hours/utils"
import * as translations from "constants/translations"
import { Text } from "components/service"

export const initialValues = {
  searchAreas: "",
  branchId: "",
  countryId: "",
  deliveryFee: 0,
  deliveryTime: [0, "mins"],
  deliveryTimeDays: 0,
  minimumOrder: 0,
  scheduleMaxPeriod: 0,
  openingHours: hoursUtils.generateInitialValue("11:00", "21:30"),
  areas: [],
  searchedAreas: [],
}

export const validationSchema = yup.object().shape({
  branchId: yup
    .string()
    .required(<Text value={translations.BUSINESS_LOCATIONS_IS_REQ} />),
  countryId: yup
    .string()
    .required(<Text value={translations.BUSINESS_COUNTRY_IS_REQ} />),
  deliveryFee: yup
    .string()
    .required(<Text value={translations.DELIVERY_FEES_REQ} />),
  scheduleMaxPeriod: yup
    .string()
    .required(<Text value={translations.SCHEDULE_MAX_TIME_REQ} />),
  deliveryTime: yup
    .string()
    .required(<Text value={translations.DELIVERY_TIME_REQ} />),
  deliveryTimeDays: yup
    .string()
    .required(<Text value={translations.DELIVERY_TIME_REQ} />),
})
