import React from 'react';
import PropTypes from 'prop-types';
import CashbackProgram from '../../../pages/orderfast/loyalty/components/CashbackProgram';

const FeatureEnablement = ({ hint, title, actionButton, description }) => (
  <div className="flex flex-1 mx-6 my-10">
    <div className="flex flex-col p-4 w-1/2">
      {hint && hint}
      {title && title}
      {actionButton && actionButton}
    </div>
    <hr className="h-56 bg-gray-200 border mt-4" />
    {description && description}
  </div>
);

CashbackProgram.propTypes = {
  hint: PropTypes.element,
  title: PropTypes.element,
  actionButton: PropTypes.element,
  description: PropTypes.element,
};

export default FeatureEnablement;
