import React, { useState } from "react"
import { Container, Section, Row } from "components/form/generic"
import { Button, Spinner, Title, DropDown } from "components/kit"
import { Link, Text } from "components/service"
import Table from "./Table"
import { Layout, Breadcrumbs } from "components/common/dashboard"
import * as translations from "constants/translations"
import { breadcrumbs } from "constants/translations"
import * as paths from "paths.js"

export default () => {
  const options = [
    { id: "1", title: "option 0" },
    { id: "2", title: "option 1" },
    { id: "3", title: "option 2" },
  ]

  const [optionSelected, setOptionSelected] = useState("1")

  return (
    <Layout
      top={
        <Breadcrumbs
          links={[paths.reports]}
          path={breadcrumbs.REPORTS}
          right={
            <Button kind="tertiary">
              <Text value={translations.EXPORT} className="px-1" />
              CSV
            </Button>
          }
        />
      }
    >
      <div className="px-6 pb-6">
        <Row>
          <DropDown
            float={"left"}
            label={<Text value={translations.FILTER} className="inline" />}
            optionSelected={optionSelected}
            onSelect={option => setOptionSelected(option)}
            data={options}
          />
          <DropDown
            float={"right"}
            label={<Text value={translations.SORT_BY} className="inline" />}
            optionSelected={optionSelected}
            onSelect={option => setOptionSelected(option)}
            data={options}
          />
        </Row>
        <Table />
      </div>
    </Layout>
  )
}
