export const APPLIES_TO = {
  ENTIRE_ORDER: 'entire_order',
  DELIVERY_TYPE: 'delivery_type',
  DELIVERY: 'delivery',
  PICKUP: 'pickup',
};
export const VOUCHER_TYPE = {
  FREE_DELIVERY: 'free_delivery',
  PERCENTAGE: 'percentage',
  AMOUNT: 'amount',
  NO_VOUCHER: 'no_voucher',
};
export const MINIMUM_REQUIREMENTS = { NONE: 'none', MINIMUM_ORDER_AMOUNT: 'minimumOrderAmount' };

export const SENDER_ID_TYPES = {
  ORDER_DIRECT: 'OrderDirect',
};
