import React from "react"
import styled, { createGlobalStyle } from "styled-components"

export default ({ children, image, bgColor }) => {
  return (
    <>
      <GlobalStyle />
      <div className="flex flex-row">
        <div
          style={{ backgroundColor: bgColor }}
          className="h-screen w-2/5 hidden sm:hidden md:block lg:block xl:block"
        >
          <Image>
            <img src={image} />
          </Image>
        </div>
        <div className="overflow-y-auto h-screen md:w-3/5 w-full h-full px-8 flex flex-1 items-center">
          <div className="m-auto w-full flex justify-center">{children}</div>
        </div>
      </div>
    </>
  )
}

const GlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }
`

const Image = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  img {
    margin-right: -5%;
    margin-left: auto;
    width: 70%;
  }
`
