import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { GIFT_FROM, NAME, MOBILE, GIFT_NOTE } from 'constants/translations';

const OrderInvoiceGift = ({ translate, order, direction, lang }) => {
  const { userData: { name, phoneNumber, recipient } = {} } = order || {};
  return (
    <div>
      <div className="bg-zyda-grey-50 w-full py-1 px-4">
        <div className={cx('flex justify-between', lang === 'ar' && 'flex-row-reverse')}>
          <div className={cx('flex', lang === 'ar' && 'flex-row-reverse')}>
            <span className="text-sm font-bold" style={{ direction }}>
              {translate(GIFT_FROM)}
            </span>
          </div>
        </div>
      </div>
      <div className={cx('flex p-4', lang === 'ar' && 'flex-row-reverse')}>
        <div className="flex flex-col w-1/2">
          <div className={cx('flex py-1', lang === 'ar' && 'flex-row-reverse')}>
            <span className="text-sm font-bold" style={{ direction }}>
              {translate(NAME)}:
            </span>
            <span className="text-sm px-3" style={{ direction }}>
              {name}
            </span>
          </div>
          <div className={cx('flex py-1', lang === 'ar' && 'flex-row-reverse')}>
            <span className="text-sm font-bold" style={{ direction }}>
              {translate(MOBILE)}:
            </span>
            <span className="text-sm px-3">{phoneNumber}</span>
          </div>
          {recipient.giftNotes && (
            <div className={cx('flex py-1', lang === 'ar' && 'flex-row-reverse')}>
              <span className="text-sm font-bold" style={{ direction }}>
                {translate(GIFT_NOTE)}:
              </span>
              <span className="text-sm px-3">{recipient.giftNotes}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

OrderInvoiceGift.propTypes = {
  translate: PropTypes.func,
  order: PropTypes.shape({
    userData: PropTypes.shape({
      name: PropTypes.string,
      phoneNumber: PropTypes.number,
      recipient: PropTypes.string,
    }),
  }),
  lang: PropTypes.string,
  direction: PropTypes.string,
};

export default OrderInvoiceGift;
