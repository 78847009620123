import { gql } from '@apollo/client';

const NEW_VS_RETURNING_CUSTOMERS_REPORT = gql`
  query($storeId: String!, $date: AnalyticsPrecision!, $range: AnalyticsRange!, $page: Int!, $limit: Int!) {
    newVsReturningCustomersReport(restaurantId: $storeId, date: $date, range: $range, page: $page, limit: $limit) {
      startDate
      endDate
      data {
        totalSales
        totalOrders
        avgOrderValue
        totalCustomers
      }
      totalCount
      totalPages
    }
  }
`;

export default NEW_VS_RETURNING_CUSTOMERS_REPORT;
