import React, { useContext } from "react"
import cx from "classnames"
import { Text } from "components/service"
import * as translations from "constants/translations"
import { context as userContext } from "context/user"

export default ({ lang, isEdit, isMenu }) => {
  const { selectedStore: selected, isPosCourier: isPosType } = useContext(userContext)
  const currency = selected.currency
  const headCellClassName = cx(
    "w-1/4 px-4 py-2 flex-1 text-zyda-grey-60 font-normal text-justify whitespace-nowrap border-b border-gray-300",
    lang === "ar" ? "text-right" : "text-left"
  )
  
  return (
    <thead>
      <tr className="bg-zyda-grey-50">
        <th className={cx("px-4 border-b", isEdit && "hidden")}></th>
        <th className={cx(headCellClassName, "hidden")}>
          <Text
            value={translations.IMAGE}
            className="whitespace-nowrap inline-block"
          />
        </th>
        <th className={headCellClassName}>
          <Text
            value={translations.VARIANT_NAME}
            className="whitespace-nowrap inline-block"
          />
        </th>
        <th className={headCellClassName}></th>
        {isPosType && (
          <th className={headCellClassName}>
            <Text
              value={translations.External_ID}
              className="whitespace-nowrap inline-block"
            />
          </th>
        )}
        <th className={headCellClassName}>
          <Text
            value={translations.PRICE_CURRENCY}
            payload={lang === "en" ? currency.titleEn : currency.titleAr}
            className="whitespace-nowrap inline-block"
          />
        </th>
        <th className={headCellClassName}>
          <Text
            value={translations.DISCOUNTED_PRICE_CURRENCY}
            payload={lang === "en" ? currency.titleEn : currency.titleAr}
            className="whitespace-nowrap inline-block"
          />
        </th>
        {isMenu && (
          <>
            <th className={headCellClassName}>
              <Text
                value={translations.SKU}
                className="whitespace-nowrap inline-block"
              />
            </th>
            <th className={headCellClassName}>
              <Text
                value={translations.BARCODE}
                className="whitespace-nowrap inline-block"
              />
            </th>
          </>
        )}
        <th className={headCellClassName}></th>
      </tr>
    </thead>
  )
}
