/**
 * Gets center position off a group of points
 * @param {*} points array of lat and lng objects
 * @param {*} type type of the object containing the lat and lng points
 * @returns object containing lat and lng of the center point
 */
export const getCenterPosition = points => {
  let sumX = 0;
  let sumY = 0;

  points.forEach(pointItem => {
    sumX += pointItem.lat;
    sumY += pointItem.lng;
  });

  return { lat: sumX / points.length, lng: sumY / points.length };
};
/**
 * Get the center poisition of a Geometry
 * @param {*} geometry Google maps feature geometry
 * @returns object containing lat and lng of the center point
 */
export const getGeometryCenterPosition = geometry => {
  let lng = 0;
  let lat = 0;
  let counter = 0;
  geometry.forEachLatLng(latlng => {
    lng += latlng.lng();
    lat += latlng.lat();
    counter++;
  });
  lng /= counter;
  lat /= counter;
  return { lat, lng };
};
