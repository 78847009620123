import { gql } from "@apollo/client"

export const SUBSCRIPTION = gql`
  query($storeId: String!) {
    subscription(restaurantId: $storeId) {
      id
      status
      total
      expiresAt
      goActiveAt
      serviceData {
        interval
      }
      countryPlan {
        plan {
          titleEn
        }
      }
      invoices {
        createdAt
      }
    }
  }
`
