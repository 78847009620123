export const transactionReason = {
  CASHBACK: 'cashback',
  REFUNDED: 'refunded',
  REDEEMED: 'redeemed',
  COMPENSATION: 'compensation',
  CANCELED_CASHBACK: 'canceled_cashback',
};

export const transactionOperation = {
  DEPOSIT: 'deposit',
  WITHDRAW: 'withdraw',
};
