import { gql } from "@apollo/client"

export const PAYMENT_CREDENTIALS = gql`
  query($storeId: String!) {
    paymentCredentials(restaurantId: $storeId) {
      id
      merchantId
      countryCode
      accountType
      profileName
      paymentGatewayId
      active
      transfer
      status
      publicApiKey
      apiKey
      iframeId
      remarks
      isLicensed
      licenseCopyUrl
      authorizedSignatoryCopyUrl
      nationalFrontCopyUrl
      nationalBackCopyUrl
      idBackCopyUrl
      idFrontCopyUrl
      paymentDepositTerm {
        id
        name
      }
      contactInfo {
        firstName
        lastName
        emailAddress
        password
        mobileNumber
        idNumber
        idBackCopyUrl
        idFrontCopyUrl
      }
      bankAccountInfo {
        iban
        bankName
        bankId
        beneficiaryAddress
        bankAddress
        swiftCode
        accountName
        accountNumber
      }
      corporateInfo {
        name
        licenseNumber
      }
      restaurantPaymentMethods {
        name
        enabled
        id
        referenceId
      }
      paymentGateway { 
        id
        name
        integrationFromGateway
      }
    }
  }
`

export const UPDATE_PAYMENT_CREDENTIALS = gql`
  mutation(
    $restaurantId: String!
    $id: String!
    $paymentGatewayId: Int
    $profileName: String
    $accountType: String
    $firstName: String
    $lastName: String
    $emailAddress: String
    $password: String
    $mobileNumber: String
    $isLicensed: Boolean
    $idNumber: String
    $iban: String
    $bankName: String
    $bankId: Int
    $paymentDepositTermId: Int
    $beneficiaryAddress: String
    $bankAddress: String
    $swiftCode: String
    $accountName: String
    $accountNumber: String
    $name: String
    $licenseNumber: String
    $licenseCopyUrl: String
    $authorizedSignatoryCopyUrl: String
    $nationalFrontCopyUrl: String
    $nationalBackCopyUrl: String
    $merchantId: String
    $apiKey: String
    $iframeId: String
    $cardIntegrationId: String
  ) {
    updatePaymentCredentials(
      restaurantId: $restaurantId
      id: $id
      paymentGatewayId: $paymentGatewayId
      profileName: $profileName
      accountType: $accountType
      firstName: $firstName
      lastName: $lastName
      emailAddress: $emailAddress
      password: $password
      mobileNumber: $mobileNumber
      idNumber: $idNumber
      iban: $iban
      bankName: $bankName
      bankId: $bankId
      paymentDepositTermId: $paymentDepositTermId
      isLicensed: $isLicensed
      beneficiaryAddress: $beneficiaryAddress
      bankAddress: $bankAddress
      swiftCode: $swiftCode
      accountName: $accountName
      accountNumber: $accountNumber
      name: $name
      licenseNumber: $licenseNumber
      licenseCopyUrl: $licenseCopyUrl
      authorizedSignatoryCopyUrl: $authorizedSignatoryCopyUrl
      nationalFrontCopyUrl: $nationalFrontCopyUrl
      nationalBackCopyUrl: $nationalBackCopyUrl
      merchantId: $merchantId
      apiKey: $apiKey
      iframeId: $iframeId
      cardIntegrationId: $cardIntegrationId
    ) {
      id
    }
  }
`
