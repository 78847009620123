import React, { useContext } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import * as R from 'ramda';

import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { context as userContext } from 'context/user';
import { context as notificationsContext } from 'context/notifications';
import * as schemas from '../schemas';
import * as utils from '../utils';

const useAddWebLink = ({ lang }) => {
  const { selectedStore } = useContext(userContext);
  const notifications = useContext(notificationsContext);
  const isArabic = lang === 'ar';

  const { data: links } = useQuery(schemas.SETTINGS, {
    variables: { storeId: selectedStore.id },
  });

  const [addWebLink, { loading }] = useMutation(schemas.ADD_WEB_LINK, {
    onCompleted: data => {
      const newWebLink = [...data?.addWebLink?.webLinks?.links].pop();
      notifications.show(
        <Text value={translations.THE_LINK_ADDED} payload={(isArabic && newWebLink?.titleAr) || newWebLink?.titleEn} />,
      );
    },
  });

  const handleOnAddClick = formData => {
    const generatedWebLinkObj = {
      ...R.omit(['title'], formData),
      titleAr: formData.titleAr,
      titleEn: formData.titleEn,
      url: utils.prependLink(formData),
    };
    const formattedWebLinksArr =
      links?.settings?.webLinks?.links && utils.formatWebLinksArr(links?.settings?.webLinks?.links);
    return addWebLink({
      variables: {
        storeId: selectedStore.id,
        link: generatedWebLinkObj,
        links: formattedWebLinksArr,
      },
    });
  };

  return [handleOnAddClick, { loading }];
};

export default useAddWebLink;
