import React, { useContext } from "react"
import { context as localeContext } from "context/locale"
import { Text } from "components/service"
import { SpinnerAlt } from "components/kit"
import * as translations from "constants/translations"
import cx from "classnames"

export default ({
  name,
  disabled,
  loading = false,
  value = false,
  onChange,
  btnTestId,
  statusTestId,
  title,
}) => {
  const { lang, direction } = useContext(localeContext)
  return (
    <div className={cx("flex items-center", disabled && "opacity-25")}>
      <div
        style={{ direction: "ltr" }}
        className="relative inline-block w-10 mx-2 align-middle select-none transition duration-200 ease-in"
      >
        <input
          type="checkbox"
          name={name}
          id={name}
          disabled={disabled}
          // defaultChecked={value}
          checked={value}
          className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 border-gray-300 focus:outline-none appearance-none cursor-pointer"
          onClick={e =>
            !disabled &&
            onChange({
              target: {
                name,
                value: !value,
              },
            })
          }
          data-testid={btnTestId}
        />
        <label
          htmlFor={name}
          className="toggle-label block overflow-hidden h-6 rounded-full bg-zyda-grey-500 cursor-pointer"
        ></label>
      </div>
      {title && (
        <label
          className={cx(
            "text-xs w-20",
            value ? "text-gray-700" : "text-gray-700"
          )}
          data-testid={statusTestId}
        >
          {loading ? (
            <SpinnerAlt color="primary-base" />
          ) : value ? (
            <Text value={translations.AVAILABLE} />
          ) : (
            <Text value={translations.UNAVAILABLE} />
          )}
        </label>
      )}
    </div>
  )
}
