import React from "react"
import * as yup from "yup"
import * as translations from "constants/translations"
import { Text } from "components/service"

export const initialValues = {
  discountEnabled: null,
  discountedPrice: null,
  externalId: null,
  id: "",
  images: [],
  price: "",
  titleAr: "",
  titleEn: "",
}
