import React, { useContext } from "react"
import moment from "moment"
import { navigate } from "@reach/router"

import { useSelectedStore, useReplaceParams, useUTCtimeZone } from "hooks"
import { context as userContext } from "context/user"
import { context as localeContext } from "context/locale"
import { Button, Stack } from "components/kit"
import * as translations from "constants/translations"
import { Text } from "components/service"
import * as paths from "paths.js"

export default ({ onClose, voucherId, item }) => {
  const { selectedStore } = useContext(userContext)
  const { translate, lang } = useContext(localeContext)
  const storeId = useSelectedStore()
  const replace = useReplaceParams()

  const type = {
    percentage: translate(translations.PERCENTAGE),
    amount: translate(translations.AMOUNT),
  }

  return (
    <div className="px-4">
      <div className="mb-4 text-xs">
        <div className="">{`- ${
          lang === "en" ? item.description : item.descriptionAr
        }`}</div>
        {item.voucherType === "free_delivery" ? (
          "- " + translate(translations.FREE_DELIVERY)
        ) : (
          <Text
            value={translations.VOUCHER_TYPE_PREVIEW}
            payload={[
              type[item.voucherType],
              `${item.amount}${
                item.voucherType === "percentage"
                  ? "%"
                  : translate(selectedStore.currency)
              }`,
            ]}
          />
        )}
        <Text
          value={translations.VOUCHER_MINIMUM_REQ_PREVIEW}
          payload={[item.minSubtotalAmount, translate(selectedStore.currency)]}
        />
        <Text
          value={translations.VOUCHER_LIMIT_USERS_PREVIEW}
          payload={[
            item.redemptionLimit === 0
              ? translate(translations.UNLIMITED)
              : item.redemptionLimit,
            item.redemptionLimitPerUser === 0
              ? translate(translations.UNLIMITED)
              : item.redemptionLimitPerUser,
          ]}
        />
        {!!item.validTimeFrom && (
          <Text
            value={translations.VOUCHER_HAPPY_HOURS_PREVIEW}
            payload={[
              useUTCtimeZone({ time: item.validTimeFrom }),
              useUTCtimeZone({ time: item.validTimeTo }),
            ]}
          />
        )}
        {item.validTo ? (
          <Text
            value={translations.VOUCHER_VALID_DATE_PREVIEW}
            payload={[
              moment(item.validFrom).format("DD/MM/YYYY"),
              moment(item.validTo).format("DD/MM/YYYY"),
            ]}
          />
        ) : (
          <Text
            value={translations.VOUCHER_VALID_DATE_UNLIMITED}
            payload={moment(item.validFrom).format("DD/MM/YYYY")}
          />
        )}
      </div>
      <Stack direction="row-reverse" expandItem={false} spacing={2}>
        <Button
          onClick={() => {
            navigate(
              replace(paths.editVoucher, {
                params: { voucherId },
              })
            )
          }}
          type="button"
          kind="primary"
        >
          <Text value={translations.EDIT} />
        </Button>
        <Button onClick={onClose} type="button" kind="tertiary">
          <Text value={translations.CANCEL} />
        </Button>
      </Stack>
    </div>
  )
}
