import React from 'react';
import PropTypes from 'prop-types';

import * as translations from 'constants/translations';
import { DropDown } from 'components/kit';
import { Text } from 'components/service';

const BranchSelection = ({ branches, tableViewController, setTableViewController, setBulkZones }) => (
  <DropDown
    onSelect={option => {
      setTableViewController(prevState => ({
        ...prevState,
        selectedBranch: option,
      }));
      setBulkZones([]);
    }}
    optionSelected={tableViewController.selectedBranch}
    placeholder={<Text value={translations.CHOOSE_ONE_BRANCH} className="text-sm text-gray-500" />}
    data={branches}
    icon="keyboard_arrow_down"
    full
    scrollable
    className="w-full"
  />
);

BranchSelection.propTypes = {
  branches: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ).isRequired,
  tableViewController: PropTypes.shape({
    selectedBranch: PropTypes.number,
  }).isRequired,
  setTableViewController: PropTypes.func.isRequired,
  setBulkZones: PropTypes.func.isRequired,
};

export default BranchSelection;
