import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

const Tag = ({ icon, title, lang, baseCss, labelCss, refund = false }) => (
  <div className={cx('flex rounded-full justify-center w-fit px-1 py-2', baseCss, labelCss)}>
    <div className="pl-1 pr-1" style={{ transform: 'scale(0.75)' }}>
      {icon}
    </div>
    <p className={cx('whitespace-nowrap', lang === 'en' ? 'pr-2' : 'pl-2')}>{title}</p>
  </div>
);

Tag.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  lang: PropTypes.oneOf(['en', 'ar']).isRequired,
  baseCss: PropTypes.string.isRequired,
  labelCss: PropTypes.string.isRequired,
  refund: PropTypes.bool,
};

export default Tag;
