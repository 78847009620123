import React, { useContext, useState, useEffect } from "react"
import { useSelectedStore } from "hooks/index"
import { context as localeContext } from "context/locale"
import { Text, Link } from "components/service"
import { breadcrumbs } from "constants/translations"
import { Layout, Breadcrumbs } from "components/common/dashboard"
import * as paths from "paths.js"
import * as translations from "constants/translations"
import "styled-components/macro"
import { ReactComponent as Done } from "assets/check.svg"
import { useQuery } from "@apollo/client"
import { context as userContext } from "context/user"
import cx from "classnames"
import { Form } from "formik"
import { navigate } from "@reach/router"
import { useMutation } from "@apollo/client"
import { useReplaceParams } from "hooks/index"
import { UserForm, UserData, DeleteUser } from "components/common/team"
import {
  ChangePasswordData,
  ChangePasswordForm,
  EditUserData,
} from "components/common/editUserInfo"
import { Button, Stack, More, Modal, Spinner } from "components/kit"
import { transformUser } from "components/common/team/utils"
import * as schemas from "../editInfo/schemas"

const ChangePassword = () => {
  const { direction } = useContext(localeContext)
  const [plan, setPlan] = useState("")
  const selectedStoreId = useSelectedStore()

  const userInfo = useContext(userContext)

  const [updateUserPassword, { loading }] = useMutation(
    schemas.UPDATE_USER_PASSWORD,
    {
      variables: {
        storeId: selectedStoreId,
        userId: userInfo && userInfo.user.id,
      },
    }
  )
  return (
    <div>
      <Layout>
        <div
          className={cx(
            "px-4 w-full lg:w-2/3 flex-row",
            direction === "rtl" && "ml-auto"
          )}
        >
          <ChangePasswordData
            onSubmit={variables => updateUserPassword({ variables })}
          >
            {({ values }) => (
              <Form>
                <Breadcrumbs
                  path={breadcrumbs.CHANGE_PASSWORD}
                  right={
                    <Stack direction="wrap" expandItem={false} spacing="2">
                      <Button kind="primary" isSpinning={loading}>
                        <Text value={translations.SAVE_CHANGE} />
                      </Button>
                      <Link omitQuery to={paths.orders}>
                        <Button type="button" kind="secondary">
                          <Text value={translations.CANCEL} />
                        </Button>
                      </Link>
                    </Stack>
                  }
                />
                <div
                  style={{ direction }}
                  className={cx(
                    "pb-6",
                    direction === "rtl" && "mr-auto"
                  )}
                >
                  <ChangePasswordForm />
                </div>
              </Form>
            )}
          </ChangePasswordData>
        </div>
      </Layout>
    </div>
  )
}

export default ChangePassword
