import { gql } from 'graphql-request';

const countriesListQuery = gql`
  {
    countries {
      id
      code
      titleEn
      titleAr
    }
  }
`;

export default countriesListQuery;
