import React from "react"
import moment from "moment"
import * as yup from "yup"
import { Text } from "components/service"
import * as translations from "constants/translations"
import smsCount from "./smsCount"

export const createValidationSchemaCreation = yup.object().shape({
  title: yup
    .string()
    .required(<Text value={translations.CAMPAIGN_TITLE_REQUIRED} />),
  type: yup
    .string()
    .required(<Text value={translations.CAMPAIGN_TYPE_REQUIRED} />),
  inactivityPeriod: yup.number().when("lookbackWindow", {
    is: value => value !== "all_customers",
    then: yup
      .number()
      .required(<Text value={translations.INACTIVITY_REQUIRED} />)
      .min(1)
      .when("lookbackWindow", (type, schema) => {
        return (
          type !== "all_customers" &&
          schema.lessThan(
            moment().diff(lockBackwindowDates[type], "days"),
            <Text
              value={translations.INACTIVITY_CANT_MORE_THAN_LOOKBACKWINDOW}
            />
          )
        )
      }),
    otherwise: yup
      .number()
      .required(<Text value={translations.INACTIVITY_REQUIRED} />)
      .min(1),
  }),
  smsSendingLimit: yup.number(),
  startsAt: yup
    .date()
    .required(<Text value={translations.START_DATE_REQUIRED} />),
  expiresAt: yup
    .date()
    .min(
      yup.ref("startsAt"),
      <Text value={translations.END_DATE_CANT_BE_BEFORE_START_DATE} />
    )
    .test({
      name: "same",
      exclusive: false,
      params: {},
      message: <Text value={translations.END_DATE_CANT_BE_SAME_START_DATE} />,
      test: function(value) {
        const startDate = moment(this.parent.startsAt).format("YYYY-MM-DD")
        const endDate = moment(value).format("YYYY-MM-DD")
        return !moment(startDate).isSame(moment(endDate))
      },
    }),

  voucherAmount: yup.number().when("AddVoucher", {
    is: true,
    then: yup.number().when("voucherType", {
      is: type => type !== "free_delivery" && true,
      then: yup.number().when("voucherType", {
        is: type => type === "percentage" && true,
        then: yup
          .number()
          .required(<Text value={translations.VOUCHER_AMOUNT_IS_REQUIRED} />)
          .min(1)
          .moreThan(0, <Text value={translations.AMOUNT_MORE_THAN_0} />)
          .when("voucherType", (type, schema) => {
            return (
              type === "percentage" &&
              schema.max(100, <Text value={translations.PERCENTAGE_MAX_100} />)
            )
          }),
        otherwise: yup
          .number()
          .required(<Text value={translations.VOUCHER_AMOUNT_IS_REQUIRED} />)
          .min(1)
          .moreThan(0, <Text value={translations.AMOUNT_MORE_THAN_0} />),
      }),
      otherwise: yup.number(),
    }),
    otherwise: yup.number(),
  }),
  redemptionLimitPerUser: yup.number().when("AddVoucher", {
    is: true,
    then: yup.number().when("redemptionLimitEnabled", {
      is: true,
      then: yup.number().when("redemptionLimit", (redemptionLimit, schema) => {
        return (
          redemptionLimit !== (0 || null) &&
          schema.max(
            redemptionLimit,
            <Text value={translations.MAX_IS} payload={redemptionLimit} />
          )
        )
      }),
      otherwise: yup.number(),
    }),
    otherwise: yup.number(),
  }),
})

export const editValidationSchemaCreation = yup.object().shape({
  startsAt: yup
    .date()
    .required(<Text value={translations.START_DATE_REQUIRED} />),
  expiresAt: yup
    .date()
    .min(
      yup.ref("startsAt"),
      <Text value={translations.END_DATE_CANT_BE_BEFORE_START_DATE} />
    )
    .test({
      name: "same",
      exclusive: false,
      params: {},
      message: <Text value={translations.END_DATE_CANT_BE_SAME_START_DATE} />,
      test: function(value) {
        const startDate = moment(this.parent.startsAt).format("YYYY-MM-DD")
        const endDate = moment(value).format("YYYY-MM-DD")
        return !moment(startDate).isSame(moment(endDate))
      },
    })
    .nullable(),
})

export const initialValues = {
  type: "Retention",
  smsBody: "",
  lookbackWindow: "all_customers",
  estimateReach: "--",
  startsAt: moment().format(),
  AddVoucher: false,
  voucherType: "percentage",
  minimumRequirements: "none",
  redemptionLimit: 0,
  isDraft: false,
  isCreate: false,
}

const getDate = (amount, unit) =>
  moment()
    .subtract(amount, unit)
    .format("YYYY-MM-DD")

const lockBackwindowDates = {
  last_month: getDate(1, "month"),
  last_3_months: getDate(3, "month"),
  last_6_months: getDate(6, "month"),
  last_year: getDate(1, "year"),
}
