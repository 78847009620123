import React, { useContext } from "react"
import { useQuery, useMutation } from "@apollo/client"
import { context as notificationsContext } from "context/notifications"
import { Layout, Breadcrumbs } from "components/common/dashboard"
import { Text } from "components/service"
import { Button, Tabs, Toggle, Spinner } from "components/kit"
import * as translations from "constants/translations"
import cx from "classnames"
import * as paths from "paths.js"
import { useSelectedStore } from "hooks/index"
import { Stepper, RadioList } from "components/form/elements"
import { breadcrumbs } from "constants/translations"
import { context as localeContext } from "context/locale"
import { Section, Row, Label, Footer } from "components/form/generic"
import { Form, Formik, Field } from "formik"
import * as schemas from "./schemas"
import EmptyState from "../EmptyState"
import * as data from "./data"
import * as R from "ramda"

export default () => {
  const storeId = useSelectedStore()
  const { loading, data: settings } = useQuery(schemas.STOCK_SETTINGS, {
    variables: { storeId },
    fetchPolicy: "no-cache",
  })

  const notifications = useContext(notificationsContext)

  const { lang, translate, direction } = useContext(localeContext)

  const [updateSettings, { loading: isStillUpdating }] = useMutation(
    schemas.UPDATE_STOCK_SETTINGS
  )

  return (
    <Layout
      top={
        <Breadcrumbs
          links={[paths.products, paths.stockManagementGeneralSettings]}
          path={breadcrumbs.SETUP_STOCK_MANAGEMENT}
        />
      }
    >
      <div className="px-3 py-1">
        <div className="mb-4">
          <Tabs
            items={[
              {
                title: <Text value={translations.STOCK_VARIANTS} />,
                to: paths.productStockVariants,
              },
              {
                title: <Text value={translations.STOCK_GENERAL_SETTINGS} />,
                to: paths.productStockSettings,
              },
              {
                title: <Text value={translations.STOCK_HISTORY_LOG} />,
                to: paths.productStockHistory,
              },
            ]}
          />
        </div>
      </div>
      {!settings ? (
        <Spinner />
      ) : !settings.settings.isStockEnabled ? (
        <EmptyState />
      ) : (
        <div
          className={cx("pb-6 flex", lang === "ar" && "flex-row-reverse")}
        >
          <div className="w-full md:w-2/3" style={{ direction }}>
            <Formik
              initialValues={R.mergeDeepRight(
                data.initialValues,
                settings && {
                  lowStockField: settings.settings.lowStockThreshold,
                  storeMessageField:
                    settings.settings.lowStockMessage === "FEW_ITEMS_LEFT"
                      ? 1
                      : 2,
                }
              )}
              onSubmit={async values => {
                let message =
                  values.storeMessageField === 1
                    ? "FEW_ITEMS_LEFT"
                    : "LESS_THAN_MIN_LEFT"
                let threshold = values.lowStockField ? values.lowStockField : 0
                let variables = {
                  storeId: storeId,
                  lowStockThreshold: threshold,
                  lowStockMessage: message,
                }
                await updateSettings({ variables: variables })
                  .then(() => {
                    notifications.show(
                      <Text value={translations.STORE_INFORMATION_UPDATED} />
                    )
                  })
                  .catch(() => {
                    notifications.show(
                      <Text value={translations.INVALID_FORMAT_UPLOAD} />,
                      "error"
                    )
                  })
              }}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <Section
                    key={1}
                    title={<Text value={translations.WARNINIG_THRESHOLD} />}
                  >
                    <Toggle
                      isvisible={true}
                      testId={"toggle-tax"}
                      content={
                        <Row>
                          <Label
                            htmlFor="primaryColor"
                            title={
                              <Text value={translations.LOW_STOCK_NOTIFY} />
                            }
                            subtitle={
                              <Text value={translations.LOW_STOCK_ALERT} />
                            }
                          >
                            <Field
                              name="lowStockField"
                              data-testid="stockManagement-lowStock"
                              min={0}
                              step={1}
                              placeholder={translate(translations.LOW_STOCK_EX)}
                              component={Stepper}
                              validation={n => n >= 0}
                              value={values.lowStockField}
                              onChange={e =>
                                setFieldValue(
                                  "lowStockField",
                                  parseInt(e.target.value)
                                )
                              }
                            />
                          </Label>
                          <div />
                        </Row>
                      }
                    />
                  </Section>
                  <Section
                    key={2}
                    title={<Text value={translations.STORE_MESSAGE} />}
                  >
                    <Row>
                      <Label
                        htmlFor="primaryColor"
                        title={
                          <Text value={translations.STORE_MESSAGE_CONTENT} />
                        }
                      >
                        <div style={{ marginLeft: "-7px" }}>
                          <Field
                            data-testid="storeMessageField"
                            onChange={e =>
                              setFieldValue(
                                "storeMessageField",
                                parseInt(e.target.value)
                              )
                            }
                            direction="col"
                            name="storeMessageField"
                            value={values.storeMessageField}
                            items={[
                              {
                                title: (
                                  <Text
                                    value={translations.STORE_MESSAGE_FEW_ITEMS}
                                  />
                                ),
                                value: 1,
                              },
                              {
                                title: (
                                  <Text
                                    value={translations.STORE_MESSAGE_LESS_THAN}
                                    payload={
                                      values.lowStockField &&
                                      values.lowStockField
                                        ? values.lowStockField
                                        : 0
                                    }
                                  />
                                ),
                                value: 2,
                              },
                            ]}
                            component={RadioList}
                          />
                        </div>
                      </Label>
                    </Row>
                  </Section>
                  <Footer>
                    <Button
                      data-testid="stockManagement-general-save"
                      kind="primary"
                      isSpinning={loading || isStillUpdating}
                    >
                      <Text value={translations.SAVE_CHANGE} />
                    </Button>
                  </Footer>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </Layout>
  )
}
