import React, { useState, useEffect, useContext } from "react"
import { Text } from "components/service"
import cx from "classnames"
import * as translations from "constants/translations"
import { context as localeContext } from "context/locale"

export default ({ name, value = [], onChange, items, areas }) => {
  const [opened, setOpened] = useState()
  const { lang } = useContext(localeContext)
  const [selectedValues, setSelectedValues] = useState(value)

  useEffect(() => {
    setSelectedValues(
      selectedValues.filter((v, i) => selectedValues.indexOf(v) === i)
    )
  }, [value])
  return (
    <>
      {areas && (
        <>
          <div className="border rounded border-gray-300 py-2 mb-3">
            <Row
              isOpened={opened === 0}
              valueCheck={"hidden"}
              onClick={() =>
                opened !== 0 ? setOpened(0) : setOpened(undefined)
              }
              title={
                lang === "en"
                  ? translations.SELECTED_AREAS[0] + ` (${value.length})`
                  : translations.SELECTED_AREAS[1] + ` (${value.length})`
              }
            >
              {!!selectedValues.length ? (
                selectedValues.map((id, i) => {
                  const isActive = value.includes(id)
                  return (
                    <Checkbox
                      isActive={isActive}
                      key={i}
                      title={
                        areas.find(item => item.id === id) ? (
                          <Text value={areas.find(item => item.id === id)} />
                        ) : (
                          " "
                        )
                      }
                      onChange={() => {
                        onChange({
                          target: {
                            name,
                            value: !isActive
                              ? [...value, id]
                              : value.filter(ids => ids !== id),
                          },
                        })
                      }}
                    />
                  )
                })
              ) : (
                <Text className="mx-2" value={translations.NO_SELECTED_AREAS} />
              )}
            </Row>
          </div>
        </>
      )}
      {items.length ? (
        items.map((item, i) => {
          const isOpened = opened === i + 1
          const toggleAll = () => {
            if (value.includes(item[0].id)) {
              let itemTemp = item.map(val => val.id)
              selectedValues.filter(val => !itemTemp.includes(val))
              return onChange({
                target: {
                  name,
                  value: value.filter(val => !itemTemp.includes(val)),
                },
              })
            } else {
              setSelectedValues([...selectedValues, ...item.map(val => val.id)])
              return onChange({
                target: {
                  name,
                  value: [...value, ...item.map(val => val.id)],
                },
              })
            }
          }
          return (
            <div
              key={i}
              className="border rounded border-gray-300 py-2 mb-3"
            >
              <Row
                toggleAll={() => toggleAll()}
                valueCheck={value.includes(item[0].id)}
                title={
                  lang === "en"
                    ? item[0].cityTitleEn + " (" + item.length + ")"
                    : item[0].cityTitleAr + " (" + item.length + ")"
                }
                isOpened={isOpened}
                onClick={() =>
                  !isOpened ? setOpened(i + 1) : setOpened(undefined)
                }
                onChange={onChange}
              >
                <div
                  className={cx(
                    item.length > 5 && "h-40",
                    "mt-2 p-2 overflow-auto",
                    isOpened && "border-t"
                  )}
                >
                  {item.map((item, index) => {
                    const isActive = value.includes(item.id)

                    return (
                      <Checkbox
                        key={index}
                        onChange={() => {
                          onChange({
                            target: {
                              name,
                              value: !isActive
                                ? [...value, item.id]
                                : value.filter(id => id !== item.id),
                            },
                          })
                          !isActive
                            ? setSelectedValues([...selectedValues, item.id])
                            : selectedValues.filter(id => id !== item.id)
                        }}
                        title={<Text value={item} />}
                        isActive={isActive}
                      />
                    )
                  })}
                </div>
              </Row>
            </div>
          )
        })
      ) : (
        <Text value={translations.NO_AREAS_IN_THIS_RANGE} />
      )}
    </>
  )
}

const Row = ({
  isOpened,
  title,
  children,
  onClick,
  onChange,
  toggleAll,
  valueCheck,
}) => {
  const { lang } = useContext(localeContext)

  return (
    <div className="">
      <div className="flex items-center px-2">
        <p className="font-medium">{title}</p>
        <span
          className={cx(
            "flex items-center",
            lang == "ar" ? "mr-auto" : "ml-auto"
          )}
        >
          {valueCheck !== "hidden" && (
            <span
              onClick={() => {
                toggleAll()
              }}
              className="cursor-pointer"
            >
              <Text
                value={
                  valueCheck ? translations.CLEAR_ALL : translations.SELECT_ALL
                }
                className="text-primary-base tex-sm mx-2 inline "
              />
            </span>
          )}

          <span
            onClick={onClick}
            className="material-icons text-base text-primary-base cursor-pointer"
          >
            {!isOpened ? "keyboard_arrow_down" : "keyboard_arrow_up"}
          </span>
        </span>
      </div>
      {isOpened && <div className="mt-3">{children}</div>}
    </div>
  )
}

export const Checkbox = ({ title, isActive, onChange }) => (
  <div className="flex items-center mb-3" onClick={onChange}>
    <span
      className={cx(
        "w-5 h-5 mx-3 rounded border-2 flex items-center justify-center",
        isActive
          ? "border-primary-base bg-primary-base hover:border-gray-300 hover:bg-primary-100 focus:border-gray-300 focus:bg-white active:border-gray-300 active:bg-primary-100"
          : "border-gray-300 hover:bg-primary-100 active:bg-primary-100"
      )}
    >
      {isActive && (
        <i className="material-icons text-sm text-white focus:text-primary-base active:text-primary-base">
          check
        </i>
      )}
    </span>
    {title}
  </div>
)
