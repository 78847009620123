const transformNewVsReturningData = data => {
  const listData = !data
    ? []
    : Object.keys(data).reduce(
        (acc, x) => [
          ...acc,
          {
            date: x,
            new: data[x].new || 0,
            returning: data[x].returning || 0,
          },
        ],
        [],
      );
  return listData;
};

export default transformNewVsReturningData;
