import React from 'react';
import cx from 'classnames';
import moment from 'moment';

import { Text, Link } from 'components/service';
import * as translations from 'constants/translations';
import { useReplaceParams } from 'hooks/index';
import * as paths from 'paths';
import { ReactComponent as NoResults } from 'assets/no-orders.svg';
import { transactionReason } from '../../../../../constants/wallet';

const dataHeader = [
  {
    key: 'amount',
    name: <Text value={translations.AMOUNT} />,
    inView: false,
  },
  {
    key: 'transactionReason',
    name: <Text value={translations.TYPE} />,
    inView: false,
  },
  {
    key: 'createdAt',
    name: <Text value={translations.DATE} />,
    inView: false,
  },
  {
    key: 'orderNumber',
    name: <Text value={translations.ORDER_HASH} />,
    inView: false,
  },
  {
    key: 'userName',
    name: <Text value={translations.CUSTOMER_NAME} />,
    inView: false,
  },
  {
    key: 'userPhoneNumber',
    name: <Text value={translations.PHONE_NUMBER} />,
    inView: false,
  },
];

const WalletTransactionsTable = ({ transactions, selectedStore, lang, translate }) => {
  const replace = useReplaceParams();
  const ordersURL = replace(paths.allOrders, { storeId: selectedStore?.id });

  const getTransactionType = ({ reason }) => {
    switch (reason) {
      case transactionReason.REDEEMED:
        return translate(translations.PAID_BY_WALLET);
      case transactionReason.CANCELED_CASHBACK:
        return translate(translations.CASHBACK_CANCELLED);
      case transactionReason.CASHBACK:
        return translate(translations.CASHBACK_TO_WALLET);
      case transactionReason.REFUNDED:
        return translate(translations.REFUND_TO_WALLET);
      case transactionReason.COMPENSATION:
        return translate(translations.COMPENSATION_TO_WALLET);
      default:
        return '';
    }
  };

  return (
    <table className="w-full border border-gray-300">
      <thead>
        <tr className="font-semibold bg-gray-200">
          {dataHeader.map(item => (
            <td className="p-4" key={item.key}>
              {item.name}
            </td>
          ))}
        </tr>
      </thead>

      <tbody>
        {!transactions?.length && (
          <tr>
            <td className="py-20" colSpan={dataHeader.length}>
              <div className="w-full text-center">
                <NoResults className="mx-auto" />
                <Text value={translations.NO_RESULTS} className="mb-2 mt-6 text-lg font-semibold" />
              </div>
            </td>
          </tr>
        )}
        {transactions?.map(item => (
          <tr key={item.id}>
            <td className={cx(item.transactionType === 'in' ? 'text-zyda-green-600' : 'text-zyda-red-600', 'p-4')}>
              {` ${item.amount} ${translate(selectedStore?.currency)} `}
            </td>
            <td className="p-4">
              {getTransactionType({
                reason: item.transactionReason,
              })}
            </td>
            <td className="p-4">
              {moment(item.createdAt)
                .locale(lang)
                .format('DD MMM YYYY')}
            </td>
            <Link to={`${ordersURL}&search_by=number&search_value=${item.orderNumber}`} omitQuery>
              <td className="p-4">{item.orderNumber}</td>
            </Link>
            <td className="p-4">{item.userName}</td>
            <Link
              omitQuery
              to={`${replace(paths.customerProfile, {
                params: { customerId: item.restaurantCustomerId },
              })}&phoneNumber=${item.userPhoneNumber.replace('+', '')}`}
              className="border-none"
            >
              <td className="p-4">{item.userPhoneNumber}</td>
            </Link>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default WalletTransactionsTable;
