export const CAMPAIGN_TYPES = {
  RETENTION: 'RetentionCampaign',
  CONVERSION: 'ConversionCampaign',
  SEGMENT_TARGETING: 'RetargetingCampaign',
  ANNOUNCEMENT: 'AnnouncementCampaign',
};

export const CAMPAIGN_OBJECT_KEYS = {
  STEP: 'step',
  TITLE: 'title',
  TYPE: 'type',
  INACTIVITY_PERIOD: 'inactivityPeriod',
  ESTIMATE_REACH: 'estimateReach',
  LOOKBACK_WINDOW: 'lookbackWindow',
  SMS_SENDING_LIMIT: 'smsSendingLimit',
  STARTS_AT: 'startsAt',
  EXPIRES_AT: 'expiresAt',
  ADD_VOUCHER: 'AddVoucher',
  VOUCHER_TYPE: 'voucherType',
  VOUCHER_AMOUNT: 'voucherAmount',
  MINIMUM_REQUIREMENTS: 'minimumRequirements',
  VOUCHER_MIN_SUBTOTAL_AMOUNT: 'voucherMinSubtotalAmount',
  VOUCHER_REDEMPTION_LIMIT_PER_USER_ENABLED: 'voucherRedemptionLimitPerUserEnabled',
  VOUCHER_REDEMPTION_LIMIT_PER_USER: 'voucherRedemptionLimitPerUser',
  SMS_BODY: 'smsBody',
  VALID_FOR: 'validFor',
  CAMPAIGN_LANGUAGE: 'campaignLanguage',
  CAMPAIGN_GOAL: 'campaignGoal',
  TARGETED_SEGMENT: 'targetedSegment',
  CREDITS_PER_MESSAGE: 'creditsPerMessage',
  ESTIMATED_CREDITS: 'estimatedCredits',
  ESTIMATE_REACH_WITH_SENDING_LIMIT: 'estimateReachWithSendingLimit',
  USE_CONTACT_IMPORT: 'useContactImport',
  UPLOADED_FILE_NAME: 'uploadedFileName',
};

export const LOOKBACK_WINDOW_KEYS = {
  ALL_CUSTOMERS: 'all_customers',
  LAST_YEAR: 'last_year',
  LAST_6_MONTHS: 'last_6_months',
  LAST_3_MONTHS: 'last_3_months',
  LAST_MONTH: 'last_month',
};

export const CAMPAIGN_LANGUAGES = {
  ENGLISH: 'english',
  ARABIC: 'arabic',
};

export const CAMPAIGN_STATES = {
  ACTIVE: 'activated',
  DRAFT: 'draft',
  SCHEDULED: 'scheduled',
};

export const SMS_BODY_TOOLBOX_TAGS = {
  VOUCHER_VALUE: '=VV',
  VOUCHER_CODE: '=VC',
  VOUCHER_EXPIRY: '=VE',
  DIRECT_LINK: '=DL',
};

export const CREDIT_BALANCE_STATE = {
  BELOW_MINIMUM: 1,
  MEETS_MINIMUM_BELOW_REQUIRED: 2,
  MEETS_REQUIRED: 3,
};

export const NO_ENOUGH_CREDITS = 'not enough credits';

export const TAB_NAMES = {
  SEGMENTS: 'segments',
  CONTACTS: 'contacts',
};
