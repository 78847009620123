import React from "react"
import * as R from "ramda"
import cx from "classnames"

export default ({ children, currentStep }) => {
  return (
    <>
      <div className="flex items-center justify-center bg-gray-200 min-h-screen">
        <div className="flex flex-1 flex-col items-center m-4">{children}</div>
      </div>
      <div className="flex fixed bottom-0 h-1 left-0 w-full">
        {R.times(
          i => (
            <div
              key={i}
              className={cx("flex-1", i <= currentStep && "bg-blue-500")}
            />
          ),
          2
        )}
      </div>
    </>
  )
}
