import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { parse } from 'query-string';

import { breadcrumbs } from 'constants/translations';
import { useQueryState, usePriceFormat } from 'hooks/index';
import { Layout, Breadcrumbs } from 'components/common/dashboard';
import { Table, TableFooter } from 'components/form/elements';
import { Text } from 'components/service';
import { Spinner, Tabs, DropDown } from 'components/kit';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { context as notificationsContext } from 'context/notifications';
import { DateRange, Period } from 'components/common/analytics';
import { parseRange, stringifyRange, toRangeVariable, getRange } from 'utils/date';
import DATE_RANGES from 'constants/date';
import * as translations from 'constants/translations';
import * as paths from 'paths';
import BEHAVIOR_BREAKDOWN from './schemas';
import { exportBehaviorReport } from '../../../../rest';
import { PAYMENT_METHOD_COLUMNS, ANALYTICS_TAB_NAMES } from '../../constants';
import { BEHAVIOR_BREAKDOWN_TABS, DATA_SELECTION } from '../constants';
import { transformBehaviorBreakdownData, formatBehaviorBreakdownData } from './utils';

const PaymentMethods = () => {
  const { lang, direction } = useContext(localeContext);
  const { selectedStore } = useContext(userContext);
  const notifications = useContext(notificationsContext);
  const formatPrice = usePriceFormat();
  const { type_date: typeDate, behavior_date: behaviorDate, behavior_selection: behaviorSelection, range } = parse(
    document.location.search,
  );
  const [transformedData, setTransformedData] = useState([]);
  const resettableStates = { page: 1 };
  const [query, setQuery] = useQueryState(
    {
      type_date: typeDate || DATE_RANGES.THIRTY_DAYS,
      date: behaviorDate || 'day',
      behavior_selection: behaviorSelection || ANALYTICS_TAB_NAMES.totalSales.snakeCaseName,
      ...resettableStates,
      range: range || stringifyRange(getRange(DATE_RANGES.THIRTY_DAYS)),
    },
    {
      page: parseInt,
    },
  );
  useEffect(() => {
    setQuery({ page: 1 });
  }, [query.behavior_selection]);

  const display =
    query.behavior_selection === ANALYTICS_TAB_NAMES.totalSales.snakeCaseName ||
    query.behavior_selection === ANALYTICS_TAB_NAMES.averageOrderValue.snakeCaseName
      ? formatPrice
      : x => x || 0;

  moment.locale(lang === 'ar' ? 'ar-kw' : 'en-gb');

  const parsedRange = parseRange(query.range);
  const rangeVariable = toRangeVariable(parsedRange);
  const LIMIT = 30;
  const { data } = useQuery(BEHAVIOR_BREAKDOWN, {
    variables: {
      storeId: selectedStore.id,
      range: rangeVariable,
      date: query.date,
      breakdown: BEHAVIOR_BREAKDOWN_TABS.PAYMENT_METHOD,
      metric: query.behavior_selection,
      page: query.page,
      limit: LIMIT,
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (data?.behaviorBreakdown?.data)
      setTransformedData(
        transformBehaviorBreakdownData(data?.behaviorBreakdown?.data[DATA_SELECTION[query.behavior_selection]]),
      );
  }, [data, query.behavior_selection]);

  return (
    <Layout
      top={
        <Breadcrumbs
          path={breadcrumbs.PAYMENT_METHODS}
          right={
            <DateRange
              queryTypeDate={query.type_date}
              range={parsedRange}
              query={query}
              setQuery={setQuery}
              selectedStoreCreatedAt={selectedStore.createdAt}
              direction={direction}
              notifications={notifications}
              resettableStates={resettableStates}
            />
          }
        />
      }
    >
      {!data?.behaviorBreakdown?.keys ? (
        <Spinner />
      ) : (
        <div>
          <div className="pb-32 flex flex-col">
            <Text value={translations.BREAKDOWN_BY} className="text-lg font-bold mb-4" />
            <div>
              <div className="mb-4">
                <Tabs
                  items={[
                    {
                      title: <Text value={translations.DELIVERY_VS_PICKUP} />,
                      to: paths.breakdownDeliveryPickup,
                    },
                    {
                      title: <Text value={translations.PAYMENT_METHODS_ANALYTICS} />,
                      to: paths.breakdownPaymentMethods,
                    },
                    {
                      title: <Text value={translations.NEW_VS_RETURNING} />,
                      to: paths.breakdownNewVsReturning,
                    },
                  ]}
                />
              </div>
            </div>
            <div>
              <div className="mb-4 flex justify-between items-center">
                <DropDown
                  float="left"
                  optionSelected={query.behavior_selection}
                  onSelect={option => setQuery({ behavior_selection: option })}
                  data={[
                    {
                      id: ANALYTICS_TAB_NAMES.totalSales.snakeCaseName,
                      title: <Text value={translations.TOTAL_SALES} />,
                    },
                    {
                      id: ANALYTICS_TAB_NAMES.averageOrderValue.snakeCaseName,
                      title: <Text value={translations.AVERAGE_ORDER_VALUE} />,
                    },
                    {
                      id: ANALYTICS_TAB_NAMES.totalOrders.snakeCaseName,
                      title: <Text value={translations.TOTAL_ORDERS} />,
                    },
                    {
                      id: ANALYTICS_TAB_NAMES.totalCustomers.snakeCaseName,
                      title: <Text value={translations.TOTAL_CUSTOMERS} />,
                    },
                  ]}
                  icon="keyboard_arrow_down"
                  position={lang === 'ar' ? 'right' : 'left'}
                />
                <Period value={query.date} onChange={value => setQuery({ page: 1, date: value })} />
              </div>
            </div>
            <div className="w-full">
              <Table
                dataHeader={[
                  {
                    key: 'date',
                    name:
                      query.date === 'day' ? <Text value={translations.DAY} /> : <Text value={translations.MONTH} />,
                    sort: false,
                    inView: false,
                  },
                  ...data?.behaviorBreakdown?.keys.map(item => ({
                    ...PAYMENT_METHOD_COLUMNS[item],
                    sort: false,
                    inView: false,
                  })),
                ]}
                dataBody={transformedData}
                formatData={values => formatBehaviorBreakdownData(values, query.date, display)}
                footer={
                  <TableFooter
                    totalRecords={data?.behaviorBreakdown?.totalCount}
                    pageLimit={LIMIT}
                    currentPage={query.page}
                    onPageChanged={({ currentPage }) => setQuery({ page: currentPage })}
                    totalCount={transformedData.length}
                    exportLink={() =>
                      (window.location.href = exportBehaviorReport(
                        selectedStore.id,
                        parsedRange[0],
                        parsedRange[1],
                        query.date,
                        BEHAVIOR_BREAKDOWN_TABS.PAYMENT_METHOD,
                        query.behavior_selection,
                      ))
                    }
                  />
                }
              />
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default PaymentMethods;
