import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { Text } from 'components/service';
import { context as localeContext } from 'context/locale';
import * as translations from 'constants/translations';

const OrderDetailsUmbrella = ({ order }) => {
  const { direction } = useContext(localeContext);

  return (
    <>
      <div className="mt-4">
        <span className="block text-sm font-semibold">
          <Text value={translations.UMBRELLA_DETAILS} />
        </span>
      </div>
      <div className="w-full border-t border-b border-gray-300 py-4 mt-2 flex flex-col" style={{ direction }}>
        <span className="text-sm pb-1">
          <Text value={translations.UMBRELLA_NUMBER} className="inline" /> {order.beachUmbrella?.number}
        </span>
        {order.beachUmbrella?.specialDirections && (
          <span className="text-sm">
            <Text value={translations.UMBRELLA_INSTRUCTION} className="inline" />
            {order.beachUmbrella?.specialDirections}
          </span>
        )}
      </div>
    </>
  );
};

OrderDetailsUmbrella.propTypes = {
  order: PropTypes.shape({
    beachUmbrella: PropTypes.shape({
      number: PropTypes.string,
      specialDirections: PropTypes.string,
    }),
  }),
};
export default OrderDetailsUmbrella;
