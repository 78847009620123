import { THE_DELIVERY, THE_PICKUP, STORE } from 'constants/translations';
import { DELIVERY_BUSY_MODE } from 'components/common/branch/Busy/utils';

const translateBusyMode = (busyMode, translate) =>
  !!busyMode &&
  (busyMode === DELIVERY_BUSY_MODE.DELIVERY
    ? translate(THE_DELIVERY)
    : busyMode === DELIVERY_BUSY_MODE.PICKUP
    ? translate(THE_PICKUP)
    : busyMode === DELIVERY_BUSY_MODE.BOTH
    ? translate(STORE)
    : null);

export default translateBusyMode;
