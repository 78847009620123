import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Text } from 'components/service';
import { ReactComponent as ProductIcon } from 'assets/product-place-holder.svg';
import { TOTAL_SALES, TOTAL_ORDERS, QUANTITY_SOLD } from 'constants/translations';

const Product = ({ image = '', title, index, totalSales, totalOrders, quantitySold, formatPrice }) => (
  <div className="p-px mx-auto" style={{ maxWidth: '250px' }}>
    <div className="bg-white shadow p-4">
      <div
        className={cx('relative h-32 w-52 mb-4 flex items-center justify-center', !image && 'border border-gray-300')}
      >
        <span className="absolute top-0 left-0 bg-zyda-grey-100 text-primary-base -my-px -mx-px px-2">{index}</span>
        {image ? <img src={image} className="w-full h-full object-cover" alt="" /> : <ProductIcon />}
      </div>
      <span className="font-semibold pb-2 border-b border-gray-300 block truncate">
        <Text value={title} className="truncate" />
      </span>
      <div className="text-sm">
        <div className="flex justify-between py-2 border-b border-gray-300">
          <Text className="text-gray-700 truncate" value={TOTAL_SALES} />
          <div className="font-semibold whitespace-nowrap">{formatPrice(totalSales)}</div>
        </div>
        <div className="flex justify-between py-2 border-b border-gray-300">
          <Text className="text-gray-700 truncate" value={TOTAL_ORDERS} />
          <div className="font-semibold whitespace-nowrap">{totalOrders}</div>
        </div>
        <div className="flex justify-between py-2">
          <Text className="text-gray-700 truncate" value={QUANTITY_SOLD} />
          <div className="font-semibold whitespace-nowrap">{quantitySold}</div>
        </div>
      </div>
    </div>
  </div>
);

Product.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  totalSales: PropTypes.number.isRequired,
  totalOrders: PropTypes.number.isRequired,
  quantitySold: PropTypes.number.isRequired,
  formatPrice: PropTypes.func.isRequired,
};

export default Product;
