import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';

import { BACKEND_GRAPHQL_SERVER } from 'constants/backendGraphqlServer';
import { context as userContext } from 'context/user';
import { context as notificationsContext } from 'context/notifications';
import { Text } from 'components/service';
import * as translations from 'constants/translations';
import * as utils from 'pages/setup/webLinks/utils';
import { UPDATE_ORDERFAST_LINK } from '../schemas';

const useUpdateOrderfastLink = ({ lang }) => {
  const { selectedStore } = useContext(userContext);
  const notifications = useContext(notificationsContext);
  const { subdomain } = selectedStore || {};
  const isArabic = lang === 'ar';

  const [updateOrderfastLink] = useMutation(UPDATE_ORDERFAST_LINK, {
    context: { serverName: BACKEND_GRAPHQL_SERVER },
    onCompleted: data => {
      const { titleAr, titleEn } = data?.updateOrderfastLink;
      notifications.show(<Text value={translations.THE_LINK_UPDATED} payload={(isArabic && titleAr) || titleEn} />);
    },
  });

  const handleOnUpdateOrderfastClick = formData => {
    const { __typename, url, id: orderfastLinkId, ...rest } = formData;
    const variables = { ...rest, content: utils.prependLink(formData), orderfastLinkId, subdomain };
    return updateOrderfastLink({
      variables,
    });
  };

  return [handleOnUpdateOrderfastClick];
};

export default useUpdateOrderfastLink;
