import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import cx from 'classnames';
import { parse } from 'query-string';

import { breadcrumbs } from 'constants/translations';
import { useQueryState, usePriceFormat, useSelectedStore } from 'hooks';
import { Layout, Breadcrumbs } from 'components/common/dashboard';
import { Table, TableFooter } from 'components/form/elements';
import { Text } from 'components/service';
import { Spinner, LoadingContainer } from 'components/kit';
import { context as localeContext } from 'context/locale';
import { context as notificationsContext } from 'context/notifications';
import DATE_RANGES from 'constants/date';
import * as translations from 'constants/translations';
import { ReactComponent as ProductIcon } from 'assets/product-place-holder.svg';
import { DateRange } from 'components/common/analytics';
import { context as userContext } from 'context/user';
import { parseRange, stringifyRange, toRangeVariable, getRange } from 'utils/date';
import { exportProductsReport, exportCategoriesReport } from '../../../../rest';
import * as schemas from '../schemas';

const ProductAnalytics = () => {
  const { lang, direction } = useContext(localeContext);
  const { selectedStore } = useContext(userContext);
  const notifications = useContext(notificationsContext);
  const storeId = useSelectedStore();
  const formatPrice = usePriceFormat();
  const { type_date: typeDate, range } = parse(document.location.search);
  const resettableStates = { products_page: 1, categories_page: 1 };
  const [query, setQuery] = useQueryState(
    {
      type_date: typeDate || DATE_RANGES.THIRTY_DAYS,
      ...resettableStates,
      range: range || stringifyRange(getRange(DATE_RANGES.THIRTY_DAYS)),
    },
    {
      products_page: parseInt,
      categories_page: parseInt,
    },
  );

  const parsedRange = parseRange(query.range);
  const rangeVariable = toRangeVariable(parsedRange);
  const LIMIT = 10;

  const { data: { topSellingProducts } = {}, loading: loadingProducts } = useQuery(schemas.TOP_SELLING_PRODUCTS, {
    variables: {
      storeId,
      range: rangeVariable,
      page: query.products_page,
      limit: LIMIT,
    },
    fetchPolicy: 'cache-and-network',
  });

  const { data: { topSellingCategories } = {}, loading: loadingCategories } = useQuery(schemas.TOP_SELLING_CATEGORIES, {
    variables: {
      storeId,
      range: rangeVariable,
      page: query.categories_page,
      limit: LIMIT,
    },
    fetchPolicy: 'cache-and-network',
  });

  const listData = data =>
    !data
      ? []
      : data.map(item => ({
          product: (
            <div className="flex items-center">
              <div className="w-10 h-10 flex justify-center">
                {item.photoUrl ? (
                  <img src={item.photoUrl} className="object-cover rounded" alt="" />
                ) : (
                  <ProductIcon className="w-10 h-10" />
                )}
              </div>
              <div className="truncate">
                <Text value={item} className="truncate mx-2 inline-block" />
              </div>
            </div>
          ),
          quantitySold: <div style={{ margin: '9.5px 0px' }}>{item.quantitySold}</div>,
          totalSales: formatPrice(item.totalSales),
          totalOrders: item.totalOrders,
        }));

  return (
    <Layout
      top={
        <Breadcrumbs
          path={breadcrumbs.PRODUCTS_ANALYTICS}
          right={
            <DateRange
              queryTypeDate={query.type_date}
              range={parsedRange}
              query={query}
              setQuery={setQuery}
              selectedStoreCreatedAt={selectedStore.createdAt}
              direction={direction}
              notifications={notifications}
              resettableStates={resettableStates}
            />
          }
        />
      }
    >
      <div className={cx('pb-32 flex', lang === 'ar' && 'flex-row-reverse')}>
        <div className="w-full">
          {!topSellingProducts ? (
            <div className="my-2">
              <Spinner />
            </div>
          ) : (
            <>
              <Text value={translations.BEST_SELLING_PRODUCTS} className="mb-4 font-bold text-lg" />
              <LoadingContainer isLoading={loadingProducts}>
                <Table
                  dataHeader={[
                    {
                      key: 'product',
                      name: <Text value={translations.PRODUCTS} />,
                      sort: false,
                      inView: false,
                    },
                    {
                      key: 'quantitySold',
                      name: <Text value={translations.QUANTITY_SOLD} />,
                      sort: false,
                      inView: false,
                    },
                    {
                      key: 'totalOrders',
                      name: <Text value={translations.TOTAL_ORDERS} />,
                      sort: false,
                      inView: false,
                    },
                    {
                      key: 'totalSales',
                      name: <Text value={translations.TOTAL_SALES} />,
                      sort: false,
                      inView: false,
                    },
                  ]}
                  dataBody={listData(topSellingProducts?.data)}
                  footer={
                    <TableFooter
                      totalRecords={topSellingProducts?.totalCount}
                      pageLimit={LIMIT}
                      currentPage={query.products_page}
                      onPageChanged={({ currentPage }) => setQuery({ products_page: currentPage })}
                      totalCount={!!topSellingProducts?.data && topSellingProducts?.data.length}
                      exportLink={() => {
                        exportProductsReport(storeId, parsedRange[0], parsedRange[1])
                          .then(() => notifications.show(<Text value={translations.EXPORT_TOP_SELLING_PRODUCTS} />))
                          .catch(err => notifications.show(err.message));
                      }}
                    />
                  }
                />
              </LoadingContainer>
            </>
          )}
          {!topSellingCategories ? (
            <div className="my-2">
              <Spinner />
            </div>
          ) : (
            <>
              <Text value={translations.TOP_CATEGORIES} className="my-4 font-bold text-lg" />
              <LoadingContainer isLoading={loadingCategories}>
                <Table
                  dataHeader={[
                    {
                      key: 'product',
                      name: <Text value={translations.CATEGORIES} />,
                      sort: false,
                      inView: false,
                    },
                    {
                      key: 'quantitySold',
                      name: <Text value={translations.QUANTITY_SOLD} />,
                      sort: false,
                      inView: false,
                    },
                    {
                      key: 'totalOrders',
                      name: <Text value={translations.TOTAL_ORDERS} />,
                      sort: false,
                      inView: false,
                    },
                    {
                      key: 'totalSales',
                      name: <Text value={translations.TOTAL_SALES} />,
                      sort: false,
                      inView: false,
                    },
                  ]}
                  dataBody={listData(topSellingCategories?.data)}
                  footer={
                    <TableFooter
                      totalRecords={topSellingCategories?.totalCount}
                      pageLimit={LIMIT}
                      currentPage={query.categories_page}
                      onPageChanged={({ currentPage }) => setQuery({ categories_page: currentPage })}
                      totalCount={topSellingCategories?.data.length}
                      exportLink={() => {
                        exportCategoriesReport(storeId, parsedRange[0], parsedRange[1])
                          .then(() => notifications.show(<Text value={translations.EXPORT_TOP_CATEGORIES} />))
                          .catch(err => notifications.show(err.message));
                      }}
                    />
                  }
                />
              </LoadingContainer>
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default ProductAnalytics;
