import React from 'react';
import PropTypes from 'prop-types';

import { Button, Stack } from 'components/kit';
import * as translations from 'constants/translations';
import { Text } from 'components/service';

const DeleteRider = ({ onClose, handleRiderDeletion, rider }) => (
  <div className="px-4">
    <Text value={translations.DELETE_RIDER_CONFIRMATION} className="mb-6" />
    <Stack direction="row-reverse" expandItem={false} spacing={2}>
      <Button
        onClick={() => {
          handleRiderDeletion(rider);
          onClose();
        }}
        type="button"
        kind="secondaryError"
      >
        <Text value={translations.DELETE} />
      </Button>
      <Button onClick={onClose} type="button" kind="tertiary">
        <Text value={translations.DISMISS} />
      </Button>
    </Stack>
  </div>
);

export default DeleteRider;

DeleteRider.propTypes = {
  onClose: PropTypes.func,
  rider: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    assignedOrdersCount: PropTypes.number,
    isActive: PropTypes.bool,
    jobType: PropTypes.string,
    onShift: PropTypes.bool,
    branches: PropTypes.arrayOf(PropTypes.string),
  }),
  handleRiderDeletion: PropTypes.func,
};
