import { gql } from "@apollo/client"

export const MENUITEM = gql`
  query($restaurantId: String!, $id: String!) {
    menuItem(restaurantId: $restaurantId, id: $id) {
      id
      titleAr
      titleEn
      externalId
      descriptionAr
      descriptionEn
      variantsTitleAr
      variantsTitleEn
      menuSectionIds
      publishedBranchIds
      specialNotesPlaceholderAr
      specialNotesPlaceholderEn
      specialNotesEnabled
      specialNotesRequired
      trackingType
      fulfillmentMode
      photoUrl
    }
  }
`

export const INITIAL = gql`
  query($restaurantId: String!, $id: String!) {
    menuItem(restaurantId: $restaurantId, id: $id) {
      id
      titleAr
      titleEn
      externalId
      descriptionAr
      descriptionEn
      variantsTitleAr
      variantsTitleEn
      menuSectionIds
      publishedBranchIds
      specialNotesPlaceholderAr
      specialNotesPlaceholderEn
      specialNotesEnabled
      specialNotesRequired
      trackingType
      fulfillmentMode
      photoUrl
    }
    variants(restaurantId: $restaurantId, menuItemId: $id) {
      id
      titleEn
      titleAr
      price
      prepTime
      externalId
      properties {
        uuid
        titleAr
        titleEn
        minQuantity
        maxQuantity
        multipleAddOnsEnabled
      }
      discountedPrice
      discountEnabled
      images {
        id
        imageUrl
      }
      propertySections {
        id
        variantIds
        titleEn
        titleAr
        minQuantity
        maxQuantity
        multipleAddOnsEnabled
        propertyValues {
          id
          titleEn
          titleAr
          price
          externalId
        }
        variantProperties {
          id
          minQuantity
          maxQuantity
          multipleAddOnsEnabled
        }
        menuItems {
          id
          titleEn
          titleAr
        }
      }
    }
  }
`

export const UPDATE_PRODUCT = gql`
  mutation updateMenuItem(
    $restaurantId: String!
    $menuItemId: String!
    $menuSectionIds: [String]
    $publishedBranchIds: [String]
    $titleEn: String!
    $titleAr: String!
    $descriptionEn: String
    $descriptionAr: String
    $variantsTitleAr: String
    $variantsTitleEn: String
    $variantsAttributes: [variantsValue]
    $externalId: String
    $specialNotesPlaceholderAr: String
    $specialNotesPlaceholderEn: String
    $specialNotesEnabled: Boolean
    $specialNotesRequired: Boolean
    $trackingType: String
    $fulfillmentMode: String
  ) {
    updateMenuItem(
      restaurantId: $restaurantId
      menuItemId: $menuItemId
      menuSectionIds: $menuSectionIds
      publishedBranchIds: $publishedBranchIds
      titleAr: $titleAr
      titleEn: $titleEn
      externalId: $externalId
      descriptionAr: $descriptionAr
      descriptionEn: $descriptionEn
      variantsTitleAr: $variantsTitleAr
      variantsTitleEn: $variantsTitleEn
      variantsAttributes: $variantsAttributes
      specialNotesPlaceholderAr: $specialNotesPlaceholderAr
      specialNotesPlaceholderEn: $specialNotesPlaceholderEn
      specialNotesEnabled: $specialNotesEnabled
      specialNotesRequired: $specialNotesRequired
      trackingType: $trackingType
      fulfillmentMode: $fulfillmentMode
    ) {
      id
      titleAr
      titleEn
      externalId
      descriptionAr
      descriptionEn
      variantsTitleAr
      variantsTitleEn
      menuSectionIds
      publishedBranchIds
      variantsAttributes {
        id
        titleEn
        titleAr
        price
        externalId
        discountedPrice
        prepTime
      }
      specialNotesPlaceholderAr
      specialNotesPlaceholderEn
      specialNotesEnabled
      specialNotesRequired
      trackingType
      fulfillmentMode
    }
  }
`
export const VARIANTS = gql`
  query($restaurantId: String!, $id: String!) {
    variants(restaurantId: $restaurantId, menuItemId: $id) {
      id
      titleEn
      titleAr
      price
      prepTime
      externalId
      discountedPrice
      discountEnabled
      sku
      barCode
      properties {
        uuid
        titleAr
        titleEn
        minQuantity
        maxQuantity
        multipleAddOnsEnabled
      }
      images {
        id
        imageUrl
      }
      propertySections {
        id
        variantIds
        titleEn
        titleAr
        minQuantity
        maxQuantity
        multipleAddOnsEnabled
        propertyValues {
          id
          titleEn
          titleAr
          price
          externalId
        }
        variantProperties {
          id
          minQuantity
          maxQuantity
          multipleAddOnsEnabled
        }
        menuItems {
          id
          titleEn
          titleAr
        }
      }
    }
  }
`
export const CREATE_PRODUCT = gql`
  mutation createMenuItem(
    $restaurantId: String!
    $menuSectionIds: [String]!
    $publishedBranchIds: [String]
    $titleEn: String!
    $titleAr: String!
    $descriptionEn: String
    $descriptionAr: String
    $externalId: String
    $price: String
    $trackingType: String
  ) {
    createMenuItem(
      restaurantId: $restaurantId
      menuSectionIds: $menuSectionIds
      publishedBranchIds: $publishedBranchIds
      titleAr: $titleAr
      titleEn: $titleEn
      descriptionAr: $descriptionAr
      descriptionEn: $descriptionEn
      externalId: $externalId
      price: $price
      trackingType: $trackingType
    ) {
      id
      titleEn
      titleAr
    }
  }
`
export const DELETE_PRODUCT = gql`
  mutation($storeId: String!, $id: String!) {
    deleteMenuItem(restaurantId: $storeId, menuItemId: $id) {
      id
    }
  }
`
