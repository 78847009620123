import Cookies from 'universal-cookie';

import { getCookie } from 'utils';
import { useSelectedStore } from 'hooks';
import { fetchDeliveryZonesMsToken } from 'rest';

const useDeliveryZoneMsToken = () => {
  const cookies = new Cookies();
  const selectedStoreId = useSelectedStore();
  let deliveryZoneMsToken = getCookie('delivery_zone_ms_token');
  const deliveryZoneMsStoreId = getCookie('delivery_zone_ms_store_id');

  if (deliveryZoneMsToken === '' || deliveryZoneMsStoreId !== selectedStoreId) {
    if (selectedStoreId) {
      fetchDeliveryZonesMsToken(selectedStoreId).then(response => {
        cookies.set('delivery_zone_ms_token', response.token);
        cookies.set('delivery_zone_ms_store_id', selectedStoreId);
        deliveryZoneMsToken = response.token;
      });
    }
  }
  return deliveryZoneMsToken;
};

export default useDeliveryZoneMsToken;
