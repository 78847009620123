import React, { useContext } from "react"
import { CreationBox } from "components/kit"
import { Text } from "components/service"
import * as translations from "constants/translations"
import { context as localeContext } from "context/locale"
import { ReactComponent as EmptyProductsDesktop } from "assets/empty-product.svg"
import { ReactComponent as EmptyProductsMobile } from "assets/empty-product-mob.svg"
import { navigate } from "@reach/router"
import { useMobile, useReplaceParams } from "hooks"
import * as paths from "paths.js"
import { withBackParam } from "utils/history"

export default () => {
  const { direction } = useContext(localeContext)
  const isMobile = useMobile()
  const replace = useReplaceParams()

  return (
    <div className="flex flex-col mx-auto max-w-sm items-center ">
      {isMobile ? (
        <EmptyProductsMobile className="w-full" />
      ) : (
        <EmptyProductsDesktop className="w-full" />
      )}
      <CreationBox
        title={<Text value={translations.CREATE_PRODUCT_CATALOG} />}
        description={<Text value={translations.START_ADDING_PRODUCTS} />}
        actions={
          <button
            data-testid="add-product-btn"
            className="text-primary-base"
            style={{ direction }}
            onClick={() => {
              navigate(
                replace(paths.baseProductCreation) + `&${withBackParam()}`
              )
            }}
            type="button"
          >
            <Text
              value={translations.ADD_PRODUCT_EMPTY}
              className="font-semibold"
            />
          </button>
        }
      />
    </div>
  )
}
