import React, { useContext } from "react"
import { useSelectedStore } from "hooks"
import { useMutation } from "@apollo/client"
import { navigate } from "@reach/router"
import { replaceParams } from "utils"
import * as paths from "paths.js"
import { Field, Text } from "components/service"
import { Button, Stack } from "components/kit"
import { Label, Container, Row, Footer } from "components/form/generic"
import * as translations from "constants/translations"
import { context as localeContext } from "context/locale"
import * as schemas from "../schemas"
import { context as notificationsContext } from "context/notifications"

export default ({ catId, onCancel, storeId, refetch, setSelectedCat, titleAr, titleEn}) => {
  const { lang, direction } = useContext(localeContext)
  const notifications = useContext(notificationsContext)

  const [deleteCategory, { loading: deleteCategoryLoading }] = useMutation(
    schemas.DELETE_CATEGORY,
    {
      onCompleted: () => {
        notifications.show(<Text value={translations.CATEGORY_DELETED}  payload={lang === "en" ? titleEn : titleAr}/>)
        refetch()
        setSelectedCat("all")
      },
      onError: () => {
        notifications.show(
          <Text value={translations.SOMETHING_WENT_WRONG} />,
          "error"
        )
      },
    }
  )

  return (
    <>
      <div className="px-6">
        <Text
          value={translations.ARE_YOU_SURE_TO_DELETE_GATEGORY}
          className="mb-3"
        />
        <Text
          value={translations.DELETE_GATEGORY_NOTE}
          className="text-gray-700 text-xs mb-3"
        />
      </div>
      <Footer modalFooter>
        <div className="w-full flex items-center">
          <div className="ml-auto">
            <Row>
              <Button
                data-testid="cancel-delete-category-btn"
                onClick={onCancel}
                type="button"
                kind="tertiary"
              >
                <Text value={translations.DISMISS} />
              </Button>
              <Button
                data-testid="delete-category-btn"
                isSpinning={deleteCategoryLoading}
                onClick={async () => {
                  await deleteCategory({
                    variables: {
                      storeId,
                      id: catId,
                    },
                  })
                  onCancel()
                }}
                kind="secondaryError"
              >
                <Text value={translations.DELETE} />
              </Button>
            </Row>
          </div>
        </div>
      </Footer>
    </>
  )
}
