import React, { useContext } from "react"
import { Text } from "components/service"
import { Button, Stack } from "components/kit"
import { Footer } from "components/form/generic"
import cx from "classnames"

import * as translations from "constants/translations"
import { context as localeContext } from "context/locale"

export const ModalConfirmBoxBody = ({
  onCancel,
  onClick,
  areYouSureText,
  testid,
}) => {
  const { lang } = useContext(localeContext)

  return (
    <div className="flex-col items-center">
      <div className="flex-1 flex-col items-center px-6">
        <span className="text-sm block my-1">
          <Text value={areYouSureText} />
        </span>
      </div>
      <Footer modalFooter isMenu>
        <div className="w-full flex items-center">
          <div className={cx(lang === "ar" ? "mr-auto" : "ml-auto")}>
            <Stack direction={cx(lang === "ar" ? "row-reverse" : "row")}>
              <Button
                onClick={onCancel}
                type="button"
                kind="tertiary"
                data-testid={`cancel-${testid}-btn`}
              >
                <Text value={translations.CANCEL} />
              </Button>
              <Button
                onClick={onClick}
                kind="secondaryError"
                data-testid={`confirm-${testid}-btn`}
              >
                <Text value={translations.CONFIRM} />
              </Button>
            </Stack>
          </div>
        </div>
      </Footer>
    </div>
  )
}

export const ModalConfirmBoxHeader = ({ headerText }) => {
  const { lang } = useContext(localeContext)

  return (
    <div
      className={cx(
        "flex justify-between items-center text-lg",
        lang === "ar" && "flex-row-reverse"
      )}
    >
      <span className="text-lg text-red-600">
        <Text value={headerText} />
      </span>
    </div>
  )
}
