import React from 'react';
import PropTypes from 'prop-types';

import * as translations from 'constants/translations';
import { Text } from 'components/service';
import { Button } from 'components/kit';
import { Row } from 'components/form/generic';
import { selectedZoneIdUtils } from '../../utils';

const BackToTableModal = ({ close, navigateBack }) => (
  <div className="flex flex-col items-center mb-4">
    <div className="mt-4 mb-6">
      <Text value={translations.LEAVE_WITHOUT_SAVE} />
    </div>
    <Row>
      <Button onClick={close} type="button" kind="tertiary">
        <Text value={translations.DISMISS} />
      </Button>
      <Button
        onClick={() => {
          close();
          localStorage.removeItem(selectedZoneIdUtils.SELECTEDZONEID);
          navigateBack();
        }}
        kind="primary"
      >
        <Text value={translations.CONFIRM} />
      </Button>
    </Row>
  </div>
);

BackToTableModal.propTypes = {
  close: PropTypes.func.isRequired,
  navigateBack: PropTypes.func.isRequired,
};

export default BackToTableModal;
