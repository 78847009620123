import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Text } from 'components/service';
import { ACTIVE, INACTIVE } from 'constants/translations';

const ZoneStatus = ({ published, direction }) => (
  <div className="flex flex-row align-middle items-center">
    <span
      className={cx(
        'w-2 h-2 block rounded-full text-base',
        direction === 'rtl' ? 'ml-1' : 'mr-1',
        published ? 'bg-green-600' : 'bg-gray-300',
      )}
    />
    {published ? <Text className="text-xs" value={ACTIVE} /> : <Text className="text-xs" value={INACTIVE} />}
  </div>
);

ZoneStatus.propTypes = {
  published: PropTypes.bool.isRequired,
  direction: PropTypes.oneOf(['ltr', 'rtl']).isRequired,
};

export default ZoneStatus;
