import React, { useContext } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { context as localeContext } from 'context/locale';
import { Text } from 'components/service';

const PostfixLink = ({ url, text, urlAr = '' }) => {
  const { direction } = useContext(localeContext);

  return (
    <a href={urlAr && direction === 'rtl' ? urlAr : url} rel="noreferrer" target="_blank">
      <Text className={cx('inline text-primary-base', direction === 'ltr' ? 'pl-1' : 'pr-1')} value={text} />
    </a>
  );
};

PostfixLink.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.arrayOf(PropTypes.string).isRequired,
  urlAr: PropTypes.string,
};

export default PostfixLink;
