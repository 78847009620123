import React, { createContext, useRef } from 'react';
import NotificationSystem from 'react-notification-system';

export const context = createContext();

export const Provider = ({ children }) => {
  const notificationSystem = useRef();

  return (
    <context.Provider
      value={{
        show: (message, level = 'success', onClick, { isDismisable } = { isDismisable: false }, dismissTime = 15) => {
          notificationSystem.current.addNotification({
            message,
            level,
            position: 'tc',
            autoDismiss: dismissTime,
            ...(isDismisable && { dismissible: 'none' }),
            action: {
              label: 'Action',
              callback: () => {
                notificationSystem.current.clearNotifications();
                onClick && onClick;
              },
            },
          });
        },
      }}
    >
      {children}
      <NotificationSystem ref={notificationSystem} />
    </context.Provider>
  );
};
