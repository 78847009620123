export const ORDERS_LINKS = {
  text: ["Orders' Help Center", 'مركز مساعدة الطلبات'],
  link: [
    'https://help.zyda.com/en/collections/3467243-orders-help-center',
    'https://help.zyda.com/ar/collections/3467243-%D9%85%D8%B1%D9%83%D8%B2-%D9%85%D8%B3%D8%A7%D8%B9%D8%AF%D8%A9-%D8%A7%D9%84%D8%B7%D9%84%D8%A8%D8%A7%D8%AA',
  ],
};

export const CUSTOMERS_LINKS = {
  text: ["Customers' Help Center", 'مركز مساعدة العملاء'],
  link: [
    'https://help.zyda.com/en/collections/3467244-customers-help-center',
    'https://help.zyda.com/ar/collections/3467244-%D9%85%D8%B1%D9%83%D8%B2-%D9%85%D8%B3%D8%A7%D8%B9%D8%AF%D8%A9-%D8%A7%D9%84%D8%B9%D9%85%D9%84%D8%A7%D8%A1',
  ],
};

export const SALES_ANALYTICS_LINKS = {
  text: ["Sales Analytics' Help Center", 'مركز مساعدة تقارير المبيعات'],
  link: [
    'https://help.zyda.com/en/collections/3467252-sales-analytics-help-center',
    'https://help.zyda.com/ar/collections/3467252-%D9%85%D8%B1%D9%83%D8%B2-%D9%85%D8%B3%D8%A7%D8%B9%D8%AF%D8%A9-%D8%AA%D9%82%D8%A7%D8%B1%D9%8A%D8%B1-%D8%A7%D9%84%D9%85%D8%A8%D9%8A%D8%B9%D8%A7%D8%AA',
  ],
};

export const BEHAVIOR_BREAKDOWN_LINKS = {
  text: ["Behaviour Breakdown's Help Center", 'مركز مساعدة التقارير المفصلة'],
  link: [
    'https://help.zyda.com/en/collections/3467269-behaviour-breakdown-help-center',
    'https://help.zyda.com/ar/collections/3467269-%D9%85%D8%B1%D9%83%D8%B2-%D9%85%D8%B3%D8%A7%D8%B9%D8%AF%D8%A9-%D8%A7%D9%84%D8%AA%D9%82%D8%A7%D8%B1%D9%8A%D8%B1-%D8%A7%D9%84%D9%85%D9%81%D8%B5%D9%84%D8%A9',
  ],
};

export const BEST_SELLING_LINKS = {
  text: ["Best Selling's Help Center", 'مركز مساعدة الأكثر مبيعًا'],
  link: [
    'https://help.zyda.com/en/collections/3467270-best-selling-help-center',
    'https://help.zyda.com/ar/collections/3467270-%D9%85%D8%B1%D9%83%D8%B2-%D9%85%D8%B3%D8%A7%D8%B9%D8%AF%D8%A9-%D8%A7%D9%84%D8%A3%D9%83%D8%AB%D8%B1-%D9%85%D8%A8%D9%8A%D8%B9%D9%8B%D8%A7',
  ],
};

export const BRANCHES_DELIVERY_AREAS_LINKS = {
  text: ["Branches & Delivery Areas' Help Center", 'مركز مساعدة الفروع ومناطق التوصيل'],
  link: [
    'https://help.zyda.com/en/collections/3467273-branches-delivery-areas-help-center',
    'https://help.zyda.com/ar/collections/3467273-%D9%85%D8%B1%D9%83%D8%B2-%D9%85%D8%B3%D8%A7%D8%B9%D8%AF%D8%A9-%D8%A7%D9%84%D9%81%D8%B1%D9%88%D8%B9-%D9%88%D9%85%D9%86%D8%A7%D8%B7%D9%82-%D8%A7%D9%84%D8%AA%D9%88%D8%B5%D9%8A%D9%84',
  ],
};
export const BRANCHES_DELIVERY_ZONES_LINKS = {
  text: ["Branches & Delivery Zones' Help Center", 'مركز مساعدة الفروع ومناطق التوصيل'],
  link: [
    'https://help.zyda.com/en/collections/3467273-branches-delivery-areas-help-center',
    'https://help.zyda.com/ar/collections/3467273-%D9%85%D8%B1%D9%83%D8%B2-%D9%85%D8%B3%D8%A7%D8%B9%D8%AF%D8%A9-%D8%A7%D9%84%D9%81%D8%B1%D9%88%D8%B9-%D9%88%D9%85%D9%86%D8%A7%D8%B7%D9%82-%D8%A7%D9%84%D8%AA%D9%88%D8%B5%D9%8A%D9%84',
  ],
};

export const MENU_OVERVIEW_LINKS = {
  text: ["Menu's Help Center", 'مركز مساعدة القائمة'],
  link: [
    'https://help.zyda.com/en/collections/3467274-menu-help-center',
    'https://help.zyda.com/ar/collections/3467274-%D9%85%D8%B1%D9%83%D8%B2-%D9%85%D8%B3%D8%A7%D8%B9%D8%AF%D8%A9-%D8%A7%D9%84%D9%82%D8%A7%D8%A6%D9%85%D8%A9',
  ],
};

export const OPTION_GROUPS_LINKS = {
  text: ["Option Groups' Help Center", 'مركز مساعدة مجموعات الخيارات'],
  link: [
    'https://help.zyda.com/en/collections/3468045-option-groups-help-center',
    'https://help.zyda.com/ar/collections/3468045-%D9%85%D8%B1%D9%83%D8%B2-%D9%85%D8%B3%D8%A7%D8%B9%D8%AF%D8%A9-%D9%85%D8%AC%D9%85%D9%88%D8%B9%D8%A7%D8%AA-%D8%A7%D9%84%D8%AE%D9%8A%D8%A7%D8%B1%D8%A7%D8%AA',
  ],
};

export const ITEM_TRACKING_LINKS = {
  text: ["Item Tracking's Help Center", 'مركز مساعدة تعقب الاصناف'],
  link: [
    'https://help.zyda.com/en/collections/3468048-item-tracking-help-center',
    'https://help.zyda.com/ar/collections/3468048-%D9%85%D8%B1%D9%83%D8%B2-%D9%85%D8%B3%D8%A7%D8%B9%D8%AF%D8%A9-%D8%AA%D8%B9%D9%82%D8%A8-%D8%A7%D9%84%D8%A7%D8%B5%D9%86%D8%A7%D9%81',
  ],
};

export const VOUCHERS_LINKS = {
  text: ["Vouchers' Help Center", 'مركز مساعدة القسائم الشرائية'],
  link: [
    'https://help.zyda.com/en/collections/3468158-vouchers-help-center',
    'https://help.zyda.com/ar/collections/3468158-%D9%85%D8%B1%D9%83%D8%B2-%D9%85%D8%B3%D8%A7%D8%B9%D8%AF%D8%A9-%D8%A7%D9%84%D9%82%D8%B3%D8%A7%D8%A6%D9%85-%D8%A7%D9%84%D8%B4%D8%B1%D8%A7%D8%A6%D9%8A%D8%A9',
  ],
};

export const SOCIAL_MEDIA_LINKS = {
  text: ["Tracking & Social Media's Help Center", 'مركز مساعدة التتبع ووسائل التواصل الاجتماعي'],
  link: [
    'https://help.zyda.com/en/collections/3468161-tracking-social-media-help-center',
    'https://help.zyda.com/ar/collections/3468161-%D9%85%D8%B1%D9%83%D8%B2-%D9%85%D8%B3%D8%A7%D8%B9%D8%AF%D8%A9-%D8%A7%D9%84%D8%AA%D8%AA%D8%A8%D8%B9-%D9%88%D9%88%D8%B3%D8%A7%D8%A6%D9%84-%D8%A7%D9%84%D8%AA%D9%88%D8%A7%D8%B5%D9%84-%D8%A7%D9%84%D8%A7%D8%AC%D8%AA%D9%85%D8%A7%D8%B9%D9%8A',
  ],
};

export const BUSINESS_INFORMATION_LINKS = {
  text: ["Business Information's Help Center", 'مركز مساعدة بينات النشاط التجاري'],
  link: [
    'https://help.zyda.com/en/collections/3468162-business-information-help-center',
    'https://help.zyda.com/ar/collections/3468162-%D9%85%D8%B1%D9%83%D8%B2-%D9%85%D8%B3%D8%A7%D8%B9%D8%AF%D8%A9-%D8%A8%D9%8A%D9%86%D8%A7%D8%AA-%D8%A7%D9%84%D9%86%D8%B4%D8%A7%D8%B7-%D8%A7%D9%84%D8%AA%D8%AC%D8%A7%D8%B1%D9%8A',
  ],
};

export const BRANDING_LINKS = {
  text: ["Branding's Help Center", 'مركز مساعدة العلامات التجارية'],
  link: [
    'https://help.zyda.com/en/collections/3468168-branding-help-center',
    'https://help.zyda.com/ar/collections/3468168-%D9%85%D8%B1%D9%83%D8%B2-%D9%85%D8%B3%D8%A7%D8%B9%D8%AF%D8%A9-%D8%A7%D9%84%D8%B9%D9%84%D8%A7%D9%85%D8%A7%D8%AA-%D8%A7%D9%84%D8%AA%D8%AC%D8%A7%D8%B1%D9%8A%D8%A9',
  ],
};

export const STORE_LAYOUT_LINKS = {
  text: ["Store Layout's Help Center", 'مركز مساعدة تصميم المتجر'],
  link: [
    'https://help.zyda.com/en/collections/3468186-store-layout-help-center',
    'https://help.zyda.com/ar/collections/3468186-%D9%85%D8%B1%D9%83%D8%B2-%D9%85%D8%B3%D8%A7%D8%B9%D8%AF%D8%A9-%D8%AA%D8%B5%D9%85%D9%8A%D9%85-%D8%A7%D9%84%D9%85%D8%AA%D8%AC%D8%B1',
  ],
};

export const BUSINESS_LOCATION_LINKS = {
  text: ["Business Locations' Help Center", 'مركز مساعدة الفروع '],
  link: [
    'https://help.zyda.com/en/collections/3468195-business-locations-help-center',
    'https://help.zyda.com/ar/collections/3468195-%D9%85%D8%B1%D9%83%D8%B2-%D9%85%D8%B3%D8%A7%D8%B9%D8%AF%D8%A9-%D8%A7%D9%84%D9%81%D8%B1%D9%88%D8%B9',
  ],
};

export const DELIVERY_LINKS = {
  text: ["Delivery's Help Center", 'مركز مساعدة التوصيل'],
  link: [
    'https://help.zyda.com/en/collections/3468200-delivery-help-center',
    'https://help.zyda.com/ar/collections/3468200-%D9%85%D8%B1%D9%83%D8%B2-%D9%85%D8%B3%D8%A7%D8%B9%D8%AF%D8%A9-%D8%A7%D9%84%D8%AA%D9%88%D8%B5%D9%8A%D9%84',
  ],
};

export const DELIVERY_LINKS_NEW = {
  text: ["Delivery's Help Center", 'مركز مساعدة التوصيل'],
  link: [
    'https://help.zyda.com/en/articles/6493733-delivery-new-zones-system',
    'https://help.zyda.com/en/articles/6493733-delivery-new-zones-system'
  ],
};

export const DELIVERY_PRODUCT_TOUR = storeId => (
  {
    text: "Take a tour",
    link: `https://dash.zyda.com/${storeId}/setup/delivery/list?lang=en&product_tour_id=371195`,
  }
);




export const MARKETING_CAMPAIGNS_SMS = {
  text: ["SMS Campaigns' Help Center", 'مركز مساعدة حملات الرسائل'],
  link: [
    'https://help.zyda.com/en/collections/3516109-sms-campaigns-help-center',
    'https://help.zyda.com/ar/collections/3516109-%D9%85%D8%B1%D9%83%D8%B2-%D9%85%D8%B3%D8%A7%D8%B9%D8%AF%D8%A9-%D8%AD%D9%85%D9%84%D8%A7%D8%AA-%D8%A7%D9%84%D8%B1%D8%B3%D8%A7%D8%A6%D9%84',
  ],
};



export const ORDER_SCHEDULING_LINKS = {
  text: ["Order Managment's Help Center", 'مركز مساعدة إدارة الطلبات'],
  link: [
    'https://help.zyda.com/en/collections/3468204-order-managment-help-center',
    'https://help.zyda.com/ar/collections/3468204-%D9%85%D8%B1%D9%83%D8%B2-%D9%85%D8%B3%D8%A7%D8%B9%D8%AF%D8%A9-%D8%A5%D8%AF%D8%A7%D8%B1%D8%A9-%D8%A7%D9%84%D8%B7%D9%84%D8%A8%D8%A7%D8%AA',
  ],
};

export const PAYMENT_GATEWAY_LINKS = {
  text: ["Payment Gateways' Help Center", 'مركز مساعدة بوابات الدفع'],
  link: [
    'https://help.zyda.com/en/collections/3468213-payment-gateways-help-center',
    'https://help.zyda.com/ar/collections/3468213-%D9%85%D8%B1%D9%83%D8%B2-%D9%85%D8%B3%D8%A7%D8%B9%D8%AF%D8%A9-%D8%A8%D9%88%D8%A7%D8%A8%D8%A7%D8%AA-%D8%A7%D9%84%D8%AF%D9%81%D8%B9',
  ],
};

export const INTEGRATIONS_LINKS = {
  text: ["Integrations' Help Center", 'مركز مساعدة دمج الخدمات'],
  link: [
    'https://help.zyda.com/en/collections/3468224-integrations-help-center',
    'https://help.zyda.com/ar/collections/3468224-%D9%85%D8%B1%D9%83%D8%B2-%D9%85%D8%B3%D8%A7%D8%B9%D8%AF%D8%A9-%D8%AF%D9%85%D8%AC-%D8%A7%D9%84%D8%AE%D8%AF%D9%85%D8%A7%D8%AA',
  ],
};

export const WEB_LINKS_LINKS = {
  text: ["Web Links' Help Center", 'مركز مساعدة الروابط الخارجية'],
  link: [
    'https://help.zyda.com/en/collections/3468228-web-links-help-center',
    'https://help.zyda.com/ar/collections/3468228-%D9%85%D8%B1%D9%83%D8%B2-%D9%85%D8%B3%D8%A7%D8%B9%D8%AF%D8%A9-%D8%A7%D9%84%D8%B1%D9%88%D8%A7%D8%A8%D8%B7-%D8%A7%D9%84%D8%AE%D8%A7%D8%B1%D8%AC%D9%8A%D8%A9',
  ],
};

export const TEAM_LINKS = {
  text: ["Team's Help Center", 'مركز مساعدة الفريق'],
  link: [
    'https://help.zyda.com/en/collections/3468238-team-help-center',
    'https://help.zyda.com/ar/collections/3468238-%D9%85%D8%B1%D9%83%D8%B2-%D9%85%D8%B3%D8%A7%D8%B9%D8%AF%D8%A9-%D8%A7%D9%84%D9%81%D8%B1%D9%8A%D9%82',
  ],
};

export const BILLING_AND_PLANS_LINKS = {
  text: ["Billing and Plans' Help Center", 'مركز مساعدة الفواتير والباقات'],
  link: [
    'https://help.zyda.com/en/collections/3468242-billing-and-plans-help-center',
    'https://help.zyda.com/ar/collections/3468242-%D9%85%D8%B1%D9%83%D8%B2-%D9%85%D8%B3%D8%A7%D8%B9%D8%AF%D8%A9-%D8%A7%D9%84%D9%81%D9%88%D8%A7%D8%AA%D9%8A%D8%B1-%D9%88%D8%A7%D9%84%D8%A8%D8%A7%D9%82%D8%A7%D8%AA',
  ],
};

export const SALES_AND_REPORTS_LINKS = {
  text: ["Sales Report's Help Center", 'مركز مساعدة المبيعات'],
  link: [
    'https://help.zyda.com/en/collections/3468244-sales-report-help-center',
    'https://help.zyda.com/ar/collections/3468244-%D9%85%D8%B1%D9%83%D8%B2-%D9%85%D8%B3%D8%A7%D8%B9%D8%AF%D8%A9-%D8%A7%D9%84%D9%85%D8%A8%D9%8A%D8%B9%D8%A7%D8%AA',
  ],
};

export const WHAT_IS_LOYALTY = {
  text: ['What is loyalty?', 'ما هي برامج الولاء؟'],
  link: [
    'https://help.zyda.com/en/collections/3467243-orders-help-center',
    'https://help.zyda.com/ar/collections/3467243-%D9%85%D8%B1%D9%83%D8%B2-%D9%85%D8%B3%D8%A7%D8%B9%D8%AF%D8%A9-%D8%A7%D9%84%D8%B7%D9%84%D8%A8%D8%A7%D8%AA',
  ],
};

export const LOYALTY_WATCH_GUIDE = {
  text: ['Watch guide', 'مشاهدة الدليل'],
  link: [
    'https://help.zyda.com/en/collections/3467243-orders-help-center',
    'https://help.zyda.com/ar/collections/3467243-%D9%85%D8%B1%D9%83%D8%B2-%D9%85%D8%B3%D8%A7%D8%B9%D8%AF%D8%A9-%D8%A7%D9%84%D8%B7%D9%84%D8%A8%D8%A7%D8%AA',
  ],
};
