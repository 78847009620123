import React from 'react';
import PropTypes from 'prop-types';

import { allOrders, paymentFailedOrders, waitingForPaymentOrders } from 'paths.js';
import { Text } from 'components/service';
import { ORDER_STATUS } from 'constants/order';
import { SEARCH_RESULTS, FILTER_RESULTS } from 'constants/translations';
import { DropDown } from 'components/kit';
import { changePage } from './utils';
import { orderFilterStatus } from './data';

const OrderFrameSearchResultsFilter = ({ totalCount, query, selectedStatus, replace, isMobile }) => (
  <div className="flex items-center">
    <p className="inline-flex text-lg p-2 font-semibold">
      <Text value={SEARCH_RESULTS} />
      <span className="flex px-1">{`(${totalCount})`}</span>
    </p>
    <div className="ml-auto">
      <DropDown
        float="right"
        label={<Text value={FILTER_RESULTS} />}
        optionSelected={query.search_status || selectedStatus}
        onSelect={option => {
          // TODO: move to a separate utility function
          const appendingQuery = query;

          if (option === ORDER_STATUS.PAYMENT_FAILED) {
            appendingQuery.search_status = null;
            return changePage(
              replace(paymentFailedOrders, {
                omitQuery: true,
              }),
              appendingQuery,
            );
          }
          if (option === ORDER_STATUS.WAITING_FOR_PAYMENT) {
            appendingQuery.search_status = null;
            return changePage(
              replace(waitingForPaymentOrders, {
                omitQuery: true,
              }),
              appendingQuery,
            );
          }
          appendingQuery.search_status = option;
          return changePage(replace(allOrders, { omitQuery: true }), appendingQuery);
        }}
        data={orderFilterStatus}
        icon="keyboard_arrow_down"
        full
        containerStyle={{
          minWidth: !isMobile && '13rem',
        }}
      />
    </div>
  </div>
);

export default OrderFrameSearchResultsFilter;

OrderFrameSearchResultsFilter.propTypes = {
  totalCount: PropTypes.number.isRequired,
  query: PropTypes.shape({ search_status: PropTypes.string }).isRequired,
  selectedStatus: PropTypes.string.isRequired,
  replace: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};
