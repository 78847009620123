import React, { useContext } from "react"
import { Form } from "formik"
import { useMutation } from "@apollo/client"
import { useSelectedStore } from "hooks/index"
import { context as localeContext } from "context/locale"
import { Text, Link } from "components/service"
import { breadcrumbs } from "constants/translations"
import { Layout, Breadcrumbs } from "components/common/dashboard"
import { UserForm, UserData } from "components/common/team"
import { Button } from "components/kit"
import * as paths from "paths.js"
import * as translations from "constants/translations"
import * as schemas from "./schemas"

export default () => {
  const { direction } = useContext(localeContext)
  const selectedStoreId = useSelectedStore()
  const [createUser, { loading }] = useMutation(schemas.ADD_USER, {
    variables: {
      storeId: selectedStoreId,
    },
  })

  return (
    <UserData onSubmit={variables => createUser({ variables })}>
      {({ values }) => (
        <Layout
          top={
            <div>
              <Breadcrumbs
                links={[paths.team]}
                path={breadcrumbs.TEAM_CREATE_USER}
              />
            </div>
          }
        >
          <div style={{ direction }} className="pb-6">
            <Form className="w-full md:w-2/3">
              <UserForm isOperator={values.role === "operator"} />
              <div className="flex justify-end">
                <div className="mr-3">
                  <Link to={paths.team}>
                    <Button kind="secondary">
                      <Text value={translations.CANCEL} />
                    </Button>
                  </Link>
                </div>
                <Button isSpinning={loading} kind="primary">
                  <Text value={translations.ADD} />
                </Button>
              </div>
            </Form>
          </div>
        </Layout>
      )}
    </UserData>
  )
}
