import React from 'react';
import moment from 'moment';

import { DELIVERY_IS_SET, PICKUP_IS_SET, STORE_IS_SET } from 'constants/translations';
import { Text } from 'components/service';

export const DELIVERY_BUSY_MODE = {
  DELIVERY: 'delivery',
  PICKUP: 'pickup',
  BEACH: 'beach',
  MANUAL: 'manual',
  BOTH: 'both',
};

export const BUSY_TYPES = {
  DATETIME: 'datetime',
  DURATION: 'duration',
};

export const TIME_FORMAT = {
  MINUTE: 'minute',
  MINUTES: 'minutes',
  MINS: 'mins',
  HOURS: 'hours',
  HRS: 'hrs',
  YEAR_MONTH_DAY: 'YYYY-MM-DD',
  ENGLISH_FORMAT: 'en-gb',
  HOURS_MINS: 'h:mm A',
  DAY_MONTH_FORMAT: 'DD MMM, h:mm A',
  DATE_FORMAT: 'dd/MM/yyyy',
  ARABIC_FORMAT: 'ar',
};

export const handleErrorMessages = (err, notifications, formikRef) => {
  const body = err?.graphQLErrors[0]?.extensions?.exception?.body;
  Object.keys(body).forEach(key => {
    notifications.show(`${key} ${body[key][0]}`, 'error');
  });
  formikRef.current.setSubmitting(false);
};

export const messageContext = (updateBranch, selectedBranch, lang) => {
  switch (updateBranch.busyMode) {
    case DELIVERY_BUSY_MODE.DELIVERY:
      return <Text value={DELIVERY_IS_SET} payload={lang === 'en' ? selectedBranch.titleEn : selectedBranch.titleAr} />;
    case DELIVERY_BUSY_MODE.PICKUP:
      return <Text value={PICKUP_IS_SET} payload={lang === 'en' ? selectedBranch.titleEn : selectedBranch.titleAr} />;
    case DELIVERY_BUSY_MODE.BOTH:
      return <Text value={STORE_IS_SET} payload={lang === 'en' ? selectedBranch.titleEn : selectedBranch.titleAr} />;
    default:
      return null;
  }
};

export const handleCompleteMutation = (
  data,
  branches,
  selectedBranchId,
  setBranches,
  setIsBusy,
  setBusyTime,
  onCancel,
  timeZone,
  notifications,
  selectedBranch,
  lang,
) => {
  const { updateBranch } = data || {};
  if (setBusyTime) {
    const branchesCopy = [...branches];
    const targetBranch = branchesCopy.find(branch => branch.id === selectedBranchId);
    const targetBranchIndex = branchesCopy.map(branch => branch.id).indexOf(selectedBranchId);
    branchesCopy[targetBranchIndex] = {
      ...targetBranch,
      ...updateBranch,
    };
    setBranches(branchesCopy);

    setIsBusy && setIsBusy(updateBranch.busy);

    if (updateBranch.busyFrom) {
      const busyFrom = moment(updateBranch.busyFrom)
        .tz(timeZone)
        .startOf(TIME_FORMAT.MINUTE);
      const busyUntil = moment(updateBranch.busyUntil)
        .tz(timeZone)
        .startOf(TIME_FORMAT.MINUTE);
      setBusyTime([busyFrom, busyUntil]);
    } else if (updateBranch.busyUntil) {
      setBusyTime(
        moment(updateBranch.busyUntil)
          .tz(timeZone)
          .startOf(TIME_FORMAT.MINUTE),
      );
    } else {
      setBusyTime(0);
      onCancel();
      return;
    }
  }
  onCancel();

  const message = updateBranch.busyMode && messageContext(updateBranch, selectedBranch, lang);
  !!message && notifications.show(message, 'info');
};

export const busyUntilTime = (busyTime, now, timeZone) => {
  let time = null;
  if (busyTime) {
    if (Array.isArray(busyTime)) {
      const [firstElement] = busyTime;
      time = firstElement;
    } else {
      time = busyTime;
    }
  } else {
    time = moment(now)
      .add(30, TIME_FORMAT.MINUTES)
      .tz(timeZone);
  }
  return time;
};

export const handleInitialValues = (busyTime, timeZone, now, busyMode, deliveryOrdersEnabled, pickupOrdersEnabled) => ({
  busyType: busyTime ? BUSY_TYPES.DATETIME : BUSY_TYPES.DURATION,
  busyUntilDuration: [0, TIME_FORMAT.HRS],
  busyFromDate: (!!busyTime && Array.isArray(busyTime) ? busyTime[0] : moment(now).tz(timeZone))
    .locale(TIME_FORMAT.ENGLISH_FORMAT)
    .format(TIME_FORMAT.YEAR_MONTH_DAY),
  busyFromTime: (!!busyTime && Array.isArray(busyTime) ? busyTime[0] : moment(now).tz(timeZone))
    .locale(TIME_FORMAT.ENGLISH_FORMAT)
    .format(TIME_FORMAT.HOURS_MINS),
  busyUntilDate: (busyTime ? (Array.isArray(busyTime) ? busyTime[1] : busyTime) : moment(now).tz(timeZone))
    .locale(TIME_FORMAT.ENGLISH_FORMAT)
    .format(TIME_FORMAT.YEAR_MONTH_DAY),
  busyUntilTime: busyUntilTime(busyTime, now, timeZone)
    .locale(TIME_FORMAT.ENGLISH_FORMAT)
    .format(TIME_FORMAT.HOURS_MINS),
  busyMode:
    (!!busyTime && busyMode) ||
    (deliveryOrdersEnabled && DELIVERY_BUSY_MODE.DELIVERY) ||
    (pickupOrdersEnabled && DELIVERY_BUSY_MODE.PICKUP),
});
