import React, { useContext, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import cx from 'classnames';

import { useQueryState } from 'hooks';
import { breadcrumbs } from 'constants/translations';
import { Layout, Breadcrumbs } from 'components/common/dashboard';
import { Text, Link } from 'components/service';
import * as translations from 'constants/translations';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { Button, Stack, Spinner } from 'components/kit';
import * as paths from 'paths.js';
import { CAMPAIGN_PERMISSIONS } from 'constants/users';
import { getCookie } from 'utils';
import { MARKETING_CAMPAIGNS_SMS } from 'constants/helperLinks';
import * as schemas from './schemas';
import EmptyState from './EmptyState';
import AccessDenied from '../AccessDenied';
import Table from './Table';
import SenderId from '../SenderId';

export default () => {
  const { direction, lang } = useContext(localeContext);
  const { selectedStore, campaignPermission, user } = useContext(userContext);
  const isSupportedPlan =
    campaignPermission !== CAMPAIGN_PERMISSIONS.NOT_SUPPORTED_PLAN && campaignPermission !== CAMPAIGN_PERMISSIONS.FALSE;
  const [query, setQuery] = useQueryState(
    {
      status: 'all',
      page: 1,
    },
    {
      page: parseInt,
    },
  );
  const LIMIT = 10;

  useEffect(() => {
    setQuery({ page: 1 });
  }, [query.status]);

  const campaignsVariables = {
    storeId: selectedStore.id,
    status: query.status,
    page: query.page,
    limit: LIMIT,
  };

  const { data, loading, error } = useQuery(schemas.CAMPAIGNS, {
    fetchPolicy: 'cache-and-network',
    variables: campaignsVariables,
  });

  const logCreateCampaignClicked = () => {
    if (process.env.REACT_APP_SEGMENT_WRITE_KEY) {
      if (getCookie('ajs_user_id') !== '') {
        window.analytics.track(
          'Create Campaign Clicked From Campaigns Tab',
          {
            user_name: user.name,
            user_role: user.roles[0]?.role,
          },
          {
            groupId: selectedStore.id,
            userId: user.id,
          },
        );
      }
    }
  };

  return (
    <Layout
      top={
        <Breadcrumbs
          helperLinks={MARKETING_CAMPAIGNS_SMS}
          path={breadcrumbs.MARKETING_CAMPAIGNS}
          right={
            <>
              {isSupportedPlan && (
                <div className={cx('flex items-center', lang === 'ar' && 'flex-row-reverse')}>
                  <div className={cx('flex items-center', lang === 'ar' ? 'ml-3 flex-row-reverse' : 'mr-3')}>
                    <SenderId title={<Text className={lang === 'ar' ? 'ml-2' : 'mr-2'} value={translations.SENDER_ID} />} />
                  </div>
                  <Stack className={('items-center', lang === 'ar' && 'flex-row-reverse')} direction="wrap">
                    <div className={cx('flex items-center', lang === 'ar' && 'flex-row-reverse')}>
                      <span className="whitespace-nowrap mx-1">
                        <span className="font-semibold">{selectedStore.campaignsSmsCredit}</span>
                        <Text value={translations.CREDITS_LEFT} className="inline mx-2 text-gray-700" />
                      </span>
                      <Link className="w-full" to={paths.campaignSubscriptions}>
                        <Button size="full" kind="secondary">
                          <Text value={translations.PURCHASE} />
                        </Button>
                      </Link>
                    </div>

                    {!!data?.campaigns?.campaigns.length && (
                      <Link className="w-48 mt-4" to={paths.createCampaign} onClick={logCreateCampaignClicked}>
                        <Button size="full" kind="primary">
                          <Text value={translations.CREATE_CAMPAIGN} />
                        </Button>
                      </Link>
                    )}
                  </Stack>
                </div>
              )}
            </>
          }
        />
      }
    >
      <div style={{ direction }}>
        {!isSupportedPlan ? (
          <AccessDenied />
        ) : (
          <div className="pb-20">
            {error ? (
              <div className="my-5 px-4">
                <Text value={translations.FAILED_TO_LOAD_DATA} />
              </div>
            ) : !data ? (
              <Spinner />
            ) : query.status === 'all' && !data.campaigns.campaigns.length ? (
              <EmptyState />
            ) : (
              <Table
                data={data}
                loading={loading}
                campaignsVariables={campaignsVariables}
                query={query}
                setQuery={setQuery}
                LIMIT={LIMIT}
              />
            )}
          </div>
        )}
      </div>
    </Layout>
  );
};
