import React, { useContext } from "react"
import { Query } from "@apollo/client/react/components"
import { Spinner } from "components/kit"
import { useSelectedStore } from "hooks/index"
import { context as userContext } from "context/user"
import * as schemas from "../schemas"
import * as translations from "constants/translations"
import { Text } from "components/service"

export default ({ status }) => {
  const selectedStoreId = useSelectedStore()
  const { selectedStore: { currency } = {} } = useContext(userContext)

  return (
    <Query
      query={schemas.ORDERS}
      variables={{ storeId: selectedStoreId.toString() }}
    >
      {res =>
        res.loading ? (
          <Spinner />
        ) : (
          <div className="flex p-0">
            <div className="flex-1 text-center rounded bg-white shadow px-4 py-2 m-2">
              <span className="w-full block text-sm text-gray-600">
                <Text value={translations.SALES} />
              </span>
              <span className="w-full block text-2xl text-gray-800">
                <Text value={currency} className="inline" />
                2,9000.500
              </span>
              <a
                href="#"
                className="w-full block text-sm text-primary-base hover:underline"
              >
                12% <Text value={translations.HIGHERTHAN} className="inline" />
                &#x2197;
              </a>
            </div>
            <div className="flex-1 text-center rounded bg-white shadow px-4 py-2 m-2">
              <span className="w-full block text-sm text-gray-600">
                <Text value={translations.NO_ORDERS} className="inline" />
              </span>
              <span className="w-full block text-2xl text-gray-800">
                {res.data.orders.totalCount}
              </span>
              <a
                href="#"
                className="w-full block text-sm text-primary-base hover:underline"
              >
                20% <Text value={translations.HIGHERTHAN} className="inline" />
                &#x2197;
              </a>
            </div>
            <div className="flex-1 text-center rounded bg-white shadow px-4 py-2 m-2">
              <span className="w-full block text-sm text-gray-600">
                <Text value={translations.AVG_ORDER} className="inline" />
              </span>
              <span className="w-full block text-2xl text-gray-800">
                <Text value={currency} className="inline" />
                5.500
              </span>
              <a
                href="#"
                className="w-full block text-sm text-gray-600 hover:underline"
              >
                -20% <Text value={translations.LOWERTHAN} className="inline" />
                &#x2199;
              </a>
            </div>
            <div className="flex-1 text-center rounded bg-white shadow px-4 py-2 m-2">
              <span className="w-full block text-sm text-gray-600">
                <Text value={translations.REPEAT_OREDERS} className="inline" />
              </span>
              <span className="w-full block text-2xl">10,430</span>
            </div>
          </div>
        )
      }
    </Query>
  )
}
