import React, { useContext } from "react"
import { useMutation } from "@apollo/client"
import { Button, Stack } from "components/kit"
import * as translations from "constants/translations"
import { context as notificationsContext } from "context/notifications"
import { Text } from "components/service"

const DeleteModal = ({
  onClose,
  querySchema,
  queryVariables,
  queryUpdateFunction,
  onCompletedNotificationText,
  confirmationText,
  secondaryConfirmationText,
}) => {
  const notifications = useContext(notificationsContext)
  const [deleteItem, { loading }] = useMutation(querySchema, {
    variables: queryVariables,
    update: queryUpdateFunction,
    onError: err => {
      let body = err.graphQLErrors[0].extensions.exception.body
      for (let key of Object.keys(body)) {
        notifications.show(`${body[key][0]}`, "error")
      }
    },
    onCompleted: () =>
      notifications.show(<Text value={onCompletedNotificationText} />),
  })

  return (
    <div className="px-4">
      <Text
        value={confirmationText}
        className={secondaryConfirmationText ? "mb-1" : "mb-6"}
      />
      {secondaryConfirmationText && (
        <Text value={secondaryConfirmationText} className="mb-5" />
      )}
      <Stack direction="row-reverse" expandItem={false} spacing={2}>
        <Button
          isSpinning={loading}
          onClick={async () => {
            await deleteItem()
            onClose()
          }}
          type="button"
          kind="secondaryError"
        >
          <Text value={translations.DELETE} />
        </Button>
        <Button onClick={onClose} type="button" kind="tertiary">
          <Text value={translations.DISMISS} />
        </Button>
      </Stack>
    </div>
  )
}
export default DeleteModal
