import React, { useContext } from "react"
import cx from "classnames"
import { Form } from "formik"
import { navigate } from "@reach/router"
import { useQuery, useMutation } from "@apollo/client"
import { useReplaceParams, useSelectedStore } from "hooks/index"
import { context as localeContext } from "context/locale"
import { Text, Link } from "components/service"
import { breadcrumbs } from "constants/translations"
import { Layout, Breadcrumbs } from "components/common/dashboard"
import { UserForm, UserData, DeleteUser } from "components/common/team"
import { Button, Stack, More, Modal, Spinner } from "components/kit"
import * as paths from "paths.js"
import * as translations from "constants/translations"
import { transformUser } from "components/common/team/utils"
import * as schemas from "./schemas"

export default ({ userId }) => {
  const replace = useReplaceParams()
  const selectedStoreId = useSelectedStore()
  const { direction } = useContext(localeContext)
  const user = useQuery(schemas.USER, {
    variables: { userId, storeId: selectedStoreId },
  })
  const [updateUser, { loading }] = useMutation(schemas.UPDATE_USER, {
    variables: {
      storeId: selectedStoreId,
      userId,
    },
  })

  return (
    <Modal>
      {({ open, close }) => (
        <Layout
          top={
            <Breadcrumbs
              links={[paths.team]}
              path={breadcrumbs.TEAM_EDIT_USER}
              right={
                <Stack direction="wrap" expandItem={false} spacing="2">
                  <Button kind="primary" isSpinning={loading} form="user-edit-form">
                    <Text value={translations.SAVE_CHANGE} />
                  </Button>
                  <Link omitQuery to={paths.team}>
                    <Button type="button" kind="secondary">
                      <Text value={translations.CANCEL} />
                    </Button>
                  </Link>
                  <More
                    items={[
                      {
                        title: <Text value={translations.DELETE} />,
                        onClick: () =>
                          open({
                            title: user.data.user.name,
                            body: (
                              <DeleteUser
                                userId={user.data.user.id}
                                onClose={() => {
                                  close();
                                  navigate(replace(paths.team));
                                }}
                              />
                            ),
                            size: 'max-w-md',
                          }),
                      },
                    ]}
                  />
                </Stack>
              }
            />
          }
        >
          <div className={cx('w-full flex-row', direction === 'rtl' && 'ml-auto')}>
            {!user.data ? (
              <div className="mt-5">
                <Spinner />
              </div>
            ) : (
              <UserData initialValues={transformUser(user.data.user)} onSubmit={variables => updateUser({ variables })}>
                {({ values }) => (
                  <Form id="user-edit-form">
                    <div style={{ direction }} className={cx('pb-6', direction === 'rtl' && 'mr-auto')}>
                      <UserForm isOperator={values.role === 'operator'} />
                    </div>
                  </Form>
                )}
              </UserData>
            )}
          </div>
        </Layout>
      )}
    </Modal>
  );
};
