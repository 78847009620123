import React from 'react';
import PropTypes from 'prop-types';

import { Text } from 'components/service';
import * as translations from '../../../constants/translations';

const StatusTag = ({ status }) => {
  const upperCasedStatus = status.toUpperCase();

  const map = {
    ACTIVE: (
      <div className="rounded-full mb-3 bg-green-600 text-xs py-1 px-2 flex items-center">
        <Text value={translations[upperCasedStatus]} className="text-sm font-medium text-white" />
      </div>
    ),
    DEACTIVATED: (
      <div className="flex items-center">
        <Text value={translations[upperCasedStatus]} className="text-sm font-medium text-gray-600" />
      </div>
    ),
    ENDED: (
      <div className="rounded-full mb-3 bg-gray-300 text-xs py-1 px-2 flex items-center">
        <Text value={translations[upperCasedStatus]} className="text-sm font-medium text-gray-600" />
      </div>
    ),
    SCHEDULED: (
      <div className="rounded-full mb-3 bg-gray-100 text-xs py-1 px-2 flex items-center">
        <Text value={translations[upperCasedStatus]} className="text-sm font-medium text-yellow-600" />
      </div>
    ),
  };

  return map[upperCasedStatus];
};

StatusTag.propTypes = {
  status: PropTypes.string,
};

export default StatusTag;
