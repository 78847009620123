import { gql } from '@apollo/client';

export default gql`
  query($storeId: String!) {
    storeInvoices(restaurantId: $storeId) {
      number
      typeHumanize
      currency
      total
      current
      billingCycleStartDate
      billingCycleEndDate
      serviceData {
        titleEn
        titleAr
        interval
        price
      }
      createdAt
      status
      paymentUrl
    }
  }
`;
