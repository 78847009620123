import React from "react"
import { useSelectedStore } from "hooks"
import * as translations from "constants/translations"
import * as schemas from "./schemas"
import * as utils from "./utils"
import DeleteModal from "components/common/DeleteModal"

const DeleteVoucher = ({ onClose, voucherId, vouchersVariables }) => {
  const storeId = useSelectedStore()
  return (
    <DeleteModal
      onClose={onClose}
      queryVariables={{ storeId, voucherId }}
      querySchema={schemas.DELETE_VOUCHER}
      queryUpdateFunction={() => {
        utils.removeVoucher(vouchersVariables, voucherId)
      }}
      onCompletedNotificationText={translations.VOUCHER_DELETED}
      confirmationText={translations.DELETE_VOUCHER_CONFIRMATION}
    />
  )
}
export default DeleteVoucher
