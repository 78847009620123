import gql from "graphql-tag"

export const SMS_PACKAGES = gql`
  query($storeId: String!) {
    smsPackages(restaurantId: $storeId) {
      id
      countryId
      credit
      price
      discount
      total
    }
  }
`
