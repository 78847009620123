import React, { useContext } from "react"
import { parse } from "query-string"

import { context as userContext } from 'context/user';
import { Text, Link } from "components/service"
import * as translations from "constants/translations"
import { breadcrumbs } from "constants/translations"
import { Layout, Breadcrumbs } from "components/common/dashboard"
import { Button, Stack } from "components/kit"
import * as paths from "paths.js"
import { ReactComponent as Illustration } from "assets/location-creation.svg"

export default () => {
  const { location_name } = parse(document.location.search);
  const { settings } = useContext(userContext);


  return (
    <Layout
      top={
        <Breadcrumbs
          links={[paths.setup, paths.branchList]}
          path={breadcrumbs.SETUP_CREATE_BUSINESS_LOCATION}
        />
      }
    >
      <div className="px-6 pb-4 flex flex-col items-center text-center pt-4">
        <Illustration />
        <Text
          className="text-lg mb-1 font-semibold mt-4"
          value={translations.BUSINESS_LOCATION_SUCCESS_TITLE}
          payload={location_name}
        />
        <Text
          className="text-gray-700"
          value={translations.BUSINESS_LOCATION_SUCCESS_DESC}
        />
        <Stack direction="row" className="mt-5">
          <Link to={paths.branchList}>
            <Button kind="secondary">
              <Text value={translations.SKIP} />
            </Button>
          </Link>
          <Link to={settings.useDeliveryzonesMs ? paths.zonesCreation : paths.deliveryCreation}>
            <Button kind="primary">
              <Text value={translations.ADD_DELIVERY_ZONES} />
            </Button>
          </Link>
        </Stack>
      </div>
    </Layout>
  )
}
