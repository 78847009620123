import React, { useState } from "react"
import filesize from "filesize"
import cx from "classnames"
import { Text } from "components/service"
import * as translations from "constants/translations"
import uploadIcon from "assets/upload.svg"

export default ({
  size = "regular",
  direction = "col",
  justify = "center",
  items = "center",
  disabled,
  imageUrl,
  info,
  type,
  ...props
}) => {
  const [file, setFile] = useState(imageUrl)
  const [meta, setMeta] = useState()

  return (
    <div className={cx("relative w-full", disabled && "opacity-50")}>
      <div
        className={cx(
          "relative rounded border-2 border-dashed flex text-gray-600 py-1 px-3 flex-wrap",
          `flex-${direction} justify-${justify} items-${items}`
        )}
      >
        <label className="relative flex items-center flex-1 flex-wrap">
          {file ? (
            meta && <Meta {...meta} />
          ) : (
            <>
              <div className={cx("ml-2", sizes[size].circle.wrap)}>
                <img className="" src={uploadIcon} alt="" />
              </div>
              <p>{info && <div className="m-3">{info}</div>}</p>
              <span className="font-normal text-primary-base py-2 px-4 border rounded border-solid border-gray-400 bg-white border-gray-300 hover:border-transparent hover:bg-gray-200 focus:border-gray-300 focus:text-primary-base active:bg-zyda-grey-100 disabled:border-gray-300 disabled:text-zyda-grey-100">
                <Text value={translations.ADD_FILE} />
              </span>
            </>
          )}

          <input
            {...props}
            disabled={disabled}
            value=""
            type="file"
            style={{ opacity: 0 }}
            className="absolute w-full h-full top-0 left-0"
            onChange={e => {
              const file = e.target.files[0]
              setFile(file)

              props.onChange({
                target: {
                  name: props.name,
                  value: file,
                },
              })

              setMeta({ name: file.name, size: filesize(file.size) })
            }}
          />
        </label>
      </div>
    </div>
  )
}

const Meta = ({ name, size }) => (
  <div className="ml-4 mr-2 flex items-center">
    <div className="mr-1 text-sm text-black">{name}</div>
    <div className="text-xs text-gray-700">{size}</div>
  </div>
)

const sizes = {
  small: {
    types: {
      squared: "w-10 h-10",
      wide: "h-10",
    },
    circle: {
      wrap: "w-6",
      icon: "w-4 h-4 text-xs",
    },
  },
  regular: {
    types: {
      squared: "w-16 h-16",
      wide: "h-16",
    },
    circle: {
      wrap: "w-12",
      icon: "w-8 h-8 text-base",
    },
  },
}
