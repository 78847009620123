import React, { useState, useEffect } from 'react';
import moment from 'moment';
import cx from 'classnames';
import 'moment/locale/en-gb';
import 'moment/locale/ar';
import PropTypes from 'prop-types';

import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { calcBusyTime } from 'components/common/orders/Frame/utils';
import { Button } from 'components/kit';
import SetAsBusy from './SetAsBusy';

const BranchAvailability = ({ branch, lang, timeZone, open, close, setIsBusy }) => {
  const [busyTime, setBusyTime] = useState();

  useEffect(() => {
    const branchBusyTime = calcBusyTime(branch, timeZone);
    setBusyTime(branchBusyTime);
  }, [branch, timeZone]);

  const { titleEn, busyMode, id } = branch || {};

  let busyTimeFormatted = !!busyTime && (Array.isArray(busyTime) ? [...busyTime] : { ...busyTime });
  busyTimeFormatted =
    busyTimeFormatted &&
    (Array.isArray(busyTimeFormatted)
      ? busyTimeFormatted.map(item => item.locale(lang === 'ar' ? 'ar' : 'en-gb').format('ddd DD, MMM YYYY h:mm A'))
      : moment(busyTimeFormatted)
          .locale(lang === 'ar' ? 'ar' : 'en-gb')
          .format('ddd DD, MMM YYYY h:mm A'));

  return (
    <div className="p-2 px-4 border-b border-zyda-grey-50 last:border-b-0 ">
      <div className="pb-2">
        <div className="font-bold">{titleEn}</div>
        {!!busyTimeFormatted && (
          <div className="flex justify-start">
            <span className={cx('flex text-zyda-grey-500 font-normal', lang === 'ar' && 'flex-row-reverse')}>
              <span className="flex-1">
                {Array.isArray(busyTime) ? (
                  <Text
                    prefix={busyMode}
                    value={translations.BUSY_FROM_TO}
                    className="inline"
                    payload={busyTimeFormatted}
                  />
                ) : (
                  <Text prefix={busyMode} value={translations.BUSY_UNTIL(busyTimeFormatted)} className="inline" />
                )}
              </span>
            </span>
          </div>
        )}
      </div>

      <div className="pt-2 pb-4">
        <Button
          data-testid={busyTimeFormatted ? 'edit-avalibility-btn' : 'set-busy-btn'}
          size={busyTimeFormatted ? 'close' : 'option'}
          kind={busyTimeFormatted ? 'borderlessButton' : 'tertiary'}
          onClick={() =>
            open({
              title: (
                <div className={cx('flex justify-between items-center', lang === 'ar' && 'flex-row-reverse')}>
                  <div className="flex flex-col">
                    <Text
                      value={translations.SET_BRANCH_BUSY}
                      payload={lang === 'en' ? branch.titleEn : branch.titleAr}
                    />
                    <Text className="text-xs text-zyda-grey-500 font-normal" value={translations.SET_AVAILABILITY} />
                  </div>
                </div>
              ),
              body: (
                <SetAsBusy branchId={id} onCancel={close} setIsBusy={setIsBusy} busyModeTranslated={id && busyMode} />
              ),
              size: 'max-w-md',
            })
          }
        >
          <Text
            value={busyTimeFormatted ? translations.EDIT_AVALIABILITY : translations.SET_AS_BUSY}
            className={cx(!!busyTimeFormatted && 'text-primary-base font-normal')}
          />
        </Button>
      </div>
    </div>
  );
};

BranchAvailability.propTypes = {
  branch: PropTypes.shape({
    busy: PropTypes.bool,
    busyFrom: PropTypes.string,
    busyMode: PropTypes.string,
    busyUntil: PropTypes.string,
    deliveryOrdersEnabled: PropTypes.bool.isRequired,
    externalId: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    pickupOrdersEnabled: PropTypes.bool.isRequired,
    titleAr: PropTypes.string.isRequired,
    titleEn: PropTypes.string.isRequired,
  }).isRequired,
  close: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  open: PropTypes.func.isRequired,
  setIsBusy: PropTypes.func.isRequired,
  timeZone: PropTypes.string.isRequired,
};
export default BranchAvailability;
