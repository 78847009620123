import React from 'react';
import { Typography, useTheme } from '@zydalabs/zac-react';

import { Field, Text } from 'components/service';
import { ColorPicker } from 'components/form/elements';
import * as translations from 'constants/translations';

const ColorSelector = () => {
  const { colors } = useTheme();

  return (
    <div className="border-b border-gray-300 pb-6">
      <Typography variant="heading16" mt={24}>
        <Text value={translations.BRAND_COLOR} />
      </Typography>
      <Typography variant="body14" mt={4} mb={16} color={colors.positive.secondary}>
        <Text value={translations.SELECT_COLOR} />
      </Typography>
      <div className="flex flex-row justify-between">
        <div>
          <Field name="primaryColor" component={ColorPicker} />
        </div>
      </div>
    </div>
  );
};

export default ColorSelector;
