import { gql } from "@apollo/client"

export const DELIVERY_ZONES_BRANCH = gql`
  query($restaurantId: String!, $branchId: String!, $countryId: Int!) {
    deliveryZonesByBranch(
      restaurantId: $restaurantId
      branchId: $branchId
      countryId: $countryId
    ) {
      id
    }
  }
`
export const ASSIGN_TO_AREA_ZONE_DELIVERY = gql`
  mutation(
    $areaId: String!
    $restaurantId: String!
    $branchId: String!
    $deliveryZoneId: String!
  ) {
    assignToAreaDeliveryZone(
      id: $areaId
      restaurantId: $restaurantId
      branchId: $branchId
      deliveryZoneId: $deliveryZoneId
    ) {
      id
    }
  }
`
