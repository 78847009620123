import React, { useContext } from "react"
import { RadioChaine } from "components/form/elements"
import { Text } from "components/service"
import * as translations from "constants/translations"
import { Field } from "formik"
import { Row, Label } from "components/form/generic"
import { context as localeContext } from "context/locale"
import cx from "classnames"

export default ({ setValueField, fulfillmentMode, isMenu = false }) => {
  const { lang, direction } = useContext(localeContext)
  const fulfillment_type =
    fulfillmentMode === "delivery_and_pickup"
      ? 1
      : fulfillmentMode === "delivery"
      ? 2
      : 3

  return (
    <div
      style={{ direction }}
      className={cx(
        "flex items-center justify-between border border-gray-300 p-4 mb-6 overflow-hidden md:overflow-visible",
        isMenu ? "flex-col" : "flex-col md:flex-row"
      )}
    >
      <div
        className={cx("w-full", isMenu && lang == "ar" && "ml-auto text-right")}
      >
        <p className="text-lg font-semibold">
          <Text value={translations.FULFILLMENT_TITLE} />
        </p>
        <p className="mt-2 mb-3 text-xs text-gray-600">
          <Text value={translations.FULFILLMENT_DESC} />
        </p>
      </div>

      <Row>
        <Label htmlFor="primaryColor">
          <div style={{ marginLeft: "-7px" }}>
            <Field
              onChange={e =>
                setValueField(
                  "fulfillmentMode",
                  parseInt(e.target.value) === 1
                    ? "delivery_and_pickup"
                    : parseInt(e.target.value) === 2
                    ? "delivery"
                    : "pickup"
                )
              }
              direction="col"
              name="fulfillmentMode"
              value={fulfillment_type}
              items={[
                {
                  title: <Text value={translations.FULFILLMENT_DELIVERY} />,
                  value: 2,
                },
                {
                  title: <Text value={translations.FULFILLMENT_PICKUP} />,
                  value: 3,
                },
                {
                  title: <Text value={translations.BOTH} />,
                  value: 1,
                },
              ]}
              component={RadioChaine}
            />
          </div>
        </Label>
      </Row>
    </div>
  )
}
