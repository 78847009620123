import React from "react"
import { Text } from "components/service"

const EmptyState = ({ skeletonComponent, iconComponent, emptyStateText }) => (
  <div className="relative p-16">
    {skeletonComponent}
    <div className="absolute inset-0 flex items-center justify-center">
      <div className="flex flex-col items-center text-center">
        {iconComponent}
        <div className="mt-2  w-1/2">
          <Text value={emptyStateText} />
        </div>
      </div>
    </div>
  </div>
)

export default EmptyState
