import { gql } from "@apollo/client"

export const CLONE_PROPERTY = gql`
  mutation(
    $restaurantId: String
    $propertyId: String
    $titleEn: String
    $titleAr: String
    $externalId: String
  ) {
    cloneProperty(
      restaurantId: $restaurantId
      propertyId: $propertyId
      titleEn: $titleEn
      titleAr: $titleAr
      externalId: $externalId
    ) {
      titleEn
      titleAr
      externalId
    }
  }
`
