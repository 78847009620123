import React from 'react';
import PropTypes from 'prop-types';

import { DELIVERY_DURATION_TEMPLATE } from 'constants/integrations';
import DeliveryDurationTemplate from './DeliveryDurationTemplate';

const DynamicSection = ({ setExtraData, courierDynamicSection }) => {
  const switchSection = section => {
    if (section?.body?.template === DELIVERY_DURATION_TEMPLATE) {
      return <DeliveryDurationTemplate key={section.body.template} setExtraData={setExtraData} />;
    }
    return <></>;
  };
  return courierDynamicSection.map(section => switchSection(section));
};

DynamicSection.propTypes = {
  setExtraData: PropTypes.func.isRequired,
  courierDynamicSection: PropTypes.arrayOf(
    PropTypes.shape({
      body: PropTypes.shape({
        template: PropTypes.string,
      }),
    }),
  ),
};

DynamicSection.defaultProps = {
  courierDynamicSection: [
    {
      body: {
        template: '',
      },
    },
  ],
};

export default DynamicSection;
