import React, { useContext, useState, useEffect } from "react"
import { useSelectedStore } from "hooks/index"
import { context as localeContext } from "context/locale"
import { Text } from "components/service"
import { breadcrumbs } from "constants/translations"
import { Layout, Breadcrumbs } from "components/common/dashboard"
import * as translations from "constants/translations"
import "styled-components/macro"
import { ReactComponent as Done } from "assets/check.svg"
import subs from "../Details/billingSchema"
import plans from "./countryPlanSchema"
import { useQuery } from "@apollo/client"
import { useMobile } from "hooks/index"

const Renew = () => {
  const isMobile = useMobile()
  const { direction } = useContext(localeContext)
  const [plan, setPlan] = useState("")
  const selectedStoreId = useSelectedStore()
  const { REACT_APP_BACKEND_URL } = process.env
  const cc = {
    storeId: selectedStoreId,
  }
  const subsc = useQuery(subs, {
    fetchPolicy: "network-only",
    variables: cc,
  })

  const wrapper = "flex justify-center mt-8"
  const renewContainer = `flex ${
    isMobile ? "flex-wrap justify-center" : "justify-between"
  }`
  const renewPlanBilling = "w-2/3 rounded bg-white"
  const renewPlanBillingWrapper = "flex flex-col h-full px-4 py-8"
  const renewPlanBillingTitleWrapper = "mb-4"
  const renewPlanBillingTitlePlan =
    "text-xs font-medium leading-ease text-gray-1000"
  const renewPlanBillingTitle = "text-xl font-bold leading-normal text-black"
  const renewPlanBillingForm =
    "flex items-center justify-between py-4"
  const renewPlanBillingFormInputLable =
    "text-sm font-semibold leading-normal text-black"
  const renewPlanBillingFormInputStatment = "text-xs leading-ease text-black"
  const renewPlanBillingFormInputStatmentOffer =
    "text-xs leading-ease font-semibold text-green-600"
  const renewPlanBillingFormCost = "text-2xl font-bold leading-pixel text-black"
  const renewPlanBillingFormCostInterval =
    "text-sm leading-none text-gray-1100"
  const renewPlanBillingFormPay = "flex justify-center"
  const renewPlanBillingFormPayButton =
    "w-65 h-10 bg-primary-base rounded text-white mt-6 mb-8 text-sm font-medium leading-7"
  const renewPlanDetails = ` rounded shadow-3xl bg-white p-4 ${
    isMobile ? "w-5/6 mt-4" : "w-31p"
  }`
  const renewPlanDetailsTitle = "text-xl leading-none text-zyda-black-100 mb-2"
  const renewPlanDetailsStatment = "text-sm leading-normal text-gray-1200"
  const renewPlanDetailsOptionsOption = "flex items-center mt-3"
  const renewPlanDetailsOptionsOptionIncluded = `w-5 h-5 ${
    direction === "ltr" ? "mr-2 " : "ml-2"
  }`
  const renewPlanDetailsOptionsOptionName =
    "text-sm font-medium leading-normal text-gray-1200 "

  const renewPlanBillingFormBorders = {
    borderBottom: "1px solid #d8dae5",
    borderTop: "1px solid #d8dae5"
  }
  const BoxShadowStyle = {
    boxShadow: "0 5px 80px 0 rgba(207, 216, 220, 0.3)"
  }
  const DisplayInlineTable = {
    display: "inline-table"
  }

  useEffect(() => {
    if (subs && subsc.data) {
      setPlan(subsc.data.subscription.countryPlanId)
    }
  }, [subsc])

  const countryPlans = useQuery(plans, {
    etchPolicy: "network-only",
    variables: cc,
  })

  const translate = (subscriptionInterval, payIntervals) => {
    let intervals = payIntervals
      ? [
          { id: "month", en: "Monthly", ar: "شهرياً" },
          { id: "annual", en: " Yearly", ar: " سنوياً" },
          { id: "quarter", en: " Quarterly", ar: "ربع سنوياً" },
          {
            id: "semi_annual",
            en: "Semi-annually",
            ar: "نصف سنوياً",
          },
        ]
      : [
          { id: "month", en: "billed monthly", ar: "محاسبة شهرية" },
          { id: "annual", en: "billed annually", ar: "محاسبة سنوية" },
          { id: "quarter", en: "billed quarterly", ar: "محاسبة ربع سنوية" },
          {
            id: "semi_annual",
            en: "billed semi-annually",
            ar: "محاسبة نصف سنوية",
          },
          { id: "per_month", en: "per month", ar: " شهرياً" },
        ]
    let interval = intervals.filter(
      interval => interval.id === subscriptionInterval
    )[0]
    if (direction === "ltr") {
      return interval ? interval.en : ""
    } else {
      return interval ? interval.ar : ""
    }
  }

  const label = interval => {
    let labels = [
      { id: "month", en: "Month", ar: "شهري" },
      { id: "annual", en: " Annual", ar: " سنوي" },
      { id: "quarter", en: " Quarter", ar: "ربع سنوي" },
      {
        id: "semi_annual",
        en: "Semi-annual",
        ar: "نصف سنوي",
      },
    ]
    let label = labels.filter(label => label.id === interval)[0]
    if (direction === "ltr") {
      return label ? label.en : ""
    } else {
      return label ? label.ar : ""
    }
  }

  return (
    <div>
      {subsc.data ? (
        <Layout
          top={
            <div className="px-4">
              <Breadcrumbs
                path={breadcrumbs.Renew_Subscription}
              />
            </div>
          }
        >
          <div className={wrapper}>
            <div className={renewContainer} style={{width: "93%"}}>
              <div 
              className={renewPlanBilling}
              style={BoxShadowStyle, DisplayInlineTable}
              >
                {subsc.data && (
                  <div
                    style={{ direction }}
                    className={renewPlanBillingWrapper}
                  >
                    <div className={renewPlanBillingTitleWrapper}>
                      <p className={renewPlanBillingTitlePlan}>
                        <Text value={translations.CURRENT_PLAN} />
                      </p>
                      <p className={renewPlanBillingTitle}>
                        {direction === "ltr"
                          ? subsc.data.subscription.serviceData.titleEn.split(
                              " "
                            )[0] !== "Legacy"
                            ? subsc.data.subscription.serviceData.titleEn.split(
                                " "
                              )[0]
                            : subsc.data.subscription.serviceData.titleEn.split(
                                " "
                              )[1]
                          : subsc.data.subscription.serviceData.titleAr.split(
                              " "
                            )[1]}
                      </p>
                    </div>
                    <div className={renewPlanBillingForm} style={renewPlanBillingFormBorders}>
                      <form>
                        {countryPlans.data &&
                          countryPlans.data.countryPlans.map(countryPlan => (
                            <div>
                              <input
                                type="radio"
                                id={countryPlan.interval}
                                name="Billing"
                                value={plan}
                                onChange={() => setPlan(countryPlan.id)}
                                checked={plan === countryPlan.id}
                              />
                              <label
                                className={renewPlanBillingFormInputLable}
                                for={countryPlan.interval}
                              >
                                {" "}
                                {label(countryPlan.interval)}
                              </label>
                              <p className={renewPlanBillingFormInputStatment}>
                                {direction === "ltr"
                                  ? subsc.data.subscription.restaurant.currency
                                      .titleEn
                                  : subsc.data.subscription.restaurant.currency
                                      .titleAr}{" "}
                                {countryPlan.monthlyPrice} /{" "}
                                {translate("per_month", false)},
                                {translate(countryPlan.interval, false)}
                                <span
                                  className={
                                    renewPlanBillingFormInputStatmentOffer
                                  }
                                >
                                  {direction === "ltr"
                                    ? countryPlan.discountTextEn
                                    : countryPlan.discountTextAr}
                                </span>
                              </p>
                            </div>
                          ))}
                      </form>
                      <div>
                        {countryPlans.data &&
                          countryPlans.data.countryPlans
                            .filter(countryPlan => countryPlan.id === plan)
                            .map(chosenPlan => (
                              <p className={renewPlanBillingFormCost}>
                                {direction === "ltr"
                                  ? subsc.data.subscription.restaurant.currency
                                      .titleEn
                                  : subsc.data.subscription.restaurant.currency
                                      .titleAr}
                                {chosenPlan.price}{" "}
                                <span
                                  className={renewPlanBillingFormCostInterval}
                                >
                                  / {translate(chosenPlan.interval, true)}
                                </span>
                              </p>
                            ))}
                      </div>
                    </div>
                    <a
                      className={renewPlanBillingFormPay}
                      href={
                        plan === subsc.data.subscription.countryPlanId
                          ? subsc.data.subscription.invoices.filter(
                              invoice => invoice.current === true
                            )[0] &&
                            subsc.data.subscription.invoices.filter(
                              invoice => invoice.current === true
                            )[0].paymentUrl
                          : `${REACT_APP_BACKEND_URL}/restaurants/${selectedStoreId}/invoices/change_plan?country_plan_id=${plan}`
                      }
                    >
                      <button className={renewPlanBillingFormPayButton}>
                        <Text value={translations.PAY_NOW} />
                      </button>
                    </a>
                  </div>
                )}
              </div>
              <div style={{ direction }} className={renewPlanDetails} style={BoxShadowStyle}>
                <p className={renewPlanDetailsTitle}>
                  {direction === "ltr"
                    ? subsc.data.subscription.serviceData.titleEn.split(
                        " "
                      )[0] !== "Legacy"
                      ? subsc.data.subscription.serviceData.titleEn.split(
                          " "
                        )[0]
                      : subsc.data.subscription.serviceData.titleEn.split(
                          " "
                        )[1]
                    : subsc.data.subscription.serviceData.titleAr.split(" ")[1]}
                </p>
                <p className={renewPlanDetailsStatment}>
                  {direction === "ltr"
                    ? subsc.data.subscription.countryPlan.plan.descriptionEn
                    : subsc.data.subscription.countryPlan.plan.descriptionAr}
                </p>
                <ul className="pt-3">
                  {subsc.data &&
                    (direction === "ltr"
                      ? subsc.data.subscription.countryPlan.plan.featuresEn
                      : subsc.data.subscription.countryPlan.plan.featuresAr
                    ).map(option => (
                      <li className={renewPlanDetailsOptionsOption}>
                        <div>
                          <Done
                            className={renewPlanDetailsOptionsOptionIncluded}
                          />
                        </div>
                        <div>
                          <p className={renewPlanDetailsOptionsOptionName}>
                            {option}
                          </p>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </Layout>
      ) : (
        ""
      )}
    </div>
  )
}

export default Renew
