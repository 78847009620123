import { gql } from '@apollo/client';

export const LEGAL_DATA = gql`
  query($storeId: String!) {
    legalData(restaurantId: $storeId) {
      email
      firstName
      lastName
      nationalNumber
      phoneNumber
      nationalBackCopyUrl
      nationalFrontCopyUrl
      iban
      companyNameEn
      companyNameAr
      vatIdNumber
      licenseNumber
      licenseCopyUrl
      authorizedSignatoryCopyUrl
      isLicensed
      countryCode
    }
  }
`;

export const PAYMENT_CREDENTIALS = gql`
  query($storeId: String!) {
    paymentCredentials(restaurantId: $storeId) {
      id
      merchantId
      countryCode
      accountType
      profileName
      paymentGatewayId
      active
      transfer
      status
      publicApiKey
      remarks
      licenseCopyUrl
      authorizedSignatoryCopyUrl
      nationalFrontCopyUrl
      nationalBackCopyUrl
      contactInfo {
        firstName
        lastName
        emailAddress
        password
        mobileNumber
        idNumber
        idBackCopyUrl
        idFrontCopyUrl
      }
      bankAccountInfo {
        iban
        bankName
        bankId
        beneficiaryAddress
        bankAddress
        swiftCode
        accountName
      }
      corporateInfo {
        name
        licenseNumber
      }
      restaurantPaymentMethods {
        name
        enabled
        id
        referenceId
      }
    }
  }
`;

export const ADD_PAYMENT_CREDENTIALS = gql`
  mutation(
    $restaurantId: String!
    $paymentGatewayId: Int
    $paymentDepositTermId: Int
    $profileName: String
    $accountType: String
    $firstName: String
    $lastName: String
    $emailAddress: String
    $password: String
    $mobileNumber: String
    $isLicensed: Boolean
    $idNumber: String
    $iban: String
    $bankName: String
    $bankId: Int
    $beneficiaryAddress: String
    $bankAddress: String
    $swiftCode: String
    $accountName: String
    $accountNumber: String
    $name: String
    $licenseNumber: String
    $licenseCopyUrl: String
    $authorizedSignatoryCopyUrl: String
    $nationalFrontCopyUrl: String
    $nationalBackCopyUrl: String
  ) {
    addPaymentCredentials(
      restaurantId: $restaurantId
      paymentGatewayId: $paymentGatewayId
      paymentDepositTermId: $paymentDepositTermId
      bankId: $bankId
      profileName: $profileName
      accountType: $accountType
      firstName: $firstName
      lastName: $lastName
      emailAddress: $emailAddress
      password: $password
      mobileNumber: $mobileNumber
      idNumber: $idNumber
      iban: $iban
      bankName: $bankName
      beneficiaryAddress: $beneficiaryAddress
      bankAddress: $bankAddress
      swiftCode: $swiftCode
      accountName: $accountName
      accountNumber: $accountNumber
      isLicensed: $isLicensed
      name: $name
      licenseNumber: $licenseNumber
      licenseCopyUrl: $licenseCopyUrl
      authorizedSignatoryCopyUrl: $authorizedSignatoryCopyUrl
      nationalFrontCopyUrl: $nationalFrontCopyUrl
      nationalBackCopyUrl: $nationalBackCopyUrl
    ) {
      id
      isLicensed
      # accountType
      # profileName
      # firstName
      # lastName
      # emailAddress
      # password
      # mobileNumber
      # idNumber
      # iban
      # bankName
      # beneficiaryAddress
      # bankAddress
      # swiftCode
      # accountName
      # licenseNumber
    }
  }
`;
