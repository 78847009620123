import React, { useContext } from 'react';
import cx from 'classnames';

import { Text } from 'components/service';
import { context as localeContext } from 'context/locale';
import * as translations from 'constants/translations';
import { ReactComponent as GlobalIcon } from 'assets/globe-outline.svg';

const ManualPaymentMethodCard = () => {
  const { lang } = useContext(localeContext);
  return (
    <div className={cx('flex flex-col -mt-2 mb-2', lang === 'en' ? 'ml-4' : ' mr-4')}>
      <div className="font-medium text-lg flex items-center">
        <GlobalIcon className={lang === 'en' ? 'mr-2' : 'ml-2'} />
        <Text className="text-gray-600 text-sm" value={translations.WORLDWIDE} />
      </div>
    </div>
  );
};

export default ManualPaymentMethodCard;
