import React, { useContext, useState } from "react"
import { useQuery } from "@apollo/client"
import { context as localeContext } from "context/locale"
import { breadcrumbs } from "constants/translations"
import { context as userContext } from "context/user"
import { Layout, Breadcrumbs } from "components/common/dashboard"
import * as paths from "paths.js"
import cx from "classnames"
import Frame from "./Frame"
import OptionGroupTable from "./Table/OptionGroupTable"
import { Stack, Button, Modal } from "components/kit"
import { ReactComponent as AddIcon } from "assets/add.svg"
import * as translations from "constants/translations"
import { Text } from "components/service"
import OptionGroupModal from "./OptionGroupModal/OptionGroupModal"
import OptionsModel from "./OptionsModal/OptionsModal"
import * as schemas from "./Table/schemas"
import { useSelectedStore, useQueryState } from "hooks"
import { OPTION_GROUPS_LINKS } from "constants/helperLinks"

const MenuOptionGroups = () => {
  const storeId = useSelectedStore()
  const { lang, direction } = useContext(localeContext)
  const { branches, hasRole } = useContext(userContext)
  const [optionGroupId, setOptionGroupId] = useState("all")
  const [optionGroupSelected, setOptionGroupSelected] = useState("all")

  const [localOptions, setLocalOptions] = useState([])

  const [query, setQuery] = useQueryState({
    location: branches ? branches[0].id : null,
    status: "all",
    page: 1,
  })

  const { isPosCourier: isFoodics } = useContext(userContext)

  const handleSettingOptionId = id => {
    setOptionGroupId(id)
  }
  const allOptionsVariables = {
    storeId,
    page: parseInt(query.page),
    limit: 10,
    title: query.title ? query.title : null,
  }
  const allOptionsQuery = useQuery(schemas.ALL_OPTIONS, {
    variables: allOptionsVariables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  })

  const optionGroupsQuery = useQuery(schemas.OPTION_GROUPS, {
    variables: { storeId },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  })

  const optionsQueryVariables = {
    storeId,
    propertySectionId: optionGroupId,
    title: query.search ? query.search : null,
  }

  const optionsQuery = useQuery(schemas.OPTIONS, {
    variables: optionsQueryVariables,
    skip: optionGroupId === "all",
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  })

  return (
    <Modal>
      {({ open, close }) => {
        const addOptionsDisable =
          optionGroupsQuery?.data?.propertySections === null ||
          optionGroupsQuery.loading
        return (
          <Layout
            top={
              <Breadcrumbs
                isMenu
                isOptions
                reverse
                helperLinks={OPTION_GROUPS_LINKS}
                customTitle={
                  <Text
                    value={translations.OPTION_GROUPS}
                    className="text-2xl font-bold"
                  />
                }
                right={
                  !hasRole("operator") && (
                    <div style={{ direction }}>
                      <Stack expandItem={false} direction="wrap">
                        <Button
                          data-testid="optionGroups-add-option"
                          kind="primary"
                          size="baseGroup"
                          disabled={addOptionsDisable}
                          className="w-40"
                          style={{
                            direction,
                          }}
                          onClick={() => {
                            open({
                              title: (
                                <Text
                                  value={translations.OPTION_GROUPS_ADD_OPTION}
                                  className="text-lg"
                                />
                              ),
                              body: (
                                <OptionsModel
                                  type="add"
                                  initialValues={{ isFoodics, optionGroupId }}
                                  onCancel={close}
                                  openModel={open}
                                  refetchAllOptions={allOptionsQuery}
                                  refetchAllGroup={optionGroupsQuery}
                                  refetchOptions={optionsQuery}
                                  setOptionGroupSelected={setOptionGroupSelected}
                                />
                              ),
                            })
                          }}
                        >
                          <AddIcon
                            className={cx(lang === "ar" ? "ml-2" : "mr-2")}
                          />
                          <Text
                            value={translations.OPTION_GROUPS_ADD_OPTION}
                            className="font-semibold"
                          />
                        </Button>
                        <Button
                          data-testid="optionGroups-add-group"
                          kind="secondary"
                          size="baseGroup"
                          className="w-40"
                          style={{
                            direction,
                          }}
                          onClick={() => {
                            open({
                              testId: "list-products-add-category",
                              title: (
                                <Text
                                  value={
                                    translations.OPTION_GROUPS_ADD_GROUP_MODAL
                                  }
                                  className="text-lg"
                                />
                              ),
                              body: (
                                <OptionGroupModal
                                  type="add"
                                  onCancel={close}
                                  openModel={open}
                                  refetchAllOptions={allOptionsQuery}
                                  refetchAllGroup={optionGroupsQuery}
                                  setOptionGroupSelected={setOptionGroupSelected}
                                />
                              ),
                            })
                          }}
                        >
                          <AddIcon
                            className={cx(lang === "ar" ? "ml-2" : "mr-2")}
                          />
                          <Text
                            value={translations.OPTION_GROUPS_ADD_GROUP}
                            className="font-semibold"
                          />
                        </Button>
                      </Stack>
                    </div>
                  )
                }
              />
            }
          >
            <div
              className={cx(
                "pb-6 flex",
                lang === "ar" && "flex-row-reverse"
              )}
            >
              <div className="w-full pb-12">
                <Frame
                  emptyGroups={addOptionsDisable}
                  query={query}
                  setQuery={setQuery}
                  optionGroupId={optionGroupId}
                >
                  {(query, setQuery, langSelected) => (
                    <OptionGroupTable
                      query={query}
                      setQuery={setQuery}
                      langSelected={langSelected}
                      optionGroupsQuery={optionGroupsQuery}
                      allOptionsQuery={allOptionsQuery}
                      optionsQuery={optionsQuery}
                      fetchOptionGroupId={handleSettingOptionId}
                      setLocalOptions={setLocalOptions}
                      localOptions={localOptions}
                      optionGroupSelected={optionGroupSelected}
                    />
                  )}
                </Frame>
              </div>
            </div>
          </Layout>
        )
      }}
    </Modal>
  )
}
export default MenuOptionGroups
