import React, { useContext } from 'react';
import { Formik, Form } from 'formik';

import { Field, Text } from 'components/service';
import { RadioList } from 'components/form/elements';
import {
  DELIVERECT_MODAL_TAX_TYPE,
  DELIVERECT_MODAL_TAX_INCLUSIVE,
  DELIVERECT_MODAL_TAX_EXCLUSIVE,
} from 'constants/translations';
import { TAX_INCLUSIVE, TAX_EXCLUSIVE } from 'constants/integrations';
import { context } from '../../context';

const Taxes = () => {
  const { taxType, setTaxType } = useContext(context);

  const changeData = e => {
    setTaxType(e.target.value);
  };

  return (
    <Formik>
      <Form>
        <div className="flex justify-between items-center w-full p-4 border">
          <span className="text-sm">
            <Text value={DELIVERECT_MODAL_TAX_TYPE} />
          </span>

          <Field
            onChange={changeData}
            direction="row"
            value={taxType}
            name="taxes"
            items={[
              {
                title: <Text value={DELIVERECT_MODAL_TAX_INCLUSIVE} />,
                value: TAX_INCLUSIVE,
              },
              {
                title: <Text value={DELIVERECT_MODAL_TAX_EXCLUSIVE} />,
                value: TAX_EXCLUSIVE,
              },
            ]}
            component={RadioList}
          />
        </div>
      </Form>
    </Formik>
  );
};

export default Taxes;
