import { gql } from "@apollo/client"

export const UPDATE_PUBLISH_DELIVERY_ZONE = gql`
  mutation(
    $id: String!
    $restaurantId: String!
    $branchId: String!
    $published: Boolean!
  ) {
    updateDeliveryZone(
      id: $id
      restaurantId: $restaurantId
      branchId: $branchId
      published: $published
    ) {
      id
      published
    }
  }
`
