import React, { useContext, useState } from "react"
import { useQuery } from "@apollo/client"
import cx from "classnames"
import { Text } from "components/service"
import { Field } from "components/service"
import { Label, Row } from "components/form/generic"
import { Button, Uploader, CatalogBox } from "components/kit"
import {
  Input,
  Textarea,
  MultiSelect,
  Stepper,
  MultiCheckbox,
} from "components/form/elements"
import { context as localeContext } from "context/locale"
import { context as userContext } from "context/user"
import { Spinner } from "components/kit"
import * as translations from "constants/translations"
import * as schemas from "./schemas"
import { ReactComponent as Trash } from "assets/trash.svg"

export default ({
  pageType,
  price = false,
  isDescriptionVisible,
  open,
  close,
  setFieldValue,
  productImage,
  imageTokenSource,
  deleteProductImage,
}) => {
  const { lang } = useContext(localeContext)
  const { selectedStore: selected, isPosCourier: isPosType } = useContext(userContext)
  const [descriptionVisable, setDescriptionVisable] = useState(
    isDescriptionVisible || false
  )
  const currency = selected.currency
  const { loading: catLoading, data: dataCategories } = useQuery(
    schemas.CATEGORIES,
    {
      variables: {
        storeId: selected.id,
      },
    }
  )
  const { loading: branchesLoading, data: dataBranches } = useQuery(
    schemas.BRANCHES,
    {
      variables: {
        storeId: selected.id,
      },
    }
  )

  const handleRemoveDescription = () => {
    setFieldValue("descriptionEn", null)
    setFieldValue("descriptionAr", null)
    setDescriptionVisable(!descriptionVisable)
  }
  return catLoading && branchesLoading ? (
    <Spinner />
  ) : (
    <div className="flex flex-wrap justify-between">
      <div className={cx("w-full md:w-2/3")}>
        <CatalogBox
          title={<Text value={translations.BASIC_INFO} className="inline" />}
          body={
            <>
              <Text
                value={translations.PRODUCT_NAME}
                className="font-medium text-base py-2"
              />
              <Row>
                <div className="flex flex-col md:flex-row justify-between items-start">
                  <div
                    className={cx(
                      "w-full mb-6",
                      lang === "en" ? "mr-0 md:mr-4" : "ml-0 md:ml-4"
                    )}
                  >
                    <Label title={<Text value={translations.ENGLISH} />}>
                      <Field
                        data-testid={`${pageType}-product-name-en`}
                        type="text"
                        name="titleEn"
                        placeholder={translations.ENTER_PRODUCT_NAME[0]}
                        component={Input}
                        direction="ltr"
                      />
                    </Label>
                  </div>
                  <div className="w-full mb-6">
                    <Label title={<Text value={translations.ARABIC} />}>
                      <Field
                        data-testid={`${pageType}-product-name-ar`}
                        direction="rtl"
                        type="text"
                        name="titleAr"
                        placeholder={translations.ENTER_PRODUCT_NAME[1]}
                        component={Input}
                      />
                    </Label>
                  </div>
                </div>
              </Row>

              {isPosType && (
                <Row className="py-3">
                  <Label
                    title={<Text value={translations.External_ID} />}
                    subtitle={<Text value={translations.EXTERNAL_ID_DESC} />}
                  >
                    <Field
                      type="text"
                      name="externalId"
                      data-testid={`${pageType}-product-externalId`}
                      placeholder={translations.ENTER_EXTERNAL_ID[0]}
                      component={Input}
                      hideError={true}
                    />
                  </Label>
                </Row>
              )}
              {!descriptionVisable ? (
                <button
                  className="text-primary-base"
                  onClick={handleRemoveDescription}
                >
                  <Text
                    value={translations.ADD_DESCRIPTION}
                    className="text-primary-base"
                  />
                </button>
              ) : (
                <div className="py-3">
                  <Row className="flex justify-between">
                    <Text
                      value={translations.ADD_DESCRIPTION}
                      className="text-base mb-2 font-medium"
                    />
                    <div
                      className={cx(
                        lang === "en" ? "float-right" : "float-left"
                      )}
                    >
                      <Button
                        type="button"
                        kind="secondary"
                        size="trash"
                        onClick={handleRemoveDescription}
                      >
                        <Trash />
                      </Button>
                    </div>
                  </Row>
                  <div className="flex flex-col md:flex-row justify-between items-center">
                    <div
                      className={cx(
                        "w-full mb-6 md:mb-0",
                        lang === "en" ? "mr-0 md:mr-4" : "ml-0 md:ml-4"
                      )}
                    >
                      <Label title={<Text value={translations.ENGLISH} />}>
                        <Field
                          data-testid={`${pageType}-product-description-en`}
                          name="descriptionEn"
                          placeholder={translations.ENTER_DESCRIPTION[0]}
                          component={Textarea}
                          rows="5"
                        />
                      </Label>
                    </div>
                    <div className="w-full mb-0 md:mb-0">
                      <Label title={<Text value={translations.ARABIC} />}>
                        <Field
                          data-testid={`${pageType}-product-description-ar`}
                          name="descriptionAr"
                          direction="rtl"
                          placeholder={translations.ENTER_DESCRIPTION[1]}
                          component={Textarea}
                          rows="5"
                        />
                      </Label>
                    </div>
                  </div>
                </div>
              )}

              <Row className="py-3">
                {catLoading ? (
                  <Spinner />
                ) : (
                  <Field
                    testIdOpenList={`${pageType}-product-category-list`}
                    testIdOptionsList={`${pageType}-product-category-option`}
                    testIdClearAll={`${pageType}-product-clear-all-category`}
                    testIdSelectAll={`${pageType}-product-select-all-category`}
                    title={<Text value={translations.CATEGORY} />}
                    subtitle={<Text value={translations.EACH_ITEM_CAN_BE} />}
                    name="categories"
                    placeholder={
                      lang === "en"
                        ? translations.PLEASE_SELECT_CATEGORY[0]
                        : translations.PLEASE_SELECT_CATEGORY[1]
                    }
                    items={dataCategories.menuSections.menuSections.map(
                      (cat, i) => {
                        return {
                          id: cat.id,
                          title: <Text value={cat} />,
                        }
                      }
                    )}
                    component={MultiCheckbox}
                  />
                )}
              </Row>
              {price && (
                <Row className="py-3">
                  <Label
                    title={<Text value={translations.PRICE} />}
                    subtitle={<Text value={translations.DESC_SKIP_PRICE} />}
                  >
                    <Field
                      data-testid="product-price"
                      name="price"
                      min={0}
                      left={<Text value={currency} />}
                      placeholder={(0).toFixed(currency.decimals)}
                      component={Stepper}
                      validation={n => n >= 0}
                    />
                  </Label>
                </Row>
              )}

              <Row className="py-3">
                {branchesLoading ? (
                  <Spinner />
                ) : (
                  <Field
                    testIdOpenList={`${pageType}-product-publishIn-list`}
                    testIdOptionsList={`${pageType}-product-publishIn-option`}
                    testIdClearAll={`${pageType}-product-clear-all-publishIn`}
                    testIdSelectAll={`${pageType}-product-select-all-publishIn`}
                    title={<Text value={translations.PUBLISH_IN} />}
                    subtitle={
                      <Text value={translations.CHOOSE_ALL_BUSINESS_ITEM} />
                    }
                    name="publishIn"
                    placeholder={
                      lang === "en"
                        ? translations.PLEASE_SELECT_LOCATION[0]
                        : translations.PLEASE_SELECT_LOCATION[1]
                    }
                    items={dataBranches.branches.map((branch, i) => {
                      return {
                        id: branch.id,
                        title: <Text value={branch} />,
                      }
                    })}
                    component={MultiCheckbox}
                  />
                )}
              </Row>
            </>
          }
        />
      </div>

      <div className="md:px-8 w-full md:w-1/3">
        <Label title={<Text value={translations.MEDIA} />} font>
          <Field
            component={Uploader}
            productCatalog
            name="photoUrl"
            imageUrl={productImage && productImage}
            onRemove={() => {
              productImage && deleteProductImage()
              setFieldValue("photoUrl", null)
            }}
            size="bigger"
            type="squared"
            info={
              <Text
                className="text-primary-base font-semibold"
                value={translations.UPLOAD_DRAG_IMAGE}
              />
            }
            format={
              <Text
                className="text-gray-500"
                value={translations.UPLOAD_DRAG_IMAGE_FORMATS}
              />
            }
            justify="start"
            onUpload={image => setFieldValue("photoUrl", image)}
            onCancel={() => imageTokenSource.cancel()}
            close={close}
            open={open}
            accept="image/*"
            testId_uploadImg="photo-url"
            testId_BtnUpload={`${pageType}-photo-url-btn`}
          />
        </Label>
      </div>
    </div>
  )
}
