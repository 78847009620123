import React, { useContext } from "react"
import { Text } from "components/service"
import { Pagination, Button } from "components/kit"
import * as translations from "constants/translations"
import { context as localeContext } from "context/locale"
import cx from "classnames"

const TableFooter = ({
  totalRecords,
  pageLimit,
  currentPage,
  onPageChanged,
  totalCount,
  exportLink,
}) => {
  const { direction } = useContext(localeContext)

  const getPageStart = (pageSize, pageNr) => {
    return pageSize * pageNr
  }

  const getPageLabel = (total, pageSize = pageLimit, pageNr) => {
    const start = Math.max(getPageStart(pageSize, pageNr), 0)
    const end = Math.min(getPageStart(pageSize, pageNr + 1), total)

    return `${start + 1} - ${end}`
  }

  return (
    <div
      style={{ direction }}
      className="w-full flex flex-wrap-reverse justify-between items-center px-4"
    >
      <div className="text-gray-700 order-1">
        <Text
          value={translations.SHOWING_RESULTS}
          payload={[
            getPageLabel(totalRecords, pageLimit, currentPage - 1),
            totalRecords,
          ]}
        />
      </div>
      <div
        className={cx(
          "my-2 w-full md:w-auto",
          direction === "ltr" ? "ml-auto" : "mr-auto",
          exportLink ? "order-3 md:order-2" : "order-2"
        )}
      >
        <Pagination
          totalRecords={totalRecords}
          pageLimit={pageLimit}
          currentPage={currentPage}
          onPageChanged={onPageChanged}
        />
      </div>
      {exportLink && (
        <div className="order-2 md:order-3">
          <Button
            kind="tertiary"
            data-testid="stockManagement-history-export"
            onClick={exportLink}
          >
            <Text value={translations.EXPORT} />
          </Button>
        </div>
      )}
    </div>
  )
}

export default TableFooter
