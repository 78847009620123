import React, { useContext } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import { BACKEND_GRAPHQL_SERVER } from 'constants/backendGraphqlServer';
import { context as userContext } from 'context/user';
import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { context as notificationsContext } from 'context/notifications';
import * as utils from 'pages/setup/webLinks/utils';
import { ADD_ORDERFAST_LINK, ORDERFAST_LINKS } from '../schemas';

const useAddOrderfastLink = ({ lang }) => {
  const { selectedStore } = useContext(userContext);
  const notifications = useContext(notificationsContext);
  const { subdomain } = selectedStore || {};
  const isArabic = lang === 'ar';

  const [addOrderfastLink, { loading }] = useMutation(ADD_ORDERFAST_LINK, {
    context: { serverName: BACKEND_GRAPHQL_SERVER },
    onCompleted: data => {
      const { titleAr, titleEn } = data?.addOrderfastLink;
      notifications.show(<Text value={translations.THE_LINK_ADDED} payload={(isArabic && titleAr) || titleEn} />);
    },
  });
  const { updateQuery } = useQuery(ORDERFAST_LINKS, {
    variables: { subdomain },
    context: { serverName: BACKEND_GRAPHQL_SERVER },
  });

  const handleOnAddOrderfastClick = async formData => {
    const { data: resultData } = await addOrderfastLink({
      variables: {
        linkType: formData.linkType,
        titleAr: formData.titleAr,
        titleEn: formData.titleEn,
        content: utils.prependLink(formData),
        subdomain,
      },
    });
    updateQuery?.(prevData => ({
      ...prevData,
      orderfastLinks: [...prevData.orderfastLinks, resultData?.addOrderfastLink],
    }));
  };

  return [handleOnAddOrderfastClick, { loading }];
};

export default useAddOrderfastLink;
