import { gql } from '@apollo/client';

export default gql`
  query($storeId: String!) {
    subscription(restaurantId: $storeId) {
      id
      countryPlanId
      status
      startsAt
      autoRenew
      total
      cancelReason
      cancelSubscriptionRequests {
        status
      }
      restaurant {
        currency {
          titleEn
          titleAr
        }
      }
      serviceData {
        titleEn
        titleAr
        interval
        price
      }
      expiresAt
      countryPlan {
        plan {
          titleEn
          titleAr
          featuresEn
          featuresAr
          descriptionEn
          descriptionAr
        }
        price
        published
      }
      invoices {
        number
        typeHumanize
        currency
        total
        current
        billingCycleStartDate
        billingCycleEndDate
        serviceData {
          titleEn
          titleAr
          interval
          price
        }
        status
        paymentUrl
      }
    }
  }
`;
