import React, { useContext } from "react"
import { useSelectedStore } from "hooks"
import { useMutation } from "@apollo/client"
import { navigate } from "@reach/router"
import { replaceParams } from "utils/index"
import * as paths from "paths.js"
import { Field, Text } from "components/service"
import { Button, Stack } from "components/kit"
import { Label, Container, Row, Footer } from "components/form/generic"
import * as translations from "constants/translations"
import { context as localeContext } from "context/locale"
import * as schemas from "./schemas"
import { context as notificationsContext } from "context/notifications"

export default ({ zoneId, branchId, onClose, storeId, refetch }) => {
  const { lang, direction } = useContext(localeContext)
  const notifications = useContext(notificationsContext)

  const [deleteDeliveryZone] = useMutation(schemas.DELETE_DELIVERY_ZONE, {
    onCompleted: () => {
      notifications.show(<Text value={translations.DELIVERY_ZONE_DELETED} />)
      navigate(replaceParams(paths.deliveryList, { storeId: storeId }))
      if (refetch) {
        refetch()
      }
    },
  })

  return (
    <>
      <div className="px-6">
        <Container>
          <Row>
            <Text value={translations.ARE_YOU_SURE_TO_DELETE_ZONE} />
          </Row>
        </Container>
      </div>
      <Footer modalFooter>
        <div className="w-full flex items-center">
          <div className="ml-auto">
            <Row>
              <Button onClick={onClose} type="button" kind="tertiary">
                <Text value={translations.DISMISS} />
              </Button>
              <Button
                onClick={() => {
                  deleteDeliveryZone({
                    variables: {
                      restaurantId: storeId.toString(),
                      branchId: branchId,
                      id: zoneId,
                    },
                  })
                  onClose()
                }}
                kind="secondaryError"
              >
                <Text value={translations.DELETE} />
              </Button>
            </Row>
          </div>
        </div>
      </Footer>
    </>
  )
}
