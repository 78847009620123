import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { InfoIcon } from '@zydalabs/zac-icons-react';
import { useMutation } from '@apollo/client';
import cx from 'classnames';

import { context as userContext } from 'context/user';
import { context as localeContext } from 'context/locale';
import { context as notificationsContext } from 'context/notifications';
import * as translations from 'constants/translations';
import { Button, Modal } from 'components/kit';
import { Field, Text } from 'components/service';
import { Label } from 'components/form/generic';
import { PhoneInput } from 'components/form/elements';
import { CAMPAIGN_STATES, NO_ENOUGH_CREDITS } from './constants';
import { prepareCampaignObject } from './utils';
import { SEND_TEST_SMS } from './schemas';

const TestSmsModalForm = ({ close, values }) => {
  const { selectedStore } = useContext(userContext);
  const { direction, translate } = useContext(localeContext);
  const notifications = useContext(notificationsContext);
  const [userPhone, setUserPhone] = useState('');
  const [testCampaignSms, { loading }] = useMutation(SEND_TEST_SMS, {
    variables: {
      restaurantId: selectedStore.id,
    },
    onCompleted: () => {
      close();
      notifications.show(<Text value={translations.TEST_SMS_SUCCESS_MESSAGE} />, 'success');
    },
    onError: error => {
      const { body } = error.graphQLErrors[0].extensions.exception;
      if (body.message !== NO_ENOUGH_CREDITS) {
        notifications.show(<Text value={translations.SOMETHING_WENT_WRONG} />, 'error');
      } else {
        notifications.show(<Text value={translations.TEST_SMS_FAILURE_MESSAGE} />, 'error');
      }
    },
  });

  const handleTestSms = async () => {
    const campaignObject = prepareCampaignObject(values, CAMPAIGN_STATES.ACTIVE);
    try {
      await testCampaignSms({
        variables: {
          ...campaignObject,
          testPhoneNumber: `+${userPhone}`,
        },
      });
    } catch (err) {
      notifications.show(<Text value={translations.SOMETHING_WENT_WRONG} />, 'error');
    }
  };
  return (
    <div className="px-4">
      <Label title={<Text value={translations.TEST_SMS_PHONE} />}>
        <Field
          type="text"
          name="phone_number"
          country={selectedStore.countryCode.toLowerCase()}
          placeholder={translate(translations.MOBILE_NUMBER_PLACEHOLDER)}
          component={PhoneInput}
          onChange={elem => setUserPhone(elem.target.value)}
          disableDropdown
        />
      </Label>
      <div
        className="mt-4 py-2 px-4 border border-primary-200 mb-4 bg-primary-25 flex items-center justify-between flex-wrap"
        style={{ direction }}
      >
        <div className="flex items-start">
          <InfoIcon
            width="16"
            color="#196EFF"
            className={cx('flex-shrink-0 mt-1', direction === 'rtl' ? 'ml-2' : 'mr-2')}
          />
          <div>
            <span className={cx('text-xs text-primary-base font-semibold', direction === 'rtl' ? 'ml-2' : 'mr-2')}>
              {translate(translations.NOTES)}
            </span>
            <span className="text-xs">{translate(translations.TEST_SMS_BALANCE_NOTE)}</span>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-end mt-6">
        <Button onClick={close} type="button" kind="tertiary" className="mx-4">
          <Text value={translations.CANCEL} />
        </Button>
        <Button kind="primary" type="button" onClick={handleTestSms} disabled={userPhone.length === 0 || loading}>
          <Text value={translations.SEND} />
        </Button>
      </div>
    </div>
  );
};

TestSmsModalForm.propTypes = {
  values: PropTypes.shape({
    restaurantId: PropTypes.string,
  }),
  close: PropTypes.func,
};

const CampaignTestSms = ({ values }) => (
  <Modal isDzmsTableView>
    {({ open, close }) => (
      <Button
        isRounded
        kind="outline"
        onClick={e => {
          e.preventDefault();
          open({
            title: <Text className="text-xl" value={translations.TEST_SMS} />,
            body: <TestSmsModalForm close={close} values={values} />,
            size: 'max-w-xl',
          });
        }}
      >
        <Text className="text-primary-base" value={translations.TEST_SMS_TEXT} />
      </Button>
    )}
  </Modal>
);

CampaignTestSms.propTypes = {
  values: PropTypes.shape({
    restaurantId: PropTypes.string,
  }),
};

export default CampaignTestSms;
