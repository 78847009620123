import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const TooltipProp = ({ id, indexValue, value, display, type, lang, direction, labels, selectedDataType }) => {
  const dateFormat =
    type === 'day' ? (lang === 'en' ? 'MMM DD, YYYY' : 'MMM DD، YYYY') : lang === 'en' ? 'MMM, YYYY' : 'MMM، YYYY';
  return (
    <div className="py-3" style={{ direction }}>
      <div className="mb-2 pb-1 border-b border-gray-300 capitalize">
        {`${labels[id]} - ${moment(indexValue).format(dateFormat)}`}
      </div>
      <div className="flex items-center justify-center">
        <span className="text-gray-700">{selectedDataType}</span>
        <span className="font-bold mx-2">{display(value)}</span>
      </div>
    </div>
  );
};

TooltipProp.propTypes = {
  id: PropTypes.string.isRequired,
  indexValue: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  display: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['day', 'month']).isRequired,
  lang: PropTypes.oneOf(['en', 'ar']).isRequired,
  direction: PropTypes.oneOf(['rtl', 'ltr']).isRequired,
  labels: PropTypes.objectOf(PropTypes.string).isRequired,
  selectedDataType: PropTypes.element.isRequired,
};

export default TooltipProp;
