import { gql } from 'graphql-request';

const deliveryZoneMapViewQuery = gql`
  query($restaurantReferenceId: ID!, $branchReferenceId: ID) {
    deliveryZoneMapView(restaurantReferenceId: $restaurantReferenceId, branchReferenceId: $branchReferenceId) {
      featureCollection
    }
  }
`;

export default deliveryZoneMapViewQuery;
