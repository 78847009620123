export const LINK_TYPES = {
  SMS: 'sms',
  FACEBOOK: 'facebook',
  INSTAGRAM: 'instagram',
  TWITTER: 'twitter',
  SNAPCHAT: 'snapchat',
  WHATSAPP: 'whatsapp',
  URL: 'url',
  PHONE: 'phone',
  EMAIL: 'email',
};
