// - zones mocks
export const zonesMocks = [
  {
    id: 1,
    branchId: 1,
    type: 'polygon',
    zoneName: 'Zone name A 1',
    deliveryFee: '155',
    minValue: '50',
    enableZone: true,
    paths: [
      {
        lat: 29.331911989088137,
        lng: 47.961723741575604,
      },
      {
        lat: 29.333184038444767,
        lng: 47.958204683348065,
      },
      {
        lat: 29.333857469914758,
        lng: 47.95382731823576,
      },
      {
        lat: 29.33251060252691,
        lng: 47.95314067272795,
      },
      {
        lat: 29.32345619888979,
        lng: 47.95811885265959,
      },
      {
        lat: 29.32480318586484,
        lng: 47.962410387083416,
      },
    ],
  },
  {
    id: 3,
    branchId: 2,
    type: 'polygon',
    zoneName: 'Zone name B 1',
    deliveryFee: '125',
    minValue: '30',
    enableZone: true,
    paths: [
      {
        lat: 29.330714751667852,
        lng: 48.00519698528898,
      },
      {
        lat: 29.326150155051536,
        lng: 47.98700087933195,
      },
      {
        lat: 29.32278269873177,
        lng: 47.98811667828215,
      },
      {
        lat: 29.318666767811145,
        lng: 47.99000495342863,
      },
      {
        lat: 29.319714475073724,
        lng: 47.99730056194914,
      },
      {
        lat: 29.321435685075127,
        lng: 48.01077598003996,
      },
    ],
  },
  {
    id: 4,
    branchId: 2,
    type: 'polygon',
    zoneName: 'Zone name B 2',
    deliveryFee: '300',
    minValue: '20',
    enableZone: true,
    paths: [
      {
        lat: 29.329891642787338,
        lng: 47.995412286802654,
      },
      {
        lat: 29.32704812462664,
        lng: 47.986400064512615,
      },
      {
        lat: 29.331088889871705,
        lng: 47.98476928143156,
      },
      {
        lat: 29.33512949500004,
        lng: 47.98751586346281,
      },
      {
        lat: 29.339843331928282,
        lng: 48.00030463604582,
      },
      {
        lat: 29.331911989088137,
        lng: 48.00554030804289,
      },
    ],
  },
  {
    id: 2,
    branchId: 1,
    type: 'polygon',
    zoneName: 'Zone name A 2',
    deliveryFee: '553',
    minValue: '82',
    enableZone: true,
    paths: [
      {
        lat: 29.331911989088137,
        lng: 47.97039264111174,
      },
      {
        lat: 29.331911989088137,
        lng: 47.96309703259123,
      },
      {
        lat: 29.325102513888048,
        lng: 47.963440355345135,
      },
      {
        lat: 29.327497106449332,
        lng: 47.969963487669354,
      },
    ],
  },
  {
    id: 1648981652127,
    branchId: 3,
    type: 'polygon',
    zoneName: 'Zone name C 1',
    deliveryFee: '300',
    minValue: '20',
    enableZone: true,
    paths: [
      {
        lat: 29.318105491637393,
        lng: 47.97288173107756,
      },
      {
        lat: 29.316234548757514,
        lng: 47.960951265379315,
      },
      {
        lat: 29.309498870331673,
        lng: 47.9628395405258,
      },
      {
        lat: 29.31062151427691,
        lng: 47.97554248242033,
      },
    ],
  },
  {
    id: 1648981792612,
    branchId: 3,
    type: 'polygon',
    zoneName: 'Zone name C 2',
    deliveryFee: '300',
    minValue: '20',
    enableZone: true,
    paths: [
      {
        lat: 29.31746937490768,
        lng: 47.97597163586271,
      },
      {
        lat: 29.295015656392234,
        lng: 47.98395388989103,
      },
      {
        lat: 29.297635511379426,
        lng: 47.991678651853924,
      },
      {
        lat: 29.31948996728039,
        lng: 47.986185487791424,
      },
    ],
  },
  {
    id: 1648981775426,
    branchId: 3,
    type: 'polygon',
    zoneName: 'Zone name C 3',
    deliveryFee: '300',
    minValue: '20',
    enableZone: true,
    paths: [
      {
        lat: 29.308937543728167,
        lng: 47.9628395405258,
      },
      {
        lat: 29.29217116630494,
        lng: 47.96627276806486,
      },
      {
        lat: 29.29449167732915,
        lng: 47.98232310680998,
      },
      {
        lat: 29.309685978513457,
        lng: 47.97622912792814,
      },
    ],
  },
];

// - circle mocks [NOT USE JUST FOR REF]
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const circle = {
  id: 5,
  branchId: 1,
  fillColor: '#FF0000',
  type: 'circle',
  radius: 520,
  center: {
    lat: 29.324878017952994,
    lng: 47.962925371214276,
  },
};

// - branches mocks
export const branchesMocks = [
  {
    id: 1,
    title: 'Branch A',
    position: {
      lat: 29.324878017952994,
      lng: 47.962925371214276,
    },
  },
  {
    id: 2,
    title: 'Branch B',
    position: {
      lat: 29.32936784276778,
      lng: 47.99644225506437,
    },
  },
  {
    id: 3,
    title: 'Branch C',
    position: {
      lat: 29.31062151427691,
      lng: 47.97554248242033,
    },
  },
];

// - map center position
export const centerMocks = { lat: 29.328507308323413, lng: 47.96494239239347 };

// - countries and cities mocks
export const countriesMocks = [{ id: 2, title: 'Kuwait', countryCode: 'KW' }];
export const citiesMocks = [
  { id: 1, title: 'Mishrif', cityCode: 'MU' },
  { id: 2, title: 'Hawalli', cityCode: 'HI' },
];

// - zones feature collections mocks
export const zonesFeatureCollectionsMocks = {
  type: 'FeatureCollection',
  properties: { restaurantName: 'restaurant A' },
  features: [
    {
      type: 'Feature',
      properties: {
        id: 15,
        zoneName: 'Corniche El Maadi, Cairo (0.5 km)',
        visible: false,
        editable: false,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [48.064584732, 29.190457902],
            [48.067674637, 29.180042037],
            [48.070163727, 29.178843021],
            [48.07308197, 29.178168568],
            [48.076171875, 29.179367592],
            [48.080720901, 29.180491664],
            [48.086042404, 29.18064154],
            [48.0905056, 29.180566602],
            [48.096256256, 29.180566602],
            [48.101835251, 29.181315976],
            [48.101320267, 29.184613158],
            [48.096513748, 29.19442914],
            [48.09179306, 29.196452165],
            [48.086557388, 29.198475149],
            [48.083553314, 29.198699923],
            [48.077459335, 29.197201423],
            [48.068962097, 29.194054502],
            [48.065443039, 29.192705791],
            [48.064584732, 29.190457902],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: 16,
        zoneName: 'Corniche El Maadi',
        visible: false,
        editable: false,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [48.111417654, 29.187007117],
            [48.111836079, 29.18607045],
            [48.112061384, 29.18561148],
            [48.112340334, 29.18505884],
            [48.112458351, 29.184880871],
            [48.112576369, 29.184721635],
            [48.112683657, 29.184665434],
            [48.112769488, 29.184627966],
            [48.112994793, 29.184571765],
            [48.113220099, 29.184562398],
            [48.113434675, 29.184581132],
            [48.114303711, 29.184815303],
            [48.114872339, 29.184955805],
            [48.11523712, 29.18503074],
            [48.115719917, 29.185002639],
            [48.115848664, 29.185218076],
            [48.115998867, 29.18529301],
            [48.116567496, 29.185470979],
            [48.117221955, 29.185695781],
            [48.118842009, 29.186192217],
            [48.118402126, 29.186276517],
            [48.118133906, 29.186323351],
            [48.117994431, 29.186454484],
            [48.117844227, 29.186651184],
            [48.117468718, 29.187381781],
            [48.117114666, 29.18806554],
            [48.116921547, 29.188561965],
            [48.116857174, 29.188871058],
            [48.116664055, 29.189657837],
            [48.116513851, 29.190379046],
            [48.116385105, 29.190847361],
            [48.115805748, 29.192214828],
            [48.114711407, 29.195895658],
            [48.114373449, 29.196982087],
            [48.112715843, 29.196757309],
            [48.112436894, 29.197740707],
            [48.108123902, 29.196668335],
            [48.108386758, 29.196230485],
            [48.108585241, 29.195783268],
            [48.109089497, 29.194050576],
            [48.109668854, 29.192317855],
            [48.110870484, 29.188533865],
            [48.111417654, 29.187007117],
          ],
        ],
      },
    },
  ],
};

// - zones feature collections mocks
export const zonesFeatureCollectionsNearestMocks = {
  type: 'FeatureCollection',
  properties: { restaurantName: 'restaurant A' },
  features: [
    {
      type: 'Feature',
      properties: {
        id: 20,
        zoneName: 'El Maadi, Cairo (0.5 km)',
        visible: false,
        editable: false,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [48.091762145, 29.196529386],
            [48.092577536, 29.196154755],
            [48.09365042, 29.195705196],
            [48.094980796, 29.195143244],
            [48.095924933, 29.194693681],
            [48.096354087, 29.194393971],
            [48.096654494, 29.194206652],
            [48.096997817, 29.193794549],
            [48.097555716, 29.192895408],
            [48.101546843, 29.184203311],
            [48.101890166, 29.18289194],
            [48.101975997, 29.181880299],
            [48.101589759, 29.180906116],
            [48.111975272, 29.183866103],
            [48.108542044, 29.195780122],
            [48.108069976, 29.1967167],
            [48.112447341, 29.19780312],
            [48.112704833, 29.196791626],
            [48.114335616, 29.197016404],
            [48.113691886, 29.199676231],
            [48.113734801, 29.200500389],
            [48.113734801, 29.201024849],
            [48.113391478, 29.201399462],
            [48.11291941, 29.201661691],
            [48.112661918, 29.202073763],
            [48.112018187, 29.203422349],
            [48.111331542, 29.205070598],
            [48.110473235, 29.207468004],
            [48.10987242, 29.209078729],
            [48.109572013, 29.210464682],
            [48.10922869, 29.210951634],
            [48.108971198, 29.21132621],
            [48.108627875, 29.212225189],
            [48.10360678, 29.211101464],
            [48.103177626, 29.212712133],
            [48.090818007, 29.209266021],
            [48.091504653, 29.207580381],
            [48.091933806, 29.206306767],
            [48.092577536, 29.204733459],
            [48.09300669, 29.203572191],
            [48.093264182, 29.202635676],
            [48.093392928, 29.202036302],
            [48.093435843, 29.201436924],
            [48.093435843, 29.200800081],
            [48.093178351, 29.19986354],
            [48.092534621, 29.198065358],
            [48.091762145, 29.196529386],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: 21,
        zoneName: 'El Maadi',
        visible: false,
        editable: false,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [48.090801218, 29.20926561],
            [48.089828247, 29.209037348],
            [48.088626617, 29.208882832],
            [48.087682479, 29.208807915],
            [48.086395019, 29.208807915],
            [48.084850067, 29.208882832],
            [48.0827043, 29.209107583],
            [48.080987686, 29.209187182],
            [48.079099411, 29.209337015],
            [48.077897781, 29.20940725],
            [48.075151199, 29.20940725],
            [48.073777908, 29.209257416],
            [48.072318786, 29.209037348],
            [48.070516342, 29.208658081],
            [48.066396469, 29.207684153],
            [48.065023178, 29.207384481],
            [48.059554154, 29.206192808],
            [48.063993209, 29.190751312],
            [48.0653665, 29.192324835],
            [48.065623993, 29.192474694],
            [48.068628067, 29.19382809],
            [48.072662109, 29.195401566],
            [48.075065368, 29.196370928],
            [48.078825825, 29.197396474],
            [48.08076238, 29.197897537],
            [48.0827043, 29.198318989],
            [48.084506744, 29.198543763],
            [48.086073154, 29.198450107],
            [48.087596649, 29.198000559],
            [48.091721886, 29.196544194],
            [48.092494362, 29.198038021],
            [48.09279477, 29.198848144],
            [48.093175644, 29.199892396],
            [48.09331713, 29.200393447],
            [48.093433136, 29.200800841],
            [48.093433136, 29.201091167],
            [48.093420395, 29.201575822],
            [48.093397932, 29.201824587],
            [48.093373456, 29.202072181],
            [48.093291314, 29.202446205],
            [48.093254936, 29.202635558],
            [48.093201795, 29.202817301],
            [48.092700222, 29.20438186],
            [48.091974014, 29.206185784],
            [48.091781565, 29.20670846],
            [48.091603869, 29.207227621],
            [48.091464394, 29.207623282],
            [48.091327602, 29.207965094],
            [48.091152587, 29.208372459],
            [48.090948739, 29.208819328],
            [48.090812953, 29.20927],
            [48.090801218, 29.20926561],
          ],
        ],
      },
    },
  ],
};
