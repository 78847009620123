import * as schemas from "../schemas"

export const updateCache = restaurantId => (
  cache,
  { data: { deleteBranch } }
) => {
  const { branches } = cache.readQuery({
    query: schemas.LIST,
    variables: { restaurantId },
  })

  cache.writeQuery({
    query: schemas.LIST,
    data: {
      branches: branches.filter(item => item.id !== deleteBranch.id),
    },
    variables: { restaurantId },
  })
}
