import React, { useState, useContext } from "react"
import axios from "axios"
import { Field, Text } from "components/service"
import * as translations from "constants/translations"
import { Input } from "components/form/elements"
import { Uploader } from "components/kit"
import { Label, Row, Section } from "components/form/generic"
import cx from "classnames"
import PhoneInput from "components/form/elements/PhoneInput"
import { context as userContext } from "context/user"

export default ({ lang, values, close, open, setFieldValue }) => {
  const [showPassword, setShowPassword] = useState(false)
  const frontTokenSource = axios.CancelToken.source()
  const backTokenSource = axios.CancelToken.source()
  const { selectedStore } = useContext(userContext)
  return (
    <Section title={<Text value={translations.LEGAL_INFO} />}>
      <Row className="flex-col md:flex-row">
        <Label title={<Text value={translations.FIRST_NAME} />}>
          <Field
            type="text"
            name="firstName"
            placeholder={
              translations.FIRST_NAME_PLACEHOLDER[lang === "en" ? 0 : 1]
            }
            component={Input}
            data-testid="payment-register-firstName"
          />
        </Label>
        <Label title={<Text value={translations.LAST_NAME} />}>
          <Field
            type="text"
            name="lastName"
            placeholder={
              translations.LAST_NAME_PLACEHOLDER[lang === "en" ? 0 : 1]
            }
            component={Input}
            data-testid="payment-register-lastName"
          />
        </Label>
      </Row>
      <Row className="flex-col md:flex-row">
        <Label title={<Text value={translations.EMAIL_ADDRESS} />}>
          <Field
            type="text"
            name="emailAddress"
            placeholder={translations.EMAIL_PLACEHOLDER[lang === "en" ? 0 : 1]}
            component={Input}
            data-testid="payment-register-email"
          />
        </Label>
        <Label
          htmlFor="password"
          title={<Text value={translations.PASSWORD} className="font-light" />}
        >
          <div className="flex items-center relative w-full">
            <Field
              type={showPassword ? "text" : "password"}
              name="password"
              placeholder="******************"
              component={Input}
              data-testid="payment-register-password"
            />
            {values.password && (
              <i
                className={cx(
                  "material-icons text-xl text-gray-500 px-2 cursor-pointer absolute ",
                  lang === "ar" ? "left-0" : "right-0"
                )}
                style={{
                  top: ".6rem",
                }}
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? "visibility_off" : "visibility"}
              </i>
            )}
          </div>
        </Label>
      </Row>
      <Row className="flex-col md:flex-row">
        <Label title={<Text value={translations.PHONE_NUMBER_ORDER} />}>
          <Field
            type="text"
            name="mobileNumber"
            testId="payment-register-mobileNumber"
            country={selectedStore.countryCode.toLowerCase()}
            placeholder={
              translations.MOBILE_NUMBER_PLACEHOLDER[lang === "en" ? 0 : 1]
            }
            component={PhoneInput}
          />
        </Label>
        <Label title={<Text value={translations.NATIONAL_ID_NUMBER} />}>
          <Field
            type="text"
            name="idNumber"
            placeholder={
              translations.ENTER_VALID_ID_NUMBER[lang === "en" ? 0 : 1]
            }
            component={Input}
            data-testid="payment-register-idNumber"
          />
        </Label>
      </Row>
      <Row className="flex-col md:flex-row">
        <Label
          title={<Text value={translations.UPLOAD_NATIONAL_ID} />}
          description={<Text value={translations.FRONT_COPY} />}
        >
          <Field
            imageUrl={values.nationalFrontId}
            testId_BtnUpload="payment-register-nationalFrontId"
            onRemove={() => setFieldValue("nationalFrontId", "")}
            size="small"
            direction="row"
            info={<Text value={translations.UPLOAD_DRAG} />}
            justify="start"
            onUpload={image => setFieldValue("nationalFrontId", image)}
            onCancel={() => frontTokenSource.cancel()}
            close={close}
            open={open}
            component={Uploader}
            name="nationalFrontId"
            accept="image/*"
          />
        </Label>
        <Label
          title={<span className="text-transparent">|</span>}
          description={<Text value={translations.BACK_COPY} />}
        >
          <Field
            imageUrl={values.nationalBackId}
            onRemove={() => setFieldValue("nationalBackId", "")}
            testId_BtnUpload="payment-register-nationalBackId"
            size="small"
            direction="row"
            info={<Text value={translations.UPLOAD_DRAG} />}
            justify="start"
            onUpload={image => setFieldValue("nationalBackId", image)}
            onCancel={() => backTokenSource.cancel()}
            close={close}
            open={open}
            component={Uploader}
            name="nationalBackId"
            accept="image/*"
          />
        </Label>
      </Row>
    </Section>
  )
}

const NumericInput = props => (
  <Input
    {...props}
    onChange={e => {
      if (isNaN(+e.target.value)) {
        return
      }
      props.onChange(e)
    }}
  />
)
