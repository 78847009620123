import React, { useContext } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import * as R from 'ramda';

import { Text } from 'components/service';
import { context as userContext } from 'context/user';
import { context as notificationsContext } from 'context/notifications';
import * as translations from 'constants/translations';
import * as schemas from '../schemas';
import * as utils from '../utils';

const useEditWebLink = ({ lang }) => {
  const { selectedStore } = useContext(userContext);
  const notifications = useContext(notificationsContext);
  const isArabic = lang === 'ar';

  const { data: dataLinks } = useQuery(schemas.SETTINGS, {
    variables: { storeId: selectedStore.id },
  });

  const [updateWebLink] = useMutation(schemas.UPDATE_WEB_LINK);

  const handleOnEditClick = async data => {
    const generatedWebLinkObj = {
      ...R.omit(['title', '__typename'], data),
      id: data.id,
      titleAr: data.titleAr,
      titleEn: data.titleEn,
      url: utils.prependLink(data),
    };
    const formattedWebLinksArr =
      dataLinks?.settings?.webLinks?.links && utils.formatWebLinksArr(dataLinks?.settings?.webLinks?.links);
    await updateWebLink({
      variables: {
        storeId: selectedStore.id,
        link: generatedWebLinkObj,
        links: formattedWebLinksArr,
      },
    });
    notifications.show(
      <Text
        value={translations.THE_LINK_UPDATED}
        payload={(isArabic && generatedWebLinkObj.titleAr) || generatedWebLinkObj.titleEn}
      />,
    );
  };
  return handleOnEditClick;
};

export default useEditWebLink;
