import React, { useState } from "react"
import cx from "classnames"
import { Button } from "components/kit"
import { fileToBase64 } from "utils/index"
import uploadIcon from "assets/upload.svg"
import { Text } from "components/service"
import * as translations from "constants/translations"

export default ({
  size = "regular",
  direction = "col",
  justify = "center",
  items = "center",
  type = "wide",
  base64value = false,
  imageUrl,
  info,
  addButton,
  titleButton,
  ...props
}) => {
  const [image, setImage] = useState()

  return (
    <label className="relative w-full">
      <div
        className={cx(
          "cursor-pointer rounded border-2 border-dashed flex text-gray-600 p-1 relative items-center",
          type === "squared"
            ? sizes[size].types.squared
            : sizes[size].types.wide
        )}
      >
        {image || imageUrl ? (
          <img className="h-full" src={image || imageUrl} alt="" />
        ) : (
          <div className={cx("ml-2", sizes[size].circle.wrap)}>
            <img className="w-full" src={uploadIcon} alt="" />
          </div>
        )}
        {info && <div className="m-3">{info}</div>}
        {addButton && (
          <div className="ml-auto mr-3">
            <Button>
              {titleButton ? (
                titleButton
              ) : (
                <Text value={translations.ADD_FILE} />
              )}
            </Button>
          </div>
        )}
      </div>
      <input
        {...props}
        value=""
        type="file"
        style={{ opacity: 0 }}
        className="absolute w-full h-full top-0 left-0 cursor-pointer"
        onChange={async e => {
          const file = e.target.files[0]
          const base64 = await fileToBase64(file)
          setImage(base64)

          props.onChange({
            target: {
              name: props.name,
              value: base64value
                ? base64.replace(/^.*base64,(.*)$/, "$1")
                : file,
            },
          })
        }}
      />
    </label>
  )
}

const sizes = {
  small: {
    types: {
      squared: "w-10 h-10",
      wide: "h-10",
    },
    circle: {
      wrap: "w-6",
      icon: "w-4 h-4 text-xs",
    },
  },
  regular: {
    types: {
      squared: "w-24 h-24",
      wide: "h-24",
    },
    circle: {
      wrap: "w-12",
      icon: "w-8 h-8 text-base",
    },
  },
}
