import React, { useContext } from "react"
import { useMutation } from "@apollo/client"
import { useSelectedStore } from "hooks"
import { Button, Stack } from "components/kit"
import * as translations from "constants/translations"
import { context as notificationsContext } from "context/notifications"
import { Text } from "components/service"
import * as schemas from "./schemas"
import * as utils from "./utils"

export default ({ onClose, campaignId, campaignsVariables }) => {
  const notifications = useContext(notificationsContext)
  const storeId = useSelectedStore()
  const [deleteCampaign, { loading }] = useMutation(schemas.DELETE_CAMPAIGN, {
    variables: {
      storeId,
      campaignId,
    },
    update: utils.removeCampaign(campaignsVariables, campaignId),
    onError: err => {
      let body = err.graphQLErrors[0].extensions.exception.body

      for (let key of Object.keys(body)) {
        notifications.show(`${key} ${body[key][0]}`, "error")
      }
    },
    onCompleted: () =>
      notifications.show(<Text value={translations.CAMPAIGN_DELETED} />),
  })

  return (
    <div className="px-4">
      <Text
        value={translations.DELETE_CAMPAIGN_CONFIRMATION}
        className="mb-6"
      />
      <Stack direction="row-reverse" expandItem={false} spacing={2}>
        <Button
          isSpinning={loading}
          onClick={async () => {
            await deleteCampaign()
            onClose()
          }}
          type="button"
          kind="tertiary"
          textColor="text-zyda-red-300"
        >
          <Text value={translations.DELETE} />
        </Button>
        <Button onClick={onClose} type="button" kind="tertiary">
          <Text value={translations.DISMISS} />
        </Button>
      </Stack>
    </div>
  )
}
