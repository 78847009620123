import React, { useState, useContext, useRef, useEffect } from 'react';
import cx from 'classnames';

import { useClickOutside } from 'hooks/index';
import { useSelectedStore, useMobile } from 'hooks';
import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { context as localeContext } from 'context/locale';
import LowStock from 'components/common/inventory/LowStock';
import { ReactComponent as VariantsIcon } from 'assets/product-place-holder.svg';
import { Input } from 'components/form/elements';
import { useQuery, useMutation } from '@apollo/client';
import { UPDATE_STOCK_STATUS } from '../../schemas';
import { SpinnerAlt } from 'components/kit';
import { context as notificationsContext } from 'context/notifications';
import ItemEditPopUp from './ItemEditPopUp';
import { INITIAL } from '../../../overview/AdvancedCreation/schemas';
import { TRACKING_TYPE_ENUM } from 'components/common/product/ItemTracking/utils';

const Variant = ({ variant, lowStock = 0, editVariant, setLoadingEditted, branchId, storeBranches }) => {
  const { lang } = useContext(localeContext);
  const [isOpened, setOpened] = useState(false);
  const storeId = useSelectedStore();
  const isMobile = useMobile();
  const ref = useRef();
  const notifications = useContext(notificationsContext);

  const {
    variantTitleEn,
    stockCount,
    variantPhotoUrl,
    unavailable,
    id,
    trackingType,
    remainingCount,
    initialCount,
    menuItemId,
  } = variant;

  useClickOutside(ref, () => setOpened(false));

  const [updateStockStatus, { loading: loadingStockStatus }] = useMutation(UPDATE_STOCK_STATUS, {
    onCompleted: data => {
      editVariant(data?.updateStockStatus);
    },
  });

  const { data: menuItemData, loading: itemLoading } = useQuery(INITIAL, {
    variables: {
      restaurantId: storeId,
      id: menuItemId,
    },
  });

  const branch = storeBranches?.filter(storeBranch => storeBranch.id === branchId)[0];
  const branchIndex = storeBranches?.findIndex(storeBranch => storeBranch?.id === branchId);

  let currentVariantQuantity = trackingType === TRACKING_TYPE_ENUM.DAILY_CAPACITY ? initialCount : stockCount;

  const upateStockCount = e => {
    if (parseInt(e.target.value) !== currentVariantQuantity) {
      setLoadingEditted(true);
      updateStockStatus({
        variables: {
          storeId,
          id,
          stockCount: parseInt(e.target.value),
          tracked: true,
        },
      }).catch(error => {
        error.graphQLErrors && error.graphQLErrors[0]?.extensions?.exception?.body
          ? Object.keys(error.graphQLErrors[0].extensions.exception.body).forEach(err => {
              const value = error.graphQLErrors[0].extensions.exception.body[err];
              notifications.show(`${err} ${value}`, 'error');
            })
          : notifications.show(<Text value={translations.SOMETHING_WENT_WRONG} />, 'error');
      });
    }
  };

  const updateVariantStatus = unavailable => {
    updateStockStatus({
      variables: {
        storeId,
        id,
        unavailable,
      },
    }).catch(error => {
      error.graphQLErrors && error.graphQLErrors[0]?.extensions?.exception?.body
        ? Object.keys(error.graphQLErrors[0].extensions.exception.body).forEach(err => {
            const value = error.graphQLErrors[0].extensions.exception.body[err];
            notifications.show(`${err} ${value}`, 'error');
          })
        : notifications.show(<Text value={translations.SOMETHING_WENT_WRONG} />, 'error');
    });
    setOpened(!isOpened);
  };

  const dropdownBackground = !unavailable ? 'bg-zyda-green-700' : 'bg-zyda-red-700';
  const dropdownColor = !unavailable ? 'text-zyda-green-600' : 'text-zyda-red-600';

  return (
    <div
      className={cx(
        'flex justify-between  border-b border-gray-300',
        isMobile ? ' flex-col pr-0 pl-2 py-4 my-0 mx-0' : 'p-4 mt-0 mb-0',
      )}
    >
      <div className="flex flex-1 items-center">
        {variantPhotoUrl ? (
          <div className={cx(lang === 'ar' ? 'ml-4' : 'mr-4', 'w-12 h-12')}>
            <img
              className={cx(lang === 'ar' ? 'mr-0' : 'mr-10', 'h-full w-full rounded overflow-hidden')}
              src={variantPhotoUrl}
              alt={variantTitleEn}
            />
          </div>
        ) : (
          <div className={cx(lang === 'ar' ? 'ml-4' : 'mr-4', 'w-12 h-12')}>
            <VariantsIcon className="h-full w-full rounded overflow-hidden" />
          </div>
        )}
        <div>
          <LowStock lowStock={lowStock} stockItem={variant} />
        </div>
      </div>
      <div className={cx('flex flex-1', isMobile ? 'justify-between mt-2' : 'items-center justify-end')}>
        {trackingType && (
          <div
            className={cx(
              'flex items-start',
              isMobile && 'flex-1',
              isMobile && trackingType !== TRACKING_TYPE_ENUM.STOCK && 'flex-col-reverse ',
            )}
          >
            {trackingType === TRACKING_TYPE_ENUM.DAILY_CAPACITY && (
              <div className={cx('flex justify-center', isMobile ? 'flex-row mt-2' : 'flex-col')}>
                <Text
                  className="text-xs font-normal text-right text-zyda-grey-400 mt-0 mx-0 leading-compact mb-sm"
                  value={translations.REMAINING_TODAY}
                />
                <p
                  className={cx(
                    'text-xs font-normal text-zyda-black-100 my-0 mx-0 leading-compact',
                    lang === 'en' ? 'text-right pl-1' : 'text-left pr-1',
                  )}
                >
                  {remainingCount}
                </p>
              </div>
            )}
            <div
              className={cx(
                'flex justify-between py-2 px-2 items-center border-gray-300 border h-36 my-sm',
                isMobile ? 'mx-0 w-full' : 'mx-4 w-168',
              )}
            >
              <Text
                className="font-normal text-gray-700 text-sm leading-normal"
                value={trackingType === TRACKING_TYPE_ENUM.STOCK ? translations.INVENTORY : translations.DAILY_CAPACITY}
              />
              <div className="w-45">
                <Input
                  className={cx('outline-none w-45 py-0 px-0 my-0 mx-0', lang === 'en' ? 'text-right' : 'text-left')}
                  noBorder
                  type="number"
                  value={trackingType === TRACKING_TYPE_ENUM.DAILY_CAPACITY ? initialCount : stockCount}
                  onBlur={upateStockCount}
                  onKeyPress={e => {
                    e.key === 'Enter' && upateStockCount(e);
                  }}
                />
              </div>
            </div>
          </div>
        )}
        {!trackingType && <ItemEditPopUp branchIndex={branchIndex} storeBranch={branch} productId={menuItemId} />}
        {isMobile || <hr className="h-6 bg-gray-200 my-auto mx-0 border" />}
        <div className={cx('relative select-none', isMobile && 'flex w-1/2 pl-2')} ref={ref}>
          <div
            onClick={() => setOpened(!isOpened)}
            className={cx(
              'pb-3 px-2 pt-2 cursor-pointer flex items-center justify-between h-36  my-sm mr-0',
              dropdownBackground,
              isMobile ? 'ml-0 w-full' : `w-125 ${lang === 'en' ? 'ml-4' : 'mr-4'}`,
            )}
          >
            <span className={dropdownColor}>
              <Text value={!unavailable ? translations.AVALIABLE : translations.UNAVALIABLE} />
            </span>
            <span className={cx('material-icons text-base ml-1', dropdownColor)}>
              {!isOpened ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}
            </span>
          </div>
          {isOpened && (
            <div
              className={cx(
                'z-10 absolute top-0 mt-12 w-48 shadow bg-white py-4 w-125',
                lang === 'en' ? 'right-0' : 'left-0',
              )}
            >
              <div
                className="py-2 px-4 border-t border-b border-transparent hover:border-gray-300 cursor-pointer text-xs leading-none"
                onClick={() => {
                  updateVariantStatus(false);
                  setLoadingEditted(true);
                }}
              >
                <Text value={translations.AVALIABLE} />
              </div>
              <div
                className="py-2 px-4 border-t border-b border-transparent hover:border-gray-300 cursor-pointer text-xs leading-none"
                onClick={() => {
                  updateVariantStatus(true);
                  setLoadingEditted(true);
                }}
              >
                <Text value={translations.UNAVALIABLE} />
              </div>
            </div>
          )}
        </div>
        {
          <ItemEditPopUp
            menuItemDataTrack={menuItemData?.menuItem?.trackingType}
            settings={true}
            branchIndex={branchIndex}
            storeBranch={branch}
            productId={menuItemId}
          />
        }
      </div>
      {loadingStockStatus && (
        <div className="self-center ml-3">
          <SpinnerAlt color="primary-base" />
        </div>
      )}
    </div>
  );
};

export default Variant;
