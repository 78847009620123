import React, { useState } from "react"
import filesize from "filesize"
import axios from "axios"
import cx from "classnames"
import { Text } from "components/service"
import * as translations from "constants/translations"
import { useMobile } from "hooks/index"
import { fileToBase64 } from "utils/index"
import uploadIcon from "assets/uploader-img.svg"
import warningIcon from "assets/warning.svg"
import Spinner from "./Spinner"
import { Error } from "components/form/generic"
import DeleteModal from "./DeleteModal"
import PreviewModel from "./PreviewModel"
import { ReactComponent as Trash } from "assets/white-trash.svg"
import { ReactComponent as Eye } from "assets/eye.svg"

export default ({
  size = "regular",
  direction = "col",
  justify = "center",
  items = "center",
  disabled,
  imageUrl,
  info,
  type = "wide",
  onCancel,
  onUpload,
  onRemove,
  close,
  open,
  error,
  testId_uploadImg,
  testId_BtnUpload,
  accept,
  format,
  productCatalog,
  onUpdateImageMutation,
}) => {
  const [image, setImage] = useState(imageUrl)
  const [isUploading, setUploading] = useState()
  const [isDeleting, setDeleting] = useState(false)
  const [isFailed, setFailed] = useState()
  const [meta, setMeta] = useState()
  const isMobile = useMobile()
  return (
    <div className={cx("relative w-full", disabled && "opacity-50")}>
      <div
        className={cx(
          "rounded border-2 border-dashed flex text-gray-600 relative overflow-hidden",
          size !== "bigger" && "px-3",
          isMobile && "mx-auto",
          `flex-${direction} justify-${justify} items-${items}`,
          error && "border-zyda-red-500 bg-zyda-red-50",
          isFailed && "border-zyda-red-500 bg-zyda-red-50",
          type === "squared"
            ? sizes[size].types.squared
            : sizes[size].types.wide
        )}
      >
        {isFailed ? (
          <div className={cx(size === "bigger" && "m-auto")}>
            <img
              className={cx("w-6", size === "bigger" ? "mx-auto" : "mx-3")}
              src={warningIcon}
              alt=""
            />
            <span className="text-black">
              <Text value={translations.FAILED_UPLOAD} />
            </span>
          </div>
        ) : isUploading ? (
          <>
            <Spinner
              size={size}
              className={cx(size === "bigger" && "m-auto")}
            />
            {meta && <Meta {...meta} />}
            {onCancel && (
              <button
                onClick={() => {
                  setTimeout(() => {
                    onCancel()
                    setImage(imageUrl)
                  }, 100)
                }}
                className="inline-flex text-sm text-primary-base cursor-pointer ml-auto"
              >
                <Text value={translations.CANCEL} />
              </button>
            )}
          </>
        ) : (
          <>
            <label
              className={cx(
                "label-img",
                size === "bigger" ? "m-auto" : "flex items-center flex-1"
              )}
            >
              {image ? (
                <div
                  className={cx(
                    "rounded-md",
                    size === "bigger" ? "" : "h-8 w-8"
                  )}
                >
                  <img
                    data-testid={testId_uploadImg}
                    className={cx(
                      "object-fill",
                      size === "bigger" ? "w-full h-56" : "w-full h-full"
                    )}
                    src={image}
                    alt=""
                  />
                </div>
              ) : (
                <div
                  className={cx(
                    "m-auto",
                    size !== "bigger" && "flex items-center"
                  )}
                >
                  <div
                    className={cx(
                      size === "bigger" ? "mx-auto text-center" : "mx-2",
                      sizes[size].circle.wrap
                    )}
                  >
                    <img className="w-full" src={uploadIcon} alt="" />
                  </div>
                  {info && <div className="pt-3 text-xs">{info}</div>}
                  {format && (
                    <div className="text-center text-xs">{format}</div>
                  )}
                </div>
              )}

              <input
                data-testid={testId_BtnUpload}
                disabled={disabled}
                accept={accept}
                type="file"
                style={{ opacity: 0 }}
                className="absolute w-full h-full top-0 left-0 cursor-pointer"
                onChange={async e => {
                  setUploading(true)
                  const file = e.target.files[0]
                  const base64 = await fileToBase64(file)
                  setImage(base64)

                  setMeta({ name: file.name, size: filesize(file.size) })
                  try {
                    await onUpload(file)
                  } catch (err) {
                    if (!axios.isCancel(err)) {
                      setFailed(true)
                    }
                  }
                  setUploading(false)
                  setMeta(null)
                }}
              />
            </label>
            {onRemove && (imageUrl || image) && (
              <>
                {productCatalog ? (
                  <div className="leading-none delete-img absolute w-full h-full">
                    <div className="flex justify-between h-full items-center">
                      <div
                        className={cx(
                          "cursor-pointer delete-icon",
                          size === "bigger" &&
                            "text-4xl absolute top-1/2 visible md:invisible"
                        )}
                        style={{
                          borderRadius: "50%",
                          backgroundColor: "#f3f5f7",
                          padding: "5px",
                          right: size === "bigger" && isMobile ? "60%" : "55%",
                        }}
                        onClick={() => {
                          open({
                            title: <Text value={translations.PRODUCT_IMAGE} />,
                            body: <PreviewModel image={image} />,
                            size: "max-w-xl",
                          })
                        }}
                      >
                        <Eye />
                      </div>
                      <div
                        className={cx(
                          "text-red-600 cursor-pointer bg-zyda-red-500 delete-icon",
                          size === "bigger" &&
                            "text-4xl absolute top-1/2 visible md:invisible"
                        )}
                        style={{
                          borderRadius: "50%",
                          padding: "8px",
                          right: size === "bigger" && isMobile ? "40%" : "35%",
                        }}
                        onClick={() => {
                          open({
                            title:
                              imageUrl && image ? (
                                <Text value={translations.DELETE_IMAGE} />
                              ) : (
                                <Text value={translations.DELETE_FILE} />
                              ),
                            body: (
                              <DeleteModal
                                image={imageUrl && image}
                                isDeleting={isDeleting}
                                onClick={async () => {
                                  setDeleting(true)
                                  await onRemove()
                                  setImage(null)
                                  close && close()
                                  setDeleting(false)
                                  isMobile && onUpdateImageMutation && onUpdateImageMutation();
                                }}
                                onClose={close}
                              />
                            ),
                            size: "max-w-xs",
                          })
                        }}
                      >
                        <Trash />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      open({
                        title:
                          imageUrl && image ? (
                            <Text value={translations.DELETE_IMAGE} />
                          ) : (
                            <Text value={translations.DELETE_FILE} />
                          ),
                        body: (
                          <DeleteModal
                            image={imageUrl && image}
                            isDeleting={isDeleting}
                            onClick={async () => {
                              setDeleting(true)
                              await onRemove()
                              setImage(null)
                              close()
                              setDeleting(false)
                              isMobile && onUpdateImageMutation && onUpdateImageMutation();
                            }}
                            onClose={close}
                          />
                        ),
                        size: "max-w-md",
                      })
                    }}
                    className={cx(
                      " leading-none flex items-center cursor-pointer",
                      size === "bigger"
                        ? "delete-img absolute w-full h-full"
                        : "relative ml-auto h-full"
                    )}
                  >
                    <div
                      className={cx(
                        "material-icons text-base text-red-600 delete-icon",
                        size === "bigger" &&
                          "text-4xl absolute top-1/2 visible md:invisible"
                      )}
                      style={{
                        right: size === "bigger" && isMobile ? "45%" : "40%",
                      }}
                    >
                      delete
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
      {error && <Error>{error}</Error>}
    </div>
  )
}
const Meta = ({ name, size }) => (
  <div className="flex flex-wrap w-full truncate ml-4 mr-2">
    <div className="mb-1 inline-flex justify-between w-full text-sm text-black">
      {name}
    </div>
    <div className="inline-flex w-full text-xs text-gray-700">{size}</div>
  </div>
)
const sizes = {
  small: {
    types: {
      squared: "w-10 h-10",
      wide: "h-10",
    },
    circle: {
      wrap: "w-6",
      icon: "w-4 h-4 text-xs",
    },
  },
  regular: {
    types: {
      squared: "w-16 h-16",
      wide: "h-16",
    },
    circle: {
      wrap: "w-12",
      icon: "w-8 h-8 text-base",
    },
  },
  bigger: {
    types: {
      squared: "w-full h-56",
      wide: "h-56",
    },
    circle: {
      wrap: "w-12",
      icon: "w-8 h-8 text-base",
    },
  },
}
