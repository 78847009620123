// @ts-nocheck
import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';

import * as translations from 'constants/translations';
import { context as userContext } from 'context/user';
import { context as localeContext } from 'context/locale';
import { Spinner } from 'components/kit';
import { Text, Field } from 'components/service';
import { Input } from 'components/form/elements';
import { debounce } from 'utils';
import { BRANCHES } from '../schemas';

const ThirdStep = ({ setOcimsBranches, branchLinkingSection, ocimsBranches }) => {
  const { selectedStore } = useContext(userContext);
  const { direction, translate } = useContext(localeContext);

  const headCellClassName = 'text-justify font-semibold border-b whitespace-nowrap w-1/3';
  const cellClassName = 'py-2 px-3 border-b border-gray-300 whitespace-nowrap';

  const { loading: loadingBranches, data: branchesData } = useQuery(BRANCHES, {
    variables: {
      restaurantId: selectedStore.id,
    },
    fetchPolicy: 'network-only',
  });

  const handleChangeRowValue = (branchId, key, value) => {
    const updateTableRow = () => {
      if (ocimsBranches.length === 0) {
        ocimsBranches.push({ id: branchId, [key]: value });
        setOcimsBranches(ocimsBranches);
      } else {
        const existingBranch = ocimsBranches.find(branch => branch.id === branchId);
        if (existingBranch) {
          existingBranch[key] = value;
        } else {
          ocimsBranches.push({ id: branchId, [key]: value });
        }
        setOcimsBranches(ocimsBranches);
      }
    };
    debounce(updateTableRow, 100);
  };

  return (
    <Formik>
      <Form>
        {loadingBranches ? (
          <Spinner />
        ) : (
          <table style={{ direction }} className="border shadow rounded-lg mt-4 w-full">
            <thead>
              <tr className="bg-gray-200">
                <th className={headCellClassName}>
                  <Text value={translations.BUSINESS_LOCATION} className="whitespace-nowrap inline-block m-4" />
                </th>
                {branchLinkingSection.map(branchLinkSection => (
                  <th className={headCellClassName}>
                    <Text
                      value={[branchLinkSection.title.en, branchLinkSection.title.ar]}
                      className="whitespace-nowrap inline-block m-4"
                    />
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {branchesData.branches.map(item => (
                <tr key={item.id}>
                  <td className={cellClassName}>
                    <Text value={item} className="m-4" />
                  </td>
                  <td className={cellClassName}>
                    <Field
                      type="text"
                      name={`${item.id}-external_id`}
                      placeholder={translate(translations.OCIMS_EXTERNAL_ID_HINT)}
                      component={Input}
                      onChange={e => handleChangeRowValue(item.id, 'external_id', e.target.value)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </Form>
    </Formik>
  );
};

ThirdStep.propTypes = {
  setOcimsBranches: PropTypes.func.isRequired,
  ocimsBranches: PropTypes.arrayOf(
    PropTypes.shape({
      external_id: PropTypes.string.isRequired,
      area_id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  branchLinkingSection: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.shape({
        ar: PropTypes.string.isRequired,
        en: PropTypes.string.isRequired,
      }),
      inputField: PropTypes.shape({
        backendName: PropTypes.string.isRequired,
        hintAr: PropTypes.string.isRequired,
        hintEn: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
      }),
    }),
  ),
};
export default ThirdStep;
