import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Marker, InfoBox } from '@react-google-maps/api';

import { context as localeContext } from 'context/locale';

const BranchesMarker = ({ item, zoneController, setZoneController }) => {
  const { translate } = useContext(localeContext);

  const [showInfo, setShowInfo] = useState(false);

  const onMouseOver = () => {
    if (zoneController.enableDrawerMode && !zoneController.showZoneTemplates) {
      setShowInfo(true);
    }
  };

  const onMouseOut = () => {
    setShowInfo(false);
  };

  return (
    <>
      {showInfo && (
        <InfoBox
          position={{ lat: item.lat, lng: item.lng }}
          options={{ closeBoxURL: '', enableEventPropagation: true }}
        >
          <div className="bg-white p-3 shadow">
            <div className="flex justify-between align-centertext-xs">
              <strong>{translate([item.titleEn, item.titleAr])}</strong>
            </div>
          </div>
        </InfoBox>
      )}
      <Marker
        position={{ lat: item.lat, lng: item.lng }}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        onClick={() => {
          if (zoneController.enableDrawerMode && !zoneController.showZoneTemplates)
            setZoneController(prevState => ({ ...prevState, selectedBranch: item }));
        }}
      />
    </>
  );
};

BranchesMarker.propTypes = {
  item: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
    titleEn: PropTypes.string,
    titleAr: PropTypes.string,
  }).isRequired,
  zoneController: PropTypes.shape({
    enableDrawerMode: PropTypes.bool,
    showZoneTemplates: PropTypes.bool,
  }).isRequired,
  setZoneController: PropTypes.func.isRequired,
};

export default BranchesMarker;
