import { gql } from '@apollo/client';

const Period = gql`
  fragment Period on AnalyticsPeriod {
    startDate
    endDate
    data
    keys
  }
`;

export const TOTAL_SALES = gql`
  query($storeId: String!, $date: AnalyticsPrecision!, $range: AnalyticsRange!, $breakdown: BreakdownPrecision!) {
    breakdownTotalSales(restaurantId: $storeId, date: $date, range: $range, breakdown: $breakdown) {
      currentPeriod {
        ...Period
      }
    }
  }
  ${Period}
`;

export const AVG_ORDER_VALUE = gql`
  query($storeId: String!, $date: AnalyticsPrecision!, $range: AnalyticsRange!, $breakdown: BreakdownPrecision!) {
    breakdownAvgOrderValue(restaurantId: $storeId, date: $date, range: $range, breakdown: $breakdown) {
      currentPeriod {
        ...Period
      }
    }
  }
  ${Period}
`;

export const TOTAL_ORDERS = gql`
  query($storeId: String!, $date: AnalyticsPrecision!, $range: AnalyticsRange!, $breakdown: BreakdownPrecision!) {
    breakdownTotalOrders(restaurantId: $storeId, date: $date, range: $range, breakdown: $breakdown) {
      currentPeriod {
        ...Period
      }
    }
  }
  ${Period}
`;

export const TOTAL_CUSTOMERS = gql`
  query($storeId: String!, $date: AnalyticsPrecision!, $range: AnalyticsRange!, $breakdown: BreakdownPrecision!) {
    breakdownTotalCustomers(restaurantId: $storeId, date: $date, range: $range, breakdown: $breakdown) {
      currentPeriod {
        ...Period
      }
    }
  }
  ${Period}
`;

export const NEW_VS_RETURNING_CUSTOMERS = gql`
  query($storeId: String!, $date: AnalyticsPrecision!, $range: AnalyticsRange!) {
    newVsReturningCustomers(restaurantId: $storeId, date: $date, range: $range) {
      startDate
      endDate
      data {
        totalSales
        totalOrders
        avgOrderValue
        totalCustomers
      }
      keys {
        totalSales
        totalOrders
        avgOrderValue
        totalCustomers
      }
    }
  }
`;
