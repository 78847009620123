import React from 'react';

import { Text } from 'components/service';
import {
  CAPITAL_CASH,
  KNET_NO_DASH,
  MASTER_CARD,
  BENEFIT,
  CARDONDELIVERY,
  VISA,
  CREDITCARD,
  APPLE_PAY_ANALYTICS,
  CASH,
  ONLINE,
  MADA,
  MEEZA,
} from 'constants/translations';

export const ANALYTICS_TAB_NAMES = {
  area: {
    name: 'Area',
    snakeCaseName: 'area',
    camelCase: 'area',
  },
  branch: {
    name: 'Branch',
    snakeCaseName: 'branch',
    camelCase: 'branch',
  },
  totalSales: {
    name: 'Total Sales',
    snakeCaseName: 'total_sales',
    camelCase: 'totalSales',
  },
  averageOrderValue: {
    name: 'Average Order Value',
    snakeCaseName: 'avg_order_value',
    camelCase: 'avgOrderValue',
  },
  totalOrders: {
    name: 'Total Orders',
    snakeCaseName: 'total_orders',
    camelCase: 'totalOrders',
  },
  totalDeliveryOrders: {
    name: 'Total Delivery Orders',
    snakeCaseName: 'total_delivery_orders',
    camelCase: 'totalDeliveryOrders',
  },
  totalPickupOrders: {
    name: 'Total Pickup Orders',
    snakeCaseName: 'total_pickup_orders',
    camelCase: 'totalPickupOrders',
  },
  totalCustomers: {
    name: 'Total Customers',
    snakeCaseName: 'total_customers',
    camelCase: 'totalCustomers',
  },
};

export const PAYMENT_METHOD_COLUMNS = {
  CASH: { key: 'cash', name: <Text value={CAPITAL_CASH} /> },
  KNET: { key: 'knet', name: <Text value={KNET_NO_DASH} /> },
  MASTERCARD: {
    key: 'mastercard',
    name: <Text value={MASTER_CARD} />,
  },
  BENEFIT: { key: 'benefit', name: <Text value={BENEFIT} /> },
  CARDONDELIVERY: {
    key: 'cardondelivery',
    name: <Text value={CARDONDELIVERY} />,
  },
  VISA: { key: 'visa', name: <Text value={VISA} /> },
  CREDITCARD: {
    key: 'creditcard',
    name: <Text value={CREDITCARD} />,
  },
  APPLE_PAY: {
    key: 'applepay',
    name: <Text value={APPLE_PAY_ANALYTICS} />,
  },
  Cash: { key: 'cash', name: <Text value={CASH} /> },
  Online: { key: 'online', name: <Text value={ONLINE} /> },
  MASTER: { key: 'master', name: <Text value={MASTER_CARD} /> },
  MADA: { key: 'mada', name: <Text value={MADA} /> },
  MEEZA: { key: 'meeza', name: <Text value={MEEZA} /> },
};
