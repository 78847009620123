import React from "react"
import { Box } from "components/kit"
import { Text } from "components/service"
import * as translations from "constants/translations"

export default ({ paymentCredential, currency }) => {
  const { totalAwaitingBalance,
          totalNumberOfTransactions,
          totalSupplierInvoiceShare,
          totalDepositedAmount } = paymentCredential

  const { titleAr: currencyAr, titleEn: currencyEn } = currency

  return (
    <div className="mx-2 mb-2 flex gap-3">
      <Box
        title={<Text value={[`${totalAwaitingBalance} ${currencyEn}`, `${totalAwaitingBalance} ${currencyAr}`]} />}
        description={<Text className="mt-3 text-gray-500" value={translations.totalAwaitingBalance} />}
        displayBorderBottom={false}
      />
      <Box
        title={<Text value={[`${totalDepositedAmount} ${currencyEn}`, `${totalDepositedAmount} ${currencyAr}`]} />}
        description={<Text className="mt-3 text-gray-500" value={translations.totalDepositedAmount} />}
        displayBorderBottom={false}
      />
      <Box
        title={<Text value={[`${totalSupplierInvoiceShare} ${currencyEn}`, `${totalSupplierInvoiceShare} ${currencyAr}`]} />}
        description={<Text className="mt-3 text-gray-500" value={translations.totalSupplierInvoiceShare} />}
        displayBorderBottom={false}
      />
      <Box
        title={<Text value={[`${totalNumberOfTransactions}`, `${totalNumberOfTransactions}`]} />}
        description={<Text className="mt-3 text-gray-500" value={translations.totalNumberOfTransactions} />}
        displayBorderBottom={false}
      />
    </div>
)}
