import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@zydalabs/zac-react';

import { Input } from 'components/form/elements';
import { context as notificationsContext } from 'context/notifications';
import { context as customersContext } from 'context/customers';
import { context as localeContext } from 'context/locale';
import {
  CANCEL,
  CONFIRM,
  FILE_NAME_EXIST,
  FILE_NAME_PLACEHOLDER,
  FILE_NAME_REQURIED,
  FILE_NAME_SUBTEXT,
  SOMETHING_WENT_WRONG,
} from 'constants/translations';
import { Label, Row } from 'components/form/generic';
import { Text } from 'components/service';
import { useSelectedStore } from 'hooks';
import { importContactFile } from 'rest';
import { CAMPAIGN_OBJECT_KEYS } from '../../constants';
import { fileErrorCodes } from '../utils';

const CampaignContactsFileNameModal = ({ close, setFieldValue }) => {
  const { direction, translate } = useContext(localeContext);
  const storeId = useSelectedStore();
  const notifications = useContext(notificationsContext);
  const { fileController, setFileController } = useContext(customersContext);
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState(fileController.uploadingFileName);

  // rename file
  const renameFile = (originalFile, newName) =>
    new File([originalFile], newName, {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
      lastModifiedDate: originalFile.lastModifiedDate,
    });

  // set controller values
  const setControllerValues = response => {
    setFieldValue(CAMPAIGN_OBJECT_KEYS.UPLOADED_FILE_NAME, response.file_name);
    setFieldValue(CAMPAIGN_OBJECT_KEYS.ESTIMATE_REACH, fileController?.uploadingFileResponse?.validRecordCount);
    setFileController(prevState => ({
      ...prevState,
      uploadingFile: renameFile(fileController.uploadingFile, response.file_name),
      isFileValidated: true,
    }));
    close();
  };
  const handleFormSubmit = async () => {
    if (fileName.length >= 1) {
      const fileNameWithExe = `${fileName}.${fileController.uploadingFileExe}`;
      setLoading(true);
      try {
        const response = await importContactFile(storeId, renameFile(fileController.uploadingFile, fileNameWithExe));
        const { errors } = response;
        setLoading(false);
        // check if file contains errors
        if (errors.length >= 1) {
          const isNameExist = errors.find(errorItem => errorItem.error_code === fileErrorCodes.FILE_NAME_EXIST);
          if (isNameExist) {
            notifications.show(<Text value={FILE_NAME_EXIST} />, 'error');
          } else {
            setControllerValues(response);
          }
        } else {
          setControllerValues(response);
        }
      } catch (err) {
        setLoading(false);
        notifications.show(<Text value={SOMETHING_WENT_WRONG} />, 'error');
      }
    }
  };

  return (
    <div className="px-4">
      <Row className="mb-3">
        <Label textSize="text-xs" subtitle={<Text value={FILE_NAME_SUBTEXT} />}>
          <Input
            value={fileName}
            onChange={e => setFileName(e.target.value)}
            type="text"
            placeholder={translate(FILE_NAME_PLACEHOLDER)}
            direction={direction}
            error={fileName.length === 0 ? <Text value={FILE_NAME_REQURIED} /> : ''}
          />
        </Label>
      </Row>

      <div className="mt-4 flex justify-end">
        <div className={direction === 'rtl' ? 'ml-2' : 'mr-2'}>
          <Button onClick={close} type="button" variant="tertiary" size="medium" rounded text={translate(CANCEL)} />
        </div>
        <div>
          <Button
            isLoading={loading}
            disabled={fileName.length === 0 || loading}
            size="medium"
            rounded
            text={translate(CONFIRM)}
            onClick={handleFormSubmit}
          />
        </div>
      </div>
    </div>
  );
};

CampaignContactsFileNameModal.propTypes = {
  close: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default CampaignContactsFileNameModal;
