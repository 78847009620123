import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { useSelectedStore } from 'hooks';
import useCreateDeliveryZone from 'service/hooks/deliveryZones/useCreateDeliveryZone';
import { convertTime } from 'utils';
import {
  DELIVERY_ZONE_GEO_JSON_FEATURE_COLLECTION_TEMPLATE_CIRCLE,
  DELIVERY_ZONE_GEO_JSON_FEATURE_CIRCLE_TEMPLATE,
} from 'constants/index';
import { convertedDeliveryTimeUtils, dzCrudErrorHandler } from '../utils';
import { trackDeliveryZoneEvents } from '../../ZonesList/utils';
import ZoneForm from '../ZoneForm';

const Creation = ({ zoneController, setZoneController, setZones }) => {
  const { user } = useContext(userContext);
  const { translate } = useContext(localeContext);
  const [pending, setPending] = useState(false);
  const createDeliveryZone = useCreateDeliveryZone();
  const selectedStoreId = useSelectedStore();
  const { pos: POSIntegration } = useContext(userContext);
  const needsPOSExternalId = POSIntegration?.supportZoneMapping;

  const initialValues = {
    zoneName: '',
    deliveryFee: '',
    minValue: '',
    posExternalId: '',
    enableZone: true,
    deliveryTime: [0, 'mins'],
    openingHours: zoneController.selectedBranch.openingHours,
    needsPOSExternalId,
  };

  const handleFormSubmit = async (values, setErrors) => {
    const convertedDeliveryTime = convertedDeliveryTimeUtils(
      values.deliveryTime[1],
      values.deliveryTime[0],
      convertTime,
    );

    const addNewZone = {
      ...zoneController.selectedZone,
      properties: {
        ...zoneController.selectedZone.properties,
        zone_name: values.zoneName,
        delivery_fee: parseFloat(values.deliveryFee),
        minimum_order: parseFloat(values.minValue),
        pos_external_id: values.posExternalId,
        published: values.enableZone,
        opening_hours: values.openingHours,
        delivery_time: convertedDeliveryTime,
        fillOpacity: 0.5,
      },
    };
    setPending(true);

    const response = await createDeliveryZone({
      params: {
        restaurantReferenceId: parseInt(selectedStoreId),
        branchReferenceId: addNewZone.properties.branch_reference_id,
        zoneName: addNewZone.properties.zone_name,
        minimumOrder: addNewZone.properties.minimum_order ? addNewZone.properties.minimum_order : 0,
        deliveryFee: addNewZone.properties.delivery_fee,
        deliveryTime: addNewZone.properties.delivery_time,
        posExternalId: addNewZone.properties.pos_external_id,
        openingHours: addNewZone.properties.opening_hours,
        geoShape: addNewZone.geoShape,
        published: addNewZone.properties.published,
      },
    });
    if (response.hasError) {
      setPending(false);
      dzCrudErrorHandler(response, setErrors, translate, translations);
    } else {
      setPending(false);
      setZones(prevState =>
        prevState.concat({
          geometry: addNewZone.geometry,
          properties: { ...addNewZone.properties, id: parseInt(response.createDeliveryZone.deliveryZone.id) },
          paths: addNewZone.paths,
          type: 'Feature',
        }),
      );
      trackDeliveryZoneEvents(selectedStoreId, user.id, 'Create Delivery Zone Map', {
        ...values,
        openingHours: JSON.stringify(values.openingHours),
        zoneType: addNewZone.geometry.type,
      });
      zoneController.shapeRef.setMap(null);

      setZoneController({
        ...zoneController,
        isAdd: false,
        drawerMode: null,
        showDrawerMode: false,
        enableDrawerMode: true,
        shapeRef: null,
        selectedZone: null,
        circleRef: null,
        addZoneNotify: true,
      });
    }
  };

  const handleCancelMode = () => {
    zoneController.shapeRef.setMap(null);
    setZoneController({
      ...zoneController,
      isAdd: false,
      drawerMode: null,
      showDrawerMode: false,
      enableDrawerMode: true,
      shapeRef: null,
      selectedZone: null,
      circleShape: null,
      prevZone: {
        paths: null,
        center: null,
        radius: null,
      },
    });
  };

  const handleFormCircleSubmit = async (values, setErrors) => {
    const convertedDeliveryTime = convertedDeliveryTimeUtils(
      values.deliveryTime[1],
      values.deliveryTime[0],
      convertTime,
    );
    const geoJsonFeatureCollection = DELIVERY_ZONE_GEO_JSON_FEATURE_COLLECTION_TEMPLATE_CIRCLE;
    const geoJsonPolygonFeature = DELIVERY_ZONE_GEO_JSON_FEATURE_CIRCLE_TEMPLATE;
    geoJsonPolygonFeature.geometry.coordinates = [
      zoneController.circleShape.center.lng,
      zoneController.circleShape.center.lat,
    ];

    geoJsonFeatureCollection.features = [geoJsonPolygonFeature];

    const createdZone = {
      geometry: geoJsonPolygonFeature.geometry,
      properties: {
        branch_reference_id: zoneController.selectedBranch.id,
        fillColor: zoneController.selectedBranch.color,
        id: Date.now(),
        zone_name: values.zoneName,
        pos_external_id: values.posExternalId,
        delivery_fee: parseFloat(values.deliveryFee),
        minimum_order: parseFloat(values.minValue),
        published: values.enableZone,
        opening_hours: values.openingHours,
        delivery_time: convertedDeliveryTime,
        fillOpacity: 0.5,
        radius: zoneController.shapeRef.getRadius(),
      },
      geoShape: geoJsonFeatureCollection,
    };

    setPending(true);

    const response = await createDeliveryZone({
      params: {
        restaurantReferenceId: parseInt(selectedStoreId),
        branchReferenceId: createdZone.properties.branch_reference_id,
        zoneName: createdZone.properties.zone_name,
        minimumOrder: createdZone.properties.minimum_order ? createdZone.properties.minimum_order : 0,
        deliveryFee: createdZone.properties.delivery_fee,
        deliveryTime: createdZone.properties.delivery_time,
        posExternalId: createdZone.properties.pos_external_id,
        openingHours: createdZone.properties.opening_hours,
        geoShape: createdZone.geoShape,
        published: createdZone.properties.published,
        radius: createdZone.properties.radius,
      },
    });

    if (response.hasError) {
      setPending(false);
      dzCrudErrorHandler(response, setErrors, translate, translations);
    } else {
      setPending(false);
      setZones(prevState =>
        prevState.concat({
          geometry: createdZone.geometry,
          properties: { ...createdZone.properties, id: parseInt(response.createDeliveryZone.deliveryZone.id) },
          type: 'Feature',
        }),
      );

      trackDeliveryZoneEvents(selectedStoreId, user.id, 'Create Delivery Zone Map', {
        ...values,
        openingHours: JSON.stringify(values.openingHours),
        zoneType: createdZone.geometry.type,
      });
      zoneController.shapeRef.setMap(null);

      setZoneController({
        ...zoneController,
        isAdd: false,
        drawerMode: null,
        showDrawerMode: false,
        enableDrawerMode: true,
        shapeRef: null,
        selectedZone: null,
        circleShape: null,
        addZoneNotify: true,
      });
    }
  };

  return (
    <div className="bg-white p-4 mb-4 shadow">
      <div className="flex justify-between align-center mb-4">
        <Text className="text-base" value={translations.ZONE_PROPERTIES} />
        <div className="material-icons text-base cursor-pointer" onClick={handleCancelMode} aria-hidden="true">
          close
        </div>
      </div>
      <ZoneForm
        handleFormSubmit={zoneController.circleShape ? handleFormCircleSubmit : handleFormSubmit}
        handleCancelMode={handleCancelMode}
        initialValues={initialValues}
        pending={pending}
        needsPOSExternalId={needsPOSExternalId}
      />
    </div>
  );
};

Creation.propTypes = {
  zoneController: PropTypes.shape({
    selectedZone: PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.string,
      zoneName: PropTypes.string,
      posExternalId: PropTypes.string,
      deliveryFee: PropTypes.string,
      minValue: PropTypes.string,
      enableZone: PropTypes.bool,
    }),
    shapeRef: PropTypes.shape({
      id: PropTypes.number,
    }),
    drawerMode: PropTypes.string,
  }).isRequired,
  setZoneController: PropTypes.func.isRequired,
  setZones: PropTypes.func.isRequired,
};

export default Creation;
