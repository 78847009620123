import { gql } from '@apollo/client';

export const PAYMENT_GATEWAYS = gql`
  query($countryId: Int!) {
    paymentGateways(countryId: $countryId) {
      id
      name
      extraData {
        title {
          ar
          en
        }
        description {
          ar
          en
        }
        pricingUrl
      }
      logoUrl
      paymentMethods {
        name
        code
        id
      }
    }
  }
`;
export const PAYMENT_CREDENTIALS = gql`
  query($storeId: String!) {
    paymentCredentials(restaurantId: $storeId) {
      id
      merchantId
      countryCode
      transfer
      status
      profileName
      publicApiKey
      remarks
      bankAccountInfo {
        iban
      }
      corporateInfo {
        name
      }
      restaurantPaymentMethods {
        name
        enabled
        id
        referenceId
        gmvText {
          ar
          en
        }
      }
      paymentGateway {
        id
        name
      }
    }
    settings(restaurantId: $storeId) {
      id
      onlinePaymentEnabled
      cashPaymentEnabled
      cardondeliveryPaymentEnabled
    }
  }
`;

export const UPDATE_ONLINE_PAYMENT_ENABLED = gql`
  mutation($storeId: String!, $enabled: Boolean) {
    updateSettings(restaurantId: $storeId, onlinePaymentEnabled: $enabled) {
      id
      onlinePaymentEnabled
    }
  }
`;

export const UPDATE_CASH_ON_DELIVERY_ENABLED = gql`
  mutation($storeId: String!, $enabled: Boolean) {
    updateSettings(restaurantId: $storeId, cashPaymentEnabled: $enabled) {
      id
      cashPaymentEnabled
    }
  }
`;

export const UPDATE_CARD_ON_DELIVERY_ENABLED = gql`
  mutation($storeId: String!, $enabled: Boolean) {
    updateSettings(restaurantId: $storeId, cardondeliveryPaymentEnabled: $enabled) {
      id
      cardondeliveryPaymentEnabled
    }
  }
`;
