import React from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';

import { Box } from 'components/kit';
import * as translations from 'constants/translations';
import { Text } from 'components/service';
import * as schemas from './schemas';
import ActionButton from './ActionButton';

const PaymentMethodsSection = ({
  restaurantId,
  id,
  paymentCredentials,
  paymentGatewayId,
  refetch,
  title,
  description,
  isEnabled,
  icon,
  hasManualPaymentMethod,
  numOfEnabledOnlineMethods,
}) => {
  const [updateEnabled, { loading: updating }] = useMutation(schemas.UPDATE_PAYMENT_METHODS_ENABLED, {
    variables: { restaurantId, id, enabled: !isEnabled },
    onCompleted: refetch,
  });
  const canDisable = isEnabled && numOfEnabledOnlineMethods < 2 && !hasManualPaymentMethod;

  return (
    <div className="mt-4" key={`payment_method_${id}`}>
      <Box
        flexWrapClass={false}
        title={<Text className="ml-2 text-xs font-normal" value={title} />}
        note={<Text className="w-fit ml-9 text-xs text-gray-600" value={description} />}
        icon={icon}
        action={
          paymentCredentials?.paymentGateway?.id === paymentGatewayId && (
            <ActionButton
              testId={`enable-${title[0].substring(7)}-btn`}
              isSpinning={updating}
              onClick={updateEnabled}
              disabled={canDisable}
            >
              <Text value={isEnabled ? translations.DISABLE : translations.ENABLE} />
            </ActionButton>
          )
        }
      />
    </div>
  );
};

PaymentMethodsSection.propTypes = {
  restaurantId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  paymentCredentials: PropTypes.shape({
    paymentGateway: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  paymentGatewayId: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
  title: PropTypes.arrayOf(PropTypes.string).isRequired,
  description: PropTypes.arrayOf(PropTypes.string).isRequired,
  isEnabled: PropTypes.bool.isRequired,
  icon: PropTypes.string.isRequired,
  hasManualPaymentMethod: PropTypes.bool.isRequired,
  numOfEnabledOnlineMethods: PropTypes.number.isRequired,
};
export default PaymentMethodsSection;
