import { gql } from "@apollo/client"

export const USER = gql`
  query($userId: String!, $storeId: String!) {
    user(id: $userId, restaurantId: $storeId) {
      id
      name
      email
      phoneNumber
      createdAt
      roles {
        id
        resourceId
        role
      }
    }
  }
`

export const UPDATE_USER = gql`
  mutation(
    $userId: String!
    $storeId: String!
    $name: String!
    $email: String!
    $phoneNumber: String!
    $roles: [UserResource]!
  ) {
    updateUser(
      userId: $userId
      restaurantId: $storeId
      name: $name
      email: $email
      phoneNumber: $phoneNumber
      roles: $roles
    ) {
      id
    }
  }
`
