import React from "react"
import { Text } from "components/service"
import { Button } from "components/kit"
import { Container, Row, Footer } from "components/form/generic"
import * as translations from "constants/translations"

export default ({ onClick, onClose, isDeleting, image }) => {
  return (
    <>
      <div className="px-6">
        <Container>
          <Row>
            {image ? (
              <Text value={translations.ARE_YOU_SURE_DELETE_IMAGE} />
            ) : (
              <Text value={translations.ARE_YOU_SURE_DELETE_FILE} />
            )}
          </Row>
        </Container>
      </div>
      <Footer modalFooter>
        <div className="w-full flex items-center">
          <div className="ml-auto">
            <Row>
              <Button onClick={onClose} type="button" kind="tertiary">
                <Text value={translations.DISMISS} />
              </Button>
              <Button
                onClick={onClick}
                isSpinning={isDeleting}
                kind="secondaryError"
              >
                <Text value={translations.DELETE} />
              </Button>
            </Row>
          </div>
        </div>
      </Footer>
    </>
  )
}
