import React, { useState, useContext } from 'react';

import Stepper from './Stepper';
import * as translations from 'constants/translations';
import { Text } from 'components/service';
import { Footer } from 'components/form/generic';
import { Button } from 'components/kit';
import { context as localeContext } from 'context/locale';
import * as CONSTANTS from './constants';

const Wizard = ({ children, isModal, additionalActions, defaultStep = 1, isLoading = false, executeFunction }) => {
  const { direction } = useContext(localeContext);

  const [currentStepNumber, setCurrentStep] = useState(defaultStep);

  const stepArray = children;

  const proceedToNextStep = newStep => {
    newStep++;
    if (newStep > 0 && newStep <= stepArray.length) {
      setCurrentStep(newStep);
    }
  };

  const validateStepForm = (currentStep, newStep) => {
    const currentStepProps = stepArray[currentStep]?.props;

    if (currentStepProps?.validateForm) {
      currentStepProps.validateForm().then(result => {
        if (Object.keys(result).length === 0) {
          proceedToNextStep(newStep);
        } else {
          currentStepProps?.step?.fields &&
            currentStepProps.step.fields.forEach((field, i) => {
              currentStepProps.setFieldTouched(field.name, true);
            });
        }
      });
    }
  };

  const submitForm = currentStep => {
    stepArray[currentStep].props.submitForm();
  };

  const handleNextClick = (addedValue, isFinish, executeFunction) => {
    let newStep = currentStepNumber;
    let currentStep = newStep - 1;
    if (executeFunction) {
      executeFunction().then(res => {
        if (res?.data?.validateOcimsCredentials?.valid) {
          newStep = currentStep + addedValue;
          validateStepForm(currentStep, newStep);
          setCurrentStep(newStep);
        }
      });
    }
    if (isFinish) {
      submitForm(currentStep);
    } else if (!executeFunction) {
      newStep = currentStep + addedValue;
      validateStepForm(currentStep, newStep);
      setCurrentStep(newStep);
    }
  };

  const handleBackClick = () => {
    let newStep = currentStepNumber;
    newStep--;
    setCurrentStep(newStep);
  };

  return (
    <div style={{ direction }}>
      <div className="container horizontal mt-5 mb-12">
        <Stepper steps={stepArray} currentStepNumber={currentStepNumber} />
      </div>

      <Footer modalFooter={isModal}>
        <div className="flex flex-1 justify-between">
          <div>
            {currentStepNumber !== 1 && (
              <Button kind="secondary" onClick={() => handleBackClick()}>
                <Text value={translations.BACK} />
              </Button>
            )}
          </div>

          <div className="flex flex-start">
            {additionalActions &&
              additionalActions.map(action => (
                <div className="px-2 flex items-center" key={action.title}>
                  {action.type === CONSTANTS.BUTTON && currentStepNumber !== 1 && (
                    <Button kind="secondary" onClick={action.onClick}>
                      <Text value={action.title} />
                    </Button>
                  )}
                  {action.type === CONSTANTS.URL && (
                    <a className="underline text-primary-base" target="_blank" href={action.href}>
                      <Text value={action.title} />
                    </a>
                  )}
                </div>
              ))}

            <Button
              kind="primary"
              onClick={() => handleNextClick(1, currentStepNumber === stepArray.length, executeFunction)}
              isSpinning={isLoading}
            >
              <Text value={currentStepNumber === stepArray.length ? translations.FINISH : translations.NEXT} />
            </Button>
          </div>
        </div>
      </Footer>
    </div>
  );
};

export default Wizard;
