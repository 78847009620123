import React from 'react';
import { Text } from 'components/service';
import * as translations from 'constants/translations';

export const PAYMENT_METHODS_MAPPING = {
  BENEFIT: 'BENEFIT',
  CREDIT_CARD: 'CREDIT_CARD',
  MEEZA: 'MEEZA',
  KNET: 'KNET',
  APPLE_PAY: 'APPLE_PAY',
  MADA: 'MADA',
};

export const PAYMENT_STATUSES = [
  {
    id: 'SUCCEEDED',
    title: <Text value={translations.PAYMENT_TRANSACTION_CAPTURED} />,
  },
  {
    id: 'INITIATED',
    title: <Text value={translations.PAYMENT_TRANSACTION_INITIATED} />,
  },
  {
    id: 'FAILED',
    title: <Text value={translations.PAYMENT_TRANSACTION_FAILED} />,
  },
];
export const PAYMENT_STATUS_TYPES = {
  PENDING: 'PENDING',
  INITIATED: 'INITIATED',
};

export const DELIVERY_TYPES = [
  {
    id: 'delivery',
    title: <Text value={translations.DELIVERY} />,
  },
  {
    id: 'pickup',
    title: <Text value={translations.PICKUP} />,
  },
];
export const SALES_BREAKDOWN_TABLE_KEYS = {
  DATE: 'date',
  TOTAL_SALES: 'totalSales',
  AVG_ORDER_VALUE: 'avgOrderValue',
  TOTAL_ORDERS: 'totalOrders',
  TOTAL_CUSTOMERS: 'totalCustomers',
};
