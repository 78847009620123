import React from 'react';
import moment from 'moment';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { GIFT_RECEIPT, VAT_ID } from 'constants/translations';

const OrderInvoiceTitle = ({ lang, store, logoPlaceholder, translate, order, direction, name, legalData, isGift }) => {
  const { createdAt, number } = order || {};
  const { logoUrl, timeZone } = store || {};
  const { companyNameEn, companyNameAr, vatIdNumber } = legalData || {};

  return (
    <div>
      <div className={cx('flex justify-between p-3 items-end mb-8', lang === 'ar' && 'flex-row-reverse')}>
        <div className={cx('flex flex-col flex-grow', lang === 'ar' && 'items-end')}>
          <img src={logoUrl || logoPlaceholder} alt="logo" className="h-auto w-20 mr-2" />
          <h2 className="text-2xl font-medium text-zyda-grey-600">{translate(name)}</h2>
          {(companyNameAr || companyNameEn) && <span className="text-sm">{companyNameAr || companyNameEn}</span>}
          {vatIdNumber && (
            <div className={cx('flex mt-2', lang === 'ar' && 'flex-row-reverse')}>
              <span className="text-sm" style={{ direction }}>
                {translate(VAT_ID)}:
              </span>
              <span className="text-sm font-bold px-1" style={{ direction }}>
                {vatIdNumber}
              </span>
            </div>
          )}
        </div>
        <div className={cx('flex flex-col', lang !== 'ar' && 'flex-row-reverse')}>
          <span className="block text-lg font-medium self-end">
            {moment(createdAt)
              .tz(timeZone)
              .locale(lang === 'ar' ? 'ar' : 'en-gb')
              .format('DD MMM YYYY, h:mm A')}
          </span>
          <h2 className="text-2xl font-medium self-end text-zyda-grey-600">
            <span className="block text-2xl">#{number}</span>
          </h2>
        </div>
      </div>
      {isGift && (
        <div className="text-4xl mb-8 font-medium" style={{ direction }}>
          {translate(GIFT_RECEIPT)}
        </div>
      )}
    </div>
  );
};

OrderInvoiceTitle.propTypes = {
  lang: PropTypes.string.isRequired,
  translate: PropTypes.func,
  direction: PropTypes.string.isRequired,
  name: PropTypes.string,
  isGift: PropTypes.bool,
  store: PropTypes.shape({ logoUrl: PropTypes.string, timeZone: PropTypes.string }),
  logoPlaceholder: PropTypes.string,
  order: PropTypes.shape({ createdAt: PropTypes.string, number: PropTypes.number }),
  legalData: PropTypes.shape({
    companyNameEn: PropTypes.string,
    companyNameAr: PropTypes.string,
    vatIdNumber: PropTypes.string,
  }),
};
export default OrderInvoiceTitle;
