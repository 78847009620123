export const VitallyScripts = () => {
  var script = document.createElement("script")
  script.type = "text/javascript"
  script.src = "https://cdn.vitally.io/vitally.js/v1/vitally.js"
  document.getElementsByTagName("head")[0].appendChild(script)

  var script1 = document.createElement("script")
  script1.type = "text/javascript"
  script1.textContent = `!function(n,t,r){for(var i=n[t]=n[t]||[],o=function(r){i[r]=i[r]||function(){for(var n=[],t=0;t<arguments.length;t++)n[t]=arguments[t];return i.push([r,n])}},u=0,c=["init","user","account","track","nps"];u<c.length;u++){o(c[u])}}(window,"Vitally");
  Vitally.init('${process.env.REACT_APP_VITALLY}');`
  document.getElementsByTagName("head")[0].appendChild(script1)
}

export const npsBox = isMobile => {
  var npsBox = document.querySelector(
    ".Vitally--reset .Vitally--NpsSurveyView-container"
  )
  var scorePicker = document.querySelector(
    ".Vitally--reset .Vitally--ScorePicker-optionGroup"
  )
  if (npsBox) {
    npsBox.setAttribute("style", "display:block !important")
    isMobile &&
      scorePicker.setAttribute(
        "style",
        "justify-content:space-between !important"
      )
  }
  var buttons = document.querySelectorAll(
    ".Vitally--reset .Vitally--Button-button"
  )
  if (buttons) {
    document
      .querySelectorAll(".Vitally--reset .Vitally--Button-button")
      .forEach(btn =>
        btn.addEventListener("click", function() {
          const firstItem = btn.previousElementSibling?.previousElementSibling?.classList?.contains(
            "Vitally--Popover-popoverIter0"
          )
          const lastItem = btn.previousElementSibling?.previousElementSibling?.classList?.contains(
            "Vitally--Popover-popoverIter10"
          )
          if (firstItem || lastItem) {
            isMobile &&
              btn.previousElementSibling.previousElementSibling.firstChild.setAttribute(
                "style",
                "transform:translate(-50%,-50%)!important"
              )
          } else {
            isMobile &&
              btn.previousElementSibling.firstChild.setAttribute(
                "style",
                "transform:translate(-50%,-50%)!important"
              )
          }
        })
      )
  }
}
