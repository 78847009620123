import { gql } from "@apollo/client"

export const CREATE_DELIVERY_ZONE = gql`
  mutation(
    $restaurantId: String!
    $branchId: String!
    $countryId: String!
    $deliveryTime: Int!
    $deliveryFee: Float!
    $minimumOrder: Float!
    $scheduleMaxPeriod: Int
    $openingHours: [WorkingDay]
  ) {
    createDeliveryZone(
      restaurantId: $restaurantId
      branchId: $branchId
      countryId: $countryId
      deliveryTime: $deliveryTime
      deliveryFee: $deliveryFee
      minimumOrder: $minimumOrder
      scheduleMaxPeriod: $scheduleMaxPeriod
      openingHours: $openingHours
    ) {
      id
      countryId
      deliveryTime
      deliveryFee
      minimumOrder
      scheduleMaxPeriod
      openingHours {
        day
        openAt
        closeAt
        offDay
      }
    }
  }
`
export const ADD_AREAS_TO_ZONE = gql`
  mutation(
    $ids: [Int!]
    $branchId: String!
    $restaurantId: String!
    $deliveryZoneId: String!
  ) {
    addAreasToDeliveryZone(
      ids: $ids
      restaurantId: $restaurantId
      branchId: $branchId
      deliveryZoneId: $deliveryZoneId
    ) {
      id
    }
  }
`
