import React, { useContext } from "react"
import * as R from "ramda"
import { Redirect, Location } from "@reach/router"
import { useReplaceParams } from "hooks/index"
import { context as userContext } from "context/user"
import * as paths from "paths.js"

export default () => <Main />

const Main = () => {
  const { stores } = useContext(userContext)
  const replace = useReplaceParams()

  return (
    <Location>
      {({ location }) => {
        const storeIdFromUrl = location.pathname
          .split("/")
          .filter(R.identity)[0]

        return (
          <Redirect
            noThrow
            to={replace(paths.pendingOrders, {
              omitQuery:true,
              storeId: storeIdFromUrl || stores[0].id,
            })}
          />
        )
      }}
    </Location>
  )
}
