import React, { useContext } from 'react';
import posed, { PoseGroup } from 'react-pose';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { context as localeContext } from 'context/locale';
import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { Button } from 'components/kit';

const Wrap = posed.div({
  enter: { opacity: 1, transition: { duration: 200 } },
  exit: { opacity: 0, transition: { duration: 200 } },
  style: { backgroundColor: 'rgba(0, 0, 0, 0.62)', zIndex: '999999' },
});

const Body = posed.div({
  enter: { opacity: 1, x: 0, transition: { duration: 200 } },
  exit: {
    opacity: 0,
    x: props => (props.lang === 'ar' ? 200 : -200),
    transition: { duration: 200 },
  },
  style: { zIndex: '999999' },
});

const MobileFilters = ({ isVisible, onClose, onApply, children }) => {
  const { lang } = useContext(localeContext);

  return (
    <PoseGroup animateOnMount>
      {isVisible && [
        <Wrap key="wrap" className="fixed top-0 left-0 w-full h-full flex" />,
        <Body
          lang={lang}
          key="body"
          className={cx(
            'flex flex-col w-full h-screen bg-white absolute top-0 p-4 pb-16',
            lang === 'ar' ? 'right-0' : 'left-0 ',
          )}
        >
          <div className={cx('flex justify-between items-center p-4', lang === 'ar' && 'flex-row-reverse')}>
            <Text value={translations.FILTER_BY} className="text-lg font-semibold" />
            <i
              className="material-icons cursor-pointer text-primary-base text-2xl -mx-4"
              role="presentation"
              onClick={onClose}
            >
              close
            </i>
          </div>
          <div className={cx('flex flex-col overflow-y-auto', lang === 'ar' && 'flex-row-reverse')}>{children}</div>
          <div className="absolute bottom-0 left-0 right-0 w-full px-4 py-2">
            <Button onClick={() => onApply()} kind="primary" size="base" full>
              <Text value={translations.APPLY_FILTERS} />
            </Button>
          </div>
        </Body>,
      ]}
    </PoseGroup>
  );
};

MobileFilters.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  onApply: PropTypes.func,
  children: PropTypes.shape({ props: PropTypes.func }),
};
export default MobileFilters;
