import React from 'react';
import cx from 'classnames';
import { ArabicNumbers } from 'react-native-arabic-numbers';
import { Tag } from '@zydalabs/zac-react';

import { Text } from 'components/service';
import { ReactComponent as UpgradeRocketIcon } from 'assets/upgrade-rocket.svg';
import { CUSTOMERS, GET_WITH_GROWTH, OF_TOTAL, RECOMMENDED, SEGMENT_TITLE_ALL_CUSTOMERS_DESC } from 'constants/translations';

const Segment = ({
  segmentId,
  handleFilterChange,
  currentFilter,
  segmentCss,
  segmentIcon,
  segmentName,
  segmentText,
  numberOfUsersBelongingToSegment,
  percentageOfUsersInSegment,
  lang,
  hasAllCustomers,
  translate,
  upgradable,
}) => {
  const baseCss = 'cursor-pointer flex flex-col justify-between border p-4 rounded-none hover:border-primary-base';
  return (
    <div
      className={cx(
        baseCss,
        currentFilter === segmentId ? 'border-primary-base bg-primary-50' : 'border-gray-100 bg-white',
        hasAllCustomers ? 'col-span-3' : 'md:col-span-1 col-span-3',
        upgradable && 'cursor-default'
      )}
      onClick={() => {
        !upgradable && handleFilterChange(segmentId);
      }}
    >
      <div>
        <div className="flex items-center justify-between">
          <p className="text-base font-semibold whitespace-nowrap">{segmentName}</p>
          <div className={cx('flex items-center justify-center w-9 h-9 rounded-full', segmentCss)}>{segmentIcon}</div>
        </div>
        {upgradable && (
          <div className="mt-1">
            <Tag
              color="orange"
              inner={{
                text: translate(GET_WITH_GROWTH),
                icon: <UpgradeRocketIcon />,
              }}
              size="small"
            />
          </div>
        )}
        <p className="font-light text-sm text-zyda-grey-70 mt-2">
          {hasAllCustomers ? translate(SEGMENT_TITLE_ALL_CUSTOMERS_DESC) : segmentText}
        </p>
      </div>
      <div className="flex items-center pt-2 mt-2 border-t border-gray-100">
        <div
          className={cx(
            'flex items-center',
            !hasAllCustomers && 'border-gray-100',
            !hasAllCustomers ? (lang === 'en' ? 'border-r pr-4 mr-4' : 'border-l pl-4 ml-4') : '',
          )}
        >
          <p className="text-base font-semibold whitespace-nowrap">
            {lang === 'en' ? numberOfUsersBelongingToSegment : ArabicNumbers(numberOfUsersBelongingToSegment)}
          </p>
          <Text
            className={cx('font-light text-sm text-zyda-grey-70', lang === 'en' ? 'ml-1' : 'mr-1')}
            value={CUSTOMERS}
          />
          {hasAllCustomers && (
            <Text
              className={cx(
                'text-xs py-1 px-3 text-green-600 rounded-full bg-green-50 min-w-max',
                lang === 'en' ? 'ml-2' : 'mr-2',
              )}
              value={RECOMMENDED}
            />
          )}
        </div>
        {!hasAllCustomers && (
          <div className="flex items-center">
            <span className={cx('font-light text-sm', lang === 'en' ? 'mr-1' : 'ml-1')}>
              {lang === 'en'
                ? percentageOfUsersInSegment
                : percentageOfUsersInSegment
                ? ArabicNumbers(percentageOfUsersInSegment)
                : ArabicNumbers(0)}
              %
            </span>
            <Text className="font-light text-cs text-zyda-grey-70" value={OF_TOTAL} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Segment;
