import moment from 'moment';
import momentTz from 'moment-timezone';

export const formatDate = (date, time) => {
  const convertTime = moment(time, ['h:mm A']).format('HH:mm');
  return moment(`${date} ${convertTime}`, 'YYYY-MM-DD HH:mm').format();
};

export const utcFormatTime = (time, timeZone) => {
  const convertTime = moment(time, 'h:mm A').format('HH:mm');
  const utcDate = momentTz.tz(convertTime, 'HH:mm', timeZone).toISOString();

  return moment.utc(utcDate).format('HH:mm');
};
