import { STH_WENT_WRONG } from 'constants/constant-errors';

const handleSubmissionErrors = err => {
  const { body } = err.graphQLErrors[0].extensions.exception;

  if (body.phone_number) {
    return 'Phone number has invalid format';
  }

  if (body.force_scheduled_threshold) {
    return body.force_scheduled_threshold;
  }

  if (body.branch && body.branch instanceof Array) {
    return body.branch.join('. ');
  }

  return STH_WENT_WRONG;
};

export default handleSubmissionErrors;
