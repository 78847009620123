import React from 'react';
import { Text } from 'components/service';
import { Button, Stack } from 'components/kit';
import * as translations from 'constants/translations';

const DeactivateProgramConfirmation = ({ descriptionText, actionButtonText, onConfirm, onClose }) => (
  <div className="px-4">
    <div className="mb-6 flex row flex-wrap">
      <Text className="inline-flex w-auto whitespace-pre-wrap" value={descriptionText} />
    </div>
    <Stack direction="row-reverse" expandItem={false} spacing={2}>
      <Button onClick={onConfirm} type="button" kind="secondaryError">
        <Text value={actionButtonText} />
      </Button>
      <Button onClick={onClose} type="button" kind="tertiary">
        <Text value={translations.DISMISS} />
      </Button>
    </Stack>
  </div>
);

export default DeactivateProgramConfirmation;
