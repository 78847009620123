import React, { useState, useRef } from 'react';
import View from './View';
import useClickOutside from 'hooks/useClickOutside';
import cx from 'classnames';

export default props => {
  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef();

  useClickOutside(ref, () => setIsOpen(false));

  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  const onSelect = option => {
    if (!props.noCloseOnSelect) onToggle(); // Close dropdown
    if (props.onSelect) props.onSelect(option);
  };

  return (
    <div className={cx(props.width, props.className, props.wFull && 'w-full')} ref={ref} onClick={e => e.stopPropagation()}>
      <View
        {...props}
        onToggle={onToggle}
        onSelect={onSelect}
        isOpen={isOpen}
        scrollable={props.scrollable}
        optionSelected={props.optionSelected}
        data={props.data}
      />
    </div>
  );
};
