import { gql } from 'graphql-request';

const createBulkDeliveryZoneMutation = gql`
  mutation($input: CreateBulkDeliveryZonesInput!) {
    createBulkDeliveryZones(input: $input) {
      deliveryZone {
        id
        zoneName
        zoneTemplateName
      }
      errors {
        zoneTemplateName
        errorMessage
      }
    }
  }
`;

export default createBulkDeliveryZoneMutation;
