import React, { useState, useContext, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/ar';
import PropTypes from 'prop-types';
import { ClockIcon } from '@heroicons/react/outline';
import momentPropTypes from 'react-moment-proptypes';

import { context as localeContext } from 'context/locale';
import { Text } from 'components/service';
import * as translations from 'constants/translations';

moment.locale('en-gb');

const OrderFrameBusyStatus = ({ busyTime, busyMode, allBranches, numberOfBusyBranches, selectedBranch, isMobile }) => {
  const { lang } = useContext(localeContext);
  const [branchFormattedTime, setBranchFormattedTime] = useState(null);

  useEffect(() => {
    const busyTimeFormatted =
      !!busyTime &&
      (Array.isArray(busyTime)
        ? [...busyTime]
        : {
            ...busyTime,
          });
    if (busyTimeFormatted) {
      setBranchFormattedTime(
        Array.isArray(busyTimeFormatted)
          ? busyTimeFormatted.map(item => item.locale(lang === 'ar' ? 'ar' : 'en-gb').format('ddd DD, MMM YYYY h:mm A'))
          : moment(busyTimeFormatted)
              .locale(lang === 'ar' ? 'ar' : 'en-gb')
              .format('ddd DD, MMM YYYY h:mm A'),
      );
    }
  }, [busyTime, lang]);

  return !!busyTime && allBranches !== 'all' ? (
    Array.isArray(busyTime) && branchFormattedTime ? (
      <div className="flex border border-zyda-grey-30 p-2 text-red-600">
        <ClockIcon className="w-5 h-5" />
        <Text prefix={busyMode} value={translations.BUSY_FROM_TO} className="px-1" payload={branchFormattedTime} />
      </div>
    ) : (
      <div className="flex border border-zyda-grey-30 p-2 text-red-600">
        <ClockIcon className="w-5 h-5" />
        <Text prefix={busyMode} value={translations.BUSY_UNTIL(branchFormattedTime)} className="px-1" />
      </div>
    )
  ) : (
    !selectedBranch && !isMobile && numberOfBusyBranches > 0 && (
      <div className="flex border border-zyda-grey-30 p-2 text-red-600">
        <ClockIcon className="w-5 h-5" />
        <Text
          value={numberOfBusyBranches === 1 ? translations.BRANCH_BUSY : translations.BRANCHES_BUSY}
          payload={numberOfBusyBranches}
          className="px-1"
        />
      </div>
    )
  );
};

OrderFrameBusyStatus.propTypes = {
  busyTime: momentPropTypes.momentObj,
  allBranches: PropTypes.string,
  numberOfBusyBranches: PropTypes.number,
  busyMode: PropTypes.string,
  selectedBranch: PropTypes.shape({
    busy: PropTypes.bool,
    busyFrom: PropTypes.string,
    busyMode: PropTypes.string,
    busyUntil: PropTypes.string,
    deliveryOrdersEnabled: PropTypes.bool,
    externalId: PropTypes.string,
    id: PropTypes.string,
    lat: PropTypes.number,
    lng: PropTypes.number,
    pickupOrdersEnabled: PropTypes.bool,
    titleAr: PropTypes.string.isRequired,
    titleEn: PropTypes.string.isRequired,
  }),
  isMobile: PropTypes.bool,
};

export default OrderFrameBusyStatus;
