import React, { useContext, useState, useRef, useEffect } from "react"
import { Formik, Form } from "formik"
import styled from "styled-components"
import cx from "classnames"
import { context as notificationsContext } from "context/notifications"
import { context as localeContext } from "context/locale"
import { context as modal } from "context/modal"
import { Button, Stack, Box } from "components/kit"
import { useSelectedStore } from "hooks"
import { Field, Text } from "components/service"
import { importStock, exportStockUrl } from "rest.js"
import { Label, Container, Row, Footer } from "components/form/generic"
import { Input, UploadFile } from "components/form/elements"
import * as translations from "constants/translations"

export default ({ onCancel }) => {
  const { setDivRef } = useContext(modal)
  const notifications = useContext(notificationsContext)
  const { lang } = useContext(localeContext)
  const storeId = useSelectedStore()
  const [loading, setLoading] = useState(false)
  const ref = useRef()
  useEffect(() => {
    setDivRef(ref)
  }, [])

  const onSubmit = async values => {
    try {
      setLoading(true)
      await importStock(storeId, values.file)
      notifications.show(<Text value={translations.PRODUCT_IMPORTED_SUCCESS} />)
      setLoading(false)
      onCancel()
    } catch (err) {
      if (err.response && err.response.data && !err.response.data.success) {
        setLoading(false)
        notifications.show(err.response.data.message, "error")
      }
    }
  }
  return (
    <Formik onSubmit={onSubmit}>
      {({ isSubmitting }) => (
        <Form>
          <div ref={ref} className="px-6">
            <Container>
              <div
                lang={lang}
                className={cx(
                  "flex flex-row justify-start px-2 text-left",
                  lang === "ar" && "flex-row-reverse text-right"
                )}
              >
                <div className="relative mx-4">
                  <div className="rounded-full w-5 h-5 inline-block text-center bg-zyda-grey-500 text-white text-sm">
                    1
                  </div>
                  <Line></Line>
                </div>
                <Row>
                  <div>
                    <Text
                      value={translations.FILL_TEMPLATE}
                      className="font-semibold"
                    />
                    <Text
                      className="mt-2 mb-8"
                      value={translations.DESC_FILL_TEMPLATE}
                    />

                    <Button
                      data-testid="download-template-import-stock-btn"
                      isLink={true}
                      kind="tertiary"
                      onClick={() =>
                        (window.location.href = exportStockUrl(storeId, true))
                      }
                    >
                      <Text
                        value={translations.DOWNLOAD_TEMPLATE}
                        className="font-normal text-primary-base cursor-default"
                      />
                    </Button>
                  </div>
                </Row>
              </div>
              <div
                lang={lang}
                className={cx(
                  "flex flex-row justify-start px-2 text-left",
                  lang === "ar" && "flex-row-reverse text-right"
                )}
              >
                <div className="relative mx-4">
                  <div className="rounded-full w-5 h-5 inline-block text-center bg-zyda-grey-500 text-white text-sm">
                    2
                  </div>
                </div>
                <Row>
                  <div>
                    <Text
                      value={translations.IMPORT_STOCK}
                      className="font-semibold"
                    />
                    <Text
                      value={translations.IMPORT_STOCK_TEMPLATE_DESC}
                      className="mt-2 mb-8"
                    />
                    <Field
                      data-testid="upload-import-stock"
                      name="file"
                      info={<Text value={translations.CLICK_DROP_FILE} />}
                      component={UploadFile}
                      small="small"
                      direction="row"
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    />
                  </div>
                </Row>
              </div>
            </Container>
          </div>
          <Footer modalFooter>
            <div className={cx(lang === "ar" ? "mr-auto" : "ml-auto")}>
              <Stack direction="row">
                <Button
                  data-testid="cancel-import-stock-btn"
                  onClick={onCancel}
                  type="button"
                  kind="tertiary"
                >
                  <Text value={translations.CANCEL} />
                </Button>
                <Button
                  data-testid="add-import-stock-btn"
                  kind="primary"
                  isSpinning={isSubmitting && loading}
                  disabled={isSubmitting && loading}
                >
                  <Text value={translations.ADD} />
                </Button>
              </Stack>
            </div>
          </Footer>
        </Form>
      )}
    </Formik>
  )
}

const Line = styled.div`
  top: 22px;
  left: 9px;
  height: 105%;
  position: absolute;
  border-left: 3px solid #eaebf1;
`
