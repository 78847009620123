import { gql } from '@apollo/client';

export default gql`
  mutation linkInternalBranchToPosBranch(
    $storeId: String
    $branchInternalId: String
    $branchExternalId: String
    $partnerName: String
  ) {
    linkInternalBranchToPosBranch(
      storeId: $storeId
      branchInternalId: $branchInternalId
      branchExternalId: $branchExternalId
      partnerName: $partnerName
    ) {
      branchInternalId
      branchExternalId
      partnerName
    }
  }
`;
