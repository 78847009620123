import React, { useState, useEffect, useRef } from 'react';

import { Text } from 'components/service';

const Stepper = ({ steps, currentStepNumber }) => {
  const [stepperSteps, setStep] = useState([]);
  const stepsStateRef = useRef();

  useEffect(() => {
    const stepsState = steps.map((step, index) => {
      return {
        title: step.props.title,
        html: step,
        completed: false,
        highlighted: index === 0 ? true : false,
        selected: index === 0 ? true : false,
      };
    });

    stepsStateRef.current = stepsState;

    const currentSteps = updateStep(currentStepNumber - 1, stepsState);
    setStep(currentSteps);
  }, []);

  useEffect(() => {
    const currentSteps = updateStep(currentStepNumber - 1, stepsStateRef.current);
    setStep(currentSteps);
  }, [currentStepNumber]);

  function updateStep(stepNumber, steps) {
    const newSteps = [...steps];

    let stepCounter = 0;
    while (stepCounter < newSteps.length) {
      //current step
      if (stepCounter === stepNumber) {
        newSteps[stepCounter] = {
          ...newSteps[stepCounter],
          highlighted: true,
          selected: true,
          completed: false,
        };
        stepCounter++;
      }
      // Past step
      else if (stepCounter < stepNumber) {
        newSteps[stepCounter] = {
          ...newSteps[stepCounter],
          highlighted: false,
          selected: true,
          completed: true,
        };
        stepCounter++;
      }
      // Future steps
      else {
        newSteps[stepCounter] = {
          ...newSteps[stepCounter],
          highlighted: false,
          selected: false,
          completed: false,
        };
        stepCounter++;
      }
    }
    return newSteps;
  }

  const stepsDisplay = stepperSteps.map((step, index) => {
    return (
      <div key={index} className={`flex items-start w-1/${stepperSteps.length}`}>
        <div className="items-center text-teal-600 w-full p-1">
          <div
            className={`text-center pt-2 text-xs font-medium uppercase ${
              step.highlighted ? 'text-gray-900' : 'text-gray-400'
            } border-t-4 ${step.selected ? 'border-primary-base' : 'border-zyda-grey-300'}`}
          >
            <Text value={step.title} />
          </div>
        </div>
      </div>
    );
  });

  let currentStep = stepperSteps.find(step => step.highlighted);

  return (
    <>
      <div className="mx-4 p-1 flex justify-between items-start">{stepsDisplay}</div>
      <div className="mt-4 mx-5">{currentStep?.html}</div>
    </>
  );
};

export default Stepper;
