import React from 'react';
import PropTypes from 'prop-types';

import { Text } from 'components/service';
import { Button } from 'components/kit';
import { Row } from 'components/form/generic';
import * as translations from 'constants/translations';

const DeleteZone = ({ close, handleRemoveZone }) => (
  <>
    <div className="flex flex-col items-center">
      <div className="px-4 mb-4">
        <Text value={translations.ARE_YOU_SURE_TO_DELETE_ZONE} />
      </div>
      <Row>
        <Button onClick={close} type="button" kind="tertiary">
          <Text value={translations.DISMISS} />
        </Button>
        <Button
          onClick={() => {
            close();
            handleRemoveZone();
          }}
          kind="secondaryError"
        >
          <Text value={translations.DELETE} />
        </Button>
      </Row>
    </div>
  </>
);

DeleteZone.propTypes = {
  close: PropTypes.func.isRequired,
  handleRemoveZone: PropTypes.func.isRequired,
};

export default DeleteZone;
