import { gql } from "@apollo/client"

export const PRODUCTS = gql`
  query($storeId: String!) {
    menuItems(restaurantId: $storeId, limit: 10, page: 1) {
      totalCount
    }
  }
`

export const SORT_CATEGORIES = gql`
  mutation($storeId: String!, $positions: [MenuSectionPosition!]!) {
    updateCategories(restaurantId: $storeId, positions: $positions)
  }
`
