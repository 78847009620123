import React, { useContext } from 'react';

import { Modal, Button } from 'components/kit';
import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { context as userContext } from 'context/user';
import { context as localeContext } from 'context/locale';

import ImportFromFoodicsModal from '../../../menu/overview/List/ImportFromFoodicsModal';
import ImportFromFoodicsNoBranchesModal from '../../../menu/overview/List/ImportFromFoodicsNoBranchesModal';

const MenuImport = () => {
  const { lang } = useContext(localeContext);
  const { branches, isPosCourier, settings, pos } = useContext(userContext);
  const posName = lang === 'en' ? pos?.courierDetails?.name : pos?.courierDetails?.nameAr;

  return (
    <Modal emptyState>
      {({ open, close }) => (
        <>
          {isPosCourier && pos?.isMenuPull && !settings?.isImportingPosMenu && (
            <Button
              data-testid="import-catalog-btn"
              size="full"
              onClick={() => {
                if (!branches) {
                  open({
                    testId: 'list-products-sort-catalog-import-foodics-no-branches-modal',
                    body: <ImportFromFoodicsNoBranchesModal onCancel={close} />,
                  });
                } else {
                  open({
                    testId: 'list-products-sort-catalog-import-foodics-modal',
                    title: <Text value={translations.IMPORT_CATALOG_FROM_POS(posName)} />,
                    body: <ImportFromFoodicsModal onCancel={close} />,
                  });
                }
              }}
              kind="primary"
            >
              <Text value={translations.IMPORT_CATALOG_FROM_POS(posName)} />
            </Button>
          )}
        </>
      )}
    </Modal>
  );
};

export default MenuImport;
