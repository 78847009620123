import React, { useContext, useRef } from "react"
import cx from "classnames"

import { Text } from "components/service"
import { DropDown } from "components/kit"
import * as translations from "constants/translations"
import { Input } from "components/form/elements"
import { context as localeContext } from "context/locale"
import { ReactComponent as SearchIcon } from "assets/search.svg"
import { itemTrackingStatusData, trackingData } from "../../kits/dropDownData"

const MobileSearchBar = ({
  query,
  settingQuery,
  settingTotalStocks,
  categoriesData,
  branchesData,
  dataStocksLength,
}) => {
  const { lang, direction } = useContext(localeContext)
  const searchTimeout = useRef()
  const statusData = itemTrackingStatusData()
  const variantTrackingData = trackingData()

  return (
    <>
      <div className="w-full">
        <Input
          isMenu
          isStock
          data-testid="stockManagement-search-stock"
          type="text"
          icon={<SearchIcon />}
          search
          placeholder={
            lang === "en"
              ? translations.SEARCH_VARIANTS(dataStocksLength)[0]
              : translations.SEARCH_VARIANTS(dataStocksLength)[1]
          }
          initialValue={query.title}
          onChange={e => {
            clearTimeout(searchTimeout.current)
            const { value } = e.target
            searchTimeout.current = setTimeout(() => {
              settingTotalStocks([])
              settingQuery({ page: 1, title: value })
            }, 500)
          }}
        />
      </div>
      <div className="flex flex-col">
        <div className="flex items-center">
          <div className="w-1/2 mt-2 mr-2 ml-0 mb-0">
            <DropDown
              testId_openDropDown="stockManagement-locs-open"
              testId_selectedData="stockManagement-locs-data"
              testId_listData="stockManagement-list-locs"
              float={"left"}
              label={<Text value={translations.BRANCH} />}
              optionSelected={query.branchId}
              onSelect={option => {
                settingTotalStocks([])
                settingQuery({ page: 1, branchId: option })
              }}
              data={branchesData}
              icon="keyboard_arrow_down"
              position={lang === "ar" ? "right" : "left"}
              scrollable
              wFull={true}
              responsiveLabel
              labelClassNames="text-gray-700"
            />
          </div>
          <div className="w-1/2 md:w-auto md:ml-0 mt-2 mb-0 mx-0">
            <DropDown
              testId_openDropDown="stockManagement-categs-open"
              testId_selectedData="stockManagement-categs-data"
              testId_listData="stockManagement-list-categs"
              label={<Text value={translations.CATEGORY} />}
              data={categoriesData}
              optionSelected={query.categoryId}
              onSelect={categoryId => {
                settingTotalStocks([])
                settingQuery({ page: 1, categoryId })
              }}
              icon="keyboard_arrow_down"
              position={direction === "rtl" ? "right" : "left"}
              scrollable
              labelClassNames="text-gray-700"
              responsiveLabel
            />
          </div>
        </div>
        <div className="flex items-center">
          <div className="w-1/2 mt-2 mr-2 ml-0 mb-0">
            <DropDown
              testId_openDropDown="stockManagement-open-statusList"
              testId_selectedData="stockManagement-status-data"
              testId_listData="stockManagement-list-status"
              label={<Text value={translations.TRACKING_STOCK} />}
              data={variantTrackingData}
              icon="keyboard_arrow_down"
              position={direction === "rtl" ? "right" : "left"}
              optionSelected={query.trackingType}
              onSelect={option => {
                settingTotalStocks([])
                settingQuery({ page: 1, trackingType: option })
              }}
              labelClassNames="text-gray-700"
            />
          </div>
          <div className={cx("w-1/2 md:w-auto md:ml-0 mt-2 mb-0 mx-0")}>
            <DropDown
              testId_openDropDown="stockManagement-open-statusList"
              testId_selectedData="stockManagement-status-data"
              testId_listData="stockManagement-list-status"
              label={<Text value={translations.STATUS_STOCK} />}
              data={statusData}
              icon="keyboard_arrow_down"
              position={direction === "rtl" ? "right" : "left"}
              optionSelected={query.status}
              onSelect={option => {
                settingTotalStocks([])
                settingQuery({ page: 1, status: option })
              }}
              labelClassNames="text-gray-700"
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default MobileSearchBar
