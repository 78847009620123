import React, { useState, useContext } from "react"
import { context as localeContext } from "context/locale"
import { Text } from "components/service"
import { Error } from "components/form/generic"
import * as translations from "constants/translations"
import cx from "classnames"

export default ({
  data,
  name,
  value,
  error,
  onChange,
  testId,
  placeholder = "Search ...",
}) => {
  const { lang, direction } = useContext(localeContext)
  const [activeData, setActiveData] = useState(0)
  const [filteredData, setFilteredData] = useState([])
  const [showData, setShowData] = useState(false)
  const [userInput, setUserInput] = useState("")

  const onClick = (e, valueId) => {
    setActiveData(0)
    setFilteredData([])
    setShowData(false)
    setUserInput(e.currentTarget.innerText)
    onChange({
      target: {
        name,
        value: valueId,
      },
    })
  }

  const onKeyDown = e => {
    if (e.keyCode === 13) {
      setActiveData(0)
      // setShowData(true)
      setUserInput(filteredData[activeData].title)
      setShowData(false)
      onChange({
        target: {
          name,
          value: filteredData[activeData].id,
        },
      })
    } else if (e.keyCode === 38) {
      if (activeData === 0) {
        return
      }
      setActiveData(activeData - 1)
    } else if (e.keyCode === 40) {
      if (activeData - 1 === filteredData.length) {
        return
      }
      setActiveData(activeData + 1)
    }
  }

  const onMouseEnter = e => {
    e.persist()
    const currentIndex = parseInt(e.target.dataset.index, 10)
    setActiveData(currentIndex)
  }

  let DataListComponent
  if (showData && userInput) {
    if (filteredData.length) {
      DataListComponent = (
        <ul
          className={cx(
            "absolute bg-white mt-2 border rounded p-1 overflow-auto z-50 w-full",
            filteredData.length > 5 && "h-40"
          )}
        >
          {filteredData.map((value, index) => {
            return (
              <li
                className={cx(
                  "px-2 py-1 block text-zyda-black-100 rounded cursor-pointer whitespace-nowrap",
                  activeData === index && "bg-primary-50"
                )}
                key={index}
                onClick={e => onClick(e, value.id)}
                onMouseEnter={onMouseEnter}
                data-index={index}
              >
                {value.title}
              </li>
            )
          })}
        </ul>
      )
    } else {
      DataListComponent = (
        <ul
          className={cx(
            "absolute bg-white mt-2 border rounded p-1 overflow-auto z-50 w-full"
          )}
        >
          <li>
            <Text className="italic" value={translations.NO_OPTION_GROUP} />
          </li>
        </ul>
      )
    }
  }

  return (
    <>
      <div className="relative group w-full">
        <input
          className={cx(
            "focus:outline-none focus:border-primary-base w-full border rounded px-2 py-1 h-10"
          )}
          type="text"
          onChange={e => {
            const userInput = e.currentTarget.value
            const filteredData = data.filter(
              data =>
                data.title.toLowerCase().indexOf(userInput.toLowerCase()) > -1
            )
            setActiveData(0)
            setFilteredData(filteredData)
            setShowData(true)
            setUserInput(e.currentTarget.value)
          }}
          onKeyDown={onKeyDown}
          value={userInput}
          placeholder={placeholder}
          data-testid={testId}
        />
        {DataListComponent}
      </div>
      {error && <Error direction={direction}>{error}</Error>}
    </>
  )
}
