import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Text } from 'components/service';
import { RadioList } from 'components/form/elements';
import * as translations from 'constants/translations';
import { refundRadioListItems } from './data';

const RefundOptions = ({
  lang,
  orderfastWalletRefundData,
  setOrderfastWalletRefundData,
  orderTotalWithCurrecy,
  orderPaidThrough,
  fromModal = false,
  orderTotal,
  currency,
}) => {
  const handleSettingCompensationAmount = compensationAmount => {
    setOrderfastWalletRefundData(prevState => ({
      ...prevState,
      compensationAmount,
    }));
  };
  const radioListItems = refundRadioListItems(
    orderPaidThrough,
    lang,
    handleSettingCompensationAmount,
    currency,
    orderTotal,
    orderfastWalletRefundData,
  );
  return (
    <RadioList
      refund
      groupTitle={
        <Text
          value={fromModal ? translations.REFUND_TO : translations.REFUND_AMOUNT_TITLE(orderTotalWithCurrecy)}
          payload={orderTotalWithCurrecy}
          className={cx('text-sm font-medium', !fromModal && 'border-t border-gray-400 py-4')}
        />
      }
      items={radioListItems}
      value={orderfastWalletRefundData.refundType}
      onChange={option => {
        setOrderfastWalletRefundData(prevState => ({
          ...prevState,
          refundType: option.target.value,
        }));
      }}
      elementStyle={{
        direction: lang === 'ar' ? 'rtl' : 'unset',
      }}
      containerStyle={{
        marginBottom: '1.25rem',
        minWidth: '18rem',
      }}
      wFull
    />
  );
};

RefundOptions.propTypes = {
  lang: PropTypes.oneOf(['en', 'ar']).isRequired,
  orderfastWalletRefundData: PropTypes.shape({
    refundType: PropTypes.string,
    compensationAmount: PropTypes.number,
  }).isRequired,
  setOrderfastWalletRefundData: PropTypes.func.isRequired,
  orderTotalWithCurrecy: PropTypes.string.isRequired,
  orderPaidThrough: PropTypes.string.isRequired,
  fromModal: PropTypes.bool,
  orderTotal: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
};
export default RefundOptions;
