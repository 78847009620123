export const DELIVERY_TYPE_CHART_COLORS = ['#3852ca', '#a0b1fa'];
export const PAYMENTS_METHOD_CHART_COLORS = [
  '#45985e',
  '#3852ca',
  '#7d4598',
  '#f57f17',
  '#fff288',
  '#f95b45',
  '#f95b45',
  '#f95b45',
  '#f95b45',
];
export const NEW_VS_RETURNING_CHART_COLORS = ['#1d43ff', '#7990ff'];
