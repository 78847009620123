import React, { useContext } from "react"
import cx from "classnames"
import { useQuery } from "@apollo/client"
import {
  useSelectedStore,
  useReplaceParams,
  useMobile,
  useQueryState,
} from "hooks/index"
import { Text } from "components/service"
import * as translations from "constants/translations"
import { breadcrumbs } from "constants/translations"
import { Layout, Breadcrumbs } from "components/common/dashboard"
import { context as localeContext } from "context/locale"
import { Link } from "components/service"
import { DeleteUser } from "components/common/team"
import {
  Button,
  More,
  Pagination,
  Modal,
  Spinner,
  DropDown,
  LoadingContainer,
} from "components/kit"
import { ReactComponent as AddIcon } from "assets/add.svg"
import * as paths from "paths.js"
import MobileList from "./MobileList"
import * as schemas from "./schemas"
import { transformUser, roles } from "components/common/team/utils"
import { ROLES_TYPES } from "../utils"
import { TEAM_LINKS } from "constants/helperLinks"

export default () => {
  const selectedStoreId = useSelectedStore();
  const cellClassName = 'px-4 py-3 border-b border-gray-300';
  const { direction } = useContext(localeContext);
  const replace = useReplaceParams();
  const head = [
    translations.FULL_NAME,
    translations.EMAIL,
    translations.PHONE_NUMBER,
    translations.ROLE,
    translations.ACTIONS,
  ];
  const isMobile = useMobile();
  const [query, setQuery] = useQueryState(
    {
      page: 1,
      role: 'all',
    },
    {
      page: parseInt,
    },
  );

  const LIMIT = 10;

  const usersVariables = {
    storeId: selectedStoreId,
    role: query.role !== 'all' ? query.role : undefined,
    page: query.page,
    limit: LIMIT,
  };

  const users = useQuery(schemas.USERS, {
    fetchPolicy: 'network-only',
    variables: usersVariables,
  });

  const pagination = users.data?.users.total > LIMIT && (
    <div className="mt-4">
      <Pagination
        totalRecords={users.data.users.total}
        pageLimit={LIMIT}
        currentPage={query.page}
        onPageChanged={({ currentPage }) => setQuery({ page: currentPage })}
      />
    </div>
  );
  return (
    <Layout
      top={
        <Breadcrumbs
          helperLinks={TEAM_LINKS}
          path={breadcrumbs.TEAM}
          right={
            <Link to={paths.createUser}>
              <Button kind="primary">
                <AddIcon className={cx(direction === 'ltr' ? 'mr-2' : 'ml-2')} />
                <Text value={translations.ADD_NEW_USER} />
              </Button>
            </Link>
          }
        />
      }
    >
      <div style={{ direction }}>
        <div className={isMobile ? 'pb-6' : 'pb-6'}>
          <div className="flex mb-4">
            <DropDown
              label={<Text value={translations.ROLE} />}
              optionSelected={query.role}
              onSelect={role => setQuery({ role, page: 1 })}
              data={roles}
              icon="keyboard_arrow_down"
              position={direction === 'rtl' ? 'right' : 'left'}
              scrollable
            />
          </div>
          {!users.data ? (
            <Spinner />
          ) : !users.data.users.users.length ? (
            'No users'
          ) : isMobile ? (
            <>
              <LoadingContainer isLoading={users.loading}>
                <MobileList usersVariables={usersVariables} items={users.data.users.users.map(transformUser)} />
              </LoadingContainer>
              {pagination}
            </>
          ) : (
            <>
              <LoadingContainer isLoading={users.loading}>
                <table className="w-full border border-gray-300">
                  <thead>
                    <tr className="font-semibold bg-gray-200">
                      {head.map((item, i) => (
                        <td className="p-4" key={i}>
                          <Text value={item} />
                        </td>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {users.data.users.users.map(transformUser).map((item, i) => (
                      <tr key={i}>
                        <td className={cellClassName}>{item.name}</td>
                        <td className={cellClassName}>{item.email}</td>
                        <td className={cellClassName}>{item.phoneNumber && '+' + item.phoneNumber}</td>
                        <td className={cellClassName + ' capitalize'}>
                          {item.role === ROLES_TYPES.OPS_MANAGER ? ROLES_TYPES.MANAGER : item.role}
                        </td>
                        <td className={cx(cellClassName, 'w-px')}>
                          <div className="flex items-center">
                            <Link
                              to={replace(paths.editUser, {
                                omitQuery: true,
                                params: { userId: item.id },
                              })}
                            >
                              <Button size="sm">
                                <Text value={translations.EDIT} />
                              </Button>
                            </Link>
                            <div className={cx(direction === 'ltr' ? 'ml-2' : 'mr-2')}>
                              <Modal>
                                {({ open, close }) => (
                                  <More
                                    kind="action"
                                    size="smIcon"
                                    items={[
                                      {
                                        title: <Text value={translations.DELETE} />,
                                        onClick: () => {
                                          open({
                                            title: item.name,
                                            body: (
                                              <DeleteUser
                                                userId={item.id}
                                                usersVariables={usersVariables}
                                                onClose={close}
                                              />
                                            ),
                                            size: 'max-w-md',
                                          });
                                        },
                                      },
                                    ]}
                                  />
                                )}
                              </Modal>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </LoadingContainer>
              {pagination}
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};
