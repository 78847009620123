import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'formik';

import * as translations from 'constants/translations';
import { context as userContext } from 'context/user';
import { context as localeContext } from 'context/locale';
import { Label, Row, Container } from 'components/form/generic';
import { Field, Text } from 'components/service';
import { PhoneInput, Input } from 'components/form/elements';
import { Button } from 'components/kit';
import SlideoverTitle from 'components/common/dashboard/Layout/Slideover/SlideoverTitle';
import { CUSTOMER_INFO_ENUMS } from '../utils';

const CreateCustomer = ({ userName, submitForm, isCreatingUser }) => {
  const { selectedStore } = useContext(userContext);
  const { lang, translate } = useContext(localeContext);

  return (
    <div>
      <SlideoverTitle title={<Text value={translations.COMPLETE_INFO} />} />
      <Form className="mt-6 relative flex-1 px-4 sm:px-6" key="manual-order-create-user-form">
        <Container>
          <Row>
            <Label title={<Text value={translations.PHONE_NUMBER_ORDER} />}>
              <Field
                type="text"
                name={CUSTOMER_INFO_ENUMS.PHONE_NUMBER}
                country={selectedStore.countryCode.toLowerCase()}
                placeholder={
                  lang === 'en' ? translations.MOBILE_NUMBER_PLACEHOLDER[0] : translations.MOBILE_NUMBER_PLACEHOLDER[1]
                }
                component={PhoneInput}
                disabled
                testId="phone-number"
              />
            </Label>
          </Row>
          <Row>
            <Label title={<Text value={translations.FULL_NAME} />}>
              <Field
                type="text"
                name={CUSTOMER_INFO_ENUMS.USER_NAME}
                maxLength={50}
                placeholder={translate(translations.ENTER_CUSTOMER_FULL_NAME)}
                component={Input}
              />
            </Label>
          </Row>
          <Row className="py-2">
            <Button kind="primary" disabled={!userName} full isSpinning={isCreatingUser}>
              <Text value={translations.SAVE} onClick={submitForm} />
            </Button>
          </Row>
        </Container>
      </Form>
    </div>
  );
};

CreateCustomer.propTypes = {
  userName: PropTypes.string.isRequired,
  submitForm: PropTypes.func.isRequired,
  isCreatingUser: PropTypes.bool.isRequired,
};

export default CreateCustomer;
