import { STH_WENT_WRONG } from 'constants/constant-errors';

const handleValidateError = err => {
  const extensions = err?.graphQLErrors && err.graphQLErrors[0]?.extensions;
  const message = extensions?.external_response;
  const timeoutMsg = extensions?.exception?.body?.message;

  if (message) {
    return message;
  }
  if (timeoutMsg) {
    return timeoutMsg;
  }

  return STH_WENT_WRONG;
};

export default handleValidateError;
