import gql from "graphql-tag"

export const GET_PAYMENT_METHODS = gql`
  query paymentMethods($restaurantId: Int!) {
    paymentMethods(restaurantId: $restaurantId) {
      paymentMethods
    }
  }
`
export const GET_PAYMENT_TRANSACTIONS = gql`
  query paymentTransactions(
    $storeId: String!
    $paymentMethod: [OrderPaymentMethod]
    $deliveryType: [OrderDeliveryType]
    $statuses: [Status]
    $branchId: String!
    $page: Int
    $limit: Int
    $orderNumber: String
    $reference: String
    $sort: PaymentTransactionSorter
    $createdAt: [String]
  ) {
    paymentTransactions(
      restaurantId: $storeId
      page: $page
      limit: $limit
      filters: {
        branch_id: $branchId
        created_at: $createdAt
        order_number: $orderNumber
        reference: $reference
        statuses: $statuses
        order_paid_through: $paymentMethod
        order_delivery_type: $deliveryType
      }
      sorter: $sort
    ) {
      paymentTransactions {
        id
        status
        chargeId
        amount
        orderNumber
        paymentMethod
        paymentDate
        branchNameEn
        branchNameAr
        reference
        currency {
          titleEn
          titleAr
          decimals
        }
      }
      totalCount
    }
  }
`
export const GET_PAYMENT_CREDENTIALS = gql`
  query($restaurantId: String!) {
    paymentCredentials(restaurantId: $restaurantId) {
      id
      totalAwaitingBalance
      totalNumberOfTransactions
      totalValueOfTransactions
      totalSupplierInvoiceShare
      totalDepositedAmount
      paymentGateway {
        depositDashboard
      }
    }
  }
`
