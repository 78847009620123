import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import * as translations from 'constants/translations';
import { context as localeContext } from 'context/locale';
import { context as notificationsContext } from 'context/notifications';
import { Text } from 'components/service';
import { Button } from 'components/kit';
import { useDeleteBulkDeliveryZone } from 'service/hooks';
import { failedBulkZones } from '../../utils';

const DeleteBulkZonesModal = ({ bulkZones, setBulkZones, setZones, close }) => {
  const notifications = useContext(notificationsContext);
  const { translate } = useContext(localeContext);
  const [pending, setPending] = useState(false);
  const deleteBulkDeliveryZones = useDeleteBulkDeliveryZone();

  const handleActivateBulk = async () => {
    setPending(true);

    const response = await deleteBulkDeliveryZones({
      deliveryZoneIds: bulkZones.map(zoneItem => zoneItem.properties.id),
    });

    if (!response.hasError) {
      setPending(false);
      if (response.deleteBulkDeliveryZones) {
        const { deliveryZones, errors } = response.deleteBulkDeliveryZones;

        // - All zones failed
        if (deliveryZones.length === 0 && errors.length >= 1) {
          notifications.show(<p>{errors[0].errorMessage}</p>, 'error');
          close();
        }

        // - Some zones success and some failed
        if (deliveryZones.length >= 1 && errors.length >= 1) {
          const failedZones = failedBulkZones(bulkZones, errors);
          notifications.show(
            <div className="flex flex-wrap">
              <p>{translate(translations.BULK_DELETE_ERROR_MESSAGE)} &nbsp;</p>
              <ul className="list-none flex flex-wrap">
                {failedZones.map(failedZoneItem => (
                  <li className="px-1" key={failedZoneItem.properties.id}>
                    {failedZoneItem.properties.zone_name},
                  </li>
                ))}
              </ul>
            </div>,
            'error',
          );
          setZones(prevState =>
            prevState.filter(zoneItem => !deliveryZones.includes(zoneItem.properties.id.toString())),
          );
          close();
          setBulkZones([]);
        }

        // - All zones success
        if (deliveryZones.length >= 1 && errors.length === 0) {
          notifications.show(<Text value={translations.BULK_DELETE_SUCCESS_MSG(deliveryZones.length)} />);
          setZones(prevState =>
            prevState.filter(zoneItem => !deliveryZones.includes(zoneItem.properties.id.toString())),
          );
          close();
          setBulkZones([]);
        }
      }
    } else {
      setPending(false);
      close();
      notifications.show(<Text value={translations.SOMETHING_WENT_WRONG} />, 'error');
    }
  };

  return (
    <div className="flex items-center justify-end">
      <Button onClick={close} type="button" kind="tertiary" className="mx-4">
        <Text value={translations.DISMISS} />
      </Button>
      <Button onClick={handleActivateBulk} kind="secondaryError" disabled={pending}>
        <Text value={translations.CONFIRM} />
      </Button>
    </div>
  );
};

DeleteBulkZonesModal.propTypes = {
  bulkZones: PropTypes.arrayOf(
    PropTypes.shape({
      properties: PropTypes.shape({
        id: PropTypes.number,
      }),
    }),
  ),
  setZones: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  setBulkZones: PropTypes.func.isRequired,
};

export default DeleteBulkZonesModal;
