import { gql } from "@apollo/client"

export const DELETE_DELIVERY_ZONE = gql`
  mutation($id: String!, $restaurantId: String!, $branchId: String!) {
    deleteDeliveryZone(
      id: $id
      restaurantId: $restaurantId
      branchId: $branchId
    ) {
      id
    }
  }
`
