import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import * as translations from 'constants/translations';
import { Text, Link } from 'components/service';
import { Button } from 'components/kit';
import * as paths from 'paths.js';
import { replaceParams } from 'utils/index';
import { withBackParam } from 'utils/history';
import { context as localeContext } from 'context/locale';
import PaymentStatusSign from '../Root/PaymentStatusSign';

const PaymentStatus = ({ paymentCredentials, storeId }) => {
  const { lang } = useContext(localeContext);
  const integrationFromGateway = paymentCredentials?.paymentGateway?.integrationFromGateway;

  return (
    <div
      className={cx('border rounded text-xs p-4 flex tems-center justify-between', lang === 'ar' && 'flex-row-reverse')}
    >
      <div className={!integrationFromGateway && 'flex-col i'}>
        <div className="mb-2">
          <Text className="text-gray-600" value={translations.MERCHANT_ID} />
          {paymentCredentials.merchantId}
        </div>
        <div data-testid="payMethods-status-container">
          <Text testId="paymentMethods-status" value={translations.STATUS_ORDER} className="text-gray-600" />
          <PaymentStatusSign status={paymentCredentials.status} />
        </div>
      </div>

      {!integrationFromGateway && (
        <>
          <div className="flex-col">
            <div className="mb-2">
              <Text className="text-gray-600" value={translations.PROFILE_NAME} />
              {paymentCredentials.profileName}
            </div>

            <div>
              <Text value={translations.IBAN} className="text-gray-600" />
              {paymentCredentials.bankAccountInfo.iban}
            </div>
          </div>
        </>
      )}
      <div className="flex-col ">
        <div className="mb-2">
          <Link
            omitQuery
            to={`${replaceParams(paths.onlinePaymentEditionForm, {
              storeId,
              paymentId: paymentCredentials.id,
            })}?${withBackParam()}`}
            className="text-xs text-primary-base"
          >
            <Button kind="tertiary" data-testid="paymentMethods-online-edit">
              <Text value={translations.EDIT} className="font-normal" />
            </Button>
          </Link>
        </div>
        {paymentCredentials.remarks && (
          <div>
            <Text value={translations.REASON} className="text-gray-600" />
            {paymentCredentials.remarks}
          </div>
        )}
        <div />
      </div>
    </div>
  );
};

PaymentStatus.propTypes = {
  paymentCredentials: PropTypes.shape({
    id: PropTypes.string.isRequired,
    merchantId: PropTypes.string.isRequired,
    profileName: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    remarks: PropTypes.string.isRequired,
    bankAccountInfo: PropTypes.shape({
      iban: PropTypes.string.isRequired,
    }).isRequired,
    paymentGateway: PropTypes.shape({
      integrationFromGateway: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
  storeId: PropTypes.string.isRequired,
};

export default PaymentStatus;
