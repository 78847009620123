export const fileControllerUtils = {
  uploadingFile: null,
  uploadingFileLoading: false,
  uploadingFileSize: null,
  uploadingFileExe: null,
  uploadingFileResponse: null,
  uploadingFileName: null,
  isFileValidated: false,
};

export const fileErrorCodes = {
  INVALID_SIZE_LARGE: 'CMP413',
  INVALID_FORMATE: 'CMP422',
  INVALID_HEADER: 'CMP499',
  FILE_HAS_NO_PHONES: 'CMP204',
  INVALID_PHONE_CODE: 'CMP423',
  DUPLICATE_PHONE_CDE: 'CMP409',
  FILE_NAME_EXIST: 'CMP458',
};
export const fileErrorCodesDanger = [
  fileErrorCodes.INVALID_SIZE_LARGE,
  fileErrorCodes.INVALID_FORMATE,
  fileErrorCodes.INVALID_HEADER,
  fileErrorCodes.FILE_HAS_NO_PHONES,
  fileErrorCodes.FILE_NAME_EXIST,
];
