import { gql } from '@apollo/client';

const BEHAVIOR_BREAKDOWN = gql`
  query(
    $storeId: String!
    $date: AnalyticsPrecision!
    $range: AnalyticsRange!
    $breakdown: BreakdownPrecision!
    $metric: Metric!
    $page: Int!
    $limit: Int!
  ) {
    behaviorBreakdown(
      restaurantId: $storeId
      date: $date
      range: $range
      breakdown: $breakdown
      metric: $metric
      page: $page
      limit: $limit
    ) {
      startDate
      endDate
      data {
        salesData
        ordersData
        avgOrderData
        customersData
      }
      totalCount
      totalPages
    }
  }
`;

export default BEHAVIOR_BREAKDOWN;
