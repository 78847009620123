import { gql } from "@apollo/client"

export const SIGN_UP = gql`
  mutation SignUp(
    $name: String!
    $lastName: String!
    $email: String!
    $password: String!
  ) {
    signUp(
      name: $name
      lastName: $lastName
      email: $email
      password: $password
      passwordConfirmation: $password
    ) {
      id
      name
      email
      accessToken
    }
  }
`
