import * as R from "ramda"
import { useState } from "react"
import { createHistory } from "@reach/router"
import { parse, stringify } from "query-string"
export default (initialState, stateMap = {}) => {
  const { search: initialSearch } = document.location
  const [state, setState] = useState({
    ...initialState,
    ...(initialSearch && parseSearch(initialSearch, stateMap)),
  })
  const history = createHistory(window)
  history.listen(({ location }) =>
    setState({
      ...initialState,
      ...parseSearch(location.search, stateMap),
    })
  )
  return [
    state,
    nextState => {
      return history.navigate(
        history.location.origin +
          history.location.pathname +
          `?${stringify(R.mergeDeepRight(state, nextState))}`
      )
    },
  ]
}
const parseSearch = (search, stateMap) => {
  let state = parse(search)
  for (let key of Object.keys(state)) {
    const map = stateMap[key]
    if (map) {
      state[key] = map(state[key])
    }
  }
  return state
}
