import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { parse } from 'query-string';
import camelize from 'camelize';

import { breadcrumbs } from 'constants/translations';
import { useQueryState, usePriceFormat } from 'hooks';
import { context as notificationsContext } from 'context/notifications';
import { Layout, Breadcrumbs } from 'components/common/dashboard';
import { Table, TableFooter } from 'components/form/elements';
import { Text } from 'components/service';
import { Spinner, Tabs, DropDown } from 'components/kit';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { DateRange, Period } from 'components/common/analytics';
import { parseRange, stringifyRange, toRangeVariable, getRange } from 'utils/date';
import DATE_RANGES from 'constants/date';
import * as translations from 'constants/translations';
import * as paths from 'paths';
import NEW_VS_RETURNING_CUSTOMERS_REPORT from './schemas';
import { exportNewVsReturningReport } from '../../../../rest';
import transformNewVsReturningData from './utils';
import { ANALYTICS_TAB_NAMES } from '../../constants';
import momentLanguage from '../../utils';

const NewVsReturning = () => {
  const { lang, direction } = useContext(localeContext);
  const notifications = useContext(notificationsContext);
  const { selectedStore } = useContext(userContext);
  const formatPrice = usePriceFormat();
  const { type_date: typeDate, behavior_date: behaviorDate, behavior_selection: behaviorSelection, range } = parse(
    document.location.search,
  );
  const [transformedData, setTransformedData] = useState([]);
  const resettableStates = { page: 1 };
  const [query, setQuery] = useQueryState(
    {
      type_date: typeDate || DATE_RANGES.THIRTY_DAYS,
      date: behaviorDate || 'day',
      behavior_selection: behaviorSelection || ANALYTICS_TAB_NAMES.totalSales.snakeCaseName,
      ...resettableStates,
      range: range || stringifyRange(getRange(DATE_RANGES.THIRTY_DAYS)),
    },
    {
      page: parseInt,
    },
  );
  useEffect(() => {
    setQuery({ page: 1 });
  }, [query.behavior_selection]);

  const display =
    query.behavior_selection === ANALYTICS_TAB_NAMES.totalSales.snakeCaseName ||
    query.behavior_selection === ANALYTICS_TAB_NAMES.averageOrderValue.snakeCaseName
      ? formatPrice
      : x => x || 0;

  moment.locale(momentLanguage(lang));

  const parsedRange = parseRange(query.range);
  const rangeVariable = toRangeVariable(parsedRange);
  const LIMIT = 30;
  const { data, loading } = useQuery(NEW_VS_RETURNING_CUSTOMERS_REPORT, {
    variables: {
      storeId: selectedStore.id,
      range: rangeVariable,
      date: query.date,
      page: query.page,
      limit: LIMIT,
    },
  });

  useEffect(() => {
    if (data?.newVsReturningCustomersReport?.data)
      setTransformedData(
        transformNewVsReturningData(data?.newVsReturningCustomersReport?.data[camelize(query.behavior_selection)]),
      );
  }, [data, query.behavior_selection]);

  const formatData = values =>
    values.map(item => ({
      date:
        query.date === 'day'
          ? moment(new Date(item.date)).format('MMM DD, YYYY')
          : moment(new Date(item.date)).format('MMMM, YYYY'),
      new: display(item.new),
      returning: display(item.returning),
    }));

  return (
    <Layout
      top={
        <Breadcrumbs
          path={breadcrumbs.NEW_VS_RETURNING}
          right={
            <DateRange
              queryTypeDate={query.type_date}
              range={parsedRange}
              query={query}
              setQuery={setQuery}
              selectedStoreCreatedAt={selectedStore.createdAt}
              direction={direction}
              notifications={notifications}
              resettableStates={resettableStates}
            />
          }
        />
      }
    >
      {loading ? (
        <Spinner />
      ) : (
        <div>
          <div className="pb-32 flex flex-col">
            <Text value={translations.BREAKDOWN_BY} className="text-lg font-bold mb-4" />
            <div>
              <div className="mb-4">
                <Tabs
                  items={[
                    {
                      title: <Text value={translations.DELIVERY_VS_PICKUP} />,
                      to: paths.breakdownDeliveryPickup,
                    },
                    {
                      title: <Text value={translations.PAYMENT_METHODS_ANALYTICS} />,
                      to: paths.breakdownPaymentMethods,
                    },
                    {
                      title: <Text value={translations.NEW_VS_RETURNING} />,
                      to: paths.breakdownNewVsReturning,
                    },
                  ]}
                />
              </div>
            </div>
            <div>
              <div className="mb-4 flex justify-between items-center">
                <DropDown
                  float="left"
                  optionSelected={query.behavior_selection}
                  onSelect={option => setQuery({ behavior_selection: option })}
                  data={[
                    {
                      id: ANALYTICS_TAB_NAMES.totalSales.snakeCaseName,
                      title: <Text value={translations.TOTAL_SALES} />,
                    },
                    {
                      id: ANALYTICS_TAB_NAMES.averageOrderValue.snakeCaseName,
                      title: <Text value={translations.AVERAGE_ORDER_VALUE} />,
                    },
                    {
                      id: ANALYTICS_TAB_NAMES.totalOrders.snakeCaseName,
                      title: <Text value={translations.TOTAL_ORDERS} />,
                    },
                    {
                      id: ANALYTICS_TAB_NAMES.totalCustomers.snakeCaseName,
                      title: <Text value={translations.TOTAL_CUSTOMERS} />,
                    },
                  ]}
                  icon="keyboard_arrow_down"
                  position={lang === 'ar' ? 'right' : 'left'}
                />
                <Period value={query.date} onChange={value => setQuery({ page: 1, date: value })} />
              </div>
            </div>
            <div className="w-full">
              <Table
                dataHeader={[
                  {
                    key: 'date',
                    name:
                      query.date === 'day' ? <Text value={translations.DAY} /> : <Text value={translations.MONTH} />,
                    sort: false,
                    inView: false,
                  },
                  {
                    key: 'new',
                    name: <Text value={translations.NEW_CUSTOMERS} />,
                    sort: false,
                    inView: false,
                  },
                  {
                    key: 'returning',
                    name: <Text value={translations.RETURNING_CUSTOMERS} />,
                    sort: false,
                    inView: false,
                  },
                ]}
                dataBody={transformedData}
                formatData={values => formatData(values)}
                footer={
                  <TableFooter
                    totalRecords={data?.newVsReturningCustomersReport?.totalCount}
                    pageLimit={LIMIT}
                    currentPage={query.page}
                    onPageChanged={({ currentPage }) => setQuery({ page: currentPage })}
                    totalCount={transformedData.length}
                    exportLink={() => {
                      exportNewVsReturningReport(
                        selectedStore.id,
                        parsedRange[0],
                        parsedRange[1],
                        query.date,
                        query.behavior_selection,
                      )
                        .then(() => notifications.show(<Text value={translations.EXPORT_NEW_VS_RETURNING_REPORT} />))
                        .catch(err => notifications.show(err.message));
                    }}
                  />
                }
              />
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};
export default NewVsReturning;
