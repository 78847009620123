import * as R from "ramda"

export const handleSignInErrors = callback => err => {
  if (R.path(["graphQLErrors", 0, "extensions", "code"], err) === 403) {
    callback("invalid_login")
    return
  }

  callback("generic")
}
