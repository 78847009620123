import React, { useContext } from "react"
import { navigate } from "@reach/router"
import { replaceParams } from "utils/index"
import { ReactComponent as DeliveryAi } from "assets/delivery-ai.svg"
import { Button, CreationBox } from "components/kit"
import { context as notificationsContext } from "context/notifications"
import { context as localeContext } from "context/locale"
import { Text } from "components/service"
import * as translations from "constants/translations"
import { Link } from "components/service"
import * as paths from "paths.js"

export default ({ branches, storeId }) => {
  const notifications = useContext(notificationsContext)

  return (
    <div className="flex flex-col mx-auto max-w-sm items-center ">
      <DeliveryAi className="w-full" />
      <CreationBox
        title={<Text value={translations.CONSIDER_ADDING} />}
        description={<Text value={translations.YOU_CAN_ADD_MORE_DELEVERY} />}
        actions={
          <Button
            onClick={() => {
              if (!branches) {
                notifications.show(
                  <Text
                    value={translations.YOU_HAVE_ADD_BUSINESS_LOCATION_FIRST}
                  />,
                  "info"
                )
              } else if (branches) {
                navigate(
                  replaceParams(paths.deliveryCreation, { storeId: storeId })
                )
              }
            }}
            kind="primary"
          >
            <Text value={translations.ADD_DELIVERY_ZONE} />
          </Button>
        }
      />
    </div>
  )
}
