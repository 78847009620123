import React, { useContext, useState, useRef } from "react"
import { context as localeContext } from "context/locale"
import { context as userContext } from "context/user"
import { useMobile } from "hooks"
import { Text } from "components/service"
import * as translations from "constants/translations"
import { Input } from "components/form/elements"
import { ReactComponent as SearchIcon } from "assets/search.svg"

import { DropDown } from "components/kit"
import cx from "classnames"
import { useQueryState } from "hooks"

export default ({ children, query, setQuery, optionGroupId, emptyGroups }) => {
  return (
    <Root
      query={query}
      setQuery={setQuery}
      optionGroupId={optionGroupId}
      emptyGroups={emptyGroups}
    >
      {children}
    </Root>
  )
}

const Root = ({ children, query, setQuery, optionGroupId, emptyGroups }) => {
  const { lang } = useContext(localeContext)
  const { branches } = useContext(userContext)
  const isMobile = useMobile()
  const [langSelected, setLangSelected] = useState("en")
  const searchTimeout = useRef()

  const prodcutsLang = [
    { id: "en", title: "English" },
    { id: "ar", title: "Arabic" },
  ]

  const branchesData =
    branches &&
    [...branches].map(branch => ({
      id: branch.id,
      title: <Text value={branch} />,
    }))

  const statusData = [
    {
      id: "all",
      title: isMobile ? (
        <Text value={translations.ALL_STATUS} />
      ) : (
        <Text value={translations.ALL} />
      ),
    },
    { id: "avaliable", title: <Text value={translations.AVALIABLE} /> },
    {
      id: "unavaliable",
      title: <Text value={translations.UNAVALIABLE} />,
    },
  ]

  const MobileFrame = () => {
    return (
      !emptyGroups && (
        <>
          <div className="w-full">
            <Input
              type="text"
              search
              icon={<SearchIcon />}
              placeholder={
                lang === "en"
                  ? translations.SEARCH_OPTION[0]
                  : translations.SEARCH_OPTION[1]
              }
              initialValue={
                optionGroupId === "all" ? query.title : query.search
              }
              onChange={e => {
                clearTimeout(searchTimeout.current)
                const { value } = e.target
                searchTimeout.current = setTimeout(() => {
                  optionGroupId === "all"
                    ? setQuery({ page: 1, title: value })
                    : setQuery({ page: 1, search: value })
                }, 500)
              }}
            />
          </div>
          <div className={cx("w-full md:w-auto my-2")}>
            <DropDown
              float={"left"}
              label={<Text value={translations.BUSINESS_LOCATION} />}
              optionSelected={query.location}
              onSelect={option => setQuery({ location: option })}
              data={branchesData}
              icon="keyboard_arrow_down"
              position={lang === "ar" ? "right" : "left"}
              scrollable
              testId_openDropDown="list-optionGroups-select-business-location"
              testId_selectedData="list-optionGroups-business-location-name"
              testId_listData="list-optionGroups-business-location-list"
              wFull={true}
              responsiveLabel
            />
          </div>
          <div className="flex items-center w-full md:w-auto flex items-center">
            <div className="md:hidden w-1/2">
              <DropDown
                float={"left"}
                label={<Text value={translations.OPTION_GROUPS_STATUS} />}
                optionSelected={query.status}
                onSelect={option => setQuery({ status: option })}
                data={statusData}
                icon="keyboard_arrow_down"
                position={lang === "ar" ? "right" : "left"}
                scrollable
                testId_openDropDown="list-optionGroups-select-status"
                testId_selectedData="list-optionGroups-status-name"
                testId_listData="list-optionGroups-status-list"
                wFull={true}
                responsiveLabel
              />
            </div>
            <div className={cx("my-2 w-1/2 md:w-auto ml-4")}>
              <DropDown
                float={lang === "ar" ? "left" : "right"}
                label={<Text value={translations.VIEW_IN} />}
                responsiveLabel
                full
                optionSelected={langSelected}
                onSelect={prodcutsLang => setLangSelected(prodcutsLang)}
                data={prodcutsLang}
                icon="keyboard_arrow_down"
                testId_openDropDown="list-optionGroups-select-view-lang"
                testId_selectedData="list-optionGroups-view-lang-name"
                testId_listData="list-optionGroups-view-lang-list"
                wFull={true}
                responsiveLabel
              />
            </div>
          </div>
        </>
      )
    )
  }

  const DesktopFrame = () => {
    return (
      !emptyGroups && (
        <div
          className={cx(
            "flex flex-wrap justify-between",
            lang === "ar" && "flex-row-reverse"
          )}
        >
          <div
            className={cx(
              "flex flex-wrap flex-col md:flex-row",
              lang === "ar" && "flex-row-reverse"
            )}
          >
            <div className={cx("flex-2 my-2", lang === "en" ? "mr-4" : "ml-4")}>
              <DropDown
                float={"left"}
                label={<Text value={translations.BUSINESS_LOCATION} />}
                optionSelected={query.location}
                onSelect={option => setQuery({ location: option })}
                data={branchesData}
                icon="keyboard_arrow_down"
                position={lang === "ar" ? "right" : "left"}
                scrollable
                testId_openDropDown="list-optionGroups-select-business-location"
                testId_selectedData="list-optionGroups-business-location-name"
                testId_listData="list-optionGroups-business-location-list"
              />
            </div>

            <div className={cx("flex-2 my-2", lang === "en" ? "mr-4" : "ml-4")}>
              <DropDown
                float={"left"}
                label={<Text value={translations.OPTION_GROUPS_STATUS} />}
                optionSelected={query.status}
                onSelect={option => setQuery({ status: option })}
                data={statusData}
                icon="keyboard_arrow_down"
                position={lang === "ar" ? "right" : "left"}
                scrollable
                testId_openDropDown="list-optionGroups-select-status"
                testId_selectedData="list-optionGroups-status-name"
                testId_listData="list-optionGroups-status-list"
              />
            </div>
          </div>
          <div className={cx("flex-2 my-2", lang === "en" ? "mr-4" : "ml-4")}>
            <DropDown
              float={lang === "ar" ? "left" : "right"}
              label={<Text value={translations.VIEW_IN} />}
              responsiveLabel
              full
              optionSelected={langSelected}
              onSelect={prodcutsLang => setLangSelected(prodcutsLang)}
              data={prodcutsLang}
              icon="keyboard_arrow_down"
              testId_openDropDown="list-optionGroups-select-view-lang"
              testId_selectedData="list-optionGroups-view-lang-name"
              testId_listData="list-optionGroups-view-lang-list"
            />
          </div>
        </div>
      )
    )
  }

  return (
    <>
      {branches === null ? (
        "no business location"
      ) : (
        <div className="mt-4 pb-32 md:pb-16">
          {isMobile ? <MobileFrame /> : <DesktopFrame />}
          <div className="mt-4">{children(query, setQuery, langSelected)}</div>
        </div>
      )}
    </>
  )
}
