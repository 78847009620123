import React, { useContext } from "react"
import { context as localeContext } from "context/locale"
import cx from "classnames"

export default ({ children, onClose }) => {
  const { lang, direction } = useContext(localeContext)

  return (
    <div
      style={{ direction }}
      className={cx(
        "bg-white-100",
        lang === "ar" ? "border-r-4" : "border-l-4",
        "rounded text-black-700 px-2 py-2 shadow relative border-zyda-red-300"
      )}
      role="alert"
    >
      <div className="flex items-start text-sm">
        <i className={"material-icons text-zyda-red-300 text-2xl mx-2"}>
          error
        </i>
        <p className="px-2">{children}</p>
        <button
          onClick={onClose}
          type="button"
          className={cx(
            "absolute top-0",
            lang === "ar" ? "left-0" : "right-0",
            "material-icons text-gray-500 text-xl"
          )}
        >
          close
        </button>
      </div>
    </div>
  )
}
