import { useContext } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';

import { context as userContext } from 'context/user';
import { ADD_CASHBACK_PROGRAM, CASHBACK_PROGRAMS_LIST } from '../schemas';
import { BACKEND_GRAPHQL_SERVER } from '../../../../constants';

const useAddProgram = () => {
  const { selectedStore } = useContext(userContext);
  const { subdomain } = selectedStore || {};

  const [addProgram] = useMutation(ADD_CASHBACK_PROGRAM, {
    context: { serverName: BACKEND_GRAPHQL_SERVER },
  });
  const { updateQuery } = useQuery(CASHBACK_PROGRAMS_LIST, {
    context: { serverName: BACKEND_GRAPHQL_SERVER },
    variables: { subdomain },
  });

  const handleAddProgram = async formData => {
    const { name, percentage, hasEndDate, startsAt, endsAt } = formData;
    const isProgramStartsToday = moment(startsAt).isSame(moment(), 'day');

    const { data } = await addProgram({
      variables: {
        subdomain,
        name,
        percentage,
        startsAt: isProgramStartsToday ? null : startsAt,
        endsAt: hasEndDate ? endsAt : null,
      },
    });

    updateQuery?.(prevData => ({
      ...prevData,
      cashbackPrograms: [data?.createCashbackProgram, ...prevData.cashbackPrograms],
    }));
  };

  return [handleAddProgram];
};

export default useAddProgram;
