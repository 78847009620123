import React, { useContext } from "react"
import { navigate } from "@reach/router"
import { Formik } from "formik"
import { validationSchema } from "components/common/editUserInfo/changePassword/data"
import { useReplaceParams } from "hooks/index"
import * as paths from "paths.js"
import { context as userContext } from "context/user"
import { path } from "ramda"
import * as translations from "constants/translations"
import { context as notificationsContext } from "context/notifications"
import { Text } from "components/service"
export default ({
  initialValues = { role: "owner", branches: [] },
  children,
  onSubmit,
}) => {
  const replace = useReplaceParams()
  const userInfo = useContext(userContext)
  const notifications = useContext(notificationsContext)

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async data => {
        const variables = {
          oldPassword: data.old_password,
          newPassword: data.new_password,
        }

        try {
          await onSubmit(variables)
          userInfo && userInfo.userUpdatedPassword(true)
          navigate(replace(paths.editUserInfo))
        } catch (err) {
          const errors = path(
            ["graphQLErrors", 0, "extensions", "exception", "body"],
            err
          )
          if (errors.base.length) {
            notifications.show(
              <Text value={translations.OLD_PASSWORD_INCORRECT} />,
              "error",
              () => {},
              { isDismisable: false },
              2
            )
          }
        }
      }}
    >
      {children}
    </Formik>
  )
}
