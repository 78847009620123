import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import cx from 'classnames';
import { useLocation } from '@reach/router';

import { Text, Field } from 'components/service';
import { Label, Row, Container } from 'components/form/generic';
import { Uploader, Spinner } from 'components/kit';
import { Input, Textarea, MultiCheckbox } from 'components/form/elements';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { context as featureBlockingContext } from 'context/feature-blocking';
import * as translations from 'constants/translations';
import FeatureAccess from 'common/lib/FeatureAccess';
import { ACCESS_MAP_KEYS } from 'constants/featureAccessMatrix';
import { useMobile } from 'hooks';
import * as schemas from './schemas';
import { handleTrackingDesctiption } from './utils';
import ItemTrackingType from './ItemTrackingType';
import ItemTrackingBlocked from './ItemTrackingBlocked';

const BasicDetails = ({
  productId,
  isDescriptionVisible,
  open,
  close,
  setFieldValue,
  productImage,
  imageTokenSource,
  deleteProductImage,
  variants,
  trackingType,
  createMenuItem,
  updateProduct,
  titleEn,
  titleAr,
}) => {
  const isMobile = useMobile();
  const { lang, translate } = useContext(localeContext);
  const { selectedStore: selected, isPosCourier: isPosType } = useContext(userContext);
  const [descriptionVisable, setDescriptionVisable] = useState(isDescriptionVisible || false);
  const { loading: catLoading, data: dataCategories } = useQuery(schemas.CATEGORIES, {
    variables: {
      storeId: selected.id,
    },
  });
  const { loading: branchesLoading, data: dataBranches } = useQuery(schemas.BRANCHES, {
    variables: {
      storeId: selected.id,
    },
  });

  const { featureAccess } = useContext(featureBlockingContext);
  const featureFeatureAccess = featureAccess.get(ACCESS_MAP_KEYS.FEATURES);

  const location = useLocation();
  const {
    ITEM_TRACKING: { isBlocked },
  } = featureFeatureAccess?.get(FeatureAccess.getFeaturePath(location?.pathname, selected.id));

  const handleRemoveDescription = () => {
    setFieldValue('descriptionEn', null);
    setFieldValue('descriptionAr', null);
    setDescriptionVisable(!descriptionVisable);
  };
  return catLoading && branchesLoading ? (
    <Spinner />
  ) : (
    <div className="flex flex-wrap justify-between">
      <div className="w-full">
        <Container>
          <>
            <Text
              value={translations.PRODUCT_NAME}
              className={cx('font-medium text-base py-2', lang === 'ar' && 'text-right')}
            />
            <Row>
              <div className="flex flex-col justify-between items-start">
                <div className={cx('w-full mb-3', lang === 'en' ? 'mr-0 md:mr-4' : 'ml-0 md:ml-4')}>
                  <Label title={<Text value={translations.ENGLISH} />}>
                    <Field
                      data-testid="basic-details-product-name-en"
                      type="text"
                      name="titleEn"
                      placeholder={translations.ENTER_PRODUCT_NAME[0]}
                      component={Input}
                      direction="ltr"
                    />
                  </Label>
                </div>
                <div className="w-full ">
                  <Label title={<Text value={translations.ARABIC} />}>
                    <Field
                      data-testid="basic-details-product-name-ar"
                      direction="rtl"
                      type="text"
                      name="titleAr"
                      placeholder={translations.ENTER_PRODUCT_NAME[1]}
                      component={Input}
                    />
                  </Label>
                </div>
              </div>
            </Row>
            {isPosType && (
              <Row className="py-3">
                <Label
                  title={<Text value={translations.External_ID} />}
                  subtitle={<Text value={translations.EXTERNAL_ID_DESC} />}
                >
                  <Field
                    type="text"
                    name="externalId"
                    data-testid="basic-details-product-externalId"
                    placeholder={translations.ENTER_EXTERNAL_ID[0]}
                    component={Input}
                    hideError
                  />
                </Label>
              </Row>
            )}
            {!descriptionVisable ? (
              <button type="button" className="text-primary-base py-3" onClick={handleRemoveDescription}>
                <Text value={translations.DESCRIPTION} className="text-primary-base" />
              </button>
            ) : (
              <div className="py-3">
                <Row className="flex justify-between">
                  <Text value={translations.DESCRIPTION} className="text-base mb-2 font-medium" />
                  <div className={cx(lang === 'en' ? 'float-right' : 'float-left')}>
                    <button type="button" onClick={handleRemoveDescription}>
                      <Text className="text-gray-700 font-medium" value={translations.DELETE} />
                    </button>
                  </div>
                </Row>
                <div className="flex flex-col md:flex-row justify-between items-center">
                  <div className={cx('w-full mb-6 md:mb-0', lang === 'en' ? 'mr-0' : 'ml-0 md:ml-4')}>
                    <Row>
                      <div className="flex flex-col justify-between items-start">
                        <div className={cx('w-full mb-3', lang === 'en' ? 'mr-0 md:mr-4' : 'ml-0 md:ml-4')}>
                          <Label title={<Text value={translations.ENGLISH} />}>
                            <Field
                              data-testid="basic-details-product-description-en"
                              name="descriptionEn"
                              placeholder={translations.ENTER_DESCRIPTION[0]}
                              component={Textarea}
                              rows="5"
                            />
                          </Label>
                        </div>
                        <div className="w-full ">
                          <Label title={<Text value={translations.ARABIC} />}>
                            <Field
                              data-testid="basic-details-product-description-ar"
                              name="descriptionAr"
                              direction="rtl"
                              placeholder={translations.ENTER_DESCRIPTION[1]}
                              component={Textarea}
                              rows="5"
                            />
                          </Label>
                        </div>
                      </div>
                    </Row>
                  </div>
                </div>
              </div>
            )}
            <Row>
              <div className="w-full">
                <Field
                  component={Uploader}
                  productCatalog
                  name="photoUrl"
                  imageUrl={productImage && productImage}
                  onRemove={() => {
                    productImage && deleteProductImage();
                    setFieldValue('photoUrl', null);
                  }}
                  size="bigger"
                  type="squared"
                  info={<Text className="text-zyda-black-100" value={translations.UPLOAD_DRAG_IMAGE} />}
                  format={<Text className="text-gray-500" value={translations.UPLOAD_DRAG_IMAGE_FORMATS} />}
                  justify="start"
                  onUpload={image => setFieldValue('photoUrl', image)}
                  onCancel={() => imageTokenSource.cancel()}
                  close={close}
                  open={open}
                  accept="image/*"
                  testId_uploadImg="photo-url"
                  testId_BtnUpload="basic-details-photo-url-btn"
                />
              </div>
            </Row>
            <Row className="py-3">
              {catLoading ? (
                <Spinner />
              ) : (
                <Field
                  testIdOpenList="basic-details-product-category-list"
                  testIdOptionsList="basic-details-product-category-option"
                  testIdClearAll="basic-details-product-clear-all-category"
                  testIdSelectAll="basic-details-product-select-all-category"
                  title={<Text value={translations.CATEGORY} />}
                  subtitle={<Text className="text-gray-700" value={translations.EACH_ITEM_CAN_BE} />}
                  name="categories"
                  placeholder={
                    lang === 'en' ? translations.PLEASE_SELECT_CATEGORY[0] : translations.PLEASE_SELECT_CATEGORY[1]
                  }
                  items={dataCategories?.menuSections?.menuSections?.map(cat => ({
                    id: cat.id,
                    title: <Text value={cat} />,
                  }))}
                  component={MultiCheckbox}
                />
              )}
            </Row>

            <Row className="py-3">
              {branchesLoading ? (
                <Spinner />
              ) : (
                <Field
                  testIdOpenList="basic-details-product-publishIn-list"
                  testIdOptionsList="basic-details-product-publishIn-option"
                  testIdClearAll="basic-details-product-clear-all-publishIn"
                  testIdSelectAll="basic-details-product-select-all-publishIn"
                  title={<Text value={translations.SELECTED_BRANCHES} />}
                  subtitle={<Text className="text-gray-700" value={translations.CHOOSE_ALL_BUSINESS_ITEM} />}
                  name="publishIn"
                  placeholder={
                    lang === 'en' ? translations.PLEASE_SELECT_LOCATION[0] : translations.PLEASE_SELECT_LOCATION[1]
                  }
                  items={dataBranches.branches.map(branch => ({
                    id: branch.id,
                    title: <Text value={branch} />,
                  }))}
                  component={MultiCheckbox}
                />
              )}
            </Row>
            {isBlocked ? (
              <ItemTrackingBlocked />
            ) : (
              <ItemTrackingType
                lang={lang}
                createMenuItem={createMenuItem}
                isMobile={isMobile}
                translate={translate}
                setFieldValue={setFieldValue}
                trackingType={trackingType}
                updateProduct={updateProduct}
                titleEn={titleEn}
                titleAr={titleAr}
                descriptionType={handleTrackingDesctiption(trackingType)}
                dataBranches={dataBranches}
                selectedId={selected.id}
                productId={productId}
                variants={variants}
              />
            )}
          </>
        </Container>
      </div>
    </div>
  );
};

BasicDetails.propTypes = {
  productId: PropTypes.string.isRequired,
  isDescriptionVisible: PropTypes.bool.isRequired,
  open: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  productImage: PropTypes.string,
  imageTokenSource: PropTypes.func.isRequired,
  deleteProductImage: PropTypes.func.isRequired,
  variants: PropTypes.arrayOf(
    PropTypes.shape({
      barCode: PropTypes.string,
      discountEnabled: PropTypes.bool.isRequired,
      discountedPrice: PropTypes.number,
      externalId: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      images: PropTypes.arrayOf(PropTypes.string).isRequired,
      prepTime: PropTypes.number.isRequired,
      price: PropTypes.number.isRequired,
      propertySections: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.object)),
      sku: PropTypes.string,
      titleAr: PropTypes.string.isRequired,
      titleEn: PropTypes.string.isRequired,
    }),
  ).isRequired,
  trackingType: PropTypes.string.isRequired,
  createMenuItem: PropTypes.func.isRequired,
  updateProduct: PropTypes.func.isRequired,
  titleEn: PropTypes.string.isRequired,
  titleAr: PropTypes.string.isRequired,
};

BasicDetails.defaultProps = {
  productImage: '',
};

export default BasicDetails;
