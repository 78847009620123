import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { Text } from 'components/service';
import { Button, Stack } from 'components/kit';
import { Footer } from 'components/form/generic';
import { ARE_YOU_SURE_UNPUBLISH, IF_YOU_WANT_JUST, CANCEL, YES } from 'constants/translations';

const UnpublishModal = ({ onCancel, updateStore, lang }) => (
  <div className="flex-col items-center">
    <div className="flex-1 flex-col items-center px-6">
      <span className="text-sm block my-1">
        <Text value={ARE_YOU_SURE_UNPUBLISH} />
      </span>
      <span className="text-sm block my-1" data-testid="unpublish-modal-title">
        <Text value={IF_YOU_WANT_JUST} />
      </span>
    </div>
    <Footer modalFooter>
      <div className="w-full flex items-center">
        <div className={cx(lang === 'ar' ? 'mr-auto' : 'ml-auto')}>
          <Stack direction="row">
            <Button onClick={onCancel} type="button" kind="tertiary" data-testid="cancel-unpublish-btn">
              <Text value={CANCEL} />
            </Button>
            <Button onClick={updateStore} kind="secondaryError" data-testid="confirm-unpublish-btn">
              <Text value={YES} />
            </Button>
          </Stack>
        </div>
      </div>
    </Footer>
  </div>
);

UnpublishModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  updateStore: PropTypes.func.isRequired,
  lang: PropTypes.oneOf(['en', 'ar']).isRequired,
};

export default UnpublishModal;
