import styled from "styled-components"

export default styled.div`
  > div {
    margin-bottom: 1.5rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  > button {
    margin-right: 1rem;
  }
`
