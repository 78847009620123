import logger from 'common/utils/logger';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage, isSupported } from 'firebase/messaging';

import * as userService from 'services/user';
import { axios } from './utils';

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const isUiWebview = /((iPhone|iPod|iPad).*AppleWebKit(?!.*Version)|; wv)/i.test(navigator.userAgent); // Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCO1QaV9tvAMORScuLR3Mczl3VgV8tjFIU',
  authDomain: 'zyda-dashboard-app.firebaseapp.com',
  databaseURL: 'https://zyda-dashboard-app.firebaseio.com',
  projectId: 'zyda-dashboard-app',
  storageBucket: 'zyda-dashboard-app.appspot.com',
  messagingSenderId: '188886948054',
  appId: '1:188886948054:web:b0a9402efade668fcf9177',
  measurementId: 'G-2D0ZMH6C0K',
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
let messaging = {};
if (isSupported() && !isSafari && !isUiWebview) {
  messaging = getMessaging(firebase);
}

const getOS = () => {
  let os = 'Unknown';
  if (navigator.userAgent.indexOf('Win') !== -1) os = 'Windows';
  if (navigator.userAgent.indexOf('Mac') !== -1) os = 'MacOS';
  if (navigator.userAgent.indexOf('X11') !== -1) os = 'UNIX';
  if (navigator.userAgent.indexOf('Linux') !== -1) os = 'Linux';

  return os;
};

const isMobile = () => {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return true;
  }
  return false;
};

const sendPushSubscription = token => {
  const userId = userService.getUserId();
  const userToken = userService.getToken();
  const os = getOS();
  return axios({
    method: 'POST',
    url: '/notifications/mobile_subscribe',
    data: {
      os,
      user_id: userId,
      token,
    },
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${userToken}`,
    },
  });
};

function requestPermission() {
  const isMobileUser = isMobile();
  if (!isMobileUser && !isSafari && isUiWebview) {
    Notification.requestPermission().then(permission => {
      if (permission === 'granted') {
        getToken(messaging, {
          vapidKey: 'BEOLZPL81jwYM52phrjVgCyTk_b7bqwi0q2MuAGNgDKX4EmDGWeph_Z17QdiVmd87gX_H7RZowG3I2iNju3rK1w',
        })
          .then(currentToken => {
            if (currentToken) {
              sendPushSubscription(currentToken);
            } else {
              // Show permission request UI
              console.log('No registration token available. Request permission to generate one.');
              // ...
            }
          })
          .catch(err => {
            logger.error(err);
          });
      } else {
        console.log('No Notification permission.');
      }
    });
  }
}

onMessage(messaging, payload => {
  const notificationTitle = payload.notification.title;
  const notificationOptions = {
    body: payload.notification.body,
    icon: payload.notification.icon,
    requireInteraction: true,
    silent: false,
    vibrate: true,
  };

  if (!('Notification' in window)) {
    console.log('This browser does not support system notifications.');
  } else if (Notification.permission === 'granted') {
    // If it's okay let's create a notification
    const notification = new Notification(notificationTitle, notificationOptions);
    notification.onclick = function(event) {
      event.preventDefault();
      window.parent.focus();
      notification.close();
    };
  }
});

requestPermission();
