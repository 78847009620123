export const unauthenticated = isAuthenticated => !isAuthenticated

export const storeCreated = (isAuthenticated, { isStoreCreated } = {}) =>
  isAuthenticated && isStoreCreated

export const storeCreatedAndAvail = (
  isAuthenticated,
  { isStoreCreated, blockTabs } = {}
) => isAuthenticated && isStoreCreated && !blockTabs

export const storeNotCreated = (isAuthenticated, { isStoreCreated } = {}) =>
  isAuthenticated && !isStoreCreated

export const storeCreatedAndSubscriped = (
  isAuthenticated,
  { isStoreCreated, blockTabs, subscription } = {}
) => {
  if (subscription === "late_payment") {
    return isAuthenticated && isStoreCreated
  }
  return isAuthenticated && isStoreCreated && !blockTabs
}
