import React, { useContext, useEffect } from 'react';
import cx from 'classnames';
import 'styled-components/macro';
import { Form } from 'formik';
import { useMutation } from '@apollo/client';

import { useSelectedStore } from 'hooks/index';
import { context as localeContext } from 'context/locale';
import { Text, Link } from 'components/service';
import { breadcrumbs } from 'constants/translations';
import { Layout, Breadcrumbs } from 'components/common/dashboard';
import * as paths from 'paths.js';
import * as translations from 'constants/translations';
import { context as userContext } from 'context/user';
import { UserForm } from 'components/common/team';
import { Button, Stack } from 'components/kit';
import { transformUser } from 'components/common/team/utils';
import * as schemas from './schemas';
import { EditUserData } from 'components/common/editUserInfo';
import { ReactComponent as Done } from 'assets/check.svg';
import { ReactComponent as Close } from 'assets/Icons_Close.svg';
import { useMobile } from 'hooks/index';
import { getCookie } from 'utils';

const EditInfo = () => {
  const { direction } = useContext(localeContext);
  const selectedStoreId = useSelectedStore();
  const userInfo = useContext(userContext);
  const isMobile = useMobile();

  const topbar = 'fixed w-full z-50 flex justify-center h-16';
  const messageWrapper = `flex items-center justify-center border-solid ${
    direction === 'ltr' ? 'border-l-4 border-left-zyda-green-300' : 'border-r-4 border-right-zyda-green-300 '
  } ${isMobile ? 'w-90p' : 'w-30p'} `;
  const done = 'w-8 object-contain h-10 mr-2 ml-3 my-0 ';
  const closeWrapper = 'inline-block ';
  const close = 'cursor-pointer fill-i mr-2 h-5 w-5  ';
  const ShadowStyle = {
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.16)',
    backgroundColor: '#ffffff',
  };

  const [userUpdateSelf, { loading }] = useMutation(schemas.USER_UPDATE_SELF, {
    variables: {
      storeId: selectedStoreId,
      userId: userInfo && `${userInfo.user.id}`,
    },
  });

  const onSubmitSaveChanges = variables => {
    if (process.env.REACT_APP_SEGMENT_WRITE_KEY) {
      if (getCookie('ajs_user_id') !== '') {
        window.analytics.identify(userInfo.user.id, {
          createdAt: userInfo.user.createdAt,
          name: variables.name,
          email: variables.email,
          phone: variables.phoneNumber,
          roles: userInfo.user.roles,
          isSupport: userInfo.user.isSupport,
        });
      }
    }
    userUpdateSelf({ variables });
  };

  useEffect(() => {
    setTimeout(() => {
      userInfo && userInfo.userUpdatedPassword(false);
    }, 3000);
  }, []);

  return (
    <div>
      {userInfo && userInfo.passwordChanged ? (
        <div className={topbar} style={{ top: '40px' }}>
          <div
            className={messageWrapper}
            style={{
              direction,
              ShadowStyle,
            }}
          >
            <Done className={done} />
            <Text value={translations.CHANGE_PASSWORD_SUCESS} />
            <div className={closeWrapper}>
              <Close className={close} onClick={() => userInfo && userInfo.userUpdatedPassword(false)} />
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      <Layout
        top={
          <div className={cx('w-full flex-row', direction === 'rtl' && 'ml-auto')}>
            <EditUserData initialValues={transformUser(userInfo && userInfo.user)} onSubmit={onSubmitSaveChanges}>
              {({ values }) => (
                <Form>
                  <Breadcrumbs
                    path={breadcrumbs.EDIT_USER_INFO}
                    right={
                      <Stack direction="wrap" expandItem={false} spacing="2">
                        <Button kind="primary" isSpinning={loading}>
                          <Text value={translations.SAVE_CHANGE} />
                        </Button>
                        <Link omitQuery to={paths.orders}>
                          <Button type="button" kind="secondary">
                            <Text value={translations.CANCEL} />
                          </Button>
                        </Link>
                      </Stack>
                    }
                  />
                  <div style={{ direction }} className={cx('pb-6 xl:px-6 px-4 pt-4', direction === 'rtl' && 'mr-auto')}>
                    <UserForm isOperator={values.role !== 'operator'} comingFromDropDown={true} />
                  </div>
                </Form>
              )}
            </EditUserData>
          </div>
        }
      ></Layout>
    </div>
  );
};

export default EditInfo;
