import { STH_WENT_WRONG } from 'constants/constant-errors';

export const toCamel = s =>
  s.replace(/([-_][a-z])/gi, $1 =>
    $1
      .toUpperCase()
      .replace('-', '')
      .replace('_', ''),
  );

export function camelCaseKeys(object) {
  return Object.entries(object).reduce((carry, [key, value]) => {
    const tempCarry = carry;
    tempCarry[toCamel(key)] = value;
    return tempCarry;
  }, {});
}

export const handleSubmissionErrors = err => {
  const { body } = err.graphQLErrors[0].extensions.exception;

  if (body.message) {
    return body.message;
  }

  return STH_WENT_WRONG;
};
