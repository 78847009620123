import React from "react"
import * as yup from "yup"
import * as translations from "constants/translations"
import { Text } from "components/service"

export const handleProductInitialValue = (
  productId,
  initialData,
  categoryId,
  defaultProductPrepTime,
) => {
  return !productId
    ? {
        fulfillmentMode: "delivery_and_pickup",
        price: "0",
        publishIn: [],
        categories: [categoryId] || [],
        storeEn: "Size",
        storeAr: "الحجم",
        externalId: "",
        specialNotesPlaceholderEn: "Add Instructions",
        specialNotesPlaceholderAr: "أضف تعليمات",
        allowAttachNotes: false,
        specialNotesRequired: "",
        enablePrepTime: "",
        variantsTitleEn: "Size",
        variantsTitleAr: "الحجم",
        specialNotesEnabled: false,
        specialNotesRequired: false,
        trackingType: "none",
        variantsAttributes: [
          {
            discountEnabled: false,
            discountedPrice: null,
            externalId: null,
            images: [],
            prepTime: defaultProductPrepTime,
            price: 0,
            propertySections: [],
            titleAr: "عادى",
            titleEn: "Regular",
          },
        ],
      }
    : {
        ...initialData?.data?.menuItem,
        fulfillmentMode: initialData?.data?.menuItem?.fulfillmentMode,
        price: initialData?.data?.menuItem?.displayPrice + "",
        publishIn: initialData?.data?.menuItem?.publishedBranchIds,
        categories: initialData?.data?.menuItem?.menuSectionIds || [],
        storeEn: initialData?.data?.menuItem?.variantsTitleEn,
        storeAr: initialData?.data?.menuItem?.variantsTitleAr,
        externalId: initialData?.data?.menuItem?.externalId,
        trackingType: initialData?.data?.menuItem?.trackingType,
        specialNotesPlaceholderEn: initialData?.data?.menuItem
          ?.specialNotesPlaceholderEn
          ? initialData?.data?.menuItem?.specialNotesPlaceholderEn
          : "Add Instructions",
        specialNotesPlaceholderAr: initialData?.data?.menuItem
          ?.specialNotesPlaceholderAr
          ? initialData?.data?.menuItem?.specialNotesPlaceholderAr
          : "أضف تعليمات",
        allowAttachNotes: initialData?.data?.menuItem?.specialNotesEnabled,
        specialNotesRequired: initialData?.data?.menuItem?.specialNotesRequired,
        enablePrepTime: !initialData?.data?.variants?.every(
          i => i.prepTime < 0
        ),
      }
}

export const handleVariantInitialValue = (
  productId,
  variantData,
  defaultProductPrepTime
) => {
  return !productId
    ? [
        {
          discountEnabled: false,
          discountedPrice: null,
          externalId: null,
          images: [],
          prepTime: defaultProductPrepTime,
          price: 0,
          propertySections: [],
          titleAr: "عادى",
          titleEn: "Regular",
          barCode: null,
          sku: null,
        },
      ]
    : variantData?.data?.variants
}

export const validationSchema = yup.object().shape({
  titleEn: yup
    .string()
    .required(<Text value={translations.PRODUCT_TITLE_EN_REQ} />),
  titleAr: yup
    .string()
    .required(<Text value={translations.PRODUCT_TITLE_AR_REQ} />),
  storeEn: yup
    .string()
    .required(<Text value={translations.VR_SECTION_TITLE_EN_REQ} />),
  storeAr: yup
    .string()
    .required(<Text value={translations.VR_SECTION_TITLE_AR_REQ} />),
  categories: yup
    .array()
    .of(yup.string().required())
    .required(<Text value={translations.PRODUCT_CATEGORIES_REQ} />),
})
