import React, { memo } from 'react';
import { GoogleMapContainer } from 'components/kit';
import { Marker } from '@react-google-maps/api';

const DeliveryAddress = ({ deliveryAddress, style, height }) => {
  const zoom = 16;
  const lat = parseFloat(deliveryAddress.lat);
  const lng = parseFloat(deliveryAddress.lng);

  return (
    <div className="flex flex-col border border-gray-300 p-4 mb-4" style={{ ...style, height }}>
      <p className="text-base font-semibold">{deliveryAddress.type}</p>
      <p className="text-sm mb-2">{deliveryAddress.address}</p>
      <div>
        <GoogleMapContainer
          google
          center={{
            lat: lat,
            lng: lng,
          }}
          zoom={zoom}
          mapContainerStyle={{
            width: '100%',
            height: '120px',
          }}
        >
          {google => <Marker position={new google.maps.LatLng(lat, lng)} />}
        </GoogleMapContainer>
      </div>
    </div>
  );
};

export default memo(DeliveryAddress);
