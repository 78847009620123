import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { context as localeContext } from 'context/locale';
import { TRACKING_TYPE_ENUM } from 'components/common/product/ItemTracking/utils';

export const STOCK_ITEM_STATUS = {
  TRACKED: 'TRACKED',
  IN_STOCK: 'IN_STOCK',
  OUT_OF_STOCK: 'OUT_OF_STOCK',
};

const LowStock = ({ lowStock, stockItem }) => {
  const { lang } = useContext(localeContext);
  const { variantTitleEn, variantTitleAr, stockCount, trackingType, unavailable, initialCount } = stockItem;

  let currentVariantQuantity = trackingType === TRACKING_TYPE_ENUM.DAILY_CAPACITY ? initialCount : stockCount;

  return (
    <>
      {lang === 'en' ? variantTitleEn : variantTitleAr}
      {trackingType && !unavailable && lowStock > currentVariantQuantity && (
        <Text value={translations.LOW_STOCK} className="text-red-600" />
      )}
    </>
  );
};

LowStock.propTypes = {
  lowStock: PropTypes.number.isRequired,
  stockItem: PropTypes.shape({
    variantTitleEn: PropTypes.string.isRequired,
    variantTitleAr: PropTypes.string.isRequired,
    stockCount: PropTypes.number.isRequired,
    trackingType: PropTypes.string.isRequired,
    unavailable: PropTypes.bool.isRequired,
    initialCount: PropTypes.number.isRequired,
  }),
};

export default LowStock;
