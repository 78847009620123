import React, { useContext } from "react"
import { Field, Text } from "components/service"
import * as translations from "constants/translations"
import { Input, RadioList, Select } from "components/form/elements"
import { Label, Row, Section } from "components/form/generic"
import * as schemas from './schemas'
import { useQuery } from '@apollo/client'
import { context as userContext } from "context/user"

export default ({ values, lang, countryCode, paymentMethods, pushedToGateway, paymentCredentialsId }) => {
  const direction = lang === "ar" ? "rtl" : "ltr"
  const { selectedStore } = useContext(userContext)

  const { data: { banksList } = {} } = useQuery(schemas.BANKS, {
    variables: { countryId: parseInt(selectedStore.countryId), gatewayId: values.paymentGatewayId },
    fetchPolicy: "cache-and-network",
    skip: (values.paymentGatewayId !== 2 ) //Don't call list of banks unless its needed
  })
  
  const { data: { depositTerms } = {} } = useQuery(schemas.DEPOSIT_TERMS, {
    variables: { countryId: parseInt(selectedStore.countryId), gatewayId: values.paymentGatewayId },
    fetchPolicy: "cache-and-network",
    skip: (values.paymentGatewayId !== 2 ) //Don't call list of banks unless its needed
  })

  return (
    <>
      <Section
        title={<Text value={translations.BANK_INFO} />}
        description={<Text value={translations.BANK_INFO_DESCRIPTION} />}
      >
        {!pushedToGateway &&
          <Row className="items-center" expandItem={false}>
            <Text className="my-2" value={translations.ACCOUNT_TYPE} />
            <Field
              type="text"
              name="accountType"
              disabled={!values.isLicensed}
              component={RadioList}
              direction="row"
              items={[
                {
                  title: <Text value={translations.ACCOUNT_TYPE_INDIVIDUAL} />,
                  value: "ind",
                },
                {
                  title: <Text value={translations.ACCOUNT_TYPE_COMPANY} />,
                  value: "corp",
                },
              ]}
            />
          </Row>
        }
        <Row className="flex-col md:flex-row">
          <Label title={<Text value={translations.BANK_NAME} />}>
            {(values.paymentGatewayId === 1) && <Field
              type="text"
              name="bankName"
              data-testid="payments-register-bankName"
              placeholder={
                translations.BANK_NAME_PLACE_HOLDER[lang === "en" ? 0 : 1]
              }
              component={Input}
            />}
            {((values.paymentGatewayId === 2 )&& banksList?.length) && <Field
              name="bankId"
              data-testid="payments-register-bankName"
              placeholder={
                translations.BANK_NAME_PLACE_HOLDER[lang === "en" ? 0 : 1]
              }
              component={Select}
            >
              {banksList.map((c, i) => (
                <option key={i} value={c.Value}>
                  {c.Text}
                </option>
              ))}
            </Field>}
          </Label>
          <Label
            title={
              <Text
                value={
                  values.accountType === "corp"
                    ? translations.COMPANY_ACCOUNT_NAME
                    : translations.ACCOUNT_NAME
                }
              />
            }
            subtitle={
              <Text
                value={
                  values.accountType === "corp"
                    ? translations.COMPANY_ACCOUNT_NAME_SUBTITLE
                    : translations.ACCOUNT_NAME_SUBTITLE
                }
                className="font-light"
              />
            }
          >
            <Field
              type="text"
              name="accountName"
              data-testid="payments-register-accountName"
              placeholder={
                lang === "en"
                  ? values.accountType === "corp"
                    ? translations.COMPANY_ACCOUNT_NAME_PLACE_HOLDER[0]
                    : translations.ACCOUNT_NAME_PLACE_HOLDER[0]
                  : values.accountType === "corp"
                    ? translations.COMPANY_ACCOUNT_NAME_PLACE_HOLDER[1]
                    : translations.ACCOUNT_NAME_PLACE_HOLDER[1]
              }
              component={Input}
            />
          </Label>
        </Row>
        <Row>
          <Label title={<Text value={translations.IBAN} />}>
            <Field
              type="text"
              name="iban"
              data-testid="payments-register-iban"
              placeholder={
                translations.IBAN_PLACE_HOLDER(countryCode)[
                lang === "en" ? 0 : 1
                ]
              }
              component={Input}
            />
          </Label>
          {(values.paymentGatewayId === 2 && depositTerms) &&
            <Label title={<Text value={translations.DEPOSIT_TERMS} />}>
              <Field
                name="paymentDepositTermId"
                data-testid="payments-register-depositTerms"
                placeholder={
                  translations.DEPOSIT_TERMS_PLACE_HOLDER[lang === "en" ? 0 : 1]
                }

                component={Select}
              >
                {depositTerms.map((c, i) => (
                  <option key={`deposit-option${c.id}`} value={c.id}>
                    {c.name}
                  </option>
                ))}
              </Field>
            </Label>
          }
        </Row>
        {countryCode !== "KW" && (
          <>
            <Row className="flex-col md:flex-row">
              <Label
                title={
                  <Text
                    value={
                      values.accountType === "corp"
                        ? translations.COMPANY_ACCOUNT_NUMBER
                        : translations.PERSONAL_ACCOUNT_NUMBER
                    }
                  />
                }
              >
                <Field
                  type="text"
                  name="accountNumber"
                  data-testid="payments-register-accountNumber"
                  placeholder={
                    translations.ACCOUNT_NUMBER_PLACE_HOLDER[
                    lang === "en" ? 0 : 1
                    ]
                  }
                  component={Input}
                />
              </Label>
              <Label
                title={<Text value={translations.BENEFICIARY_ADDRESS} />}
                subtitle={
                  <Text
                    value={
                      values.accountType === "corp"
                        ? translations.COMPANY_BENEFICIARY_ADDRESS_SUBTITLE
                        : translations.PERSONAL_BENEFICIARY_ADDRESS_SUBTITLE
                    }
                    className="font-light"
                  />
                }
              >
                <Field
                  type="text"
                  name="beneficiaryAddress"
                  data-testid="payments-register-beneficiaryAddress"
                  placeholder={
                    translations.BENEFICIARY_ADDRESS_PLACE_HOLDER[
                    lang === "en" ? 0 : 1
                    ]
                  }
                  component={Input}
                />
              </Label>
            </Row>
            <Row className="flex-col md:flex-row">
              <Label title={<Text value={translations.SWIFT_CODE} />}>
                <Field
                  type="text"
                  name="swiftCode"
                  data-testid="payments-register-swiftCode"
                  placeholder={
                    translations.SWIFT_CODE_PLACE_HOLDER[lang === "en" ? 0 : 1]
                  }
                  component={Input}
                />
              </Label>
              <Label title={<Text value={translations.BANK_ADDRESS} />}>
                <Field
                  type="text"
                  name="bankAddress"
                  data-testid="payments-register-bankAddress"
                  placeholder={
                    translations.BANK_ADDRESS_PLACE_HOLDER[
                    lang === "en" ? 0 : 1
                    ]
                  }
                  component={Input}
                />
              </Label>
            </Row>
          </>
        )}
      </Section>
      {!pushedToGateway &&
        <div style={{ direction }}>
          <Row className="mb-8">
            <Label
              title={<Text value={translations.PROFILE_NAME} />}
              subtitle={
                <Text
                  value={translations.PROFILE_NAME_SUBTITLE}
                  className="font-light"
                />
              }
            >
              <Field
                type="text"
                name="profileName"
                data-testid="payments-register-profileName"
                placeholder={
                  translations.PROFILE_NAME_PLACE_HOLDER[lang === "en" ? 0 : 1]
                }
                component={Input}
              />
            </Label>
          </Row>
        </div>
      }
    </>
  )
}
