import React, { useContext, useMemo, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import cx from 'classnames';

import { Text } from 'components/service';
import { context as localeContext } from 'context/locale';
import * as translations from 'constants/translations';
import { WEB_LINKS_LINKS } from 'constants/helperLinks';
import { breadcrumbs } from 'constants/translations';
import { Layout, Breadcrumbs } from 'components/common/dashboard';
import { Button, Spinner } from 'components/kit';
import { useSelectedStore } from 'hooks';
import LinksTable from 'common/components/LinksTable';
import { ReactComponent as AddIcon } from 'assets/add.svg';
import { context as notificationsContext } from 'context/notifications';
import * as schemas from '../schemas';
import WebLinkEmptyState from '../EmptyState';
import * as utils from '../utils';
import useEditWebLink from '../hooks/useEditWebLink';
import useDeleteWebLink from '../hooks/useDeleteWebLink';
import AddWebLink from '../Add';

const WebLinksList = () => {
  const selectedStoreId = useSelectedStore();
  const { direction, lang } = useContext(localeContext);
  const notifications = useContext(notificationsContext);
  const handleOnEditClick = useEditWebLink({ lang });
  const handleOnDeleteClick = useDeleteWebLink({ lang });

  const [isAddingNewLink, setIsAddingNewLink] = useState(false);

  const { data, loading } = useQuery(schemas.SETTINGS, {
    variables: { storeId: selectedStoreId },
  });

  const [sortWebLinks, { loading: isSortLoading }] = useMutation(schemas.UPDATE_WEB_LINKS, {
    onCompleted: () => {
      notifications.show(<Text value={translations.THE_LINKS_SORTED} />);
    },
  });

  const webLinksData = data?.settings?.webLinks?.links;

  const formattedLinks = useMemo(() => webLinksData?.map(link => utils.formatLinkToForm(link, 'url')), [webLinksData]);

  const handleSorting = async sortedData => {
    const formattedWebLinksArr = utils.formatWebLinksArr(sortedData);

    await sortWebLinks({
      variables: {
        storeId: selectedStoreId,
        links: formattedWebLinksArr,
      },
    });
  };

  return (
    <Layout
      top={
        <Breadcrumbs
          helperLinks={WEB_LINKS_LINKS}
          path={breadcrumbs.SETUP_WEB_LINKS}
          right={
            <Button kind="primary" onClick={() => setIsAddingNewLink(true)}>
              <AddIcon className={cx(lang === 'en' ? 'mr-2' : 'ml-2')} />
              <Text value={translations.ADD_NEW_LINK} />
            </Button>
          }
        />
      }
    >
      {loading ? (
        <Spinner />
      ) : (
        <div style={{ direction }}>
          <div className="pb-32 flex flex-row">
            <div className="w-full md:w-3/4">
              {isAddingNewLink && <AddWebLink setIsAddingNewLink={setIsAddingNewLink} />}
              {(!data?.settings?.webLinks || !webLinksData?.length) && !isAddingNewLink && (
                <WebLinkEmptyState handleOnAddClick={() => setIsAddingNewLink(true)} />
              )}
              {!!webLinksData?.length && (
                <LinksTable
                  isSavingSorting={isSortLoading}
                  storeId={selectedStoreId}
                  setSortedItems={handleSorting}
                  items={formattedLinks}
                  onEditClick={handleOnEditClick}
                  onDeleteClick={handleOnDeleteClick}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default WebLinksList;
