import React from 'react';
import PropTypes from 'prop-types';
import { ChevronRightIcon, UserIcon } from '@heroicons/react/outline';

import { Text } from 'components/service';
import * as translations from 'constants/translations';

const CustomerCard = ({ userName }) => (
  <div className="flex justify-between flex-wrap border-b-16 border-gray-100 pb-2">
    <div className="flex">
      <div className="px-4 pt-2">
        <UserIcon className="h-6 w-6" />
      </div>
      <div className="flex flex-col">
        <Text value={translations.STOREFRONT_CUSTOMER} className="text-gray-600 text-xs" />
        <p className="text-sm">{userName}</p>
      </div>
    </div>
    <div className="px-4">
      <ChevronRightIcon className="h-8 w-6 text-gray-400" />
    </div>
  </div>
);

CustomerCard.propTypes = {
  userName: PropTypes.string.isRequired,
};

export default CustomerCard;
