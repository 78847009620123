import React from "react"
import * as R from "ramda"
import { Formik, Form } from "formik"
import { Mutation } from "@apollo/client/react/components"
import { Field } from "components/service"
import { Wrap } from "components/common/onboarding"
import { Input, Select } from "components/form/elements"
import { Label, Container, Row } from "components/form/generic"
import { Countries } from "components/data"
import { Button } from "components/kit"
import SubdomainInput from "./SubdomainInput"
import * as data from "./data"
import * as schemas from "./schemas"
import * as utils from "./utils"

export default () => {
  return (
    <Countries>
      {countries => (
        <Mutation mutation={schemas.ADD_RESTAURANT}>
          {(addRestaurant, { loading }) => (
            <Wrap currentStep={0}>
              <Formik
                validationSchema={data.validationSchema}
                initialValues={data.initialValues}
                onSubmit={(variables, actions) =>
                  addRestaurant({ variables }).catch(
                    R.compose(
                      actions.setErrors,
                      utils.formatErrors
                    )
                  )
                }
              >
                <Form className="w-full flex flex-col m-4 max-w-3xl w-full bg-white shadow-md rounded p-8">
                  <div className="mb-4 pb-3 text-2xl font-medium border-b border-gray-300">
                    Store creation
                  </div>
                  <Container>
                    <Row>
                      <Label htmlFor="titleEn" title="Store name">
                        <Field
                          type="text"
                          name="titleEn"
                          placeholder="Starbucks"
                          component={Input}
                        />
                      </Label>
                      <Label htmlFor="titleAr" title="In arabic">
                        <Field
                          direction="rtl"
                          type="text"
                          name="titleAr"
                          placeholder="اسم المطعم"
                          component={Input}
                        />
                      </Label>
                    </Row>
                    <Row>
                      <Label htmlFor="countryId" title="Country">
                        <Field
                          name="countryId"
                          placeholder="Select country"
                          component={Select}
                        >
                          {countries.map((c, i) => (
                            <option key={i} value={c.id}>
                              {c.titleEn}
                            </option>
                          ))}
                        </Field>
                      </Label>
                      <Label
                        htmlFor="instagram"
                        right={
                          <span className="text-xs bg-gray-200 px-2 rounded-full">
                            optional
                          </span>
                        }
                        title="Instagram"
                      >
                        <Field
                          type="text"
                          name="instagram"
                          placeholder=""
                          component={Input}
                        />
                      </Label>
                    </Row>
                    <Row>
                      <Label htmlFor="subdomain" title="Store URL">
                        <SubdomainInput
                          name="subdomain"
                          placeholder="your-store"
                          domain=".zyda.com"
                        />
                      </Label>
                    </Row>
                    <Row>
                      <Button disabled={loading}>Create</Button>
                    </Row>
                  </Container>
                </Form>
              </Formik>
            </Wrap>
          )}
        </Mutation>
      )}
    </Countries>
  )
}
