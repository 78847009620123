import React, { useContext } from 'react';
import {
  ShoppingBagIcon,
  UsersIcon,
  GridSquare2X2Icon,
  SendIcon,
  SettingsIcon,
  UserIcon,
  CreditCardIcon,
  ArrowUpRightIcon,
  ChartColumnIcon,
  NotificationIcon,
  GiftIcon,
  EyeIcon,
} from '@zydalabs/zac-icons-react';
import { navigate } from '@reach/router';

import { useReplaceParams } from 'hooks/index';
import { ReactComponent as Orderfast } from 'assets/orderfast-icon.svg';
import { context as userContext } from 'context/user';
import * as paths from '../../../../../paths';
import REFER_PROGRAM_LINKS from '../constants';
import { ReactComponent as Verd } from 'assets/verd.svg';
import Store from 'common/components/Store';
import Item from './Item';
import { getStoreURl } from '../utils';
import UserMenu from '../UserMenu';

const MenuIcons = () => {
  const { hasRole, selectedStore, setCollapseNavBar } = useContext(userContext);
  const { countryCode } = selectedStore || {};
  const isManagerOrHigher = hasRole('owner') || hasRole('ops_manager');
  const storeUrl = getStoreURl(selectedStore);
  const replace = useReplaceParams();
  const handleOnClickOnStoreLogo = () => {
    navigate(replace(paths.switchStore, selectedStore.id));
    setCollapseNavBar(false);
  };

  return (
    <>
      <div className="flex flex-col relative layout-side-bar">
        <Store onClick={handleOnClickOnStoreLogo} isMain {...selectedStore} />
        <div className="flex flex-col w-full pt-3 border-gray-300 border-t">
          <Item icon={ShoppingBagIcon} firstLink={paths.allOrders} testId="orders" to={paths.orders} />
          {(hasRole('owner') || hasRole('ops_manager')) && (
            <>
              <Item icon={UsersIcon} firstLink={paths.customers} testId="customers" to={paths.customers} />
              <Item icon={ChartColumnIcon} firstLink={paths.salesAnalytics} testId="analytics" to={paths.analytics} />
              <Item icon={GridSquare2X2Icon} firstLink={paths.menuOverview} testId="menu" to={paths.menu} />
              <Item icon={SendIcon} firstLink={paths.allVouchers} testId="marketing" to={paths.marketing} />
              <Item icon={SettingsIcon} firstLink={paths.businessInformation} testId="setup" to={paths.setup} />
              <Item
                icon={Orderfast}
                firstLink={isManagerOrHigher ? paths.loyalty : paths.links}
                testId="orderfast"
                to={paths.orderfast}
              />
              {hasRole('owner') && <Item icon={UserIcon} firstLink={paths.team} testId="team" to={paths.team} />}
              <Item icon={Verd} firstLink={paths.verd} testId="verd" to={paths.verd} />
              <Item icon={CreditCardIcon} firstLink={paths.billing} testId="billing" to={paths.billing} />
              <div className="pb-4">
                <Item
                  icon={ArrowUpRightIcon}
                  firstLink={paths.salesReports}
                  testId="salesReports"
                  to={paths.salesReports}
                />
              </div>
            </>
          )}
          <div id="beamerUpdatesButton" className="pt-3 border-gray-300 border-t">
            <Item icon={NotificationIcon} />
          </div>
          <Item
            icon={GiftIcon}
            firstLink=""
            testId="help"
            link={REFER_PROGRAM_LINKS[countryCode] || REFER_PROGRAM_LINKS['KW']}
          />
          {storeUrl && <Item icon={EyeIcon} link={storeUrl} firstLink="" testId="visitStore" />}
        </div>
      </div>
      <div className="pt-4 items-center flex flex-col border-gray-300 border-t">
        <UserMenu />
      </div>
    </>
  );
};

export default MenuIcons;
