import { gql } from "@apollo/client"

export default gql`
  query($storeId: String!) {
    countryPlans(restaurantId: $storeId) {
      id
      interval
      price
      monthlyPrice
      discountTextEn
      discountTextAr
    }
  }
`
