import React, { useContext, useEffect } from 'react';
import { Redirect, Location } from '@reach/router';

import { Text } from 'components/service';
import { context as userContext } from 'context/user';
import StoresSwitch from 'components/common/dashboard/Layout/StoresSwitch';
import { CHOOSE_YOUR_STORE_FOR_FACEBOOK } from 'constants/translations';

const FacebookConnect = () => {
  const { stores, setFacebookConnect } = useContext(userContext);

  useEffect(() => {
    setFacebookConnect({ connecting: false, query: '' });
  }, []);

  return (
    <div className="h-screen">
      <Location>
        {({ location }) => {
          const queryToAppend = location.search;
          return stores.length === 1 ? (
            <Redirect noThrow to={`/${stores[0].id}/${queryToAppend}`} />
          ) : (
            <div className="flex justify-center h-full w-full">
              <div className="flex flex-col">
                <Text className="font-semibold text-lg mt-20 mb-4" value={CHOOSE_YOUR_STORE_FOR_FACEBOOK} />
                <StoresSwitch queryToAppend={queryToAppend} />
              </div>
            </div>
          );
        }}
      </Location>
    </div>
  );
};

export default FacebookConnect;
