import { gql } from "@apollo/client"

export const BRANCHES = gql`
  query Branches($restaurantId: String!) {
    branches(restaurantId: $restaurantId) {
      id
      titleEn
      titleAr
      lat
      lng
    }
  }
`
export const DELIVERY_ZONES = gql`
  query deliveryZones($restaurantId: String!, $countryId: Int!) {
    deliveryZones(restaurantId: $restaurantId, countryId: $countryId) {
      id
      countryId
      deliveryTime
      deliveryFee
      minimumOrder
      published
      assignedAreasBasedOnDistance {
        zeroToTenKms
        tenToFifteenKms
        fifteenToMoreKms
      }
      branch {
        id
      }
    }
  }
`

export const REMAINING_COUNTRY_AREAS = gql`
  query remainingAreas(
    $restaurantId: String!
    $countryId: Int!
    $branchId: String
  ) {
    remainingAreas(
      restaurantId: $restaurantId
      countryId: $countryId
      branchId: $branchId
    ) {
      id
      titleEn
      titleAr
      lat
      lng
      cityId
      cityTitleEn
      cityTitleAr
    }
  }
`
