import React, { createContext, useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';

import { BRANCHES } from './schemas';

export const context = createContext();

export const Provider = ({ children, defaultValues }) => {
  const { storeId } = defaultValues;
  const [ocimsValues, setOcimsValues] = useState({
    username: '',
    password: '',
    merchant_id: '',
    external_store_id: '',
    menu_id: '',
    discount_id: '',
    brand_name: '',
  });
  const [ocimsBranches, setOcimsBranches] = useState([]);
  const [branches, setBranches] = useState([]);
  const { data: dataBranches } = useQuery(BRANCHES, {
    variables: { restaurantId: storeId },
  });

  useEffect(() => {
    if (dataBranches?.branches?.length > 0) {
      const tempInitialValues = {};
      const tempFields = [];
      const tempBranches = dataBranches.branches.map(branch => {
        tempInitialValues[branch.id] = branch.id;
        tempFields.push({
          required: false,
          connected: false,
          name: branch.id,
          label: {
            en: branch.titleEn,
            ar: branch.titleAr,
          },
        });
        return {
          id: branch.id,
          externalId: null,
        };
      });
      setBranches([...tempBranches]);
    }
  }, [dataBranches]);

  return (
    <context.Provider
      value={{
        storeId,
        branches,
        setBranches,
        ocimsValues,
        setOcimsValues,
        ocimsBranches,
        setOcimsBranches,
      }}
    >
      {children}
    </context.Provider>
  );
};
