import { times, mergeDeepRight } from 'ramda';
import moment from 'moment';

export const percentDiff = value => (value !== 'N/A' ? value : '');

export const percentDir = value => (value !== 'N/A' && value ? (value[0] === '-' ? '-' : '+') : '');

export const zoomLevelCalculation = (bounds, mapDim) => {
  const WORLD_DIM = { height: 256, width: 256 };
  const ZOOM_MAX = 21;

  const latRad = lat => {
    const sin = Math.sin((lat * Math.PI) / 180);
    const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
    return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
  };

  const zoom = (mapPx, worldPx, fraction) => Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);

  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();

  const latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

  const lngDiff = ne.lng() - sw.lng();
  const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

  const latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
  const lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);
  // -1 is added to ensure that markers on the map show without being cut at a border
  return Math.min(latZoom, lngZoom, ZOOM_MAX) - 1;
};

// Latitude Center in the mid point between North Most Latitude and South Most Latitude
// Longitude Center in the mid point between East Most Longitude and West Most Longitude
export const centerCalculation = (northLatitude, southLatitude, eastLongitude, westLongitude) => {
  const centerLatitude = (northLatitude + southLatitude) / 2;
  const centerLongitude = (eastLongitude + westLongitude) / 2;
  return { lat: centerLatitude, lng: centerLongitude };
};

const colorGradience = (startColor, endColor, numberOfColors) => {
  const diffRed = endColor.red - startColor.red;
  const diffGreen = endColor.green - startColor.green;
  const diffBlue = endColor.blue - startColor.blue;
  const colorList = [];
  const percentFade = 1 / (numberOfColors + 1);
  for (let i = 0; i < numberOfColors; i++) {
    colorList.push(
      `rgba(${Math.floor(diffRed * (percentFade * (i + 1)) + startColor.red)},${Math.floor(
        diffGreen * (percentFade * (i + 1)) + startColor.green,
      )},${Math.floor(diffBlue * (percentFade * (i + 1)) + startColor.blue)},1)`,
    );
  }
  return colorList;
};

export const generateGradient = (main, secondary, dissipating) => {
  const zeroRGBA = 'rgba(255,255,0,0)';
  const mainRGBA = `rgba(${main.red},${main.green},${main.blue},1)`;
  const secondaryRGBA = `rgba(${secondary.red},${secondary.green},${secondary.blue},1)`;
  const dissipatingRGBA = `rgba(${dissipating.red},${dissipating.green},${dissipating.blue},1)`;
  const gradientArray = [
    zeroRGBA,
    dissipatingRGBA,
    secondaryRGBA,
    secondaryRGBA,
    secondaryRGBA,
    secondaryRGBA,
    ...colorGradience(secondary, main, 7),
    mainRGBA,
  ];
  return gradientArray;
};

const difference = (start, end, format, diffType) => moment(end, format).diff(moment(start, format), diffType);

const compareX = format => (a, b) => moment(a.x, format).unix() - moment(b.x, format).unix();

const compareDate = format => (a, b) => moment(a.date, format).unix() - moment(b.date, format).unix();

export const barChartDataTransform = ({ data, startDate, endDate }, type, keys) => {
  let dataTransform = data || {};
  const dataKeys = keys.reduce(
    (obj, item) => ({
      ...obj,
      [item]: 0,
    }),
    {},
  );

  const format = type === 'day' ? 'YYYY-MM-DD' : 'YYYY-MM';
  const diffType = type === 'day' ? 'days' : 'months';
  times(i => {
    const date = moment(startDate, format)
      .add(i, diffType)
      .format(format);

    dataTransform = {
      ...dataTransform,
      [date]: dataTransform[date] ? mergeDeepRight(dataKeys, dataTransform[date] || {}) : dataKeys,
    };
  }, difference(startDate, endDate, format, diffType) + 1);

  return Object.keys(dataTransform)
    .reduce((acc, x) => [...acc, { date: x, ...dataTransform[x] }], [])
    .sort(compareDate(format));
};

export const lineChartDataTransform = ({ data: initialTemp, startDate, endDate }, type, anchor) => {
  if (!startDate || !endDate) return [];

  const initial = initialTemp || {};

  let data = anchor ? {} : initial;

  const format = type === 'day' ? 'YYYY-MM-DD' : 'YYYY-MM';
  const diffType = type === 'day' ? 'days' : 'months';

  times(i => {
    const date = moment(startDate, format)
      .add(i, diffType)
      .format(format);

    if (!anchor) {
      data = { ...data, [date]: data[date] || 0 };
    } else {
      const shiftAmount = moment(anchor.startDate, format).diff(moment(startDate, format), diffType);
      const shiftedDate = moment(date, format)
        .add(shiftAmount, diffType)
        .format(format);

      data = { ...data, [shiftedDate]: initial[date] || 0 };
    }
  }, difference(startDate, endDate, format, diffType) + 1);

  return Object.keys(data)
    .reduce((acc, x) => [...acc, { x, y: +data[x] }], [])
    .sort(compareX(format));
};

export const getSalesData = data => {
  const key = Object.keys(data)[0];
  return data[key];
};

export const generateTickValues = (currentPeriodLength, type) =>
  currentPeriodLength < 13 && type === 'day'
    ? 'every day'
    : currentPeriodLength < 60 && type === 'day'
    ? 5
    : currentPeriodLength < 90 && type === 'day'
    ? 7
    : currentPeriodLength <= 390 && type === 'day'
    ? 'every 1 month'
    : currentPeriodLength > 390 && type === 'day'
    ? 'every 3 month'
    : currentPeriodLength < 13 && type === 'month'
    ? 'every 1 month'
    : currentPeriodLength >= 13 && type === 'month'
    ? 'every 1 year'
    : undefined;

export const selectedPeriodValues = {
  CURRENT_PERIOD: 'currentPeriod',
  PREVIOUS_PERIOD: 'previousPeriod',
  PREVIOUS_YEAR: 'previousYear',
}



