import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

const CheckBox = ({ value, lang, className, onChange, customCheck }) => (
  <div
    onClick={event => {
      event.stopPropagation(), onChange(!value);
    }}
    className={cx('cursor-pointer select-none flex items-center', className)}
  >
    <span
      className={cx(
        lang === 'ar' ? 'ml-3' : 'mr-3',
        'w-4 h-4 p-2 rounded border flex items-center justify-center',
        value
          ? 'border-primary-base bg-primary-base hover:border-gray-300 hover:bg-primary-100 focus:border-gray-300 focus:bg-white active:border-gray-300 active:bg-primary-100'
          : 'border-gray-300 hover:bg-primary-100 active:bg-primary-100',
      )}
    >
      <i className={cx('material-icons text-sm text-white focus:text-primary-base active:text-primary-base')}>
        {customCheck ? 'remove' : 'check'}
      </i>
    </span>
  </div>
);

CheckBox.propTypes = {
  value: PropTypes.bool.isRequired,
  lang: PropTypes.oneOf(['en', 'ar']).isRequired,
  className: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  customCheck: PropTypes.string.isRequired,
};
export default CheckBox;
