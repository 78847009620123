import React from 'react';
import { Typography, useTheme, RadioButtonGroup } from '@zydalabs/zac-react';
import PropTypes from 'prop-types';

import { Text } from 'components/service';
import * as translations from 'constants/translations';

const LanguageSelector = ({ setFieldValue, preferredLanguage }) => {
  const { colors } = useTheme();

  return (
    <div className="border-b border-gray-300 pb-6">
      <Typography variant="heading16">
        <Text value={translations.DEFAULT_LANGUAGE} />
      </Typography>
      <Typography variant="body14" mt={4} color={colors.positive.secondary} mb={24}>
        <Text value={translations.SELECT_LANGUAGE} />
      </Typography>
      <RadioButtonGroup
        name="preferredLanguage"
        value={preferredLanguage}
        onChange={value => setFieldValue('preferredLanguage', value)}
        options={[
          { label: 'English', value: 'english' },
          { label: 'عربي', value: 'arabic' },
        ]}
      />
    </div>
  );
};

LanguageSelector.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  preferredLanguage: PropTypes.oneOf(['english', 'arabic']).isRequired,
};
export default LanguageSelector;
