import { gql } from "@apollo/client"

export const COUNTRIES = gql`
  query {
    countries {
      id
      titleEn
      titleAr
    }
  }
`
