import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { Text } from 'components/service';
import * as translations from 'constants/translations';

import 'react-datetime/css/react-datetime.css';
import * as utils from './utils';
import Time from './Time';
import Days from './Days';

export default ({ name, onChange, value, testId }) => {
  const isEveryDay = utils.isEveryDay(value);
  const [isOpened, setOpened] = useState(!isEveryDay);

  const update = (data, index) =>
    onChange({
      target: {
        name,
        value: value.map((item, i) => (index === i || typeof index === 'undefined' ? { ...item, ...data } : item)),
      },
    });

  useEffect(() => {
    if (!isOpened) {
      const { openAt, closeAt } = value[0];
      update({ openAt, closeAt, offDay: false });
    }
  }, [isOpened]);

  return (
    <div className="border border-gray-300 rounded w-full">
      <div
        className={cx(
          'flex flex-wrap items-center px-3 border-b border-gray-300 py-2 -mb-px cursor-pointer',
          !isEveryDay && 'opacity-25',
        )}
        onClick={() => setOpened(!isOpened)}
      >
        <span className="w-24 mx-3 py-1 md:py-0 text-primary-base flex items-center">
          <Text value={translations.EVERYDAY} className="inline" />
          <Arrow isOpened={isOpened} />
        </span>
        <div className="flex py-1 md:py-0">
          <div className="mx-3">
            <Time
              isDisabled={!isEveryDay}
              title={<Text value={translations.FROM} className="inline" />}
              value={value[0].openAt}
              onChange={openAt => update({ openAt })}
              testId={`${testId}-from-time`}
            />
          </div>
          <div className="mx-4">
            <Time
              isDisabled={!isEveryDay}
              title={<Text value={translations.TO} className="inline" />}
              value={value[0].closeAt}
              onChange={closeAt => update({ closeAt })}
              testId={`${testId}-to-time`}
            />
          </div>
        </div>
      </div>
      {isOpened && <Days items={value} update={update} />}
    </div>
  );
};

const Arrow = ({ isOpened }) => (
  <i className="material-icons mx-1">{!isOpened ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}</i>
);
