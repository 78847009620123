import gql from 'graphql-tag';

export const CAMPAIGNS = gql`
  query($storeId: String!, $status: CampaignState!, $page: Int!, $limit: Int!) {
    campaigns(restaurantId: $storeId, page: $page, limit: $limit, state: $status) {
      campaigns {
        id
        title
        type
        state
        smsBody
        inactivityPeriod
        lookbackWindow
        lookbackWindowInterval
        smsSendingLimit
        sentSmsCount
        startsAt
        expiresAt
        voucherType
        voucherAmount
        voucherRedemptionLimitPerUser
        voucherRedemptionsCount
        generateSmsBody
        targetedSegment
        crmSegmentId
        estimatedCreditPerSms
        stats {
          totalSales
          totalOrders
          totalOrdersWithCampaignVouchers
          totalSalesFromCampaignVouchers
          campaignOrders
          campaignSales
        }
      }
      totalCount
    }
  }
`;

export const UPDATE_CAMPAIGN = gql`
  mutation($storeId: String!, $campaignId: String!, $state: String!) {
    updateCampaign(restaurantId: $storeId, id: $campaignId, state: $state) {
      id
      state
    }
  }
`;
