import React from "react"
import { navigate } from "@reach/router"
import { Text } from "components/service"
import cx from "classnames"
import { useReplaceParams } from "hooks"
import { More, Modal } from "components/kit"
import * as translations from "constants/translations"
import * as paths from "paths.js"

const VerticalEllipsisActionButton = ({
  direction,
  totalCount,
  index,
  customerId,
  phoneNumber,
}) => {
  const replace = useReplaceParams()
  return (
    <div className={cx("items-center -my-2")}>
      <div className={cx(direction === "ltr" ? "ml-2" : "mr-2")}>
        <Modal>
          {({ open, close }) => (
            <More
              modalUp={
                totalCount > 2 &&
                (totalCount - 1 === index || totalCount - 2 === index)
              }
              kind="secondary"
              size="smIcon"
              stopEventPropagation
              items={[
                {
                  title: <Text value={translations.VIEW_PROFILE} />,
                  onClick: () => {
                    navigate(
                      replace(paths.customerProfile, {
                        params: { customerId },
                      }) + `&phoneNumber=${phoneNumber.replace("+", "")}`
                    )
                  },
                },
              ]}
            />
          )}
        </Modal>
      </div>
    </div>
  )
}

export default VerticalEllipsisActionButton
