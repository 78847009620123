import { gql } from '@apollo/client';

export const VALIDATE_OCIMS = gql`
  mutation validateOcimsCredentials($username: String!, $password: String!) {
    validateOcimsCredentials(username: $username, password: $password) {
      valid
    }
  }
`;

export const BRANCHES = gql`
  query Branches($restaurantId: String!) {
    branches(restaurantId: $restaurantId) {
      id
      titleEn
      titleAr
    }
  }
`;

export const OCIMS_CONFIRMATION = gql`
  mutation updateOcimsConfirmation(
    $storeId: String
    $branches: [OcimsBranches]
    $credentials: CredentialType
    $menuId: String
    $externalStoreId: String
    $zydaExternalId: String
    $discountId: String
    $zydaBrandName: String
  ) {
    updateOcimsConfirmation(
      storeId: $storeId
      credentials: $credentials
      branches: $branches
      menuId: $menuId
      externalStoreId: $externalStoreId
      zydaExternalId: $zydaExternalId
      discountId: $discountId
      zydaBrandName: $zydaBrandName
    ) {
      message
    }
  }
`;
