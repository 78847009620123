import React, { useContext, useRef } from "react"
import * as R from "ramda"
import axios from "axios"
import { useQuery, useMutation } from "@apollo/client"
import { navigate } from "@reach/router"
import { parse } from "query-string"
import { uploadProductImage, deleteProductImage } from "rest.js"
import { Text, Link } from "components/service"
import { Layout } from "components/common/dashboard"
import { useReplaceParams } from "hooks"
import { Button, Spinner, Modal } from "components/kit"
import * as translations from "constants/translations"
import { context as notificationsContext } from "context/notifications"
import { context as localeContext } from "context/locale"
import { context as userContext } from "context/user"
import { Formik, Form } from "formik"
import {
  BasicDetails,
  OptionGroups,
  Variants,
  Notes,
  PreparationTime,
  Fulfillment,
  ProductImages,
} from "components/common/product"
import { getBackUrl } from "utils/history"
import * as paths from "paths.js"
import * as schemas from "./schemas"
import * as data from "./data"
import * as utils from "./utils"
import TopPane from "./TopPane"
import cx from "classnames"

export default ({ productId, storeId }) => {
  const replace = useReplaceParams()
  const submitting = useRef()
  const { lang, direction } = useContext(localeContext)
  const { selectedStore: selected } = useContext(userContext)
  const notifications = useContext(notificationsContext)
  const imageTokenSource = axios.CancelToken.source()
  const handleErrorMessage = utils.handleErrors(msg =>
    notifications.show(msg, "error")
  )

  const initial = useQuery(schemas.INITIAL, {
    variables: {
      restaurantId: storeId,
      id: productId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  })

  const variantsInitial = useQuery(schemas.VARIANTS, {
    variables: {
      restaurantId: storeId,
      id: productId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  })

  const [updateProduct] = useMutation(schemas.UPDATE_PRODUCT, {
    onError: handleErrorMessage,
  })
  const { type = "create" } = parse(document.location.search)

  return (
    <Layout>
      <Modal>
        {({ open, close }) =>
          !initial.data ? (
            <Spinner />
          ) : (
            <div
              className={cx(
                "pb-6 flex",
                lang === "ar" && "flex-row-reverse"
              )}
            >
              <div className="w-full" style={{ direction }}>
                <Formik
                  key="product-form"
                  initialValues={R.mergeDeepRight(data.initialValues, {
                    ...initial.data.menuItem,
                    fulfillmentMode: initial.data.menuItem.fulfillmentMode,
                    price: initial.data.menuItem.displayPrice + "",
                    publishIn: initial.data.menuItem.publishedBranchIds,
                    categories: initial.data.menuItem.menuSectionIds || [],
                    storeEn: initial.data.menuItem.variantsTitleEn,
                    storeAr: initial.data.menuItem.variantsTitleAr,
                    externalId: initial.data.menuItem.externalId,
                    specialNotesPlaceholderEn: initial.data.menuItem
                      .specialNotesPlaceholderEn
                      ? initial.data.menuItem.specialNotesPlaceholderEn
                      : "Add Instructions",
                    specialNotesPlaceholderAr: initial.data.menuItem
                      .specialNotesPlaceholderAr
                      ? initial.data.menuItem.specialNotesPlaceholderAr
                      : "أضف تعليمات",
                    allowAttachNotes: initial.data.menuItem.specialNotesEnabled,
                    specialNotesRequired:
                      initial.data.menuItem.specialNotesRequired,
                    enablePrepTime: !initial.data.variants.every(
                      i => i.prepTime < 0
                    ),
                  })}
                  validationSchema={data.validationSchema}
                  onSubmit={async (values, { setErrors, setSubmitting }) => {
                    if (submitting.current) return

                    submitting.current = true

                    if (values.allowAttachNotes) {
                      let obj = {}
                      if (!values.specialNotesPlaceholderAr) {
                        obj.specialNotesPlaceholderAr = (
                          <Text value={translations.NOTE_AR_REQ} />
                        )
                      }
                      if (!values.specialNotesPlaceholderEn) {
                        obj.specialNotesPlaceholderEn = (
                          <Text value={translations.NOTE_EN_REQ} />
                        )
                      }
                      if (!R.isEmpty(obj)) {
                        setErrors(obj)
                        submitting.current = false
                        return setSubmitting(false)
                      }
                    }

                    await updateProduct({
                      variables: {
                        restaurantId: storeId,
                        menuItemId: productId,
                        menuSectionIds: values.categories,
                        publishedBranchIds: values.publishIn,
                        titleEn: values.titleEn,
                        titleAr: values.titleAr,
                        descriptionEn: values.descriptionEn,
                        descriptionAr: values.descriptionAr,
                        externalId: values.externalId,
                        specialNotesEnabled: values.allowAttachNotes,
                        specialNotesRequired:
                          values.allowAttachNotes === true
                            ? values.specialNotesRequired
                            : false,
                        specialNotesPlaceholderAr:
                          values.specialNotesPlaceholderAr,
                        specialNotesPlaceholderEn:
                          values.specialNotesPlaceholderEn,
                        fulfillmentMode: values.fulfillmentMode,
                      },
                    }).then(res => {
                      if (res) {
                        notifications.show(
                          <Text
                            value={translations.PRODUCT_UPDATED}
                            payload={
                              lang === "en"
                                ? res?.data?.updateMenuItem?.titleEn
                                : res?.data?.updateMenuItem?.titleAr
                            }
                          />
                        )
                        type === "create"
                          ? navigate(
                              replace(paths.productsCatalog, {
                                storeId,
                                appendQuery: true,
                              })
                            )
                          : navigate(getBackUrl())
                      }
                    })
                    if (values.photoUrl instanceof File) {
                      await uploadProductImage(
                        storeId,
                        productId,
                        { productImage: values.photoUrl },
                        imageTokenSource.token
                      )
                    }
                    setSubmitting(false)
                    submitting.current = false
                  }}
                >
                  {({ values, setFieldValue, ...rest }) => (
                    <Form className="mt-2 pb-32 md:pb-6" key="product-form">
                      <TopPane
                        pageType={type}
                        storeId={selected.id}
                        isSubmitting={rest.isSubmitting}
                        product={initial.data.menuItem}
                      />
                      <div className="mt-6 mb-4">
                        <BasicDetails
                          pageType={type}
                          isError={rest.errors}
                          isSubmitting={rest.isSubmitting}
                          isDescriptionVisible={
                            !!(values.descriptionEn || values.descriptionAr)
                          }
                          open={open}
                          close={close}
                          setFieldValue={setFieldValue}
                          productImage={values.photoUrl}
                          deleteProductImage={() =>
                            deleteProductImage(
                              storeId,
                              productId,
                              imageTokenSource.token
                            )
                          }
                        />
                      </div>
                      <div className="w-full md:w-2/3" style={{ direction }}>
                        <div className="mb-4">
                          <Variants
                            pageType={type}
                            open={open}
                            closeModal={close}
                            storeId={storeId}
                            productId={productId}
                            variants={variantsInitial?.data?.variants}
                            variantsLoading={variantsInitial?.loading}
                            variantsRefetch={variantsInitial?.refetch}
                            variantsTitleEn={
                              initial.data.menuItem.variantsTitleEn
                            }
                            variantsTitleAr={
                              initial.data.menuItem.variantsTitleAr
                            }
                            productLang={values.productLang}
                            query={schemas.INITIAL}
                            isError={rest.errors}
                            titleEn={values.titleEn}
                            titleAr={values.titleAr}
                            setFieldValue={setFieldValue}
                            updateProduct={updateProduct}
                            setErrorField={rest.setFieldError}
                            isSectionTitleVisible={
                              !!(
                                values.variantsTitleEn || values.variantsTitleAr
                              )
                            }
                          />
                        </div>
                        <div className="mb-4">
                          <ProductImages
                            pageType={type}
                            storeId={storeId}
                            productId={productId}
                            variants={initial.data.variants}
                            query={schemas.INITIAL}
                            open={open}
                            closeModal={close}
                          />
                        </div>
                        <div className="mb-4">
                          <OptionGroups
                            storeId={storeId}
                            variants={initial.data.variants}
                            productId={productId}
                            refetchProduct={initial.refetch}
                            open={open}
                            query={schemas.INITIAL}
                            closeModal={close}
                          />
                        </div>
                        <div className="mb-4">
                          <Fulfillment
                            fulfillmentMode={values.fulfillmentMode}
                            setValueField={setFieldValue}
                          />
                        </div>
                        <div className="mb-4">
                          <PreparationTime
                            pageType={type}
                            variants={initial.data.variants}
                            productId={productId}
                            storeId={storeId}
                            enablePrepTime={values.enablePrepTime}
                            setValueField={setFieldValue}
                          />
                        </div>
                        <div className="mb-4">
                          <Notes
                            pageType={type}
                            isError={rest.errors}
                            isRequired={values.specialNotesRequired}
                            isAllowedToAttach={values.allowAttachNotes}
                            setValueField={setFieldValue}
                            lang={lang}
                          />
                        </div>
                        <div
                          className={cx(
                            "flex flex-row justify-between",
                            lang === "ar" ? "mr-auto" : "ml-auto"
                          )}
                        >
                          <Link
                            data-testid={`${type}-cancel-product`}
                            to={getBackUrl() || paths.productsCatalog}
                            omitQuery
                          >
                            <Button type="button" kind="secondary">
                              <Text value={translations.CANCEL} />
                            </Button>
                          </Link>
                          <Button
                            data-testid={`${type}-save-product`}
                            kind="primary"
                            isSpinning={rest.isSubmitting}
                          >
                            <Text value={translations.SAVE_CHANGE} />
                          </Button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          )
        }
      </Modal>
    </Layout>
  )
}
