export const DELIVERY_ZONE_TEMPLATE_PROPERTIES = {
  CITY_ID: 'city_id',
  AREA_ID: 'area_id',
};

export const DELIVERY_ZONE_GEO_JSON_FEATURE_COLLECTION_TEMPLATE = {
  type: 'FeatureCollection',
  properties: {},
  features: [],
};

export const DELIVERY_ZONE_GEO_JSON_FEATURE_COLLECTION_TEMPLATE_CIRCLE = {
  type: 'FeatureCollection',
  features: [],
};


export const DELIVERY_ZONE_GEO_JSON_FEATURE_POLYGON_TEMPLATE = {
  type: 'Feature',
  id: 'test_create_polygon',
  geometry: {
    type: 'Polygon',
    coordinates: [[]],
  },
  properties: {},
};

export const DELIVERY_ZONE_GEO_JSON_FEATURE_CIRCLE_TEMPLATE = {
  type: 'Feature',
  geometry: {
    type: 'Point',
    coordinates: [],
  },
};
