import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Text } from 'components/service';
import { context as localeContext } from 'context/locale';
import * as translations from 'constants/translations';
import * as CONSTANTS from 'constants/integrations';
import convertMultiLanguagesFromObjectToArray from '../../../utils';

const CourierHeader = ({ title, isBeta, logo, status }) => {
  const { direction } = useContext(localeContext);
  return (
    <div className="flex items-center">
      <img src={logo} alt="Courier" className={cx('w-6', direction === 'rtl' ? 'ml-3' : 'mr-3')} />
      <Text className="font-semibold text-lg" value={convertMultiLanguagesFromObjectToArray(title)} />
      {isBeta && (
        <div className="ml-2 rounded-full uppercase bg-zyda-yellow-300 text-zyda-black-100 text-xs px-2 h-5 flex items-center">
          <Text value={translations.BETA} />
        </div>
      )}
      {status === CONSTANTS.CONNECTED && (
        <div className="ml-2 rounded-full uppercase bg-zyda-green-50 text-green-600 text-xs px-2 h-5 flex items-center">
          <Text value={translations.CONNECTED} />
        </div>
      )}
      {status === CONSTANTS.PARTIALLY_CONNECTED && (
        <div className="ml-2 rounded-full uppercase bg-yellow-100 text-yellow-600 text-xs px-2 h-9 w-24 flex items-center">
          <Text value={translations.PARTIALLY_CONNECTED} />
        </div>
      )}
    </div>
  );
};

CourierHeader.propTypes = {
  title: PropTypes.shape({
    en: PropTypes.string.isRequired,
    ar: PropTypes.string.isRequired,
  }).isRequired,
  isBeta: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
};

export default CourierHeader;
