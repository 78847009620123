import { useEffect } from 'react';

export default (ref, onPressingEscape) => {
  useEffect(() => {
    const handleOnPressingEscape = event => {
      if (ref.current && event.code === 'Escape') {
        onPressingEscape();
      }
    };
    document.addEventListener('keydown', handleOnPressingEscape);

    return () => {
      document.removeEventListener('keydown', handleOnPressingEscape);
    };
  }, []);
};
