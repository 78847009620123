import React from "react"
import cx from "classnames"
import { Label } from "components/form/generic"
import Input from "../Input"
import * as translations from "constants/translations"
import { Text as Span } from "components/service"

export default class extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      lang: props.appLang,
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.appLang !== prevProps.appLang) {
      this.setState({ lang: this.props.appLang })
    }

    if (!this.props.error) return

    if (
      this.props.submitCount > prevProps.submitCount &&
      !this.props.error.en &&
      this.props.error.ar
    ) {
      this.setState({ lang: "ar" })
    }
  }

  render() {
    const {
      name,
      value,
      onChange,
      title,
      subtitle,
      description,
      error,
      testIdSwitchLangEN,
      testIdSwitchLangAR,
      ...props
    } = this.props
    const itemClassName = "py-1 px-3 rounded text-xs"

    const { lang } = this.state

    const change = (key, text) =>
      onChange({
        target: {
          name,
          value: {
            ...value,
            [key]: text,
          },
        },
      })

    return (
      <Label
        title={title}
        subtitle={subtitle}
        description={description}
        right={
          <div className="p-px rounded bg-gray-300 flex cursor-pointer">
            <span
              data-testid={testIdSwitchLangEN}
              className={cx(itemClassName, getBgColor("en", lang, error))}
              onClick={() => this.setState({ lang: "en" })}
            >
              English
            </span>
            <span
              data-testid={testIdSwitchLangAR}
              className={cx(itemClassName, getBgColor("ar", lang, error))}
              onClick={() => this.setState({ lang: "ar" })}
            >
              عربي
            </span>
          </div>
        }
      >
        <Text
          current={lang}
          onChange={change}
          value={value[lang]}
          error={error}
          options={props[lang]}
        />
      </Label>
    )
  }
}

const getBgColor = (lang, selectedLang, error) =>
  lang === selectedLang
    ? "bg-white"
    : error && error[lang] && "bg-zyda-red-500 text-white"

const Text = ({ current, value, onChange, error, options = {} }) => (
  <Input
    direction={current === "ar" ? "rtl" : "ltr"}
    type="text"
    onChange={e => onChange(current, e.target.value)}
    placeholder={options.placeholder}
    value={value}
    error={error && error[current]}
  />
)
