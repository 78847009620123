import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { InfoIcon } from '@zydalabs/zac-icons-react';
import * as translations from 'constants/translations';
import { InfoCheckbox } from 'components/form/elements';
import { Button, Modal } from 'components/kit';
import { Text } from 'components/service';
import { context as localeContext } from 'context/locale';
import { allBranchesId, zoneTypeUtils } from '../../utils';
import ZoneTemplateModal from './ZoneTemplateModal';

const ZoneCreator = ({ zoneController, setZoneController }) => {
  const { direction, translate } = useContext(localeContext);

  const toggleDrawerMode = zoneType => {
    if (zoneType === zoneTypeUtils.POLYGON) {
      setZoneController({
        ...zoneController,
        drawerMode: zoneTypeUtils.POLYGON,
        enableDrawerMode: false,
        circleShape: null,
      });
    }
    if (zoneType === zoneTypeUtils.CIRCLE) {
      setZoneController({
        ...zoneController,
        enableDrawerMode: false,
        drawerMode: zoneTypeUtils.CIRCLE,
        circleShape: {
          id: Date.now(),
          branchId: zoneController.selectedBranch.id,
          fillColor: zoneController.selectedBranch.color,
          type: zoneTypeUtils.CIRCLE,
          radius: 1000,
          center: {
            lat: zoneController.selectedBranch.lat,
            lng: zoneController.selectedBranch.lng,
          },
        },
        isAdd: true,
      });
    }
  };
  return (
    <>
      {!zoneController.showDrawerMode &&
        zoneController.selectedBranch &&
        !zoneController.isEdit &&
        !zoneController.isAdd &&
        !zoneController.showZoneTemplates &&
        !zoneController.isBulkSelected &&
        zoneController.selectedBranch.id !== allBranchesId && (
          <Modal>
            {({ open, close }) => (
              <>
                <Button
                  kind="primary"
                  type="button"
                  className="mt-4 w-full"
                  onClick={e => {
                    e.preventDefault();
                    open({
                      size: 'max-w-2xl',
                      title: <Text value={translations.WOULD_YOU_LIKE_ADD_ZONE} />,
                      body: (
                        <ZoneTemplateModal
                          close={close}
                          zoneController={zoneController}
                          setZoneController={setZoneController}
                        />
                      ),
                    });
                  }}
                >
                  <Text value={translations.START_ADDING_ZONE} />
                </Button>
              </>
            )}
          </Modal>
        )}

      {zoneController.showDrawerMode && (
        <div className="border-t border-gray-300 mt-4 pt-4">
          <div className="flex items-center bg-gray-300 p-1">
            <div
              className={cx(
                zoneController.drawerMode === zoneTypeUtils.POLYGON && 'bg-white',
                'p-2 w-1/2 text-center text-xs cursor-pointer',
              )}
              onClick={() => toggleDrawerMode(zoneTypeUtils.POLYGON)}
              aria-hidden="true"
            >
              <Text value={translations.DRAW_SHAPE} />
            </div>

            <div
              className={cx(
                zoneController.drawerMode === zoneTypeUtils.CIRCLE && 'bg-white',
                'p-2 w-1/2 text-center text-xs cursor-pointer',
              )}
              onClick={() => toggleDrawerMode(zoneTypeUtils.CIRCLE)}
              aria-hidden="true"
            >
              <Text value={translations.USE_CIRCLE} />
            </div>
          </div>

          {zoneController.drawerMode === zoneTypeUtils.POLYGON && (
            <div
              className="py-2 px-3 mt-3 border border-primary-base bg-primary-25 w-full flex items-start"
              style={{ direction }}
            >
              <InfoIcon
                width="16"
                color="#196EFF"
                className={cx(direction === 'rtl' ? 'ml-2' : 'mr-2', 'flex-shrink-0')}
              />
              <span className="text-xs text-primary-base">{translate(translations.DRAW_SHAPE_TOOLTIP)}</span>
            </div>
          )}

          {zoneController.drawerMode === zoneTypeUtils.CIRCLE && (
            <div
              className="py-2 px-3 mt-3 border border-primary-base bg-primary-25 w-full flex items-start"
              style={{ direction }}
            >
              <InfoIcon
                width="16"
                color="#196EFF"
                className={cx(direction === 'rtl' ? 'ml-2' : 'mr-2', 'flex-shrink-0')}
              />
              <span className="text-xs text-primary-base">{translate(translations.DRAW_CIRCLE_TOOLTIP)}</span>
            </div>
          )}
        </div>
      )}

      {zoneController.selectedBranch && zoneController.selectedBranch.id !== allBranchesId && (
        <div className="mt-5">
          <InfoCheckbox
            toggle
            zoneToggle
            onChange={() => setZoneController(prevState => ({ ...prevState, hideZones: !zoneController.hideZones }))}
            value={zoneController.hideZones}
            title={<Text className="text-xs" value={translations.HIDE_OTHER_ZONES} />}
          />
        </div>
      )}
    </>
  );
};

ZoneCreator.propTypes = {
  zoneController: PropTypes.shape({
    enableDrawerMode: PropTypes.bool,
    showDrawerMode: PropTypes.bool,
    isAdd: PropTypes.bool,
    isEdit: PropTypes.bool,
    drawerMode: PropTypes.string,
    showZoneTemplates: PropTypes.bool,
    selectedBranch: PropTypes.shape({
      id: PropTypes.number,
      color: PropTypes.string,
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
    hideZones: PropTypes.bool,
    isBulkSelected: PropTypes.bool,
  }).isRequired,
  setZoneController: PropTypes.func.isRequired,
};

export default ZoneCreator;
