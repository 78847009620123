import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import BackgroundIphone from 'assets/phone-mockup.svg';
import { context as localeContext } from 'context/locale';
import CampaignTestSms from './CampaignTestSms';
import SenderId from '../SenderId';

const PhoneMockup = ({ values, generatedSmsBody }) => {
  const { direction, lang } = useContext(localeContext);

  return (
    <div
      style={{
        backgroundImage: `url(${BackgroundIphone})`,
        backgroundPosition: 'top',
        backgroundSize: 'cover',
        width: '300px',
        height: '380px',
      }}
    >
      <div className="flex flex-col items-center justify-center w-full h-full">
        <div
          className="flex items-center justify-center w-full"
          style={{
            paddingTop: '55px',
          }}
        >
          <div className={cx('flex items-center', lang === 'ar' ? 'ml-3' : 'mr-3')}>
            <SenderId placement="left" />
          </div>
        </div>
        <div
          className="flex-auto w-full flex flex-col items-center justify-between"
          style={{
            padding: '40px 48px 16px 48px',
          }}
        >
          <div
            className="overflow-y-auto overflow-x-hidden"
            style={{
              maxHeight: '180px',
            }}
          >
            {generatedSmsBody && (
              <p className="p-3 text-xs rounded-tr-lg rounded-tl-lg rounded-br-lg bg-blue-100" dir={direction}>
                {generatedSmsBody}
              </p>
            )}
          </div>
          <CampaignTestSms values={values} />
        </div>
      </div>
    </div>
  );
};

PhoneMockup.propTypes = {
  generatedSmsBody: PropTypes.string,
  values: PropTypes.shape({
    restaurantId: PropTypes.string,
  }),
};

export default PhoneMockup;
