import React, { useEffect } from "react"
import { useGtm } from "hooks/index"
import Head from "./Head"

export default ({ name, component: Component, ...props }) => {
  const { pageView } = useGtm()

  useEffect(() => pageView(name && name[0]), [])

  return (
    <Head name={name}>
      <Component {...props} />
    </Head>
  )
}
