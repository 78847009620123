import { gql } from '@apollo/client';

const CUSTOMERS = gql`
  query(
    $storeId: String!
    $page: Int!
    $limit: Int!
    $segment: Int
    $phoneNumber: String
    $userName: String
    $field: String
    $order: String
  ) {
    customers(
      restaurantId: $storeId
      page: $page
      limit: $limit
      segment: $segment
      phoneNumber: $phoneNumber
      userName: $userName
      sorter: { order: $order, field: $field }
    ) {
      customers {
        id
        userId
        userName
        phoneNumber
        firstOrder
        lastOrder
        totalOrders
        totalSales
        segmentId
      }
      totalCount
      totalPages
    }
  }
`;

export default CUSTOMERS;
