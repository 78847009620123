import React, { useContext } from "react"
import { CreationBox, Modal } from "components/kit"
import { Text } from "components/service"
import * as translations from "constants/translations"
import { context as localeContext } from "context/locale"
import OptionsModel from "../OptionsModel/index"
import { ReactComponent as EmptyOptionsDesktop } from "assets/empty-options.svg"
import { ReactComponent as EmptyOptionsMobile } from "assets/empty-options-mob.svg"
import { useMobile } from "hooks"

export default ({
  initialValues,
  refetchAllGroup,
  refetchAllOptions,
  refetchOptions,
  setOptionGroupSelected,
}) => {
  const { direction } = useContext(localeContext)
  const isMobile = useMobile()

  return (
    <Modal>
      {({ open, close }) => (
        <div className="flex flex-col mx-auto max-w-sm items-center py-4">
          {isMobile ? (
            <EmptyOptionsMobile className="w-full" />
          ) : (
            <EmptyOptionsDesktop className="w-full" />
          )}
          <CreationBox
            title={<Text value={translations.CREACTE_PRODUCT_OPTIONS} />}
            description={
              <Text value={translations.CREACTE_PRODUCT_OPTIONS_DESC} />
            }
            actions={
              <button
                data-testid="add-option-btn"
                className="text-primary-base"
                style={{
                  direction,
                }}
                onClick={() => {
                  open({
                    title: (
                      <Text
                        value={translations.OPTION_GROUPS_EDIT_GROUP_MODAL}
                        className="text-lg"
                      />
                    ),
                    body: (
                      <OptionsModel
                        type="add"
                        initialValues={initialValues}
                        onCancel={close}
                        openModel={open}
                        refetchAllOptions={refetchAllOptions}
                        refetchAllGroup={refetchAllGroup}
                        refetchOptions={refetchOptions}
                        setOptionGroupSelected={setOptionGroupSelected}
                      />
                    ),
                  })
                }}
                type="button"
              >
                <Text
                  value={translations.ADD_OPTION_EMPTY}
                  className="font-semibold"
                />
              </button>
            }
          />
        </div>
      )}
    </Modal>
  )
}
