import { gql } from 'graphql-request';

const citiesListQuery = gql`
  query($countryId: ID!) {
    cities(countryId: $countryId) {
      id
      code
      titleEn
      titleAr
      lonlat
    }
  }
`;

export default citiesListQuery;
