import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { DropDown } from 'components/kit';

const OptiongroupDesktopFrame = ({
  emptyGroups,
  query,
  setQuery,
  isSingleBranch,
  branchesData,
  lang,
  statusData,
  prodcutLang,
  setLangSelected,
  langSelected,
}) =>
  !emptyGroups && (
    <div className={cx('flex flex-wrap justify-between', lang === 'ar' && 'flex-row-reverse')}>
      <div className={cx('flex flex-wrap flex-col md:flex-row', lang === 'ar' && 'flex-row-reverse')}>
        <div className={cx('flex-2 my-2', lang === 'en' ? 'mr-4' : 'ml-4')}>
          <DropDown
            float={'left'}
            label={<Text value={isSingleBranch ? translations.BRANCH : translations.BUSINESS_LOCATION} />}
            optionSelected={query.location}
            onSelect={option => setQuery({ location: option })}
            data={branchesData}
            icon="keyboard_arrow_down"
            position={lang === 'ar' ? 'right' : 'left'}
            scrollable
            testId_openDropDown="list-optionGroups-select-business-location"
            testId_selectedData="list-optionGroups-business-location-name"
            testId_listData="list-optionGroups-business-location-list"
            labelClassNames="text-gray-700"
            isDisabled={isSingleBranch}
          />
        </div>

        <div className={cx('flex-2 my-2', lang === 'en' ? 'mr-4' : 'ml-4')}>
          <DropDown
            float={'left'}
            label={<Text value={translations.OPTION_GROUPS_STATUS} />}
            optionSelected={query.status}
            onSelect={option => setQuery({ status: option })}
            data={statusData}
            icon="keyboard_arrow_down"
            position={lang === 'ar' ? 'right' : 'left'}
            scrollable
            testId_openDropDown="list-optionGroups-select-status"
            testId_selectedData="list-optionGroups-status-name"
            testId_listData="list-optionGroups-status-list"
            labelClassNames="text-gray-700"
          />
        </div>
      </div>
      <div className={cx('flex-2 my-2', lang === 'en' ? 'mr-4' : 'ml-4')}>
        <DropDown
          float={lang === 'ar' ? 'left' : 'right'}
          label={<Text value={translations.VIEW_IN} />}
          responsiveLabel
          full
          optionSelected={langSelected}
          onSelect={prodcutLang => setLangSelected(prodcutLang)}
          data={prodcutLang}
          icon="keyboard_arrow_down"
          testId_openDropDown="list-optionGroups-select-view-lang"
          testId_selectedData="list-optionGroups-view-lang-name"
          testId_listData="list-optionGroups-view-lang-list"
          labelClassNames="text-gray-700"
        />
      </div>
    </div>
  );

OptiongroupDesktopFrame.propTypes = {
  emptyGroups: PropTypes.bool.isRequired,
  query: PropTypes.element.isRequired,
  setQuery: PropTypes.func.isRequired,
  isSingleBranch: PropTypes.bool.isRequired,
  branchesData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ),
  lang: PropTypes.string.isRequired,
  statusData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ),
  prodcutLang: PropTypes.string.isRequired,
  setLangSelected: PropTypes.func.isRequired,
  langSelected: PropTypes.string.isRequired,
};

export default OptiongroupDesktopFrame;
