import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import SalesSection from './SalesSection';

const SalesTab = ({ title, value = '0', percent, dir, isSelected, onClick }) => {
  const handleKeyDown = ev => {
    if (ev.keyCode === 13) {
      onClick();
    }
  };
  return (
    <div
      onKeyDown={handleKeyDown}
      role="button"
      className={cx(
        'px-4 py-5 bg-white border-b-2 cursor-pointer',
        isSelected ? 'border-primary-base bg-primary-25' : 'border-transparent',
      )}
      onClick={onClick}
      tabIndex={0}
    >
      <SalesSection title={title} value={value} percent={percent} dir={dir} isSelected={isSelected} />
    </div>
  );
};

SalesTab.propTypes = {
  title: PropTypes.element.isRequired,
  value: PropTypes.string,
  percent: PropTypes.string.isRequired,
  dir: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SalesTab;
