import { gql } from "@apollo/client"

export const PERFORM_PASSWORD_RESET = gql`
  mutation performPasswordReset(
    $resetToken: String!
    $email: String!
    $password: String!
    $passwordConfirmation: String!
  ) {
    performPasswordReset(
      resetToken: $resetToken
      email: $email
      password: $password
      passwordConfirmation: $passwordConfirmation
    ) {
      name
      email
    }
  }
`
