import React, { useContext } from 'react';
import { navigate } from '@reach/router';
import { useMutation } from '@apollo/client';

import { Button, Stack } from 'components/kit';
import * as translations from 'constants/translations';
import { replaceParams } from 'utils/index';
import { Text } from 'components/service';
import * as paths from 'paths.js';
import * as schemas from './schemas';
import { context as userContext } from 'context/user';
import { context as notificationsContext } from 'context/notifications';

export default ({ id, storeId, onClose }) => {
  const notifications = useContext(notificationsContext);
  const { setDzmsSelectedBranch } = useContext(userContext);
  const [deleteBranch, { loading: isDeleting }] = useMutation(schemas.DELETE_BRANCH, {
    onCompleted: () => {
      navigate(replaceParams(paths.branchList, { storeId })),
      notifications.show(<Text value={translations.BUSINESS_LOCATION_DELETE_NOTIFICATION} />);
      setDzmsSelectedBranch(null);
    },
    onError: () => notifications.show(<Text value={translations.SOMETHING_WENT_WRONG} />, 'error'),
  });

  return (
    <div className="px-4">
      <Text value={translations.BUSINESS_LOCATION_DELETE_CONFIRMATION} className="mb-6" />
      <Stack direction="row-reverse" expandItem={false} spacing={2}>
        <Button
          isSpinning={isDeleting}
          onClick={() =>
            deleteBranch({
              variables: {
                id,
                storeId,
              },
            })
          }
          type="button"
          kind="secondaryError"
        >
          <Text value={translations.DELETE} />
        </Button>
        <Button onClick={onClose} type="button" kind="tertiary">
          <Text value={translations.DISMISS} />
        </Button>
      </Stack>
    </div>
  );
};
