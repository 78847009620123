import React, { useContext } from "react"
import { path } from "ramda"
import { navigate } from "@reach/router"
import { Formik } from "formik"
import { context as notificationsContext } from "context/notifications"
import { Text } from "components/service"
import { validationSchema } from "components/common/team/UserForm/data"
import { useSelectedStore, useReplaceParams } from "hooks/index"
import * as translations from "constants/translations"
import * as paths from "paths.js"

export default ({
  initialValues = { role: "owner", branches: [] },
  children,
  onSubmit,
}) => {
  const replace = useReplaceParams()
  const notifications = useContext(notificationsContext)
  const selectedStoreId = useSelectedStore()

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async data => {
        if (
          data.role === "operator" &&
          (!data.branches || data.branches.length === 0)
        ) {
          return notifications.show(
            <Text value={translations.OPERATOR_BRANCHES_REQ} />,
            "error"
          )
        }

        if (!data.name.trim()) {
          return notifications.show(
            <Text value={translations.NAME_SHOULD_CONTAIN_LETTERS} />,
            "error"
          )
        }

        const roles =
          data.role !== "operator"
            ? [
                {
                  resourceId: selectedStoreId,
                  resourceType: "Restaurant",
                  name: data.role,
                },
              ]
            : data.branches.map(branchId => ({
                resourceId: branchId,
                resourceType: "Branch",
                name: data.role,
              }))

        const variables = {
          name: data.name,
          email: data.email,
          phoneNumber: '+' + data.phoneNumber,
          roles,
        }

        try {
          await onSubmit(variables)
          navigate(replace(paths.team))
        } catch (err) {
          const errors = path(
            ["graphQLErrors", 0, "extensions", "exception", "body"],
            err
          )

          if (errors.phone_number) {
            notifications.show(
              <Text value={translations.PHONE_IS_INVALID} />,
              "error"
            )
          }
        }
      }}
    >
      {children}
    </Formik>
  )
}
