import { gql } from '@apollo/client';

export const CREATE_CAMPAIGN = gql`
  mutation(
    $restaurantId: String!
    $title: String!
    $smsBody: String!
    $type: CampaignType!
    $state: String!
    $inactivityPeriod: Int
    $lookbackWindow: String
    $smsSendingLimit: Int
    $targetedSegment: Int
    $startsAt: String!
    $expiresAt: String
    $voucherType: VoucherType
    $voucherAmount: Float
    $voucherRedemptionLimitPerUser: Int
    $voucherMinSubtotalAmount: Float
    $voucherValidFor: Int
    $campaignGoal: String
    $campaignLanguage: String!
    $uploadedFileName: String
  ) {
    addCampaign(
      restaurantId: $restaurantId
      title: $title
      smsBody: $smsBody
      type: $type
      state: $state
      inactivityPeriod: $inactivityPeriod
      lookbackWindow: $lookbackWindow
      smsSendingLimit: $smsSendingLimit
      targetedSegment: $targetedSegment
      startsAt: $startsAt
      expiresAt: $expiresAt
      voucherType: $voucherType
      voucherAmount: $voucherAmount
      voucherRedemptionLimitPerUser: $voucherRedemptionLimitPerUser
      voucherMinSubtotalAmount: $voucherMinSubtotalAmount
      voucherValidFor: $voucherValidFor
      campaignGoal: $campaignGoal
      campaignLanguage: $campaignLanguage
      uploadedFileName: $uploadedFileName
    ) {
      id
    }
  }
`;

export const CAMPAIGN_PREVIEW = gql`
  mutation(
    $restaurantId: String!
    $title: String!
    $state: String!
    $campaignLanguage: String!
    $smsBody: String!
    $type: CampaignType!
    $inactivityPeriod: Int
    $lookbackWindow: String
    $smsSendingLimit: Int
    $startsAt: String!
    $expiresAt: String
    $voucherType: VoucherType
    $voucherAmount: Float
    $voucherRedemptionLimitPerUser: Int
    $voucherMinSubtotalAmount: Float
    $voucherValidFor: Int
    $targetedSegment: Int
    $campaignGoal: String
    $uploadedFileName: String
  ) {
    previewCampaign(
      restaurantId: $restaurantId
      title: $title
      state: $state
      campaignLanguage: $campaignLanguage
      smsBody: $smsBody
      type: $type
      inactivityPeriod: $inactivityPeriod
      lookbackWindow: $lookbackWindow
      smsSendingLimit: $smsSendingLimit
      startsAt: $startsAt
      expiresAt: $expiresAt
      voucherType: $voucherType
      voucherAmount: $voucherAmount
      voucherRedemptionLimitPerUser: $voucherRedemptionLimitPerUser
      voucherMinSubtotalAmount: $voucherMinSubtotalAmount
      voucherValidFor: $voucherValidFor
      targetedSegment: $targetedSegment
      campaignGoal: $campaignGoal
      uploadedFileName: $uploadedFileName
    ) {
      type
      title
      state
      smsBody
      inactivityPeriod
      lookbackWindow
      lookbackWindowInterval
      smsSendingLimit
      startsAt
      expiresAt
      voucherType
      voucherAmount
      voucherRedemptionLimitPerUser
      voucherValidFor
      voucherMinSubtotalAmount
      sentSmsCount
      estimateReach
      campaignGoal
      campaignLanguage
      generateSmsBody
      restaurantSenderId
    }
  }
`;

export const CAMPAIGN_GOALS = gql`
  query($storeId: String!, $campaignType: String) {
    campaignGoals(restaurantId: $storeId, campaignType: $campaignType) {
      campaignGoals {
        id
        titleEn
        titleAr
        bodyEn
        bodyAr
        voucherMinSubtotalAmount
        voucherRedemptionLimitPerUser
        voucherValidUntil
        voucherAmount
        voucherType
        recommendedCrmSegmentId
      }
      storefrontDefaultLanguage
    }
  }
`;

export const ESTIMATE_REACH = gql`
  mutation($storeId: String!, $type: String!, $inactivityPeriod: Int!, $lookbackWindow: String!) {
    estimateReach(
      restaurantId: $storeId
      type: $type
      inactivityPeriod: $inactivityPeriod
      lookbackWindow: $lookbackWindow
    ) {
      estimateReach
    }
  }
`;

export const SEND_TEST_SMS = gql`
  mutation(
    $restaurantId: String!
    $title: String!
    $smsBody: String!
    $type: CampaignType!
    $state: String!
    $inactivityPeriod: Int
    $lookbackWindow: String
    $smsSendingLimit: Int
    $targetedSegment: Int
    $startsAt: String!
    $expiresAt: String
    $voucherType: VoucherType
    $voucherAmount: Float
    $voucherRedemptionLimitPerUser: Int
    $voucherMinSubtotalAmount: Float
    $voucherValidFor: Int
    $campaignGoal: String
    $campaignLanguage: String!
    $testPhoneNumber: String
  ) {
    testCampaignSms(
      restaurantId: $restaurantId
      title: $title
      smsBody: $smsBody
      type: $type
      state: $state
      inactivityPeriod: $inactivityPeriod
      lookbackWindow: $lookbackWindow
      smsSendingLimit: $smsSendingLimit
      targetedSegment: $targetedSegment
      startsAt: $startsAt
      expiresAt: $expiresAt
      voucherType: $voucherType
      voucherAmount: $voucherAmount
      voucherRedemptionLimitPerUser: $voucherRedemptionLimitPerUser
      voucherMinSubtotalAmount: $voucherMinSubtotalAmount
      voucherValidFor: $voucherValidFor
      campaignGoal: $campaignGoal
      campaignLanguage: $campaignLanguage
      testPhoneNumber: $testPhoneNumber
    ) {
      valid
      message
    }
  }
`;

export const REMAINING_CAMPAIGN = gql`
  query($storeId: String!) {
    remainingCampaign(restaurantId: $storeId)
  }
`;
