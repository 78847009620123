import React, { useState, useContext } from 'react';
import { createGlobalStyle } from 'styled-components';
import PhoneInput from 'react-phone-input-2';
import cx from 'classnames';
import 'react-phone-input-2/lib/style.css';
import ar from 'react-phone-input-2/lang/ar.json';

import { context as userContext } from 'context/user';
import { context as localeContext } from 'context/locale';
import { Error } from 'components/form/generic';

export default ({
  error,
  label,
  icon = '',
  name,
  value,
  noBorder,
  height = 'h-10',
  onChange,
  testId,
  disabled,
  ...props
}) => {
  const [isFocused, setFocused] = useState(false);
  const { lang } = useContext(localeContext);
  const direction = lang === 'ar' ? 'rtl' : 'ltr';
  const { selectedStore } = useContext(userContext);

  return (
    <>
      <div className="flex flex-col w-full">
        <div
          className={cx(
            'flex-1 flex items-center h-full rounded',
            noBorder ? '' : 'border',
            error ? 'border-zyda-red-500 bg-zyda-red-50' : isFocused ? 'border-primary-200' : 'border-zyda-gray-100',
          )}
        >
          <PhoneInput
            style={{ direction }}
            key={lang}
            disabled={disabled}
            country={selectedStore.countryCode?.toLowerCase()}
            localization={lang === 'ar' && ar}
            inputProps={{
              style: { direction: 'ltr' },
              name,
              className: cx(
                'bg-transparent text-zyda-black-100 appearance-none rounded-l w-full text-gray-700 leading-tight focus:outline-none',
                height,
                lang === 'ar' ? 'pr-16 pl-2' : 'pl-12 pr-2',
              ),
              'data-testid': testId,
            }}
            name={name}
            value={value}
            onChange={value =>
              onChange &&
              onChange({
                target: {
                  name,
                  value: value.replace(/\s/g, ''),
                },
              })
            }
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            {...props}
          />
        </div>
        {error && <Error direction={direction}>{error}</Error>}
      </div>
      <Style direction={direction} />
    </>
  );
};

const Style = createGlobalStyle`
  .react-tel-input {
    width: 100%;
    .flag-dropdown {
      border: 0 !important;
      &:hover .selected-flag {
        background-color: #f5f5f5 !important;
      }
    }
    ${({ direction }) =>
      direction === 'rtl' &&
      `
      .flag-dropdown .selected-flag {
        padding-left: 50px;
        .flag {
          left: 8px;
        }
      }
      .flag-dropdown .country-list .flag {
        margin-right: 0;
        margin-left: 8px;
      }
    `}
  }
`;
