import React, { useContext } from 'react';

import { context as userContext } from 'context/user';
import { context as localeContext } from 'context/locale';
import Iframe from 'components/common/webview';
import { storefrontOrderMode } from './utils';

const StorefrontWebView = ({ selectedStorefrontUser }) => {
  const { userToken, userCartId } = selectedStorefrontUser || {};
  const { lang } = useContext(localeContext);
  const { selectedStore, branches, user } = useContext(userContext);
  const oderMode = storefrontOrderMode(branches);
  const deployedSubdomain = selectedStore.subdomain;
  const manualOrderSubdomain = process.env.REACT_APP_MANUAL_ORDER_URL;
  const urlForActiveUser = !userCartId ? `/order-mode?mode=${oderMode}` : '/?';
  const referenceCartId = userCartId ? `&referenceId=${userCartId}` : '';
  const IframeURL = `${manualOrderSubdomain}/${lang}/${deployedSubdomain}/${urlForActiveUser}&token=${userToken}&operatorId=${user.id}${referenceCartId}`;
  return <Iframe src={IframeURL} width="100%" height="100%" />;
};

export default StorefrontWebView;
