import React, { useContext } from "react"
import { Button, Stack, Modal } from "components/kit"
import { context as localeContext } from "context/locale"
import * as translations from "constants/translations"
import { breadcrumbs } from "constants/translations"
import { Breadcrumbs } from "components/common/dashboard"
import { Text } from "components/service"
import * as paths from "paths.js"
import { useMobile } from "hooks/index"
import cx from "classnames"
import DeleteModal from "./DeleteModal"

export default ({ pageType, product, storeId, isSubmitting }) => {
  const isMobile = useMobile()
  const { lang, direction } = useContext(localeContext)
  return (
    <Modal>
      {({ open, close }) => (
        <div
          className={cx(
            "flex flex-wrap items-center md:w-2/3", isMobile && "py-6"
          )}
        >
          <Breadcrumbs
            links={[
              paths.products,
              paths.productsCatalog,
              paths.advancedProductCreation,
            ]}
            path={
              pageType === "edit"
                ? breadcrumbs.SETUP_PRODUCT_EDITION
                : breadcrumbs.SETUP_PRODUCT_CREATION
            }
          />
          <Stack
            className={cx(lang === "ar" ? "mr-auto" : "ml-auto")}
            direction="wrap"
            expandItem={false}
            spacing="2"
          >
            <Button
              kind="secondaryError"
              data-testid="edit-delete-product"
              isSpinning={isSubmitting}
              onClick={(e) => {
                e.preventDefault()
                open({
                  testId: "detele-product-modal",
                  title: <Text value={product} />,
                  body: (
                    <DeleteModal
                      id={product.id}
                      storeId={storeId}
                      onClose={close}
                    />
                  ),
                  size: "max-w-md",
                })
              }}
            >
              <Text value={translations.DELETE_PRODUCT} />
            </Button>
          </Stack>
        </div>
      )}
    </Modal>
  )
}
