import React from "react"
import { Stack } from "components/kit"

export default ({ title, description, actions, emptyState }) => (
  <div className="text-center w-full flex flex-col items-center justify-center py-2">
    <p className="font-semibold text-xl mb-2">{title}</p>
    <p className="text-gray-600 mb-4">{description}</p>
    {emptyState ? actions  : <Stack direction="row">{actions}</Stack>}
  </div>
)
