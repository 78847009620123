import React from "react"
import moment from "moment"
import { Text } from "components/service"
import * as translations from "constants/translations"
import { DropDown, DatePicker } from "components/kit"
import {
  stringifyRange,
  formatDate,
  dateQueryFormat,
  getRange,
} from "utils/date"
import DATE_RANGES from "constants/date"

const DateRange = ({
  queryTypeDate,
  range,
  selectedStoreCreatedAt,
  notifications,
  setQuery,
  direction,
  resettableStates = {},
}) => {
  return (
    <div>
      <DropDown
        dropdownClassName="w-64"
        noOverflow
        label={<Text value={translations.DATE} />}
        optionSelected={queryTypeDate}
        onSelect={option => {
          setQuery({
            type_date: option,
            ...resettableStates,
            ...(option !== DATE_RANGES.CUSTOM && {
              range: stringifyRange(getRange(option, selectedStoreCreatedAt)),
            }),
          })
        }}
        data={[
          {
            id: DATE_RANGES.TODAY,
            title: <Text value={translations.TODAY} />,
          },
          {
            id: DATE_RANGES.SEVEN_DAYS,
            title: <Text value={translations.LAST_7_DAYS} />,
          },
          {
            id: DATE_RANGES.THIRTY_DAYS,
            title: <Text value={translations.LAST_30_DAYS} />,
          },
          {
            id: DATE_RANGES.NINETY_DAYS,
            title: <Text value={translations.LAST_90_DAYS} />,
          },
          {
            id: DATE_RANGES.ALL_TIME,
            title: <Text value={translations.ALL_TIME} />,
          },
          {
            id: DATE_RANGES.CUSTOM,
            title: `${formatDate(range[0])} - ${formatDate(range[1])}`,
            body: close => (
              <DatePicker
                range={range.map(date =>
                  moment(date, dateQueryFormat).toDate()
                )}
                minDate={new Date(selectedStoreCreatedAt)}
                maxDate={new Date()}
                onChange={range => {
                  const error = validateRange(range, selectedStoreCreatedAt)

                  if (error) {
                    return notifications.show(error, "error")
                  }

                  return (
                    !close() &&
                    setQuery({
                      type_date: DATE_RANGES.CUSTOM,
                      range: stringifyRange(
                        range.map(date => moment(date).format(dateQueryFormat))
                      ),
                    })
                  )
                }}
              />
            ),
          },
        ]}
        icon="keyboard_arrow_down"
        position={
          direction === "rtl"
            ? "md:left-0 left-auto md:right-auto right"
            : "md:right-0 right-auto md:left-auto left"
        }
      />
    </div>
  )
}

const validateRange = (range, storeCreation) => {
  const startDate = moment(range[0]).format(dateQueryFormat)
  const endDate = moment(range[1]).format(dateQueryFormat)
  const storeCreationDate = moment(storeCreation).format(dateQueryFormat)

  if (moment(startDate).isBefore(moment(storeCreationDate))) {
    return <Text value={translations.ANALYTICS_DATE_BEFORE_CREATION} />
  }

  if (moment(endDate).isAfter(moment())) {
    return <Text value={translations.ANALYTICS_DATE_FUTURE} />
  }

  if (moment(startDate).isAfter(moment(endDate))) {
    return <Text value={translations.DATES_CONFLICT} />
  }
}

export default DateRange
