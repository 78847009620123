import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import * as translations from 'constants/translations';
import { useSelectedStore } from 'hooks';
import { Text } from 'components/service';
import { Button } from 'components/kit';
import { Row } from 'components/form/generic';
import { context as notificationsContext } from 'context/notifications';
import { context as userContext } from 'context/user';
import { useDeleteDeliveryZone } from 'service/hooks';
import { trackDeliveryZoneEvents } from '../../utils';

const DeleteZone = ({ onClose, selectedZoneId, setTableViewController }) => {
  const notifications = useContext(notificationsContext);
  const selectedStoreId = useSelectedStore();
  const { user } = useContext(userContext);

  const deleteDeliveryZone = useDeleteDeliveryZone();
  return (
    <>
      <div className="flex flex-col items-center">
        <div className="px-4 mb-4">
          <Text value={translations.ARE_YOU_SURE_TO_DELETE_ZONE} />
        </div>
        <Row>
          <Button onClick={onClose} type="button" kind="tertiary">
            <Text value={translations.DISMISS} />
          </Button>
          <Button
            onClick={() => {
              const handleDelete = async () => {
                const response = await deleteDeliveryZone({ deliveryZoneId: selectedZoneId });
                if (response.hasError) {
                  notifications.show(<Text value={translations.DELIVERY_ZONE_NOT_FOUND} />, 'error');
                } else {
                  setTableViewController(prevState => ({ ...prevState, selectedZoneId }));
                  notifications.show(<Text value={translations.DELIVERY_ZONE_DELETED} />);
                  trackDeliveryZoneEvents(selectedStoreId, user.id, 'Delete Delivery Zone List', {
                    id: selectedZoneId,
                  });
                }
              };
              handleDelete();
              onClose();
            }}
            kind="secondaryError"
          >
            <Text value={translations.DELETE} />
          </Button>
        </Row>
      </div>
    </>
  );
};

DeleteZone.propTypes = {
  onClose: PropTypes.func.isRequired,
  selectedZoneId: PropTypes.number.isRequired,
  setTableViewController: PropTypes.func.isRequired,
};

export default DeleteZone;
