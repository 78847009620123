import React, { useContext } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { context as localeContext } from 'context/locale';
import { ORDER_STATUS } from 'constants/order';
import { Text } from 'components/service';
import * as translations from 'constants/translations';
import PaymentTransactionStatus from '../PaymentTransactionStatus';

const OrderDetailsPaymentInfo = ({ order, selected }) => {
  const { lang } = useContext(localeContext);
  const direction = lang === 'ar' ? 'rtl' : 'ltr';
  const { timeZone } = selected || {};
  const { paymentStatus, paymentTransaction } = order || {};

  return (
    (paymentStatus === ORDER_STATUS.WAITING_FOR_PAYMENT ||
      paymentStatus === ORDER_STATUS.PAYMENT_FAILED ||
      paymentStatus === ORDER_STATUS.PAYMENT_EXPIRED ||
      paymentStatus === ORDER_STATUS.REDIRECT_URL) && (
      <div className="w-full bg-white p-2 border" style={{ direction }}>
        <div className="flex-start md:flex items-center justify-between mb-6">
          <Text className="font-bold" value={translations.PAYMENT_INFO} full={false} />
        </div>

        <div className="flex-start md:flex items-center justify-between">
          <div>
            <Text className="text-gray-600" value={translations.PAYMENT_REFERENCE} />
            {paymentTransaction?.chargeId}
          </div>
          <div>
            <Text className="text-gray-600" value={translations.INITIATED_AT} />
            {moment(paymentTransaction?.paymentDate)
              .tz(timeZone)
              .locale(lang === 'ar' ? 'ar' : 'en-gb')
              .format('h:mm A, DD MMM YYYY')}
          </div>
          <div>
            <Text className="text-gray-600" value={translations.STATUS_ORDER} />
            <PaymentTransactionStatus status={paymentTransaction?.status} lang={lang} />
          </div>
        </div>
      </div>
    )
  );
};

OrderDetailsPaymentInfo.propTypes = {
  order: PropTypes.shape({ paymentStatus: PropTypes.string, paymentTransaction: PropTypes.string }),
  selected: PropTypes.shape({ timeZone: PropTypes.string }),
};
export default OrderDetailsPaymentInfo;
