import { gql } from "@apollo/client"

export const CREATE_VARIANT = gql`
  mutation createVariant(
    $restaurantId: String!
    $menuItemId: String!
    $titleEn: String!
    $titleAr: String!
    $price: String!
    $externalId: String
    $discountedPrice: String
    $discountEnabled: Boolean
  ) {
    createVariant(
      restaurantId: $restaurantId
      menuItemId: $menuItemId
      titleEn: $titleEn
      titleAr: $titleAr
      price: $price
      externalId: $externalId
      discountedPrice: $discountedPrice
      discountEnabled: $discountEnabled
    ) {
      id
      titleEn
      titleAr
      price
      discountedPrice
      discountEnabled
      prepTime
      externalId
      images {
        id
        imageUrl
      }
      propertySections {
        id
        variantIds
        titleEn
        titleAr
        minQuantity
        maxQuantity
        multipleAddOnsEnabled
        propertyValues {
          id
          titleEn
          titleAr
          price
          externalId
        }
        variantProperties {
          id
          minQuantity
          maxQuantity
          multipleAddOnsEnabled
        }
        menuItems {
          id
          titleEn
          titleAr
        }
      }
    }
  }
`

export const UPDATE_VARIANT = gql`
  mutation updateVariant(
    $restaurantId: String!
    $menuItemId: String!
    $variantId: String!
    $titleEn: String
    $titleAr: String
    $price: String
    $externalId: String
    $prepTime: Int
    $discountedPrice: String
    $discountEnabled: Boolean
  ) {
    updateVariant(
      restaurantId: $restaurantId
      menuItemId: $menuItemId
      variantId: $variantId
      titleEn: $titleEn
      titleAr: $titleAr
      price: $price
      externalId: $externalId
      prepTime: $prepTime
      discountedPrice: $discountedPrice
      discountEnabled: $discountEnabled
    ) {
      id
      titleEn
      titleAr
      price
      prepTime
      externalId
      discountedPrice
      discountEnabled
      images {
        id
        imageUrl
      }
    }
  }
`

export const DELETE_VARIANT = gql`
  mutation deleteVariant(
    $restaurantId: String!
    $menuItemId: String!
    $variantId: String!
  ) {
    deleteVariant(
      restaurantId: $restaurantId
      menuItemId: $menuItemId
      variantId: $variantId
    ) {
      id
    }
  }
`

export const UPDATE_POSITIONS = gql`
  mutation updateVariantsPositions(
    $restaurantId: String!
    $menuItemId: String!
    $positions: [VariantPosition]!
  ) {
    updateVariantsPositions(
      restaurantId: $restaurantId
      menuItemId: $menuItemId
      positions: $positions
    )
  }
`
