import React, { useLayoutEffect, useContext } from 'react';
import { navigate } from '@reach/router';

import { context as userContext } from "context/user"
import { Frame, Table } from "components/common/orders"
import { fbRedirectUrl } from 'constants/fb';

export default () => {
  const { selectedStore } = useContext(userContext)
  useLayoutEffect(() => {
    if (window.location.search && window.location.search.includes('redirect_uri')) {
      navigate(
        decodeURIComponent(
          window.location.search
            .split('=')[2]
            .concat(
              fbRedirectUrl(selectedStore),
            ),
        ),
      );
    }
  }, []);
  return (
    <Frame status="submitted">
      <Table />
    </Frame>
  )
}
