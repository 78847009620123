import React from 'react';
import PropTypes from 'prop-types';

import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { ORDER_RIDER_STATUS } from 'constants/orderRiderStatus';

const OrderRiderStatus = ({ status }) => {
  switch (status) {
    case ORDER_RIDER_STATUS.SUBMITTED:
    case ORDER_RIDER_STATUS.PENDING:
      return <Text value={translations.PENDING_ACCEPTANCE} className="text-yellow-600" />;
    case ORDER_RIDER_STATUS.DECLINED:
      return <Text value={translations.DECLINED_THE_REQUEST} className="text-red-600" />;
    case ORDER_RIDER_STATUS.ASSIGNED:
      return <Text value={translations.ASSIGNED_SUCCESSFULLY} className="text-green-600" />;
    case ORDER_RIDER_STATUS.ACCEPTED:
    case ORDER_RIDER_STATUS.PUSHED:
      return <Text value={translations.ASSIGNED_SUCCESSFULLY} className="text-green-600" />;
    case ORDER_RIDER_STATUS.DISPATCHED:
      return <Text value={translations.DELIVERING} className="text-primary-600" />;
    case ORDER_RIDER_STATUS.CANCELED:
      return <Text value={translations.CANCELED_ORDERS} className="text-red-600" />;
    case ORDER_RIDER_STATUS.DELIVERED:
      return <Text value={translations.DELIVERED} className="text-green-600" />;
    default:
      return '';
  }
};

OrderRiderStatus.propTypes = {
  status: PropTypes.oneOf([
    ORDER_RIDER_STATUS.PENDING,
    ORDER_RIDER_STATUS.DECLINED,
    ORDER_RIDER_STATUS.ACCEPTED,
    ORDER_RIDER_STATUS.PUSHED,
    ORDER_RIDER_STATUS.DECLINED,
    ORDER_RIDER_STATUS.PICKED,
    ORDER_RIDER_STATUS.DELIVERED,
  ]),
};
export default OrderRiderStatus;
