import React, { useRef, useContext } from 'react';
import cx from 'classnames';

import { Text } from 'components/service';
import { Error } from 'components/form/generic';
import { context as userContext } from 'context/user';
import {
  TOOLBOX,
  DIRECT_LINK,
  VOUCHER_CODE_TOOLBOX,
  VOUCHER_VALUE_TOOLBOX,
  VOUCHER_EXPIRY_TOOLBOX,
  CREDITS,
} from 'constants/translations';
import { VOUCHER_TYPE } from 'pages/marketing/constants';
import { CAMPAIGN_OBJECT_KEYS, SMS_BODY_TOOLBOX_TAGS } from './constants';
import smsCount from './smsCount';
import { getStoreURl } from '../../../../components/common/dashboard/Layout/utils';

const CampaignTextArea = ({
  error,
  lang,
  direction = 'ltr',
  onChange,
  name,
  setFieldValue,
  disabled,
  value,
  translate,
  voucherType,
  setSmsError,
  isAnnouncementCampaign,
  ...props
}) => {
  const { selectedStore } = useContext(userContext);
  const storeUrl = getStoreURl(selectedStore);
  const smsCountResult = smsCount(value);
  const ref = useRef();

  const smsMaxLength = smsCountResult.perMessage * 3;

  const toolboxConcat = (e, smsBody, tag) => {
    e.preventDefault();
    const newSmsBody = smsBody.concat(` ${tag} `);
    const newSmsBodyCount = smsCount(newSmsBody);
    if (newSmsBodyCount.length <= smsMaxLength) {
      setFieldValue(CAMPAIGN_OBJECT_KEYS.SMS_BODY, newSmsBody);
      setFieldValue(CAMPAIGN_OBJECT_KEYS.CREDITS_PER_MESSAGE, newSmsBodyCount.messages);
    }
    ref.current.focus();
  };

  return (
    <div className="w-full">
      <div
        className={cx(
          'flex flex-col w-full items-start border border-zyda-grey-80 focus:border-primary-base focus:ring',
          error && 'border-zyda-red-500 bg-zyda-red-50',
          !disabled && 'hover:border-primary-200',
        )}
      >
        <textarea
          ref={ref}
          style={{ direction }}
          className="block appearance-none w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none resize-none"
          maxLength={smsMaxLength}
          disabled={disabled}
          onChange={({ target: { value: targetValue } }) => {
            if (targetValue.includes('=DL') || targetValue.includes('= DL') || targetValue.includes(storeUrl)) {
              setSmsError(false);
            } else {
              setSmsError(true);
            }
            const newSmsBodyCount = smsCount(targetValue);
            if (newSmsBodyCount.length <= smsMaxLength) {
              setFieldValue(CAMPAIGN_OBJECT_KEYS.CREDITS_PER_MESSAGE, newSmsBodyCount.messages);
              onChange({
                target: {
                  name,
                  value: targetValue,
                },
              });
            }
          }}
          value={value}
          {...props}
        />
        <div className="w-full px-3 pb-4 text-xs" style={{ direction }}>
          <Text selectedLang={lang} value={TOOLBOX} forcedDirection={direction} className="mb-2" />
          <div className="flex flex-col md:flex-row md:justify-between gap-2">
            <button
              type="button"
              className="w-full py-1 px-3 border border-gray-300 disabled:cursor-default"
              onClick={e => toolboxConcat(e, value, SMS_BODY_TOOLBOX_TAGS.DIRECT_LINK)}
              disabled={disabled}
            >
              <Text selectedLang={lang} value={DIRECT_LINK} />
            </button>
            {voucherType !== VOUCHER_TYPE.NO_VOUCHER && !isAnnouncementCampaign && (
              <>
                <button
                  type="button"
                  className="w-full py-1 px-3 border border-gray-300 disabled:cursor-default"
                  onClick={e => toolboxConcat(e, value, SMS_BODY_TOOLBOX_TAGS.VOUCHER_CODE)}
                  disabled={disabled}
                >
                  <Text selectedLang={lang} value={VOUCHER_CODE_TOOLBOX} />
                </button>
                <button
                  type="button"
                  className="w-full py-1 px-3 border border-gray-300 disabled:cursor-default"
                  onClick={e => toolboxConcat(e, value, SMS_BODY_TOOLBOX_TAGS.VOUCHER_EXPIRY)}
                  disabled={disabled}
                >
                  <Text selectedLang={lang} value={VOUCHER_EXPIRY_TOOLBOX} />
                </button>
              </>
            )}
            {voucherType !== VOUCHER_TYPE.NO_VOUCHER && !isAnnouncementCampaign && voucherType !== VOUCHER_TYPE.FREE_DELIVERY && (
              <button
                type="button"
                className="w-full py-1 px-3 border border-gray-300 disabled:cursor-default"
                onClick={e => toolboxConcat(e, value, SMS_BODY_TOOLBOX_TAGS.VOUCHER_VALUE)}
                disabled={disabled}
              >
                <Text selectedLang={lang} value={VOUCHER_VALUE_TOOLBOX} />
              </button>
            )}
          </div>
          <div className={cx(lang === 'en' ? 'ml-auto' : 'mr-auto', 'mt-2 text-gray-700')}>{`${
            smsCountResult.length
          } / ${smsMaxLength} = ${smsCountResult.messages} ${translate(CREDITS)}`}</div>
        </div>
      </div>

      {error && <Error>{error}</Error>}
    </div>
  );
};

export default CampaignTextArea;
