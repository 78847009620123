import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';

import * as translations from 'constants/translations';
import { context as localeContext } from 'context/locale';
import { ArabicNumbers } from 'utils/numbers';
import { WALLET_AMOUNTS_QUERY } from '../../schema';
import { BACKEND_GRAPHQL_SERVER } from '../../../../../constants';
import WalletDataBreakDown from '../WalletDataBreakDown';
import WalletDataMoney from '../WalletDataMoney';

const WalletDataSummary = ({ selectedStore }) => {
  const { translate } = useContext(localeContext);

  const { data } = useQuery(WALLET_AMOUNTS_QUERY, {
    variables: {
      subdomain: selectedStore?.subdomain,
    },
    context: {
      serverName: BACKEND_GRAPHQL_SERVER,
    },
  });
  const { storeWalletAmount: walletData } = data || {};
  const currencyText = translate(selectedStore.currency);

  if (!walletData) return <></>;

  const earned = [
    {
      title: translations.ORDERS_PAID_BY_WALLET,
      description: [`${walletData?.ordersPaidByWallet}`, `${ArabicNumbers(walletData?.ordersPaidByWallet)}`],
    },
    {
      title: translations.ORDERS_VALUE,
      description: [
        `${walletData?.totalOrdersValue.toFixed(2)} ${currencyText}`,
        `${ArabicNumbers(walletData?.totalOrdersValue.toFixed(2))} ${currencyText}`,
      ],
    },
    {
      title: translations.TOTAL_CUSTOMERS,
      description: [`${walletData?.totalCustomer}`, `${ArabicNumbers(walletData?.totalCustomer)}`],
    },
  ];
  const deposited = [
    {
      title: translations.CASHBACK,
      description: [
        `${walletData?.cashback.toFixed(2)} ${currencyText}`,
        `${ArabicNumbers(walletData?.cashback.toFixed(2))} ${currencyText}`,
      ],
    },
    {
      title: translations.REFUNDED,
      description: [
        `${walletData?.refund.toFixed(2)} ${currencyText}`,
        `${ArabicNumbers(walletData?.refund.toFixed(2))} ${currencyText}`,
      ],
    },
    {
      title: translations.COMPENSATIONS,
      description: [
        `${walletData?.compensation.toFixed(2)} ${currencyText}`,
        `${ArabicNumbers(walletData?.compensation.toFixed(2))} ${currencyText}`,
      ],
    },
  ];

  return (
    <div className="flex gap-4">
      <div className="flex flex-1 flex-col border rounded-lg my-4 px-4">
        <WalletDataMoney
          title={translations.TOTAL_PAID_BY_WALLET}
          value={[
            `${walletData?.totalRedemptions.toFixed(2)} ${currencyText}`,
            `${ArabicNumbers(walletData?.totalRedemptions.toFixed(2))} ${currencyText}`,
          ]}
          valueClassName="text-zyda-green-600"
          toolTipText={translations.TOTAL_PAID_BY_WALLET_TOOLTIP}
          id="totalPaidTooltip"
        />
        <hr className="self-center mx-4 bg-gray-400 w-full" />

        <WalletDataMoney
          title={translations.AMOUNT_IN_WALLETS}
          value={[
            `${walletData?.totalWalletAmounts.toFixed(2)} ${currencyText}`,
            `${ArabicNumbers(walletData?.totalWalletAmounts.toFixed(2))} ${currencyText}`,
          ]}
          valueClassName="text-zyda-red-600"
          toolTipText={translations.TOOLTIP_AMOUNT_IN_WALLETS}
          id="amountTooltip"
        />
      </div>
      <WalletDataBreakDown deposited={deposited} earned={earned} />
    </div>
  );
};

export default WalletDataSummary;
