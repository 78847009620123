import React from 'react';
import { Text, Link } from 'components/service';
import * as translations from 'constants/translations';
import { Button } from 'components/kit';
import * as paths from 'paths.js';
import { ReactComponent as Illustration } from 'assets/no-branches.svg';
import { BranchAddingModal } from 'components/common/branch';

export default (subscOnStandardPlan = false, zydaMail = true) => (
  <div className="flex flex-col items-center mt-24 text-center">
    <Illustration />
    <Text className="text-lg mb-1 font-semibold mt-4" value={translations.BUSINESS_LOCATIONS_EMPTY_TITLE} />
    <Text className="text-gray-700 mb-6" value={translations.BUSINESS_LOCATIONS_EMPTY_DESC} />
    <>
      {!zydaMail && subscOnStandardPlan ? (
        <BranchAddingModal />
      ) : (
        <Link to={paths.branchCreation}>
          <Button kind="primary">
            <Text value={translations.ADD_BUSINESS_LOCATION} />
          </Button>
        </Link>
      )}
    </>
  </div>
);
