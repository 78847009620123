import moment from "moment"
import * as R from "ramda"

export const generateInitialValue = (openAt, closeAt) => {
  const days = moment.weekdays()

  return days.map(day => {
    return {
      day: day.toLowerCase().substr(0, 3),
      openAt,
      closeAt,
      offDay: false,
    }
  })
}

export const isEveryDay = value => {
  const noDay = R.omit(["day"])

  return value.every(
    item => R.equals(noDay(item), noDay(value[0])) && !item.offDay
  )
}
