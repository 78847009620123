import React from "react"
import { When } from "components/service"
import SpinnerAlt from "../SpinnerAlt"

export default ({ children, isLoading }) => {
  return (
    <When cond={isLoading} component={Container}>
      {children}
    </When>
  )
}

const Container = ({ children }) => (
  <div className="relative">
    {children}
    <div
      style={{ backgroundColor: "rgba(255, 255, 255, .9)" }}
      className="absolute top-0 left-0 w-full h-full flex items-center justify-center"
    >
      <SpinnerAlt color="primary-base" />
    </div>
  </div>
)
