import React, { useState, useContext } from "react"
import { useApolloClient } from "@apollo/client"
import { SpinnerAlt } from "components/kit"
import { uploadVariantImages, removeVariantImage } from "rest.js"
import { context as notificationsContext } from "context/notifications"
import { context as localeContext } from "context/locale"
import * as translations from "constants/translations"
import { Text } from "components/service"
import { ReactComponent as Trash } from "assets/white-trash.svg"
import DeleteModal from "./DeleteModal"

export default ({
  items,
  storeId,
  productId,
  query,
  open,
  close,
  pageType,
}) => {
  const { lang } = useContext(localeContext)
  return (
    <div className="pb-3">
      {items?.map(item => {
        return (
          <div
            key={item.id}
            className="py-3 px-2 border border-gray-300 mb-3"
          >
            <div className="flex items-center">
              <div
                data-testid={`${pageType}-variant-image-title`}
                className="flex-shrink-0 flex items-center"
              >
                {item.titleEn} -{" "}
                <Upload
                  pageType={pageType}
                  query={query}
                  variantId={item.id}
                  productId={productId}
                  storeId={storeId}
                />
              </div>
            </div>
            {!!(item.images && item.images.length) && (
              <div className="flex flex-wrap -m-1 pt-3">
                {item.images.map(image => (
                  <Image
                    {...image}
                    query={query}
                    productId={productId}
                    storeId={storeId}
                    variantId={item.id}
                    open={open}
                    close={close}
                    pageType={pageType}
                  />
                ))}
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}

const Image = ({
  query,
  productId,
  storeId,
  variantId,
  id,
  imageUrl,
  open,
  close,
  pageType,
}) => {
  const [isDeleting, setDeleting] = useState(false)
  const updateImagesCache = useUpdateImagesCache(variantId, {
    query,
    variables: {
      restaurantId: storeId,
      id: productId,
    },
  })

  return (
    <div className="w-1/4 h-32 relative" key={id}>
      <img
        className="object-cover align-bottom h-full min-w-full p-1 rounded"
        src={imageUrl}
        alt=""
      />
      <div className="absolute w-full h-full top-0 left-0 flex group">
        <div className="ml-auto mb-auto p-1 mr-2 mt-2">
          {!isDeleting ? (
            <div
              className="invisible group-hover:visible cursor-pointer text-red-600 text-white  bg-zyda-red-500"
              data-testid={`${pageType}-delete-variant-image-btn`}
              style={{
                borderRadius: "50%",
                padding: "8px",
              }}
              onClick={() => {
                open({
                  testId: `${pageType}-delete-variant-image-modal`,
                  title: <Text value={translations.DELETE_IMAGE} />,
                  body: (
                    <DeleteModal
                      pageType={pageType}
                      isDeleting={isDeleting}
                      onClick={async () => {
                        setDeleting(true)
                        const res = await removeVariantImage(
                          storeId,
                          variantId,
                          id
                        )
                        close()
                        updateImagesCache(res.data)
                        setDeleting(false)
                      }}
                      onClose={close}
                    />
                  ),
                  size: "max-w-md",
                })
              }}
            >
              <Trash />
            </div>
          ) : (
            <SpinnerAlt />
          )}
        </div>
      </div>
    </div>
  )
}

const Upload = ({ query, variantId, productId, storeId, pageType }) => {
  const notifications = useContext(notificationsContext)
  const [isUploading, setUploading] = useState(false)
  const updateImagesCache = useUpdateImagesCache(variantId, {
    query,
    variables: {
      restaurantId: storeId,
      id: productId,
    },
  })

  return (
    <label className="text-primary-base cursor-pointer relative flex items-center">
      <Text value={translations.UPLOAD_IMAGES} className="mx-2" />
      {isUploading && <SpinnerAlt color="primary-base" />}
      <input
        data-testid={`${pageType}-upload-variant-image`}
        type="file"
        accept=".jpg, .jpeg, .png"
        multiple
        className="invisible absolute w-full h-full top-0 left-0 cursor-pointer"
        onChange={async e => {
          setUploading(true)
          for (let file of e.target.files) {
            const filesize = Math.round(file.size / 1024)
            if (filesize >= 5096) {
              notifications.show(
                `image ${file.name} too Big, please select a image less than 5 mb`,
                "error"
              )
            } else {
              const res = await uploadVariantImages(storeId, variantId, file)
              updateImagesCache(res.data)
            }
          }
          setUploading(false)
        }}
        onClick={e => {
          e.target.value = null
        }}
      />
    </label>
  )
}

const useUpdateImagesCache = (variantId, args) => {
  const client = useApolloClient()

  const cache = client.readQuery(args)

  return images => {
    client.writeQuery({
      ...args,
      data: {
        ...cache,
        variants: cache.variants.map(variant =>
          variant.id !== variantId
            ? variant
            : {
                ...variant,
                images: images.map(item => ({
                  id: item.id,
                  imageUrl: item.attributes["image-url"],
                  __typename: "VariantImage",
                })),
              }
        ),
      },
    })
  }
}