import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';

import * as translations from 'constants/translations';
import { useSelectedStore } from 'hooks';
import { context as notificationsContext } from 'context/notifications';
import * as hoursUtils from 'components/form/elements/Hours/utils';
import { Button, DropDown } from 'components/kit';
import { Label, Row } from 'components/form/generic';
import { Hours, InfoCheckbox, Input, Stepper } from 'components/form/elements';
import { Text, Field } from 'components/service';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import zoneFormValidationSchema from 'pages/setup/delivery/Zones/ZoneForm/data';
import { useUpdateDeliveryZone } from 'service/hooks';
import { convertedDeliveryTimeUtils, dzCrudErrorHandler, zoneTypeUtils } from 'pages/setup/delivery/Zones/utils';
import { convertTime } from 'utils';
import { TIME_FORMAT } from 'components/common/branch/Busy/utils';
import DeleteZone from '../DeleteZone';
import { trackDeliveryZoneEvents } from '../../utils';

const EditZone = ({ onClose, item, setZones, setTableViewController, branches, open }) => {
  const { direction, translate } = useContext(localeContext);
  const selectedStoreId = useSelectedStore();
  const { user } = useContext(userContext);
  const notifications = useContext(notificationsContext);

  const [pending, setPending] = useState(false);

  const updateDeliveryZone = useUpdateDeliveryZone();

  const { properties } = item;
  const { pos: POSIntegration } = useContext(userContext);
  const needsPOSExternalId = POSIntegration?.supportZoneMapping;
  const {
    zone_name: zoneName,
    pos_external_id: posExternalId,
    delivery_fee: deliveryFee,
    minimum_order: minValue,
    published,
    delivery_time: deliveryTime,
    opening_hours: openingHours,
    branch_reference_id: branchId,
    id,
  } = properties;

  const initialValues = {
    zoneName,
    posExternalId: posExternalId || '',
    deliveryFee,
    minValue,
    published,
    deliveryTime: [deliveryTime, TIME_FORMAT.MINS],
    openingHours: openingHours || hoursUtils.generateInitialValue('11:00', '21:30'),
    branchId,
    needsPOSExternalId,
  };

  const updateZone = async (obj, values, setErrors) => {
    setPending(true);
    const response = await updateDeliveryZone(obj);
    if (response.hasError) {
      setPending(false);
      dzCrudErrorHandler(response, setErrors, translate, translations);
    } else {
      setPending(false);
      setZones(prevState =>
        prevState.map(zoneItem =>
          zoneItem.properties.id === id
            ? {
                ...zoneItem,
                properties: {
                  ...zoneItem.properties,
                  zone_name: values.zoneName,
                  pos_external_id: values.posExternalId,
                  delivery_fee: values.deliveryFee,
                  minimum_order: values.minValue,
                  published: values.published,
                  opening_hours: values.openingHours,
                  delivery_time: convertedDeliveryTimeUtils(
                    values.deliveryTime[1],
                    values.deliveryTime[0],
                    convertTime,
                  ),
                  branch_reference_id: parseFloat(values.branchId),
                },
              }
            : zoneItem,
        ),
      );
      trackDeliveryZoneEvents(selectedStoreId, user.id, 'Update Delivery Zone List', {
        ...values,
        openingHours: JSON.stringify(values.openingHours),
        zoneType: item.geometry.type,
      });
      setTableViewController(prevState => ({ ...prevState }));
      notifications.show(<Text value={translations.DELIVERY_ZONE_EDITED} />);
      onClose();
    }
  };

  const handleFormSubmit = (values, setErrors) => {
    updateZone(
      {
        params: {
          id,
          zoneName: values.zoneName,
          posExternalId: values.posExternalId,
          deliveryFee: parseFloat(values.deliveryFee),
          minimumOrder: values.minValue ? parseFloat(values.minValue) : 0,
          deliveryTime: parseFloat(
            convertedDeliveryTimeUtils(values.deliveryTime[1], values.deliveryTime[0], convertTime),
          ),
          published: values.published,
          openingHours: values.openingHours,
          branchReferenceId: parseFloat(values.branchId),
        },
      },
      values,
      setErrors,
    );
  };

  return (
    <div className="px-4">
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setErrors }) => handleFormSubmit(values, setErrors)}
        validationSchema={zoneFormValidationSchema}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Row className="mb-3">
              <Label textSize="text-xs" title={<Text value={translations.ZONE_NAME} />}>
                <Field
                  type="text"
                  name="zoneName"
                  component={Input}
                  placeholder={translate(translations.ZONE_NAME_PLACEHOLDER)}
                  direction={direction}
                />
              </Label>
              {needsPOSExternalId && (
                <Label textSize="text-xs" title={<Text value={translations.POS_EXTERNAL_ID} />}>
                  <Field
                    type="text"
                    name="posExternalId"
                    component={Input}
                    placeholder={translate(translations.POS_EXTERNAL_ID_PLACEHOLDER)}
                    direction={direction}
                  />
                </Label>
              )}
              <Label textSize="text-xs" title={<Text value={translations.ASSIGNED_BRANCH} />}>
                <DropDown
                  onSelect={option => setFieldValue('branchId', option)}
                  optionSelected={values.branchId}
                  placeholder={<Text value={translations.CHOOSE_ONE_BRANCH} className="text-sm text-gray-500" />}
                  data={branches}
                  icon="keyboard_arrow_down"
                  full
                  scrollable
                  className="w-full"
                  isDisabled={item.geometry.type === zoneTypeUtils.POINT && true}
                />
              </Label>
            </Row>

            <Row className="mb-3">
              <Label textSize="text-xs" title={<Text value={translations.DELIVERY_FEES} />}>
                <Field
                  type="text"
                  name="deliveryFee"
                  component={Input}
                  placeholder={translate(translations.DELIVERY_FEES_PLACEHOLDER)}
                  direction={direction}
                />
              </Label>
              <Label textSize="text-xs" title={<Text value={translations.MINIMUM_ORDER_AMOUNT} />}>
                <Field
                  type="text"
                  name="minValue"
                  component={Input}
                  placeholder={translate(translations.MINIMUM_ORDER_AMOUNT_PLACEHOLDER)}
                  direction={direction}
                />
              </Label>
              <Label textSize="text-xs" title={<Text value={translations.DELIVERY_TIME} />}>
                <Field
                  min={0}
                  step={1}
                  name="deliveryTime"
                  options={[
                    {
                      id: TIME_FORMAT.MINS,
                      title: <Text value={translations.MINUTES} />,
                    },
                    {
                      id: TIME_FORMAT.HRS,
                      title: <Text value={translations.HOURS} />,
                    },
                  ]}
                  dropdown
                  hideStepper
                  component={Stepper}
                />
              </Label>
            </Row>

            <Row className="mb-5 mt-8">
              <Field
                title={<Text className="text-sm" value={translations.PUBLISH_ZONE} />}
                toggle
                zoneToggle
                name="published"
                buttonSize="base"
                btnTxtColor="text-primary-base"
                component={InfoCheckbox}
              />
            </Row>

            <Row className="mb-5 mt-5">
              <div className="border-b border-gray-300" />
            </Row>

            <Row className="mb-12">
              <Label textSize="text-xs" title={<Text value={translations.DELIVERY_HOURS} />}>
                <Field name="openingHours" component={Hours} />
              </Label>
            </Row>

            <div className="flex flex-row justify-between pt-4 w-full">
              <Button
                onClick={() =>
                  open({
                    title: <Text value={translations.ZONE} />,
                    body: (
                      <DeleteZone
                        onClose={onClose}
                        selectedZoneId={item.properties.id}
                        setTableViewController={setTableViewController}
                      />
                    ),
                    size: 'max-w-md',
                  })
                }
                type="button"
                kind="tertiary"
                className="w-40"
                isSpinning={pending}
              >
                <Text value={translations.DELETE_ZONE} />
              </Button>
              <Button kind="primary" type="submit" className="w-40" isSpinning={pending}>
                <Text value={translations.SAVE} />
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

EditZone.propTypes = {
  item: PropTypes.shape({
    properties: PropTypes.shape({
      zone_name: PropTypes.string,
      pos_external_id: PropTypes.string,
      delivery_fee: PropTypes.number,
      minimum_order: PropTypes.number,
      published: PropTypes.bool,
      delivery_time: PropTypes.number,
      opening_hours: PropTypes.arrayOf(
        PropTypes.shape({
          openAt: PropTypes.string,
          closeAt: PropTypes.string,
        }),
      ),
      id: PropTypes.number,
      branch_reference_id: PropTypes.number,
    }),
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  setZones: PropTypes.func.isRequired,
  setTableViewController: PropTypes.func.isRequired,
  branches: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
  open: PropTypes.shape({
    size: PropTypes.string,
  }).isRequired,
};

export default EditZone;
