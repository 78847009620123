import { gql } from "@apollo/client"

const CUSTOMER = gql`
  query($storeId: String!, $customerId: Int!) {
    customer(restaurantId: $storeId, customerId: $customerId) {
      id
      userId
      userName
      phoneNumber
      firstOrder
      lastOrder
      totalOrders
      totalSales
      segmentId
      email
      walletBalance
      addresses {
        lat
        lng
        address
        type
      }
    }
  }
`

const profileSchemas = {
  CUSTOMER,
}

export default profileSchemas
