import { CAMPAIGNS } from "../../schemas"

export const removeCampaign = (campaignsVariables, campaignId) => cache => {
  const data = cache.readQuery({
    query: CAMPAIGNS,
    variables: campaignsVariables,
  })

  if (!data) return

  cache.writeQuery({
    query: CAMPAIGNS,
    data: {
      campaigns: {
        ...data.campaigns,
        campaigns: data.campaigns.campaigns.filter(
          campaign => campaign.id !== campaignId
        ),
      },
    },
    variables: campaignsVariables,
  })
}
