import * as R from "ramda"
import camelize from "camelize"

export const formatErrors = err => {
  if (R.path(["graphQLErrors", 0, "extensions", "code"], err) === 403) {
    return R.compose(
      camelize,
      R.mapObjIndexed(R.head),
      R.path(["graphQLErrors", 0, "extensions", "exception", "body"])
    )(err)
  }

  return {}
}
