import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { InfoIcon } from '@zydalabs/zac-icons-react';

import MapViewIcon from 'assets/map-view.svg';
import ZoneTemplatesIcon from 'assets/zone-templates.svg';
import * as translations from 'constants/translations';
import { Text } from 'components/service';
import { Button } from 'components/kit';
import { context as localeContext } from 'context/locale';
import { zoneDrawerUtils, zoneTypeUtils } from '../../utils';

const ZoneTemplateModal = ({ close, zoneController, setZoneController }) => {
  const { direction, translate } = useContext(localeContext);
  const [drawerMode, setDrawerMode] = useState(null);

  const handleDrawerMode = mode => {
    setDrawerMode(mode);
  };

  const handleDrawerNavigate = () => {
    if (drawerMode === zoneDrawerUtils.FREEDRAWING) {
      setZoneController({
        ...zoneController,
        showDrawerMode: true,
        drawerMode: zoneTypeUtils.POLYGON,
        enableDrawerMode: true,
      });
    } else if (drawerMode === zoneDrawerUtils.TEMPLATES) {
      setZoneController({
        ...zoneController,
        showZoneTemplates: true,
      });
    }
  };

  return (
    <div className="px-4">
      <div className="flex justify-between" style={{ direction }}>
        <div
          className={cx(
            'p-4 border cursor-pointer w-1/2 flex flex-row mb-10',
            direction === 'ltr' ? 'mr-4' : 'ml-4',
            drawerMode === zoneDrawerUtils.FREEDRAWING ? 'border-primary-base bg-blue-100' : 'border-gray-300',
          )}
          aria-hidden="true"
          onClick={() => handleDrawerMode(zoneDrawerUtils.FREEDRAWING)}
          style={{ direction }}
        >
          <img
            src={MapViewIcon}
            alt=""
            className={cx('self-start mb-3 sm:mb-0', direction === 'rtl' ? 'ml-2' : 'mr-2')}
          />
          <div>
            <Text value={translations.FREE_DRAWING_ZONES} className="font-semibold" />
            <Text value={translations.FREE_DRAWING_ZONES_DESC} className="text-xs text-gray-400" />
          </div>
        </div>
        <div className="flex flex-col w-1/2 relative" style={{ direction }}>
          <div
            className={cx(
              'p-4 border cursor-pointer w-full flex flex-row mb-10',
              drawerMode === zoneDrawerUtils.TEMPLATES ? 'border-primary-base bg-blue-100' : 'border-gray-300',
              !zoneController.isNearestZonesAvailable && 'opacity-30 cursor-not-allowed',
            )}
            aria-hidden="true"
            onClick={() => zoneController.isNearestZonesAvailable && handleDrawerMode(zoneDrawerUtils.TEMPLATES)}
          >
            <img
              src={ZoneTemplatesIcon}
              alt=""
              className={cx('self-start mb-3 sm:mb-0', direction === 'rtl' ? 'ml-2' : 'mr-2')}
            />
            <div>
              <Text value={translations.ZONE_TEMPLATES} className="font-semibold" />
              <Text value={translations.ZONE_TEMPLATES_DESC} className="text-xs text-gray-400" />
            </div>
          </div>
          {!zoneController.isNearestZonesAvailable && (
            <div
              className="py-1 px-2 border border-primary-base absolute bg-primary-25 bottom-0 w-full flex"
              style={{ direction }}
            >
              <InfoIcon width="16" color="#196EFF" className={direction === 'rtl' ? 'ml-2' : 'mr-2'} />
              <span className="text-xs text-primary-base">{translate(translations.NO_TEMPLATES_TEXT)}</span>
            </div>
          )}
        </div>
      </div>

      <div className={cx('flex flex-row justify-between w-full', !zoneController.isNearestZonesAvailable && 'mt-6')}>
        <Button onClick={close} type="button" kind="tertiary" className="w-40">
          <Text value={translations.CANCEL} />
        </Button>
        <Button
          className="w-40"
          onClick={() => {
            close();
            handleDrawerNavigate();
          }}
          kind="primary"
          disabled={drawerMode === null}
        >
          <Text value={translations.GET_STARTED} />
        </Button>
      </div>
    </div>
  );
};

ZoneTemplateModal.propTypes = {
  close: PropTypes.func.isRequired,
  zoneController: PropTypes.shape({
    enableDrawerMode: PropTypes.bool,
    showDrawerMode: PropTypes.bool,
    drawerMode: PropTypes.string,
    isNearestZonesAvailable: PropTypes.bool,
  }).isRequired,
  setZoneController: PropTypes.func.isRequired,
};

export default ZoneTemplateModal;
