const getCookie = cookieName => {
  let cookieNameMatchPattern = cookieName + "="
  // example for document.cookie = "username=John Doe; expires=Thu, 18 Dec 2013 12:00:00 UTC; path=/";
  // the whitespaces after the semi-colon are then removed and we split on the semi colon
  let cookieArray = document.cookie.replace(/;\s/g, ";").split(";")
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i]
    if (cookie.indexOf(cookieNameMatchPattern) === 0) {
      return cookie.substring(cookieNameMatchPattern.length, cookie.length)
    }
  }
  return ""
}

export default getCookie
