import { STH_WENT_WRONG } from "constants/constant-errors"

export const handleErrors = callback => err => {
  const body = err.graphQLErrors[0].extensions.exception.body
  if (Object.keys(body).length === 0) {
    callback(STH_WENT_WRONG)
  }
  for (let key of Object.keys(body)) {
    callback(`${map[key]} ${body[key][0]}`)
  }
}

const map = {
  "variants.title_en": "English title",
  "variants.title_ar": "Arabic title",
  "variants.price": "Price",
  "title_en": "English title",
  "title_ar": "Arabic title",
  "pos": "POS Integration",
  "external_id": "POS Integration"
}
