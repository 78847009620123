import React, { useState } from 'react';
import cx from 'classnames';
import { useMutation } from '@apollo/client';

import { DropDown, SpinnerAlt } from 'components/kit';
import { Field } from 'components/service';
import { Input } from 'components/form/elements';
import { Formik } from 'formik';
import { handleTrackingType, TRACKING_TYPE_ENUM } from './utils';
import { UPDATE_VARIANT_TRACKING } from './schemas';
import { useSelectedStore } from 'hooks';
import { debounce } from 'utils';

const ItemTrackingTableRow = ({
  branchVariantIndex,
  variantName,
  stockCount,
  initialCount,
  trackedTypes,
  lang,
  branchVariantId,
  unavailable,
  isMobile,
  trackingType,
}) => {
  const storeId = useSelectedStore();
  const hasATrackingTypeValue = handleTrackingType(trackingType);

  const [updateItemTracked, { loading: isUpdatingTracked }] = useMutation(UPDATE_VARIANT_TRACKING);

  const handleChangeStockCount = count => {
    if (count >= 0 && count !== '') {
      const updateQuantity = () => 
        updateItemTracked({
          variables: {
            storeId,
            id: branchVariantId,
            stockCount: parseInt(count),
            tracked: hasATrackingTypeValue,
          },
        });
      debounce(updateQuantity, 500);
    }
  };

  const handleChangeStockUnavailablity = isUnavailable => {
    updateItemTracked({
      variables: {
        storeId,
        id: branchVariantId,
        unavailable: isUnavailable,
        tracked: hasATrackingTypeValue,
      },
    });
  };
  const [itemThemeColor, setItemThemeColor] = useState(null);
  const cellClassName = cx(
    hasATrackingTypeValue ? 'w-3/10' : 'w-1/2',
    'py-3 border-collapse items-center border-b border-gray-300 whitespace-nowrap flex-1',
    isMobile && 'text-sm',
  );

  const initialValues = {
    variantId: branchVariantId,
    quantity: TRACKING_TYPE_ENUM.DAILY_CAPACITY === trackingType ? String(initialCount) : String(stockCount),
    unavailable: String(unavailable),
    tracked: hasATrackingTypeValue,
  };

  return (
    <Formik initialValues={initialValues}>
      {({ values, setFieldValue }) => {
        setItemThemeColor(values.unavailable === 'false' ? 'text-zyda-green-600' : 'text-zyda-red-600');
        return (
          <tr key={branchVariantIndex}>
            <td className={cx('px-3 ', cellClassName)}>
              <span className="text-center">{variantName}</span>
            </td>
            {hasATrackingTypeValue && (
              <td className={cx('md:px-3 ', cellClassName)}>
                <Field
                  data-testid={`branch-variants-section-stock-count`}
                  name="quantity"
                  min={0}
                  type="number"
                  validation={n => n >= 0}
                  component={Input}
                  width={20}
                  onChange={e => handleChangeStockCount(e.target.value)}
                />
              </td>
            )}
            <td className={cellClassName}>
              <DropDown
                testId_openDropDown="list-track-avalibility-status"
                testId_selectedData="list-track-avalibility-status-name"
                testId_listData="list-track-avalibility-status-list"
                float={lang === 'ar' ? 'left' : 'right'}
                noMargin
                full
                name="unavailable"
                optionSelected={String(values.unavailable)}
                onSelect={selectedType => {
                  handleChangeStockUnavailablity(selectedType === 'true');
                  setFieldValue('unavailable', selectedType);
                }}
                data={trackedTypes}
                icon="keyboard_arrow_down"
                wFull
                responsiveLabel
                noBorder
                itemColor={itemThemeColor}
                isTracked
              />
            </td>
            <td className={cx(cellClassName, 'w-1/10')}>
              {isUpdatingTracked && (
                <SpinnerAlt color="primary-base" />
              )}
            </td>
          </tr>
        );
      }}
    </Formik>
  );
};

export default ItemTrackingTableRow;
