import { ORDER_STATUS } from 'constants/order';
import * as translations from 'constants/translations';

const getOrderStatusTranslation = status => {
  switch (status) {
    case ORDER_STATUS.READY:
      return translations.READY_ORDER;
    case ORDER_STATUS.DISPATCHED:
      return translations.DISPATCHED_ORDERS;
    case ORDER_STATUS.COMPLETED:
      return translations.COMPLETED_ORDERS;
    default:
      return '';
  }
};
export default getOrderStatusTranslation;
