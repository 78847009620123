import React from 'react';
import { Text } from 'components/service';
import * as yup from 'yup';
import * as translations from 'constants/translations';

export const initialValues = {
  linkType: 'url',
  titleEn: '',
  titleAr: '',
};
export const validationSchema = yup.object().shape({
  linkType: yup.string().required(<Text value={translations.LINK_TYPE_IS_REQ} />),
  phoneNumber: yup.string().when('linkType', {
    is: linkType => ['sms', 'whatsapp', 'phone'].includes(linkType),
    then: yup
      .string()
      .required(<Text value={translations.PHONE_NUMEBER_IS_REQ} />)
      .nullable(true)
      .transform((v, o) => (o === '' ? null : v)),
    otherwise: yup.string().nullable(),
  }),
  smsBody: yup.string().when('linkType', {
    is: linkType => linkType === 'sms' && true,
    then: yup
      .string()
      .required(<Text value={translations.SMS_BODY_IS_REQ} />)
      .nullable(),
    otherwise: yup.string().nullable(),
  }),
  titleEn: yup.string().required(<Text value={translations.TITLE_EN_IS_REQ} />),
  titleAr: yup.string().required(<Text value={translations.TITLE_AR_IS_REQ} />),
  url: yup.string().when('linkType', currentLinkType => {
    switch (currentLinkType) {
      case 'url':
        return yup
          .string()
          .trim()
          .matches(
            /^(?!.*https)(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/,
            <Text value={translations.VALID_URL} />,
          )
          .required(<Text value={translations.URL_IS_REQ} />)
          .nullable();
      case 'email':
        return yup
          .string()
          .email(<Text value={translations.INVALID_EMAIL_FORMAT} />)
          .required(<Text value={translations.EMAIL_IS_REQ} />)
          .nullable();
      case 'whatsapp':
      case 'sms':
      case 'phone':
        return yup.string();
      default:
        return yup
          .string()
          .required(<Text value={translations.URL_IS_REQ} />)
          .nullable();
    }
  }),
});

export const webLinksTypes = [
  { title: <Text value={translations.WEBSITE_URL} />, id: 'url' },
  { title: <Text value={translations.FACEBOOK} />, id: 'facebook' },
  { title: <Text value={translations.INSTAGRAM} />, id: 'instagram' },
  { title: <Text value={translations.TWITTER} />, id: 'twitter' },
  { title: <Text value={translations.SNAPCHAT} />, id: 'snapchat' },
  { title: <Text value={translations.WHATSAPP} />, id: 'whatsapp' },
  { title: <Text value={translations.SMS} />, id: 'sms' },
  { title: <Text value={translations.PHONE} />, id: 'phone' },
  { title: <Text value={translations.EMAIL} />, id: 'email' },
];

export const webLinksInputsData = {
  url: {
    text: <Text value={translations.URL} />,
    prependedText: 'https://',
    placeholderText: translations.ENTER_URL,
  },
  facebook: {
    text: <Text value={translations.FACEBOOK} />,
    prependedText: 'facebook.com/',
    placeholderText: translations.ENTER_FACEBOOK_USERNAME,
  },
  instagram: {
    text: <Text value={translations.INSTAGRAM} />,
    prependedText: 'instagram.com/',
    placeholderText: translations.ENTER_INSTAGRAM_USERNAME,
  },
  twitter: {
    text: <Text value={translations.TWITTER} />,
    prependedText: 'twitter.com/',
    placeholderText: translations.ENTER_TWITTER_USERNAME,
  },
  snapchat: {
    text: <Text value={translations.SNAPCHAT} />,
    prependedText: 'snapchat.com/add/',
    placeholderText: translations.ENTER_SNAPCHAT_USERNAME,
  },
  email: {
    text: <Text value={translations.EMAIL} />,
    prependedText: false,
    placeholderText: translations.ENTER_EMAIL_ADDRESS,
  },
};
