import React, { useState, useContext } from "react"
import { navigate } from "@reach/router"
import { useMutation } from "@apollo/client"
import { Layout, Breadcrumbs } from "components/common/dashboard"
import { useSelectedStore, useReplaceParams } from "hooks/index"
import { context as notificationsContext } from "context/notifications"
import { VoucherForm } from "components/common/marketing/vouchers"
import { VOUCHERS_LINKS } from "constants/helperLinks"
import * as translations from "constants/translations"
import { Link, Text } from "components/service"
import { Button } from "components/kit"
import * as paths from "paths.js"
import * as schemas from "./schemas"

export default () => {
  const replace = useReplaceParams()
  const storeId = useSelectedStore()
  const notifications = useContext(notificationsContext)
  const [isShowPreview, setIsShowPreview] = useState(false)
  const [createVoucher, { loading }] = useMutation(schemas.CREATE_VOUCHER, {
    variables: {
      restaurantId: storeId,
    },
    onCompleted: () => navigate(replace(paths.allVouchers)),
    onError: err => {
      const { body } = err.graphQLErrors[0]?.extensions?.exception || {}

      if (body?.code && body.code[0] === "must be unique per store") {
        return notifications.show(
          <Text value={translations.VOUCHER_UNIQUE} />,
          "error"
        )
      } else if (!body.error) {
        for (let key of Object.keys(body)) {
          notifications.show(`${key} ${body[key][0]}`, "error")
        }
        return
      }

      return notifications.show(
        <Text value={translations.SOMETHING_WENT_WRONG} />,
        "error"
      )
    },
  })

  return (
    <Layout
      top={
        <div>
          <Breadcrumbs helperLinks={VOUCHERS_LINKS} path={translations.breadcrumbs.MARKETING_ADD_VOUCHER} />
        </div>
      }
    >
      <div>
        <div className="pb-32 flex">
          <VoucherForm
            onSubmit={data => {
              createVoucher({ variables: data });
            }}
            isShowPreview={isShowPreview}
            bottom={isValid => (
              <div className="flex flex-wrap mt-8">
                <div className="flex-2 mt-2 md:mt-0 w-full md:w-auto">
                  <Link to={paths.allVouchers}>
                    <Button kind="secondary" size="base" full>
                      <Text value={translations.CANCEL} />
                    </Button>
                  </Link>
                </div>
                <div className="flex-1 flex items-stretch flex-row justify-end mt-2 md:mt-0">
                  <div className="mx-2">
                    <Button
                      onClick={e => {
                        e.preventDefault();
                        setIsShowPreview(!isShowPreview);
                      }}
                      type="button"
                      kind="secondary"
                      size="base"
                      disabled={!isValid}
                      full
                    >
                      <Text value={isShowPreview ? translations.BACK : translations.PREVIEW} />
                    </Button>
                  </div>
                  <div className="w-full md:w-auto">
                    <Button full kind="primary" size="base" isSpinning={loading}>
                      <Text value={translations.SAVE} />
                    </Button>
                  </div>
                </div>
              </div>
            )}
          />
        </div>
      </div>
    </Layout>
  );
}
