import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { useMobile } from 'hooks';
import { Input } from 'components/form/elements';
import * as translations from 'constants/translations';
import { ReactComponent as SearchIcon } from 'assets/search.svg';
import FilterCategoriesDropdown from './FilterCategoriesDropdown';
import ViewInLanguageDropdown from './ViewInLanguageDropdown';
import CollapseCategoryButton from './CollapseCategoryButton';

const MenuPageFrame = ({
  setQuery,
  query,
  lang,
  branchesData,
  langSelected,
  setLangSelected,
  productsLang,
  handleOnCollapse,
  collapseItems,
  searchTimeout,
  open,
  close,
  AddCategoryButton,
  totalNumberOfCategories = 0,
  totalNumberOfItems = 0,
  translate,
  isSingleBranch,
}) => {
  const isMobile = useMobile();

  const handleMenuInput = e => {
    clearTimeout(searchTimeout.current);
    const { value } = e.target;
    searchTimeout.current = setTimeout(() => {
      setQuery({ filter: value });
    }, 500);
  };

  return (
    <Fragment>
      <div className={cx('flex flex-wrap justify-between border-gray-300 w-full', lang === 'ar' && 'flex-row-reverse')}>
        <div className="w-full">
          {isMobile ? (
            <>
              <div className="w-full md:w-auto my-2">
                <Input
                  isMenu
                  data-testid="search-products"
                  type="text"
                  icon={<SearchIcon />}
                  placeholder={translate(translations.SEARCH_MENU, lang, [totalNumberOfCategories, totalNumberOfItems])}
                  search
                  value={query.filter}
                  onChange={e => handleMenuInput(e)}
                />
              </div>
              <div
                className={cx('flex items-center w-full md:w-auto ', lang == 'ar' ? 'flex-row-reverse' : 'flex-row')}
              >
                <div className="pr-3 w-1/2">
                  <FilterCategoriesDropdown
                    query={query}
                    setQuery={setQuery}
                    lang={lang}
                    branchesData={branchesData}
                    isSingleBranch={isSingleBranch}
                  />
                </div>
                <div className={cx('my-2 w-1/2 md:w-auto xl:ml-4', lang == 'ar' && 'ml-auto mr-0')}>
                  <ViewInLanguageDropdown
                    lang={lang}
                    langSelected={langSelected}
                    setQuery={setQuery}
                    setLangSelected={setLangSelected}
                    productsLang={productsLang}
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="w-full">
              <div
                className={cx(
                  'flex items-center w-full md:flex-wrap xl:flex-nowrap justify-between',
                  lang == 'ar' ? 'flex-row' : 'flex-row',
                )}
              >
                <div className="flex items-center">
                  <div className={cx(lang == 'ar' ? 'pl-3' : 'pr-3')}>
                    <FilterCategoriesDropdown
                      query={query}
                      setQuery={setQuery}
                      lang={lang}
                      branchesData={branchesData}
                      isSingleBranch={isSingleBranch}
                    />
                  </div>
                  <div className="w-full md:w-auto my-2">
                    <Input
                      isMenu
                      data-testid="search-products"
                      type="text"
                      icon={<SearchIcon />}
                      placeholder={translate(translations.SEARCH_MENU, lang, [
                        totalNumberOfCategories,
                        totalNumberOfItems,
                      ])}
                      search
                      value={query.filter}
                      onChange={e => handleMenuInput(e)}
                    />
                  </div>
                </div>
                <div className={cx('my-2 w-1/2 md:w-auto mr-0', lang == 'ar' ? 'xl:ml-0' : 'xl:ml-auto')}>
                  <ViewInLanguageDropdown
                    lang={lang}
                    langSelected={langSelected}
                    setQuery={setQuery}
                    setLangSelected={setLangSelected}
                    productsLang={productsLang}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-between items-center py-4">
        <AddCategoryButton open={open} close={close} />
        <CollapseCategoryButton lang={lang} handleOnCollapse={handleOnCollapse} collapseItems={collapseItems} />
      </div>
    </Fragment>
  );
};

MenuPageFrame.propTypes = {
  setQuery: PropTypes.func.isRequired,
  query: PropTypes.element.isRequired,
  lang: PropTypes.string.isRequired,
  branchesData: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string.isRequired, title: PropTypes.string.isRequired }),
  ),
  langSelected: PropTypes.string.isRequired,
  setLangSelected: PropTypes.func.isRequired,
  productsLang: PropTypes.string.isRequired,
  handleOnCollapse: PropTypes.func.isRequired,
  collapseItems: PropTypes.bool.isRequired,
  searchTimeout: PropTypes.func.isRequired,
  open: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  AddCategoryButton: PropTypes.func.isRequired,
  totalNumberOfCategories: PropTypes.number,
  totalNumberOfItems: PropTypes.number,
  translate: PropTypes.func.isRequired,
  isSingleBranch: PropTypes.bool.isRequired,
};
export default MenuPageFrame;
