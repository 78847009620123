import React from 'react';
import PropTypes from 'prop-types';

import * as translations from 'constants/translations';
import { Text, Link } from 'components/service';

const ReportLink = ({ to }) => (
  <div className="text-center py-5 font-semibold text-primary-base cursor-pointer border-t border-gray-300">
    <Link to={to} appendQuery>
      <Text value={translations.VIEW_FULL_REPORT} />
    </Link>
  </div>
);

ReportLink.propTypes = {
  to: PropTypes.string.isRequired,
};

export default ReportLink;
