import React from 'react';

import { Text } from 'components/service';
import { ALL } from 'constants/translations';

const transformBranches = items => {
  if (!items || !items.length) {
    return [];
  }
  return [...items].reduce(
    (acc, item) => ({
      ...acc,
      [item.id]: <Text value={item} />,
    }),
    {},
  );
};
const fetchStoreBranches = (branches, isSingleBranch) => [
  branches &&
    !isSingleBranch && {
      id: 'all',
      title: <Text value={ALL} />,
    },
  ...Object.entries(transformBranches(branches)).map(key => {
    const branch = {
      id: key[0],
      title: key[1],
    };
    return branch;
  }),
];

export default fetchStoreBranches;
