import { gql } from "@apollo/client"

export const COUNTRIES = gql`
  query {
    countries {
      id
      titleEn
    }
  }
`

export const ADD_RESTAURANT = gql`
  mutation addRestaurant(
    $countryId: String!
    $titleAr: String!
    $titleEn: String!
    $subdomain: String!
  ) {
    addRestaurant(
      countryId: $countryId
      titleAr: $titleAr
      titleEn: $titleEn
      subdomain: $subdomain
    ) {
      id
    }
  }
`
