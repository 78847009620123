import React from 'react';

import { Text } from 'components/service';

const WalletDataBreakDown = ({ earned, deposited }) => (
  <div className="flex flex-1 flex-col border rounded-lg my-4 px-4">
    <div className="flex flex-col md:flex-row">
      {earned.map(item => (
        <div className="flex flex-col flex-1 py-6" key={item.title[0]}>
          <Text value={item.title} className="text-sm font-normal opacity-75 mb-1" />
          <Text value={item.description} className="text-lg font-medium mb-1" />
        </div>
      ))}
    </div>

    <hr className="self-center mx-4 bg-gray-400 w-full" />
    <div className="flex flex-col md:flex-row">
      {deposited.map(item => (
        <div className="flex flex-col flex-1 py-6" key={item.title[0]}>
          <Text value={item.title} className="text-sm font-normal opacity-75 mb-1" />
          <Text value={item.description} className="text-lg font-medium mb-1" />
        </div>
      ))}
    </div>
  </div>
);

export default WalletDataBreakDown;
