import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as SearchIcon } from 'assets/search.svg';
import { Input } from 'components/form/elements';

const SearchInput = ({ searchValue = '', setSearchValue, ...restProps }) => (
  <Input
    type="text"
    data-testid="search-text"
    noBorder
    search
    icon={<SearchIcon />}
    height="h-full"
    value={searchValue}
    inputPadding="px-0"
    iconPadding="p-2"
    onChange={setSearchValue}
    {...restProps}
  />
);

SearchInput.propTypes = {
  setSearchValue: PropTypes.func.isRequired,
  searchValue: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
};

export default SearchInput;
