import React from 'react';
import PropTypes from 'prop-types';

import { ORDER_STATUS } from 'constants/order';

const OrderFrameTabTitles = ({ name, status, data }) => {
  let count = 0;
  if (status === ORDER_STATUS.COMPLETED) {
    count = data.orders.statusCount && data.orders.statusCount.delivered + data.orders.statusCount.fulfilled;
  } else if (status === ORDER_STATUS.ALL) {
    count = data.orders.statusCount && data.orders.statusCount.all;
  } else if (status === ORDER_STATUS.WAITING_FOR_PAYMENT) {
    count =
      data.orders.statusCount &&
      data.orders.statusCount.waitingForPayment +
        data.orders.statusCount.redirectUrl +
        data.orders.statusCount.iframeUrl;
  } else if (status === ORDER_STATUS.ACCEPTED) {
    count = data.orders.statusCount && data.orders.statusCount.accepted;
  } else if (status === ORDER_STATUS.PAYMENT_FAILED) {
    count = data.orders.statusCount && data.orders.statusCount.paymentFailed + data.orders.statusCount.paymentExpired;
  } else if (status === ORDER_STATUS.FULFILLED) {
    count = data.orders.statusCount && data.orders.statusCount.fulfilled + data.orders.statusCount.delivered;
  } else {
    count = data.orders.statusCount && data.orders.statusCount[status];
  }

  return (
    <>
      {name}
      {count || count === 0 ? (
        <span className="mx-1" style={{ direction: 'ltr' }}>
          ({count})
        </span>
      ) : (
        <div />
      )}
    </>
  );
};
OrderFrameTabTitles.propTypes = {
  name: PropTypes.element,
  status: PropTypes.string,
  data: PropTypes.shape({
    orders: PropTypes.shape({
      statusCount: PropTypes.shape({
        accepted: PropTypes.number,
        all: PropTypes.number,
        canceled: PropTypes.number,
        delivered: PropTypes.number,
        dispatched: PropTypes.number,
        fulfilled: PropTypes.number,
        paid: PropTypes.number,
        paymentExpired: PropTypes.number,
        paymentFailed: PropTypes.number,
        ready: PropTypes.number,
        redirectUrl: PropTypes.number,
        submitted: PropTypes.number,
        waitingForPayment: PropTypes.number,
        iframeUrl: PropTypes.number,
      }),
    }),
  }),
};

export default OrderFrameTabTitles;
