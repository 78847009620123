import React from 'react';
import moment from 'moment';
import cx from 'classnames';
import PropTypes from 'prop-types';

import {
  ORDER_TYPE,
  DELIVERY_TIME,
  PICKUP_TIME,
  SCHEDULED,
  ASAP,
  BRANCH,
  CITY,
  AREA,
  ZONE,
} from 'constants/translations';
import { getDeliveryTypeText } from 'utils/getDeliveryTypeText';
import DELIVERY_TYPE_ENUM from './utils';

const OrderInvoiceInfo = ({ lang, direction, order, translate, theTimeSlot, store }) => {
  const { deliveryType, isScheduled, expectedAt, branchData, branchName, userData, deliveryZone } = order || {};
  const { timeZone } = store || {};

  return (
    <div className="flex bg-zyda-grey-50 p-4 flex-col mb-6">
      <div className={cx('flex justify-between mb-3', lang === 'ar' && 'flex-row-reverse')}>
        <div className={cx('flex w-1/2', lang === 'ar' && 'flex-row-reverse')}>
          <span className="text-sm font-bold" style={{ direction }}>
            {translate(ORDER_TYPE)}:
          </span>
          <span className="text-sm px-1" style={{ direction }}>
            {getDeliveryTypeText(deliveryType, translate)}
          </span>
        </div>
        <div className={cx('flex w-1/2', lang === 'ar' && 'flex-row-reverse')}>
          <span className="text-sm font-bold" style={{ direction }}>
            {deliveryType === DELIVERY_TYPE_ENUM.DELIVERY ? translate(DELIVERY_TIME) : translate(PICKUP_TIME)}:
          </span>
          <span className="text-sm px-1" style={{ direction }}>
            {isScheduled ? translate(SCHEDULED) : translate(ASAP)},
            {theTimeSlot
              ? ` ${theTimeSlot.toString()}, ${moment(expectedAt)
                  .tz(timeZone)
                  .locale(lang === 'ar' ? 'ar' : 'en-gb')
                  .format('DD MMM YYYY')}`
              : moment(expectedAt)
                  .tz(timeZone)
                  .locale(lang === 'ar' ? 'ar' : 'en-gb')
                  .format('DD MMM YYYY, h:mm A')}
          </span>
        </div>
      </div>
      <div className={cx('flex justify-between w-full', lang === 'ar' && 'flex-row-reverse')}>
        <div
          className={cx(
            'flex',
            lang === 'ar' && 'flex-row-reverse',
            deliveryType === DELIVERY_TYPE_ENUM.DELIVERY && 'w-1/2',
          )}
        >
          <span className="text-sm font-bold" style={{ direction }}>
            {translate(BRANCH)}:
          </span>
          <span className="text-sm px-1" style={{ direction }}>
            {branchData ? translate(branchData) : branchName}
          </span>
        </div>
        {deliveryType === DELIVERY_TYPE_ENUM.DELIVERY && (
          <div className={cx('flex flex-wrap w-1/2', lang === 'ar' && 'flex-row-reverse')}>
            {// checking if store use new delivery zone system (useDeliveryzonesMs)
            // NOTE later we will migrate all stores to new system
            deliveryZone?.zoneName && (
              <div className="flex-row w-full" style={{ direction }}>
                <span className="text-sm font-bold">{translate(ZONE)}:</span>
                <span className="text-sm px-1">{deliveryZone.zoneName}</span>
              </div>
            )}
            {deliveryZone?.zoneName && userData.address.cityName && (
              <div className="flex-row" style={{ direction }}>
                <span className="text-sm font-bold">{translate(CITY)}:</span>
                <span className="text-sm px-1">{userData.address.cityName}</span>
              </div>
            )}
            {deliveryZone?.zoneName && userData.address.areaName && (
              <div className="flex-row px-3" style={{ direction }}>
                <span className="text-sm font-bold">{translate(AREA)}:</span>
                <span className="text-sm px-1">{userData.address.areaName}</span>
              </div>
            )}
            {!deliveryZone?.zoneName && (
              <>
                <div className="flex-row">
                  <span className="text-sm font-bold" style={{ direction }}>
                    {translate(AREA)}:
                  </span>
                  <span className="text-sm px-1" style={{ direction }}>
                    {translate(userData?.address?.area)}
                  </span>
                </div>
                <div className="flex-row px-3">
                  <span className="text-sm font-bold" style={{ direction }}>
                    {translate(CITY)}:
                  </span>
                  <span className="text-sm px-1" style={{ direction }}>
                    {lang === 'en' ? userData?.address?.area?.cityTitleEn : userData?.address?.area?.cityTitleAr}
                  </span>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

OrderInvoiceInfo.propTypes = {
  lang: PropTypes.string,
  direction: PropTypes.string,
  order: PropTypes.shape({
    deliveryType: PropTypes.string,
    isScheduled: PropTypes.bool,
    expectedAt: PropTypes.string,
    branchData: PropTypes.string,
    branchName: PropTypes.string,
    userData: PropTypes.shape({
      address: PropTypes.shape({
        area: PropTypes.shape({ cityTitleEn: PropTypes.string, cityTitleAr: PropTypes.string }),
      }),
    }),
  }),
  translate: PropTypes.func,
  theTimeSlot: PropTypes.string,
  store: PropTypes.shape({ timeZone: PropTypes.string }),
};
export default OrderInvoiceInfo;
