import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { GIFT_FROM, NAME, MOBILE, GIFT_NOTE } from 'constants/translations';

const ThermalInvoiceGift = ({ order, lang, direction, translate }) => {
  const { userData: { name, phoneNumber, recipient } = {} } = order || {};

  return (
    <div>
      <div
        className={cx(
          'flex justify-between px-1 py-2 border-dashed border-gray-300 border-t w-full text-sm',
          lang === 'ar' && 'flex-row-reverse',
        )}
      >
        <div className={cx('flex', lang === 'ar' && 'flex-row-reverse')}>
          <span className="text-xs" style={{ direction }}>
            {translate(GIFT_FROM)}
          </span>
        </div>
      </div>
      <div className={cx('flex px-1', lang === 'ar' && 'flex-row-reverse')}>
        <div className="flex flex-col">
          <div className={cx('flex py-1', lang === 'ar' && 'flex-row-reverse')}>
            <span className="text-xs" style={{ direction }}>
              {translate(NAME)}:
            </span>
            <span className="text-xs px-3" style={{ direction }}>
              {name}
            </span>
          </div>
          <div className={cx('flex py-1', lang === 'ar' && 'flex-row-reverse')}>
            <span className="text-xs" style={{ direction }}>
              {translate(MOBILE)}:
            </span>
            <span className="text-xs px-3">{phoneNumber}</span>
          </div>
          {recipient.giftNotes && (
            <div className={cx('flex py-1', lang === 'ar' && 'flex-row-reverse')}>
              <span className="text-xs" style={{ direction }}>
                {translate(GIFT_NOTE)}:
              </span>
              <span className="text-xs px-3">{recipient.giftNotes}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

ThermalInvoiceGift.propTypes = {
  order: PropTypes.shape({
    userData: PropTypes.shape({ name: PropTypes.string, number: PropTypes.number, recipient: PropTypes.string }),
  }),
  lang: PropTypes.string.isRequired,
  direction: PropTypes.string.isRequired,
  translate: PropTypes.func,
};
export default ThermalInvoiceGift;
