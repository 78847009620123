import { gql } from "@apollo/client"

export const FETCH_STORE = gql`
  query($storeId: String!) {
    restaurant(id: $storeId) {
      id
      logoUrl
      photoUrl
      titleEn
      titleAr
      pageTitleEn
      pageTitleAr
      blockTabs
      descriptionEn
      descriptionAr
    }
    settings(restaurantId: $storeId) {
      id
      hideStoreName
      theme {
        palette {
          headerBackgroundColor
          primaryColor
        }
      }
    }
  }
`

export const UPDATE = gql`
  mutation(
    $storeId: String!
    $headerColor: String
    $primaryColor: String
    $titleEn: String
    $titleAr: String
    $pageTitleEn: String
    $pageTitleAr: String
    $descriptionEn: String
    $descriptionAr: String
    $hideStoreName: Boolean
  ) {
    updateRestaurant(
      id: $storeId
      titleEn: $titleEn
      titleAr: $titleAr
      pageTitleEn: $pageTitleEn
      pageTitleAr: $pageTitleAr
      descriptionEn: $descriptionEn
      descriptionAr: $descriptionAr
    ) {
      id
    }
    updateSettings(
      restaurantId: $storeId
      hideStoreName: $hideStoreName
      headerBackgroundColor: $headerColor
      primaryColor: $primaryColor
    ) {
      theme {
        palette {
          headerBackgroundColor
          primaryColor
        }
      }
    }
  }
`
