import { replaceParams } from "utils/index"
import { parse, stringify } from "query-string"
import useSelectedStore from "./useSelectedStore"

export default () => {
  const selectedStoreId = useSelectedStore()

  return (to, { storeId, omitQuery, appendQuery, params = {} } = {}) => {
    const langFromStorage = JSON.parse(localStorage.getItem("lang"))
    let { lang = langFromStorage, ...query } = parse(document.location.search)
    if (Array.isArray(lang)) {
      lang = lang[0]
      localStorage.setItem("lang", JSON.stringify(lang))
    }

    return (
      replaceParams(to, { storeId: storeId || selectedStoreId, ...params }) +
      `${
        !omitQuery ? `?${stringify({ lang, ...(appendQuery && query) })}` : ""
      }`
    )
  }
}
