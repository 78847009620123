import React, { useState } from "react"
import { Form, Formik } from "formik"

import { Button } from "components/kit"
import { Field, Text } from "components/service"
import { Input } from "components/form/elements"
import { Label, Row, Container, Footer } from "components/form/generic"
import * as translations from "constants/translations"
import * as utils from "./utils"
import * as data from "./data"
import VariantsTable from "./VariantsTable"


const EditVariant = ({
  isError,
  pageType,
  client,
  query,
  storeId,
  productId,
  updatePositions,
  variants,
  productLang,
  close,
  open,
  setErrorField,
  storeEn,
  storeAr,
  onSubmit,
  isMenu,
}) => {
  const [variantsData, setVariantsData] = useState(variants)
  const initalValues = {
    storeEn: storeEn,
    storeAr: storeAr,
    variants: variantsData,
  }
  const varinatInitialData = data.initialValues
  return (
    <Formik onSubmit={onSubmit} initialValues={initalValues}>
      {({ values, isSubmitting, setFieldValue, submitForm }) => {
        return (
          <Form className="px-4 my-4 w-full" key="variants-form">
            <Container>
              <Text
                value={translations.SECTION_TITLE}
                className="font-medium text-base"
              />
              <Row className="flex-col md:flex-row">
                <Label
                  error={isError.storeEn || isError.storeAr}
                  title={<Text value={translations.ENGLISH} />}
                  subtitle={
                    <Text
                      value={translations.YOU_CAN_OVERRIDE}
                      className="hidden md:block"
                    />
                  }
                >
                  <Field
                    data-testid={`${pageType}-variants-section-title-en`}
                    type="text"
                    name="storeEn"
                    placeholder={translations.TITLE_PLACEHOLDER[0]}
                    component={Input}
                    onChange={e => setFieldValue("storeEn", e.target.value)}
                  />
                </Label>
                <Label
                  error={isError.storeEn || isError.storeAr}
                  title={<Text value={translations.ARABIC} />}
                  subtitle={
                    <Text
                      value={translations.YOU_CAN_OVERRIDE}
                      className="block md:hidden"
                    />
                  }
                >
                  <Field
                    data-testid={`${pageType}-variants-section-title-ar`}
                    direction="rtl"
                    type="text"
                    name="storeAr"
                    placeholder={translations.TITLE_PLACEHOLDER[1]}
                    component={Input}
                    onChange={e => setFieldValue("storeAr", e.target.value)}
                  />
                </Label>
              </Row>
              <div className="overflow-x-auto">
                <Field
                  isMenu={isMenu}
                  open={open}
                  isEdit
                  pageType={pageType}
                  close={close}
                  setFieldValue={setFieldValue}
                  query={query}
                  setErrorField={setErrorField}
                  storeId={storeId}
                  name="variants"
                  productId={productId}
                  onSort={async items => {
                    utils.replaceVariants(
                      client,
                      query,
                      storeId,
                      productId,
                      items
                    )
                    const positions = items.map((item, i) => ({
                      id: +item.id,
                      position: i + 1,
                    }))

                    await updatePositions({ variables: { positions } })
                  }}
                  onRemove={index => {
                    let updatedVariants = [...values.variants].map((item, i) =>
                      index !== i
                        ? item
                        : {
                            ...item,
                            _destroy: "1",
                          }
                    )
                    setVariantsData(
                      updatedVariants.filter(item => item._destroy !== "1")
                    )
                    setFieldValue("variants", updatedVariants)
                  }}
                  onCreate={() => {
                    setVariantsData([...variantsData, varinatInitialData])
                    setFieldValue("variants", [
                      ...values.variants,
                      varinatInitialData,
                    ])
                  }}
                  items={variantsData}
                  productLang={productLang}
                  component={VariantsTable}
                />
              </div>
            </Container>
            <Footer>
              <div className="ml-auto">
                <div className="w-full flex items-center">
                  <Button
                    data-testid="product-variants-save"
                    kind="primary"
                    isSpinning={isSubmitting}
                    onClick={() => submitForm()}
                  >
                    <Text value={translations.SAVE_CHANGE} />
                  </Button>
                </div>
              </div>
            </Footer>
          </Form>
        )
      }}
    </Formik>
  )
}

export default EditVariant
