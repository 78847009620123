import React from 'react';
import * as translations from 'constants/translations';

import { ReactComponent as CreditCardIcon } from 'assets/credit-card-blue.svg';
import { ReactComponent as MadaIcon } from 'assets/mada-icon.svg';
import { ReactComponent as KnetIcon } from 'assets/knet-icon.svg';
import { ReactComponent as BenefitIcon } from 'assets/benefit.svg';
import { ReactComponent as ApplePayIcon } from 'assets/apple-pay-icon.svg';
import { ReactComponent as MeezaIcon } from 'assets/meeza-icon.svg';
import { PAYMENT_METHODS_ENUMS } from 'components/common/orders/Frame/utils';
import convertMultiLanguagesFromObjectToArray from '../../integrations/utils';
import PaymentMethodsSection from './PaymentMethodsSection';

const renderPaymentMethods = ({
  method,
  restaurantId,
  paymentCredentials,
  refetch,
  paymentGatewayId,
  lang,
  hasManualPaymentMethod,
  numOfEnabledOnlineMethods,
}) => {
  let title = '';
  let icon = '';

  switch (method.name) {
    case PAYMENT_METHODS_ENUMS.BENEFIT.toLowerCase():
      title = translations.BENEFIT;
      icon = <BenefitIcon className={lang === 'en' ? 'mr-2' : 'ml-2'} />;
      break;
    case PAYMENT_METHODS_ENUMS.KNET.toLowerCase():
      title = translations.KNET_NO_DASH;
      icon = <KnetIcon className={lang === 'en' ? 'mr-2' : 'ml-2'} />;
      break;
    case PAYMENT_METHODS_ENUMS.APPLE_PAY.toLowerCase():
      title = translations.APPLE_PAY;
      icon = <ApplePayIcon className={lang === 'en' ? 'mr-2' : 'ml-2'} />;
      break;
    case PAYMENT_METHODS_ENUMS.CREDIT_CARD.toLowerCase():
      title = translations.CREDIT_CARD_TITLE;
      icon = <CreditCardIcon className={lang === 'en' ? 'mr-2' : 'ml-2'} />;
      break;
    case PAYMENT_METHODS_ENUMS.MADA.toLowerCase():
      title = translations.MADA;
      icon = <MadaIcon className={lang === 'en' ? 'mr-2' : 'ml-2'} />;
      break;
    case PAYMENT_METHODS_ENUMS.MEEZA.toLowerCase():
      title = translations.MEEZA;
      icon = <MeezaIcon className={lang === 'en' ? 'mr-2' : 'ml-2'} />;
      break;
    default:
      title = '';
      icon = '';
  }

  return (
    <PaymentMethodsSection
      restaurantId={restaurantId}
      id={method.id}
      key={method.id}
      paymentCredentials={paymentCredentials}
      paymentGatewayId={paymentGatewayId}
      refetch={refetch}
      title={title}
      description={convertMultiLanguagesFromObjectToArray(method.gmvText)}
      isEnabled={method.enabled}
      icon={icon}
      hasManualPaymentMethod={hasManualPaymentMethod}
      numOfEnabledOnlineMethods={numOfEnabledOnlineMethods}
    />
  );
};

export default renderPaymentMethods;
