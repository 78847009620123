import React from "react"
import * as yup from "yup"
import * as translations from "constants/translations"
import { Text } from "components/service"

export const initialValues = {
  minQuantity: 1,
  maxQuantity: 10,
  optionsGroup: "",
  propertySections: "",
  multipleAddOnsEnabled: false,
}

export const validationSchema = yup.object().shape({
  minQuantity: yup.number().required("Min quantity required"),
  maxQuantity: yup.number().required("Max quantity required"),
  optionsGroup: yup.string().required("Choose option group"),
  variantIds: yup
    .array()
    .of(yup.string().required())
    .required(<Text value={translations.CHOOSE_VARIANT} />),
})
