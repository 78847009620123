import React, { useContext } from "react"
import { Mutation } from "@apollo/client/react/components"
import { navigate } from "@reach/router"
import { replaceParams, convertTime } from "utils/index"
import { Text, Link } from "components/service"
import { Button } from "components/kit"
import { context as userContext } from "context/user"
import { context as notificationsContext } from "context/notifications"
import { context as localeContext } from "context/locale"
import cx from "classnames"
import { getBackUrl } from "utils/history"
import * as translations from "constants/translations"
import { breadcrumbs } from "constants/translations"
import { Layout, Breadcrumbs } from "components/common/dashboard"
import { DeliveryForm } from "components/common/delivery"
import { concat } from "ramda"
import * as paths from "paths.js"
import * as schemas from "./schemas"

export default (props) => {
  const { selectedStore } = useContext(userContext)
  const notifications = useContext(notificationsContext)
  const { lang } = useContext(localeContext)
  let errorGph

  return (
    <Mutation
      mutation={schemas.ADD_AREAS_TO_ZONE}
      onCompleted={() => {
        notifications.show(<Text value={translations.DELIVERY_ZONE_ADDED} />)
        navigate(
          replaceParams(paths.deliveryList, { storeId: selectedStore.id })
        )
      }}
    >
      {(addAreaToZone, { loading: loadingAddAreas, data: resAddZone }) => (
        <Mutation
          mutation={schemas.CREATE_DELIVERY_ZONE}
          onError={({ graphQLErrors, networkError, response, operation }) => {
            if (graphQLErrors)
              graphQLErrors.map(({ message, locations, path, extensions }) => {
                errorGph = extensions.exception.body
              })
          }}
        >
          {(createZone, { loading: loadingCreate, data: resData }) => (
            <Layout
              top={
                <Breadcrumbs
                  links={[paths.setup, paths.deliveryList]}
                  path={breadcrumbs.SETUP_DELIVERY_CREATION}
                />
              }
            >
              <div
                className={cx(
                  "pb-6 flex",
                  lang === "ar" && "flex-row-reverse"
                )}
              >
                <div className="w-full md:w-2/3">
                  <DeliveryForm
                    addDelivery = {props.path.split('/')[4] === 'create' ? true : false} 
                    isSubmitting={loadingCreate || loadingAddAreas}
                    renderBottomPane={({ isSubmitting }) => [
                      <Button kind="primary" isSpinning={isSubmitting}>
                        <Text value={translations.SAVE} />
                      </Button>,
                      <Link to={getBackUrl() || paths.deliveryList} omitQuery>
                        <Button type="button" kind="secondary">
                          <Text value={translations.CANCEL} />
                        </Button>
                      </Link>,
                    ]}
                    onSubmit={(data, { setErrors }) => {
                      errorGph = null
                      let deliveryTime
                      let scheduleMaxPeriod
                      if (data.countryId !== selectedStore.countryId) {
                        deliveryTime = convertTime.fromDaysToMinutes(
                          data.deliveryTimeDays
                        )
                        scheduleMaxPeriod = convertTime.fromDaysToHours(
                          data.scheduleMaxPeriod
                        )
                      } else {
                        deliveryTime =
                          data.deliveryTime[1] === "hrs"
                            ? convertTime.fromHoursToMinutes(
                                data.deliveryTime[0]
                              )
                            : Number(data.deliveryTime[0])
                      }
                      let variables =
                        data.countryId !== selectedStore.countryId
                          ? {
                              restaurantId: selectedStore.id.toString(),
                              branchId: data.branchId,
                              countryId: data.countryId,
                              deliveryTime: deliveryTime,
                              deliveryFee: data.deliveryFee,
                              scheduleMaxPeriod: scheduleMaxPeriod,
                              minimumOrder: data.minimumOrder,
                              openingHours: data.openingHours,
                            }
                          : {
                              restaurantId: selectedStore.id.toString(),
                              branchId: data.branchId,
                              countryId: data.countryId,
                              deliveryTime: deliveryTime,
                              deliveryFee: data.deliveryFee,
                              minimumOrder: data.minimumOrder,
                              openingHours: data.openingHours,
                            }

                      createZone({
                        variables: variables,
                      }).then(res => {
                        let areasSelected = concat(
                          data.areas,
                          data.searchedAreas
                        ).map(v => parseInt(v, 10))

                        if (errorGph) {
                          let obj = {}
                          let num
                          let text
                          Object.entries(errorGph).map((value, index) => {
                            text = value[1][0].match(/.+(\S+\s)/)[0]
                            num = parseInt(
                              value[1][0].match(/[-]?(\d+)(?!.*\d)/)[0]
                            )

                            if (data.countryId !== selectedStore.countryId) {
                              if (value[0] === "delivery_time") {
                                return (obj.deliveryTimeDays =
                                  text + convertTime.fromMinutesToDays(num))
                              }
                            } else {
                              if (value[0] === "delivery_time")
                                return (obj.deliveryTime = value[1][0])
                            }
                            if (value[0] === "schedule_max_period") {
                              return (obj.scheduleMaxPeriod =
                                text + convertTime.fromHoursToDays(num))
                            }
                            if (value[0] === "delivery-fee")
                              return (obj.deliveryFee = value[1][0])
                          })
                          setErrors(obj)
                        } else if (areasSelected.length > 0) {
                          addAreaToZone({
                            variables: {
                              ids: areasSelected.filter(
                                (v, i) => areasSelected.indexOf(v) === i
                              ),
                              restaurantId: selectedStore.id.toString(),
                              branchId: data.branchId,
                              deliveryZoneId: res.data.createDeliveryZone.id,
                            },
                          })
                        } else {
                          notifications.show(
                            <Text value={translations.DELIVERY_ZONE_ADDED} />
                          )
                          navigate(
                            getBackUrl() ||
                              replaceParams(paths.deliveryList, {
                                storeId: selectedStore.id,
                              })
                          )
                        }
                      })
                    }}
                  />
                </div>
              </div>
            </Layout>
          )}
        </Mutation>
      )}
    </Mutation>
  )
}
