import React, { useContext } from "react"
import { Formik, Field } from "formik"
import cx from "classnames"
import { context as notificationsContext } from "context/notifications"
import { context as userContext } from "context/user"
import { context as localeContext } from "context/locale"
import { Button } from "components/kit"
import { Text } from "components/service"
import * as translations from "constants/translations"

export default ({
  isEntrySubmitting,
  setErrorField,
  submitFormGroup,
  onSubmit,
}) => {
  const notifications = useContext(notificationsContext)
  const { isPosCourier: isPosType } = useContext(userContext)
  const { lang, direction } = useContext(localeContext)
  const cellClassName = "p-2"

  return (
    <Formik
      initialValues={{ titleEn: "", titleAr: "", price: "", externalId: "" }}
    >
      {({ values, resetForm, isSubmitting, setValues }) => {
        const isDisabled =
          (!values.titleEn || !values.titleAr) && values.price === ""

        return (
          <>
            <div
              className="border-b border-gray-300 flex items-center overflow-x-auto"
              style={{ direction }}
            >
              <div className={cellClassName}>
                <Field
                  key="en"
                  type="text"
                  name="titleEn"
                  data-testid="product-options-titleEn"
                  className="w-40 md:w-full p-2 border border-gray-300"
                  placeholder={translations.EX_LARGE[0]}
                />
              </div>
              <div className={cellClassName}>
                <Field
                  key="ar"
                  type="text"
                  name="titleAr"
                  data-testid="product-options-titleAr"
                  className="w-40 md:w-full p-2 border border-gray-300"
                  placeholder={translations.EX_LARGE[1]}
                />
              </div>
              {isPosType && (
                <div className={cellClassName}>
                  <Field
                    type="text"
                    name="externalId"
                    data-testid="product-options-externalId"
                    className="w-40 md:w-full p-2 border border-gray-300"
                    placeholder={translations.EX_EXTERNAL_ID[0]}
                    component="input"
                  />
                </div>
              )}
              <div className={cellClassName}>
                <Field
                  type="text"
                  name="price"
                  data-testid="product-options-price"
                  className="w-40 md:w-full p-2 border border-gray-300"
                  placeholder={
                    translations.EX_1500[lang === "english" ? 0 : 1]
                  }
                />
              </div>
            </div>
            <div
              className="flex-2 border-b border-gray-300"
              style={{ direction }}
            >
              <button
                className={cx(
                  "my-2 px-2",
                  isDisabled
                    ? "text-gray-400 cursor-not-allowed"
                    : "text-primary-base"
                )}
                type="button"
                disabled={isDisabled || isSubmitting}
                onClick={() => {
                  if (!values.titleAr) {
                    notifications.show(
                      <Text value={translations.ADD_OPTION_AR_REQ} />,
                      "error"
                    )
                    setErrorField("lang", "language")
                    return
                  }
                  if (!values.titleEn) {
                    notifications.show(
                      <Text value={translations.ADD_OPTION_EN_REQ} />,
                      "error"
                    )
                    setErrorField("lang", "language")
                    return
                  }

                  onSubmit({
                    ...values,
                    price: values.price === "" ? 0.0 : parseFloat(values.price),
                  })
                  resetForm()
                }}
              >
                <Text value={translations.NEW_OPTION} className="inline" />
              </button>
            </div>

            <div className="flex flex-row mt-4 px-4">
              <div className="flex-1 flex items-stretch flex-row justify-end">
                <Button
                  type="button"
                  kind="primary"
                  size="option"
                  data-testid="product-createGroup-save"
                  onClick={submitFormGroup}
                  isSpinning={isEntrySubmitting}
                >
                  <Text value={translations.SAVE} className="inline px-6" />
                </Button>
              </div>
            </div>
          </>
        )
      }}
    </Formik>
  )
}
