import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import * as translations from 'constants/translations';
import { Text } from 'components/service';
import { LinkForm } from 'components/common/WebLink';
import { Button } from 'components/kit';
import { context as localeContext } from 'context/locale';
import { webLinksTypes } from 'components/common/WebLink/data';
import useAddWebLink from '../hooks/useAddWebLink';

const AddWebLink = ({ setIsAddingNewLink }) => {
  const { lang } = useContext(localeContext);
  const [handleOnAddClick, { loading: isAddLoading }] = useAddWebLink({ lang });

  const handleOnSubmit = async formData => {
    await handleOnAddClick(formData);
    setIsAddingNewLink(false);
  };

  return (
    <div className="flex border border-gray-300 pb-4 mb-4 flex-col rounded-lg">
      <LinkForm
        linksTypes={webLinksTypes}
        isSubmitting={isAddLoading}
        formClassName="px-4"
        renderTopPane={() => (
          <div className="my-2 py-2 flex justify-between border-b-2 px-4">
            <div>
              <Text className="font-medium text-black" value={translations.ADD_NEW_LINK} />
              <Text value={translations.URL} />
            </div>
            <div>
              <Button type="button" kind="link" onClick={() => setIsAddingNewLink(false)}>
                <Text value={translations.CANCEL} />
              </Button>
            </div>
          </div>
        )}
        renderBottomPane={({ isSubmitting }) => (
          <div>
            <Button kind="primary" isSpinning={isSubmitting}>
              <Text value={translations.SAVE} />
            </Button>
          </div>
        )}
        onSubmit={handleOnSubmit}
      />
    </div>
  );
};

AddWebLink.propTypes = {
  setIsAddingNewLink: PropTypes.func.isRequired,
};

export default AddWebLink;
