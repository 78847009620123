import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { InfoIcon, MinusCircleIcon, CheckCircleIcon } from '@zydalabs/zac-icons-react';

import { context as localeContext } from 'context/locale';
import { context as customersContext } from 'context/customers';
import { Text } from 'components/service';
import { Button } from '@zydalabs/zac-react';
import {
  DUPLICATED_NUMBERS,
  DUPLICATED_NUMBERS_NOTE,
  INVALID_NUMBERS,
  INVALID_NUMBERS_NOTE,
  PROCEED,
  TOTAL_UPLOADED_NUMBERS,
  UPLOADED_FILE_NAME,
  UPLOAD_ANOTHER_FILE,
  VALID_NUMBERS,
  VALID_NUMBERS_NOTE,
} from 'constants/translations';
import { Modal } from 'components/kit';
import { fileControllerUtils, fileErrorCodes } from '../utils';
import CampaignContactsFileNameModal from '../CampaignContactsFileNameModal';

const CampaignContactsFileValidation = ({ setFieldValue }) => {
  const { fileController, setFileController } = useContext(customersContext);
  const { direction, translate } = useContext(localeContext);
  const { errors, recordCount, validRecordCount } = fileController.uploadingFileResponse;
  const invalidPhoneNumbers = errors.find(item => item.error_code === fileErrorCodes.INVALID_PHONE_CODE) || null;
  const duplicatePhoneNumbers = errors.find(item => item.error_code === fileErrorCodes.DUPLICATE_PHONE_CDE) || null;

  return (
    <div className="p-4">
      <div className="border-b pb-3">
        <Text value={TOTAL_UPLOADED_NUMBERS(recordCount)} className="font-bold" />
      </div>

      <div className="p-4 mt-4 bg-zyda-green-25 flex items-start" style={{ direction }}>
        <CheckCircleIcon
          width="21"
          color="#37995D"
          className={cx('flex-shrink-0', direction === 'rtl' ? 'ml-2' : 'mr-2')}
        />
        <div>
          <Text value={VALID_NUMBERS(validRecordCount)} className="font-bold" />
          <Text value={VALID_NUMBERS_NOTE} className="mt-1" />
        </div>
      </div>
      {errors.length >= 1 && (
        <>
          {duplicatePhoneNumbers && (
            <div className="p-4 mt-4 bg-zyda-yellow-25 flex items-start" style={{ direction }}>
              <InfoIcon
                width="21"
                color="#B48A00"
                className={cx('flex-shrink-0', direction === 'rtl' ? 'ml-2' : 'mr-2')}
              />
              <div>
                <Text value={DUPLICATED_NUMBERS(duplicatePhoneNumbers?.violating_records)} className="font-bold" />
                <Text value={DUPLICATED_NUMBERS_NOTE} className="mt-1" />
              </div>
            </div>
          )}
          {invalidPhoneNumbers && (
            <div className="p-4  mt-4 bg-zyda-red-25 flex items-start" style={{ direction }}>
              <MinusCircleIcon
                width="21"
                color="#E04848"
                className={cx('flex-shrink-0', direction === 'rtl' ? 'ml-2' : 'mr-2')}
              />
              <div>
                <Text value={INVALID_NUMBERS(invalidPhoneNumbers?.violating_records)} className="font-bold" />
                <Text value={INVALID_NUMBERS_NOTE} className="mt-1" />
              </div>
            </div>
          )}
        </>
      )}

      <div className="mt-12 flex justify-end">
        <div className={direction === 'rtl' ? 'ml-2' : 'mr-2'}>
          <Button
            onClick={() => {
              setFileController(fileControllerUtils);
            }}
            type="button"
            variant="tertiary"
            size="medium"
            rounded
            text={translate(UPLOAD_ANOTHER_FILE)}
          />
        </div>
        <div>
          <Modal>
            {({ open, close }) => (
              <Button
                onClick={() =>
                  open({
                    size: 'max-w-lg',
                    title: <Text value={UPLOADED_FILE_NAME} />,
                    body: <CampaignContactsFileNameModal close={close} setFieldValue={setFieldValue} />,
                  })
                }
                type="button"
                size="medium"
                rounded
                text={translate(PROCEED)}
              />
            )}
          </Modal>
        </div>
      </div>
    </div>
  );
};

CampaignContactsFileValidation.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
};

export default CampaignContactsFileValidation;
