import React from "react"
import { Box } from "components/kit"
import { Text } from "components/service"
import * as translations from "constants/translations"
import Accordion from "./Accordion"

export default ({
  variants,
  storeId,
  productId,
  query,
  open,
  closeModal,
  pageType,
}) => (
  <Box
    title={<Text value={translations.VARIANT_IMAGE} />}
    subtitle={<Text value={translations.UPLOAD_AND_SORT_IMAGES} />}
    body={
      <div className="px-4">
        <Accordion
          query={query}
          items={variants}
          productId={productId}
          storeId={storeId}
          close={closeModal}
          open={open}
          pageType={pageType}
        />
      </div>
    }
  />
)
