import React from 'react';
import { navigate } from '@reach/router';
import moment from 'moment';

import * as paths from 'paths.js';
import { DatePicker } from 'components/kit';
import { Text } from 'components/service';
import DATE_RANGES from 'constants/date';
import { dateQueryFormat, formatDate, stringifyRange } from 'utils/date';
import { DELIVERY_BUSY_MODE } from 'components/common/branch/Busy/utils';
import {
  MANUAL_ORDER_DROPDOWN,
  DELIVERY,
  PICKUP,
  ALL_ORDERS,
  PENDING_ORDERS,
  ACCEPTED_ORDERS,
  READY_ORDERS,
  DISPATCHED_ORDERS,
  DELIVERED_ORDERS,
  COMPLETED_ORDERS,
  CANCELED_ORDERS,
  WAITING_FOR_PAYMENT_ORDERS,
  PAYMENT_FAILED_ORDERS,
  TODAY,
  LAST_7_DAYS,
  LAST_30_DAYS,
  ALL_TIME,
  CUSTOM_DATE,
  BEACH,
} from 'constants/translations';
import { ORDER_STATUS } from 'constants/order';
import OrderFrameTabTitles from './OrderFrameTabTitles';
import { FRAME_ENUM, validateRange } from './utils';

export const orderFilterStatus = [
  {
    id: ORDER_STATUS.ALL,
    title: <Text value={ALL_ORDERS} />,
  },
  {
    id: ORDER_STATUS.SUBMITTED,
    title: <Text value={PENDING_ORDERS} />,
  },
  {
    id: ORDER_STATUS.ACCEPTED,
    title: <Text value={ACCEPTED_ORDERS} />,
  },
  {
    id: ORDER_STATUS.READY,
    title: <Text value={READY_ORDERS} />,
  },
  {
    id: ORDER_STATUS.DISPATCHED,
    title: <Text value={DISPATCHED_ORDERS} />,
  },
  {
    id: ORDER_STATUS.DELIVERED,
    title: <Text value={DELIVERED_ORDERS} />,
  },
  {
    id: ORDER_STATUS.CANCELED,
    title: <Text value={CANCELED_ORDERS} />,
  },
  {
    id: ORDER_STATUS.WAITING_FOR_PAYMENT,
    title: <Text value={WAITING_FOR_PAYMENT_ORDERS} />,
  },
  {
    id: ORDER_STATUS.PAYMENT_FAILED,
    title: <Text value={PAYMENT_FAILED_ORDERS} />,
  },
];

export const deliveryTypes = [
  {
    id: DELIVERY_BUSY_MODE.MANUAL,
    title: <Text full={false} value={MANUAL_ORDER_DROPDOWN} />,
  },
  {
    id: DELIVERY_BUSY_MODE.DELIVERY,
    title: <Text full={false} value={DELIVERY} />,
  },
  {
    id: DELIVERY_BUSY_MODE.PICKUP,
    title: <Text full={false} value={PICKUP} />,
  },
  {
    id: DELIVERY_BUSY_MODE.BEACH,
    title: <Text full={false} value={BEACH} />,
  },
];
export const fetchDateOptions = (range, notifications, setFilters, filters, timeZone) => [
  {
    value: DATE_RANGES.ZERO,
    title: <Text value={TODAY} />,
  },
  {
    value: DATE_RANGES.SEVEN_DAYS,
    title: <Text value={LAST_7_DAYS} />,
  },
  {
    value: DATE_RANGES.THIRTY_DAYS,
    title: <Text value={LAST_30_DAYS} />,
  },
  {
    value: DATE_RANGES.ALL,
    title: <Text value={ALL_TIME} />,
  },
  {
    value: DATE_RANGES.CUSTOM,
    className: 'px-4',
    hideRadioBtn: true,
    title: `${formatDate(range[0])} -> ${formatDate(range[1])}`,
    body: (
      <DatePicker
        range={range.map(date => moment(date, dateQueryFormat).toDate())}
        pickerTitle={<Text value={CUSTOM_DATE} className="text-base font-medium block mb-2" />}
        onChange={rangeMap => {
          const error = validateRange(rangeMap);
          if (error) {
            return notifications.show(error, 'error');
          }
          return setFilters({
            ...filters,
            date: DATE_RANGES.CUSTOM,
            range: stringifyRange(
              rangeMap.map((date, index) =>
                index === 0
                  ? moment(date)
                      .startOf('day')
                      .tz(timeZone, true)
                      .format()
                  : moment(date)
                      .endOf('day')
                      .tz(timeZone, true)
                      .format(),
              ),
            ),
          });
        }}
      />
    ),
  },
];

export const fetchAllTabsItems = (orders, setSelectedStatus, replace, replaceParams, storeId) => moreTabs => {
  if (!moreTabs) {
    return [
      {
        id: ORDER_STATUS.ALL,
        title: (
          <OrderFrameTabTitles name={<Text value={ALL_ORDERS} className="inline" />} status="all" data={orders.data} />
        ),
        to: paths.allOrders,
        testId: 'all-orders',
        onSelect: () => {
          setSelectedStatus(ORDER_STATUS.ALL);
          navigate(replace(paths.allOrders, { appendQuery: true, omitQuery: false }));
        },
      },
      {
        id: ORDER_STATUS.SUBMITTED,
        title: (
          <OrderFrameTabTitles
            name={<Text value={PENDING_ORDERS} className="inline" />}
            status={ORDER_STATUS.SUBMITTED}
            data={orders.data}
          />
        ),
        to: paths.pendingOrders,
        testId: 'pending-orders',
        onSelect: () => {
          setSelectedStatus(ORDER_STATUS.SUBMITTED);
          navigate(replace(paths.pendingOrders, { appendQuery: true, omitQuery: false }));
        },
      },
      {
        id: ORDER_STATUS.ACCEPTED,
        title: (
          <OrderFrameTabTitles
            name={<Text value={ACCEPTED_ORDERS} className="inline" />}
            status={ORDER_STATUS.ACCEPTED}
            data={orders.data}
          />
        ),
        to: paths.acceptedOrders,
        testId: 'accepted-orders',
        onSelect: () => {
          setSelectedStatus(ORDER_STATUS.ACCEPTED);
          navigate(replace(paths.acceptedOrders, { appendQuery: true, omitQuery: false }));
        },
      },
      {
        id: ORDER_STATUS.READY,
        title: (
          <OrderFrameTabTitles
            name={<Text value={READY_ORDERS} className="inline" />}
            status={ORDER_STATUS.READY}
            data={orders.data}
          />
        ),
        to: paths.readyOrders,
        testId: 'ready-orders',
        onSelect: () => {
          setSelectedStatus(ORDER_STATUS.READY);
          navigate(replace(paths.readyOrders, { appendQuery: true, omitQuery: false }));
        },
      },
      {
        id: ORDER_STATUS.DISPATCHED,
        title: (
          <OrderFrameTabTitles
            name={<Text value={DISPATCHED_ORDERS} className="inline" />}
            status={ORDER_STATUS.DISPATCHED}
            data={orders.data}
          />
        ),
        to: paths.dispatchedOrders,
        testId: 'dispatched-orders',
        onSelect: () => {
          navigate(
            replace(paths.dispatchedOrders, {
              appendQuery: true,
              omitQuery: false,
            }),
          );
          setSelectedStatus(ORDER_STATUS.DISPATCHED);
        },
      },
      {
        id: ORDER_STATUS.FULFILLED,
        title: (
          <OrderFrameTabTitles
            name={<Text value={COMPLETED_ORDERS} className="inline" />}
            status={ORDER_STATUS.COMPLETED}
            data={orders.data}
          />
        ),
        to: paths.completedOrders,
        testId: 'completed-orders',
        onSelect: () => {
          setSelectedStatus(ORDER_STATUS.FULFILLED);
          navigate(
            replaceParams(paths.completedOrders, {
              storeId,
            }),
          );
        },
      },
    ];
  }
  return [
    {
      id: ORDER_STATUS.CANCELED,
      title: (
        <OrderFrameTabTitles
          name={<Text value={CANCELED_ORDERS} className="inline" />}
          status={ORDER_STATUS.CANCELED}
          data={orders.data}
        />
      ),
      onSelect: () => {
        setSelectedStatus(ORDER_STATUS.CANCELED);
        navigate(replace(paths.canceledOrders, { appendQuery: true, omitQuery: false }));
      },
    },
    {
      id: ORDER_STATUS.WAITING_FOR_PAYMENT,
      title: (
        <OrderFrameTabTitles
          name={<Text value={WAITING_FOR_PAYMENT_ORDERS} className="inline" />}
          status={ORDER_STATUS.WAITING_FOR_PAYMENT}
          data={orders.data}
        />
      ),
      onSelect: () => {
        setSelectedStatus(ORDER_STATUS.WAITING_FOR_PAYMENT);
        navigate(replace(paths.waitingForPaymentOrders, { appendQuery: true, omitQuery: false }));
      },
    },
    {
      id: ORDER_STATUS.PAYMENT_FAILED,
      title: (
        <OrderFrameTabTitles
          name={<Text value={PAYMENT_FAILED_ORDERS} className="inline" />}
          status={ORDER_STATUS.PAYMENT_FAILED}
          data={orders.data}
        />
      ),
      onSelect: () => {
        setSelectedStatus(ORDER_STATUS.PAYMENT_FAILED);
        navigate(
          replace(paths.paymentFailedOrders, {
            appendQuery: true,
            omitQuery: false,
          }),
        );
      },
    },
  ];
};

export const initialOrderQuery = {
  branch: FRAME_ENUM.ALL,
  search_value: '',
  search_status: '',
  date: ORDER_STATUS.ALL,
  payment_methods: [],
  delivery_type: [],
  is_manual_order: false,
  areas: [],
  delivery_zone_in: [],
  driver_id: '',
  sort_by: FRAME_ENUM.CREATED_AT,
};

export const initalOrderFilters = query => {
  const filterObject = {
    payment_methods: typeof query.payment_methods === 'string' ? [query.payment_methods] : query.payment_methods,
    delivery_type: typeof query.delivery_type === 'string' ? [query.delivery_type] : query.delivery_type,
    areas: typeof query.areas === 'string' ? [query.areas] : query.areas,
    delivery_zone_in: typeof query.delivery_zone_in === 'string' ? [query.delivery_zone_in] : query.delivery_zone_in,
    driver_id: query.driver_id,
    date: query.date,
    is_manual_order: query.is_manual_order,
  };
  return filterObject;
};
