import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Text } from 'components/service';
import { PUBLISHED, UNPUBLISHED } from 'constants/translations';

const PublishedState = ({ published, direction }) => (
  <div className="flex flex-row align-middle items-center">
    <span
      className={cx(
        'w-2 h-2 block rounded-full text-base',
        direction === 'rtl' ? 'ml-1' : 'mr-1',
        published ? 'bg-green-600' : 'bg-red-600',
      )}
    />
    {published ? <Text className="text-xs" value={PUBLISHED} /> : <Text className="text-xs" value={UNPUBLISHED} />}
  </div>
);

PublishedState.propTypes = {
  published: PropTypes.bool.isRequired,
  direction: PropTypes.oneOf(['ltr', 'rtl']),
};

export default PublishedState;
