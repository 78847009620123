import React from 'react';
import ProductForm from '../AdvancedCreation/ProductForm';
import CategoryForm from './CategoryForm';
import * as translations from 'constants/translations';
import { Text } from 'components/service';

const SidePanel = props => {
  const SIDE_PANEL_COMPNENT_TYPE = {
    1: CategoryForm,
    2: ProductForm,
  };

  const { sidePanelComponent } = props;
  if (sidePanelComponent && sidePanelComponent.component !== null) {
    const Comp = SIDE_PANEL_COMPNENT_TYPE[sidePanelComponent.component];
    return (
      <div data-testid="side-panel">
        <Comp isMenu {...props} />
      </div>
    );
  } else {
    return (
      <div data-testid="side-panel">
        <h1 className="mt-64 text-gray-700 text-center">
          <Text value={translations.SELECT_ITEM} />
        </h1>
      </div>
    );
  }
};

export default SidePanel;
