import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import { NODE_ENV_PROD, NODE_ENV_STG } from 'constants/environments';

const init: () => void = () => {
  const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
  const shouldReportToSentry = [NODE_ENV_STG, NODE_ENV_PROD].includes(process.env.REACT_APP_ENV);

  if (shouldReportToSentry) {
    Sentry.init({
      release: process.env.RELEASE,
      dsn: SENTRY_DSN,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 0.1,
      environment: process.env.REACT_APP_ENV,
      autoSessionTracking: true,
    });
  }
};

const externalLogger = {
  async log({ error, extraData }) {
    const { requestSchema, ...rest } = extraData || {};
    Sentry.captureException(error, extraData ? { extra: rest, fingerprint: [requestSchema] } : {});
    await Sentry.flush(2000);
  },
};

const LoggerLevels = {
  error({ error, extraData }: { error: any; extraData: any }) {
    externalLogger.log({ error, extraData });
  },
};

const logger = {
  ...LoggerLevels,
  init,
};

export default logger;
