import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Text } from 'components/service';

const Badge = ({ title, color = '' }) => (
  <div className={cx('rounded-full text-xs px-2 py-1 h-5 flex items-center', !color ? 'bg-gray-300' : color)}>
    <Text value={title} className="text-white" />
  </div>
);

Badge.propTypes = {
  title: PropTypes.arrayOf(PropTypes.string).isRequired,
  color: PropTypes.string,
};

export default Badge;
