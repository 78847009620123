import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useQuery, NetworkStatus } from '@apollo/client';
import camelize from 'camelize';

import { context as localeContext } from 'context/locale';
import { usePriceFormat, useSelectedStore } from 'hooks';
import * as translations from 'constants/translations';
import { Spinner, LoadingContainer, Tabs, DropDown } from 'components/kit';
import { Text } from 'components/service';
import Fallback from 'components/common/Fallback';
import * as paths from 'paths';
import EmptyState from 'components/common/EmptyState';
import { ReactComponent as IconPieData } from 'assets/icon-pie-data.svg';
import { ReactComponent as SkeletonBars } from 'assets/skeleton-bars.svg';
import {
  DELIVERY_TYPE_CHART_COLORS,
  PAYMENTS_METHOD_CHART_COLORS,
  NEW_VS_RETURNING_CHART_COLORS,
} from 'utils/colorLibraries';
import * as schemas from './schemas';
import BarChart from '../../Main/charts/BarChart';
import ReportLink from '../../Main/ReportLink';
import Period from '../../Main/Period';
import { dataCustomers, getBehaviorBreakdownData } from './utils';
import { BEHAVIOR_BREAKDOWN_TABS } from '../constants';
import { ANALYTICS_TAB_NAMES } from '../../constants';

const Breakdown = ({ date, setDate, selection, setSelection, range }) => {
  const { direction } = useContext(localeContext);
  const formatPrice = usePriceFormat();
  const storeId = useSelectedStore();
  const [breakdownData, setBreakdownData] = useState();
  const [selectedTab, setSelectedTab] = useState(BEHAVIOR_BREAKDOWN_TABS.NEW_VS_RETURNING);
  const display =
    selection === ANALYTICS_TAB_NAMES.totalSales.snakeCaseName ||
    selection === ANALYTICS_TAB_NAMES.averageOrderValue.snakeCaseName
      ? formatPrice
      : x => x || 0;

  useEffect(() => {
    const interval = setInterval(() => {
      moment(range.end).diff(moment(range.start), 'days') > 31 && setDate('month');
    }, 1000);
    return () => clearInterval(interval);
  }, [range, setDate]);

  const query = {
    total_sales: schemas.TOTAL_SALES,
    avg_order_value: schemas.AVG_ORDER_VALUE,
    total_orders: schemas.TOTAL_ORDERS,
    total_customers: schemas.TOTAL_CUSTOMERS,
  };

  const result = useQuery(query[selection], {
    variables: {
      storeId,
      range,
      date,
      breakdown: selectedTab,
    },
    skip: selectedTab === BEHAVIOR_BREAKDOWN_TABS.NEW_VS_RETURNING,
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const newVSreturning = useQuery(schemas.NEW_VS_RETURNING_CUSTOMERS, {
    variables: {
      storeId,
      range,
      date,
    },
    skip: selectedTab !== BEHAVIOR_BREAKDOWN_TABS.NEW_VS_RETURNING,
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const handleRefetch = () => {
    if (result.error) result.refetch();
    if (newVSreturning.error) newVSreturning.refetch();
  };

  useEffect(() => {
    !result.loading && selectedTab !== BEHAVIOR_BREAKDOWN_TABS.NEW_VS_RETURNING
      ? setBreakdownData(getBehaviorBreakdownData(result))
      : !newVSreturning.loading &&
        newVSreturning?.data?.newVsReturningCustomers &&
        setBreakdownData(dataCustomers(newVSreturning?.data?.newVsReturningCustomers, camelize(selection)));
  }, [newVSreturning, result, selectedTab, selection]);

  const bodyTabs = {
    delivery_type: {
      data: !result.loading && breakdownData,
      colors: DELIVERY_TYPE_CHART_COLORS,
      path: paths.breakdownDeliveryPickup,
    },
    payment_method: {
      data: !result.loading && breakdownData,
      colors: PAYMENTS_METHOD_CHART_COLORS,
      path: paths.breakdownPaymentMethods,
    },
    new_vs_returning: {
      data: !newVSreturning.loading && breakdownData,
      colors: NEW_VS_RETURNING_CHART_COLORS,
      path: paths.breakdownNewVsReturning,
    },
  };

  const dataTypes = {
    total_sales: <Text value={translations.TOTAL_SALES} />,
    avg_order_value: <Text value={translations.AVERAGE_ORDER_VALUE} />,
    total_orders: <Text value={translations.SALES_TOTAL_ORDERS} />,
    total_customers: <Text value={translations.TOTAL_CUSTOMERS} />,
  };

  return (
    <div>
      <Tabs
        stateless
        items={[
          {
            title: <Text value={translations.NEW_VS_RETURNING} />,
            selected: selectedTab === BEHAVIOR_BREAKDOWN_TABS.NEW_VS_RETURNING,
            onClick: () => setSelectedTab(BEHAVIOR_BREAKDOWN_TABS.NEW_VS_RETURNING),
          },
          {
            title: <Text value={translations.PAYMENT_METHOD} />,
            selected: selectedTab === BEHAVIOR_BREAKDOWN_TABS.PAYMENT_METHOD,
            onClick: () => setSelectedTab(BEHAVIOR_BREAKDOWN_TABS.PAYMENT_METHOD),
          },
          {
            title: <Text value={translations.DELIVERY_VS_PICKUP} />,
            selected: selectedTab === BEHAVIOR_BREAKDOWN_TABS.DELIVERY_TYPE,
            onClick: () => setSelectedTab(BEHAVIOR_BREAKDOWN_TABS.DELIVERY_TYPE),
          },
        ]}
      />
      <div className="mt-4 flex justify-between items-center">
        <DropDown
          dropdownClassName="w-64"
          noOverflow
          optionSelected={selection}
          onSelect={option => setSelection(option)}
          data={Object.entries(dataTypes).map(item => ({
            id: item[0],
            title: item[1],
          }))}
          icon="keyboard_arrow_down"
          position={direction === 'rtl' ? 'right' : 'left'}
        />
        <Period
          disabled={moment(range.end).diff(moment(range.start), 'days') > 31}
          value={date}
          onChange={v => setDate(v)}
        />
      </div>
      <div className="bg-white border border-gray-300 mt-4">
        {!breakdownData ||
        result.networkStatus === NetworkStatus.refetch ||
        newVSreturning.networkStatus === NetworkStatus.refetch ? (
          <div className="my-4">
            <Spinner />
          </div>
        ) : result.error || newVSreturning.error ? (
          <div className="my-5 px-4 text-center">
            <Fallback refetch={handleRefetch} />
          </div>
        ) : (
          <LoadingContainer isLoading={result.loading || newVSreturning.loading}>
            {!breakdownData?.currentPeriod?.data ? (
              <EmptyState
                skeletonComponent={<SkeletonBars className="w-full" />}
                iconComponent={<IconPieData />}
                emptyStateText={translations.EMPTY_BARS_CHART}
              />
            ) : (
              <BarChart
                data={{ ...bodyTabs[selectedTab] }}
                range={range}
                type={date}
                selection={selection}
                selectedDataType={dataTypes[selection]}
                display={display}
              />
            )}
          </LoadingContainer>
        )}
        <ReportLink to={bodyTabs[selectedTab].path} />
      </div>
    </div>
  );
};

Breakdown.propTypes = {
  date: PropTypes.oneOf(['day', 'month']).isRequired,
  setDate: PropTypes.func.isRequired,
  selection: PropTypes.string.isRequired,
  setSelection: PropTypes.func.isRequired,
  range: PropTypes.shape({ start: PropTypes.string, end: PropTypes.string }).isRequired,
};

export default Breakdown;
