import { GraphQLClient } from 'graphql-request';
import { GRAPHQL_CLIENT_NAMES } from 'service/constants';

const client = new GraphQLClient(`${process.env.REACT_APP_API_GATEWAY_URL}/v1/delivery_zones/graphql`);
const verdClient = new GraphQLClient(`${process.env.REACT_APP_API_GATEWAY_URL}/v1/delivery/graphql`);

const getClient = (clientName: string): any => {
  if (clientName === GRAPHQL_CLIENT_NAMES.VERD) return verdClient;
  return client;
};

export default getClient;
