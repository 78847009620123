import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { UserAddIcon } from '@heroicons/react/outline';

import { useSlideOverScroll, useSelectedStore } from 'hooks';
import * as translations from 'constants/translations';
import { context as userContext } from 'context/user';
import { context as localeContext } from 'context/locale';
import { Label, Row, Container } from 'components/form/generic';
import { Spinner, Button } from 'components/kit';
import { Field, Text } from 'components/service';
import { PhoneInput } from 'components/form/elements';
import SlideoverTitle from 'components/common/dashboard/Layout/Slideover/SlideoverTitle';
import { CUSTOMERS_SEARCH_BY_OPTIONS } from 'constants/customers';
import { debounce } from 'utils';
import PhoneNumberList from './PhoneNumberList';
import { GET_STOREFRONT_USERS } from '../schemas';
import { SLIDE_OVER_COMPONENTS_ENUMS, CUSTOMER_INFO_ENUMS } from '../utils';

const CustomerInfo = ({ setSlideOverComponent, setFieldValue, setSelectedStorefrontUser }) => {
  const { selectedStore } = useContext(userContext);
  const storeId = useSelectedStore();
  const { lang } = useContext(localeContext);
  const [storefrontUsers, setStorefrontUsers] = useState([]);
  const [totalNumberOfPages, setTotalNumberOfPages] = useState();

  const [query, setQuery] = useState({
    page: 1,
    limit: 15,
    searchValue: '',
    searchBy: CUSTOMERS_SEARCH_BY_OPTIONS.PHONE_NUMBER,
  });

  const storefrontUsersVariables = {
    storeId,
    page: parseInt(query.page),
    limit: parseInt(query.limit),
    phoneNumber: query.searchValue,
  };

  const { data: storefrontUsersData, loading: isLoadingUsers } = useQuery(GET_STOREFRONT_USERS, {
    variables: storefrontUsersVariables,
    fetchPolicy: 'network-only',
    onCompleted: data => setTotalNumberOfPages(data.customers.totalPages),
  });

  useEffect(() => {
    !isLoadingUsers &&
      !!storefrontUsersData?.customers?.customers?.length &&
      setStorefrontUsers(prevState => [...prevState, ...storefrontUsersData.customers.customers]);
  }, [query.page, storefrontUsersData]);

  useSlideOverScroll(() => {
    if (
      isLoadingUsers ||
      !storefrontUsersData?.customers?.customers.length ||
      storefrontUsersData?.customers?.customers.length < query.limit ||
      storefrontUsers.length === totalNumberOfPages
    )
      return;

    const nextPage = query.page + 1;
    setQuery({ ...query, page: nextPage });
  });

  const handleFilterPhoneNumber = phoneNumber => {
    if (phoneNumber !== '') {
      const filterNumber = () => {
        setStorefrontUsers([]);
        setQuery({
          ...query,
          searchValue: phoneNumber,
        });
        setFieldValue(CUSTOMER_INFO_ENUMS.PHONE_NUMBER, phoneNumber);
      };
      debounce(filterNumber, 500);
    }
  };

  const handleOnAddUserButton = () => {
    setSlideOverComponent({ component: SLIDE_OVER_COMPONENTS_ENUMS.CREATE_CUSTOMER });
  };

  const handleOnSelectingUser = user => {
    setSelectedStorefrontUser(user);
    setSlideOverComponent({ component: SLIDE_OVER_COMPONENTS_ENUMS.SF_WEBVIEW });
  };

  return (
    <div>
      <SlideoverTitle title={<Text value={translations.SELECT_CUSTOMER} />} />
      <div className="mt-6 relative flex-1">
        <Container>
          <Row className="px-6">
            <Label title={<Text value={translations.PHONE_NUMBER_ORDER} />}>
              <Field
                type="text"
                name={CUSTOMER_INFO_ENUMS.PHONE_NUMBER}
                country={selectedStore.countryCode.toLowerCase()}
                placeholder={
                  lang === 'en' ? translations.MOBILE_NUMBER_PLACEHOLDER[0] : translations.MOBILE_NUMBER_PLACEHOLDER[1]
                }
                component={PhoneInput}
                testId="phone-number"
                onChange={e => handleFilterPhoneNumber(e.target.value)}
              />
            </Label>
          </Row>
          {isLoadingUsers && <Spinner />}

          {storefrontUsers.length > 0 ? (
            <PhoneNumberList phoneNumbersList={storefrontUsers} handleOnSelectingUser={handleOnSelectingUser} />
          ) : (
            <div className="justify-center py-4">
              <div className="flex justify-center">
                <UserAddIcon className="text-gray-400 h-6 w-6" />
              </div>
              <div className="flex text-center py-3 justify-center">
                <Text value={translations.LOOKS_LIKE_NEW_CUSTOMER} className="text-gray-400" />
              </div>
              <div className="flex px-6 text-center py-3 justify-center">
                <Button kind="secondary" full onClick={handleOnAddUserButton} disabled={isLoadingUsers}>
                  <Text value={translations.ADD_CUSTOMER} />
                </Button>
              </div>
            </div>
          )}
        </Container>
      </div>
    </div>
  );
};

CustomerInfo.propTypes = {
  setSlideOverComponent: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setSelectedStorefrontUser: PropTypes.func.isRequired,
};

export default CustomerInfo;
