import React from 'react';
import { Typography, useTheme } from '@zydalabs/zac-react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { Text } from 'components/service';
import * as translations from 'constants/translations';

const ButtonShape = ({ setFieldValue, buttonShape }) => {
  const { colors } = useTheme();

  return (
    <div className="border-b border-gray-300 pb-6">
      <Typography variant="heading16" mt={24}>
        <Text value={translations.BUTTONS} />
      </Typography>
      <Typography variant="body14" mt={4} color={colors.positive.secondary} mb={24}>
        <Text value={translations.SELECT_BUTTONS} />
      </Typography>
      <div className="flex gap-6 flex-wrap xl:flex-nowrap">
        <div
          className={cx(
            'col-span-4 py-4 px-6 cursor-pointer border rounded w-56',
            buttonShape ? 'border-blue-500' : 'border-gray-300',
          )}
          role="presentation"
          onClick={() => setFieldValue('isButtonRounded', true)}
        >
          <Typography cursor="pointer" color={buttonShape ? colors.accent.primary : colors.positive.primary} mb={16}>
            <Text value={translations.CIRCLE} />
          </Typography>
          <div className="w-fill mx-auto rounded-3xl py-3" style={{ background: colors.positive.quaternary }}>
            <div className="w-16 h-3 rounded-3xl mx-auto" style={{ background: colors.positive.quaternary }} />
          </div>
        </div>
        <div
          className={cx(
            'col-span-4 py-4 px-6 cursor-pointer border rounded w-56',
            !buttonShape ? 'border-blue-500' : 'border-gray-300',
          )}
          role="presentation"
          onClick={() => setFieldValue('isButtonRounded', false)}
        >
          <Typography cursor="pointer" color={!buttonShape ? colors.accent.primary : colors.positive.primary} mb={16}>
            <Text value={translations.SHARP} />
          </Typography>
          <div className="w-fill mx-auto py-3" style={{ background: colors.positive.quaternary }}>
            <div className="w-16 h-3 mx-auto" style={{ background: colors.positive.quaternary }} />
          </div>
        </div>
      </div>
    </div>
  );
};

ButtonShape.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  buttonShape: PropTypes.bool.isRequired,
};

export default ButtonShape;
