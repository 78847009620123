import { STH_WENT_WRONG } from "constants/constant-errors"

export const updateCacheAfterCreate = (query, restaurantId, menuItemId) => (
  cache,
  { data: { createVariant: variant } }
) => {
  const variables = { restaurantId, id: menuItemId }
  const cacheData = cache.readQuery({
    query,
    variables,
  })

  cache.writeQuery({
    query,
    data: {
      ...cacheData,
      variants: [...cacheData.variants, variant],
    },
    variables,
  })
}

export const updateCacheAfterDelete = (query, restaurantId, menuItemId) => (
  cache,
  {
    data: {
      deleteVariant: { id: variantId },
    },
  }
) => {
  const variables = { restaurantId, id: menuItemId }
  const cacheData = cache.readQuery({
    query,
    variables,
  })

  cache.writeQuery({
    query,
    data: {
      ...cacheData,
      variants: cacheData.variants.filter(item => item.id !== variantId),
    },
    variables,
  })
}

export const replaceVariants = (
  client,
  query,
  restaurantId,
  menuItemId,
  variants
) => {
  const variables = { restaurantId, id: menuItemId }
  const cacheData = client.readQuery({ query, variables })

  client.writeQuery({
    query,
    data: {
      ...cacheData,
      variants,
    },
    variables,
  })
}

export const handleSubmissionErrors = err => {
  const { body } = err.graphQLErrors[0].extensions.exception

  if (body.external_id) {
    return "External Id is required"
  }
  if (body.title_en) {
    return ["English title can't be blank", "أدخل الاسم باللغة الإنجليزية"]
  }
  if (body.title_ar) {
    return ["Arabic title can't be blank", "أدخل الاسم باللغة العربية"]
  }
  if (body.price) {
    return [
      "Price can't be blank, Original price can not be less or equal than Discounted price",
      "“أدخل السعر، السعر الأصلي لا يجب أن يساوي أو يكون أقل من السعر المخفض",
    ]
  }
  if (body.discounted_price){
    return [
      "Discounted price can not be more than or equal Original price",
      "لا يمكن أن يكون السعر المخفض أكثر من السعر الأصلي أو مساويًا له",
    ]
  }
  return STH_WENT_WRONG
}
