import React from "react"
import { Container } from "components/form/generic"
import styled from "styled-components"

export default ({ image }) => {
  return (
    <div className="px-6 py-2">
      <Container>
        <Image>
          <img src={image} />
        </Image>
      </Container>
    </div>
  )
}

const Image = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`
