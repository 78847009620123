import { gql } from '@apollo/client';

export const VALIDATE_API_KEY = gql`
  mutation($courierName: String!, $APIKey: String!) {
    validateAPIKey(courierName: $courierName, APIKey: $APIKey) {
      valid
    }
  }
`;

export const UPDATE_COURIER_BRANCH_ID = gql`
  mutation linkInternalBranchToCourierBranch(
    $storeId: String
    $branchInternalId: String
    $branchExternalId: String
    $courierName: String
  ) {
    linkInternalBranchToCourierBranch(
      storeId: $storeId
      branchInternalId: $branchInternalId
      branchExternalId: $branchExternalId
      courierName: $courierName
    ) {
      branchInternalId
      branchExternalId
      courierName
    }
  }
`;
