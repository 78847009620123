import React, { useContext } from 'react';
import { Text } from 'components/service';
import { Button, Stack, More, ModalConfirmBoxBody } from 'components/kit';
import * as translations from 'constants/translations';
import { context as localeContext } from 'context/locale';
import { useMobile } from 'hooks/index';
import DeleteProduct from './DeleteProductModal';
import cx from 'classnames';

const ProductFormFrame = ({
  titleEn,
  titleAr,
  open,
  close,
  resetState,
  productId,
  isCreatingProduct,
  isUpdatingProduct,
  refetchMenuPageData,
  storeId,
}) => {
  const isMobile = useMobile();
  const { lang, direction } = useContext(localeContext);

  return (
    !isMobile && (
      <div className={cx('flex items-center justify-between pt-2 py-4', lang === 'en' ? 'pl-4' : 'pr-4')}>
        <div className="flex">
          <div
            className={cx(lang === 'en' ? 'mr-2' : 'ml-2')}
            onClick={e => {
              e.preventDefault();
              open({
                testId: 'reset-side-panel',
                title: <Text value={translations.CONFIRM_CANCELLATION} className="text-xl" />,
                body: (
                  <ModalConfirmBoxBody
                    areYouSureText={translations.DISCLAMER}
                    onClick={() => {
                      resetState();
                      close();
                    }}
                    onCancel={close}
                  />
                ),
              });
            }}
          >
            <i className="material-icons flex items-center h-full w-full justify-center text-gray-700 font-bold cursor-pointer">
              close
            </i>
          </div>
          <div className="font-semibold">
            <span className="block w-full text-xl" style={{ direction }}>
              {productId ? <Text value={translations.EDIT_PRODUCT} /> : <Text value={translations.ADD_PRODUCT} />}
            </span>
          </div>
        </div>
        <div>
          <Stack expandItem={false} direction="wrap">
            <Button
              data-testid={`${productId ? 'add' : 'edit'}-save-product`}
              kind="secondary"
              isSpinning={isCreatingProduct || isUpdatingProduct}
            >
              <Text value={translations.SAVE} />
            </Button>
            {productId && (
              <More
                testIdBtn="list-products-more-btn"
                size={'xs'}
                iconSize="2xl"
                items={[
                  {
                    testId: 'delete-product-item',
                    title: <Text value={translations.DELETE_PRODUCT} />,
                    onClick: () => {
                      open({
                        testId: 'delete-product-modal',
                        size: 'max-w-md',
                        title: <Text value={translations.DELETE_PRODUCT} className="text-xl" />,
                        body: (
                          <DeleteProduct
                            id={productId}
                            storeId={storeId}
                            close={close}
                            resetState={resetState}
                            title={lang === 'en' ? titleEn : titleAr}
                            refetchMenuPageData={refetchMenuPageData}
                          />
                        ),
                      });
                    },
                  },
                ]}
              />
            )}
          </Stack>
        </div>
      </div>
    )
  );
};

export default ProductFormFrame;
