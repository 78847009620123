import React, { useContext, useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { useMutation, useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import { context as notificationsContext } from 'context/notifications';
import { Text } from 'components/service';
import { Button } from 'components/kit';
import { useSelectedStore } from 'hooks/index';
import { Container, Footer } from 'components/form/generic';
import * as translations from 'constants/translations';
import { context as userContext } from 'context/user';
import * as schemas from './schemas';
import formatError from './utils';
import RefundConfirmationDetails from './RefundConfirmationDetails';
import RefundDisclamer from './RefundDisclamer';
import { REFUND_TYPE_ENUM } from '../Table/Order/OrderDetails/OrderDetailsStatus/CancelOrderBody/data';

const RefundedOrder = ({ orderId, onCancel, refundTo, compensationAmount, currency, orderTotal, refundType }) => {
  const [gatewayId, setGatewayId] = useState();
  const notifications = useContext(notificationsContext);
  const storeId = useSelectedStore();
  const orderWithCurrency = `${currency} ${orderTotal}`;
  const [refund, { loading: isRefunding }] = useMutation(schemas.REFUND, {
    onError: err => {
      const errorMessage = formatError(err);

      notifications.show(errorMessage, 'error');
    },
  });
  const { selectedStore } = useContext(userContext);

  const [getPaymentCredential, { loading: loadingCredential }] = useLazyQuery(schemas.GET_PAYMENT_CREDENTIALS, {
    onCompleted: data => setGatewayId(data.paymentCredentials.paymentGateway.id),
  });

  const [getPaymentGateway, { data: paymentGateway, loading: loadingGateway }] = useLazyQuery(schemas.PAYMENT_GATEWAY);

  useEffect(() => {
    getPaymentCredential({ variables: { restaurantId: storeId }, fetchPolicy: 'network-only' });
  }, []);

  useEffect(() => {
    if (gatewayId) {
      getPaymentGateway({
        variables: {
          countryId: parseInt(selectedStore.countryId),
          gatewayId: parseInt(gatewayId),
        },
        fetchPolicy: 'cache-and-network',
      });
    }
  }, [gatewayId]);

  const paymentMethods = paymentGateway?.paymentGateway?.paymentMethods;

  return (
    <Formik
      onSubmit={async () => {
        await refund({
          variables: {
            storeId,
            orderId,
            compensation: parseFloat(compensationAmount),
            typeOfRefund: refundType,
          },
        });
        onCancel();
      }}
    >
      <Form>
        <div className="px-6">
          <Container>
            <div className="flex flex-col">
              <RefundConfirmationDetails
                refundTo={refundTo}
                compensationAmount={compensationAmount}
                orderWithCurrency={orderWithCurrency}
                orderTotal={orderTotal}
                currency={currency}
              />
              <RefundDisclamer isWallet={refundType === REFUND_TYPE_ENUM.ORDERFAST_WALLET} />
            </div>
          </Container>
        </div>
        <Footer refund>
          <div className="ml-auto">
            <div className="w-full mt-5 flex items-center">
              <span className="mr-6">
                <Button onClick={onCancel} type="button" kind="tertiary">
                  <Text value={translations.NOT_NOW} />
                </Button>
              </span>
              <Button
                disabled={loadingGateway || loadingCredential || !paymentMethods}
                isSpinning={isRefunding}
                kind="secondaryError"
              >
                <Text value={translations.REFUND} />
              </Button>
            </div>
          </div>
        </Footer>
      </Form>
    </Formik>
  );
};

RefundedOrder.propTypes = {
  orderId: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  refundTo: PropTypes.string.isRequired,
  compensationAmount: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  orderTotal: PropTypes.number.isRequired,
  refundType: PropTypes.string.isRequired,
};

export default RefundedOrder;
