import { gql } from 'graphql-request';

const deliveryZoneTemplatesQuery = gql`
  query($countryCode: String!, $cityCode: String, $areaId: ID, $pageSize: Int, $pageNumber: Int, $searchParam: String) {
    deliveryZoneTemplates(
      countryCode: $countryCode
      cityCode: $cityCode
      areaId: $areaId
      pageSize: $pageSize
      pageNumber: $pageNumber
      searchParam: $searchParam
    ) {
      featureCollection
      meta
    }
  }
`;

export default deliveryZoneTemplatesQuery;
