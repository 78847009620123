import { NODE_ENV_PROD } from 'constants/environments';

export const getStoreURl = selectedStore => {
  const { subdomain, fullUrl } = selectedStore || {};
  const hasZydaDomain = process.env.REACT_APP_ENV === NODE_ENV_PROD && fullUrl?.includes('zyda');

  if (hasZydaDomain && subdomain) return `https://orderfast.com/${subdomain}`;

  return fullUrl;
};

export const getStoreOrderfastURL = selectedStore => {
  const { subdomain } = selectedStore || {};
  return `https://orderfast.com/${subdomain}`;
};
