import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { InfoBox } from '@react-google-maps/api';

import { context as localeContext } from 'context/locale';
import * as translations from 'constants/translations';
import { getCenterPosition } from 'pages/setup/delivery/utils';

const LocalInfoBox = ({ item }) => {
  const { translate, direction } = useContext(localeContext);
  const {
    paths,
    properties: { zone_name: zoneName, delivery_fee: deliveryFee, minimum_order: minimumOrder, published, branchName },
  } = item;
  return (
    <InfoBox
      position={getCenterPosition(paths)}
      options={{ closeBoxURL: '', enableEventPropagation: true, disableAutoPan: true }}
    >
      <div className="bg-white p-4 shadow w-65" style={{ direction }}>
        <strong className="text-lg">{zoneName}</strong>
        <div className="flex justify-between mt-3 text-xs">
          <span>{translate(translations.BRANCH_NAME)}</span>
          <strong>{branchName}</strong>
        </div>
        <div className="flex justify-between mt-3 text-xs">
          <span>{translate(translations.DELIVERY_FEES)}</span>
          <strong>{deliveryFee}</strong>
        </div>
        <div className="flex justify-between mt-3 text-xs">
          <span>{translate(translations.MINIMUM_ORDER)}</span>
          <strong>{parseInt(minimumOrder) || translate(translations.NONE)}</strong>
        </div>
        <div className="flex justify-between mt-3 text-xs">
          <span>{translate(translations.INTEGRATION_STATUS)}</span>
          {published ? (
            <strong className="text-zyda-green-150">{translate(translations.PUBLISHED)}</strong>
          ) : (
            <strong className="text-zyda-red-150">{translate(translations.UNPUBLISHED)}</strong>
          )}
        </div>
      </div>
    </InfoBox>
  );
};

LocalInfoBox.propTypes = {
  item: PropTypes.shape({
    properties: PropTypes.shape({
      zone_name: PropTypes.string,
      published: PropTypes.bool,
      minimum_order: PropTypes.number,
      delivery_fee: PropTypes.number,
      branchName: PropTypes.shape({}),
    }),
    paths: PropTypes.arrayOf(
      PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number,
      }),
    ),
  }).isRequired,
};

export default LocalInfoBox;
