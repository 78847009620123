import React, { useContext } from "react"
import { context as notificationsContext } from "context/notifications"
import { navigate } from "@reach/router"
import { useMutation } from "@apollo/client"
import { Button, Stack } from "components/kit"
import * as translations from "constants/translations"
import { replaceParams } from "utils/index"
import { Text } from "components/service"
import * as paths from "paths.js"
import * as schemas from "./schemas"
import { Footer } from "components/form/generic"

export default ({ id, storeId, onClose }) => {
  const notifications = useContext(notificationsContext)

  const [deletePaymentCredentials, { loading: isDeleting }] = useMutation(
    schemas.DELETE_PAYMENT_CREDENTIAL,
    {
      onCompleted: () => {
        notifications.show(
          <Text value={translations.DELETED_PAYMENT_SUCCESS} />
        )
        navigate(replaceParams(paths.paymentGateways, { storeId }))
      },
    }
  )

  return (
    <>
      <div className="px-4">
        <Text
          value={translations.ARE_SURE_TO_DELEYE_PAYMENT_GATEWAY}
          className="mb-2"
        />
        <Text
          value={translations.IF_YOU_DELETE_PAYMENT_GATEWAY}
          className="mb-6 text-gray-700 text-sm"
        />
      </div>
      <Footer modalFooter>
        <Stack direction="row-reverse" expandItem={false} spacing={2}>
          <Button
            isSpinning={isDeleting}
            onClick={() =>
              deletePaymentCredentials({
                variables: {
                  id,
                  storeId,
                },
              })
            }
            type="button"
            kind="secondaryError"
          >
            <Text value={translations.DELETE} />
          </Button>
          <Button onClick={onClose} type="button" kind="tertiary">
            <Text value={translations.DISMISS} />
          </Button>
        </Stack>
      </Footer>
    </>
  )
}
