import React, { useContext } from "react"
import { useMutation } from "@apollo/client"
import { Formik } from "formik"
import { Field, Text } from "components/service"
import { InfoCheckbox } from "components/form/elements"
import { context as localeContext } from "context/locale"
import * as translations from "constants/translations"
import TableHead from "./TableHead"
import cx from "classnames"
import TableRow from "./TableRow"
import * as schemas from "./../Variants/schemas"
import { context as userContext } from "context/user"

export default ({
  storeId,
  productId,
  variants,
  enablePrepTime,
  setValueField,
  pageType,
  isMenu = false
}) => {
  const { selectedStore: selected } = useContext(userContext)
  const [updateVariant, { loading }] = useMutation(schemas.UPDATE_VARIANT, {
    variables: {
      restaurantId: storeId,
      menuItemId: productId,
    },
  })

  return (
    <Field
      title={<Text value={translations.PREPARATION_TIME} />}
      description={
        <Text
          className="text-gray-600 text-xs"
          value={translations.CUSTOMIZE_PREPTIME(
            selected.defaultProductPrepTime || 15
          )}
        />
      }
      toggle
      name="enablePrepTime"
      value={enablePrepTime}
      buttonSize="base"
      btnTxtColor="text-primary-base"
      onChange={e => {
        if (e.target.value) {
          setValueField("enablePrepTime", true)
        } else {
          if (variants.some(i => i.prepTime !== -1)) {
            variants.map(async item => {
              if (item.prepTime > 0) {
                await updateVariant({
                  variables: {
                    variantId: item.id,
                    prepTime: -1,
                  },
                })
              }
            })
          }
          setValueField("enablePrepTime", false)
        }
      }}
      component={InfoCheckbox}
      testId={`${pageType}-enable-disable-prepTime`}
      actionName={[
        <Text value={translations.CUSTOMIZE} />,
        <Text value={translations.DISABLE} />,
      ]}
      body={
        enablePrepTime && (
          <Field
            pageType={pageType}
            productId={productId}
            variantsData={variants}
            storeId={storeId}
            name="prepTime"
            isMenu={isMenu}
            component={Table}
            updateVariant={updateVariant}
            loading={loading}
          />
        )
      }
    />
  )
}

const Table = ({
  values,
  productId,
  storeId,
  variantsData,
  updateVariant,
  loading,
  pageType,
  isMenu
}) => {
  const { lang } = useContext(localeContext)
  const direction = lang === "ar" ? "rtl" : "ltr"

  return (
    <Formik
      initialValues={{ variants: variantsData }}
    >
      {({ values, resetForm }) => (
        <div className={cx("overflow-scroll -m-4 ", (isMenu ? "prep-table" : "catalog-prep-table"))}>
          <table style={{ direction }} className="w-full">
            <TableHead lang={lang} />
            <tbody>
              {variantsData?.map((item, index) => (
                <TableRow
                  pageType={pageType}
                  values={values}
                  item={item}
                  storeId={storeId}
                  productId={productId}
                  index={index}
                  key={index}
                />
              ))}
            </tbody>
          </table>
        </div>
      )}
    </Formik>
  )
}
