import { gql } from '@apollo/client';

const VALIDATE_API_KEY = gql`
  mutation($courierName: String!, $APIKey: String!) {
    validateAPIKey(courierName: $courierName, APIKey: $APIKey) {
      valid
    }
  }
`;

export default VALIDATE_API_KEY;
