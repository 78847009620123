import React, { useState, useRef, useContext } from 'react';
import { Autocomplete, Marker } from '@react-google-maps/api';

import { Input } from 'components/form/elements';
import { Button, Stack, GoogleMapContainer } from 'components/kit';
import { context as localeContext } from 'context/locale';
import { Text } from 'components/service';
import * as translations from 'constants/translations';

const PinModal = ({ coords, onConfirm, onCancel }) => {
  const [tempCoords, setTempCoords] = useState(coords);
  const autocomplete = useRef();
  const { translate } = useContext(localeContext);

  return (
    <div className="relative">
      <GoogleMapContainer
        center={tempCoords}
        zoom={14}
        id="g-map"
        mapContainerClassName="h-64"
        options={{
          disableDefaultUI: true,
        }}
        onClick={e => {
          const coords = {
            lat: e.latLng.lat(),
            lng: e.latLng.lng(),
          };
          setTempCoords(coords);
        }}
      >
        <div className="absolute top-0 left-0 w-full mt-3 z-10 px-24">
          <Autocomplete
            onLoad={inst => (autocomplete.current = inst)}
            onPlaceChanged={place => {
              const { location } = autocomplete.current.getPlace().geometry;
              const coords = {
                lat: location.lat(),
                lng: location.lng(),
              };
              setTempCoords(coords);
            }}
          >
            <Input icon="search" placeholder={translate(translations.SEARCH_FOR_PLACES)} type="text" />
          </Autocomplete>
        </div>
        <Marker position={tempCoords} />
      </GoogleMapContainer>
      <Stack className="pt-3 px-6" direction="row-reverse" expandItem={false}>
        <Button onClick={() => onConfirm(tempCoords)} type="button" kind="primary">
          <Text value={translations.CONFIRM} />
        </Button>
        <Button onClick={onCancel} type="button" kind="tertiary">
          <Text value={translations.CANCEL} />
        </Button>
      </Stack>
    </div>
  );
};

export default PinModal;
