import React, { useContext } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import { context as userContext } from 'context/user';
import { context as notificationContext } from 'context/notifications';
import { Text } from 'components/service';
import * as translations from 'constants/translations';
import * as schemas from '../schemas';
import * as utils from '../utils';

const useDeleteWebLink = ({ lang }) => {
  const { selectedStore } = useContext(userContext);
  const notifications = useContext(notificationContext);
  const { id: storeId } = selectedStore;
  const isArabic = lang === 'ar';

  const { data } = useQuery(schemas.SETTINGS, {
    variables: { storeId },
  });
  const dataLinks = data?.settings?.webLinks?.links;

  const [deleteWebLink] = useMutation(schemas.DELETE_WEB_LINK);

  const handleOnDeleteClick = async weblink => {
    const { id, titleAr, titleEn } = weblink;
    const formattedWebLinksArr = utils.formatWebLinksArr(dataLinks);
    await deleteWebLink({
      variables: {
        storeId,
        id,
        links: formattedWebLinksArr,
      },
    });
    notifications.show(<Text value={translations.THE_LINK_DELETED} payload={(isArabic && titleAr) || titleEn} />);
  };

  return handleOnDeleteClick;
};

export default useDeleteWebLink;
