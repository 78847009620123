import { gql } from '@apollo/client';

export const SETTINGS = gql`
  query($storeId: String!) {
    settings(restaurantId: $storeId) {
      id
      scheduleInterval
      scheduleMaxTimePeriod
      scheduleOrdersEnabled
      isAutoAccept
      orderAcceptMaxThresholdMinutes
      maxAcceptanceThresholdType
      defaultProductPrepTime
      sendAsGiftEnabled
    }
  }
`;

export const UPDATE_SETTINGS = gql`
  mutation(
    $storeId: String!
    $later: Boolean
    $scheduleInterval: Int
    $scheduleMaxTimePeriod: Int
    $isAutoAccept: Boolean
    $orderAcceptMaxThresholdMinutes: Int
    $maxAcceptanceThresholdType: String
    $defaultProductPrepTime: Int
    $sendAsGiftEnabled: Boolean
  ) {
    updateSettings(
      restaurantId: $storeId
      scheduleOrdersEnabled: $later
      scheduleInterval: $scheduleInterval
      scheduleMaxTimePeriod: $scheduleMaxTimePeriod
      isAutoAccept: $isAutoAccept
      maxAcceptanceThresholdType: $maxAcceptanceThresholdType
      orderAcceptMaxThresholdMinutes: $orderAcceptMaxThresholdMinutes
      defaultProductPrepTime: $defaultProductPrepTime
      sendAsGiftEnabled: $sendAsGiftEnabled
    ) {
      id
      scheduleInterval
      scheduleMaxTimePeriod
      scheduleOrdersEnabled
      isAutoAccept
      maxAcceptanceThresholdType
      orderAcceptMaxThresholdMinutes
      defaultProductPrepTime
      sendAsGiftEnabled
    }
  }
`;

export const RESTAURANT = gql`
  query($storeId: String!) {
    restaurant(id: $storeId) {
      id
      defaultProductPrepTime
    }
  }
`;
