import React, { useState, useContext, useMemo, useRef } from 'react';
import { context as localeContext } from 'context/locale';
import { ReactComponent as SortIcon } from 'assets/icons-sort.svg';
import { ReactComponent as LeftIcon } from 'assets/icons-chevron-left.svg';
import { ReactComponent as RightIcon } from 'assets/icons-chevron-right.svg';
import { ReactComponent as NoResults } from 'assets/no-orders.svg';
import styled from 'styled-components';
import { useMobile } from 'hooks/index';
import { InView } from 'react-intersection-observer';
import { Text } from 'components/service';
import * as translations from 'constants/translations';
import cx from 'classnames';

const Table = ({
  dataBody = [],
  dataHeader,
  customRow,
  customCol,
  formatData,
  footer,
  isSingleTable = false,
  customSort,
  hasSideBorders = true,
  customHeaderClasses = '',
}) => {
  const { lang, direction } = useContext(localeContext);
  const isMobile = useMobile();
  const { values, sort } = useSortableData(dataBody, null, formatData);
  const [tHeader, setTHeader] = useState([...dataHeader]);
  const sorting = customSort ? customSort : sort;
  const ref = useRef();
  const headCellClassName = customHeaderClasses ? customHeaderClasses : 'p-4 whitespace-nowrap';
  const cellClassName = 'px-4 py-3 border-b border-gray-300';
  let maxWidth = isMobile ? '10rem' : '20rem';
  var hasHorizontalScrollbar = ref?.current?.scrollWidth > ref?.current?.clientWidth;
  let boxShadow = hasHorizontalScrollbar && '3px 0px 5px 0px rgba(63,63,68,0.08)';

  return (
    <>
      {hasHorizontalScrollbar && (
        <div
          className={cx(
            'py-2 w-full flex items-center justify-end border-t rounded-t md:rounded-none',
            hasSideBorders && 'border-r border-l',
          )}
        >
          <LeftIcon
            onClick={() => (document.getElementById('Table').scrollLeft -= 30)}
            className="inline cursor-pointer fill-current text-zyda-grey-100"
          />
          {tHeader.map(
            (item, i) =>
              i !== 0 && (
                <span
                  key={i}
                  className={cx('w-2 h-2 mx-1  rounded-full', item.inView ? 'bg-zyda-grey-500' : 'bg-zyda-grey-100')}
                />
              ),
          )}
          <RightIcon
            onClick={() => (document.getElementById('Table').scrollLeft += 30)}
            className="inline cursor-pointer fill-current text-zyda-grey-100"
          />
        </div>
      )}
      <div
        style={{ direction }}
        className={cx(
          'flex w-auto border-gray-300',
          !hasHorizontalScrollbar && 'border-t',
          lang === 'en' ? 'text-left' : 'text-right',
          isSingleTable && 'overflow-auto',
          hasSideBorders && 'border-l border-r',
        )}
      >
        {!isSingleTable && (
          <table className="inline overflow-auto md:w-auto">
            <thead>
              <tr className="font-semibold bg-gray-200">
                <th className={cx(headCellClassName)}>
                  {dataHeader[0].name}
                  {dataHeader[0].sort && (
                    <SortIcon onClick={() => sorting(dataHeader[0].key)} className="inline mx-1 cursor-pointer" />
                  )}
                </th>
              </tr>
            </thead>
            <StyledBody shadow={hasHorizontalScrollbar}>
              {values.map((item, i) => {
                return customRow ? (
                  customCol(item, i)
                ) : (
                  <tr key={i}>
                    <td className={cx(cellClassName, 'truncate')} style={{ maxWidth }}>
                      {item[dataHeader[0].key]}
                    </td>
                  </tr>
                );
              })}
            </StyledBody>
          </table>
        )}
        <table
          id="Table"
          ref={ref}
          className={cx(
            'relative w-full table-auto overflow-auto',
            tHeader.length > 2 && !isSingleTable && 'inline-block',
          )}
        >
          <thead>
            <tr className="font-semibold bg-gray-200">
              {tHeader.map((item, i) => {
                return (
                  (i !== 0 || isSingleTable) && (
                    <InView
                      key={i}
                      as="th"
                      className={cx(headCellClassName, !isSingleTable && 'last:w-full')}
                      onChange={(inView, entry) => {
                        setTHeader([
                          ...tHeader.map((item, index) => {
                            if (i === index) {
                              item.inView = inView;
                              return item;
                            } else {
                              return item;
                            }
                          }),
                        ]);
                      }}
                    >
                      <span className={cx(lang === 'en' ? 'float-left' : 'float-right')}>{item.name}</span>
                      {item.sort && (
                        <SortIcon onClick={() => sorting(item.key)} className="inline cursor-pointer mx-1" />
                      )}
                    </InView>
                  )
                );
              })}
            </tr>
          </thead>
          <tbody>
            {!!!values.length ? (
              <tr>
                <td className="py-20" colSpan={dataHeader.length}>
                  <div className="w-full text-center">
                    <NoResults className="mx-auto" />
                    <Text value={translations.NO_RESULTS} className="mb-2 mt-6 text-lg font-semibold" />
                  </div>
                </td>
              </tr>
            ) : (
              values.map((item, i) => {
                return customRow ? (
                  customRow(item, i)
                ) : (
                  <tr key={i}>
                    {tHeader.map((itemHeader, index) => {
                      return (
                        index !== 0 && (
                          <td key={index} className={cx(cellClassName, 'whitespace-nowrap last:w-full')}>
                            {item[itemHeader.key]}
                          </td>
                        )
                      );
                    })}
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
      {footer && (
        <div className={cx('py-2 w-full md:w-auto flex items-center border-b border-l border-r border-gray-300')}>
          {!!values.length && footer}
        </div>
      )}
    </>
  );
};

export default Table;

const useSortableData = (values, config = null, formatData) => {
  const [sortConfig, setSortConfig] = useState(config);
  const sortedItems = useMemo(() => {
    let sortableItems = [...values];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [values, sortConfig]);

  const sort = key => {
    let direction = 'ascending';
    if (sortConfig?.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  return { values: formatData ? formatData(sortedItems) : sortedItems, sort };
};

const StyledBody = styled.tbody`
  ${props =>
    props.shadow &&
    `
    display: table-cell
    -webkit-box-shadow: 3px 0px 5px 0px rgba(63,63,68,0.08);  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow:    3px 0px 5px 0px rgba(63,63,68,0.08);  /* Firefox 3.5 - 3.6 */
    box-shadow:         3px 0px 5px 0px rgba(63,63,68,0.08);
  `}
`;
