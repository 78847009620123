import React, { useContext } from "react"
import * as R from "ramda"
import { context as localeContext } from "context/locale"
import * as translations from "constants/translations"
import { Error } from "components/form/generic"
import cx from "classnames"

export default ({
  error,
  children,
  direction,
  placeholder,
  testId,
  mapValue = R.identity,
  ...props
}) => {
  const { lang, translate } = useContext(localeContext)
  direction = direction ? direction : lang === "ar" ? "rtl" : "ltr"
  return (
    <div className="w-full">
      <select
        style={{ direction }}
        className={cx(
          "appearance-none border border-gray-300 focus:border-primary-base rounded-none w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none h-10 bg-transparent",
          error && "border-zyda-red-500 bg-zyda-red-50",
          props.disabled ? "cursor-not-allowed" : "cursor-pointer"
        )}
        {...props}
        value={mapValue(props.value)}
        onChange={
          props.onChange &&
          (e =>
            props.onChange({
              ...e,
              target: {
                name: e.target.name,
                value: mapValue(e.target.value),
              },
            }))
        }
        data-testid={testId}
      >
        <option value="" disabled>
          {placeholder ? placeholder : translate(translations.PLEASE_SELECT)}
        </option>
        {children}
      </select>
      {error && <Error>{error}</Error>}
    </div>
  )
}
