import React from "react"

import ItemTrackingTableRow from "./ItemTrackingTableRow"
import { handleVariantName, TRACKED_TYPES } from "./utils"

const ItemTrackingTableRows = ({
  lang,
  branchVariantsData,
  isMobile,
  trackingType,
}) => {
  return (
    <tbody>
      {branchVariantsData?.map((branchVariant, branchVariantIndex) => {
        let variantName = handleVariantName(branchVariant, lang)
        const { id, unavailable, stockCount, initialCount } = branchVariant || {}
        return (
          <ItemTrackingTableRow
            branchVariantIndex={branchVariantIndex}
            name="branchVariants"
            variantName={variantName}
            unavailable={unavailable}
            stockCount={stockCount}
            initialCount={initialCount}
            trackedTypes={TRACKED_TYPES}
            lang={lang}
            branchVariantId={id}
            isMobile={isMobile}
            trackingType={trackingType}
          />
        )
      })}
    </tbody>
  )
}

export default ItemTrackingTableRows
