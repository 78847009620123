import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { context as localeContext } from 'context/locale';
import { usePriceFormat } from 'hooks/index';
import { Text } from 'components/service';
import { CheckboxList } from 'components/form/elements';
import * as translations from 'constants/translations';
import { getCookie } from 'utils';
import Period from '../Period';
import LineChart from '../charts/LineChart';
import { percentDiff, percentDir, selectedPeriodValues } from '../utils';
import { ANALYTICS_TAB_NAMES } from '../../constants';
import SalesRange from './SalesRange';
import SalesSection from './SalesSection';

const SalesBody = ({ type, date, data, selected, selectedTitle, range, onPrecionChange }) => {
  const { lang, translate } = useContext(localeContext);
  const [selectedPeriod, setSelectedPeriod] = useState([selectedPeriodValues.CURRENT_PERIOD, selectedPeriodValues.PREVIOUS_PERIOD]);
  const formatPrice = usePriceFormat();
  const display =
    selected === ANALYTICS_TAB_NAMES.totalSales.snakeCaseName ||
    selected === ANALYTICS_TAB_NAMES.averageOrderValue.snakeCaseName
      ? formatPrice
      : count => count || 0;
  const onChangeCheckboxList = e => {
    if (process.env.REACT_APP_SEGMENT_WRITE_KEY) {
      if (getCookie('ajs_user_id') !== '') {
        window.analytics.track('Sales Analytics Periods Checked', {
          range,
          selectedSalesAnalyticsTab: selected,
          checkedPeriods: [...e.target.value],
        });
      }
    }
    setSelectedPeriod([...e.target.value]);
  };

  return (
    <div className="flex flex-col xl:flex-row border-t border-gray-300">
      <div
        className={cx(
          'flex-shrink-0 border-gray-300 xl:max-w-sm w-full',
          lang === 'en' ? 'border-r-0 xl:border-r' : 'border-l-0 xl:border-l',
        )}
      >
        <div className="py-6 px-4 flex flex-col justify-center border-b border-gray-300 h-32">
          <div className="mb-2">
            <SalesRange startDate={data?.currentPeriod?.startDate} endDate={data?.currentPeriod?.endDate} />
          </div>
          <span className="uppercase text-lg font-semibold text-black">{display(data.currentPeriod.total)}</span>
        </div>
        <div className="py-6 h-32 px-4 flex flex-col justify-center border-b border-gray-300">
          <div className="flex justify-between">
            <SalesSection
              title={<Text value={translations.PREVIOUS_PERIOD} />}
              value={display(data.previousPeriod.total)}
              percent={percentDiff(data.previousPeriod.diff)}
              dir={percentDir(data.previousPeriod.diff)}
              valueColor="zyda-grey-500"
            />
            <SalesRange size="xs" startDate={data?.previousPeriod?.startDate} endDate={data?.previousPeriod?.endDate} />
          </div>
        </div>
        <div className="py-6 h-32 px-4 flex flex-col justify-center">
          <div className="flex justify-between">
            <SalesSection
              title={<Text value={translations.PREVIOUS_YEAR} />}
              value={display(data.previousYear.total)}
              percent={percentDiff(data.previousYear.diff)}
              dir={percentDir(data.previousYear.diff)}
              valueColor="zyda-grey-500"
            />
            <SalesRange size="xs" startDate={data?.previousYear?.startDate} endDate={data?.previousYear?.endDate} />
          </div>
        </div>
      </div>
      <div className="flex flex-col flex-1">
        <div className="flex flex-wrap items-center justify-between px-6">
          <div className="">
            <CheckboxList
              items={[
                {
                  value: selectedPeriodValues.CURRENT_PERIOD,
                  title: translate(translations.SELECTED_PERIOD),
                },
                {
                  value: selectedPeriodValues.PREVIOUS_PERIOD,
                  title: translate(translations.PREVIOUS_PERIOD),
                  bgColor: 'bg-primary-200 border-primary-200',
                },
                {
                  value: selectedPeriodValues.PREVIOUS_YEAR,
                  title: translate(translations.PREVIOUS_YEAR),
                  bgColor: 'bg-zyda-grey-100 border-gray-300',
                },
              ]}
              name="periods"
              value={selectedPeriod}
              onChange={onChangeCheckboxList}
              direction="row"
            />
          </div>
          <div className="p-3">
            <Period value={date} onChange={onPrecionChange} />
          </div>
        </div>
        <div className="w-auto" style={{ height: '330px' }}>
          <LineChart
            selectedPeriod={selectedPeriod}
            selectedTitle={selectedTitle}
            display={display}
            range={range}
            type={type}
            data={data}
          />
        </div>
      </div>
    </div>
  );
};

SalesBody.propTypes = {
  type: PropTypes.oneOf(['today', '7_day', '30_day', '90_day', 'all_time', 'custom']).isRequired,
  date: PropTypes.oneOf(['day', 'month']).isRequired,
  data: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string])).isRequired,
  selected: PropTypes.oneOf([
    ANALYTICS_TAB_NAMES.totalSales.snakeCaseName,
    ANALYTICS_TAB_NAMES.averageOrderValue.snakeCaseName,
    ANALYTICS_TAB_NAMES.totalOrders.snakeCaseName,
    ANALYTICS_TAB_NAMES.totalCustomers.snakeCaseName,
  ]).isRequired,
  selectedTitle: PropTypes.element.isRequired,
  range: PropTypes.shape({ start: PropTypes.string, end: PropTypes.string }).isRequired,
  onPrecionChange: PropTypes.func.isRequired,
};

export default SalesBody;
