import React from 'react';
import PropTypes from 'prop-types';

const Iframe = ({ title = 'WebView', src, height, width }) => (
  <div>
    <iframe id="web-frame" className="absolute pb-2" title={title} src={src} height={height} width={width} />
  </div>
);

Iframe.propTypes = {
  title: PropTypes.string,
  src: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};
export default Iframe;
