import { gql } from '@apollo/client';

export const GET_STOREFRONT_USERS = gql`
  query($storeId: String!, $page: Int!, $limit: Int!, $phoneNumber: String) {
    customers(restaurantId: $storeId, page: $page, limit: $limit, phoneNumber: $phoneNumber) {
      customers {
        id
        userId
        userName
        phoneNumber
        firstOrder
        lastOrder
        totalOrders
        totalSales
        segmentId
        userToken
        userCartId
        addresses {
          type
          lat
          lng
          address
        }
      }
      totalCount
      totalPages
    }
  }
`;

export const CREATE_STOREFRONT_USERS = gql`
  mutation createStorefrontUser($storeId: String!, $phoneNumber: String!, $userName: String) {
    createStorefrontUser(restaurantId: $storeId, phoneNumber: $phoneNumber, userName: $userName) {
      id
      userId
      userName
      phoneNumber
      userToken
    }
  }
`;
