import React, { useContext } from 'react';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import { Button, DropDown } from 'components/kit';
import { Label, Row } from 'components/form/generic';
import { InfoCheckbox, Input, Stepper } from 'components/form/elements';
import { Text, Field } from 'components/service';
import * as translations from 'constants/translations';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import zoneFormValidationSchema from './data';
import DeliveryHours from './DeliveryHours';
import { allBranchesId } from '../utils';

const ZoneForm = ({
  isEdit,
  handleFormSubmit,
  initialValues,
  handleCancelMode,
  branches,
  pending,
  isDisabled = false,
  isBulk = false,
  bulkZones,
  needsPOSExternalId = false,
}) => {
  const { direction, translate } = useContext(localeContext);
  const { settings } = useContext(userContext);
  const { defaultProductPrepTime } = settings;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setErrors }) => handleFormSubmit(values, setErrors)}
      validationSchema={zoneFormValidationSchema}
    >
      {({ values, setFieldValue }) => (
        <Form>
          {!isBulk && (
            <Row className="mb-3">
              <Label textSize="text-xs" title={<Text value={translations.ZONE_NAME} />}>
                <Field
                  type="text"
                  name="zoneName"
                  component={Input}
                  placeholder={translate(translations.ZONE_NAME_PLACEHOLDER)}
                  direction={direction}
                />
              </Label>
            </Row>
          )}

          {isBulk && (
            <div className="mb-4">
              <span className="text-sm">{bulkZones.firstZoneTitle}</span>
              {bulkZones.length >= 1 && (
                <>
                  <span data-tip data-for="zonesName" className="text-sm">
                    ,&nbsp;+{bulkZones.length} {translate(translations.ZONES)}
                  </span>

                  <ReactTooltip place="bottom" type="dark" effect="solid" id="zonesName">
                    <ul className="list-none">
                      {bulkZones.data.map(dataItem => (
                        <li key={dataItem.area_title_en} className="text-sm">
                          {dataItem.area_title_en}
                        </li>
                      ))}
                    </ul>
                  </ReactTooltip>
                </>
              )}
            </div>
          )}

          {needsPOSExternalId && (
            <Row className="mb-3">
              <Label textSize="text-xs" title={<Text value={translations.POS_EXTERNAL_ID} />}>
                <Field
                  type="text"
                  name="posExternalId"
                  component={Input}
                  placeholder={translate(translations.POS_EXTERNAL_ID_PLACEHOLDER)}
                  direction={direction}
                />
              </Label>
            </Row>
          )}

          <Row className="mb-3">
            <Label textSize="text-xs" title={<Text value={translations.DELIVERY_FEES} />}>
              <Field
                type="text"
                name="deliveryFee"
                component={Input}
                placeholder={translate(translations.DELIVERY_FEES_PLACEHOLDER)}
                direction={direction}
              />
            </Label>
          </Row>

          <Row className="mb-3">
            <Label textSize="text-xs" title={<Text value={translations.MINIMUM_ORDER_AMOUNT} />}>
              <Field
                type="text"
                name="minValue"
                component={Input}
                placeholder={translate(translations.MINIMUM_ORDER_AMOUNT_PLACEHOLDER)}
                direction={direction}
              />
            </Label>
          </Row>

          <Row className="mb-3">
            <Label
              textSize="text-xs"
              title={<Text value={translations.INCLUDE_PREP_TIME} payload={defaultProductPrepTime} />}
            >
              <Field
                min={0}
                step={1}
                name="deliveryTime"
                options={[
                  {
                    id: 'mins',
                    title: <Text value={translations.MINUTES} />,
                  },
                  {
                    id: 'hrs',
                    title: <Text value={translations.HOURS} />,
                  },
                ]}
                dropdown
                hideStepper
                component={Stepper}
              />
            </Label>
          </Row>

          {isEdit && (
            <Row className="mb-3">
              <Label textSize="text-xs" title={<Text value={translations.ASSIGNED_BRANCH} />}>
                <DropDown
                  onSelect={option => setFieldValue('branchId', option)}
                  optionSelected={values.branchId}
                  placeholder={<Text value={translations.CHOOSE_ONE_BRANCH} className="text-xs text-gray-500" />}
                  data={branches.filter(branchItem => branchItem.id !== allBranchesId)}
                  icon="keyboard_arrow_down"
                  full
                  scrollable
                  className="w-full"
                  isDisabled={isDisabled}
                  isDeliveryZone
                />
              </Label>
            </Row>
          )}

          <Row className="mb-5 mt-5">
            <Label textSize="text-xs" title={<Text value={translations.DELIVERY_HOURS} />}>
              <DeliveryHours filedName="openingHours" values={values} setFieldValue={setFieldValue} />
            </Label>
          </Row>

          <Row className="mb-5 mt-5">
            <Field
              title={<Text className="text-sm" value={translations.PUBLISH_ZONE} />}
              toggle
              zoneToggle
              name="enableZone"
              buttonSize="base"
              btnTxtColor="text-primary-base"
              component={InfoCheckbox}
            />
          </Row>

          <Row className="justify-between mt-4">
            <Button kind="tertiary" type="button" className="w-full" onClick={handleCancelMode}>
              <Text value={translations.CANCEL} />
            </Button>

            <Button kind="primary" type="submit" className="w-full" isSpinning={pending}>
              <Text value={translations.SAVE} />
            </Button>
          </Row>
        </Form>
      )}
    </Formik>
  );
};
ZoneForm.propTypes = {
  isEdit: PropTypes.bool,
  initialValues: PropTypes.shape({
    zoneName: PropTypes.string,
  }).isRequired,
  handleCancelMode: PropTypes.func,
  handleFormSubmit: PropTypes.func.isRequired,
  branches: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ),
  pending: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
  isBulk: PropTypes.bool,
  bulkZones: PropTypes.shape({
    firstZoneTitle: PropTypes.string,
    length: PropTypes.number,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        area_title_en: PropTypes.string,
      }),
    ),
  }),
  needsPOSExternalId: PropTypes.bool,
};

export default ZoneForm;
