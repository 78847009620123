import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Circle, InfoBox } from '@react-google-maps/api';

import * as translations from 'constants/translations';
import { context as localeContext } from 'context/locale';
import { selectedZoneIdUtils } from '../../utils';

const CircleItem = ({ item, zoneController, setZoneController, handleViewPort, map }) => {
  const defCenter = { lat: item.geometry.coordinates[1], lng: item.geometry.coordinates[0] };
  const { translate } = useContext(localeContext);

  const [circle, setCircle] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [distance, setDistance] = useState(item.properties.radius / 1000);
  const [showInfo, setShowInfo] = useState(false);

  const handleCircleClick = () => {
    if (zoneController.enableDrawerMode && !zoneController.showDrawerMode && !zoneController.isBulk) {
      setIsEdit(true);
      setShowInfo(true);
      setZoneController({
        ...zoneController,
        enableDrawerMode: false,
        isEdit: true,
        shapeRef: circle,
        selectedZone: item,
        isCricle: true,
        prevZone: {
          paths: null,
          center: defCenter,
          radius: item.properties.radius,
        },
      });
      map.panTo(defCenter);
      map.setZoom(14);
    }
  };

  const getBackToDefCenter = () => {
    if (circle) {
      if (circle.getCenter().lat() !== defCenter.lat) {
        circle.setCenter(defCenter);
      }
    }
  };

  useEffect(() => {
    if (circle) {
      const selectedItemId = JSON.parse(localStorage.getItem(selectedZoneIdUtils.SELECTEDZONEID));
      if (selectedItemId) {
        if (selectedItemId.id === item.properties.id) {
          setIsEdit(true);
          setZoneController({
            ...zoneController,
            enableDrawerMode: false,
            isEdit: true,
            shapeRef: circle,
            selectedZone: { ...item, properties: selectedItemId },
            isCricle: true,
            prevZone: {
              paths: null,
              center: defCenter,
              radius: item.properties.radius,
            },
          });
          if (zoneController.selectedBranch) {
            handleViewPort(zoneController.selectedBranch.branchViewPort);
          }
        }
      }
    }
  }, [circle, zoneController.selectedBranch]);

  useEffect(() => {
    if (!zoneController.isEdit) {
      setIsEdit(false);
      setShowInfo(false);
    }
  }, [zoneController]);

  return (
    <>
      {showInfo && zoneController.isEdit && (
        <InfoBox
          position={{ lat: defCenter.lat, lng: defCenter.lng }}
          options={{ closeBoxURL: '', enableEventPropagation: true }}
        >
          <div className="bg-white p-2 shadow min-w-max">
            <div className="flex justify-between text-xs">
              <span>{`${translate(translations.DISTANCE)} ${distance.toFixed(1)} ${translate(translations.KM)}`}</span>
            </div>
          </div>
        </InfoBox>
      )}
      <Circle
        onLoad={ref => setCircle(ref)}
        center={defCenter}
        onClick={handleCircleClick}
        editable={isEdit}
        onCenterChanged={getBackToDefCenter}
        draggable={false}
        radius={item.properties.radius}
        onRadiusChanged={() => circle && setDistance(parseInt(circle.radius) / 1000)}
        options={{
          strokeColor: item.properties.fillColor,
          strokeOpacity: item.properties.strokeOpacity,
          strokeWeight: 2,
          fillColor: item.properties.fillColor,
          fillOpacity: item.properties.fillOpacity,
          zIndex: -item.properties.radius,
          visible: item.properties.visibility,
        }}
      />
    </>
  );
};

CircleItem.propTypes = {
  item: PropTypes.shape({
    properties: PropTypes.shape({
      fillColor: PropTypes.string,
      strokeOpacity: PropTypes.number,
      fillOpacity: PropTypes.number,
      radius: PropTypes.number,
      visibility: PropTypes.bool,
    }),
  }).isRequired,
  zoneController: PropTypes.shape({
    isEdit: PropTypes.bool,
    enableDrawerMode: PropTypes.bool,
    showDrawerMode: PropTypes.bool,
    isBulk: PropTypes.bool,
  }).isRequired,
  setZoneController: PropTypes.func.isRequired,
  handleViewPort: PropTypes.func.isRequired,
  map: PropTypes.shape({
    panTo: PropTypes.func,
    setZoom: PropTypes.func,
  }).isRequired,
};

export default CircleItem;
