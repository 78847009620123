import React, { useState, useRef, useContext } from "react"
import { SortableElement } from "react-sortable-hoc"
import { useMutation } from "@apollo/client"
import { Text } from "components/service"
import { DragHandle, Button } from "components/kit"
import { Stepper, Input } from "components/form/elements"
import cx from "classnames"
import * as schemas from "./schemas"
import { context as userContext } from "context/user"
import { context as localeContext } from "context/locale"
import * as utils from "./utils"
import { Field } from "formik"
import * as translations from "constants/translations"
import Accordion from "../ProductImages/Accordion"
import { ReactComponent as Trash } from "assets/trash.svg"

export default SortableElement(
  ({
    storeId,
    productId,
    variant,
    open,
    close,
    productLang,
    loadingDelete,
    onRemove,
    shouldRemove,
    pageType,
    isEdit,
    id,
    variantsArray,
    query,
    setFieldValue,
    idx,
    isMenu,
  }) => {
    const { selectedStore: selected, isPosCourier: isPosType } = useContext(userContext)
    const { lang, direction } = useContext(localeContext)
    const currency = selected.currency
    const cellClassName = cx(
      isMenu ? "w-1/6" : "w-1/4",
      "px-3 py-3 border-collapse items-center  border-b border-gray-300 whitespace-nowrap flex-1 last:w-full"
    )

    const [updateVariant, { loading }] = useMutation(schemas.UPDATE_VARIANT, {
      variables: {
        restaurantId: storeId,
        menuItemId: productId,
        variantId: id,
      },
      onError: error => {
        const message = utils.handleSubmissionErrors(error)
      },
    })

    return (
      <ShowTable
        variant={variant}
        isMenu={isMenu}
        cellClassName={cellClassName}
        lang={lang}
        direction={direction}
        isEdit={isEdit}
        pageType={pageType}
        productLang={productLang}
        updateVariant={updateVariant}
        setFieldValue={setFieldValue}
        variantsArray={variantsArray}
        loading={loading}
        isPosType={isPosType}
        currency={currency}
        shouldRemove={shouldRemove}
        loadingDelete={loadingDelete}
        onRemove={onRemove}
        open={open}
        close={close}
        query={query}
        productId={productId}
        storeId={storeId}
        idx={idx}
      />
    )
  }
)
const ShowTable = ({
  variant,
  cellClassName,
  lang,
  isEdit,
  isPosType,
  pageType,
  shouldRemove,
  productLang,
  loading,
  open,
  close,
  onRemove,
  query,
  productId,
  storeId,
  setFieldValue,
  idx,
  isMenu,
}) => {
  return (
    <tr key={variant.id}>
      <td className={cx("flex-1", isEdit && "hidden")}>
        <DragHandle />
      </td>

      <td className={cx(cellClassName, "hidden")}>
        <div
          className="cursor-pointer flex items-center border border-transparent"
          onClick={() => {
            open({
              title: (
                <Text
                  value={translations.VARIANT_IMAGE_TITLE}
                  payload={lang === "en" ? variant.titleEn : variant.titleAr}
                  className="text-lg"
                />
              ),
              body: (
                <VariantImages
                  variant={variant}
                  open={open}
                  close={close}
                  query={query}
                  productId={productId}
                  storeId={storeId}
                  pageType={pageType}
                />
              ),
            })
          }}
        >
          {variant?.images?.length ? (
            <div
              className={cx(
                lang === "ar" ? "ml-4" : "mr-4",
                "w-12 h-12",
                "relative text-center"
              )}
            >
              <img
                className={cx(
                  lang === "ar" ? "ml-10" : "mr-10",
                  "h-full w-full rounded-none overflow-hidden"
                )}
                src={variant.images[0].imageUrl}
                alt={variant.titleEn}
              />
              <div
                className="absolute text-white flex items-center h-full w-full justify-center"
                style={{
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  backgroundColor: "#08103199",
                }}
              >
                {variant.images.length}
              </div>
            </div>
          ) : (
            <div
              className={cx(
                lang === "ar" ? "ml-4" : "mr-4",
                "w-12 h-12",
                "relative text-center bg-zyda-grey-50"
              )}
            >
              <i className="material-icons absolute flex items-center h-full w-full justify-center text-primary-base font-medium">
                add
              </i>
            </div>
          )}
        </div>
      </td>
      <td className={cx(cellClassName)}>
        {!isEdit ? (
          <span className={"text-center"}>{variant.titleEn}</span>
        ) : (
          <Field
            value={variant.titleEn}
            testId="default-variant-titleEn"
            component={Input}
            width="40"
            onChange={e => {
              setFieldValue(`variants[${idx}].titleEn`, e.target.value)
            }}
          />
        )}
      </td>

      <td className={cx(cellClassName)}>
        {!isEdit ? (
          <span className={"text-center"}>{variant.titleAr}</span>
        ) : (
          <Field
            value={variant.titleAr}
            component={Input}
            testId="default-variant-titleAr"
            width="40"
            onChange={e => {
              setFieldValue(`variants[${idx}].titleAr`, e.target.value)
            }}
          />
        )}
      </td>
      {isPosType && (
        <td className={cx(cellClassName)}>
          {!isEdit ? (
            <span className={"text-center"}>{variant.externalId}</span>
          ) : (
            <Field
              value={variant.externalId}
              component={Input}
              testId="default-variant-externalId"
              width="40"
              onChange={e => {
                setFieldValue(`variants[${idx}].externalId`, e.target.value)
              }}
            />
          )}
        </td>
      )}
      <td className={cx(cellClassName)}>
        {!isEdit ? (
          <span className={"text-center"}>{variant.price}</span>
        ) : (
          <CustomizedInput
            key={productLang}
            title={variant.price}
            onSave={value => {
              setFieldValue(`variants[${idx}].price`, value)
            }}
            isSaving={loading}
            renderInput={({ value, onChange }) => (
              <Field
                testId="default-variant-price"
                value={value}
                width="40"
                onChange={onChange}
                component={Stepper}
                validation={n => n >= 0}
                min={0}
              />
            )}
          />
        )}
      </td>
      <td className={cx(cellClassName)}>
        {!isEdit ? (
          <span className={"text-center"}>
            {variant.discountedPrice ? variant.discountedPrice : "--"}
          </span>
        ) : (
          <CustomizedInput
            key={productLang}
            title={variant.discountedPrice}
            onSave={value => {
              setFieldValue(
                `variants[${idx}].discountedPrice`,
                value && !isNaN(parseFloat(value))
                  ? parseFloat(value)
                  : parseFloat(0)
              )
            }}
            isSaving={loading}
            renderInput={({ value, onChange }) => (
              <Field
                value={value}
                component={Stepper}
                width="40"
                onChange={onChange}
                validation={n => n >= 0}
              />
            )}
          />
        )}
      </td>
      {isMenu && (
        <>
          <td className={cellClassName}>
            {!isEdit ? (
              <span className="text-center">
                {variant.sku ? variant.sku : "--"}
              </span>
            ) : (
              <Field
                value={variant.sku}
                component={Input}
                testId="default-variant-sku"
                width="40"
                onChange={e => {
                  setFieldValue(`variants[${idx}].sku`, e.target.value)
                }}
              />
            )}
          </td>
          <td className={cellClassName}>
            {!isEdit ? (
              <span className="text-center">
                {variant.barCode ? variant.barCode : "--"}
              </span>
            ) : (
              <Field
                value={variant.barCode}
                component={Input}
                testId="default-variant-barCode"
                width="40"
                onChange={e => {
                  setFieldValue(`variants[${idx}].barCode`, e.target.value)
                }}
              />
            )}
          </td>
        </>
      )}
      {shouldRemove && isEdit && (
        <td className={cx(cellClassName)}>
          <Button
            type="button"
            kind="secondary"
            size="trash"
            data-testid={`${pageType}-delete-variant-btn`}
            onClick={() => {
              onRemove()
            }}
          >
            <Trash />
          </Button>
        </td>
      )}
    </tr>
  )
}
const CustomizedInput = ({ title, onSave, renderInput }) => {
  const { lang } = useContext(localeContext)
  const [value, setValue] = useState(title)

  return (
    <div>
      <div className={cx("flex items-center px-2")}>
        {renderInput({
          value,
          onChange: e => {
            setValue(e.target.value)
            onSave(e.target.value)
          },
        })}
      </div>
    </div>
  )
}

const VariantImages = ({
  variant,
  open,
  close,
  query,
  productId,
  storeId,
  pageType,
}) => {
  const imagesUrls = variant.images
  return (
    <Accordion
      query={query}
      item={variant}
      productId={productId}
      storeId={storeId}
      close={close}
      open={open}
      pageType={pageType}
    />
  )
}
