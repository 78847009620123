import React from "react"
import * as yup from "yup"
import { Text } from "components/service"
import * as translations from "constants/translations"

export const validationSchema = yup.object().shape({
  countryId: yup.string().required(<Text value={translations.STORE_COUNTRY} />),
  titleEn: yup.string().required(<Text value={translations.STORE_NAME_REQ} />),
  titleAr: yup.string().required(<Text value={translations.STORE_NAME_REQ} />),
  taxPercentage: yup
    .number()
    .max(100, <Text value={translations.TAX_PERCENTAGE_ERR} />),
  legal: yup.object().shape({
  email: yup
    .string()
    .email(<Text value={translations.WRONG_EMAIL_FORMAT} />)
    .nullable(true),
  nationalNumber: yup
    .string()
    .matches(/^[0-9]+$/, <Text value={translations.INVALID_NATIONAL_NUM} />)
    .nullable(true)
    .transform((v, o) => (o === "" ? null : v)),
  licenseNumber: yup
    .string()
    .matches(
      /^[0-9]+$/,
      <Text value={translations.LEGAL_LICENSE_NUMBER_DECIMAL} />
    )
    .nullable(true)
    .transform((v, o) => (o === "" ? null : v)),
  vatIdNumber: yup
    .string()
    .matches(
      /^[0-9]+$/,
      <Text value={translations.LEGAL_VAT_ID_NUMBER_DECIMAL} />
    )
    .nullable(true)
    .transform((v, o) => (o === "" ? null : v)),
  phoneNumber: yup
    .string()
    .matches(/^[0-9]+$/, <Text value={translations.NUMBER_TYPE} />)
    .nullable(true)
    .transform((v, o) => (o === "" ? null : v)),
  }),
})

export const initialValues = {
  titleEn: "",
  titleAr: "",
  countryId: "",
  preferredLanguage: "english",
}
