import React from "react"
import { Frame, Table } from "components/common/orders"

export default () => {
  return (
    <Frame paymentStatuses={["waiting_for_payment", "redirect_url", "iframe_url"]}>
      <Table />
    </Frame>
  )
}
