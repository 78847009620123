import React, { useContext, useState, useEffect, useRef } from "react"
import { useMutation, useApolloClient } from "@apollo/client"
import { Formik, Form } from "formik"
import * as yup from "yup"
import cx from "classnames"
import { useSelectedStore } from "hooks"
import { Field, Text } from "components/service"
import { Button, Stack, SpinnerAlt } from "components/kit"
import {
  Label,
  Container,
  Row,
  LangSwitch,
  Footer,
} from "components/form/generic"
import { Input, MultiSelect, MultiCheckbox, UploadDnD } from "components/form/elements"
import {
  ADD_CATEGORY,
  UPDATE_CATEGORY,
} from "../schemas"
import * as translations from "constants/translations"
import { context as localeContext } from "context/locale"
import { context as userContext } from "context/user"
import { context as modal } from "context/modal"
import { context as notificationsContext } from "context/notifications"
import * as utils from "./utils"
import { uploadCategoryImage, deleteCategoryImage } from "rest.js"
import DeleteCategory from "../DeleteCategory"

export default ({
  resetState,
  initialValues,
  type,
  openModal,
  onCancel,
  onCategoryChange,
  refetch,
  refetchProduct,
  setSelectedCat,
}) => {
  const [initialImage, setInitialImage] = useState(initialValues.photoUrl)
  const { lang } = useContext(localeContext)
  const { branches } = useContext(userContext)
  const notifications = useContext(notificationsContext)
  const { setDivRef } = useContext(modal)
  const ref = useRef()
  useEffect(() => {
    setDivRef(ref)
  }, [])
  const storeId = useSelectedStore()
  const direction = lang === "ar" ? "rtl" : "ltr"

  const handleErrorMessage = utils.handleErrors(msg =>
    notifications.show(msg, "error")
  )

  const [
    addCategory,
    { loading: addCategoryLoading, error: errorAdd },
  ] = useMutation(ADD_CATEGORY, {
    onError: handleErrorMessage,
    onCompleted: res => {
      onCancel()
      setSelectedCat(res.createMenuSection.id)
      notifications.show(<Text value={translations.CATEGORY_CREATED} />)
    },
  })
  const [
    updateCategory,
    { loading: updateCategoryLoading, error: errorUpdate },
  ] = useMutation(UPDATE_CATEGORY, {
    onError: handleErrorMessage,
    onCompleted: () => {
      onCancel()
      notifications.show(<Text value={translations.CATEGORY_UPDATED} />)
    },
  })

  const branchesData = branches.map(branch => ({
    id: branch.id,
    title: <Text value={branch} />,
  }))

  const validationSchema = yup.object().shape({
    titleEn: yup
      .string()
      .required(<Text value={translations.TITLE_EN_IS_REQ} />),
    titleAr: yup
      .string()
      .required(<Text value={translations.TITLE_AR_IS_REQ} />),
  })

  const onSubmit = async values => {
    const category =
      type === "add"
        ? await addCategory({
            variables: {
              storeId,
              ...values,
            },
          })
        : await updateCategory({
            variables: {
              storeId,
              id: initialValues.id,
              ...values,
            },
          })

    const categoryId =
      type === "add"
        ? category?.data?.createMenuSection?.id
        : category?.data?.updateMenuSection?.id

    if (values.image) {
      const image = await uploadCategoryImage(storeId, categoryId, values.image)
    } else if (initialValues.photoUrl && !initialImage) {
      await deleteCategoryImage(storeId, categoryId)
    }

    await refetch()
    type !== "add" && (await refetchProduct())
    resetState && resetState()
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      {({ values, setFieldValue, ...rest }) => (
        <Form style={{ direction }}>
          <div ref={ref} className="px-4 my-4 w-full">
            <Container>
              <Text
                value={translations.TITLE}
                className="text-base -mb-4 font-medium"
              />
              <div className="flex flex-col md:flex-row justify-between items-start">
                <div
                  className={cx(
                    "w-full mb-4 md:mb-0",
                    lang === "en" ? "md:mr-4" : "md:ml-4"
                  )}
                >
                  <Label title={<Text value={translations.ENGLISH} />}>
                    <Field
                      data-testid={
                        type === "add"
                          ? "add-category-title-en"
                          : "edit-category-title-en"
                      }
                      key="en"
                      type="text"
                      name="titleEn"
                      placeholder={
                        lang === "en"
                          ? translations.CATEGORY_TITLE[0]
                          : translations.CATEGORY_TITLE[1]
                      }
                      component={Input}
                      spacing="px-4"
                    />
                  </Label>
                </div>
                <div className="w-full">
                  <Label title={<Text value={translations.ARABIC} />}>
                    <Field
                      data-testid={
                        type === "add"
                          ? "add-category-title-ar"
                          : "edit-category-title-ar"
                      }
                      key="ar"
                      direction="rtl"
                      type="text"
                      name="titleAr"
                      placeholder={translations.CATEGORY_TITLE[1]}
                      component={Input}
                      spacing="px-4"
                    />
                  </Label>
                </div>
              </div>
              <Row className="mt-1" spacing="0">
                <Field
                  title={<Text value={translations.PUBLISHED_IN} />}
                  subtitle={<Text value={translations.DESC_SELECT_LOCATION} />}
                  name="publishedBranchIds"
                  font
                  placeholder={
                    lang === "en"
                      ? translations.PLEASE_SELECT_LOCATION[0]
                      : translations.PLEASE_SELECT_LOCATION[1]
                  }
                  items={branchesData}
                  component={MultiCheckbox}
                  testIdOpenList={
                    type === "add"
                      ? "add-publishIn-branches"
                      : "edit-publishIn-branches"
                  }
                  testIdOptionsList={
                    type === "add"
                      ? "add-publishIn-options"
                      : "edit-publishIn-options"
                  }
                  testIdClearAll={
                    type === "add"
                      ? "add-clear-all-branches"
                      : "edit-clear-all-branches"
                  }
                  testIdSelectAll={
                    type === "add"
                      ? "add-select-all-branches"
                      : "edit-select-all-branches"
                  }
                />
              </Row>
              <Row className="mt-1" spacing="0">
                <Label
                  title={<Text value={translations.COVER_IMAGE} />}
                  font
                  right={
                    type === "edit" &&
                    initialImage && (
                      <Text
                        className="text-sm text-red-600 cursor-pointer font-medium"
                        value={translations.DELETE_IMAGE}
                        onClick={() => {
                          setInitialImage(null)
                          setFieldValue("image", null)
                        }}
                      />
                    )
                  }
                >
                  <Field
                    data-testid={
                      type === "add"
                        ? "add-upload-category-image"
                        : "edit-upload-category-image"
                    }
                    name="image"
                    imageUrl={initialImage}
                    addButton
                    component={UploadDnD}
                    accept=".jpg, .jpeg, .png"
                    titleButton={
                      <Text
                        value={
                          type === "add" || !initialImage
                            ? translations.ADD_IMAGE
                            : translations.EDIT_IMAGE
                        }
                      />
                    }
                  />
                </Label>
              </Row>
            </Container>
          </div>
          <Footer modalFooter>
            <div className="w-full flex items-center">
              {type === "edit" && (
                <Button
                  data-testid="editCategory-delete-btn"
                  kind="secondaryError"
                  onClick={() => {
                    onCancel()
                    openModal({
                      testId: "delete-category-modal",
                      size: "max-w-md",
                      title: (
                        <Text
                          value={translations.DELET_CATEGORY}
                          className="text-lg"
                        />
                      ),
                      body: (
                        <DeleteCategory
                          titleEn={values.titleEn}
                          titleAr={values.titleAr}
                          storeId={storeId}
                          catId={initialValues.id}
                          onCancel={onCancel}
                          refetch={refetch}
                          setSelectedCat={setSelectedCat}
                        />
                      ),
                    })
                  }}
                >
                  <Text value={translations.DELETE_CATEGORY} />
                </Button>
              )}
              <div className={cx(lang === "ar" ? "mr-auto" : "ml-auto")}>
                <Stack direction="row">
                  {type !== "edit" && (
                    <Button
                      data-testid={
                        type === "add"
                          ? "add-cancel-category-btn"
                          : "edit-cancel-category-btn"
                      }
                      type="button"
                      kind="tertiary"
                      onClick={onCancel}
                    >
                      <Text value={translations.CANCEL} />
                    </Button>
                  )}
                  {type === "edit" ? (
                    <Button data-testid="edit-save-category-btn" kind="primary">
                      {updateCategoryLoading && (
                        <span className="mr-2">
                          <SpinnerAlt color="primary-50" />
                        </span>
                      )}
                      <Text value={translations.SAVE} />
                    </Button>
                  ) : (
                    <Button data-testid="add-category-btn" kind="primary">
                      {addCategoryLoading && (
                        <span className="mr-2">
                          <SpinnerAlt color="primary-50" />
                        </span>
                      )}
                      <Text value={translations.ADD} />
                    </Button>
                  )}
                </Stack>
              </div>
            </div>
          </Footer>
        </Form>
      )}
    </Formik>
  )
}
