import React, { useContext } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';

import { context as localeContext } from 'context/locale';
import { Button, Stack } from 'components/kit';
import { useSelectedStore, useReplaceParams } from 'hooks';
import { Text } from 'components/service';
import { Footer } from 'components/form/generic';
import {
  IMPORT_FOODICS_NO_BRANCHES_DESCRIPTION,
  ADD_BUSINESS_LOCATION,
  IMPORT_FOODICS_MENU,
} from 'constants/translations';
import { branchCreation } from 'paths.js';
import { ReactComponent as FoodicsMenuImportFrame } from 'assets/foodics-menu-import-frame.svg';

const ImportFromFoodicsNoBranchesModal = ({ onCancel }) => {
  const { lang } = useContext(localeContext);
  const storeId = useSelectedStore();
  const replace = useReplaceParams();

  return (
    <div>
      <div className="mb-8">
        <FoodicsMenuImportFrame className="mx-auto" />
      </div>
      <Text className="text-lg font-semibold pb-2" value={IMPORT_FOODICS_MENU} />
      <Text className="text-gray-600 text-sm pb-2 px-4" value={IMPORT_FOODICS_NO_BRANCHES_DESCRIPTION} />
      <Footer modalFooter isMenu>
        <div className={cx(lang === 'ar' ? 'mr-auto' : 'ml-auto')}>
          <Stack direction="row">
            <Button
              data-testid="add-business-location-catalog-btn"
              onClick={() => {
                navigate(replace(branchCreation, { storeId }));
                onCancel();
              }}
              kind="primary"
            >
              <Text className="font-normal" value={ADD_BUSINESS_LOCATION} />
            </Button>
          </Stack>
        </div>
      </Footer>
    </div>
  );
};

ImportFromFoodicsNoBranchesModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

export default ImportFromFoodicsNoBranchesModal;
