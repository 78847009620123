import React, { useContext } from 'react';
import filesize from 'filesize';
import { ShareIcon } from '@zydalabs/zac-icons-react';
import PropTypes from 'prop-types';

import { Text } from 'components/service';
import { context as notificationsContext } from 'context/notifications';
import * as translations from 'constants/translations';
import { validateImportContacts } from 'rest';
import { useSelectedStore } from 'hooks';
import { fileErrorCodes, fileErrorCodesDanger } from '../utils';

const CampaignContactsImport = ({ setFileController }) => {
  const storeId = useSelectedStore();
  const notifications = useContext(notificationsContext);

  // set controller values
  const setControllerValues = (file, response) => {
    setFileController(prevState => ({
      ...prevState,
      uploadingFile: file,
      uploadingFileLoading: false,
      uploadingFileSize: filesize(file.size),
      uploadingFileName: file.name
        .split('.')
        .slice(0, -1)
        .join('.'),
      uploadingFileExe: file.name.substr(file.name.lastIndexOf('.') + 1, file.name.length),
      uploadingFileResponse: {
        errors: response.errors || [],
        recordCount: response.total_record_count || 0,
        validRecordCount: response.valid_record_count || 0,
        ...response,
      },
    }));
  };

  // handle file uploader
  const handleFileUpload = async file => {
    // set loading to true
    setFileController(prevState => ({
      ...prevState,
      uploadingFileLoading: true,
    }));
    // upload file
    try {
      const response = await validateImportContacts(storeId, file);
      const { errors } = response;

      // set loading to false
      setFileController(prevState => ({
        ...prevState,
        uploadingFileLoading: false,
      }));

      // check if file contains errors
      if (errors.length >= 1) {
        const errorCodes = [];
        errors.map(errorItem => errorCodes.push(errorItem.error_code));
        // check if danger codes exist in errors
        const disableNextMove = fileErrorCodesDanger.some(dangerCode => errorCodes.includes(dangerCode));
        if (disableNextMove) {
          errorCodes.map(errorCodeItem => {
            if (errorCodeItem === fileErrorCodes.INVALID_SIZE_LARGE) {
              return notifications.show(<Text value={translations.INVALID_FILE_SIZE_ERROR} />, 'error');
            }
            if (errorCodeItem === fileErrorCodes.FILE_HAS_NO_PHONES) {
              return notifications.show(<Text value={translations.FILE_HAS_NO_PHONES_ERROR} />, 'error');
            }
            if (errorCodeItem === fileErrorCodes.INVALID_FORMATE) {
              return notifications.show(<Text value={translations.INVALID_FILE_FORMAT_ERROR} />, 'error');
            }
            if (errorCodeItem === fileErrorCodes.INVALID_HEADER) {
              return notifications.show(<Text value={translations.INVALID_COLUMN_HEADER_ERROR} />, 'error');
            }
            return notifications.show(<Text value={translations.SOMETHING_WENT_WRONG} />, 'error');
          });
        } else if (response.valid_record_count === 0) {
          notifications.show(<Text value={translations.FILE_HAS_NO_VALID_PHONES_ERROR} />, 'error');
        } else {
          setControllerValues(file, response);
        }
      } else {
        setControllerValues(file, response);
      }
    } catch (err) {
      notifications.show(<Text value={translations.SOMETHING_WENT_WRONG} />, 'error');
      setFileController(prevState => ({
        ...prevState,
        uploadingFileLoading: false,
      }));
    }
  };

  return (
    <div className="p-12 w-full h-full flex flex-col items-center justify-center text-center cursor-pointer ">
      <ShareIcon color="rgba(31, 31, 31, 0.35)" width="38" />
      <Text className="my-2 font-bold text-lg" value={translations.CONTACT_DRAG_FILE} />
      <Text value={translations.CONTACT_FILE_SIZE} className="text-zyda-grey-500" />
      <Text className="mt-2 text-primary-base" value={translations.BROWSE_LOCAL_FILES} />
      <input
        value=""
        type="file"
        style={{ opacity: 0 }}
        className="absolute w-full h-full top-0 left-0 cursor-pointer"
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        onChange={e => {
          handleFileUpload(e.target.files[0]);
        }}
      />
    </div>
  );
};

CampaignContactsImport.propTypes = {
  setFileController: PropTypes.func.isRequired,
};

export default CampaignContactsImport;
