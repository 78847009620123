import React, { useContext } from "react"
import { Button, CreationBox, Modal } from "components/kit"
import { Text } from "components/service"
import * as translations from "constants/translations"
import { context as localeContext } from "context/locale"
import { ReactComponent as EmptyOptionGroupsDesktop } from "assets/empty-options.svg"
import { ReactComponent as EmptyOptionGroupsMobile } from "assets/empty-options-mob.svg"
import OptionGroupModal from "../OptionGroupModal"
import { useMobile } from "hooks"

export default ({
  allOptionsQuery,
  optionGroupsQuery,
}) => {
  const { direction } = useContext(localeContext)
  const isMobile = useMobile()
  return (
    <Modal>
      {({ open, close }) => (
        <div className="flex flex-col mx-auto max-w-sm items-center py-4">
          {isMobile ? (
            <EmptyOptionGroupsMobile className="w-full" />
          ) : (
            <EmptyOptionGroupsDesktop className="w-full" />
          )}
          <CreationBox
            title={<Text value={translations.CREACTE_PRODUCT_OPTIONS} />}
            description={
              <Text value={translations.CREACTE_PRODUCT_OPTIONS_DESC} />
            }
            actions={
              <Button
                data-testid="add-option-btn"
                kind="primary"
                className="w-40"
                style={{
                  direction,
                }}
                onClick={() => {
                  open({
                    testId: "list-products-add-category",
                    title: (
                      <Text
                        value={translations.OPTION_GROUPS_ADD_GROUP_MODAL}
                        className="text-lg"
                      />
                    ),
                    body: (
                      <OptionGroupModal
                        type="add"
                        onCancel={close}
                        openModel={open}
                        refetchAllOptions={allOptionsQuery}
                        refetchAllGroup={optionGroupsQuery}
                      />
                    ),
                  })
                }}
              >
                <Text
                  value={translations.OPTION_GROUPS_ADD_GROUP}
                  className="font-semibold"
                />
              </Button>
            }
          />
        </div>
      )}
    </Modal>
  )
}
