import { gql } from '@apollo/client';

const orderfastLinkFragment = gql`
  fragment OrderfastLinkData on OrderfastLink {
    id
    linkType
    titleEn
    titleAr
    content
  }
`;

export const ADD_ORDERFAST_LINK = gql`
  mutation($linkType: String!, $content: String!, $titleEn: String!, $titleAr: String!, $subdomain: String!) {
    addOrderfastLink(
      linkType: $linkType
      content: $content
      titleEn: $titleEn
      titleAr: $titleAr
      subdomain: $subdomain
    ) {
      ...OrderfastLinkData
    }
  }
  ${orderfastLinkFragment}
`;

export const UPDATE_ORDERFAST_LINK = gql`
  mutation(
    $subdomain: String!
    $orderfastLinkId: ID!
    $linkType: String
    $titleEn: String
    $titleAr: String
    $content: String
  ) {
    updateOrderfastLink(
      subdomain: $subdomain
      orderfastLinkId: $orderfastLinkId
      linkType: $linkType
      titleEn: $titleEn
      titleAr: $titleAr
      content: $content
    ) {
      ...OrderfastLinkData
    }
  }
  ${orderfastLinkFragment}
`;

export const DELETE_ORERFAST_LINK = gql`
  mutation($subdomain: String!, $orderfastLinkId: ID!) {
    destroyOrderfastLink(subdomain: $subdomain, orderfastLinkId: $orderfastLinkId) {
      success
    }
  }
`;

export const ORDERFAST_LINKS = gql`
  query($subdomain: String!) {
    orderfastLinks(subdomain: $subdomain) {
      ...OrderfastLinkData
    }
  }
  ${orderfastLinkFragment}
`;

export const SORT_ORDERFAST_LINKS = gql`
  mutation($subdomain: String!, $links: [OrderfastLinkSortAttributes!]!) {
    sortOrderfastLinks(subdomain: $subdomain, links: $links) {
      ...OrderfastLinkData
    }
  }
  ${orderfastLinkFragment}
`;
