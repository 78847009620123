import React from "react"
import cx from "classnames"

export default ({
  children,
  spacing = 1,
  direction = "col",
  expandItem = true,
  className,
  flexGrow,
  flexShrink,
  tooltip,
}) => {
  const axis = {
    col: "y",
    "col-reverse": "y",
    row: "x",
    "row-reverse": "x",
    wrap: "x",
    "wrap-reverse": "x",
  }[direction]

  return (
    <div
      className={cx(`flex flex-${direction} -m${axis}-${spacing}`, className)}
    >
      {React.Children.toArray(children).map((item, i) => (
        <div
          key={i}
          className={cx(
            `p${axis}-${spacing}`,
            expandItem && !tooltip && "flex-1",
            direction === ("wrap" || "wrap-reverse") && "my-2",
            flexGrow && `flex-${flexGrow}`,
            flexShrink && `flex-${flexShrink}`
          )}
        >
          {item}
        </div>
      ))}
    </div>
  )
}
