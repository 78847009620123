import React, { useEffect, useState, useContext } from "react"
import { useGtm } from "hooks/index"
import { Formik, Form } from "formik"
import { Mutation } from "@apollo/client/react/components"
import { Link, navigate } from "@reach/router"
import { Field, Text } from "components/service"
import { Input } from "components/form/elements"
import { Title, AuthWrapper,Wrap } from "components/common/auth"
import { Label, Container, Alert } from "components/form/generic"
import { Button, Spinner } from "components/kit"
import { context as userContext } from "context/user"
import { context as localeContext } from "context/locale"
import * as translations from "constants/translations"
import * as paths from "paths.js"
import * as schemas from "./schemas"
import * as data from "./data"
import * as utils from "./utils"
import ZYDA_Background from "assets/zyda-image.png"
import ZYDA from "assets/zyda-logo.svg"
import ZYDA_MOBILE from "assets/zyda-logo-black.svg"
import SignInAi from "assets/sign-in-ai.svg"
import { useMobile } from "hooks/index"
import { ReactComponent as Envelop } from "assets/login-envelop.svg"
import { ReactComponent as Lock } from "assets/login-lock.svg"
import { fbRedirectUrl } from "constants/fb"

export default () => {
  const { signIn, selectedStore, facebookConnect } = useContext(userContext)
  const { lang, direction } = useContext(localeContext)
  const [error, setError] = useState()
  const [isLoading, setLoading] = useState(true)
  const events = useGtm()
  const isMobile = useMobile()

  useEffect(() => {
    let token = new Map(
      window.location.search
        .slice(1)
        .split("&")
        .map(q => q.split("="))
    ).get("token")

    let userId = new Map(
      window.location.search
        .slice(1)
        .split("&")
        .map(q => q.split("="))
    ).get("user_id")
    if (token && userId) {
      setLoading(true)
      localStorage.setItem("token", token)
      localStorage.setItem("user_id", userId)
      window.location.reload()
    } else {
      setLoading(false)
    }
  }, [])
  if (!isLoading) {
    return (
      <Mutation
        mutation={schemas.SIGN_IN}
        onCompleted={({ signIn: user }) => {
          signIn(user)
          events.signIn(user)
          if (facebookConnect.connecting) navigate(`${paths.facebookConnect}/${facebookConnect.query}`);
          else if (window.location.search && window.location.search.includes('redirect_uri')) {
            navigate(
              decodeURIComponent(
                window.location.search
                  .split('=')[2]
                  .concat(
                    fbRedirectUrl(selectedStore),
                  ),
              ),
            );
          }
        }}
        onError={utils.handleSignInErrors(setError)}
      >
        {(signIn, { loading }) => (
            <AuthWrapper image={ZYDA_Background} logo={ZYDA} lang={lang}>
            <Formik
              validationSchema={data.validationSchema}
              initialValues={data.initialValues}
              onSubmit={variables => signIn({ variables })}
            >
              <Form
                style={{ direction }}
                className="bg-white py-8 max-w-md w-full"
              >
                <Container>
                  {isMobile && (
                    <img className="absolute -mt-12" src={ZYDA_MOBILE} alt="" />
                  )}
                  <Label
                    title={
                      <Title>
                        <Text
                          value={translations.LOGIN_ZYDA}
                          className="text-2xl"
                        />
                      </Title>
                    }
                    subtitle={<Text value={translations.LOGIN_ZYDA_SUBTITLE} />}
                  />
                  {error && (
                    <Alert onClose={() => setError()}>
                      {error === "invalid_login" ? (
                        <>
                          <Text
                            value={translations.YOUR_EMAIL_PASS_INCORRECT}
                            className="inline"
                          />
                          <Link
                            to={paths.forgetPassword}
                            className="text-primary-base cursor-pointer"
                          >
                            <Text
                              value={translations.RESET_YOUR_PASSWORD}
                              className="inline mx-1"
                            />
                          </Link>
                        </>
                      ) : (
                        <Text value={translations.SOMETHING_WENT_WRONG} />
                      )}
                    </Alert>
                  )}
                  <Label
                    htmlFor="email"
                    title={
                      <Text
                        value={translations.EMAIL_ADDRESS}
                        className="text-black"
                      />
                    }
                  >
                    <Field
                      data-testid="login-email"
                      type="text"
                      name="email"
                      placeholder={
                        lang === "en"
                          ? translations.EMAIL_ADDRESS[0]
                          : translations.EMAIL_ADDRESS[1]
                      }
                      icon={<Envelop />}
                      submitOnEnter
                      component={Input}
                    />
                  </Label>

                  <Label
                    htmlFor="password"
                    title={
                      <Text
                        value={translations.PASSWORD}
                        className="text-black"
                      />
                    }
                    bottomRight={
                      <Link
                        className="inline align-baseline text-sm text-primary-base hover:text-blue-800"
                        to={paths.forgetPassword}
                        data-testid="forget-password-link"
                      >
                        <Text value={translations.FORGET_PASSWORD} />
                      </Link>
                    }
                  >
                    <Field
                      data-testid="login-password"
                      type="password"
                      name="password"
                      submitOnEnter
                      placeholder={
                        lang === "en"
                          ? translations.ENTER_PASSWORD[0]
                          : translations.ENTER_PASSWORD[1]
                      }
                      component={Input}
                      icon={<Lock />}
                    />
                  </Label>

                  <div className="w-full">
                    <Button
                      disabled={loading}
                      full
                      kind="primary"
                      type="submit"
                      data-testid="login-button"
                    >
                      <Text value={translations.LOGIN} />
                    </Button>
                  </div>
                </Container>
              </Form>
            </Formik>
            </AuthWrapper>
        )}
      </Mutation>
    )
  }
  return (
    <div className="w-full h-screen justify-center flex overflow-hidden">
      <Spinner />
    </div>
  )
}
