import React, { useContext } from "react"
import { Formik, Form } from "formik"
import { context as userContext } from "context/user"
import { context as localeContext } from "context/locale"
import * as data from "./data"
import { LegalInfo, LicensedBusiness, BankInfo, IntegrationInfo } from "./Sections"

export default ({
  initialValues,
  pushedToGateway,
  isIntegrationFromGateway,
  close,
  open,
  onSubmit,
  renderBottomPane,
  renderTopPane,
  isSubmitting,
}) => {
  const { selectedStore } = useContext(userContext)
  const { lang, translate } = useContext(localeContext)
  const sections = []
  !isIntegrationFromGateway && !pushedToGateway && sections.push('legalInfo', 'licensedBusiness')
  isIntegrationFromGateway && sections.push('integrationInfo')
  !isIntegrationFromGateway && sections.push('bankInfo')

  const renderLegalInfo = (values, setFieldValue, handleChange) => (
    <LegalInfo
      values={values}
      setFieldValue={setFieldValue}
      storeId={selectedStore.id}
      initialValues={initialValues}
      lang={lang}
      close={close}
      open={open}
    />
  )

  const renderLicensedBusiness = (values, setFieldValue, handleChange) => (
    <LicensedBusiness
      values={values}
      setFieldValue={setFieldValue}
      handleChange={handleChange}
      storeId={selectedStore.id}
      initialValues={initialValues}
      lang={lang}
      close={close}
      open={open}
    />
  )

  const integrationInfo = (values) => (
    isIntegrationFromGateway && <IntegrationInfo values={values} lang={lang} />
  )

  const bankInfo = (values) => (
    !isIntegrationFromGateway && <BankInfo
      countryCode={selectedStore.countryCode}
      paymentMethods={selectedStore.paymentMethods}
      values={values}
      lang={lang}
      pushedToGateway={pushedToGateway}
    />
  )

  return (
    <Formik
      initialValues={initialValues}
      pushedToGateway={pushedToGateway}
      validationSchema={data.validationSchema(
        {
          country_code: selectedStore.countryCode,
          initialValues,
          sections
        }
      )}
      onSubmit={async data => {
        await onSubmit(data)
      }}
    >
      {({ values, setFieldValue, errors, handleChange }) => (
        <Form>
          {renderTopPane && renderTopPane({ isSubmitting })}
          {sections.includes('legalInfo') && renderLegalInfo(values, setFieldValue, handleChange) }
          {sections.includes('licensedBusiness') && renderLicensedBusiness(values, setFieldValue, handleChange) }
          {sections.includes('bankInfo') && bankInfo(values) }
          {sections.includes('integrationInfo') && integrationInfo(values) }
          
          {renderBottomPane && renderBottomPane({ isSubmitting })}
        </Form>
      )}
    </Formik>
  )
}
