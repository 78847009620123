import React from 'react';
import * as R from 'ramda';
import { navigate } from '@reach/router';
import { parse, stringify } from 'query-string';
import moment from 'moment';
import { createGlobalStyle } from 'styled-components';

import * as translations from 'constants/translations';
import { Text } from 'components/service';
import { DELIVERY_BUSY_MODE } from 'components/common/branch/Busy/utils';
import { dateQueryFormat, parseRange } from 'utils/date';
import { PaidMethodsIcon } from 'utils/imagesResolver';

export const FRAME_ENUM = {
  ALL: 'all',
  CUSTOM: 'custom',
  PHONE: 'phone',
  NUMBER: 'number',
  CUSTOMER_NAME: 'customerName',
  VOUCHER_CODE: 'voucherCode',
  CREATED_AT: 'created_at',
  REDIRECT_URL: 'redirect_url',
  CARD_ON_DELIVERY: 'CARDONDELIVERY',
  MORE_TABS: 'MORE_TABS',
  EXPECTED_AT: 'expected_at',
};

export const PAYMENT_METHODS_ENUMS = {
  BENEFIT: 'BENEFIT',
  CASH: 'CASH',
  CREDIT_CARD: 'CREDIT_CARD',
  MEEZA: 'MEEZA',
  KNET: 'KNET',
  APPLE_PAY: 'APPLE_PAY',
  MADA: 'MADA',
  CARDONDELIVERY: 'CARD ON DELIVERY',
  ONLINE: 'ONLINE',
  CASH_CAM: 'Cash',
  ONLINE_CAM: 'Online',
  CARD_ON_DELIVERY: 'CARDONDELIVERY',
  FAWRY: 'FAWRY',
  WALLET: 'WALLET',
  WALLET_AND_CREDIT_CARD: 'WALLET_AND_CREDIT_CARD',
};

export const appendSearch = items => items.map(item => ({ ...item, to: item.to + document.location.search }));

export const getSubmittedAt = value => {
  if (value !== FRAME_ENUM.ALL) {
    const [amount, unit] = value.split('_');
    return [
      moment()
        .subtract(amount, unit)
        .startOf('day')
        .format(),
      moment()
        .endOf('day')
        .format(),
    ];
  }
  return [];
};

export const getSorting = value => ({
  method: 'desc',
  column: value,
});

export const calcBusyTime = (selectedBranch, timeZone) => {
  if (selectedBranch) {
    if (selectedBranch.busyFrom && moment(selectedBranch.busyUntil).isAfter()) {
      const busyFrom = moment(selectedBranch.busyFrom)
        .tz(timeZone)
        .startOf('minute');
      const busyUntil = moment(selectedBranch.busyUntil)
        .tz(timeZone)
        .startOf('minute');
      return [busyFrom, busyUntil];
    }
    if (selectedBranch.busyUntil && moment(selectedBranch.busyUntil).isAfter()) {
      return moment(selectedBranch.busyUntil)
        .tz(timeZone)
        .startOf('minute');
    }
  }
  return 0;
};

export const handleNumberOfBusyBranches = branches => {
  let sum = 0;
  branches.forEach(branch => {
    if (branch.busy) {
      sum++;
    }
  });
  return sum;
};

export const cleanPaymentMethods = chosenPayments => {
  const cashIndex = chosenPayments.indexOf(PAYMENT_METHODS_ENUMS.CASH_CAM);
  const onlineIndex = chosenPayments.indexOf(PAYMENT_METHODS_ENUMS.ONLINE_CAM);
  if (cashIndex > -1) chosenPayments.splice(cashIndex, 1);
  if (onlineIndex > -1) chosenPayments.splice(onlineIndex, 1);
  if (chosenPayments.includes(PAYMENT_METHODS_ENUMS.CASH)) chosenPayments.push(PAYMENT_METHODS_ENUMS.CASH);
  if (chosenPayments.includes(PAYMENT_METHODS_ENUMS.ONLINE)) chosenPayments.push(PAYMENT_METHODS_ENUMS.ONLINE_CAM);

  return chosenPayments;
};

export const getDeliveryTypes = deliveryTypes => {
  const isManualOrderOnlySelected = typeof deliveryTypes === 'string' && deliveryTypes === DELIVERY_BUSY_MODE.MANUAL;
  const isManualOrderSelectedWithOthersFilter = deliveryTypes.includes(DELIVERY_BUSY_MODE.MANUAL);
  if (isManualOrderOnlySelected) {
    return [];
  }
  if (isManualOrderSelectedWithOthersFilter) {
    const deliveryTypesWithoutManual = deliveryTypes.filter(type => type !== DELIVERY_BUSY_MODE.MANUAL);
    if (deliveryTypesWithoutManual.length > 1) {
      return deliveryTypesWithoutManual;
    }
    return deliveryTypesWithoutManual[0];
  }
  return deliveryTypes;
};

export const prepareAreasFilter = (areasFilterData, lang) => {
  const areas =
    (areasFilterData &&
      areasFilterData.map(area => ({
        id: area.id,
        title: lang === 'ar' ? area.titleAr : area.titleEn,
      }))) ||
    [];
  return areas;
};

export const validateRange = range => {
  if (moment(range[0], dateQueryFormat).isAfter(moment(range[1], dateQueryFormat))) {
    return <Text value={translations.DATES_CONFLICT} />;
  }
  return '';
};

export const changePage = (path, appendingQuery) => {
  const parsedQuery = parse(document.location.search);
  const pathQuery = stringify(R.merge(parsedQuery, appendingQuery));
  navigate(`${path}?${pathQuery}`);
};

export const Style = createGlobalStyle`
.active-link .count {
  display: block !important;
}
`;

export const fetchOrderVariables = (query, status, paymentStatus, paymentStatuses, storeId, statuses) => {
  const orderVariables = {
    status:
      (query.search_value && query.search_status !== FRAME_ENUM.ALL && query.search_status) ||
      (status !== FRAME_ENUM.ALL && status) ||
      undefined,
    paymentStatuses: paymentStatus || paymentStatuses,
    paymentMethod: query.payment_methods,
    deliveryType: getDeliveryTypes(query.delivery_type),
    ...(query.is_manual_order.toString() === 'true' && { isManualOrder: true }),
    areas: query.areas,
    deliveryZoneIn: query.delivery_zone_in,
    driverId: query.driver_id,
    storeId,
    branchId: query.branch === FRAME_ENUM.ALL ? '' : query.branch,
    submittedAt:
      query.date === FRAME_ENUM.CUSTOM
        ? parseRange(query.range)
        : query.date !== FRAME_ENUM.ALL
        ? getSubmittedAt(query.date)
        : undefined,
    //---------------
    phone: query.search_by === FRAME_ENUM.PHONE ? query.search_value : undefined, // remove and add the search value
    number: query.search_by === FRAME_ENUM.NUMBER ? query.search_value : undefined, // remove and add the search value
    customerName: query.search_by === FRAME_ENUM.CUSTOMER_NAME ? query.search_value : undefined, // remove and add the search value
    voucherCode: query.search_by === FRAME_ENUM.VOUCHER_CODE ? query.search_value : undefined, // remove and add the search value
    // Replace with
    ...(query.search_value && {
      searchValue: query.search_value,
    }),

    sort: getSorting(query.sort_by),
    page: 1, // remove and replace with paginateFrom 0

    // Replace with
    paginateFrom: 0,

    statuses,
  };
  return orderVariables;
};

export const trimPaymentMethods = fetchedPaymentMethods => {
  const filtered = [];
  fetchedPaymentMethods.map(option => PAYMENT_METHODS_ENUMS[option] && filtered.push(PAYMENT_METHODS_ENUMS[option]));
  const uniqueValues = [...new Set(filtered)];
  const normalizedPaymentMethods = uniqueValues.map(pm => {
    switch (pm) {
      case PAYMENT_METHODS_ENUMS.CARDONDELIVERY:
        return {
          id: FRAME_ENUM.CARD_ON_DELIVERY,
          title: <Text full={false} value={translations.CARDONDELIVERY} />,
          icon: <PaidMethodsIcon paidThrough={FRAME_ENUM.CARD_ON_DELIVERY} />,
        };
      case PAYMENT_METHODS_ENUMS.WALLET:
        return {
          id: pm,
          title: <Text full={false} value={translations.ORDERFAST_WALLET} />,
          icon: <PaidMethodsIcon paidThrough={pm} />,
        };
      case PAYMENT_METHODS_ENUMS.WALLET_AND_CREDIT_CARD:
        return {
          id: pm,
          title: <Text full={false} value={translations.ORDERFAST_WALLET_AND_CREDIT_CARD} />,
          icon: <PaidMethodsIcon paidThrough={pm} />,
        };
      default:
        return {
          id: pm,
          title: <Text full={false} value={translations[pm]} />,
          icon: <PaidMethodsIcon paidThrough={pm} />,
        };
    }
  });
  return normalizedPaymentMethods;
};
