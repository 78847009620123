import React, { useState, useContext } from 'react';

import * as translations from 'constants/translations';
import { context as localeContext } from 'context/locale';
import { context as notificationsContext } from 'context/notifications';
import { Text } from 'components/service';
import { Button } from 'components/kit';
import { useSelectedStore } from 'hooks';
import { useUpdateBulkDeliveryZone } from 'service/hooks';
import { bulkEditZones } from '../../utils';

const ActivateDeactivateBulkModal = ({ bulkZones, setZones, close, status }) => {
  const notifications = useContext(notificationsContext);
  const { translate } = useContext(localeContext);
  const selectedStoreId = useSelectedStore();
  const [pending, setPending] = useState(false);
  const updateBulkDeliveryZones = useUpdateBulkDeliveryZone();

  // handle bulk activate and deactivate
  const handleActivateBulk = async activeLabel => {
    setPending(true);

    const response = await updateBulkDeliveryZones({
      deliveryZones: bulkZones.map(bulkItem => ({
        restaurantReferenceId: parseFloat(selectedStoreId),
        id: bulkItem.properties.id,
        published: activeLabel,
      })),
    });

    if (!response.hasError) {
      setPending(false);
      if (response.updateBulkDeliveryZones) {
        const { deliveryZone, errors } = response.updateBulkDeliveryZones;

        // - All zones failed
        if (deliveryZone.length === 0 && errors.length >= 1) {
          notifications.show(<p>{errors[0].errorMessage}</p>, 'error');
          close();
        }

        // - Some zones success and some failed
        if (deliveryZone.length >= 1 && errors.length >= 1) {
          notifications.show(
            <div className="flex flex-wrap">
              <p>{translate(translations.BULK_EDIT_ISSUE)} &nbsp;</p>
              <ul className="list-none flex flex-wrap">
                {errors.map(errorItem => (
                  <li key={errorItem.zoneName}>{errorItem.zoneName},&nbsp;</li>
                ))}
              </ul>
            </div>,
            'error',
          );
          bulkEditZones(setZones, deliveryZone, 'published', activeLabel);
          close();
        }

        // - All zones success
        if (deliveryZone.length >= 1 && errors.length === 0) {
          notifications.show(<Text value={translations.BULK_EDIT_SUCCESS_MSG(deliveryZone.length)} />);
          bulkEditZones(setZones, deliveryZone, 'published', activeLabel);
          close();
        }
      }
    } else {
      setPending(false);
      close();
      notifications.show(<Text value={translations.SOMETHING_WENT_WRONG} />, 'error');
    }
  };

  return (
    <div className="flex items-center justify-end">
      <Button onClick={close} type="button" kind="tertiary" className="mx-4">
        <Text value={translations.DISMISS} />
      </Button>
      <Button
        onClick={() => {
          handleActivateBulk(status);
        }}
        kind="primary"
        disabled={pending}
      >
        <Text value={translations.CONFIRM} />
      </Button>
    </div>
  );
};

export default ActivateDeactivateBulkModal;
