import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

import { Text } from 'components/service';
import { Label } from 'components/form/generic';
import { Button, DropDown } from 'components/kit';
import {
  ASSIGNED_BRANCH,
  DISMISS,
  CONFIRM,
  CHOOSE_ONE_BRANCH,
  BULK_EDIT_ISSUE,
  BULK_EDIT_SUCCESS_MSG,
  SOMETHING_WENT_WRONG,
  REASIGN_BRANCH_MSG,
} from 'constants/translations';
import { context as localeContext } from 'context/locale';
import { context as notificationsContext } from 'context/notifications';
import { useSelectedStore } from 'hooks';
import { useUpdateBulkDeliveryZone } from 'service/hooks';
import { bulkReassignZones, failedBulkZones } from '../../utils';

const UpdateAssignBranchModal = ({ bulkZones, close, setZones, branches, tableViewController, setBulkZones }) => {
  const [branchId, setBranchId] = useState(tableViewController.selectedBranch);
  const notifications = useContext(notificationsContext);
  const { translate } = useContext(localeContext);
  const selectedStoreId = useSelectedStore();
  const [pending, setPending] = useState(false);
  const updateBulkDeliveryZones = useUpdateBulkDeliveryZone();

  const handleReassignBulk = async () => {
    setPending(true);

    const response = await updateBulkDeliveryZones({
      deliveryZones: bulkZones.map(bulkItem => ({
        restaurantReferenceId: parseFloat(selectedStoreId),
        id: bulkItem.properties.id,
        branchReferenceId: branchId,
      })),
    });

    if (!response.hasError) {
      setPending(false);
      if (response.updateBulkDeliveryZones) {
        const { deliveryZone, errors } = response.updateBulkDeliveryZones;

        // - All zones failed
        if (deliveryZone.length === 0 && errors.length >= 1) {
          notifications.show(<p>{errors[0].errorMessage}</p>, 'error');
          close();
        }

        // - Some zones success and some failed
        if (deliveryZone.length >= 1 && errors.length >= 1) {
          const failedZones = failedBulkZones(bulkZones, errors);
          notifications.show(
            <div className="flex flex-wrap">
              <p>{translate(BULK_EDIT_ISSUE)} &nbsp;</p>
              <ul className="list-none flex flex-wrap">
                {failedZones.map(failedZoneItem => (
                  <li className="px-1" key={failedZoneItem.properties.id}>
                    {failedZoneItem.properties.zone_name},
                  </li>
                ))}
              </ul>
            </div>,
            'error',
          );
          bulkReassignZones(setZones, deliveryZone);
          close();
          setBulkZones([]);
        }

        // - All zones success
        if (deliveryZone.length >= 1 && errors.length === 0) {
          notifications.show(<Text value={BULK_EDIT_SUCCESS_MSG(deliveryZone.length)} />, 'success');
          bulkReassignZones(setZones, deliveryZone);
          close();
          setBulkZones([]);
        }
      }
    } else {
      setPending(false);
      close();
      notifications.show(<Text value={SOMETHING_WENT_WRONG} />, 'error');
    }
  };

  return (
    <div>
      <div className="w-1/2">
        <Label
          textSize="text-xs"
          title={<Text value={ASSIGNED_BRANCH} />}
          subtitle={<Text value={REASIGN_BRANCH_MSG} />}
        >
          <DropDown
            onSelect={option => setBranchId(option)}
            optionSelected={branchId}
            placeholder={<Text value={CHOOSE_ONE_BRANCH} className="text-sm text-gray-500" />}
            data={branches}
            icon="keyboard_arrow_down"
            full
            scrollable
            className="w-full"
          />
        </Label>
      </div>
      <div className="flex items-center justify-end mt-4">
        <Button onClick={close} type="button" kind="tertiary" className="mx-4">
          <Text value={DISMISS} />
        </Button>
        <Button kind="primary" disabled={pending} onClick={handleReassignBulk}>
          <Text value={CONFIRM} />
        </Button>
      </div>
    </div>
  );
};

UpdateAssignBranchModal.propTypes = {
  bulkZones: PropTypes.arrayOf(
    PropTypes.shape({
      properties: PropTypes.shape({
        id: PropTypes.number,
      }),
    }),
  ),
  close: PropTypes.func.isRequired,
  setZones: PropTypes.func.isRequired,
  branches: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ).isRequired,
  tableViewController: PropTypes.shape({
    selectedBranch: PropTypes.number,
  }),
  setBulkZones: PropTypes.func.isRequired,
};
export default UpdateAssignBranchModal;
