import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Text } from 'components/service';
import * as translations from 'constants/translations';

const RefundWalletDetails = ({ refundedAmount, compensation = { percentage: 0, amount: 0 }, currency, lang }) => (
  <div className="flex flex-col bg-gray-100 rounded p-2">
    <div className="flex py-2 flex-row justify-between">
      <span className="text-xs font-semibold flex items-start">
        <Text value={translations.ORDERFAST_WALLET} />
      </span>
      <span className="text-xs font-semibold flex items-end">
        <Text value={currency} className={cx('inline', lang === 'ar' ? 'ml-1' : 'mr-1')} />
        {refundedAmount.toFixed(currency.decimals)}
      </span>
    </div>
    {compensation && compensation.amount > 0 && (
      <div className="flex flex-row justify-between">
        <span className="text-xs font-semibold flex items-start">
          <Text value={translations.COMPENSATION(compensation.percentage)} />
        </span>
        <span className="text-xs font-semibold flex items-end">
          <Text value={currency} className={cx('inline', lang === 'ar' ? 'ml-1' : 'mr-1')} />
          {compensation.amount.toFixed(currency.decimals)}
        </span>
      </div>
    )}
  </div>
);

RefundWalletDetails.propTypes = {
  refundedAmount: PropTypes.number.isRequired,
  compensation: PropTypes.shape({ percentage: PropTypes.number.isRequired, amount: PropTypes.number.isRequired }),
  currency: PropTypes.shape({
    decimals: PropTypes.number.isRequired,
    titleAr: PropTypes.string.isRequired,
    titleEn: PropTypes.string.isRequired,
  }).isRequired,
  lang: PropTypes.oneOf(['en', 'ar']).isRequired,
};
export default RefundWalletDetails;
