import { gql } from "@apollo/client"

export const ORDERS = gql`
  query Orders($storeId: String!) {
    orders(restaurantId: $storeId) {
      orders {
        id
        number
        paidThrough
        status
        total
        createdAt
      }
      totalCount
    }
  }
`
