import React from 'react';
import PropTypes from 'prop-types';

import * as translations from 'constants/translations';
import { Label } from 'components/form/generic';
import { DropDown } from 'components/kit';
import { Text } from 'components/service';

const BranchSelection = ({ branches, zoneController, setZoneController }) => (
  <Label
    title={<Text value={translations.SELECT_BRANCH} />}
    subtitle={
      !zoneController.enableDrawerMode ||
      zoneController.showDrawerMode ||
      zoneController.showZoneTemplates ||
      zoneController.isBulkSelected ? null : (
        <Text value={translations.CHOOSE_FROM_MENU_OR_MAP} className="text-gray-400" />
      )
    }
  >
    <DropDown
      isDisabled={
        !zoneController.enableDrawerMode ||
        zoneController.showDrawerMode ||
        zoneController.showZoneTemplates ||
        zoneController.isBulkSelected
      }
      onSelect={option => {
        setZoneController(prevState => ({
          ...prevState,
          selectedBranch: branches.find(branchItem => branchItem.id === option),
        }));
      }}
      optionSelected={zoneController.selectedBranch?.id}
      placeholder={<Text value={translations.CHOOSE_ONE_BRANCH} className="text-sm text-gray-500" />}
      data={branches}
      icon="keyboard_arrow_down"
      full
      scrollable
      className="w-full"
      isDeliveryZone
    />
  </Label>
);

BranchSelection.propTypes = {
  branches: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ).isRequired,
  zoneController: PropTypes.shape({
    enableDrawerMode: PropTypes.bool,
    showDrawerMode: PropTypes.bool,
    selectedBranch: PropTypes.shape({
      id: PropTypes.number,
    }),
    showZoneTemplates: PropTypes.bool,
    isBulkSelected: PropTypes.bool,
  }).isRequired,
  setZoneController: PropTypes.func.isRequired,
};

export default BranchSelection;
