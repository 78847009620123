import React, { useContext } from "react"
import { useQuery, useMutation } from "@apollo/client"
import { Formik, Form } from "formik"
import * as yup from "yup"
import cx from "classnames"
import { useSelectedStore } from "hooks"
import { Field, Text } from "components/service"
import { Button, Stack, SpinnerAlt } from "components/kit"
import { Label, Container, Row, Footer } from "components/form/generic"
import { Input } from "components/form/elements"

import * as translations from "constants/translations"
import { context as localeContext } from "context/locale"
import { context as userContext } from "context/user"
import { context as notificationsContext } from "context/notifications"
import { ReactComponent as CloseIcon } from "assets/close.svg"
import * as schemas from "./schemas"
import { handleSubmissionErrors } from "./utils"
const OptionGroupModal = ({
  initialValues,
  type,
  onCancel,
  openModel,
  refetchAllGroup,
  refetchAllOptions,
  setOptionGroupSelected,
}) => {
  const { lang } = useContext(localeContext)
  const { selectedStore: selected } = useContext(userContext)
  const notifications = useContext(notificationsContext)

  const storeId = useSelectedStore()
  const direction = lang === "ar" ? "rtl" : "ltr"

  const [createOptionGroup, { loading: isStillCreating }] = useMutation(
    schemas.CREATE_GROUP,
    {
      onCompleted: async data => {
        await refetchAllGroup.refetch()
        await refetchAllOptions.refetch()
      },
    }
  )

  const [updateOptionGroup, { loading: isStillUpdating }] = useMutation(
    schemas.UPDATE_GROUP
  )

  const optionGroups = useQuery(schemas.OPTION_GROUPS_PRODUCTS, {
    variables: {
      restaurantId: storeId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  })

  const validationSchema = yup.object().shape({
    titleEn: yup
      .string()
      .required(<Text value={translations.TITLE_EN_IS_REQ} />),
    titleAr: yup
      .string()
      .required(<Text value={translations.TITLE_AR_IS_REQ} />),
  })

  const productNames =
    type === "add"
      ? " "
      : optionGroups.data &&
        optionGroups.data.propertySections.find(
          item => item.id === initialValues.groupName.id
        )

  const sharedItems =
    type === "add"
      ? " "
      : productNames &&
        productNames.menuItems &&
        productNames.menuItems
          .filter(item => item.id !== initialValues.groupName.id)
          .map(i => {
            return (
              <Text prefix={"["} value={i} postfix={"]"} className="inline" />
            )
          })

  return (
    <Formik
      initialValues={
        type === "edit"
          ? {
              titleEn: initialValues.groupName.titleEn,
              titleAr: initialValues.groupName.titleAr,
            }
          : {}
      }
      validationSchema={validationSchema}
      onSubmit={async values => {
        let variables = {
          restaurantId: storeId,
          titleEn: values.titleEn,
          titleAr: values.titleAr,
          ...(type === "edit" && {
            propertyId: initialValues.groupName.id,
          }),
        }
        type === "edit"
          ? await updateOptionGroup({ variables: variables })
              .then(() => {
                notifications.show(
                  <Text
                    value={translations.OPTION_GROUPS_EDIT_OPTION_GROUP_SUCCESS}
                  />
                )
              })
              .catch((e) => {
                const message = handleSubmissionErrors(e)
                notifications.show(message, "error")
              })
          : await createOptionGroup({ variables: variables })
              .then(res => {
                setOptionGroupSelected &&
                  setOptionGroupSelected(res.data.createOptionGroup.id)
                notifications.show(
                  <Text
                    value={translations.OPTION_GROUPS_ADD_OPTION_GROUP_SUCCESS}
                  />
                )
              })
              .catch((e) => {
                const message = handleSubmissionErrors(e)
                notifications.show(message, "error")
              })
        onCancel()
      }}
      onCancel={onCancel}
    >
      {({ values, setFieldValue, ...rest }) => (
        <Form style={{ direction }}>
          <div className="px-4 my-4 w-full">
            <Container>
              <Text value={translations.TITLE} className="text-base -mb-4" />
              <Row className="flex-col md:flex-row">
                <>
                  <div className="flex flex-col md:flex-row justify-between items-start">
                    <div
                      className={cx(
                        "w-full mb-6 md:mb-0",
                        lang === "en" ? "mr-0 md:mr-4" : "ml-0 md:ml-4"
                      )}
                    >
                      <Label title={<Text value={translations.ENGLISH} />}>
                        <Field
                          type="text"
                          data-testid="optionGroup-add-nameEn"
                          name="titleEn"
                          component={Input}
                          placeholder={
                            translations.OPTION_GROUPS_ADD_GROUP_EX[0]
                          }
                          direction="ltr"
                        />
                      </Label>
                    </div>
                    <div className="w-full mb-0 md:mb-0">
                      <Label title={<Text value={translations.ARABIC} />}>
                        <Field
                          direction="rtl"
                          type="text"
                          data-testid="optionGroup-add-nameAr"
                          name="titleAr"
                          placeholder={
                            translations.OPTION_GROUPS_ADD_GROUP_EX[1]
                          }
                          component={Input}
                        />
                      </Label>
                    </div>
                  </div>
                  {type === "edit" && productNames && !!sharedItems.length && (
                    <Label
                      subtitle={
                        <>
                          {!optionGroups.data && !productNames ? (
                            <SpinnerAlt color="primary-base" />
                          ) : (
                            !!sharedItems.length && (
                              <div className="pt-2 text-xs text-gray-500">
                                <Text
                                  value={translations.THIS_IS_SHARED}
                                  payload={
                                    lang === "en"
                                      ? values.titleEn
                                      : values.titleAr
                                  }
                                  className="inline"
                                />
                                {sharedItems.slice(0, 10)}
                                {sharedItems.length > 11 ? (
                                  <Text value={translations.ETC} />
                                ) : null}
                              </div>
                            )
                          )}
                        </>
                      }
                    />
                  )}
                </>
              </Row>
            </Container>
          </div>
          <Footer modalFooter>
            <div className="w-full flex items-center">
              {type === "edit" && (
                <button
                  type="button"
                  className="text-red-600 flex items-center outline-none focus:outline-none"
                  onClick={() => {
                    let variables = {
                      restaurantId: storeId,
                      propertyId: initialValues.groupName.id.toString(),
                    }
                    openModel({
                      testId: "product-delete-modal",
                      title: (
                        <Text value={translations.OPTION_GROUPS_DELETE_GROUP} />
                      ),
                      body: (
                        <DeleteProductBody
                          onCancel={onCancel}
                          variables={variables}
                          refetchAllOptions={refetchAllOptions}
                          refetchAllGroup={refetchAllGroup}
                        />
                      ),
                      size: "max-w-sm",
                    })
                  }}
                >
                  <Text
                    className="text-sm"
                    value={translations.OPTION_GROUPS_DELETE_GROUP}
                  />
                </button>
              )}
              <div className={cx(lang === "ar" ? "mr-auto" : "ml-auto")}>
                <Stack direction="row">
                  <Button
                    data-testid={
                      type === "add"
                        ? "add-cancel-category-btn"
                        : "edit-cancel-category-btn"
                    }
                    type="button"
                    kind="tertiary"
                    onClick={onCancel}
                  >
                    <Text value={translations.CANCEL} />
                  </Button>
                  {type === "edit" ? (
                    <Button
                      data-testid="edit-save-category-btn"
                      kind="primary"
                      isSpinning={isStillUpdating}
                    >
                      <Text value={translations.SAVE} />
                    </Button>
                  ) : (
                    <Button
                      data-testid="add-optionGroup-btn"
                      kind="primary"
                      isSpinning={isStillCreating}
                    >
                      <Text value={translations.ADD} />
                    </Button>
                  )}
                </Stack>
              </div>
            </div>
          </Footer>
        </Form>
      )}
    </Formik>
  )
}
const DeleteProductBody = ({
  onCancel,
  variables,
  refetchAllOptions,
  refetchAllGroup,
}) => {
  const { lang } = useContext(localeContext)
  const notifications = useContext(notificationsContext)

  const [deleteGroup, { loading: isStillDeleting }] = useMutation(
    schemas.DELETE_GROUP,
    {
      onCompleted: async () => {
        await refetchAllOptions.refetch()
      },
    }
  )
  const handleDeleteGroup = async (variables, onCancel) => {
    try {
      await deleteGroup({
        variables: variables,
      }).then(() => {
        notifications.show(
          <Text
            value={translations.OPTION_GROUPS_DELETE_OPTION_GROUP_SUCCESS}
          />
        )
      })
      onCancel()
      refetchAllGroup.refetch()
    } catch (error) {
      notifications.show(
        <Text value={translations.SOMETHING_WENT_WRONG} />,
        "error"
      )
    }
  }

  return (
    <div>
      <Text
        className="mx-1 p-4"
        value={translations.OPTION_GROUPS_DELETE_GROUP_CONFIRMATION}
      />
      <Footer modalFooter>
        <div className="w-full flex items-center">
          <div className={cx(lang === "ar" ? "mr-auto" : "ml-auto")}>
            <Stack direction="row">
              <Button
                data-testid="dismiss-delete-product"
                onClick={onCancel}
                type="button"
                kind="tertiary"
              >
                <Text value={translations.DISMISS} />
              </Button>
              <Button
                kind="secondaryError"
                onClick={() => handleDeleteGroup(variables, onCancel)}
                data-testid="btn-delete-product"
                isSpinning={isStillDeleting}
              >
                <Text value={translations.DELETE} />
              </Button>
            </Stack>
          </div>
        </div>
      </Footer>
    </div>
  )
}
export default OptionGroupModal
