import { gql } from '@apollo/client';

export const BRANCHES = gql`
  query Branches($restaurantId: String!) {
    branches(restaurantId: $restaurantId) {
      id
      titleEn
      titleAr
      lat
      lng
    }
  }
`;

export const SETTINGS = gql`
  query($storeId: String!) {
    settings(restaurantId: $storeId) {
      id
      isAutoAccept
      cardondeliveryPaymentEnabled
    }
  }
`;

export const AUTHORIZE = gql`
  mutation authorizeCourier($apiKey: String!, $courierName: String!, $restaurantId: String!) {
    authorizeCourier(apiKey: $apiKey, courierName: $courierName, restaurantId: $restaurantId)
  }
`;

export const AUTHORIZE_BRANCHES = gql`
  mutation authorizeCourierBranches($apiKey: [BranchAPIKey]!, $courierName: String!, $restaurantId: String!) {
    authorizeCourierBranches(apiKey: $apiKey, courierName: $courierName, restaurantId: $restaurantId)
  }
`;
