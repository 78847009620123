import React, { useContext } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import cx from 'classnames';
import * as R from 'ramda';
import { Form, Formik, Field } from 'formik';

import { context as notificationsContext } from 'context/notifications';
import { Layout, Breadcrumbs } from 'components/common/dashboard';
import { Text } from 'components/service';
import { Button, Spinner } from 'components/kit';
import * as translations from 'constants/translations';
import { useSelectedStore } from 'hooks/index';
import { RadioList, Input } from 'components/form/elements';
import { context as localeContext } from 'context/locale';
import { Row, Footer } from 'components/form/generic';
import * as schemas from './schemas';
import EmptyState from '../manageVariants/EmptyState';
import * as data from './data';
import SettingsBox from 'components/form/generic/SettingsBox/SettingsBox';
import { DASHBOARD_LAYOUT } from '../../../services/user';

const StockSettings = () => {
  const storeId = useSelectedStore();
  const { loading, data: settings } = useQuery(schemas.STOCK_SETTINGS, {
    variables: { storeId },
    fetchPolicy: 'no-cache',
  });

  const notifications = useContext(notificationsContext);

  const { lang, translate, direction } = useContext(localeContext);

  const [updateSettings, { loading: isStillUpdating }] = useMutation(schemas.UPDATE_STOCK_SETTINGS);

  let stockView;

  if (!settings) {
    stockView = <Spinner />;
  } else if (!settings?.settings?.isStockEnabled && !settings?.dashboardLayout === DASHBOARD_LAYOUT.RESTAURANT) {
    stockView = <EmptyState />;
  } else {
    stockView = null;
  }

  return (
    <Layout
      top={
        <Breadcrumbs
          isMenu
          isOverview
          reverse
          isOptions
          customTitle={<Text value={translations.STOCK_GENERAL_SETTINGS} className="text-2xl font-bold" />}
        />
      }
    >
      {stockView ? (
        stockView
      ) : (
        <div className={cx('px-6 xl:px-8 pb-6 flex', lang === 'ar' && 'flex-row-reverse')}>
          <div className="w-full md:w-2/3" style={{ direction }}>
            <Formik
              initialValues={R.mergeDeepRight(
                data.initialValues,
                settings && {
                  lowStockField: settings.settings.lowStockThreshold,
                  storeMessageField: settings.settings.lowStockMessage === 'FEW_ITEMS_LEFT' ? 1 : 2,
                },
              )}
              onSubmit={async values => {
                let message = values.storeMessageField === 1 ? 'FEW_ITEMS_LEFT' : 'LESS_THAN_MIN_LEFT';
                let threshold = values.lowStockField ? values.lowStockField : 0;
                let variables = {
                  storeId: storeId,
                  lowStockThreshold: threshold,
                  lowStockMessage: message,
                };
                await updateSettings({ variables: variables })
                  .then(() => {
                    notifications.show(<Text value={translations.STORE_INFORMATION_UPDATED} />);
                  })
                  .catch(() => {
                    notifications.show(<Text value={translations.INVALID_FORMAT_UPLOAD} />, 'error');
                  });
              }}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <Row>
                    <SettingsBox
                      title={<Text value={translations.WARNINIG_THRESHOLD} />}
                      description={<Text value={translations.LOW_STOCK_NOTIFY} />}
                    >
                      <Field
                        name="lowStockField"
                        data-testid="stockManagement-lowStock"
                        min={0}
                        step={1}
                        placeholder={translate(translations.LOW_STOCK_EX)}
                        component={Input}
                        width="w-full md:w-1/2"
                        validation={n => n >= 0}
                        value={values.lowStockField}
                        onChange={e => setFieldValue('lowStockField', parseInt(e.target.value))}
                      />
                      <div />
                    </SettingsBox>
                  </Row>
                  <Row className="py-4">
                    <SettingsBox
                      title={<Text value={translations.STORE_MESSAGE} />}
                      description={<Text value={translations.STORE_MESSAGE_CONTENT} />}
                    >
                      <div style={{ marginLeft: '-7px' }}>
                        <Field
                          data-testid="storeMessageField"
                          onChange={e => setFieldValue('storeMessageField', parseInt(e.target.value))}
                          direction="col"
                          name="storeMessageField"
                          value={values.storeMessageField}
                          items={[
                            {
                              title: <Text value={translations.STORE_MESSAGE_FEW_ITEMS} />,
                              value: 1,
                            },
                            {
                              title: (
                                <Text
                                  value={translations.STORE_MESSAGE_LESS_THAN}
                                  payload={values.lowStockField && values.lowStockField ? values.lowStockField : 0}
                                />
                              ),
                              value: 2,
                            },
                          ]}
                          component={RadioList}
                        />
                      </div>
                    </SettingsBox>
                  </Row>
                  <Footer isMenu>
                    <Button
                      data-testid="stockManagement-general-save"
                      kind="primary"
                      isSpinning={loading || isStillUpdating}
                    >
                      <Text value={translations.SAVE_CHANGE} />
                    </Button>
                  </Footer>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default StockSettings;
