import React, { useContext, useRef, useState, useEffect } from 'react';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { navigate } from '@reach/router';
import PropTypes from 'prop-types';

import { usePriceFormat, useReplaceParams } from 'hooks/index';
import { Table, TableFooter, Input } from 'components/form/elements';
import { Text } from 'components/service';
import { Spinner } from 'components/kit';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import * as translations from 'constants/translations';
import * as paths from 'paths';
import { ReactComponent as SearchIcon } from 'assets/search.svg';
import { exportGeoBranchesReport } from '../../../../rest';
import GEO_BRANCHES from './schemas';
import { transformGeoBranchesData } from '../utils';
import momentLanguage from '../../utils';
import { ANALYTICS_TAB_NAMES } from '../../constants';

const GeoBranches = ({ range, setQuery, page }) => {
  const { lang, translate, direction } = useContext(localeContext);
  const { selectedStore } = useContext(userContext);
  const searchTimeout = useRef();
  const [searchValue, setSearchValue] = useState();
  const [transformedData, setTransformedData] = useState([]);
  const formatPrice = usePriceFormat();
  const replace = useReplaceParams();

  moment.locale(momentLanguage(lang));

  const LIMIT = 30;
  const { data, loading } = useQuery(GEO_BRANCHES, {
    variables: {
      storeId: selectedStore.id,
      range,
      page,
      limit: LIMIT,
      filter: searchValue,
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    setTransformedData(transformGeoBranchesData(data?.geoAnalyticsBranches?.data?.branches));
  }, [data]);

  const formatData = values =>
    values.map(item => ({
      branch: (
        <Text
          value={item.branch}
          className="font-semibold text-primary-base cursor-pointer"
          onClick={() => {
            navigate(
              replace(paths.geoAnalyticsBranchAreas, {
                params: { branchId: item.id },
                appendQuery: true,
              }),
            );
          }}
        />
      ),
      totalSales: formatPrice(item.totalSales),
      avgOrderValue: formatPrice(item.avgOrderValue),
      totalOrders: item.totalOrders,
      totalCustomers: item.totalCustomers,
      totalPickupOrders: item.totalPickupOrders,
      totalDeliveryOrders: item.totalDeliveryOrders,
    }));

  const handleExportFunction = () =>
    (window.location.href = exportGeoBranchesReport(selectedStore.id, range.start, range.end));

  const totalRecords = data?.geoAnalyticsBranches?.data?.meta?.totalCount || 0;

  return (
    <div>
      <div className="pb-32 flex flex-col">
        <div>
          <div className="mb-4 flex justify-between items-center" style={{ direction }}>
            <Input
              type="text"
              search
              icon={<SearchIcon />}
              width="full md:w-1/2"
              value={searchValue}
              onChange={e => {
                clearTimeout(searchTimeout.current);
                const { value } = e.target;
                searchTimeout.current = setTimeout(() => {
                  setSearchValue(value);
                }, 500);
              }}
              placeholder={translate(translations.PLACEHOLDER_SEARCH_BRANCHES)}
            />
          </div>
        </div>
        {loading ? (
          <Spinner />
        ) : (
          <div className="w-full">
            <Table
              dataHeader={[
                {
                  key: ANALYTICS_TAB_NAMES.branch.camelCase,
                  name: <Text value={translations.BRANCH} />,
                  sort: false,
                  inView: false,
                },
                {
                  key: ANALYTICS_TAB_NAMES.totalSales.camelCase,
                  name: <Text value={translations.TOTAL_SALES} />,
                  inView: false,
                },
                {
                  key: ANALYTICS_TAB_NAMES.averageOrderValue.camelCase,
                  name: <Text value={translations.AVERAGE_ORDER_VALUE} />,
                  inView: false,
                },
                {
                  key: ANALYTICS_TAB_NAMES.totalDeliveryOrders.camelCase,
                  name: <Text value={translations.DELIVERY_ORDERS} />,
                  inView: false,
                },
                {
                  key: ANALYTICS_TAB_NAMES.totalPickupOrders.camelCase,
                  name: <Text value={translations.PICKUP_ORDERS} />,
                  inView: false,
                },
                {
                  key: ANALYTICS_TAB_NAMES.totalOrders.camelCase,
                  name: <Text value={translations.TOTAL_ORDERS} />,
                  inView: false,
                },
                {
                  key: ANALYTICS_TAB_NAMES.totalCustomers.camelCase,
                  name: <Text value={translations.TOTAL_CUSTOMERS} />,
                  inView: false,
                },
              ]}
              dataBody={transformedData}
              formatData={values => formatData(values)}
              footer={
                <TableFooter
                  totalRecords={totalRecords}
                  pageLimit={LIMIT}
                  currentPage={page}
                  onPageChanged={({ currentPage }) => setQuery({ page: currentPage })}
                  totalCount={transformedData.length}
                  exportLink={handleExportFunction}
                />
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

GeoBranches.propTypes = {
  range: PropTypes.shape({ start: PropTypes.string, end: PropTypes.string }).isRequired,
  setQuery: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
};

export default GeoBranches;
