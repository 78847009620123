import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useLocation } from '@reach/router';

import { context as localeContext } from 'context/locale';
import { context as customersContext } from 'context/customers';
import { context as userContext } from 'context/user';
import { context as featureAccessContext } from 'context/feature-blocking';
import Segment from 'pages/customers/Customers/Segment';
import { SEGMENT_TITLE_MAP } from 'constants/customers';
import { ACCESS_MAP_KEYS } from 'constants/featureAccessMatrix';
import FeatureAccess from 'common/lib/FeatureAccess';

const SegmentsFilter = ({ currentSegment = null, handleOnClick, hasAllCustomers = false }) => {
  const { lang, translate } = useContext(localeContext);
  const { selectedStore } = useContext(userContext);
  const { featureAccess } = useContext(featureAccessContext);
  const { segments } = useContext(customersContext);
  const location = useLocation();
  const filteredSegmentsKeys = hasAllCustomers
    ? Object.keys(segments)
    : Object.keys(segments).filter(key => parseInt(key) !== SEGMENT_TITLE_MAP.ALL_CUSTOMERS);

  const handleFilterChange = filter => {
    let setFilter;
    if (parseInt(currentSegment) === filter) setFilter = null;
    else setFilter = filter;
    handleOnClick(setFilter);
  };

  const isSegmentUpgradable = featureAccess
    .get(ACCESS_MAP_KEYS.FEATURES)
    ?.get(FeatureAccess.getFeaturePath(location?.pathname, selectedStore.id))?.SEGMENT_TARGETING?.isBlocked;

  return (
    <div
      className={cx('grid grid-col-1 gap-4 mb-6', hasAllCustomers ? 'lg:grid-cols-3' : 'md:grid-cols-2 lg:grid-cols-3')}
    >
      {filteredSegmentsKeys.map(key => (
        <Segment
          key={segments[key].segmentId}
          segmentId={segments[key].segmentId}
          handleFilterChange={handleFilterChange}
          currentFilter={currentSegment && parseInt(currentSegment)}
          segmentCss={cx(segments[key].segmentBaseCss, segments[key].segmentFilterCss)}
          segmentIcon={segments[key].segmentIcon}
          segmentName={lang === 'en' ? segments[key].titleEn : segments[key].titleAr}
          segmentText={lang === 'en' ? segments[key].descriptionEn : segments[key].descriptionAr}
          numberOfUsersBelongingToSegment={segments[key].customersCount}
          percentageOfUsersInSegment={segments[key].customerPercentage}
          lang={lang}
          hasAllCustomers={segments[key].segmentId === SEGMENT_TITLE_MAP.ALL_CUSTOMERS}
          translate={translate}
          upgradable={isSegmentUpgradable && segments[key].segmentId !== SEGMENT_TITLE_MAP.ALL_CUSTOMERS}
        />
      ))}
    </div>
  );
};

SegmentsFilter.propTypes = {
  currentSegment: PropTypes.string,
  handleOnClick: PropTypes.func.isRequired,
  hasAllCustomers: PropTypes.bool,
};

export default SegmentsFilter;
