import React, { useContext } from "react"
import { context as localeContext } from "context/locale"
import cx from "classnames"

export default ({
  title,
  description,
  subtitle,
  right,
  bottomRight,
  htmlFor,
  children,
  error,
  textSize = "text-sm",
  font,
  trackedItem,
}) => {
  const { lang, direction } = useContext(localeContext)
  return (
    <div className="w-full">
      {title && (
        <div className={cx("flex items-center")} style={{ direction }}>
          <label
            htmlFor={htmlFor}
            className={cx("block", textSize, font && "font-medium")}
          >
            {title}
          </label>
          {right && (
            <div
              className={cx(
                lang === "ar"
                  ? "mr-auto h-1 mb-auto"
                  : "ml-auto h-1 flex items-center "
              )}
            >
              {right}
            </div>
          )}
        </div>
      )}
      {description && (
        <div className="text-xs text-gray-700">{description}</div>
      )}
      {children && (
        <div className={cx("mt-2", trackedItem ? "flex-1" : "flex")}>
          {children}
        </div>
      )}
      <div className={cx("flex items-center")}>
        {subtitle && (
          <span
            className={cx(
              "block text-gray-700 text-xs",
              error ? children && "mt-8" : children && "mt-2"
            )}
          >
            {subtitle}
          </span>
        )}
        {bottomRight && (
          <div className={cx(lang === "ar" ? "mr-auto" : "ml-auto", "mt-2")}>
            {bottomRight}
          </div>
        )}
      </div>
    </div>
  )
}
