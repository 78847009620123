import React from "react"
import * as yup from "yup"
import { Text } from "components/service"
import * as translations from "constants/translations"

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"]

export const validationSchema = ({ countryCode, sections })  => {
  const bankInfoValidations = {
    accountType: yup
        .string()
        .required(<Text value={translations.ACCOUNT_TYPE_REQ} />)
        .nullable(),
    bankName: yup.string().when("paymentGatewayId", {
      is: 1,
      then: yup
        .string()
        .required(<Text value={translations.BANK_NAME_REQ} />)
        .nullable()
    }).nullable(),
    bankId: yup.string().when("paymentGatewayId", {
      is: 2,
      then: yup
        .string()
        .required(<Text value={translations.BANK_NAME_REQ} />)
        .nullable()
    }).nullable(),
    iban: yup
      .string()
      .required(<Text value={translations.IBAN_REQ} />)
      .max(30, <Text value={translations.IBAN_CHARACTERES_MAX_NUMBER} />)
      .min(10, <Text value={translations.IBAN_CHARACTERES_MIN_NUMBER} />)
      .nullable(),
    accountName: yup
      .string()
      .required(<Text value={translations.ACCOUNT_NAME_REQ} />)
      .nullable(),
    paymentDepositTermId: yup.string().when("paymentGatewayId", {
      is: 2,
      then: yup
        .string()
        .required(<Text value={translations.DEPOSIT_TERMS_REQ} />)
        .nullable()
    }).nullable(),
    accountNumber: yup
      .string()
      .when("isNotKW", {
        is: true,
        then: yup
          .string()
          .required(<Text value={translations.ACCOUNT_NUMBER_REQ} />)
          .nullable(),
        otherwise: yup.string().nullable(),
      })
      .min(8, <Text value={translations.ACCOUNT_NUMBER_MIN_NUMBER} />),
    beneficiaryAddress: yup.string().when("isNotKW", {
      is: true,
      then: yup
        .string()
        .required(<Text value={translations.BENEFICIARY_ADDRESS_REQ} />)
        .nullable(),
      otherwise: yup.string().nullable(),
    }),
    bankAddress: yup.string().when("isNotKW", {
      is: true,
      then: yup
        .string()
        .required(<Text value={translations.BANK_ADDRESS_REQ} />)
        .nullable(),
      otherwise: yup.string().nullable(),
    }),
    swiftCode: yup.string().when("isNotKW", {
      is: true,
      then: yup
        .string()
        .required(<Text value={translations.SWIFT_CODE_REQ} />)
        .nullable(),
      otherwise: yup.string().nullable(),
    }),
  }
  const legalInfoValidations = {
    firstName: yup
      .string()
      .min(3, <Text value={translations.FIRST_NAME_MINIMUM} />)
      .required(<Text value={translations.FIRST_NAME_REQUIRED} />)
      .nullable(),
    lastName: yup
      .string()
      .min(3, <Text value={translations.LAST_NAME_MINIMUM} />)
      .required(<Text value={translations.LAST_NAME_REQUIRED} />)
      .nullable(),
    emailAddress: yup
      .string()
      .email(<Text value={translations.INVALID_EMAIL_FORMAT} />)
      .required(<Text value={translations.EMAIL_IS_REQ} />)
      .nullable(),
    password: yup
      .string()
      .required(<Text value={translations.PASSWORD_REQUIRED} />)
      .min(3, <Text value={translations.PASSWORD_MIN_CHARACTERS} />)
      .max(15, <Text value={translations.PASSWORD_MAX_CHARACTERS} />)
      .nullable(),
    mobileNumber: yup
      .string()
      .required(<Text value={translations.PHONE_IS_REQ} />)
      .nullable(),
    idNumber: yup
      .string()
      .matches(/[0-9]/, <Text value={translations.INVALID_NATIONAL_NUM} />)
      .required(<Text value={translations.NATIONAL_NUM_REQ} />)
      .nullable(),
    nationalFrontId: yup
      .mixed()
      .required(<Text value={translations.IMAGE_REQ} />)
      .test(
        "fileType",
        <Text value={translations.INVALID_FORMAT_UPLOAD} />,
        value => {
          if (value instanceof File) {
            return SUPPORTED_FORMATS.includes(value.type)
          } else {
            return true
          }
        }
      ),
    nationalBackId: yup
      .mixed()
      .required(<Text value={translations.IMAGE_REQ} />)
      .test(
        "fileType",
        <Text value={translations.INVALID_FORMAT_UPLOAD} />,
        value => {
          if (value instanceof File) {
            return SUPPORTED_FORMATS.includes(value.type)
          } else {
            return true
          }
        }
      ),
    profileName: yup
      .string()
      .matches(
        /^[A-Za-z0-9]+$/,
        <Text value={translations.PROFILE_NAME_VALIDATION} />
      )
      .required(<Text value={translations.PROFILE_NAME_REQ} />)
      .nullable(),
  }
  const licensedBusinessValidations = {
    licenseNumber: yup.string().when("isLicensed", {
      is: true,
      then: yup
        .string()
        .matches(/[0-9]/, <Text value={translations.INVALID_LICENSE_NUMBER} />)
        .required(<Text value={translations.LICENSE_NUMBER_REQ} />)
        .nullable(),
      otherwise: yup.string().nullable(),
    }),
    name: yup.string().when("isLicensed", {
      is: true,
      then: yup
        .string()
        .required(<Text value={translations.STORE_NAME_REQ} />)
        .nullable(),
      otherwise: yup.string().nullable(),
    }),
    
    LicenseCopy: yup.mixed().when("isLicensed", {
      is: true,
      then: yup
        .mixed()
        .required(<Text value={translations.IMAGE_REQ} />)
        .test(
          "fileType",
          <Text value={translations.INVALID_FORMAT_UPLOAD} />,
          value => {
            if (value instanceof File) {
              return SUPPORTED_FORMATS.includes(value.type)
            } else {
              return true
            }
          }
        ),
      otherwise: yup.mixed(),
    }),
    authorizedSignatoryCopy: yup.mixed().when("isLicensed", {
      is: true,
      then: yup
        .mixed()
        .required(<Text value={translations.IMAGE_REQ} />)
        .test(
          "fileType",
          <Text value={translations.INVALID_FORMAT_UPLOAD} />,
          value => {
            if (value instanceof File) {
              return SUPPORTED_FORMATS.includes(value.type)
            } else {
              return true
            }
          }
        ),
      otherwise: yup.mixed(),
    }),
  }
  const integrationInfoValidations = {
    merchantId: yup
      .string()
      .required(<Text value={translations.REQUIRED} />)
      .nullable(),
    apiKey: yup
      .string()
      .required(<Text value={translations.REQUIRED} />)
      .nullable(),
    iframeId: yup
      .string()
      .required(<Text value={translations.REQUIRED} />)
      .nullable(),
    cardIntegrationId: yup
      .string()
      .required(<Text value={translations.REQUIRED} />)
      .nullable()
  }

  const validations = {}
  sections.includes('bankInfo')         && Object.assign(validations, bankInfoValidations)
  sections.includes('legalInfo')        && Object.assign(validations, legalInfoValidations)
  sections.includes('licensedBusiness') && Object.assign(validations, licensedBusinessValidations)
  sections.includes('integrationInfo')  && Object.assign(validations, integrationInfoValidations)

  return (
    yup.object().shape(validations)
  )
}
