import React, { useContext } from 'react';
import { context as localeContext } from 'context/locale';
import { Row, Label } from 'components/form/generic';
import { Input } from 'components/form/elements';
import { Text, Field } from 'components/service';
import * as translations from 'constants/translations';
import PropTypes from 'prop-types';

const Step = ({ step, stepKey, setFieldValue }) => {
  const { type } = step;
  const { lang } = useContext(localeContext);

  return (
    <div key={stepKey}>
      {type === 'info' ? (
        <>
          {step?.description?.map(paragraph => (
            <div key={paragraph?.en}>
              {paragraph?.url ? (
                <a
                  className="mb-4 text-primary-base hover:text-primary-700"
                  rel="noopener noreferrer"
                  target="_blank"
                  href={paragraph.url}
                >
                  {paragraph?.url}
                </a>
              ) : (
                <Text value={[paragraph?.en, paragraph?.ar]} />
              )}
            </div>
          ))}
        </>
      ) : (
        <>
          {step?.fields?.map(field => {
            const validation = value => {
              let error;
              if (field?.required && !value) error = translations.REQUIRED[lang === 'en' ? 0 : 1];
              return error;
            };
            return (
              <Row className="w-full" key={field.label?.en}>
                <Label
                  title={<Text value={[field.label?.en, field.label?.ar]} />}
                  subtitle={<Text value={[field.legend?.en, field.legend?.ar]} />}
                >
                  <Field
                    type="text"
                    autoComplete="off"
                    after
                    name={field.name}
                    placeholder={lang === 'en' ? field.label?.en : field.label?.ar}
                    validate={validation}
                    component={Input}
                    onChange={e => {
                      setFieldValue(field.name, e.target.value);
                    }}
                  />
                </Label>
              </Row>
            );
          })}
        </>
      )}
    </div>
  );
};

Step.propTypes = {
  step: PropTypes.shape({
    description: PropTypes.arrayOf(
      PropTypes.shape({
        ar: PropTypes.string.isRequired,
        en: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }),
    ),
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        required: PropTypes.bool,
        label: PropTypes.shape({
          ar: PropTypes.string.isRequired,
          en: PropTypes.string.isRequired,
        }),
        legend: PropTypes.shape({
          ar: PropTypes.string.isRequired,
          en: PropTypes.string.isRequired,
        }),
      }),
    ),
    type: PropTypes.string.isRequired,
  }).isRequired,
  stepKey: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default Step;
