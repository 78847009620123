import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { timeMonth } from 'd3-time';
import { ResponsiveBar } from '@nivo/bar';
import { toLower } from 'ramda';
import { useMediaQuery } from 'react-responsive';

import { context as localeContext } from 'context/locale';
import { useMobile } from 'hooks/index';
import * as translations from 'constants/translations';
import { Spinner } from 'components/kit';
import { barChartDataTransform } from '../utils';
import { ANALYTICS_TAB_NAMES } from '../../constants';
import BarLegend from './BarLegend';
import TooltipProp from './TooltipProp';

const BehaviourBreakdownBarChart = ({ data, type, selectedDataType, display, selection }) => {
  const { translate, direction, lang } = useContext(localeContext);
  const isMobile = useMobile();
  const isLaptop = useMediaQuery({ maxWidth: 1280 });
  const labels = {
    new: translate(translations.NEW),
    returning: translate(translations.RETURNING),
    delivery: translate(translations.DELIVERY),
    pickup: translate(translations.PICKUP),
    cash: translate(translations.CAPITAL_CASH),
    knet: translate(translations.KNET_NO_DASH),
    benefit: translate(translations.BENEFIT),
    cardondelivery: translate(translations.COD),
    creditcard: translate(translations.CREDIT_CARD),
    meeza: translate(translations.MEEZA),
    mada: translate(translations.MADA),
    applepay: translate(translations.APPLE_PAY_ANALYTICS),
  };
  const GROUP_MODE = {
    GROUPED: 'grouped',
    STACKED: 'stacked',
  };
  const [format, setFormat] = useState('MMM, YY');
  useEffect(() => {
    setFormat(type === 'day' ? 'MMM DD, YYYY' : 'MMM, YY');
  }, [type]);

  const [keys, setKeys] = useState([]);
  const [dataResult, setDataResult] = useState([]);

  useEffect(() => {
    const tempKeys = data?.data?.currentPeriod?.keys?.map(item => toLower(item)) || [];
    setKeys(tempKeys);
    setDataResult(
      (data?.data?.currentPeriod && tempKeys && barChartDataTransform(data.data.currentPeriod, type, tempKeys)) || [],
    );
  }, [data, type]);

  const getTickValues = (dataResultTemp, periodType, isMobileFlag, isLaptopFlag) => {
    let tempTickValues = [];
    if (periodType === 'day') {
      if (dataResultTemp.length > 10)
        tempTickValues = [
          dataResultTemp[0].date,
          dataResultTemp[Math.round(dataResultTemp.length / 2 - 1)].date,
          dataResultTemp[dataResultTemp.length - 1].date,
        ];
    } else if (dataResultTemp.length > 4)
      tempTickValues = [
        ...timeMonth
          .every(isMobileFlag ? 4 : isLaptopFlag ? 2 : 1)
          .range(new Date(dataResultTemp[0].date), new Date(dataResultTemp[dataResultTemp.length - 1].date))
          .map(i => moment(i).format('YYYY-MM')),
      ];
    return tempTickValues;
  };

  const findMaxValue = (data, keys) => {
    if (data?.length === 0) return 0;
    return Math.max.apply(
      Math,
      data.map(item =>
        Math.max(
          ...keys.map(key => {
            if (isNaN(item[key])) return 0;
            return item[key];
          }),
        ),
      ),
    );
  };

  const maxValue = findMaxValue(dataResult, keys);
  
  return keys.length === 0 || dataResult.length === 0 ? (
    <Spinner />
  ) : (
    <div className="h-450" dir="ltr">
      <ResponsiveBar
        animate
        groupMode={
          selection === ANALYTICS_TAB_NAMES.averageOrderValue.snakeCaseName ? GROUP_MODE.GROUPED : GROUP_MODE.STACKED
        }
        maxValue={selection === ANALYTICS_TAB_NAMES.averageOrderValue.snakeCaseName ? maxValue : 'auto'}
        innerPadding={selection === ANALYTICS_TAB_NAMES.averageOrderValue.snakeCaseName ? 2 : 0}
        keys={keys}
        colors={data?.colors}
        margin={{ top: 10, right: 30, bottom: 80, left: 80 }}
        padding={dataResult.length > 2 ? 0.3 : 0.7}
        enableLabel={false}
        indexBy="date"
        data={dataResult}
        theme={{
          textColor: '#92929d',
          fontSize: 14,
          grid: {
            line: {
              stroke: '#F1F1F1',
            },
          },
          axis: {
            ticks: {
              line: {
                stroke: '#f1f1f1',
              },
              text: {
                fontSize: 12,
                fontWeight: 600,
              },
            },
          },
        }}
        axisLeft={{
          orient: 'left',
          tickSize: 12,
          tickPadding: 10,
        }}
        axisBottom={{
          orient: 'bottom',
          tickSize: 12,
          tickPadding: 10,
          format: values => moment(values).format(format),
          tickValues: getTickValues(dataResult, type, isMobile, isLaptop),
        }}
        layers={['grid', 'axes', 'bars', 'markers', BarLegend]}
        legends={[
          {
            dataFrom: 'keys',
            data: keys.map((id, index) => ({
              color: data?.colors[index],
              id,
              label: labels[id],
            })),
            anchor: 'bottom',
            direction: 'row',
            justify: false,
            translateX: 0,
            translateY: 68,
            itemDirection: direction === 'ltr' ? 'left-to-right' : 'right-to-left',
            itemWidth: 130,
            itemHeight: 20,
            itemsSpacing: isMobile ? 0 : 10,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolSpacing: 5,
          },
        ]}
        tooltip={({ id, indexValue, value }) => (
          <TooltipProp
            id={id}
            indexValue={indexValue}
            value={value}
            display={display}
            type={type}
            labels={labels}
            lang={lang}
            direction={direction}
            selectedDataType={selectedDataType}
          />
        )}
      />
    </div>
  );
};

BehaviourBreakdownBarChart.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.bool, PropTypes.object, PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    ),
  ]).isRequired,
  type: PropTypes.oneOf(['day', 'month']).isRequired,
  selectedDataType: PropTypes.element.isRequired,
  display: PropTypes.func.isRequired,
  selection: PropTypes.oneOf([
    ANALYTICS_TAB_NAMES.totalSales.snakeCaseName,
    ANALYTICS_TAB_NAMES.averageOrderValue.snakeCaseName,
    ANALYTICS_TAB_NAMES.totalOrders.snakeCaseName,
    ANALYTICS_TAB_NAMES.totalCustomers.snakeCaseName,
  ]).isRequired,
};

export default BehaviourBreakdownBarChart;
