import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import handleCloseStorefrontIFrame from './utils';

const Slideover = ({ isOpen, setSlideOver, body, subdomain }) => (
  <Transition.Root show={isOpen} as={Fragment} static>
    <Dialog
      as="div"
      className="z-20 fixed inset-0 overflow-hidden"
      static
      onClose={() => {
        // Do Nothing
      }}
    >
      <div className="absolute inset-0 overflow-hidden">
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay
            static
            className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity pointer-events-none"
          />
        </Transition.Child>
        <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
          <Transition.Child
            as={Fragment}
            enter="transform transition ease-in-out duration-300 sm:duration-300"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transform transition ease-in-out duration-300 sm:duration-300"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div className="relative w-screen max-w-lg">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4">
                  <button
                    type="button"
                    className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                    onClick={() => {
                      setSlideOver(false);
                      subdomain && handleCloseStorefrontIFrame(subdomain);
                    }}
                  >
                    <span className="sr-only">Close panel</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div id="scroll-slide-over" className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                {body}
              </div>
            </div>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition.Root>
);

Slideover.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setSlideOver: PropTypes.func.isRequired,
  body: PropTypes.element.isRequired,
  subdomain: PropTypes.string,
};

export default Slideover;
