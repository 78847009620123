import React from 'react';
import arrayMove from 'array-move';
import PropTypes from 'prop-types';

import { LoadingContainer } from 'components/kit';
import { linkShape } from '../SortableItem';
import SortableItems from '../SortableItems';

const LinksTable = ({
  storeId,
  items,
  isSavingSorting,
  setSortedItems,
  onEditClick,
  onDeleteClick,
  isDraggable = true,
}) => (
  <LoadingContainer isLoading={isSavingSorting}>
    <SortableItems
      items={items}
      storeId={storeId}
      useDragHandle
      onSortEnd={async ({ oldIndex, newIndex }) => {
        const sorted = arrayMove(items, oldIndex, newIndex);
        setSortedItems(sorted);
      }}
      isDraggable={isDraggable}
      onEditClick={onEditClick}
      onDeleteClick={onDeleteClick}
    />
  </LoadingContainer>
);

LinksTable.propTypes = {
  storeId: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(linkShape).isRequired,
  isSavingSorting: PropTypes.bool,
  setSortedItems: PropTypes.func,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  isDraggable: PropTypes.bool,
};

export default LinksTable;
