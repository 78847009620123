import React from "react"
import { Link } from "components/service"
import { Text } from "components/service"
import { More } from "components/kit"
import { DeleteUser } from "components/common/team"
import { Modal } from "components/kit"
import { useReplaceParams } from "hooks/index"
import * as paths from "paths.js"
import * as translations from "constants/translations"
import { ROLES_TYPES } from "../utils"

export default ({ items, usersVariables }) => {
  const replace = useReplaceParams()
  return (
    <div>
      {items.map((item, i) => (
        <div className="flex justify-between mb-3 pb-3 border-b border-gray-300">
          <Link
            className="flex"
            to={replace(paths.editUser, {
              params: { userId: item.id },
            })}
            key={i}
          >
            <div className="w-12 h-12 rounded-full bg-primary-600 flex items-center justify-center flex-shrink-0 text-white text-lg font-semibold mr-3">
              {item.initials}
            </div>
            <div>
              <div className="font-semibold">{item.name}</div>
              <div className="text-gray-500 mb-1">
                {item.role === ROLES_TYPES.OPS_MANAGER ? ROLES_TYPES.MANAGER : item.role}
              </div>
              <div className="text-xs">
                {item.email} • {item.phoneNumber}
              </div>
            </div>
          </Link>
          <Modal>
            {({ open, close }) => (
              <More
                kind="action"
                size="smIcon"
                iconSize="md"
                items={[
                  {
                    title: <Text value={translations.DELETE} />,
                    onClick: () => {
                      open({
                        title: <Text value={translations.DELETE_USER} />,
                        body: (
                          <DeleteUser
                            userId={item.id}
                            usersVariables={usersVariables}
                            onClose={close}
                          />
                        ),
                        size: "max-w-md",
                      })
                    },
                  },
                ]}
              />
            )}
          </Modal>
        </div>
      ))}
    </div>
  )
}
