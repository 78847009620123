import { useContext } from "react"
import numeral from "numeral"
import { context as localeContext } from "context/locale"
import { context as userContext } from "context/user"

export default () => {
  const {
    selectedStore: { currency },
  } = useContext(userContext)
  const { translate } = useContext(localeContext)

  return value => {
    return `${translate(currency)} ${numeral(value).format(
      "0,0[.]" + "0".repeat(currency.decimals)
    )}`
  }
}
