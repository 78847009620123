import { useContext } from "react"
import { context as userContext } from "context/user"
import moment from "moment"
import momentTz from "moment-timezone"

export default ({ time, timeFormat = "HH:mm" }, resultFormat = "h:mm A") => {
  const {
    selectedStore: { timeZone },
  } = useContext(userContext)
  const convertTime = moment(time, timeFormat)
  const utcDate = momentTz.tz(convertTime, timeZone)
  const offsetInMinutes = convertTime.utcOffset()

  return moment(utcDate)
    .add(offsetInMinutes, "m")
    .format(resultFormat)
}
