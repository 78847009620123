import { gql } from "@apollo/client"

export const EDIT_VOUCHER = gql`
  mutation(
    $restaurantId: String!
    $id: String!
    $description: String
    $descriptionAr: String
    $redemptionLimit: Int
    $redemptionLimitPerUser: Int
    $validFrom: String
    $validUntil: String
    $validTimeFrom: String
    $validTimeTo: String
    $minSubtotalAmount: Float
  ) {
    updateVoucher(
      restaurantId: $restaurantId
      id: $id
      description: $description
      descriptionAr: $descriptionAr
      redemptionLimit: $redemptionLimit
      redemptionLimitPerUser: $redemptionLimitPerUser
      validFrom: $validFrom
      validUntil: $validUntil
      validTimeFrom: $validTimeFrom
      validTimeTo: $validTimeTo
      minSubtotalAmount: $minSubtotalAmount
    ) {
      id
      code
      validFrom
      validUntil
      description
      descriptionAr
      redemptionLimit
      redemptionLimitPerUser
      validTimeFrom
      validTimeTo
      minSubtotalAmount
      amount
      type
      voucherRedemptionsCount
    }
  }
`

export const GET_VOUCHER = gql`
  query getVoucher($restaurantId: String!, $id: String!) {
    voucher(restaurantId: $restaurantId, id: $id) {
      code
      validFrom
      validUntil
      description
      descriptionAr
      redemptionLimit
      redemptionLimitPerUser
      validTimeFrom
      validTimeTo
      minSubtotalAmount
      amount
      type
      voucherRedemptionsCount
    }
  }
`
