import { gql } from "@apollo/client"

export const INITIAL = gql`
  query($restaurantId: String!, $id: String!) {
    menuItem(restaurantId: $restaurantId, id: $id) {
      id
      titleAr
      titleEn
      descriptionAr
      descriptionEn
      externalId
      variantsTitleAr
      variantsTitleEn
      menuSectionIds
      publishedBranchIds
      menuItemNotesLabelOverrideAr
      menuItemNotesLabelOverrideEn
    }
    variants(restaurantId: $restaurantId, menuItemId: $id) {
      id
      titleEn
      titleAr
      price
      prepTime
      properties {
        uuid
        titleAr
        titleEn
        minQuantity
        maxQuantity
        multipleAddOnsEnabled
      }
      images {
        id
        imageUrl
      }
    }
  }
`

export const UPDATE_PRODUCT = gql`
  mutation updateMenuItem(
    $restaurantId: String!
    $menuItemId: String!
    $menuSectionIds: [String]
    $publishedBranchIds: [String]!
    $titleEn: String!
    $titleAr: String!
    $descriptionEn: String
    $descriptionAr: String
    $externalId: String
    $variantsTitleAr: String
    $variantsTitleEn: String
    $noteLabelAr: String
    $noteLabelEn: String
  ) {
    updateMenuItem(
      restaurantId: $restaurantId
      menuItemId: $menuItemId
      menuSectionIds: $menuSectionIds
      publishedBranchIds: $publishedBranchIds
      titleAr: $titleAr
      titleEn: $titleEn
      externalId: $externalId
      descriptionAr: $descriptionEn
      descriptionEn: $descriptionAr
      variantsTitleAr: $variantsTitleAr
      variantsTitleEn: $variantsTitleEn
      noteLabelAr: $noteLabelAr
      noteLabelEn: $noteLabelEn
    ) {
      id
    }
  }
`
