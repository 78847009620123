import React, { useState } from "react"
import * as cx from "classnames"
import { context as localeContext } from "context/locale"
import {
  InfoCheckbox,
  Input,
  RadioList,
  CheckboxAlt,
} from "components/form/elements"
import { Label } from "components/form/generic"
import { Field, Text } from "components/service"
import * as translations from "constants/translations"

export default ({
  isAllowedToAttach,
  isError,
  pageType,
  setValueField,
  isRequired,
  lang,
}) => {
  return (
    <Field
      title={<Text value={translations.SPECIAL_NOTES} />}
      description={
        <Text
          className="text-gray-600 text-xs"
          value={translations.GET_ADDITIONAL_NOTE}
        />
      }
      name="allowAttachNotes"
      toggle
      buttonSize="base"
      btnTxtColor="text-primary-base"
      testId={`${pageType}-enable-disable-product-note`}
      body={
        isAllowedToAttach && (
          <>
            <div style={{ width: "fit-content" }} className="pb-4">
              <Field
                onChange={e => {
                  setValueField("specialNotesRequired", e.target.value)
                }}
                name="specialNotesRequired"
                title={<Text value={translations.NOTE_REQ} />}
                value={isRequired}
                component={CheckboxAlt}
              />
            </div>

            <Text
              value={translations.NOTE_LABEL}
              className="text-base mb-2 font-semibold"
            />
            <div className="flex flex-col md:flex-row justify-between items-center">
              <div
                className={cx(
                  "w-full mb-4 md:mb-0",
                  lang === "en" ? "md:mr-4" : "md:ml-4"
                )}
              >
                <Label title={<Text value={translations.ENGLISH} />}>
                  <Field
                    data-testid={`${pageType}-product-note-en`}
                    direction="ltr"
                    type="text"
                    name="specialNotesPlaceholderEn"
                    placeholder="Ex. Add special notes here"
                    component={Input}
                  />
                </Label>
              </div>
              <div className="w-full">
                <Label title={<Text value={translations.ARABIC} />}>
                  <Field
                    data-testid={`${pageType}-product-note-ar`}
                    direction="rtl"
                    type="text"
                    name="specialNotesPlaceholderAr"
                    placeholder="مثال: اضف ملاحظات خاصة هنا"
                    component={Input}
                  />
                </Label>
              </div>
            </div>
          </>
        )
      }
      component={InfoCheckbox}
    />
  )
}
