import React, { useContext, useState, useEffect, useRef } from "react"
import { useQuery, useLazyQuery } from "@apollo/client"
import * as R from "ramda"
import { Field, Text } from "components/service"
import { Formik, Form } from "formik"
import { Spinner, Stack } from "components/kit"
import { Section, Row } from "components/form/generic"
import {
  Input,
  AccordionList,
  Hours,
  CheckboxAlt,
} from "components/form/elements"
import { Range } from "rc-slider"
import "rc-slider/assets/index.css"
import * as data from "./data"
import * as schemas from "./schemas"
import * as utils from "./utils"
import * as translations from "constants/translations"
import cx from "classnames"
import BasicDetails from "./BasicDetails"
import Map from "./Map"
import { context as userContext } from "context/user"
import { context as localeContext } from "context/locale"
import { ReactComponent as SearchIcon } from "assets/search.svg"

export default ({
  initialValues,
  onSubmit,
  isSubmitting,
  renderBottomPane,
  renderTopPane,
  addDelivery,
  }) => {
  const { branches, selectedStore } = useContext(userContext)
  const currency = selectedStore.currency
  const { lang } = useContext(localeContext)
  const [
    searchAreas,
    { loading: loadingSearch, data: searchData },
  ] = useLazyQuery(schemas.ALL_COUNTRY_AREAS)

  const searchTimeout = useRef()

  const [assignedAreas, setDataAssignedAreas] = useState(null)
  const [distance, setDistance] = useState([10, 30])
  const [areas, setAreas] = useState([])
  const [onChangeSearch, setOnChangeSearch] = useState(false)
  const [isMultiCountry, setIsMultiCountry] = useState(
    initialValues
      ? initialValues.countryId !== selectedStore.countryId
        ? true
        : false
      : false
  )
  const [selectedCountry, setSelectedCountry] = useState(
    initialValues ? initialValues.countryId : selectedStore.countryId
  )
  const [selectedBranch, setSelectedBranch] = useState(
    initialValues ? initialValues.branchId : branches[0].id
  )

  const variables = isMultiCountry
    ? {
        restaurantId: selectedStore.id,
        countryId: parseInt(selectedCountry),
      }
    : {
        restaurantId: selectedStore.id,
        countryId: parseInt(selectedCountry),
        from: 0,
        to: 100,
        center:
          "[" +
          branches.find(item => item.id === selectedBranch).lat +
          "," +
          branches.find(item => item.id === selectedBranch).lng +
          "]",
      }

  const { loading, data: dataAreas } = useQuery(schemas.REMAINING_AREAS, {
    variables: variables,
    notifyOnNetworkStatusChange: true,

  })

  const { loading: loadingAssignedAreas } = useQuery(schemas.ASSIGNED_AREAS, {
    variables: {
      restaurantId: selectedStore.id,
      countryId: parseInt(selectedCountry),
    },
    onCompleted: data => {
      setDataAssignedAreas(data.assignedAreas)
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  })

  const { loading: loadingAllAreas, data: dataAllAreas } = useQuery(
    schemas.ALL_COUNTRY_AREAS,
    {
      variables: {
        restaurantId: selectedStore.id,
        countryId: parseInt(selectedCountry),
      },
    }
  )

  useEffect(() => {
    setAreas([])
    !loading &&
      dataAreas &&
      dataAreas.remainingAreas &&
      setAreas(arr.filter(e => e.length))
  }, [dataAreas])

  const checkDistance = element => {
    return (
      utils.calcDistance(
        branches.find(item => item.id === selectedBranch).lat,
        branches.find(item => item.id === selectedBranch).lng,
        element.lat,
        element.lng,
        "K"
      ) >= distance[0] &&
      utils.calcDistance(
        branches.find(item => item.id === selectedBranch).lat,
        branches.find(item => item.id === selectedBranch).lng,
        element.lat,
        element.lng,
        "K"
      ) <= distance[1]
    )
  }

  const arr =
    !loading &&
    dataAreas &&
    dataAreas.remainingAreas &&
    utils.groupByCity(dataAreas.remainingAreas).map((item, i) => {
      return item.filter(element => {
        if (isMultiCountry) {
          return element
        }
        if (checkDistance(element)) return element
      })
    })

  return (
    <Formik
      initialValues={R.mergeDeepRight(
        {
          ...data.initialValues,
        },
        initialValues || {}
      )}
      validationSchema={data.validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, handleChange }) => (
        <Form className="pb-32">
          {renderTopPane && renderTopPane({ isSubmitting })}
          <BasicDetails
            values={values}
            currency={currency}
            branches={branches}
            countryId={selectedStore.countryId}
            handleChange={handleChange}
            isMultiCountry={isMultiCountry}
            setSelectedCountry={v => {
              setSelectedCountry(v)
              setIsMultiCountry(v !== selectedStore.countryId ? true : false)
            }}
            setSelectedBranch={v => setSelectedBranch(v)}
            openingFrom = {addDelivery && 'add-delivery' }
          />

          <Section
            title={<Text value={translations.DELIVERY_HOURS} />}
            description={<Text value={translations.CUSTOMIZE_DELIVERY_HOUR} />}
          >
            <Field name="openingHours" component={Hours} />
          </Section>
          <Section
            title={<Text value={translations.AREAS} />}
            description={<Text value={translations.ADD_AREAS_ZONE_COVERS} />}
          >
            {!isMultiCountry && (
              <>
                <Row>
                  <Map
                    marker={{
                      lat: branches.find(item => item.id === selectedBranch)
                        .lat,
                      lng: branches.find(item => item.id === selectedBranch)
                        .lng,
                    }}
                    options={{
                      streetViewControl: false,
                      zoomControl: false,
                      mapTypeControl: false,
                      fullscreenControl: false,
                      scaleControl: false,
                      gestureHandling: "none",
                    }}
                    mapContainerClassName="h-40 w-full rounded pointer-events-none"
                    zoom={15}
                    center={{
                      lat: branches.find(item => item.id === selectedBranch)
                        .lat,
                      lng: branches.find(item => item.id === selectedBranch)
                        .lng,
                    }}
                    distance={distance}
                  />
                </Row>
                <div
                  style={{ direction: "ltr" }}
                  className="my-4 px-2 rounded border border-gray-300 flex flex-col justify-between"
                >
                  <div className="flex-1 flex flex-row justify-between items-center py-2">
                    <Text
                      prefix={distance[0]}
                      value={translations.KM}
                      className="text-left"
                    />

                    <Text
                      prefix={distance[1]}
                      value={translations.KM}
                      className="text-right"
                    />
                  </div>
                  <Range
                    allowCross={false}
                    min={0}
                    max={100}
                    onChange={value => {
                      setDistance(value)
                      setAreas([])
                      !loading &&
                        dataAreas &&
                        dataAreas.remainingAreas &&
                        setAreas(arr.filter(e => e.length))
                    }}
                    defaultValue={distance}
                    railStyle={{
                      backgroundColor: "#e0e0e0",
                    }}
                    trackStyle={[{ backgroundColor: "#2c419e" }]}
                    handleStyle={[
                      {
                        backgroundColor: "#ffffff",
                        border: "solid 1px #c6d0fc",
                      },
                      {
                        backgroundColor: "#ffffff",
                        border: "solid 1px #c6d0fc",
                      },
                    ]}
                  />
                  <div className="flex-1 py-2">
                    <span className="text-gray-700">
                      <Text value={translations.YOU_CAN_FILTER_AREAS} />
                    </span>
                  </div>
                </div>
              </>
            )}
            <Row>
              <Field
                name="searchAreas"
                component={Input}
                type="text"
                icon={<SearchIcon />}
                height="h-8"
                onChange={e => {
                  clearTimeout(searchTimeout.current)

                  const { value } = e.target

                  if (!value) {
                    return setOnChangeSearch(false)
                  }

                  searchTimeout.current = setTimeout(() => {
                    searchAreas({
                      variables: {
                        restaurantId: selectedStore.id,
                        countryId: parseInt(selectedCountry),
                        filter: value,
                      },
                    })
                    setOnChangeSearch(true)
                  }, 300)
                }}
                placeholder={
                  lang === "en"
                    ? translations.FILTER_AREAS[0]
                    : translations.FILTER_AREAS[1]
                }
              />
            </Row>

            {loadingSearch && loadingAssignedAreas ? (
              <Spinner />
            ) : (
              onChangeSearch && (
                <Row>
                  <Field
                    name="searchedAreas"
                    component={SearchCheckBox}
                    assignedAreas={assignedAreas || []}
                    items={
                      searchData && searchData.allCountryAreas !== null
                        ? searchData.allCountryAreas
                        : []
                    }
                    branches={branches}
                  />
                </Row>
              )
            )}

            {!loadingSearch && !onChangeSearch && (
              <Row>
                {loading ? (
                  <Spinner />
                ) : (
                  !loadingAllAreas &&
                  (initialValues && initialValues.areas ? (
                    <Field
                      areas={dataAllAreas?.allCountryAreas}
                      name="areas"
                      component={AccordionList}
                      items={areas}
                    />
                  ) : (
                    <Field
                      name="areas"
                      component={AccordionList}
                      items={areas}
                    />
                  ))
                )}
              </Row>
            )}
          </Section>
          {renderBottomPane && (
            <Stack
              direction={lang === "ar" ? "row" : "row-reverse"}
              expandItem={false}
            >
              {renderBottomPane({ isSubmitting })}
            </Stack>
          )}
        </Form>
      )}
    </Formik>
  )
}

const SearchCheckBox = ({
  name,
  value = [],
  onChange,
  items,
  assignedAreas,
  branches,
}) => {
  return items.map((item, index) => {
    const isActive = value.includes(item.id)
    let foundedArea
    const found = assignedAreas.some(areas => {
      if (areas.id === item.id) foundedArea = areas
      return areas.id === item.id
    })

    const SubTitle = () => {
      return (
        found && (
          <span className="text-gray-700">
            <Text value={translations.SELECTED_IN} className="inline value" />
            {branches.find(
              branch => branch.id === foundedArea.branchId.toString()
            ) ? (
              <Text
                value={branches.find(
                  branch => branch.id === foundedArea.branchId.toString()
                )}
                postfix={` - #${foundedArea.deliveryZoneId}. `}
                className="mx-1 inline"
              />
            ) : (
              " #" + foundedArea.deliveryZoneId
            )}
          </span>
        )
      )
    }

    return (
      <div
        key={index}
        className={cx(
          "flex flex-col",
          item.deliveryZoneId ? "my-2" : "mt-2 mb-4"
        )}
      >
        <CheckboxAlt
          onChange={() =>
            onChange({
              target: {
                name,
                value: !isActive
                  ? [...value, item.id]
                  : value.filter(id => id !== item.id),
              },
            })
          }
          disabled={found}
          value={found ? true : isActive}
          title={<Text value={item} />}
          subTitle={<SubTitle />}
        />
      </div>
    )
  })
}
