import React, { useContext, useState } from 'react';
import moment from 'moment';
import { useSelectedStore, useMobile } from 'hooks';
import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { Spinner, LoadingContainer, Tabs, Tooltip } from 'components/kit';
import { ReactComponent as Alert } from 'assets/icons-alert.svg';
import { Table, TableFooter } from 'components/form/elements';
import cx from 'classnames';
import CampaignsTableRow from './CampaignsTableRow';
import Slideover from 'components/common/dashboard/Layout/Slideover/Slideover';
import DetailsCampaign from './DetailsCampaign';

const CampaignsTable = ({ data, loading, campaignsVariables, query, setQuery, LIMIT }) => {
  const selectedStoreId = useSelectedStore();
  const { lang } = useContext(localeContext);

  const [isSlideOverOpen, setIsSlideOverOpen] = useState(false);
  const [slideOverComponent, setSlideOverComponent] = useState(<></>);

  moment.locale(lang === 'ar' ? 'ar-kw' : 'en-gb');

  const listData = !data?.campaigns?.campaigns
    ? []
    : data?.campaigns?.campaigns.map(item => {
        return {
          id: item.id,
          title: item.title,
          type: item.type,
          startsAt: moment(item.startsAt).format('DD MMMM YYYY'),
          expiresAt: item.expiresAt ? (
            moment(item.expiresAt).format('DD MMMM YYYY')
          ) : (
            <Text value={translations.UNLIMITED} />
          ),
          smsSendingLimit: item.smsSendingLimit,
          sentSmsCount: item.sentSmsCount,
          stats: item.stats,
          state: item.state,
          smsBody: item.smsBody,
          voucherType: item.voucherType,
          voucherAmount: item.voucherAmount,
          voucherRedemptionLimitPerUser: item.voucherRedemptionLimitPerUser,
          voucherRedemptionsCount: item.voucherRedemptionsCount,
          lookbackWindow: item.lookbackWindow,
          lookbackWindowStartDate: item.lookbackWindowStartDate,
          inactivityPeriod: item.inactivityPeriod,
          generateSmsBody: item.generateSmsBody,
          crmSegmentId: item.crmSegmentId,
          estimatedCreditPerSms: item.estimatedCreditPerSms,
        };
      });

  return (
    <>
      <div>
        <div className="mb-4">
          <Tabs
            stateless
            scrollable
            items={[
              {
                title: <Text value={translations.ALL} className="mx-3" />,
                selected: query.status === 'all',
                onClick: () => setQuery({ status: 'all' }),
              },
              {
                title: <Text value={translations.ACTIVE} className="mx-3" />,
                selected: query.status === 'activated',
                onClick: () => setQuery({ status: 'activated' }),
              },
              {
                title: <Text value={translations.SCHEDULED} className="mx-3" />,
                selected: query.status === 'scheduled',
                onClick: () => setQuery({ status: 'scheduled' }),
              },
              {
                title: <Text value={translations.DEACTIVATED} className="mx-3" />,
                selected: query.status === 'deactivated',
                onClick: () => setQuery({ status: 'deactivated' }),
              },
              {
                title: <Text value={translations.COMPLETED_CAMPAIGN} className="mx-3" />,
                selected: query.status === 'completed',
                onClick: () => setQuery({ status: 'completed' }),
              },
              {
                title: <Text value={translations.DRAFT_CAMPAIGN} className="mx-3" />,
                selected: query.status === 'draft',
                onClick: () => setQuery({ status: 'draft' }),
              },
            ]}
          />
        </div>
        {!data?.campaigns ? (
          <Spinner />
        ) : (
          <>
            <LoadingContainer isLoading={loading}>
              <div className="w-full">
                <Table
                  dataHeader={[
                    {
                      key: 'title',
                      name: <Text value={translations.TITLE} />,
                      inView: false,
                    },
                    {
                      key: 'type',
                      name: <Text value={translations.TYPE} />,
                      inView: false,
                    },
                    {
                      key: 'startedAt',
                      name: <Text value={translations.START_ON} />,
                      inView: false,
                    },
                    {
                      key: 'expiredAt',
                      name: <Text value={translations.END_ON} />,
                      inView: false,
                    },
                    {
                      key: 'smsSent',
                      name: <Text value={translations.MESSAGES_SENT} />,
                      inView: false,
                    },
                    {
                      key: 'state',
                      name: <Text value={translations.STATUS_VOUCHER} />,
                      inView: false,
                    },
                  ]}
                  dataBody={listData}
                  customCol={(item, index) => (
                    <FirstCol
                      key={index}
                      index={index}
                      totalCount={listData.length}
                      lang={lang}
                      item={item}
                      setIsSlideOverOpen={setIsSlideOverOpen}
                      setSlideOverComponent={setSlideOverComponent}
                      storeId={selectedStoreId}
                    />
                  )}
                  customRow={(item, index) => (
                    <CampaignsTableRow
                      key={index}
                      item={item}
                      index={index}
                      totalCount={listData.length}
                      campaignsVariables={campaignsVariables}
                      storeId={selectedStoreId}
                      isSlideOverOpen={isSlideOverOpen}
                      setIsSlideOverOpen={setIsSlideOverOpen}
                      setSlideOverComponent={setSlideOverComponent}
                    />
                  )}
                  footer={
                    <TableFooter
                      totalRecords={data?.campaigns?.totalCount}
                      pageLimit={LIMIT}
                      currentPage={query.page}
                      onPageChanged={({ currentPage }) => setQuery({ page: currentPage })}
                      totalCount={listData.length}
                    />
                  }
                />
              </div>
            </LoadingContainer>
          </>
        )}
      </div>
      <Slideover
        isOpen={isSlideOverOpen}
        setSlideOver={setIsSlideOverOpen}
        body={slideOverComponent}
        setSlideOverComponent={setSlideOverComponent}
        title={<Text value={['Campaign details', 'تفاصيل الحملة']} />}
      />
    </>
  );
};

export default CampaignsTable;

const FirstCol = ({ index, totalCount, lang, item, setIsSlideOverOpen, setSlideOverComponent, storeId }) => {
  const { selectedStore } = useContext(userContext);
  const isMobile = useMobile();
  const cellClassName = cx(
    'px-4 border-b border-gray-300 truncate cursor-pointer',
    totalCount <= 2 ? (totalCount - 1 > index ? 'py-5' : 'pt-5 pb-40') : 'py-5',
  );
  let alertMessage = '';

  const maxWidth = isMobile ? '10rem' : '20rem';
  const smsCredit = selectedStore.campaignsSmsCredit < 100;
  const percentageSent = item.smsSendingLimit && item.smsSendingLimit - item.sentSmsCount < 50;
  const daysToExpires =
    typeof item.expiresAt === 'string' && moment(item.expiresAt).diff(moment(new Date()), 'days') < 15;
  const alert = item.state === 'activated' && (smsCredit || percentageSent || daysToExpires);

  if (smsCredit) {
    alertMessage = <Text value={translations.SMS_ALMOST_FINISHED} />;
  } else if (percentageSent) {
    alertMessage = <Text value={translations.CAMPAIGN_ALMOST_FINISHED} />;
  } else if (daysToExpires) {
    alertMessage = <Text value={translations.CAMPAIGN_ALMOST_FINISHED} />;
  }
  return (
    <tr>
      <td
        className={cellClassName}
        style={{ maxWidth }}
        onClick={() => {
          setSlideOverComponent(
            <DetailsCampaign item={item} storeId={storeId} setIsSlideOverOpen={setIsSlideOverOpen} />,
          );
          setIsSlideOverOpen(true);
        }}
      >
        <div className="flex items-center">
          {alert && (
            <Tooltip direction={lang === 'en' ? 'right' : 'left'} content={alertMessage} width="w-24">
              <Alert className="mx-1 inline fill-current text-zyda-yellow-400" />
            </Tooltip>
          )}
          <div className="fill-current text-blue-600">{item.title}</div>
        </div>
      </td>
    </tr>
  );
};
