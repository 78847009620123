import React, { createContext, useState } from 'react';

import { initialSegments } from 'constants/customers';
import { TAB_NAMES } from 'pages/marketing/campaigns/Create/constants';
import { fileControllerUtils } from 'pages/marketing/campaigns/Create/CampaignContacts/utils';

export const context = createContext();

export const Provider = ({ children }) => {
  const [segments, setSegments] = useState(initialSegments);
  const [selectedSegment, setSelectedSegment] = useState(undefined);
  const [campaignGoals, setCampaignGoals] = useState([]);
  const [storefrontDefaultLanguage, setStorefrontDefaultLanguage] = useState();
  const [segmentRetrievalDate, setSegmentRetrievalDate] = useState(new Date('1996'));
  const [audienceType, setAudienceType] = useState(TAB_NAMES.SEGMENTS);
  const [fileController, setFileController] = useState(fileControllerUtils);

  return (
    <context.Provider
      value={{
        segments,
        setSegments,
        selectedSegment,
        setSelectedSegment,
        campaignGoals,
        setCampaignGoals,
        segmentRetrievalDate,
        setSegmentRetrievalDate,
        storefrontDefaultLanguage,
        setStorefrontDefaultLanguage,
        isWithinSegmentJobIntervals: () => {
          const currentDate = new Date();
          if (segmentRetrievalDate && Object.prototype.toString.call(segmentRetrievalDate) === '[object Date]') {
            if (
              segmentRetrievalDate.getMonth() === currentDate.getMonth() &&
              segmentRetrievalDate.getDay() === currentDate.getDay() &&
              segmentRetrievalDate.getHours() === currentDate.getHours()
            )
              return true;
          }
          return false;
        },
        audienceType,
        setAudienceType,
        fileController,
        setFileController,
      }}
    >
      {children}
    </context.Provider>
  );
};
