import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { Box } from 'components/kit';
import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { context as localeContext } from 'context/locale';
import PaymentStatus from '../PaymentStatus';
import renderPaymentMethods from './renderPaymentMethods';

const OnlinePaymentSection = ({
  selectedStore,
  paymentCredentials,
  paymentGateway,
  refetch,
  storeId,
  hasManualPaymentMethod,
}) => {
  const { lang } = useContext(localeContext);
  const numOfEnabledOnlineMethods = paymentCredentials?.restaurantPaymentMethods.filter(method => method.enabled)
    .length;

  const renderObject = {
    restaurantId: selectedStore.id,
    paymentCredentials,
    paymentGatewayId: paymentGateway?.id,
    paymentMethods: paymentCredentials?.paymentMethods,
    refetch,
    lang,
    hasManualPaymentMethod,
    numOfEnabledOnlineMethods,
  };

  return (
    <div className="mb-6">
      <>
        {paymentCredentials?.paymentGateway?.id === paymentGateway?.id && (
          <PaymentStatus storeId={storeId} paymentCredentials={paymentCredentials} />
        )}
        <div className="mt-4">
          <Box
            displayBorderBottom={false}
            description={
              <Text
                value={translations.PAYMENT_METHODS_PROVIDED_BY({
                  ...paymentGateway?.extraData?.title,
                })}
                className="text-gray-700"
              />
            }
            body={
              <div className="px-4 pb-2 -mt-8">
                {paymentCredentials?.restaurantPaymentMethods &&
                  paymentCredentials?.restaurantPaymentMethods.map(method =>
                    renderPaymentMethods({
                      method,
                      ...renderObject,
                    }),
                  )}
                {!paymentCredentials &&
                  paymentGateway?.paymentMethods &&
                  paymentGateway?.paymentMethods.map(method =>
                    renderPaymentMethods({
                      method,
                      ...renderObject,
                    }),
                  )}
              </div>
            }
          />
        </div>
        <div className="mt-4">
          {paymentGateway?.extraData?.pricingUrl && (
            <div className="mt-6">
              <a href={paymentGateway?.extraData?.pricingUrl} target="_blank" rel="noreferrer">
                <ul className="px-4 py-2 list-disc">
                  <li>
                    <Text className="underline text-primary-base" value={translations.PRICING_URL} />
                  </li>
                </ul>
              </a>
            </div>
          )}
        </div>
      </>
    </div>
  );
};

OnlinePaymentSection.propTypes = {
  selectedStore: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  storeId: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
  hasManualPaymentMethod: PropTypes.bool.isRequired,
  paymentCredentials: PropTypes.shape({
    restaurantPaymentMethods: PropTypes.arrayOf(
      PropTypes.shape({
        enabled: PropTypes.bool,
      }),
    ),
    paymentGateway: PropTypes.shape({
      id: PropTypes.string,
    }),
    paymentMethods: PropTypes.arrayOf(),
  }),
  paymentGateway: PropTypes.shape({
    id: PropTypes.string,
    extraData: PropTypes.shape({
      title: PropTypes.string,
      pricingUrl: PropTypes.string,
    }),
    paymentMethods: PropTypes.arrayOf(),
  }).isRequired,
};

export default OnlinePaymentSection;
