import React from 'react';
import { Marker } from '@react-google-maps/api';
import { GoogleMapContainer } from 'components/kit';

export default ({ lat, lng }) => {
  return (
    <GoogleMapContainer
      center={{ lat, lng }}
      zoom={14}
      mapContainerClassName="h-full pointer-events-none"
      options={{
        disableDefaultUI: true,
        zoomControl: false,
        scaleControl: false,
        gestureHandling: 'none',
      }}
    >
      <Marker position={{ lat, lng }} />
    </GoogleMapContainer>
  );
};
