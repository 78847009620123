import React, { useContext } from "react"
import cx from "classnames"
import { context as localeContext } from "context/locale"
import { Text } from "components/service"

const SelectionTag = ({ items, placeholder, isRemovable, onRemoveClick }) => {
  const { lang } = useContext(localeContext)

  const itemsLength = items.length
  return (
    !!itemsLength && (
      <div className={cx("mb-4", itemsLength > 10 && "h-40 overflow-y-auto")}>
        <span
          className={cx(
            "text-sm rounded py-1 pl-2 bg-gray-200 cursor-pointer inline-flex items-center",
            lang === "ar" ? "flex-row-reverse ml-2" : "mr-2"
          )}
        >
          <div
            className="truncate"
            style={{
              maxWidth: "15rem",
              direction: lang === "ar" ? "rtl" : "ltr",
            }}
          >
            {items.map((item, i) => {
              return typeof item.title === "string" ? (
                <p key={i} className="inline whitespace-nowrap">
                  {item.title}
                  {i + 1 < items.length && (lang === "ar" ? " ،" : ", ")}&nbsp;
                </p>
              ) : (
                <Text
                  key={i}
                  value={item.title.props.value}
                  postfix={
                    i + 1 < items.length ? (lang === "ar" ? "، " : ", ") : ""
                  }
                  className="inline whitespace-nowrap"
                />
              )
            })}
          </div>
          {isRemovable && (
            <i
              className="material-icons text-gray-500 text-sm mx-2"
              onClick={e => {
                e.stopPropagation()
                onRemoveClick && onRemoveClick()
              }}
            >
              close
            </i>
          )}
        </span>
      </div>
    )
  )
}

export default SelectionTag
