import { gql } from '@apollo/client';

export const CATEGORIES = gql`
  query($storeId: String!) {
    menuSections(restaurantId: $storeId) {
      menuSections {
        id
        titleAr
        titleEn
      }
    }
  }
`;
export const BRANCHES = gql`
  query Branches($storeId: String!) {
    branches(restaurantId: $storeId) {
      id
      titleEn
      titleAr
    }
  }
`;
