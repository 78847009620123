import React, { useState, useContext, useRef, useEffect } from "react"
import { useQuery } from "@apollo/client"
import { Layout, Breadcrumbs } from "components/common/dashboard"
import { Text } from "components/service"
import { Tabs, DropDown, DropDatePicker, Stack, Button } from "components/kit"
import * as translations from "constants/translations"
import * as paths from "paths.js"
import { TableFooter, Input } from "components/form/elements"
import { Spinner } from "components/kit"
import { breadcrumbs } from "constants/translations"
import { context as localeContext } from "context/locale"
import { ReactComponent as SearchIcon } from "assets/search.svg"
import { context as userContext } from "context/user"
import * as schemas from "./schemas"
import EmptyState from "../manageVariants/EmptyState"
import cx from "classnames"
import { useSelectedStore, useQueryState, useMobile } from "hooks"
import { parseRange, toRangeVariable } from "utils/date"
import { exportHistoryLog } from "rest.js"
import { ReactComponent as NoResults } from "assets/no-orders.svg"
import Table from "./Table"
import { DASHBOARD_LAYOUT } from "../../../services/user"

const StockHistory = () => {
  const { lang, direction } = useContext(localeContext)
  const { branches, selectedStore } = useContext(userContext)
  const isMobile = useMobile()
  const storeId = useSelectedStore()
  const [langSelected, setLangSelected] = useState("en")
  const [stockHistory, setStockHistory] = useState([])
  const [categories, setCategories] = useState([])
  const searchTimeout = useRef()
  const stockLang = [
    { id: "en", title: "English" },
    { id: "ar", title: "Arabic" },
  ]
  const LIMIT = 10
  const [query, setQuery] = useQueryState({
    page: 1,
    branchId: branches ? branches[0].id : null,
    categoryId: "all",
    date: "all",
  })
  const parsedRange = parseRange(query.range)
  const rangeVariable = toRangeVariable(parsedRange)
  const rangeDate = query.date === "custom" ? rangeVariable : null

  const stockVariables = {
    storeId,
    branchId: parseInt(query.branchId),
    page: parseInt(query.page),
    limit: LIMIT,
    title: query.title ? query.title : null,
    categoryId: query.categoryId === "all" ? null : query.categoryId,
    range: rangeDate,
  }

  const { data: dataStockHistory, loading: loadingStockHistory } = useQuery(
    schemas.STOCKS_HISTORY,
    {
      variables: stockVariables,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      skip: !branches,
    }
  )
  const { data: categoriesRes, loading: loadingCat } = useQuery(
    schemas.CATEGORIES,
    {
      variables: {
        storeId,
      },
    }
  )
  useEffect(() => {
    !loadingStockHistory &&
      dataStockHistory &&
      dataStockHistory.stockHistory &&
      setStockHistory(dataStockHistory.stockHistory.stockHistory)
  }, [
    stockHistory,
    dataStockHistory,
    query.branchId,
    query.categoryId,
    query.title,
    query.page,
    rangeVariable.start,
    rangeVariable.end,
  ])

  useEffect(() => {
    setQuery({ page: 1 })
  }, [query.categoryId, query.branchId, query.date])

  useEffect(() => {
    !loadingCat &&
      categoriesRes &&
      categoriesRes.menuSections &&
      setCategories(categoriesRes.menuSections.menuSections)
  }, [categoriesRes])

  const { loading: loadingSettings, data: settings } = useQuery(
    schemas.CHECK_STOCK_ENABLE,
    {
      variables: { storeId },
      fetchPolicy: "no-cache",
    }
  )

  const categoriesData = [
    {
      id: "all",
      titleEn: isMobile ? translations.ALL_CATEGORIES[0] : translations.ALL[0],
      titleAr: isMobile ? translations.ALL_CATEGORIES[1] : translations.ALL[1],
    },
    ...categories,
  ].map(category => ({
    id: category.id,
    title: <Text value={category} />,
  }))

  const branchesData =
    branches &&
    [...branches].map(branch => ({
      id: branch.id,
      title: <Text value={branch} />,
    }))

  const tableFooter = (
    <TableFooter
      totalRecords={dataStockHistory?.stockHistory?.totalCount}
      pageLimit={LIMIT}
      currentPage={parseInt(query.page)}
      onPageChanged={({ currentPage }) => setQuery({ page: currentPage })}
      totalCount={stockHistory.length}
    />
  )

  return loadingCat && loadingSettings && loadingStockHistory ? (
    <Layout
      top={
        <Breadcrumbs
          reverse
          isMenu
          isOverview
          isOptions
          customTitle={
            <Text
              value={translations.STOCK_HISTORY_LOG}
              className="text-2xl font-bold "
            />
          }
        />
      }
    >
      <Spinner />
    </Layout>
  ) : (
    <Layout
      top={
        <Breadcrumbs
          isMenu
          reverse
          isOptions
          isOverview
          customTitle={
            <Text
              value={translations.STOCK_HISTORY_LOG}
              className="text-2xl font-bold "
            />
          }
          right={
            settings &&
            settings.settings.isStockEnabled && (
              <Stack expandItem={false} direction="wrap">
                <Button
                  data-testid="historyLog-export-stock"
                  size="baseGroup"
                  kind="secondary"
                  className="W-40"
                  style={{
                    direction,
                  }}
                  onClick={() =>
                    (window.location.href = exportHistoryLog(
                      storeId,
                      query.categoryId,
                      query.title,
                      query.branchId,
                      rangeDate?.start,
                      rangeDate?.end
                    ))
                  }
                >
                  <Text value={translations.EXPORT} className="font-semibold" />
                </Button>
              </Stack>
            )
          }
        />
      }
    >
      {settings && settings.settings.isStockEnabled && (
        <div className="px-3 lg:px-8 py-1">
          {isMobile ? (
            <>
              <div className="w-full my-2">
                <Input
                  type="text"
                  icon={<SearchIcon />}
                  search
                  placeholder={
                    lang === "en"
                      ? translations.SEARCH_STOCK[0]
                      : translations.SEARCH_STOCK[1]
                  }
                  style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                  initialValue={query.title}
                  onChange={e => {
                    clearTimeout(searchTimeout.current)
                    const { value } = e.target
                    searchTimeout.current = setTimeout(() => {
                      setQuery({ page: 1, title: value })
                    }, 500)
                  }}
                />
              </div>
              <div className="flex items-center w-full md:w-auto">
                <div className="md:hidden w-1/2">
                  <DropDown
                    float={"left"}
                    label={<Text value={translations.BUSINESS_LOCATION} />}
                    optionSelected={query.branchId}
                    onSelect={option => setQuery({ branchId: option })}
                    data={branchesData}
                    icon="keyboard_arrow_down"
                    position={lang === "ar" ? "right" : "left"}
                    scrollable
                    wFull={true}
                    responsiveLabel
                  />
                </div>
                <div className={cx("my-2 w-1/2 md:w-auto ml-4")}>
                  <DropDown
                    label={<Text value={translations.CATEGORY} />}
                    data={categoriesData}
                    optionSelected={query.categoryId}
                    onSelect={categoryId => {
                      setQuery({ page: 1, categoryId })
                    }}
                    icon="keyboard_arrow_down"
                    position={"right"}
                    scrollable
                    wFull={true}
                    responsiveLabel
                  />
                </div>
              </div>
              <div className="flex items-center w-full md:w-auto">
                <div className="md:hidden w-2/3">
                  <DropDatePicker
                    query={query}
                    setQuery={setQuery}
                    wFull
                    responsiveLabel
                  />
                </div>
                <div className={cx("my-2 w-1/3 md:w-auto ml-4")}>
                  <DropDown
                    label={<Text value={translations.VIEW_IN} />}
                    float={lang === "ar" ? "left" : "right"}
                    position={direction === "rtl" ? "right" : "left"}
                    optionSelected={langSelected}
                    onSelect={stockLang => {
                      setLangSelected(stockLang)
                    }}
                    data={stockLang}
                    icon="keyboard_arrow_down"
                    responsiveLabel
                    wFull={true}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="flex-wrap flex justify-between items-center mb-4">
                <div className="flex md:flex-row flex-col justify-between items-center">
                  <div className="md:w-auto w-full">
                    <DropDown
                      float={"left"}
                      label={<Text value={translations.BUSINESS_LOCATION} />}
                      optionSelected={query.branchId}
                      onSelect={option => setQuery({ branchId: option })}
                      data={branchesData}
                      icon="keyboard_arrow_down"
                      position={lang === "ar" ? "right" : "left"}
                      scrollable
                    />
                  </div>
                  <div className="my-2 md:my-0 md:px-3 w-full md:w-auto">
                    <DropDown
                      label={<Text value={translations.CATEGORY} />}
                      data={categoriesData}
                      optionSelected={query.categoryId}
                      onSelect={categoryId => {
                        setQuery({ page: 1, categoryId })
                      }}
                      icon="keyboard_arrow_down"
                      position={direction === "rtl" ? "right" : "left"}
                      scrollable
                    />
                  </div>
                  <div className="my-2 md:my-0 w-full md:w-auto">
                    <DropDatePicker query={query} setQuery={setQuery} />
                  </div>
                </div>
                <div className="flex md:flex-row flex-col justify-between items-center">
                  <DropDown
                    label={<Text value={translations.VIEW_IN} />}
                    float={lang === "ar" ? "left" : "right"}
                    position={direction === "rtl" ? "right" : "left"}
                    optionSelected={langSelected}
                    onSelect={stockLang => {
                      setLangSelected(stockLang)
                    }}
                    data={stockLang}
                    icon="keyboard_arrow_down"
                  />

                  <div />
                </div>
              </div>
              <div className="md:w-auto w-full mb-4">
                <Input
                  testId="stockManagement-history-search"
                  type="text"
                  icon={<SearchIcon />}
                  search
                  placeholder={
                    lang === "en"
                      ? translations.SEARCH_STOCK[0]
                      : translations.SEARCH_STOCK[1]
                  }
                  initialValue={query.title}
                  onChange={e => {
                    clearTimeout(searchTimeout.current)
                    const { value } = e.target
                    searchTimeout.current = setTimeout(() => {
                      setQuery({ page: 1, title: value })
                    }, 500)
                  }}
                />
              </div>
            </>
          )}
        </div>
      )}
      {!branches || !branches.length ? (
        <Text
          value={translations.STORE_HAS_NO_BRANCHES}
          className="px-3 py-1"
        />
      ) : !settings || loadingSettings || loadingStockHistory ? (
        <Spinner />
      ) : !settings?.settings.isStockEnabled && settings?.dashboardLayout === DASHBOARD_LAYOUT.RETAIL ? (
        <EmptyState />
      ) : (
        <div className={cx("px-3 lg:px-8 pb-20 flex flex-col overflow-x-auto")}>
          <div className="w-full">
            {stockHistory.length ? (
              <Table
                stockHistory={stockHistory}
                langSelected={langSelected}
                selectedStore={selectedStore}
                footer={tableFooter}
              />
            ) : (
              <div className="w-full text-center">
                <NoResults className="mx-auto" />
                <Text
                  value={translations.NO_RESULTS}
                  className="mb-2 mt-6 text-lg font-semibold"
                />
              </div>
            )}
          </div>
        </div>
      )}
    </Layout>
  )
}
export default StockHistory
