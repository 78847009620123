import React from 'react';
import PropTypes from 'prop-types';

import { Text } from 'components/service';
import { FAILED_TO_LOAD_DATA, CLICK_RELOAD } from 'constants/translations';

const Fallback = ({ refetch }) => {
  return (
    <div className="my-5 px-4 text-center">
      <Text value={FAILED_TO_LOAD_DATA} className="inline" />
      <Text value={CLICK_RELOAD} className="mx-1 inline text-primary-base cursor-pointer" onClick={() => refetch()} />
    </div>
  );
};

Fallback.propTypes = {
  refetch: PropTypes.func.isRequired,
};

export default Fallback;
