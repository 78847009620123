export const transformGeoAreaData = areas => {
  const listData = !areas
    ? []
    : areas.map(item => ({
        id: item.id,
        area: item,
        totalSales: item.totalSales,
        avgOrderValue: item.avgOrderValue,
        totalOrders: item.totalOrders,
        totalCustomers: item.totalCustomers,
        zoneName: item.zoneName,
      }));

  return listData;
};

export const transformGeoBranchesData = branches => {
  const listData = !branches
    ? []
    : branches.map(item => ({
        id: item.id,
        branch: item,
        totalSales: item.totalSales,
        avgOrderValue: item.avgOrderValue,
        totalOrders: item.totalOrders,
        totalCustomers: item.totalCustomers,
        totalPickupOrders: item.totalPickupOrders,
        totalDeliveryOrders: item.totalDeliveryOrders,
      }));
  return listData;
};

export const assignNorthEastWestSouthMostCoordinates = (coordinates, point) => {
  const tempLat = parseFloat(point.lat);
  const tempLng = parseFloat(point.lng);
  const tempCoordinates = { ...coordinates };
  if (tempLat > coordinates.northLatitude) tempCoordinates.northLatitude = tempLat;
  if (tempLat < coordinates.southLatitude) tempCoordinates.southLatitude = tempLat;
  if (tempLng > coordinates.eastLongitude) tempCoordinates.eastLongitude = tempLng;
  if (tempLng < coordinates.westLongitude) tempCoordinates.westLongitude = tempLng;
  return tempCoordinates;
};
