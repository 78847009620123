import React, { useState, useContext } from "react"
import { useMutation, useApolloClient } from "@apollo/client"
import cx from "classnames"

import { Button, CatalogBox, Modal } from "components/kit"
import { Field, Text } from "components/service"
import { context as localeContext } from "context/locale"
import { context as notificationsContext } from "context/notifications"
import * as translations from "constants/translations"
import * as schemas from "./schemas"
import * as utils from "./utils"
import EditVariant from "./EditVariant"
import VariantsTable from "./VariantsTable"

const Variants = ({
  query,
  pageType,
  storeId,
  productId,
  productLang,
  variants,
  setErrorField,
  isError,
  variantsTitleEn,
  variantsTitleAr,
  setFieldValue,
  updateProduct,
  titleEn,
  titleAr,
  variantsLoading,
  variantsRefetch,
  isMenu = false,
}) => {
  const client = useApolloClient()
  const { lang, direction } = useContext(localeContext)
  const [isVisible, setVisible] = useState(true)
  const notifications = useContext(notificationsContext)
  const [updatePositions] = useMutation(schemas.UPDATE_POSITIONS, {
    variables: {
      restaurantId: storeId,
      menuItemId: productId,
    },
  })
  const [isEdit, setEditingMode] = useState(false)

  return !isVisible ? (
    <CatalogBox
      title={<Text value={translations.INTRODUCTION_VARIANTS} />}
      subtitle={<Text value={translations.IF_THIS_ITEM} />}
      description={<Text value={translations.YOU_CAN_ADD_DIFFERENT} />}
      action={
        <Button
          textColor="text-primary-base"
          kind="tertiary"
          size="base"
          weight="thin"
          onClick={() => setVisible(true)}
        >
          <Text value={translations.ADD_VARIANT} />
        </Button>
      }
    />
  ) : (
    <Modal isMenu>
      {({ open, close }) => {
        return (
          <CatalogBox
            title={<Text value={translations.VARIANTS} className="inline" />}
            subtitle={<Text value={translations.IF_THIS_ITEM} />}
            action={
              <Button
                kind="secondary"
                data-testid="edit-variants-btn"
                onClick={e => {
                  e.preventDefault()
                  setEditingMode(true)
                  open({
                    title: <Text value={translations.EDIT_VARIANT} />,
                    body: (
                      <EditVariant
                        isEdit={true}
                        isError={isError}
                        pageType={pageType}
                        client={client}
                        query={query}
                        isMenu={isMenu}
                        storeId={storeId}
                        productId={productId}
                        updatePositions={updatePositions}
                        notifications={notifications}
                        variants={variants}
                        storeEn={variantsTitleEn}
                        storeAr={variantsTitleAr}
                        productLang={productLang}
                        close={close}
                        setErrorField={setErrorField}
                        open={open}
                        onClose={close}
                        setFieldValue={setFieldValue}
                        onSubmit={async (values, { setSubmitting }) => {
                          try {
                            let variantsAtrributesArray = values.variants.map(
                              element => {
                                return {
                                  price: parseFloat(element.price),
                                  titleEn: element.titleEn,
                                  titleAr: element.titleAr,
                                  discountedPrice: parseFloat(
                                    element.discountedPrice
                                  ),
                                  discountEnabled:
                                    element.discountedPrice > 0 ? true : false,
                                  ...(element.id && {
                                    id: element.id,
                                  }),
                                  ...(element.prepTime && {
                                    prepTime: element.prepTime,
                                  }),
                                  ...(element.externalId && {
                                    externalId: element.externalId,
                                  }),
                                  ...(element.sku && {
                                    sku: element.sku,
                                  }),
                                  ...(element.barCode && {
                                    barCode: element.barCode,
                                  }),
                                  _destroy: element._destroy,
                                }
                              }
                            )
                            if (productId) {
                              await updateProduct({
                                variables: {
                                  variantsTitleAr: values.storeAr,
                                  variantsTitleEn: values.storeEn,
                                  restaurantId: storeId,
                                  menuItemId: productId,
                                  titleEn: titleEn,
                                  titleAr: titleAr,
                                  variantsAttributes: variantsAtrributesArray,
                                },
                              }).then(res => {
                                if (res) {
                                  notifications.show(
                                    <Text
                                      value={translations.VARIANT_UPDATED}
                                    />
                                  )
                                  variantsRefetch && variantsRefetch()
                                  close()
                                } else {
                                  setSubmitting(false)
                                }
                              })
                            } else {
                              setFieldValue(
                                "variantsAttributes",
                                variantsAtrributesArray
                              )
                              close()
                            }
                          } catch (error) {
                            notifications.show(
                              <Text
                                value={translations.SOMETHING_WENT_WRONG}
                              />,
                              "error"
                            )
                          }
                        }}
                      />
                    ),
                    size: "max-w-xl",
                  })
                }}
              >
                <Text value={translations.EDIT} />
              </Button>
            }
            body={
              <div
                style={{
                  direction,
                }}
                className={cx(
                  "rounded-none bg-white w-full border border-gray-300"
                )}
              >
                <SectionTitle
                  titleEn={variantsTitleEn}
                  titleAr={variantsTitleAr}
                  lang={lang}
                />
                <Field
                  open={open}
                  pageType={pageType}
                  close={close}
                  isMenu={isMenu}
                  query={query}
                  variantsLoading={variantsLoading}
                  setErrorField={setErrorField}
                  storeId={storeId}
                  productId={productId}
                  onSort={async items => {
                    utils.replaceVariants(
                      client,
                      query,
                      storeId,
                      productId,
                      items
                    )
                    const positions = items.map((item, i) => ({
                      id: +item.id,
                      position: i + 1,
                    }))
                    await updatePositions({ variables: { positions } })
                  }}
                  items={variants}
                  productLang={productLang}
                  component={VariantsTable}
                />
              </div>
            }
          />
        )
      }}
    </Modal>
  )
}

const SectionTitle = ({ titleEn, titleAr, lang }) => {
  return (
    <div className={cx("p-4 flex flex-wrap items-start border-b")}>
      <div className={cx(" text-lg flex items-center")}>{titleEn}</div>
      <div
        className={cx(
          "text-lg text-zyda-grey-30  flex items-center",
          lang === "en" ? "ml-2 " : "mr-2 "
        )}
      >
        {titleAr}
      </div>
    </div>
  )
}

export default Variants
