import React, { useContext } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { context as localeContext } from 'context/locale';

const TitleOrderNumber = ({ orderNumber, isCancel = false, refundType = '' }) => {
  const { lang } = useContext(localeContext);
  const displayedText = isCancel && translations.ARE_YOU_SURE_TO_CANCEL;
  return (
    <span className={cx(!isCancel && 'flex', lang === 'ar' && 'flex-row-reverse')}>
      <Text
        value={!isCancel ? translations.ORDER_REFUND_CONFIRM(orderNumber, refundType) : displayedText}
        className="inline"
      />

      {isCancel && (
        <span className="mx-1">
          #{orderNumber} {isCancel && ' ?'}
        </span>
      )}
    </span>
  );
};

TitleOrderNumber.propTypes = {
  orderNumber: PropTypes.number.isRequired,
  refundType: PropTypes.string,
  isCancel: PropTypes.bool,
};
export default TitleOrderNumber;
