import { convertTime } from "utils"

export const scheduleIntervalInitialConverts = time => {
  if (time < 60) {
    return [time, "mins"]
  }
  if (time >= 60) {
    return [Number(convertTime.fromMinutesToHours(time)), "hrs"]
  }
}

export const maxScheduleInitialConverts = time => {
  if (time < 24) {
    return [time, "hrs"]
  }
  if (time >= 24) {
    return [Number(convertTime.fromHoursToDays(time)), "days"]
  }
}

export const convertToMins = value => {
  if (value[1] === "mins") {
    return Number(value[0])
  }
  if (value[1] === "hrs") {
    return Number(convertTime.fromHoursToMinutes(Number(value[0])))
  }
  if (value[1] === "days") {
    return Number(convertTime.fromDaysToMinutes(Number(value[0])))
  }
}

export const convertToHours = value => {
  if (value[1] === "mins") {
    return Number(convertTime.fromMinutesToHours(Number(value[0])))
  }
  if (value[1] === "hrs") {
    return Number(value[0])
  }
  if (value[1] === "days") {
    return Number(convertTime.fromDaysToHours(Number(value[0])))
  }
}

export const minsInitialConverts = time => {
  if (time % 1440 === 0) {
    return [Number(convertTime.fromMinutesToDays(time)), "days"]
  } else if (time % 60 === 0) {
    return [Number(convertTime.fromMinutesToHours(time)), "hrs"]
  } else {
    return [time, "mins"]
  }
}
