import React, { useContext } from 'react';
import { context as localeContext } from 'context/locale';
import { Text } from 'components/service';
import cx from 'classnames';
import * as translations from 'constants/translations';
import { ReactComponent as VariantsIcon } from 'assets/product-place-holder.svg';
import moment from 'moment';

export default ({ stockHistory, langSelected, selectedStore, footer }) => {
  const { lang, direction } = useContext(localeContext);

  const headCellClassName = 'w-1/4 text-justify font-semibold border-b border-gray-300 py-3 px-5 whitespace-nowrap';

  const cellClassName = cx('w-1/4 px-4 border-b border-gray-300 whitespace-nowrap last:w-full');

  return (
    <>
      <table
        style={{ direction, maxWidth: '93.5vw' }}
        className="w-full table-auto border-collapse border shadow inline-block overflow-auto"
      >
        <thead>
          <tr className="bg-gray-200">
            <th className="text-justify font-semibold border-b border-gray-300 py-3 px-5 whitespace-nowrap">
              <Text value={translations.VARIANT_PRODUCT} className="whitespace-nowrap inline-block" />
            </th>
            <th className={headCellClassName}>
              <Text value={translations.VALUE} className="whitespace-nowrap inline-block" />
            </th>
            <th className={headCellClassName}>
              <Text value={translations.UPDATED_BY} className="whitespace-nowrap inline-block" />
            </th>
            <th className={headCellClassName}>
              <Text value={translations.CHANGED_DATE} className="whitespace-nowrap inline-block" />
            </th>
          </tr>
        </thead>
        <tbody>
          {stockHistory.map((item, i) => (
            <tr key={item.id}>
              <td className={cx('px-4 border-b border-gray-300 truncate py-3')}>
                <div className="cursor-default flex items-center border border-transparent">
                  {item.variantPhotoUrl ? (
                    <div className={cx(lang === 'ar' ? 'ml-4' : 'mr-4', 'w-12 h-12')}>
                      <img
                        className={cx(lang === 'ar' ? 'ml-10' : 'mr-10', 'h-full w-full rounded overflow-hidden')}
                        src={item.variantPhotoUrl}
                        alt={item.variantTitleEn}
                      />
                    </div>
                  ) : (
                    <div className={cx(lang === 'ar' ? 'ml-4' : 'mr-4')}>
                      <VariantsIcon />
                    </div>
                  )}

                  {langSelected === 'en' ? item.variantTitleEn : item.variantTitleAr}
                </div>
              </td>
              <td className={cx(cellClassName)}>
                <span className={cx(item.quantity > 0 ? 'text-green-600' : 'text-red-600', 'text-center')}>
                  {item.quantity > 0 ? '+' + item.quantity : item.quantity}
                </span>
              </td>
              <td className={cx(cellClassName)}>
                <div className="border-b border-transparent">
                  {item.updatedByType === 'User' ? (
                    <Text value={translations.UPDATED_BY_USER} className="block text-gray-700 text-xs" />
                  ) : (
                    <Text value={translations.UPDATED_BY_ORDER} className="block text-gray-700 text-xs" />
                  )}
                  <span>{item.updatedBy ? item.updatedBy : '--'} </span>
                </div>
              </td>
              <td className={cx(cellClassName)}>
                {moment(item.createdAt)
                  .tz(selectedStore.timeZone)
                  .format('h:mm A, DD MMM YYYY')}
              </td>
            </tr>
          ))}
        </tbody>
        {footer && (
          <div className={cx('py-2 w-full md:w-auto flex items-center border-b border-l border-gray-300')}>
            {!!stockHistory.length && footer}
          </div>
        )}
      </table>
    </>
  );
};
