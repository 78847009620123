import React, { useState, useRef, useContext, useEffect } from 'react';
import moment from 'moment';
import cx from 'classnames';
import { useQuery } from '@apollo/client';
import 'moment/locale/en-gb';
import 'moment/locale/ar';

import { parseRange, dateQueryFormat, stringifyRange, formatDate, getRange } from 'utils/date';
import * as utils from './utils';
import { Layout, Breadcrumbs } from 'components/common/dashboard';
import * as paths from 'paths.js';
import { breadcrumbs } from 'constants/translations';
import { context as localeContext } from 'context/locale';
import { useSelectedStore, useQueryState } from 'hooks/index';
import { createGlobalStyle } from 'styled-components';
import { Button, DropDown, DatePicker, Spinner } from 'components/kit';
import { Text } from 'components/service';
import { useMobile } from 'hooks/index';
import * as translations from 'constants/translations';
import { SALES_AND_REPORTS_LINKS } from "constants/helperLinks"
import { context as userContext } from 'context/user';
import { context as notificationsContext } from 'context/notifications';
import * as schemas from './schemas';
import useClickOutside from 'hooks/useClickOutside';
import { ReactComponent as SearchIcon } from 'assets/search.svg';
import { ReactComponent as FilterIcon } from 'assets/filter-icon.svg';
import { default as MobileFilters } from 'components/common/orders/MobileFilters';
import { Input, SelectionTag, TableFooter, CheckboxGroup, RadioList } from 'components/form/elements';
import { ReactComponent as NoResults } from 'assets/no-orders.svg';
import Table from './table';
import DepositDashboard from './depositDashboard';
import { validateRange, getQueryTitles, cleanPaymentMethods } from './utils';
import { PAYMENT_STATUSES, DELIVERY_TYPES, PAYMENT_STATUS_TYPES } from 'constants/salesReport';
import fetchStoreBranches from 'common/utils/dataBranches';

const PaymentTransactions = () => {
  const LIMIT = 10;
  const { lang } = useContext(localeContext);
  const storeId = useSelectedStore();
  const isMobile = useMobile();
  const { branches, selectedStore } = useContext(userContext);
  const [mobileFiltersOpened, setMobileFiltersOpened] = useState(false);
  const notifications = useContext(notificationsContext);
  const searchTimeout = useRef();
  const [range, setRange] = useState([]);
  const isSingleBranch = branches && branches.length === 1;
  const [query, setQuery] = useQueryState(
    {
      branch: isSingleBranch ? branches[0].id : 'all',
      search_by: 'order_number',
      search_value: '',
      search_status: '',
      date: 'all',
      payment_methods: [],
      delivery_type: [],
      statuses: [],
      sort_by: 'created_at',
      page: 1,
    },
    {
      page: parseInt,
    },
  );

  const [paymentMethods, setPaymentMethods] = useState([]);
  const [filters, setFilters] = useState({
    payment_methods: typeof query.payment_methods === 'string' ? [query.payment_methods] : query.payment_methods,
    date: query.date,
  });

  useEffect(() => {
    setRange(parseRange(filters.range));
  }, [filters.range]);

  useEffect(() => {
    setQuery({
      createdAt:
        query.date === 'custom'
          ? parseRange(query.range)
          : query.date !== 'all'
          ? utils.getSubmittedAt(query.date)
          : void 0,
    });
  }, [query.date]);

  useQuery(schemas.GET_PAYMENT_METHODS, {
    variables: { restaurantId: Number(storeId) },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      const fetchedPaymentMethods = data.paymentMethods.paymentMethods;
      const cleanPaymentMethods = utils.cleanFetchedPaymentMethods(fetchedPaymentMethods);
      setPaymentMethods(cleanPaymentMethods);
    },
  });

  const { data: { paymentTransactions } = {}, loading: paymentTransactionsLoading } = useQuery(
    schemas.GET_PAYMENT_TRANSACTIONS,
    {
      variables: {
        paymentMethod: query.payment_methods,
        deliveryType: query.delivery_type,
        statuses: query.statuses,
        storeId,
        branchId: query.branch === 'all' ? '' : query.branch,
        createdAt: query.createdAt,
        reference: query.search_by === 'reference' ? query.search_value : '',
        orderNumber: query.search_by === 'order_number' ? query.search_value : '',
        sort: utils.getSorting(query.sort_by),
        page: query.page,
        limit: LIMIT,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
    },
  );

  const { data: paymentCredentials = {} } = useQuery(schemas.GET_PAYMENT_CREDENTIALS, {
    variables: { restaurantId: storeId },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  const [searchValue, setSearchValue] = useState(query.search_value);
  const [filtersOpened, setFiltersOpened] = useState(false);

  // TODO :: Move dateOptions to constants , figure out DatePicker
  const dateOptions = [
    {
      value: '24_hour',
      title: <Text value={translations.LAST_24_HOURS} />,
    },
    {
      value: '7_day',
      title: <Text value={translations.LAST_7_DAYS} />,
    },
    {
      value: '30_day',
      title: <Text value={translations.LAST_30_DAYS} />,
    },
    {
      value: 'all',
      title: <Text value={translations.ALL_TIME} />,
    },
    {
      value: 'custom',
      className: 'px-4',
      hideRadioBtn: true,
      title: `${formatDate(range[0])} -> ${formatDate(range[1])}`,
      body: () => (
        <DatePicker
          range={range.map(date => moment(date, dateQueryFormat).toDate())}
          pickerTitle={<Text value={translations.CUSTOM_DATE} className="text-base font-medium block mb-2" />}
          onChange={range => {
            const error = validateRange(range);
            if (error) {
              return notifications.show(error, 'error');
            }
            setFilters({
              ...filters,
              date: 'custom',
              range: stringifyRange(
                range.map((date, index) =>
                  index === 0
                    ? moment(date)
                        .startOf('day')
                        .tz(selectedStore.timeZone, true)
                        .format()
                    : moment(date)
                        .endOf('day')
                        .tz(selectedStore.timeZone, true)
                        .format(),
                ),
              ),
            });
          }}
        />
      ),
    },
  ];

  const filtersModalRef = useRef();
  useClickOutside(filtersModalRef, () => setFiltersOpened(false));

  useEffect(() => {
    if (!query.search_value && searchValue) {
      setSearchValue('');
    }
  }, [query.search_value]);

  // :: handler to add pending along with initiated statues
  const addPendingToFilterStatuses = obj => {
    const reWriteObj = [...obj];
    if (reWriteObj.includes(PAYMENT_STATUS_TYPES.INITIATED) && !reWriteObj.includes(PAYMENT_STATUS_TYPES.PENDING)) {
      reWriteObj.push(PAYMENT_STATUS_TYPES.PENDING);
    } else if (!reWriteObj.includes(PAYMENT_STATUS_TYPES.INITIATED)) {
      reWriteObj.indexOf(PAYMENT_STATUS_TYPES.PENDING) !== -1 &&
        reWriteObj.splice(reWriteObj.indexOf(PAYMENT_STATUS_TYPES.PENDING), 1);
    }
    return reWriteObj;
  };

  const dataBranches = fetchStoreBranches(branches, isSingleBranch);
  // TODO :: Break render into smaller components file size is too big
  return (
    <>
      <Layout
        top={
          <Breadcrumbs
            helperLinks={SALES_AND_REPORTS_LINKS}
            path={breadcrumbs.SALES_REPORT}
            className={cx('flex-row items-center justify-between', lang === 'ar' && 'flex-row-reverse')}
          ></Breadcrumbs>
        }
      >
        <>
          <Style />
          <div className={cx('mb-4')}>
            {paymentCredentials?.paymentGateway?.depositDashboard && (
              <DepositDashboard paymentCredential={paymentCredentials} currency={selectedStore.currency} />
            )}
            <div className={cx('flex', lang === 'ar' && 'flex-row-reverse', 'items-start')}>
              <div className={cx('flex flex-col w-full', lang === 'ar' ? 'md:flex-row-reverse' : 'md:flex-row')}>
                <DropDown
                  float={lang === 'ar' ? 'right' : 'left'}
                  label={<Text value={isSingleBranch ? translations.BRANCH : translations.BUSINESS_LOCATION} />}
                  optionSelected={query.branch}
                  onSelect={option => setQuery({ branch: option, page: parseInt(1) })}
                  data={dataBranches}
                  icon="keyboard_arrow_down"
                  position={lang === 'ar' ? 'right' : 'left'}
                  scrollable
                  testId_openDropDown="select-business-location"
                  testId_selectedData="business-location-name"
                  testId_listData="business-location-list"
                  wFull={isMobile}
                  isDisabled={isSingleBranch}
                />
              </div>
            </div>
          </div>
          <div className="mb-2">
            <div className={cx('flex flex-wrap', lang === 'ar' && 'flex-row-reverse', 'justify-between')}>
              <div
                className={cx(
                  'w-full lg:w-2/3 flex relative',
                  lang === 'ar' && 'flex-row-reverse',
                  !isMobile && (lang === 'ar' ? 'pl-6' : 'pr-6'),
                )}
              >
                {!isMobile && (
                  <div
                    ref={filtersModalRef}
                    className={cx(
                      'flex relative',
                      lang === 'ar' && 'flex-row-reverse',
                      lang === 'ar' ? 'ml-4' : 'mr-4',
                    )}
                  >
                    <div>
                      <Button
                        style={{
                          backgroundColor: filtersOpened ? '#d8dae5' : '#f7f8fc',
                          flexDirection: lang === 'ar' ? 'row-reverse' : 'row',
                        }}
                        hFull
                        icon={<FilterIcon />}
                        onClick={() => {
                          setFiltersOpened(!filtersOpened);
                        }}
                      >
                        <Text value={translations.FILTER} />
                      </Button>
                    </div>
                    <div
                      className={cx(
                        `flex flex-wrap absolute rounded bg-white z-50 w-auto h-40`,
                        filtersOpened ? 'visible' : 'invisible',
                        lang === 'ar' && 'flex-row-reverse',
                      )}
                      style={{
                        width: 'max-content',
                        height: 'fit-content',
                        maxWidth: '85vw',
                        marginTop: '52px',
                        padding: '16px',
                        borderRadius: '4px',
                        boxShadow: '0 0 10px 0 rgba(216, 218, 229, 0.6)',
                        backgroundColor: '#ffffff',
                      }}
                    >
                      <div className={cx('flex', lang === 'ar' ? 'ml-2' : 'mr-2')}>
                        <DropDown
                          float={'left'}
                          position={lang === 'ar' ? 'right' : 'left'}
                          label={<Text value={translations.PAYMENT_ORDER} />}
                          labelClassNames="text-black"
                          icon="keyboard_arrow_down"
                          testId_openDropDown="search-payment-type"
                          testId_selectedData="default-payment-type"
                          testId_listData="payment-list"
                          noCloseOnSelect
                        >
                          <CheckboxGroup
                            groupTitle={
                              <Text value={translations.PAYMENT_TYPE_ORDER} className="text-sm text-gray-700" />
                            }
                            placeholder={<Text value={translations.PAYMENT_NOT_AVAILABLE} />}
                            items={paymentMethods}
                            value={filters.payment_methods}
                            onChange={chosenPayments => {
                              const cleanPayments = cleanPaymentMethods(chosenPayments.target.value);
                              setFilters({
                                ...filters,
                                payment_methods: cleanPayments,
                              });
                            }}
                            containerStyle={{
                              marginBottom: '1.25rem',
                              minWidth: '18rem',
                            }}
                          />
                        </DropDown>
                      </div>
                      <div className={cx('flex', lang === 'ar' ? 'ml-2' : 'mr-2')}>
                        <DropDown
                          float={'left'}
                          position={lang === 'ar' ? 'right' : 'left'}
                          label={<Text value={translations.PAYMENT_STATUS} />}
                          labelClassNames="text-black"
                          icon="keyboard_arrow_down"
                          testId_openDropDown="search-payment-status"
                          testId_selectedData="default-payment-status"
                          testId_listData="payment-status-list"
                          noCloseOnSelect
                        >
                          <CheckboxGroup
                            groupTitle={<Text value={translations.PAYMENT_STATUS} className="text-sm text-gray-700" />}
                            items={PAYMENT_STATUSES}
                            value={filters.statuses}
                            onChange={chosenTypes => {
                              setFilters({
                                ...filters,
                                statuses: addPendingToFilterStatuses(chosenTypes.target.value),
                              });
                            }}
                            containerStyle={{
                              marginBottom: '1.25rem',
                              minWidth: '18rem',
                            }}
                          />
                        </DropDown>
                      </div>
                      <div className={cx('flex', lang === 'ar' ? 'ml-2' : 'mr-2')}>
                        <DropDown
                          float={'left'}
                          position={lang === 'ar' ? 'right' : 'left'}
                          label={<Text value={translations.DATE} />}
                          labelClassNames="text-black"
                          icon="keyboard_arrow_down"
                          testId_openDropDown="search-date-range"
                          testId_selectedData="default-date-range"
                          testId_listData="date-range"
                          noOverflow
                          scrollable
                          noCloseOnSelect
                        >
                          <RadioList
                            groupTitle={
                              <Text value={translations.DATE_RANGE} className="text-sm text-gray-700 px-4 pb-1 pt-3" />
                            }
                            items={dateOptions}
                            value={filters.date}
                            onChange={option => {
                              if (option.target.value !== 'custom') {
                                setFilters({
                                  ...filters,
                                  date: option.target.value,
                                  range: stringifyRange(getRange(option.target.value)),
                                });
                              }
                            }}
                            bodyElementClassName="px-4 py-2"
                            elementClassName={`px-3 py-1`}
                            elementStyle={{
                              direction: lang === 'ar' ? 'rtl' : 'unset',
                            }}
                            containerStyle={{
                              marginBottom: '1.25rem',
                              minWidth: '18rem',
                            }}
                            hoverHighlight
                          />
                        </DropDown>
                      </div>
                      <div className={cx('flex', lang === 'ar' ? 'ml-6' : 'mr-6')}>
                        <DropDown
                          float={'left'}
                          position={lang === 'ar' ? 'right' : 'left'}
                          label={<Text value={translations.ORDER_TYPE} />}
                          labelClassNames="text-black"
                          icon="keyboard_arrow_down"
                          testId_openDropDown="search-order-type"
                          testId_selectedData="default-order-type"
                          testId_listData="order-types-list"
                          noCloseOnSelect
                        >
                          <CheckboxGroup
                            groupTitle={<Text value={translations.ORDER_TYPE} className="text-sm text-gray-700" />}
                            items={DELIVERY_TYPES}
                            value={filters.delivery_type}
                            onChange={chosenTypes => {
                              setFilters({
                                ...filters,
                                delivery_type: chosenTypes.target.value,
                              });
                            }}
                            containerStyle={{
                              marginBottom: '1.25rem',
                              minWidth: '18rem',
                            }}
                          />
                        </DropDown>
                      </div>
                      <div>
                        <Button
                          onClick={() => {
                            setQuery({ ...filters, page: parseInt(1) });
                            setFiltersOpened(!filtersOpened);
                          }}
                          kind="primary"
                          size="base"
                          style={{
                            paddingLeft: '2rem',
                            paddingRight: '2rem',
                          }}
                        >
                          <Text value={translations.APPLY_FILTERS} />
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className={cx(
                    'w-full flex',
                    lang === 'ar' && 'flex-row-reverse',
                    !isMobile && (lang === 'ar' ? 'ml-5' : 'mr-5'),
                    'border rounded-none border-gray-300',
                  )}
                >
                  <DropDown
                    float={'left'}
                    optionSelected={query.search_by}
                    onSelect={option => setQuery({ search_by: option, page: parseInt(1) })}
                    testId_openDropDown="select-search-type"
                    testId_selectedData="selected-search-type"
                    testId_listData="search-type-list"
                    data={[
                      {
                        id: 'reference',
                        title: <Text value={translations.REFERENCE_ID} />,
                      },
                      {
                        id: 'order_number',
                        title: <Text value={translations.ORDER_NUMBER} />,
                      },
                    ]}
                    icon="keyboard_arrow_down"
                    position={lang === 'ar' ? 'right' : 'left'}
                    noBorder
                    bg
                  />

                  <Input
                    type="text"
                    data-testid="search-text"
                    noBorder
                    search
                    icon={<SearchIcon />}
                    height="h-full"
                    value={searchValue}
                    inputPadding="px-2"
                    iconPadding="p-0"
                    onChange={e => {
                      setSearchValue(e.target.value);
                      clearTimeout(searchTimeout.current);
                      const { value } = e.target;
                      const appendingQuery = {
                        search_value: value,
                        search_status: 'all',
                      };
                      searchTimeout.current = setTimeout(() => {
                        setQuery({ ...appendingQuery, page: parseInt(1) });
                      }, 500);
                    }}
                    placeholder={lang === 'en' ? translations.SEARCH_ORDERS[0] : translations.SEARCH_ORDERS[1]}
                  />
                </div>
              </div>
              <div
                className={cx(
                  'w-full mt-4 lg:w-1/3 md:mt-2 lg:mt-0 flex',
                  lang === 'ar' && 'flex-row-reverse',
                  !isMobile && 'flex-wrap justify-end',
                )}
              >
                {isMobile && (
                  <div className={cx(lang === 'ar' ? 'ml-4' : 'mr-4')}>
                    <Button
                      style={{
                        backgroundColor: filtersOpened ? '#d8dae5' : '#f7f8fc',
                        flexDirection: lang === 'ar' ? 'row-reverse' : 'row',
                      }}
                      hFull
                      icon={<FilterIcon />}
                      onClick={() => {
                        setMobileFiltersOpened(true);
                      }}
                    >
                      <Text value={translations.FILTER} />
                    </Button>
                  </div>
                )}
                {isMobile && (
                  <MobileFilters
                    isVisible={mobileFiltersOpened}
                    onClose={() => setMobileFiltersOpened(false)}
                    onApply={() => {
                      setQuery({ ...filters, page: parseInt(1) });
                      setMobileFiltersOpened(false);
                    }}
                  >
                    <CheckboxGroup
                      groupTitle={<Text value={translations.PAYMENT_TYPE_ORDER} className="text-base text-gray-600" />}
                      placeholder={<Text value={translations.PAYMENT_NOT_AVAILABLE} />}
                      value={filters.payment_methods}
                      onChange={chosenPayments => {
                        const cleanPayments = cleanPaymentMethods(chosenPayments.target.value);
                        setFilters({
                          ...filters,
                          payment_methods: cleanPayments,
                        });
                      }}
                      items={paymentMethods}
                      containerStyle={{
                        minWidth: '18rem',
                      }}
                      wFull
                    />
                    <RadioList
                      groupTitle={
                        <Text value={translations.DATE_RANGE} className="text-base text-gray-600 px-4 pb-1 pt-3" />
                      }
                      value={filters.date}
                      onChange={option => {
                        if (option.target.value !== 'custom') {
                          setFilters({
                            ...filters,
                            date: option.target.value,
                            range: stringifyRange(getRange(option.target.value)),
                          });
                        }
                      }}
                      items={dateOptions}
                      bodyElementClassName="px-4 py-2"
                      hoverHighlight={true}
                      elementStyle={{
                        direction: lang === 'ar' ? 'rtl' : 'unset',
                      }}
                      containerStyle={{
                        marginTop: '0.5rem',
                        minWidth: '18rem',
                      }}
                      elementClassName={`px-2 py-1`}
                    />
                    <CheckboxGroup
                      groupTitle={<Text value={translations.ORDER_TYPE} className="text-base text-gray-600" />}
                      items={DELIVERY_TYPES}
                      value={filters.delivery_type}
                      onChange={chosenTypes => {
                        setFilters({
                          ...filters,
                          delivery_type: chosenTypes.target.value,
                        });
                      }}
                      containerStyle={{
                        marginTop: '0.5rem',
                        minWidth: '18rem',
                      }}
                      wFull
                    />
                  </MobileFilters>
                )}
              </div>
            </div>
          </div>
          <div>
            <div className={cx('flex flex-wrap', lang === 'ar' && 'flex-row-reverse', 'justify-between')}>
              <div className={cx('w-full flex flex-wrap', lang === 'ar' && 'flex-row-reverse')}>
                <SelectionTag
                  isRemovable={true}
                  onRemoveClick={() => {
                    setQuery({ payment_methods: [], page: parseInt(1) });
                    setFilters({ payment_methods: [] });
                  }}
                  items={getQueryTitles(paymentMethods, query.payment_methods)}
                />
                <SelectionTag
                  isRemovable={true}
                  onRemoveClick={() => {
                    setQuery({ statuses: [], page: parseInt(1) });
                    setFilters({ statuses: [] });
                  }}
                  items={getQueryTitles(PAYMENT_STATUSES, query.statuses)}
                />
                <SelectionTag
                  isRemovable={true}
                  onRemoveClick={() => {
                    setQuery({ date: 'all', range: [], page: parseInt(1) });
                    setFilters({ date: 'all', range: [] });
                  }}
                  items={query.date !== 'all' && getQueryTitles(dateOptions, query.date)}
                />
                <SelectionTag
                  isRemovable={true}
                  onRemoveClick={() => {
                    setQuery({ delivery_type: [], page: parseInt(1) });
                    setFilters({ delivery_type: [] });
                  }}
                  items={getQueryTitles(DELIVERY_TYPES, query.delivery_type)}
                />
              </div>
            </div>
          </div>
          {!branches || !branches.length ? (
            <Text value={translations.STORE_HAS_NO_BRANCHES} className="mt-2" />
          ) : !paymentTransactions || paymentTransactionsLoading ? (
            <Spinner />
          ) : (
            <div className="mt-2 mb-24">
              {' '}
              {/* 'mb-24' is for intercom button */}
              {paymentTransactions?.paymentTransactions?.length ? (
                <Table
                  paymentTransactions={paymentTransactions?.paymentTransactions}
                  selectedStore={selectedStore}
                  footer={
                    <TableFooter
                      totalRecords={paymentTransactions?.totalCount}
                      pageLimit={LIMIT}
                      currentPage={parseInt(query.page)}
                      onPageChanged={({ currentPage }) => {
                        setQuery({ page: parseInt(currentPage) });
                      }}
                      totalCount={paymentTransactions?.paymentTransactions?.length}
                    />
                  }
                />
              ) : (
                <div className="w-full text-center">
                  <NoResults className="mx-auto" />
                  <Text value={translations.NO_RESULTS} className="mb-2 mt-6 text-lg font-semibold" />
                </div>
              )}
            </div>
          )}
        </>
      </Layout>
    </>
  );
};

export default PaymentTransactions;

const Style = createGlobalStyle`
  .active-link .count {
    display: block !important;
  }
`;
