import React, { useContext } from 'react';
import { Button } from '@zydalabs/zac-react';
import cx from 'classnames';

import { UPGRADE_YOUR_FREE_PLAN } from 'constants/translations';
import { Text } from 'components/service';
import { ReactComponent as UpgradeRocketIcon } from 'assets/upgrade-rocket.svg';
import { useMobile } from 'hooks';
import { context as localeContext } from 'context/locale';

declare global {
  interface Window {
    Intercom: any;
  }
}

type BlockedFeatureProps = {
  featureTitle: Array<string>;
  featureDescription: Array<string>;
  featureListTitle: Array<string>;
  featureList: Array<Array<string>>;
  translate: (translationTextArray: Array<string>) => string;
  featureDesktopImage: string;
  featureMobileImage: string;
};

const BlockedFeature: React.FC<BlockedFeatureProps> = ({
  featureTitle = ['', ''],
  featureDescription = ['', ''],
  featureListTitle = ['', ''],
  featureList = [['', '']],
  translate,
  featureDesktopImage = '',
  featureMobileImage = '',
}) => {
  const isMobile = useMobile();
  const { lang } = useContext(localeContext);
  const featureImage = isMobile ? featureMobileImage : featureDesktopImage;

  return (
    // Wrapper background div
    <div className="relative w-full h-full">
      <div
        className={cx('w-full h-full bg-contain bg-no-repeat flex flex-col justify-end', isMobile && 'fixed')}
        style={{
          backgroundImage: `url(${featureImage})`,
        }}
      >
        {/* Content div */}
        <div className="absolute inset-0 bg-gradient-to-b from-transparent via-white to-white" />
        <div
          className={cx(
            'grid w-full',
            isMobile ? 'grid-row-2 pb-10 fixed bottom-0' : 'relative lg:grid-cols-2 lg:divide-x px-40 pb-40',
          )}
        >
          <div className={cx(lang === 'en' ? 'pl-2' : 'pr-2')}>
            <div className={cx('flex', lang === 'ar' ? 'flex-row-reverse' : 'flex-row')}>
              <UpgradeRocketIcon />
              <Text
                testId="blocked-feature-call-to-action-text"
                className={cx('font-semibold text-sm', lang === 'en' ? 'pl-2' : 'pr-2')}
                value={featureTitle}
              />
            </div>
            <Text
              testId="blocked-feature-attraction-title"
              className="font-bold text-3xl pt-4"
              value={featureDescription}
            />
            <div className={cx('mt-5', isMobile && 'pb-16', lang === 'ar' && 'float-right')}>
              <Button
                onClick={() => {
                  if (window.Intercom) window.Intercom('show');
                }}
                type="button"
                size="medium"
                rounded
                text={translate(UPGRADE_YOUR_FREE_PLAN)}
              />
            </div>
          </div>
          <div className={cx(lang === 'en' ? 'lg:pl-8 pl-1' : 'lg:pr-8 pr-1')}>
            <Text
              testId="blocked-feature-feature-attributes-title"
              className={cx('font-semibold text-sm', lang === 'en' ? 'pl-2' : 'pr-2')}
              value={featureListTitle}
            />
            <ul
              dir={lang === 'en' ? 'ltr' : 'rtl'}
              className={cx('pt-4', lang === 'en' ? 'lg:pl-8 pl-4' : 'lg:pr-8 pr-4')}
            >
              {featureList.map((featureTextArray, index) => (
                // Disabled here since it's not the main identifor the list item

                // eslint-disable-next-line react/no-array-index-key
                <li key={`${featureTextArray[0]}-${index}`} className="list-disc  text-zyda-grey-45 opacity-60">
                  <Text testId={`blocked-feature-feature-attributes-${index}`} value={featureTextArray} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BlockedFeature;
