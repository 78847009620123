import React from 'react';
import moment from 'moment';
import DateTime from 'react-datetime';

/**
 * Back-end accepts data in 24h format, but according to the requirements, UI should display
 * time in 12h format, therefore all input and output data is in 24h format and UI related
 * data is in 12h format.
 **/
export default ({ title, value, onChange, isDisabled, testId }) => {
  const time = moment(value, 'HH:mm').format('h:mm A');
  const timeClassName = 'px-2 py-1 w-24 inline-block focus:outline-none';

  return (
    <div className="flex items-center" onClick={e => e.stopPropagation()}>
      <span className="text-gray-500">{title}</span>

      {isDisabled ? (
        <span className={timeClassName}>{time}</span>
      ) : (
        <div data-testid={testId}>
          <DateTime
            dateFormat={false}
            timeFormat="h:mm A"
            value={time}
            inputProps={{ className: 'w-full' }}
            onChange={value => onChange(moment(value, 'h:mm A').format('HH:mm'))}
            className={timeClassName}
          />
        </div>
      )}
    </div>
  );
};
