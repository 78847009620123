import React, { useContext, useState, useRef } from "react"
import { useMutation } from "@apollo/client"
import axios from "axios"
import { context as notificationsContext } from "context/notifications"
import * as cx from "classnames"
import { Text } from "components/service"
import { navigate } from "@reach/router"
import { Link } from "components/service"
import { uploadProductImage, deleteProductImage } from "rest.js"
import { BasicDetails } from "components/common/product"
import { useReplaceParams, useSelectedStore, useQueryState } from "hooks"
import { context as localeContext } from "context/locale"
import { Layout, Breadcrumbs } from "components/common/dashboard"
import { Button, Uploader, Modal } from "components/kit"
import * as translations from "constants/translations"
import { breadcrumbs } from "constants/translations"
import { Formik, Form } from "formik"
import * as paths from "paths.js"
import { useMobile } from "hooks/index"
import { getBackUrl } from "utils/history"
import * as schemas from "./schemas"
import * as utils from "./utils"
import * as data from "./data"

export default () => {
  const replace = useReplaceParams()
  const { lang, direction } = useContext(localeContext)
  const notifications = useContext(notificationsContext)
  const isMobile = useMobile()
  const formRef = useRef()
  const submitting = useRef()
  const imageTokenSource = axios.CancelToken.source()
  let handleErrorMessage = utils.handleErrors(err => {
    notifications.show(err.msg, "error")
  })

  const [query, setQuery] = useQueryState({
    categoryId: "all",
  })

  const [createProduct] = useMutation(schemas.CREATE_PRODUCT, {
    onError: handleErrorMessage,
  })
  const selectedStoreId = useSelectedStore()

  return (
    <Modal>
      {({ open, close }) => (
        <Layout
          top={
            <Breadcrumbs
              links={[paths.products, paths.productsCatalog]}
              path={breadcrumbs.SETUP_PRODUCT_CREATION}
            />
          }
        >
          <div
            className={cx(
              "pb-6 flex",
              lang === "ar" && "flex-row-reverse"
            )}
          >
            <div className="w-full" style={{ direction }}>
              <Formik
                ref={formRef}
                initialValues={data.initialValues}
                validationSchema={data.validationSchema}
                onSubmit={async (values, { setSubmitting }) => {
                  if (submitting.current) return

                  submitting.current = true
                  const publishedInStringArray = values?.publishIn
                    ? values.publishIn.map(String)
                    : []

                  const res = await createProduct({
                    variables: {
                      restaurantId: selectedStoreId,
                      menuSectionIds: values.categories,
                      publishedBranchIds: publishedInStringArray,
                      titleEn: values.titleEn,
                      titleAr: values.titleAr,
                      externalId: values.externalId,
                      descriptionEn: values.descriptionEn,
                      descriptionAr: values.descriptionAr,
                      price: values.price + "",
                    },
                  })

                  res?.data?.createMenuItem?.id &&
                    values.photoUrl &&
                    (await uploadProductImage(
                      selectedStoreId,
                      res.data.createMenuItem.id,
                      {
                        productImage: values.photoUrl,
                      },
                      imageTokenSource.token
                    ).catch(err => {
                      notifications.show(
                        <Text value={translations.SOMETHING_WENT_WRONG} />,
                        "error"
                      )
                    }))

                  // we're having an error during a submission here
                  if (!res) {
                    setSubmitting(false)
                    submitting.current = false

                    return
                  }

                  if (values.isCustomize) {
                    navigate(
                      replace(paths.advancedProductCreation, {
                        params: {
                          productId: res.data.createMenuItem.id,
                        },
                      })
                    )
                  } else if (values.isPublish) {
                    setQuery({
                      categoryId:
                        values.categories.length === 1
                          ? values.categories[0]
                          : "all",
                    })
                    navigate(
                      replace(paths.productsCatalog, {
                        storeId: selectedStoreId,
                        appendQuery: true,
                      })
                    )
                    notifications.show(
                      <Text value={translations.CREATE_PRODUCT} />
                    )
                  }

                  submitting.current = false
                }}
              >
                {({ values, setFieldValue, ...rest }) => (
                  <Form>
                    <BasicDetails
                      isError={rest.errors}
                      isSubmitting={values.isCustomize || values.isPublish}
                      price={true}
                      pageType="add"
                      open={open}
                      close={close}
                      setFieldValue={setFieldValue}
                      imageTokenSource={imageTokenSource}
                    />
                    <div className="flex flex-wrap mt-8 md:w-2/3">
                      <div className="flex-2 mt-2 md:mt-0 w-full md:w-auto">
                        <Link
                          to={getBackUrl() || paths.productsCatalog}
                          omitQuery
                        >
                          <Button
                            data-testid="cancel-add-product-btn"
                            kind="secondaryError"
                            size="base"
                            full
                          >
                            <Text value={translations.CANCEL_PRODUCT} />
                          </Button>
                        </Link>
                      </div>
                      <div className="flex-1 flex items-stretch flex-row justify-end mt-2 md:mt-0">
                        <span
                          className={cx(
                            lang === "en" ? "mr-3" : "ml-3",
                            "w-full md:w-auto"
                          )}
                        >
                          <Button
                            full
                            data-testid="publish-product-btn"
                            type="button"
                            kind="secondary"
                            size="base"
                            isSpinning={values.isPublish && rest.isSubmitting}
                            onClick={async () => {
                              await setFieldValue("isPublish", true)
                              await setFieldValue("isCustomize", false)
                              rest.submitForm()
                            }}
                          >
                            <Text value={translations.PUBLISH} />
                          </Button>
                        </span>
                        <div className="w-full md:w-auto">
                          <Button
                            full
                            data-testid="save-customize-product-btn"
                            type="button"
                            kind="primary"
                            size="base"
                            isSpinning={values.isCustomize && rest.isSubmitting}
                            onClick={async () => {
                              await setFieldValue("isCustomize", true)
                              await setFieldValue("isPublish", false)
                              rest.submitForm()
                            }}
                          >
                            <Text value={translations.SAVE_CUSTOMIZE} />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Layout>
      )}
    </Modal>
  )
}
