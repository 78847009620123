import React from 'react';

import {
  INVENTORY_DESCRIPTION,
  NONE_DESCRIPTION,
  CAPACITY_DESCRIPTION,
  TRACKING_NONE,
  TRACKING_CAPACITY,
  TRACKING_INVENTORY,
} from 'constants/translations';

export const TRACKING_TYPE_LIST = {
  none: {
    name: TRACKING_NONE,
    description: NONE_DESCRIPTION,
  },
  stock: {
    name: TRACKING_INVENTORY,
    description: INVENTORY_DESCRIPTION,
  },
  daily_capacity: {
    name: TRACKING_CAPACITY,
    description: CAPACITY_DESCRIPTION,
  },
};

export const handleTrackingDesctiption = key =>
  key ? TRACKING_TYPE_LIST[key].description : TRACKING_TYPE_LIST.none.description;

export const handleTrackingItemsDisplayButtons = (Text, className) => {
  const items = [];
  Object.keys(TRACKING_TYPE_LIST).forEach(key => {
    items.push({
      title: <Text value={TRACKING_TYPE_LIST[key].name} className={className} />,
      value: key,
    });
  });

  return items;
};
