import React, { useContext } from "react"
import moment from "moment"
import DatePicker from "react-datepicker"
import { registerLocale } from "react-datepicker"
import { context as userContext } from "context/user"
import { context as localContext } from "context/locale"
import { Text } from "components/service"
import * as translations from "constants/translations"
import cx from "classnames"
import ar from "date-fns/locale/ar-SA"
import en from "date-fns/locale/en-US"
import "./index.css"

registerLocale("ar-SA", ar)
registerLocale("en-US", en)

export default ({
  onChange,
  value,
  range,
  pickerTitle,
  allowFutureDates,
  ...props
}) => {
  return range ? (
    <div>
      {pickerTitle ? (
        pickerTitle
      ) : (
        <Text value={translations.DATE_RANGE} className="block mb-2" />
      )}
      <div className="mb-2">
        <DateItem
          onChange={date => onChange([date, range[1]])}
          value={range[0]}
          title={<Text value={translations.FROM} />}
          allowFutureDates
          {...props}
        />
      </div>
      <DateItem
        onChange={date => onChange([range[0], date])}
        value={range[1]}
        title={<Text value={translations.TO} />}
        allowFutureDates
        {...props}
      />
    </div>
  ) : (
    <DateItem onChange={onChange} value={value} allowFutureDates {...props} />
  )
}

const DateItem = ({
  onChange,
  title,
  value,
  dateFormat,
  allowFutureDates,
  ...props
}) => {
  const { selectedStore } = useContext(userContext)
  const { lang, direction } = useContext(localContext)

  return (
    <div style={{ direction }}>
      {title && <div className="mb-1">{title}</div>}
      <DatePicker
        strictParsing
        locale={lang === "en" ? "en-US" : "ar-SA"}
        dateFormat={
          dateFormat
            ? dateFormat
            : lang === "en"
            ? "MMM d, yyyy"
            : "MMMM d, yyyy"
        }
        popperPlacement="bottom-end"
        popperClassName={cx(
          lang === "en"
            ? "react-datepicker-popper-en"
            : "react-datepicker-popper-ar"
        )}
        dayClassName={date =>
          !allowFutureDates && !allowedRange(date, selectedStore.createdAt)
            ? "react-datepicker__disabled-day"
            : ""
        }
        selected={value}
        onChange={value =>
          props.isRemovable
            ? value
              ? onChange(value)
              : onChange("")
            : value && onChange(value)
        }
        {...props}
      />
    </div>
  )
}

const allowedRange = (date, creationDate) => {
  const selected = moment(date)
  const created = moment(creationDate)
  const now = moment()

  return selected.isBetween(created, now)
}
