import React, { useState, useRef, useContext, Fragment } from "react"
import { navigate } from "@reach/router"
import { useMutation } from "@apollo/client"
import moment from "moment"
import cx from "classnames"
import { Formik, Form } from "formik"

import { useSelectedStore, useReplaceParams, useMobile } from "hooks"
import useClickOutside from "hooks/useClickOutside"
import * as translations from "constants/translations"
import { context as localeContext } from "context/locale"
import { context as userContext } from "context/user"
import { context as notificationsContext } from "context/notifications"
import { withBackParam } from "utils/history"

import { Stack, Button, Modal, SpinnerAlt } from "components/kit"
import Pagination from "components/kit/Pagination"
import { Label, Row, Footer } from "components/form/generic"
import { MultiCheckbox } from "components/form/elements"
import { Text, Field, Link } from "components/service"
import { ReactComponent as CloseIcon } from "assets/close.svg"
import { ReactComponent as ProductIcon } from "assets/product-place-holder.svg"
import * as schemas from "pages/setup/product/catalog/List/schemas"
import * as paths from "paths.js"

export default ({
  products,
  selectedLocation,
  totalCount,
  refetchProduct,
  currentPage,
  langSelected: lang,
  onCurrentPageChange,
  onProductChange,
}) => {
  const { lang: storeLang } = useContext(localeContext)
  const onPageChanged = data => onCurrentPageChange(data ? data.currentPage : 1)

  return (
    <Fragment>
      <Items
        items={products}
        refetchProduct={refetchProduct}
        lang={lang}
        selectedLocation={selectedLocation}
        onProductChange={onProductChange}
        currentPage={currentPage}
      />
      {!!totalCount ? (
        <div
          className={cx(
            "h-10 my-3",
            storeLang === "ar" ? "flex-reverse justify-start" : ""
          )}
          style={{
            direction: storeLang === "ar" ? "rtl" : "ltr",
          }}
        >
          <Pagination
            totalRecords={totalCount}
            pageLimit={10}
            pageNeighbours={1}
            currentPage={currentPage}
            onPageChanged={onPageChanged}
          />
        </div>
      ) : null}
    </Fragment>
  )
}

const Items = ({
  items,
  lang,
  selectedLocation,
  refetchProduct,
  onProductChange,
  currentPage,
}) => {
  const { translate } = useContext(localeContext)
  return (
    <div className="-mt-px w-full">
      {items.map((item, i) => (
        <Item
          {...item}
          title={translate(item, lang)}
          index={i}
          key={i}
          lang={lang}
          selectedLocation={selectedLocation}
          onProductChange={onProductChange}
          refetchProduct={refetchProduct}
          currentPage={currentPage}
        />
      ))}
    </div>
  )
}

const Item = ({
  id,
  photoUrl,
  title,
  displayPrice,
  unpublishedBranchesCount,
  lang,
  publishedBranchIds,
  selectedLocation,
  onProductChange,
  refetchProduct,
  index,
  discountEnabled,
  currentPage,
}) => {
  const [toggleOpen, setToggleOpen] = useState(false)
  const notifications = useContext(notificationsContext)
  const isMobile = useMobile()
  const replace = useReplaceParams()
  const { branches, selectedStore } = useContext(userContext)
  const branchesData =
    branches === null
      ? []
      : branches.map(branch => ({
        id: branch.id,
        title: <Text value={branch} />,
      }))
  const isProductPublished = () => {
    if (selectedLocation) {
      const isPublished = publishedBranchIds.find(
        publishedBranch => publishedBranch === selectedLocation
      )
      return isPublished ? true : false
    } else {
      return unpublishedBranchesCount > 0 ? false : true
    }
  }
  const PublishButton = () => {
    if (selectedLocation) {
      const isPublished = publishedBranchIds.find(
        publishedBranch => publishedBranch === selectedLocation
      )
      return isPublished ? (
        <Text value={translations.UNPUBLISH} className="whitespace-nowrap" />
      ) : (
        <Text value={translations.PUBLISH} className="whitespace-nowrap" />
      )
    } else {
      return (
        <Text
          value={translations.EDIT_PUBLISH}
          className="whitespace-nowrap"
        />
      )
    }
  }

  const translate = (lang, value) => {
    const [en, ar] =
      value instanceof Array ? value : [value.titleEn, value.titleAr]
    return lang === "en" ? en : lang === "ar" ? ar : ""
  }

  const currency = selectedStore.currency
  lang === "ar" ? moment.locale("ar-kw") : moment.locale("en-gb")
  const direction = lang === "ar" ? "rtl" : "ltr"
  const refs = useRef()

  const unPublishedIn =
    lang === "en"
      ? `${translations.UNPUBLISHED_IN[0]} ${unpublishedBranchesCount} ${translations.LOCATIONS[0]}`
      : `${translations.UNPUBLISHED_IN[1]} ${unpublishedBranchesCount} ${translations.LOCATIONS[1]}`

  const getFormattedPrice = price => {
    if (price === null) return ""
    else if (price === "price on selection")
      return lang === "en"
        ? translations.PRICE_ON_SELECTION[0]
        : translations.PRICE_ON_SELECTION[1]
    return `${price} ${translate(lang, currency)}`
  }

  const showPublishingInfo = () => {
    if (isProductPublished()) {
      return lang === "en"
        ? ` • ${translations.PUBLISHED[0]}`
        : ` • ${translations.PUBLISHED[1]}`
    } else {
      if (selectedLocation) {
        return lang === "en"
          ? ` • ${translations.UNPUBLISHED[0]}`
          : ` • ${translations.UNPUBLISHED[1]}`
      }
      return ` • ${unPublishedIn}`
    }
  }

  const showDiscountLabel = () => {
    if (!discountEnabled) return ""
    else
      return lang === "en"
        ? ` • ${translations.DISCOUNTED_PRODUCT[0]}`
        : ` • ${translations.DISCOUNTED_PRODUCT[1]}`
  }

  useClickOutside(refs, () => setToggleOpen(false))

  const onToggle = () => {
    setToggleOpen(!toggleOpen)
  }

  const onSelect = () => {
    onToggle()
  }

  const editProduct = e => {
    e.preventDefault()
    if (branches === null) {
      notifications.show(
        <Text value={translations.YOU_HAVE_ADD_BUSINESS_LOCATION_FIRST} />,
        "info"
      )
    } else {
      navigate(
        replace(paths.advancedProductCreation, {
          omitQuery: true,
          params: { productId: id },
        }) + `?type=edit&${withBackParam()}`
      )
    }
  }

  const path =
    replace(paths.advancedProductCreation, {
      omitQuery: true,
      params: { productId: id },
    }) + `?type=edit&${withBackParam()}`

  return (
    <Modal>
      {({ open, close }) => (
        <div
          className={cx(
            "flex justify-between bg-white border-t border-b border-gray-300 px-6 cursor-pointer",
            lang === "ar" && "flex-reverse",
            "items-center"
          )}
          data-testid={`product-item-${index}`}
        >
          <Link
            to={path}
            onClick={editProduct}
            className={cx(
              "flex items-center w-full",
              lang === "ar" && "flex-reverse",
            )}
          >
            <div
              className="cursor-default py-3"
              onClick={e => e.stopPropagation()}
            >
              {photoUrl ? (
                <div className={cx(lang === "ar" ? "ml-4" : "mr-4", "w-12 h-12")}>
                  <img
                    className={cx(
                      "h-full w-full rounded overflow-hidden"
                    )}
                    src={photoUrl}
                    alt={title}
                  />
                </div>
              ) : (
                <div className={cx(lang === "ar" ? "ml-4" : "mr-4")}>
                  <ProductIcon />
                </div>
              )}
            </div>
            <div className="w-full">
              <div
                style={{
                  direction,
                }}
                className="font-semibold"
              >
                {title}
              </div>
              <div
                style={{
                  direction,
                }}
                className="text-xs text-gray-600"
                data-testid="product-status"
              >
                {getFormattedPrice(displayPrice)}
                {showPublishingInfo()}
                {showDiscountLabel()}
              </div>
            </div>
          </Link>
          <div
            className={cx(
              "flex flex-row",
              lang === "ar" && "flex-row-reverse",
              " justify-end"
            )}
          >

            <div
              className="flex-2 relative group mx-1"
              ref={refs}
              onClick={e => e.stopPropagation()}
            >
              <div className="flex items-center cursor-pointer">
                <Button
                  size="option"
                  kind="secondary"
                  onClick={onToggle}
                  icon="more_vert"
                  iconSize="2xl"
                  style={{ fill: "#818181" }}
                  data-testid="list-product-more-btn"
                />
              </div>
              <div
                className={cx(
                  "mt-1 items-center absolute border-t-0 bg-white z-50",
                  lang === "ar" ? "left-0" : "right-0",
                  " shadow rounded border border-transparent",
                  toggleOpen ? " visible" : " invisible"
                )}
              >
                <ul className="list-reset text-center py-2">
                  <li
                    className="px-4 py-2 block text-zyda-black-100 border-t border-b border-transparent hover:border-gray-300 cursor-pointer"
                    data-testid="btn-modal-publish-product"
                    onClick={() => {
                      if (branches === null) {
                        notifications.show(
                          <Text
                            value={
                              translations.YOU_HAVE_ADD_BUSINESS_LOCATION_FIRST
                            }
                          />,
                          "info"
                        )
                      } else {
                        onSelect()
                        open({
                          testId: "product-publish-modal",
                          title: <ModalTitle title={title} />,
                          body: (
                            <UpdateProductBody
                              selectedBranchId={selectedLocation}
                              branches={branchesData}
                              isPublished={isProductPublished()}
                              publishedBranchIds={publishedBranchIds}
                              productId={id}
                              onCancel={close}
                              onProductChange={onProductChange}
                              refetchProduct={refetchProduct}
                            />
                          ),
                          size: "max-w-sm",
                        })
                      }
                    }}
                  >
                    <Button
                      style={{
                        border: "none",
                        fontWeight: 'initial',
                        height: "100%",
                      }}
                    >
                      <PublishButton />
                    </Button>
                  </li>
                  <li
                    className="px-4 py-2 block border-t border-b border-transparent hover:border-gray-300 cursor-pointer"
                    data-testid="btn-modal-edit-product"
                    onClick={editProduct}
                  >
                    <Button
                      style={{
                        border: "none",
                        fontWeight: 'initial',
                        height: "100%",
                      }}
                    >
                      <Text value={translations.EDIT} />
                    </Button>
                  </li>
                  <li
                    className="px-4 py-2 block text-zyda-black-100 border-t border-b border-transparent hover:border-gray-300 cursor-pointer"
                    data-testid="btn-modal-delete-product"
                    onClick={() => {
                      onSelect()
                      open({
                        testId: "product-delete-modal",
                        title: <ModalTitle title={title} onCancel={close} />,
                        body: (
                          <DeleteProductBody
                            productId={id}
                            onCancel={close}
                            productNameEn={title}
                            onProductChange={onProductChange}
                            refetchProduct={refetchProduct}
                          />
                        ),
                        size: "max-w-sm",
                      })
                    }}
                  >
                    <Button
                      style={{
                        border: "none",
                        fontWeight: 'initial',
                        height: "100%",
                      }}
                    >
                      <Text
                        value={translations.DELETE}
                        className="whitespace-nowrap"
                      />
                    </Button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </Modal>
  )
}

const ModalTitle = ({ title, onCancel }) => {
  const { lang } = useContext(localeContext)
  return (
    <div
      className={cx(
        "flex justify-between",
        lang === "ar" && "flex-row-reverse"
      )}
    >
      <span className="mx-1">{title}</span>
    </div>
  )
}

const DeleteProductBody = ({
  productId,
  productNameEn,
  onCancel,
  onProductChange,
  refetchProduct,
}) => {
  const { lang } = useContext(localeContext)
  const notifications = useContext(notificationsContext)

  const storeId = useSelectedStore()

  const [deleteProduct, { loading: deleteProductLoading }] = useMutation(
    schemas.DELETE_PRODUCT
  )
  const handleDeleteProduct = async (id, onCancel) => {
    try {
      await deleteProduct({
        variables: {
          storeId,
          id,
        },
      })
      onCancel()
      refetchProduct()
      onProductChange()
      notifications.show(<Text value={translations.PRODUCT_DELETED} payload={productNameEn}/>)
    } catch (error) {
      notifications.show(
        <Text value={translations.SOMETHING_WENT_WRONG} />,
        "error"
      )
    }
  }
  const [updateProduct] = useMutation(
    schemas.UPDATE_PRODUCT,
    {
      onCompleted: () => {
        refetchProduct()
        notifications.show(<Text value={translations.UNPUBLISH_PRODUCT_SUCCESS} />)
      }
    }
  )
  const unPublishButton = async (productId) => {
    try {
      await updateProduct({
        variables: {
          storeId,
          productId,
          publishedBranchIds: []
        },
      })
    } catch (error) {
      notifications.show(
        <Text value={translations.SOMETHING_WENT_WRONG} />,
        "error"
      )
    }
  }
  return (
    <div>
      <Text
        className="mx-1 px-4 mb-3"
        value={translations.DELETE_PRODUCT_CONFIRMATION}
      />
      <Text
        value={translations.DELETE_PRODUCT_NOTE}
        className="mb-6 mx-1 px-4 text-gray-700 text-xs"
      />
      <Footer modalFooter>
        <div className="w-full flex items-center">
          <div className={cx(lang === "ar" ? "mr-auto" : "ml-auto")}>
            <Stack direction="row">
              <Button
                data-testid="unpublish-delete-product"
                onClick={() => unPublishButton(productId)}
                type="button"
                kind="tertiary"
              >
                <Text value={translations.UNPUBLISH} />
              </Button>
              <Button
                kind="secondaryError"
                onClick={() => handleDeleteProduct(productId, onCancel)}
                data-testid="btn-delete-product"
              >
                {deleteProductLoading && (
                  <span className="w-4 h-4 mr-2">
                    <SpinnerAlt color="primary-base" />
                  </span>
                )}
                <Text value={translations.DELETE} />
              </Button>
            </Stack>
          </div>
        </div>
      </Footer>
    </div>
  )
}

const UpdateProductBody = ({
  selectedBranchId,
  productId,
  publishedBranchIds,
  branches,
  isPublished,
  onCancel,
  onProductChange,
  refetchProduct,
}) => {
  const { lang } = useContext(localeContext)
  const storeId = useSelectedStore()
  const direction = lang === "ar" ? "rtl" : "ltr"
  const [updateProduct, { loading: updateProductLoading }] = useMutation(
    schemas.UPDATE_PRODUCT
  )
  const [
    updateProductByBranch,
    { loading: updateProductByBranchLoading },
  ] = useMutation(schemas.UPDATE_PRODUCT_BY_BRANCH)
  const handleUpdateProduct = async values => {
    if (selectedBranchId) {
      await updateProductByBranch({
        variables: {
          storeId,
          productId,
          branchId: selectedBranchId,
          published: !isPublished,
        },
      })
    } else {
      await updateProduct({
        variables: {
          storeId,
          productId,
          ...values,
        },
      })
    }
    onCancel()
    refetchProduct()
    onProductChange()
  }

  return (
    <Formik
      onSubmit={handleUpdateProduct}
      initialValues={{ publishedBranchIds }}
    >
      <Form>
        <h1 className="mx-1 px-4">
          {isPublished ? (
            <Text value={translations.UNPUBLISH_PRODUCT_CONFIRMATION} className="text-gray-700" />
          ) : (
            <Text value={translations.PUBLISH_PRODUCT_CONFIRMATION} className="text-gray-700" />
          )}
          {!selectedBranchId && (
            <Row className="mt-4" spacing="0" style={{ direction }}>
              <Field
                testIdOpenList={`list-product-publishIn-list`}
                testIdOptionsList={`list-product-publishIn-option`}
                testIdClearAll={`list-product-clear-all-publishIn`}
                testIdSelectAll={`list-product-select-all-publishIn`}
                font
                title={<Text value={translations.PUBLISHED_IN} />}
                subtitle={<Text value={translations.DESC_SELECT_LOCATION} />}
                name="publishedBranchIds"
                placeholder={
                  lang === "en"
                    ? translations.PLEASE_SELECT_LOCATION[0]
                    : translations.PLEASE_SELECT_LOCATION[1]
                }
                items={branches}
                component={MultiCheckbox}
              />
            </Row>
          )}
        </h1>
        <Footer modalFooter>
          <div className="w-full flex items-center">
            <div className={cx(lang === "ar" ? "mr-auto" : "ml-auto")}>
              <Stack direction="row">
                <Button data-testid="btn-save-publish-product" kind="primary">
                  {(updateProductLoading || updateProductByBranchLoading) && (
                    <span className="w-4 h-4 mr-2">
                      <SpinnerAlt color="primary-base" />
                    </span>
                  )}

                  {!selectedBranchId ? (
                    <Text value={translations.SAVE} />
                  ) : isPublished ? (
                    <Text value={translations.UNPUBLISH} />
                  ) : (
                    <Text value={translations.PUBLISH} />
                  )}
                </Button>
              </Stack>
            </div>
          </div>
        </Footer>
      </Form>
    </Formik>
  )
}

