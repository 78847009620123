import { gql } from '@apollo/client';

const GEO_AREAS = gql`
  query($storeId: String!, $range: AnalyticsRange!, $branchId: String, $page: Int!, $limit: Int!, $filter: String) {
    geoAnalyticsAreas(
      restaurantId: $storeId
      range: $range
      branchId: $branchId
      page: $page
      limit: $limit
      filter: $filter
    ) {
      startDate
      endDate
      data {
        areas {
          id
          titleEn
          titleAr
          totalSales
          totalOrders
          totalCustomers
          avgOrderValue
          zoneName
        }
        meta {
          totalCount
          totalPages
        }
      }
    }
  }
`;

export default GEO_AREAS;
