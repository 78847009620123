import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import { AREA_NOT_MATCHING, LOAD_MORE } from 'constants/translations';
import { context as localeContext } from 'context/locale';
import { Text } from 'components/service';
import { SpinnerAlt } from 'components/kit';
import SuggestedZoneItem from './SuggestedZoneItem';
import { suggestedZonesTypeUtils } from '../../utils';

const SuggestedZones = ({
  title,
  type,
  suggestedData,
  setSuggestedData,
  zoneController,
  setZoneController,
  templatesParams,
  setTemplatesParams,
  bulkZones,
  setBulkZones,
  fetchError,
  fetchLoading,
}) => {
  const { lang, direction } = useContext(localeContext);
  const [disableLoadMore, setDisableLoadMore] = useState(false);

  const handleSlideToggle = () => {
    if (type === suggestedZonesTypeUtils.AREAS) {
      setZoneController(prevState => ({
        ...prevState,
        [suggestedZonesTypeUtils.AREAS]: !zoneController[type],
        [suggestedZonesTypeUtils.SUGGESTED]: false,
      }));
    }

    if (type === suggestedZonesTypeUtils.SUGGESTED) {
      setZoneController(prevState => ({
        ...prevState,
        [suggestedZonesTypeUtils.SUGGESTED]: !zoneController[type],
        [suggestedZonesTypeUtils.AREAS]: false,
      }));
    }
  };

  // - set suggested zones expanded by default
  useEffect(() => {
    if (!zoneController[suggestedZonesTypeUtils.AREAS]) {
      setZoneController(prevState => ({
        ...prevState,
        [suggestedZonesTypeUtils.SUGGESTED]: true,
        [suggestedZonesTypeUtils.AREAS]: false,
      }));
    }
  }, []);

  useEffect(() => {
    if (templatesParams.searchValue) {
      setZoneController(prevState => ({
        ...prevState,
        [suggestedZonesTypeUtils.AREAS]: true,
        [suggestedZonesTypeUtils.SUGGESTED]: false,
      }));
    }
  }, [templatesParams.searchValue]);

  // - handle pagination
  const paginationHandle = () => {
    setTemplatesParams(prevState => ({ ...prevState, currentPage: templatesParams.currentPage + 1 }));
  };

  useEffect(() => {
    if (templatesParams.totalPages === templatesParams.currentPage || templatesParams.totalPages === 0) {
      setDisableLoadMore(true);
    } else {
      setDisableLoadMore(false);
    }
  }, [templatesParams]);

  if (fetchError) return null;
  if (suggestedData.features.length === 0 && fetchLoading)
    return (
      <div className="flex justify-center items-center">
        <SpinnerAlt color="primary-base" />
      </div>
    );

  return (
    <div className="w-full bg-white border border-gray-300 mb-3" style={{ direction }}>
      <div className="flex items-center">
        <div
          className="py-2 px-2 w-full cursor-pointer flex items-center select-none"
          aria-hidden="true"
          onClick={handleSlideToggle}
        >
          <div>{title}</div>
          <i className={cx('material-icons', lang === 'ar' ? 'mr-auto' : 'ml-auto')}>
            {!zoneController[type] ? 'expand_more' : 'expand_less'}
          </i>
        </div>
      </div>
      {zoneController[type] && (
        <div className="flex flex-col overflow-auto max-h-40">
          {suggestedData.features.length !== 0 ? (
            <>
              {suggestedData.features.map(item => (
                <SuggestedZoneItem
                  key={`${item.properties.id}${item.properties.area_title_en}`}
                  item={item}
                  setSuggestedData={setSuggestedData}
                  zoneController={zoneController}
                  setZoneController={setZoneController}
                  bulkZones={bulkZones}
                  setBulkZones={setBulkZones}
                />
              ))}
              {fetchLoading && (
                <div className="flex-1 flex flex-row justify-center items-center m-0 px-4 py-3">
                  <SpinnerAlt color="primary-base" />
                </div>
              )}
            </>
          ) : (
            <Text className="m-0 px-4 py-3 text-xs" value={AREA_NOT_MATCHING} />
          )}
          {!disableLoadMore && !fetchLoading && (
            <div className="w-full flex-1 m-0 px-4 py-3 text-center" onClick={paginationHandle} aria-hidden="true">
              <Text className="text-xs cursor-pointer text-primary-base" value={LOAD_MORE} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

SuggestedZones.propTypes = {
  title: PropTypes.element.isRequired,
  type: PropTypes.string.isRequired,
  setSuggestedData: PropTypes.func.isRequired,
  zoneController: PropTypes.shape({
    enableDrawerMode: PropTypes.bool,
    showDrawerMode: PropTypes.bool,
    selectedBranch: PropTypes.shape({
      id: PropTypes.number,
      color: PropTypes.string,
      position: PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number,
      }),
    }),
    drawerMode: PropTypes.string,
    circleShape: PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.string,
    }),
  }).isRequired,
  setZoneController: PropTypes.func.isRequired,
  suggestedData: PropTypes.shape({
    features: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        geometry: PropTypes.shape({
          type: PropTypes.string,
          coordinates: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))),
        }),
      }),
    ),
  }).isRequired,
  templatesParams: PropTypes.shape({
    pageSize: PropTypes.number,
    totalPages: PropTypes.number,
    currentPage: PropTypes.number,
    searchValue: PropTypes.string,
  }).isRequired,
  setTemplatesParams: PropTypes.func.isRequired,
  bulkZones: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
    }),
  ).isRequired,
  setBulkZones: PropTypes.func.isRequired,
  fetchError: PropTypes.string,
  fetchLoading: PropTypes.bool,
};

export default SuggestedZones;
