import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { ContentSwitcher, Tag } from '@zydalabs/zac-react';

import { Text } from 'components/service';
import { Label, Row } from 'components/form/generic';
import * as translations from 'constants/translations';
import { ReactComponent as UpgradeRocketIcon } from 'assets/upgrade-rocket.svg';

const ItemTrackingBlocked = ({ lang, inTrackingPage = false }) => {
  const componenetClassName = 'py-4 text-sm text-gray-700';
  const iconContent = [
    {
      title: <Text value={translations.TRACKING_NONE} />,
      component: <Text className={componenetClassName} value={translations.NONE_DESCRIPTION} />,
    },
    {
      title: <Text value={translations.TRACKING_INVENTORY} />,
      component: <Text className={componenetClassName} value={translations.INVENTORY_DESCRIPTION} />,
    },
    {
      title: <Text value={translations.TRACKING_CAPACITY} />,
      component: <Text className={componenetClassName} value={translations.CAPACITY_DESCRIPTION} />,
    },
  ];

  return (
    <div>
      <Row className={cx(inTrackingPage ? 'pb-4' : 'py-4')}>
        <Text
          value={translations.ITEM_TRACKING}
          className={cx('font-medium text-base flex', lang === 'ar' && 'text-right')}
          postfixElement={
            <div className="px-2">
              <Tag
                color="orange"
                inner={{
                  text: <Text value={translations.GET_WITH_STANDARD} />,
                  icon: <UpgradeRocketIcon />,
                }}
                size="small"
              />
            </div>
          }
        />
      </Row>
      <Row className="p">
        <Label htmlFor="primaryColor" trackedItem>
          <ContentSwitcher tabs={iconContent} />
        </Label>
      </Row>
    </div>
  );
};

ItemTrackingBlocked.propTypes = {
  lang: PropTypes.oneOf(['en', 'ar']).isRequired,
  inTrackingPage: PropTypes.bool,
};

export default ItemTrackingBlocked;
