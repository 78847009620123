import React, { useState, useContext, useEffect } from "react"
import * as R from "ramda"
import {
  useMutation,
  useQuery,
  useLazyQuery,
  useApolloClient,
} from "@apollo/client"
import { SortableContainer, SortableElement } from "react-sortable-hoc"
import cx from "classnames"
import arrayMove from "array-move"
import {
  Box,
  Button,
  Stack,
  SpinnerAlt,
  DragHandle,
  LoadingContainer,
  Spinner,
} from "components/kit"
import { Field, Text } from "components/service"
import {
  Input,
  MultiCheckbox,
  Languages,
  Stepper,
  CheckboxAlt,
} from "components/form/elements"
import { useManyQueries } from "hooks/index"
import { Formik } from "formik"
import { context as localeContext } from "context/locale"
import * as translations from "constants/translations"
import { Row, Label, Container } from "components/form/generic"
import { context as notificationsContext } from "context/notifications"
import { ReactComponent as Edit } from "assets/edit.svg"
import { ReactComponent as TrashIcon } from "assets/trash-icon.svg"
import { ReactComponent as ShevronBlueDownIcon } from "assets/icons-chevron-blue-down.svg"
import { ReactComponent as ShevronBlueUpIcon } from "assets/icons-chevron-blue-up.svg"
import Table from "./Table"
import InsertGroup from "./InsertGroup"
import DeleteModal from "./DeleteModal"
import * as schemas from "./schemas"
import * as data from "./data"
import * as utils from "./utils"

export default ({
  open,
  closeModal,
  storeId,
  isMenu = false,
  productId,
  variants,
  refetchProduct,
  query,
}) => {
  const notifications = useContext(notificationsContext)

  const [createOptionGroup] = useMutation(schemas.CREATE_OPTION_GROUP, {
    variables: { restaurantId: storeId },
    update: utils.updateCacheAfterCreate(query, storeId, productId),
  })

  const [updateOptionGroup] = useMutation(schemas.UPDATE_OPTION_GROUP, {
    variables: { restaurantId: storeId, productId },
  })
  const [deleteOptionGroupForVariant, { loadingRemove }] = useMutation(
    schemas.DELETE_OPTION_GROUP_VARIANT,
    {
      variables: { restaurantId: storeId },
      update: utils.updateCacheAfterDelete(query, storeId, productId),
    }
  )
  const hasOptionGroups = variants?.some(
    variant => variant.propertySections && variant.propertySections.length
  )

  return (
    <>
      <Box
        isMenu={isMenu}
        title={<Text value={translations.OPTION_GROUPS} />}
        subtitle={<Text value={translations.IF_YOU_WANT_ADD_EXTRA} />}
        action={
          <>
            <Row direction="row">
              <Button
                kind="tertiary"
                textColor="text-black"
                data-testid="product-options-insertGroup"
                onClick={e => {
                  e.preventDefault()
                  open({
                    title: <Text value={translations.INSERT_GROUP} />,
                    subTitle: (
                      <Text
                        className="mb-2"
                        value={translations.INSERT_GROUP_SUBTITLE}
                      />
                    ),
                    body: (
                      <InsertGroup
                        storeId={storeId}
                        variants={variants}
                        productId={productId}
                        onCancel={closeModal}
                        onSubmit={async data => {
                          await updateOptionGroup({
                            variables: {
                              productId: productId,
                              restaurantId: storeId,
                              propertyId: data.optionsGroup,
                              variantIds: [...data.propertySections.variantIds, ...data.variantIds],
                              titleAr: data.propertySections.titleAr,
                              titleEn: data.propertySections.titleEn,
                              propertyValues: data.propertySections.propertyValues.map(
                                R.omit(["__typename"])
                              ),
                              multipleAddOnsEnabled:
                                data.multipleAddOnsEnabled,
                              minQuantity: data.minQuantity,
                              maxQuantity:
                                data.maxQuantity === 0
                                  ? null
                                  : data.maxQuantity,
                            },
                          })

                          await refetchProduct()

                          closeModal()
                        }}
                      />
                    ),
                    size: "max-w-xl",
                  })
                }}
              >
                <Text value={translations.INSERT_GROUP} />
              </Button>
              <Button
                textColor="text-black"
                data-testid="product-options-createGroup"
                kind="tertiary"
                onClick={e => {
                  e.preventDefault()
                  open({
                    title: <Text value={translations.ADD_GROUP} />,
                    subTitle: (
                      <Text
                        className="text-gray-600 text-xs mb-2"
                        value={translations.IF_YOU_WANT_ADD_EXTRA}
                      />
                    ),
                    body: (
                      <Entry
                        variants={variants}
                        submitTitle={<Text value={translations.ADD_GROUP} />}
                        onSubmit={async (data, actions) => {
                          try {
                            if (
                              data.maxQuantity > data.propertyValues.length &&
                              data.multipleAddOnsEnabled === false
                            ) {
                              notifications.show(
                                <Text
                                  value={
                                    translations.MAX_QUANTITY_LESS_THAN_OPTIONS
                                  }
                                />,
                                "error"
                              )
                            } else if (
                              data.maxQuantity < data.minQuantity &&
                              data.maxQuantity > 0
                            ) {
                              notifications.show(
                                <Text
                                  value={
                                    translations.MIN_QUANTITY_LESS_THAN_MAX_QUANTITY
                                  }
                                />,
                                "error"
                              )
                            } else {
                              await createOptionGroup({ variables: data })
                            }
                          } catch (err) {
                            const message = utils.handleSubmissionErrors(err)
                            notifications.show(message, "error")
                            actions.setSubmitting(false)
                            return false
                          }
                          closeModal()
                        }}
                        onCancel={closeModal}
                      />
                    ),
                    size: "max-w-xl",
                  })
                }}
              >
                <Text value={translations.ADD_GROUP} />
              </Button>
            </Row>
          </>
        }
        body={
          hasOptionGroups && (
            <List
              query={query}
              variants={variants}
              productId={productId}
              storeId={storeId}
              onRemove={async (groupId, variantId) => {
                await deleteOptionGroupForVariant({
                  variables: { propertyId: groupId, variantId: variantId },
                })
              }}
              loadingRemove={loadingRemove}
              open={open}
              close={closeModal}
              updateOptionGroup={updateOptionGroup}
            />
          )
        }
      />
      {!hasOptionGroups && (
        <Text
          className="text-gray-600 text-xs pt-1"
          value={translations.YOU_CAN_ADD_EXTRA}
        />
      )}
    </>
  )
}

const List = ({ query, productId, storeId, variants, ...rest }) => {
  const [updatePositions] = useMutation(schemas.UPDATE_POSITIONS, {
    variables: {
      restaurantId: storeId,
    },
  })
  const [isSorting, setSorting] = useState(false)

  const client = useApolloClient()

  return (
    <LoadingContainer isLoading={isSorting}>
      {variants.map(variant => {
        return (
          <div key={variant.id} className="border p-3 mx-3 mb-3 flex flex-col">
            <div className="flex items-center font-semibold">
              <Text value={variant} />
            </div>
            <GroupsList
              useDragHandle
              onSortEnd={async ({ oldIndex, newIndex }) => {
                setSorting(true)

                const sorted = arrayMove(
                  variant.propertySections,
                  oldIndex,
                  newIndex
                )

                utils.replaceOptionGroups(
                  client,
                  query,
                  storeId,
                  productId,
                  variant.id,
                  sorted
                )

                const positions = sorted.map((item, i) => ({
                  propertyId: item.id,
                  position: i + 1,
                }))

                await updatePositions({
                  variables: {
                    variantId: variant.id,
                    positions,
                  },
                })

                setSorting(false)
              }}
              variant={variant}
              storeId={storeId}
              productId={productId}
              variants={variants}
              {...rest}
            />
          </div>
        )
      })}
    </LoadingContainer>
  )
}

const GroupsList = SortableContainer(
  ({
    variant,
    onRemove,
    storeId,
    loadingRemove,
    open,
    close,
    productId,
    query,
    variants,
    updateOptionGroup,
  }) => {
    const [isDisplay, setIsDisplay] = useState(false)
    return (
      <div>
        {!isDisplay
          ? variant.propertySections
              .slice(0, 2)
              .map((group, i) => (
                <OptionGroup
                  key={group.id}
                  index={i}
                  isDisplay={isDisplay}
                  {...group}
                  variantId={variant.id}
                  productId={productId}
                  onRemove={onRemove}
                  loadingRemove={loadingRemove}
                  open={open}
                  close={close}
                  storeId={storeId}
                  query={query}
                  variants={variants}
                  productId={productId}
                  updateOptionGroup={updateOptionGroup}
                />
              ))
          : variant.propertySections.map((group, i) => (
              <OptionGroup
                key={group.id}
                index={i}
                isDisplay={isDisplay}
                {...group}
                variantId={variant.id}
                productId={productId}
                onRemove={onRemove}
                loadingRemove={loadingRemove}
                open={open}
                close={close}
                storeId={storeId}
                query={query}
                variants={variants}
                productId={productId}
                updateOptionGroup={updateOptionGroup}
              />
            ))}
        {variant.propertySections.length > 2 && (
          <div className="flex items-center">
            <Text
              full={false}
              className="mt-4 cursor-pointer text-primary-base"
              value={
                isDisplay ? translations.SHOW_LESS : translations.SHOW_MORE
              }
              onClick={() => setIsDisplay(!isDisplay)}
            />
            <div className="mt-4">
              {isDisplay ? <ShevronBlueUpIcon /> : <ShevronBlueDownIcon />}
            </div>
          </div>
        )}
      </div>
    )
  }
)

const OptionGroup = SortableElement(
  ({
    variantId,
    productId,
    storeId,
    loadingRemove,
    open,
    close,
    onRemove,
    query,
    variants,
    updateOptionGroup,
    isDisplay,
    ...group
  }) => {
    const { translate, lang } = useContext(localeContext)
    const notifications = useContext(notificationsContext)

    const {
      loading: optionsLoading,
      data: optionsQuery,
      refetch: refetchOptions,
    } = useQuery(schemas.OPTIONS, {
      variables: {
        restaurantId: storeId,
        propertySectionId: group.id,
      },
    })

    const variantProperty = variants.find(variant => variant.id === variantId).properties.find(property => property.uuid === group.id)

    const sharedItems = group.menuItems
      .filter(item => item.id !== productId)
      .map(i => {
        return <Text prefix={"["} value={i} postfix={"]"} className="inline" />
      })

    return (
      <div className="border p-3 mt-3 flex items-center bg-white">
        {isDisplay && <DragHandle />}
        <div className={cx(lang === "ar" ? "mr-2" : "ml-2")}>
          <div className="inline text-gray-500 text-xs">
            <Text
              className="inline"
              value={
                variantProperty.minQuantity === 0
                  ? translations.OPTIONAL
                  : translations.REQUIRED
              }
            />
            {", "}
            <Text
              className="inline"
              value={translations.AT_LEAST_AT_MOST}
              payload={[
                variantProperty.minQuantity,
                variantProperty.maxQuantity ||
                  translations.UNLIMITED[lang === "en" ? 0 : 1],
              ]}
            />
          </div>
          <div className="">
            <span className="">{translate(group)}</span>
          </div>
        </div>
        <div className={cx(lang === "ar" ? "mr-auto" : "ml-auto")}>
          <Stack direction="row" className="items-center">
            <button
              type="button"
              onClick={async e => {
                open({
                  title: <Text value={translations.EDIT_GROUP} />,
                  subTitle: (
                    <Text
                      className="text-gray-600 text-xs mb-2"
                      value={translations.IF_YOU_WANT_ADD_EXTRA}
                    />
                  ),
                  body: (
                    <EditingEntry
                      storeId={storeId}
                      isEditing
                      query={query}
                      queryOptions={schemas.OPTIONS}
                      productId={productId}
                      optionGroup={variants
                        .find(variant => variant.id === variantId)
                        .propertySections.find(property => property.id === group.id)}
                      optionsQuery={optionsQuery && optionsQuery.propertyValues}
                      optionsLoading={optionsLoading}
                      refetchOptions={refetchOptions}
                      variants={variants}
                      variantId={variantId}
                      submitTitle={<Text value={translations.UPDATE_GROUP} />}
                      onSubmit={async (data, { setSubmitting }) => {
                        try {
                          if (
                            data.propertyValues &&
                            data.propertyValues.every(
                              item => item._destroy === "1"
                            )
                          ) {
                            notifications.show(
                              <Text
                                value={translations.OPTION_GROUPS_ONE_OPTION}
                              />,
                              "error"
                            )
                            setSubmitting(false)
                            return
                          }

                          if (
                            data.maxQuantity > data.propertyValues.length &&
                            data.multipleAddOnsEnabled === false
                          ) {
                            notifications.show(
                              <Text
                                value={
                                  translations.MAX_QUANTITY_LESS_THAN_OPTIONS
                                }
                              />,
                              "error"
                            )
                          } else if (
                            data.maxQuantity < data.minQuantity &&
                            data.maxQuantity > 0
                          ) {
                            notifications.show(
                              <Text
                                value={
                                  translations.MIN_QUANTITY_LESS_THAN_MAX_QUANTITY
                                }
                              />,
                              "error"
                            )
                          } else {
                            await updateOptionGroup({
                              variables: {
                                ...data,
                                multipleAddOnsEnabled:
                                  data.multipleAddOnsEnabled,
                                propertyId: group.id,
                                propertyValues: data.propertyValues.map(
                                  R.omit(["__typename"])
                                ),
                              },
                            })
                            await refetchOptions()
                          }
                        } catch (err) {
                          const message = utils.handleSubmissionErrors(err)
                          notifications.show(message, "error")
                          setSubmitting(false)
                          return false
                        }
                        close()
                      }}
                      onCancel={() => close()}
                    />
                  ),
                  size: "max-w-xl",
                  loading: optionsLoading,
                })
              }}
            >
              <Edit />
            </button>
            <button
              type="button"
              onClick={() => {
                open({
                  title: <Text value={translations.DELETE_GROUP} />,
                  body: (
                    <DeleteModal
                      isDeleting={loadingRemove}
                      onClick={async () => {
                        await onRemove(group.id, variantId)
                        close()
                      }}
                      onClose={close}
                    />
                  ),
                  size: "max-w-md",
                })
              }}
            >
              <TrashIcon />
            </button>
          </Stack>
        </div>
      </div>
    )
  }
)

const EditingEntry = ({
  optionGroup,
  optionsQuery,
  optionsLoading,
  variantId,
  refetchOptions,
  variants,
  ...props
}) => {

  const {
    uuid,
    minQuantity,
    maxQuantity,
    multipleAddOnsEnabled,
  } = variants.find(variant => variant.id === variantId).properties.find(property => property.uuid === optionGroup.id)

  return (
    <Entry
      {...props}
      variantId={variantId}
      optionsLoading={optionsLoading}
      refetchOptions={refetchOptions}
      variants={variants}
      values={{
        ...optionGroup,
        optionsQuery,
        minQuantity,
        maxQuantity,
        multipleAddOnsEnabled,
        variantPropertyId: uuid,
      }}
    />
  )
}

const Entry = ({
  isEditing,
  storeId,
  values,
  queryOptions,
  variants,
  onSubmit,
  onCancel,
  optionsLoading,
}) => {
  const { lang, direction, translate } = useContext(localeContext)
  const notifications = useContext(notificationsContext)

  const [updatePositions] = useMutation(schemas.SORT_OPTIONS, {
    variables: {
      restaurantId: storeId,
      propertySectionId: isEditing && values.id,
    },
  })
  const [isSorting, setSorting] = useState(false)
  const client = useApolloClient()

  return (
    <Formik
      onSubmit={onSubmit}
      validationSchema={data.validationSchema}
      key={isEditing && JSON.stringify(values.optionsQuery)}
      initialValues={R.mergeDeepRight(
        data.initialValues,
        {
          ...values,
          propertyValues: values && values.optionsQuery,
        } || {}
      )}
    >
      {({ values, isSubmitting, submitForm, setFieldValue, ...rest }) => (
        <div className="p-4">
          {optionsLoading ? (
            <Spinner />
          ) : (
            <LoadingContainer isLoading={isSorting}>
              <Container>
                <Text
                  value={translations.GROUP_NAME}
                  className="text-sm font-semibold -mb-4"
                />
                <Row>
                  <Label title={<Text value={translations.ENGLISH} />}>
                    <Field
                      type="text"
                      name="titleEn"
                      testId="product-groupName-en"
                      placeholder="Ex: Drinks"
                      component={Input}
                    />
                  </Label>
                  <Label title={<Text value={translations.ARABIC} />}>
                    <Field
                      direction="rtl"
                      type="text"
                      name="titleAr"
                      testId="product-groupName-ar"
                      placeholder="مثال: المشروبات"
                      component={Input}
                    />
                  </Label>
                </Row>
                <div style={{ direction }}>
                  <Field
                    title={<Text value={translations.LINK_GROUP} />}
                    name="variantIds"
                    testIdOpenList="product-open-createGroup"
                    testIdOptionsList="product-createGroup-options"
                    testIdClearAll="product-createGroup-clear"
                    testIdSelectAll="product-createGroup-selectAll"
                    placeholder={
                      lang === "en"
                        ? translations.CHOOSE_VARIANT[0]
                        : translations.CHOOSE_VARIANT[1]
                    }
                    items={variants.map(item => ({
                      ...item,
                      id: +item.id,
                    }))}
                    component={MultiCheckbox}
                  />
                </div>
                <Row direction="col">
                  <Text
                    className="font-semibold"
                    value={translations.SET_OPTIONS_LIMIT}
                  />
                  <Text
                    className="text-gray-600 text-xs"
                    value={translations.SET_MIN_MAX_OPTIONS}
                  />
                  <div className="bg-zyda-grey-50 p-2" style={{ direction }}>
                    <Field
                      name="multipleAddOnsEnabled"
                      title={
                        <Text
                          className="text-gray-600"
                          value={translations.MULTIPLE_ADD_ON_CHECKBOX}
                        />
                      }
                      component={CheckboxAlt}
                    />
                  </div>
                </Row>
                <Stack direction="col" className="flex md:flex-row">
                  <span
                    className={cx("flex flex-col", lang === "en" && "md:mr-4")}
                  >
                    <Text
                      value={translations.MINIMUM_SELECTION}
                      className="text-xs mb-2"
                    />
                    <Field
                      min={0}
                      type="number"
                      name="minQuantity"
                      testId="product-createGroup-min"
                      width="full"
                      component={Stepper}
                      validation={n => n >= 0}
                    />
                  </span>
                  <span
                    className={cx("flex flex-col", lang === "ar" && "md:ml-4")}
                  >
                    <Text
                      value={translations.MAXIMUM_SELECTION}
                      className="text-xs mb-2"
                    />
                    <Field
                      min={0}
                      type="number"
                      name="maxQuantity"
                      testId="product-createGroup-max"
                      width="full"
                      component={Stepper}
                      validation={n => n >= 0}
                    />
                  </span>
                </Stack>
                <div>
                  <Text
                    value={translations.GROUP_OPTIONS}
                    className="font-semibold"
                  />
                  <Text
                    value={translations.MULTIPLE_OPTIONS_GROUP}
                    className="text-xs text-gray-600 mb-4"
                  />
                  <Row direction="col" className="-mx-4 mb-20 md:mb-0">
                    <Field
                      useDragHandle
                      selectedLang={values.lang}
                      isEditing={isEditing}
                      values={values.propertyValues}
                      setErrorField={rest.setFieldError}
                      setNewValues={setFieldValue}
                      onSortEnd={async ({ oldIndex, newIndex }) => {
                        setSorting(true)

                        const sorted = arrayMove(
                          values.propertyValues,
                          oldIndex,
                          newIndex
                        )

                        utils.replaceOptions(
                          client,
                          queryOptions,
                          storeId,
                          values.id,
                          sorted
                        )

                        const formattedOptionsArr = sorted.map((item, i) => ({
                          id: item.id,
                          attributes: {
                            position: i,
                          },
                        }))
                        await updatePositions({
                          variables: {
                            sort: formattedOptionsArr,
                          },
                        })

                        setFieldValue("propertyValues", sorted)

                        setSorting(false)
                      }}
                      name="propertyValues"
                      submitFormGroup={() => {
                        if (!values.propertyValues) {
                          notifications.show(
                            <Text
                              value={translations.OPTION_GROUPS_ONE_OPTION}
                            />,
                            "error"
                          )
                          rest.setSubmitting(false)
                          return
                        }
                        submitForm()
                      }}
                      isSubmitting={isSubmitting}
                      onCancel={onCancel}
                      component={Table}
                    />
                  </Row>
                </div>
              </Container>
            </LoadingContainer>
          )}
        </div>
      )}
    </Formik>
  )
}
