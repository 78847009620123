import React, { useState, useRef, useContext } from "react"
import { SortableContainer, SortableElement } from "react-sortable-hoc"
import { DragHandle } from "components/kit"
import { Text } from "components/service"
import * as translations from "constants/translations"
import { useClickOutside } from "hooks/index"
import cx from "classnames"
import { context as userContext } from "context/user"
import { context as localeContext } from "context/locale"
import { ReactComponent as TrashIcon } from "assets/trash-icon.svg"
import CreationForm from "./CreationForm"

export default SortableContainer(
  ({
    submitFormGroup,
    onCancel,
    selectedLang,
    setErrorField,
    value = [],
    setNewValues,
    isEditing,
    name,
    isSubmitting,
    onChange,
  }) => {
    const { selectedStore: selected, isPosCourier: isPosType } = useContext(userContext)
    const { lang, direction } = useContext(localeContext)
    const currency = selected.currency
    const headCellClassName =
      lang === "ar"
        ? "flex-1 text-right font-semibold"
        : "flex-1 text-left font-semibold"

    return (
      <>
        <div style={{ direction }}>
          <div className="flex justify-between items-center bg-zyda-grey-50 p-2">
            <div className={headCellClassName}>
              <Text
                value={translations.OPTION_NAME}
                className="text-sm font-light text-gray-500"
              />
            </div>
            <div className={headCellClassName}></div>
            {isPosType && (
              <div className={headCellClassName}>
                <Text
                  value={translations.External_ID}
                  className="text-sm font-light text-gray-500"
                />
              </div>
            )}
            <div className={headCellClassName}>
              <Text
                value={translations.PRICE_CURRENCY}
                payload={lang === "en" ? currency.titleEn : currency.titleAr}
                className="text-sm font-light text-gray-500"
              />
            </div>
          </div>
          {value && (
          <div className="overflow-x-auto">
            {value.map((item, i) => (
              <Row
                key={item.id}
                index={i}
                idx={i}
                item={item}
                value={value}
                lang={lang}
                name={name}
                isEditing={isEditing}
                onChange={onChange}
                setNewValues={setNewValues}
                isPosType={isPosType}
              />
            ))}
          </div>
          )}
        </div>
        <CreationForm
          selectedLang={selectedLang}
          setErrorField={setErrorField}
          submitFormGroup={submitFormGroup}
          isEntrySubmitting={isSubmitting}
          onSubmit={item => {
            setNewValues('maxQuantity', value.length + 1)
            onChange({
              target: {
                name,
                value: [...value, item],
              },
            })
          }
          }
        />
      </>
    )
  }
)

const Row = SortableElement(
  ({
    idx: i,
    item,
    setNewValues,
    value,
    lang,
    name,
    isEditing,
    onChange,
    isPosType,
  }) => {
    const cellClassName = "py-3"
    return (
      <div
        className={cx(
          !!item._destroy && "hidden",
          "flex items-center bg-white border-b border-gray-400 z-20"
        )}
        key={i}
      >
        <div className={cx(cellClassName, "")}>
          <div className="flex items-center">
            {isEditing && <DragHandle />}
            <Input
              onSave={value => {
                setNewValues(`propertyValues[${i}].titleEn`, value)
              }}
              title={item.titleEn}
              renderInput={({ value, onChange }) => (
                <input
                  type="text"
                  className="outline-none px-2 h-10 border border-gray-300 w-40 md:w-full"
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </div>
        </div>
        <div className={cx(cellClassName)}>
          <div className="flex items-center">
            <Input
              onSave={value => {
                setNewValues(`propertyValues[${i}].titleAr`, value)
              }}
              title={item.titleAr}
              renderInput={({ value, onChange }) => (
                <input
                  type="text"
                  className="outline-none px-2 h-10 border border-gray-300 w-40 md:w-full"
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </div>
        </div>
        {isPosType && (
          <div className={cx(cellClassName)}>
            <div className="flex items-center">
              <Input
                onSave={value => {
                  setNewValues(`propertyValues[${i}].externalId`, value)
                }}
                title={item.externalId}
                renderInput={({ value, onChange }) => (
                  <input
                    type="text"
                    className="outline-none px-2 h-10 border border-gray-300 w-40 md:w-full"
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </div>
          </div>
        )}
        <div className={cx(cellClassName)}>
          <Input
            onSave={price => {
              setNewValues(`propertyValues[${i}].price`, parseFloat(price))
            }}
            title={item.price}
            renderInput={({ value, onChange }) => (
              <input
                type="number"
                className="outline-none px-2 h-10 border border-gray-300 w-40 md:w-full"
                value={value}
                onChange={onChange}
              />
            )}
          />
        </div>
        <div
          className={cx(
            cellClassName,
            lang === "en" ? "text-right" : "text-left"
          )}
        >
          <button
            onClick={() => {
              if (isEditing) {
                onChange({
                  target: {
                    name,
                    value: value.map((item, index) =>
                      index !== i
                        ? item
                        : {
                            ...item,
                            _destroy: "1",
                          }
                    ),
                  },
                })
              } else {
                onChange({
                  target: {
                    name,
                    value: value.filter((_, index) => index !== i),
                  },
                })
              }
            }}
            type="button"
          >
            <TrashIcon />
          </button>
        </div>
      </div>
    )
  }
)

const Input = ({ title, onSave, renderInput }) => {
  const [isFocused, setFocused] = useState(false)
  const { lang } = useContext(localeContext)
  const ref = useRef()
  useClickOutside(ref, async () => {
    setFocused(false)
  })

  return (
    <div ref={ref}>
      {/* {!isFocused ? (
        <span
          className="inline-block py-3 -my-3 px-2 w-40 md:w-full"
          onClick={() => setFocused(true)}
        >
          {title || "-----"}
        </span>
      ) : ( */}
      <div className={cx("flex items-center px-2")}>
        {renderInput({ value: title, onChange: e => onSave(e.target.value) })}
      </div>
      {/* )} */}
    </div>
  )
}
