import React from 'react';
import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';

import { GIFT_RECEIPT, VAT_ID } from 'constants/translations';

const ThermalInvoiceTitle = ({
  lang,
  direction,
  translate,
  isGift,
  order,
  store,
  name,
  legalData,
  logoPlaceholder,
}) => {
  const { logoUrl, timeZone } = store || {};
  const { createdAt, number } = order || {};
  const { companyNameEn, companyNameAr, vatIdNumber } = legalData || {};

  return (
    <div>
      <div className={cx('flex flex-col items-start mb-4', lang === 'ar' ? 'items-end' : 'items-start')}>
        <img src={logoUrl || logoPlaceholder} alt="logo" className="h-auto w-16 mr-2" />
        <h2 className="text-base font-medium text-zyda-grey-600">{translate(name)}</h2>
        {(companyNameAr || companyNameEn) && <span className="text-sm">{companyNameAr || companyNameEn}</span>}
        {vatIdNumber && (
          <>
            <span className="text-xs font-medium" style={{ direction }}>
              {translate(VAT_ID)}:
            </span>
            <span className="text-xs font-bold" style={{ direction }}>
              {vatIdNumber}
            </span>
          </>
        )}
        <span className="block text-xs font-medium mt-2">
          {moment(createdAt)
            .tz(timeZone)
            .locale(lang === 'ar' ? 'ar' : 'en-gb')
            .format('DD MMM YYYY, h:mm A')}
        </span>
        <h2 className="text-base font-medium text-zyda-grey-600">
          <span className="block text-base">#{number}</span>
        </h2>
      </div>
      {isGift && (
        <div className="text-xl mb-2 font-medium" style={{ direction }}>
          {translate(GIFT_RECEIPT)}
        </div>
      )}
    </div>
  );
};

ThermalInvoiceTitle.propTypes = {
  lang: PropTypes.string.isRequired,
  translate: PropTypes.func,
  direction: PropTypes.string.isRequired,
  name: PropTypes.string,
  isGift: PropTypes.bool,
  store: PropTypes.shape({ logoUrl: PropTypes.string, timeZone: PropTypes.string }),
  logoPlaceholder: PropTypes.string,
  order: PropTypes.shape({ createdAt: PropTypes.string, number: PropTypes.number }),
  legalData: PropTypes.shape({
    companyNameEn: PropTypes.string,
    companyNameAr: PropTypes.string,
    vatIdNumber: PropTypes.string,
  }),
};
export default ThermalInvoiceTitle;
