import React, { useState, useContext, useRef, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import cx from 'classnames';
import { navigate } from '@reach/router';

import { exportStockUrl } from 'rest.js';
import { Layout, Breadcrumbs } from 'components/common/dashboard';
import { Text } from 'components/service';
import { Stack, More, DropDown } from 'components/kit';
import * as translations from 'constants/translations';
import { replaceParams } from 'utils/index';
import * as paths from 'paths.js';
import { Input } from 'components/form/elements';
import { Modal, Spinner } from 'components/kit';
import { context as localeContext } from 'context/locale';
import { ReactComponent as SearchIcon } from 'assets/search.svg';
import { context as userContext } from 'context/user';
import * as schemas from '../../schemas';
import { useSelectedStore, useQueryState, useMobile, useScrollBottom } from 'hooks';
import { itemTrackingStatusData, trackingData, ALL } from '../../kits/dropDownData';
import Variant from './Variant';
import ImportStock from '../../ImportStock';
import MobileSearchBar from './MobileSearchBar';
import { ITEM_TRACKING_LINKS } from 'constants/helperLinks';

const ItemTracking = () => {
  const [page, setPage] = useState(1);
  const { lang, direction } = useContext(localeContext);
  const { branches, trackItemType, setTrackItemType } = useContext(userContext);
  const isMobile = useMobile();
  const storeId = useSelectedStore();
  const [totalStocks, setTotalStocks] = useState([]);
  const [stocks, setStocks] = useState([]);
  const [categories, setCategories] = useState([]);
  const [edittedVariant, setEdittedVariant] = useState(null);
  const [loadingEditted, setLoadingEditted] = useState(false);
  const searchTimeout = useRef();
  const LIMIT = 10;
  const isSingleBranch = branches && branches.length === 1;
  const [query, setQuery] = useQueryState({
    page: page,
    limit: LIMIT,
    branchId: branches ? branches[0].id : null,
    categoryId: 'all',
    status: 'all',
    trackingType: 'all',
  });
  const stockVariables = {
    storeId,
    branchId: parseInt(query.branchId),
    page: parseInt(query.page),
    limit: parseInt(query.limit),
    title: query.title ? query.title : null,
    categoryId: query.categoryId === 'all' ? null : query.categoryId,
    status: query.status === 'all' ? null : query.status,
    trackingType: query.trackingType === 'all' ? '' : query.trackingType,
  };
  const { data: dataStocks, loading: loadingStocks } = useQuery(schemas.STOCKS, {
    variables: stockVariables,
    skip: !branches,
  });
  const { data: categoriesRes, loading: loadingCat } = useQuery(schemas.CATEGORIES, {
    variables: {
      storeId,
    },
  });

  useEffect(() => {
    setQuery({ page: 1 });
  }, []);

  useEffect(() => {
    if (trackItemType) {
      window.location.reload(trackItemType);
      setTrackItemType(false);
    }
  }, [trackItemType]);

  useEffect(() => {
    !loadingStocks && dataStocks && dataStocks.stocks && setStocks(dataStocks.stocks.stocks);
  }, [
    stocks,
    dataStocks,
    query.branchId,
    query.categoryId,
    query.title,
    query.page,
    query.status,
    query.trackingType,
    loadingStocks,
  ]);

  useEffect(() => {
    if (!loadingEditted) {
      setTotalStocks([...totalStocks, ...stocks]);
    }
  }, [stocks]);

  useEffect(() => {
    if (loadingEditted) {
      if (edittedVariant) {
        setTotalStocks(
          totalStocks.map(singleVariant =>
            singleVariant?.variantId === edittedVariant?.variantId ? edittedVariant : singleVariant,
          ),
        );
        setEdittedVariant(null);
        setLoadingEditted(false);
      }
    }
  }, [edittedVariant]);

  useEffect(() => {
    !loadingCat &&
      categoriesRes &&
      categoriesRes.menuSections &&
      setCategories(categoriesRes.menuSections.menuSections);
  }, [categoriesRes, loadingCat]);

  const { loading: loadingSettings, data: settings } = useQuery(schemas.CHECK_STOCK_ENABLE, {
    variables: { storeId },
    fetchPolicy: 'no-cache',
  });

  const categoriesData = [
    {
      id: ALL,
      titleEn: isMobile ? translations.ALL_CATEGORIES[0] : translations.ALL[0],
      titleAr: isMobile ? translations.ALL_CATEGORIES[1] : translations.ALL[1],
    },
    ...categories,
  ].map(category => ({
    id: category.id,
    title: <Text value={category} />,
  }));

  const branchesData =
    branches &&
    [...branches].map(branch => ({
      id: branch.id,
      title: <Text value={branch} />,
    }));

  const statusData = itemTrackingStatusData();

  const variantTrackingData = trackingData();

  useScrollBottom(() => {
    if (loadingStocks || totalStocks.length === dataStocks?.stocks?.totalCount) return;

    if (!dataStocks?.stocks?.stocks.length || dataStocks?.stocks?.stocks.length < query.limit) return;

    const nextPage = page + 1;

    setPage(nextPage);

    setQuery({ page: page });
  });

  return (
    <Modal>
      {({ open, close }) => {
        return loadingCat && loadingSettings && loadingStocks ? (
          <Layout
            top={
              <Breadcrumbs
                reverse
                isMenu
                isOptions
                customTitle={<Text value={translations.ITEM_TRACKING} className="text-2xl font-bold" />}
              />
            }
          >
            <Spinner />
          </Layout>
        ) : (
          <Layout
            top={
              <Breadcrumbs
                isMenu
                menuTop={true}
                reverse
                isOptions
                helperLinks={ITEM_TRACKING_LINKS}
                customTitle={<Text value={translations.ITEM_TRACKING} className="text-2xl font-bold" full={false} />}
                right={
                  <div style={{ direction }}>
                    {settings && (
                      <Stack expandItem={false} direction="wrap">
                        <More
                          testIdBtn="stock-moreOptions"
                          size={'xs'}
                          iconColor="text-zyda-black"
                          iconSize="2xl"
                          items={[
                            {
                              title: <Text value={translations.SETTINGS} />,
                              onClick: () => {
                                navigate(
                                  replaceParams(paths.stockManagementGeneralSettings, {
                                    storeId: storeId,
                                  }),
                                );
                              },
                            },

                            {
                              title: <Text value={translations.IMPORT_STOCK} />,
                              onClick: () => {
                                open({
                                  title: <Text value={translations.IMPORT_STOCK} />,
                                  body: <ImportStock onCancel={close} />,
                                });
                              },
                            },

                            {
                              title: <Text value={translations.EXPORT_STOCK} />,
                              onClick: () => (window.location.href = exportStockUrl(storeId)),
                            },
                            {
                              title: <Text value={translations.VIEW_HISTORY} />,
                              onClick: () =>
                                navigate(
                                  replaceParams(paths.itemTrackingHistoryLog, {
                                    storeId: storeId,
                                  }),
                                ),
                            },
                          ]}
                        />
                      </Stack>
                    )}
                  </div>
                }
              />
            }
          >
            {settings && (
              <div className={cx('pb-1', !isMobile && 'h-10 flex items-center mb-8')}>
                {isMobile ? (
                  <MobileSearchBar
                    query={query}
                    settingQuery={setQuery}
                    settingTotalStocks={setTotalStocks}
                    categoriesData={categoriesData}
                    branchesData={branchesData}
                    dataStocksLength={dataStocks?.stocks?.totalCount}
                  />
                ) : (
                  <div className="w-full">
                    <div
                      className={cx(
                        'flex items-center w-full md:flex-wrap xl:flex-nowrap justify-between',
                        lang == 'ar' ? 'flex-row-reverse' : 'flex-row',
                      )}
                    >
                      <div className={cx('flex items-center', lang == 'ar' && 'flex-row-reverse')}>
                        <div className={cx(lang == 'ar' ? 'pl-2' : 'pr-2')}>
                          <DropDown
                            testId_openDropDown="stockManagement-locs-open"
                            testId_selectedData="stockManagement-locs-data"
                            testId_listData="stockManagement-list-locs"
                            float={'left'}
                            label={<Text value={translations.BRANCH} />}
                            optionSelected={query.branchId}
                            onSelect={option => {
                              setTotalStocks([]);
                              setPage(1);
                              setQuery({ page: 1, branchId: option });
                            }}
                            data={branchesData}
                            icon="keyboard_arrow_down"
                            position={lang === 'ar' ? 'right' : 'left'}
                            scrollable
                            wFull={true}
                            responsiveLabel
                            labelClassNames="text-gray-700"
                            isDisabled={isSingleBranch}
                          />
                        </div>
                        <div className={cx(lang == 'ar' ? 'pl-2' : 'pr-2')}>
                          <DropDown
                            testId_openDropDown="stockManagement-categs-open"
                            testId_selectedData="stockManagement-categs-data"
                            testId_listData="stockManagement-list-categs"
                            label={<Text value={translations.CATEGORY} />}
                            data={categoriesData}
                            optionSelected={query.categoryId}
                            onSelect={categoryId => {
                              setTotalStocks([]);
                              setPage(1);
                              setQuery({ page: 1, categoryId });
                            }}
                            icon="keyboard_arrow_down"
                            position={direction === 'rtl' ? 'right' : 'left'}
                            scrollable
                            labelClassNames="text-gray-700"
                          />
                        </div>
                        <div className={cx('w-60 my-2', isMobile && 'w-full md:w-auto')}>
                          <Input
                            isMenu
                            isStock
                            data-testid="stockManagement-search-stock"
                            type="text"
                            icon={<SearchIcon />}
                            search
                            placeholder={
                              lang === 'en'
                                ? translations.SEARCH_VARIANTS(dataStocks?.stocks?.totalCount)[0]
                                : translations.SEARCH_VARIANTS(dataStocks?.stocks?.totalCount)[1]
                            }
                            initialValue={query.title}
                            onChange={e => {
                              clearTimeout(searchTimeout.current);
                              const { value } = e.target;
                              searchTimeout.current = setTimeout(() => {
                                setTotalStocks([]);
                                setQuery({ page: 1, title: value });
                              }, 500);
                            }}
                          />
                        </div>
                      </div>
                      <div className={cx('my-2 w-1/2 md:w-auto mr-0', lang == 'ar' ? 'xl:ml-0' : 'xl:ml-auto')}>
                        <div
                          className={cx(
                            'flex  justify-between items-center',
                            lang == 'ar' ? 'flex-row-reverse' : 'md:flex-row',
                          )}
                        >
                          <div className={cx(' my-2 md:w-auto w-full', lang == 'ar' ? 'md:pr-0' : 'md:pl-0')}>
                            <DropDown
                              testId_openDropDown="stockManagement-open-statusList"
                              testId_selectedData="stockManagement-status-data"
                              testId_listData="stockManagement-list-status"
                              label={<Text value={translations.TRACKING_STOCK} />}
                              data={variantTrackingData}
                              icon="keyboard_arrow_down"
                              position={direction === 'rtl' ? 'right' : 'left'}
                              optionSelected={query.trackingType}
                              onSelect={option => {
                                setTotalStocks([]);
                                setPage(1);
                                setQuery({ page: 1, trackingType: option });
                              }}
                              labelClassNames="text-gray-700"
                            />
                          </div>
                          <div className={cx('my-2 md:w-auto w-full ', lang == 'ar' ? 'mr-2 md:pr-0' : 'ml-2 md:pl-0')}>
                            <DropDown
                              testId_openDropDown="stockManagement-open-statusList"
                              testId_selectedData="stockManagement-status-data"
                              testId_listData="stockManagement-list-status"
                              label={<Text value={translations.STATUS_STOCK} />}
                              data={statusData}
                              icon="keyboard_arrow_down"
                              position={direction === 'rtl' ? 'right' : 'left'}
                              optionSelected={query.status}
                              onSelect={option => {
                                setTotalStocks([]);
                                setPage(1);
                                setQuery({ page: 1, status: option });
                              }}
                              labelClassNames="text-gray-700"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            {!branches || !branches.length ? (
              <Text value={translations.STORE_HAS_NO_BRANCHES} className="px-5 py-1" />
            ) : !settings || loadingSettings || (loadingStocks && totalStocks?.length === 0) ? (
              <Spinner />
            ) : !totalStocks || !totalStocks.length ? (
              <Text value={translations.NO_PRODUCT_CATEGORY} className="px-5 py-1" />
            ) : (
              <div className="pb-20 flex flex-col">
                <div dir={direction} className="w-full">
                  {totalStocks.map(variant => (
                    <div key={variant.variantId} className="mb-4">
                      <Variant
                        editVariant={setEdittedVariant}
                        branchId={query.branchId}
                        storeBranches={branches}
                        setLoadingEditted={setLoadingEditted}
                        variant={variant}
                        stockVariables={stockVariables}
                        lowStock={settings.settings.lowStockThreshold}
                      />
                    </div>
                  ))}
                  {loadingStocks && <Spinner />}
                </div>
              </div>
            )}
          </Layout>
        );
      }}
    </Modal>
  );
};
export default ItemTracking;
