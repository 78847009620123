import React from 'react';
import noOrders from 'assets/no-orders.svg';
import { Text } from 'components/service';
import * as translations from 'constants/translations';

export default () => (
  <div className="flex flex-col items-center">
    <img className="mt-4" src={noOrders} alt="" />
    <span className="mb-2 mt-6 text-lg font-semibold px-1">
      <Text value={translations.NO_RESULTS} />{' '}
    </span>
    <p className="text-gray-700">
      <Text value={translations.COULDNT_FIND_ORDER} />
    </p>
  </div>
);
