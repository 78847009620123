import React, { useContext, useState } from 'react';
import axios from 'axios';
import * as R from 'ramda';
import { Mutation } from '@apollo/client/react/components';
import { useQuery, useMutation } from '@apollo/client';
import cx from 'classnames';
import { navigate } from '@reach/router';
import { parse } from 'query-string';

import { context as userContext } from 'context/user';
import { context as notificationsContext } from 'context/notifications';
import { context as localeContext } from 'context/locale';
import { Text, Link } from 'components/service';
import * as translations from 'constants/translations';
import { breadcrumbs } from 'constants/translations';
import { Layout, Breadcrumbs } from 'components/common/dashboard';
import PaymentCredentialsForm from 'components/common/PaymentCredentialsForm';
import { Modal, Button, Spinner, Stack } from 'components/kit';
import { useSelectedStore, useReplaceParams } from 'hooks';
import * as paths from 'paths.js';
import { uploadPaymentImages } from 'rest.js';
import { UPDATE_ONLINE_PAYMENT_ENABLED } from '../Root/schemas';
import * as schemas from './schemas';

const frontTokenSource = axios.CancelToken.source();
const backTokenSource = axios.CancelToken.source();
const licenseTokenSource = axios.CancelToken.source();
const authorizedSignatoryTokenSource = axios.CancelToken.source();

export default () => {
  const [loadingImages, setLoadingImages] = useState(false);
  const { lang } = useContext(localeContext);
  const notifications = useContext(notificationsContext);
  const storeId = useSelectedStore();
  const { selectedStore } = useContext(userContext);
  const replace = useReplaceParams();
  const { gatewayId: GWID } = parse(document.location.search);
  const gatewayId = parseInt(GWID);

  const legalData = useQuery(schemas.LEGAL_DATA, {
    variables: { storeId },
    fetchPolicy: 'network-only',
  });

  const [updateEnabled, { loading: enableUpdating }] = useMutation(UPDATE_ONLINE_PAYMENT_ENABLED, {
    variables: { storeId },
  });

  const legalInfo = legalData.data && legalData.data.legalData;
  const hasLegalData = !!legalInfo;

  return (
    <Modal>
      {({ open, close }) => (
        <Mutation mutation={schemas.ADD_PAYMENT_CREDENTIALS}>
          {(addPaymentCredentials, { loading }) => (
            <Layout
              top={
                <Breadcrumbs
                  links={[paths.setup, paths.paymentGateways]}
                  path={breadcrumbs.SETUP_CREATE_PAYMENT_METHODS}
                />
              }
            >
              <div className={cx('pb-6 flex', lang === 'ar' && 'flex-row-reverse')}>
                <div className="w-full md:w-2/3">
                  {!hasLegalData && legalInfo !== null ? (
                    <Spinner />
                  ) : (
                    <PaymentCredentialsForm
                      isSubmitting={loading || enableUpdating}
                      open={open}
                      close={close}
                      hasLegalData={hasLegalData}
                      legalData={legalInfo}
                      initialValues={{
                        profileName: '',
                        firstName: (legalInfo && legalInfo.firstName) || '',
                        lastName: (legalInfo && legalInfo.lastName) || '',
                        emailAddress: (legalInfo && legalInfo.email) || '',
                        password: '',
                        mobileNumber: (legalInfo && legalInfo.phoneNumber) || '',
                        idNumber: (legalInfo && legalInfo.nationalNumber) || '',
                        iban: (legalInfo && legalInfo.iban) || '',
                        bankName: '',
                        bankId: '',
                        paymentGatewayId: gatewayId,
                        paymentDepositTermId: '',
                        accountNumber: '',
                        bankAddress: '',
                        beneficiaryAddress: '',
                        swiftCode: '',
                        accountName: '',
                        accountType: legalInfo && legalInfo.isLicensed ? 'corp' : 'ind',
                        isLicensed: !!(legalInfo && legalInfo.isLicensed),
                        name: (legalInfo && legalInfo.companyNameEn) || null,
                        licenseNumber: (legalInfo && legalInfo.licenseNumber) || null,
                        nationalFrontId: (legalInfo && legalInfo.nationalFrontCopyUrl) || '',
                        nationalBackId: (legalInfo && legalInfo.nationalBackCopyUrl) || '',
                        LicenseCopy: (legalInfo && legalInfo.licenseCopyUrl) || '',
                        authorizedSignatoryCopy: (legalInfo && legalInfo.authorizedSignatoryCopyUrl) || '',
                        paymentMethods: {},
                        isNotKW: selectedStore.countryCode !== 'KW',
                      }}
                      onSubmit={data => {
                        addPaymentCredentials({
                          variables: {
                            restaurantId: storeId.toString(),
                            paymentGatewayId: data.paymentGatewayId,
                            profileName: data.profileName,
                            firstName: data.firstName,
                            lastName: data.lastName,
                            emailAddress: data.emailAddress,
                            password: data.password,
                            mobileNumber: data.mobileNumber,
                            idNumber: data.idNumber,
                            name: data.name,
                            licenseNumber: data.licenseNumber,
                            accountType: data.accountType,
                            iban: data.iban,
                            ...(gatewayId === 1 && { bankName: data.bankName }),
                            ...(gatewayId === 2 && { bankId: parseInt(data.bankId) }),
                            ...(gatewayId === 2 && { paymentDepositTermId: parseInt(data.paymentDepositTermId) }),
                            beneficiaryAddress: data.beneficiaryAddress,
                            bankAddress: data.bankAddress,
                            swiftCode: data.swiftCode,
                            accountName: data.accountName,
                            accountNumber: data.accountNumber,
                            isLicensed: data.isLicensed,
                            nationalFrontCopyUrl:
                              data.nationalFrontId instanceof File
                                ? null
                                : (legalInfo && legalInfo.nationalFrontCopyUrl) || null,
                            nationalBackCopyUrl:
                              data.nationalBackId instanceof File
                                ? null
                                : (legalInfo && legalInfo.nationalBackCopyUrl) || null,
                            licenseCopyUrl:
                              data.LicenseCopy instanceof File ? null : (legalInfo && legalInfo.licenseCopyUrl) || null,
                            authorizedSignatoryCopyUrl:
                              data.authorizedSignatoryCopy instanceof File
                                ? null
                                : (legalInfo && legalInfo.authorizedSignatoryCopyUrl) || null,
                          },
                        })
                          .then(res => {
                            setLoadingImages(true);
                            Promise.all(
                              [
                                data.nationalBackId instanceof File &&
                                  uploadPaymentImages(
                                    storeId,
                                    res.data.addPaymentCredentials.id,
                                    { back: data.nationalBackId },
                                    backTokenSource.token,
                                  ),
                                data.nationalFrontId instanceof File &&
                                  uploadPaymentImages(
                                    storeId,
                                    res.data.addPaymentCredentials.id,
                                    { front: data.nationalFrontId },
                                    frontTokenSource.token,
                                  ),
                                data.LicenseCopy instanceof File &&
                                  uploadPaymentImages(
                                    storeId,
                                    res.data.addPaymentCredentials.id,
                                    { license: data.LicenseCopy },
                                    licenseTokenSource.token,
                                  ),
                                data.authorizedSignatoryCopy instanceof File &&
                                  uploadPaymentImages(
                                    storeId,
                                    res.data.addPaymentCredentials.id,
                                    {
                                      authorizedSignatory: data.authorizedSignatoryCopy,
                                    },
                                    authorizedSignatoryTokenSource.token,
                                  ),
                              ].filter(R.identity),
                            ).then(() => {
                              updateEnabled({
                                variables: { enabled: true },
                              });
                              setLoadingImages(false);
                              notifications.show(<Text value={translations.STORE_INFORMATION_UPDATED} />);
                              navigate(replace(paths.paymentGateways, { storeId }));
                            });
                          })
                          .catch(err => {
                            Object.keys(err.graphQLErrors[0].extensions.exception.body).forEach(error => {
                              const value = err.graphQLErrors[0].extensions.exception.body[error];

                              notifications.show(`${error} ${value[0]}`, 'error');
                            });
                          });
                      }}
                      renderBottomPane={({ isSubmitting }) => [
                        <Stack direction={lang === 'ar' ? 'row' : 'row-reverse'} expandItem={false}>
                          <Button
                            kind="primary"
                            data-testid="payments-register-create"
                            isSpinning={isSubmitting || loadingImages}
                          >
                            <Text value={translations.CREATE} />
                          </Button>
                          <Link to={paths.paymentGateways}>
                            <Button type="button" kind="secondary" data-testid="payments-register-cancel">
                              <Text value={translations.CANCEL} />
                            </Button>
                          </Link>
                        </Stack>,
                      ]}
                    />
                  )}
                </div>
              </div>
            </Layout>
          )}
        </Mutation>
      )}
    </Modal>
  );
};
