import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import * as translations from 'constants/translations';
import { Button, Modal } from 'components/kit';
import { Text, Field } from 'components/service';
import { Hours } from 'components/form/elements';
import { context as localeContext } from 'context/locale';
import { openingHoursUtils } from 'pages/setup/delivery/ZonesList/utils';
import { checkEqual, checkOnDaysCounter } from '../../utils';

const DeliveryHours = ({ filedName, values, setFieldValue }) => {
  const { lang, translate } = useContext(localeContext);
  const [hoursInit, setHoursInit] = useState({ days: 0, time: '10am : 11pm' });
  const [isSaverClicked, setSaverClick] = useState(false);

  const handleHoursText = () => {
    let availableDays = 0;
    const filteredCheckedDays = checkOnDaysCounter(values.openingHours, openingHoursUtils.OFF_DAY);
    const only1DayOnLength = filteredCheckedDays.length === 1;
    const enhanceDisplayText = () => {
      if (only1DayOnLength) {
        return `[${moment(filteredCheckedDays[0].openAt, ['HH.mm']).format('hh:mm a')} : ${moment(
          filteredCheckedDays[0].closeAt,
          ['HH.mm'],
        ).format('hh:mm a')}]`;
      }

      if (
        checkEqual(filteredCheckedDays, openingHoursUtils.OPEN_AT) &&
        checkEqual(filteredCheckedDays, openingHoursUtils.CLOSE_AT) &&
        filteredCheckedDays.length >= 1
      ) {
        return `[${moment(filteredCheckedDays[0].openAt, ['HH.mm']).format('hh:mm a')} : ${moment(
          filteredCheckedDays[0].closeAt,
          ['HH.mm'],
        ).format('hh:mm a')}]`;
      }

      return lang === 'en' ? translations.MULTIPLE_DELIVERY_HOURS[0] : translations.MULTIPLE_DELIVERY_HOURS[1];
    };

    values.openingHours.forEach(item => {
      if (!item.offDay) {
        availableDays += 1;
      }
    });

    setHoursInit({ days: availableDays, time: enhanceDisplayText() });
  };

  useEffect(() => {
    handleHoursText();
  }, [isSaverClicked]);

  return (
    <Modal allowClose>
      {({ open, close, closeable }) => (
        <>
          <div className="w-full flex items-center justify-between">
            {!hoursInit.days ? (
              <p className="text-sm">{translate(translations.NO_DAYS_SELECTED)}</p>
            ) : (
              <p className="text-sm">
                {hoursInit.days} {lang === 'en' ? translations.DAYS[0] : translations.DAYS[1]}. {hoursInit.time}
              </p>
            )}

            <Button
              kind="tertiaryLink"
              type="button"
              isMenu
              onClick={e => {
                e.preventDefault();
                closeable(false);
                open({
                  size: 'max-w-2xl',
                  title: <Text value={translations.DELIVERY_HOURS} />,
                  body: (
                    <div className="p-4">
                      <Field name={filedName} component={Hours} />
                      <div className="flex justify-end mt-12">
                        <Button
                          onClick={() => {
                            handleHoursText();
                            setFieldValue(filedName, values.openingHours);
                            closeable(true);
                            close();
                          }}
                          type="button"
                          kind="tertiary"
                          className="mr-4 ml-4"
                        >
                          <Text value={translations.DISMISS} />
                        </Button>
                        <Button
                          onClick={() => {
                            setSaverClick(prevState => !prevState);
                            closeable(true);
                            close();
                          }}
                          kind="primary"
                        >
                          <Text value={translations.SAVE} />
                        </Button>
                      </div>
                    </div>
                  ),
                });
              }}
            >
              <Text className="text-primary-base text-sm" value={translations.CHANGE} />
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

DeliveryHours.propTypes = {
  filedName: PropTypes.string.isRequired,
  values: PropTypes.shape({
    openingHours: PropTypes.arrayOf(
      PropTypes.shape({
        day: PropTypes.string,
        openAt: PropTypes.string,
        closeAt: PropTypes.string,
      }),
    ),
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default DeliveryHours;
