import React, { useContext, useEffect, useState } from "react"
import { useSelectedStore } from "hooks/index"
import { context as localeContext } from "context/locale"
import { context as userContext } from "context/user"
import { Link } from "components/service"
import * as paths from "paths.js"
import * as translations from "constants/translations"
import "styled-components/macro"
import subs from "../Details/billingSchema"
import { useQuery } from "@apollo/client"
import { ReactComponent as AlertCircle } from "assets/Icons_Warning.svg"
import { ReactComponent as WarningTriangle } from "assets/alert-circle.svg"
import { useMobile } from "hooks/index"

const RenewTopBar = () => {
  const isMobile = useMobile()
  const { direction, translate } = useContext(localeContext)
  const {
    user,
    hasRole,
    subscriptionStatusSetting,
    selectedStore,
  } = useContext(userContext)
  const [storeId, setStoreId] = useState()
  const selectedStoreId = selectedStore?.id || 0

  useEffect(() => {
    setStoreId(selectedStoreId)
  }, [selectedStoreId])

  const subsc = useQuery(subs, {
    fetchPolicy: "network-only",
    variables: {
      storeId: storeId,
    },
    onCompleted: data =>
      subscriptionStatusSetting &&
      subscriptionStatusSetting(data.subscription?.status),
    onError: error => console.log(error.graphQLErrors),
  })

  const subscriptionData = subsc.data || null

  if (subsc.loading || subsc.error) return ""

  const subscriptionStatus = subscriptionData
    ? subscriptionData.subscription?.status
    : null
  const subscriptionInvoices = subscriptionData
    ? subscriptionData.subscription?.invoices
    : null
  const subscriptionCancelReason = subscriptionData
    ? subscriptionData.subscription?.cancelReason
    : null
  if (
    subscriptionStatus &&
    subscriptionInvoices &&
    (subscriptionStatus === "setup" ||
      (subscriptionStatus === "cancelled"
        ? false
        : subscriptionStatus === "late_payment"
        ? false
        : subscriptionInvoices.filter(invoice => invoice.current === true)[0] &&
          subscriptionInvoices.filter(invoice => invoice.current === true)[0]
            .status !== "unpaid"))
  ) {
    return ""
  }

  const topBar =
    subscriptionStatus === "late_payment" || subscriptionStatus === "cancelled"
      ? "z-50 py-3 pr-5 pl-4 flex justify-center items-center bg-zyda-red-500"
      : "z-50 py-3 pr-5 pl-4 flex justify-center items-center bg-orange-150"
  const ownerIconDeskTop =
    subscriptionStatus === "late_payment" || subscriptionStatus === "cancelled"
      ? "fill-white mx-3 w-6 h-6"
      : "fill-black mx-3 w-6 h-6"
  const ownerIconMobile =
    subscriptionStatus === "late_payment" || subscriptionStatus === "cancelled"
      ? "fill-white mx-3 w-16 h-16"
      : "fill-black mx-3 w-16 h-16"
  const operatorIconDeskTop =
    subscriptionStatus === "late_payment" || subscriptionStatus === "cancelled"
      ? "fill-white mx-3 w-8 h-8"
      : "fill-black mx-3 w-8 h-8"
  const operatorIconMobile =
    subscriptionStatus === "late_payment" || subscriptionStatus === "cancelled"
      ? "fill-white mx-3 w-16 h-16"
      : "fill-black mx-3 w-16 h-16"
  const topBarStatment =
    subscriptionStatus === "late_payment" || subscriptionStatus === "cancelled"
      ? "leading-squeeze text-sm text-left text-white"
      : "leading-squeeze text-sm text-left text-black"
  const topBarStatmentLink = "font-bold underline"
  const topBarStatmentHint = "font-semibold"

  return (
    <div>
      {subscriptionStatus ? (
        <div>
          {user && hasRole ? (
            hasRole("owner") || hasRole("ops_manager") ? (
              <div style={{ direction }} className={topBar}>
                <AlertCircle
                  className={isMobile ? ownerIconMobile : ownerIconDeskTop}
                />
                {subscriptionStatus === "cancelled" ? (
                  subscriptionCancelReason !== "late_payment" ? (
                    <p className={topBarStatment}>
                      {translate(translations.REQUEST_CANCELLED_SUBSCRIPTION)}
                      &nbsp;
                      <span className={(topBarStatment, topBarStatmentHint)}>
                        {translate(
                          translations.REQUEST_CANCELLED_SUBSCRIPTION_HINT
                        )}
                      </span>
                    </p>
                  ) : (
                    <p className={topBarStatment}>
                      {translate(translations.CANCELLED_SUBSCRIPTION)}
                      &nbsp;
                      <span className={(topBarStatment, topBarStatmentHint)}>
                        {translate(translations.CANCELLED_SUBSCRIPTION_HINT)}
                      </span>
                    </p>
                  )
                ) : subscriptionStatus === "late_payment" ? (
                  <p className={topBarStatment}>
                    {translate(translations.OWNER_LATE_SUBSCRIPTION)}&nbsp;
                    <Link to={paths.invoices}>
                      <span className={(topBarStatment, topBarStatmentLink)}>
                        {translate(translations.OWNER_LATE_SUBSCRIPTION_LINK)}
                      </span>
                    </Link>
                    {translate(translations.OWNER_LATE_SUBSCRIPTION_LINK_AFTER)}
                  </p>
                ) : (
                  <p className={topBarStatment}>
                    {translate(translations.OWNER_EXPIRED_SUBSCRIPTION)}&nbsp;
                    <Link to={paths.renew}>
                      <span className={(topBarStatment, topBarStatmentLink)}>
                        {translate(
                          translations.OWNER_EXPIRED_SUBSCRIPTION_LINK
                        )}
                      </span>
                    </Link>
                  </p>
                )}
              </div>
            ) : (
              <div style={{ direction }} className={topBar}>
                <WarningTriangle
                  className={
                    isMobile ? operatorIconMobile : operatorIconDeskTop
                  }
                />
                {subscriptionStatus === "cancelled" ? (
                  subscriptionCancelReason !== "late_payment" ? (
                    <p className={topBarStatment}>
                      {translate(translations.REQUEST_CANCELLED_SUBSCRIPTION)}
                      &nbsp;
                      <span className={(topBarStatment, topBarStatmentHint)}>
                        {translate(
                          translations.REQUEST_CANCELLED_SUBSCRIPTION_HINT
                        )}
                      </span>
                    </p>
                  ) : (
                    <p className={topBarStatment}>
                      {translate(translations.CANCELLED_SUBSCRIPTION)} &nbsp;
                      <span className={(topBarStatment, topBarStatmentHint)}>
                        {translate(translations.CANCELLED_SUBSCRIPTION_HINT)}
                      </span>
                    </p>
                  )
                ) : subscriptionStatus === "late_payment" ? (
                  <p className={topBarStatment}>
                    {translate(translations.OPERATOR_LATE_SUBSCRIPTION)}&nbsp;
                    <span className={(topBarStatment, topBarStatmentHint)}>
                      {translate(translations.OPERATOR_LATE_SUBSCRIPTION_LINK)}
                      &nbsp;
                    </span>
                    {translate(
                      translations.OPERATOR_LATE_SUBSCRIPTION_LINK_AFTER
                    )}
                  </p>
                ) : (
                  <p className={topBarStatment}>
                    {translate(translations.OPERATOR_EXPIRED_SUBSCRIPTION)}
                    &nbsp;
                    <span className={(topBarStatment, topBarStatmentHint)}>
                      {translate(
                        translations.OPERATOR_EXPIRED_SUBSCRIPTION_LINK
                      )}
                    </span>
                    &nbsp;
                    {translate(
                      translations.OPERATOR_EXPIRED_SUBSCRIPTION_AFTER_LINK
                    )}
                  </p>
                )}
              </div>
            )
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  )
}

export default RenewTopBar
