import { STH_WENT_WRONG } from "constants/constant-errors"

export const handleSubmissionErrors = err => {
  const { body } = err?.graphQLErrors[0].extensions.exception

  if (body["pos"]) {
    return "Menu is being synced from partner"
  }
  return STH_WENT_WRONG
}
