import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';

import { Field, Text } from 'components/service';
import { RadioList } from 'components/form/elements';
import { SAME_DAY, NEXT_DAY, DELIVER_WITHIN } from 'constants/translations';
import { SAME_DAY as SAME_DAY_VAL, NEXT_DAY as NEXT_DAY_VAL } from 'constants/integrations';

const DeliveryDurationTemplate = ({ setExtraData }) => {
  const changeData = e => {
    setExtraData({ deliveryDurationType: e.target.value });
  };

  useEffect(() => {
    setExtraData({ deliveryDurationType: SAME_DAY_VAL });
  }, []);

  return (
    <Formik>
      <Form>
        <div className="flex justify-between items-center w-full">
          <span className="text-sm">
            <Text value={DELIVER_WITHIN} />
          </span>

          <Field
            onChange={changeData}
            direction="row"
            value={SAME_DAY_VAL}
            name="deliveryTimeOption"
            items={[
              {
                title: <Text value={SAME_DAY} />,
                value: SAME_DAY_VAL,
              },
              {
                title: <Text value={NEXT_DAY} />,
                value: NEXT_DAY_VAL,
              },
            ]}
            component={RadioList}
          />
        </div>
      </Form>
    </Formik>
  );
};

DeliveryDurationTemplate.propTypes = {
  setExtraData: PropTypes.func.isRequired,
};

export default DeliveryDurationTemplate;
