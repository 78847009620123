import React, { useEffect, useState, useContext } from 'react';
import cx from 'classnames';
import { context as localeContext } from 'context/locale';

export default ({ items, preCreation = false, createMenuItem, postCreation = false }) => {
  const [openTab, setOpenTab] = useState(postCreation ? 1 : 0);
  const [flatTabItems, setFlatTabItems] = useState(items ? [...items] : []);
  const { lang } = useContext(localeContext);

  useEffect(() => {
    setFlatTabItems(items ? [...items] : []);
  }, [items]);
  return (
    <>
      <div className="flex flex-wrap">
        <div className={cx('w-full p-4', lang === 'en' ? 'md:pr-0' : 'md:pl-0')}>
          <ul className="flex mb-0 list-none flex-wrap flex-row border-b border-gray" role="tablist">
            {flatTabItems.map((item, i) => (
              <li className="-mb-px mr-2 last:mr-0" key={i}>
                <a
                  className={
                    'cursor-pointer pb-4 flex border-b-4 hover:border-primary-base -mb-px mx-2 ' +
                    (openTab === i
                      ? 'min-w-max border-primary-base text-primary-base font-medium active-link'
                      : 'min-w-max border-transparent')
                  }
                  onClick={e => {
                    e.preventDefault();
                    if (i === 1 && preCreation) {
                      setOpenTab(0);
                      createMenuItem && createMenuItem();
                    } else {
                      setOpenTab(i);
                    }
                  }}
                  data-toggle="tab"
                  href={preCreation ? '#link1' : '#link' + i}
                  role="tablist"
                >
                  {item.title}
                </a>
              </li>
            ))}
          </ul>
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 overflow-y-scroll lg:pb-lg lg:h-800">
            <div className="py-2 flex-auto">
              <div className="tab-content tab-space">
                {flatTabItems.map((item, i) => (
                  <div key={i} className={openTab === i ? 'block' : 'hidden'} id={preCreation ? 'link1' : 'link' + i}>
                    {item.body}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
