import React, { useContext } from "react"
import cx from "classnames"
import { context as localeContext } from "context/locale"

export default ({ items, active }) => {
  const { lang } = useContext(localeContext)
  return (
    <div className={cx("flex", lang === "ar" && "flex-row-reverse", "-mx-2")}>
      {items.map((item, i) => (
        <div key={i} className="px-2 flex-1">
          <div
            className={cx(
              "rounded bg-gray-200 h-1 w-full",
              i <= active && "bg-primary-base"
            )}
          />
          <span
            className={cx(
              "mt-2 text-sm",
              i === active ? "font-semibold text-black" : "text-gray-700"
            )}
          >
            {i + 1}. {item}
          </span>
        </div>
      ))}
    </div>
  )
}
