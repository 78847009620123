import React, { useContext } from "react"
import styled from "styled-components"
import moment from "moment"
import cx from "classnames"
import { context as localeContext } from "context/locale"
import { Error } from "components/form/generic"
import { ReactComponent as Date } from "assets/date.svg"
import { DatePicker } from "components/kit"

export default ({ name, error, onChange, value, ...props }) => {
  const { lang, direction } = useContext(localeContext)

  return (
    <div className={cx('flex', props.containerClassName)}>
      <Wrap lang={lang} className={cx('flex flex-col relative', error && 'form-input-error')}>
        <DatePicker
          onChange={value =>
            onChange({
              target: {
                name,
                value: props.isRemovable
                  ? value
                    ? moment(value).format("YYYY-MM-DD")
                    : ""
                  : moment(value).format("YYYY-MM-DD"),
              },
            })
          }
          value={value ? moment(value, "YYYY-MM-DD").toDate() : ""}
          {...props}
        />
        <div
          className={cx(
            "absolute top-0 bottom-0 flex items-center",
            lang === "ar" ? "right-0 pr-3" : "left-0 pl-3"
          )}
        >
          <Date />
        </div>
      </Wrap>
      {error && <Error direction={direction}>{error}</Error>}
    </div>
  )
}

const Wrap = styled.div`
  .react-datepicker-wrapper input {
    padding-left: ${props => `${props.lang === "ar" ? "12px" : "40px"}`};
    padding-right: ${props => `${props.lang === "ar" ? "40px" : "12px"}`};
  }
`
