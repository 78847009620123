import { convertTime } from 'utils';

export const PREP_TIME_DATE = {
  HRS: 'hrs',
  MINS: 'mins',
  DAYS: 'days',
};

export const handleSubmittingPrepTime = (optionSelected, prepTime, defaultProductPrepTime) => {
  if (prepTime === -1) return defaultProductPrepTime;
  else {
    if (optionSelected === PREP_TIME_DATE.MINS) {
      return Number(prepTime);
    }
    if (optionSelected === PREP_TIME_DATE.HRS) {
      return Number(convertTime.fromHoursToMinutes(Number(prepTime)));
    }
    if (optionSelected === PREP_TIME_DATE.DAYS) {
      return Number(convertTime.fromDaysToMinutes(Number(prepTime)));
    }
  }
};
