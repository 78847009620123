import { useDeliveryZoneMsToken } from 'hooks';
import { City, QueryCitiesArgs } from '../../types/generated';
import { useQuery } from '../../utils';
import { QueryHookResponse } from '../../types/internal';
import { citiesListQuery } from '../../queries/deliveryZones';

type useFetchCitiesListType = (args: QueryCitiesArgs) => QueryHookResponse<City>;

const useFetchCitiesList: useFetchCitiesListType = ({ countryId }) => {
  const deliveryZoneMsToken = useDeliveryZoneMsToken();
  return useQuery<City>({
    query: citiesListQuery,
    variables: { countryId },
    requestHeaders: { token: deliveryZoneMsToken },
    handler: (res: any) => res.cities,
    config: { revalidateOnMount: true },
  });
};

export default useFetchCitiesList;
