import moment from 'moment';

export const transformBehaviorBreakdownData = data => {
  const listData = !data
    ? []
    : Object.keys(data).reduce(
        (acc, x) => [
          ...acc,
          {
            date: x,
            cash: data[x].cash || 0,
            mastercard: data[x].mastercard || 0,
            visa: data[x].visa || 0,
            knet: data[x].knet || 0,
            benefit: data[x].benefit || 0,
            cardondelivery: data[x].cardondelivery || 0,
            creditcard: data[x].creditcard || 0,
            meeza: data[x].meeza || 0,
            online: data[x].online || 0,
            master: data[x].master || 0,
            mada: data[x].mada || 0,
            applepay: data[x]['apple-pay'] || 0,
          },
        ],
        [],
      );
  return listData;
};

export const formatBehaviorBreakdownData = (values, date, display) =>
  values.map(item => ({
    date: date === 'day' ? moment(item.date).format('MMM DD, YYYY') : moment(item.date).format('MMMM, YYYY'),
    cash: display(item.cash),
    knet: display(item.knet),
    benefit: display(item.benefit),
    cardondelivery: display(item.cardondelivery),
    creditcard: display(item.creditcard),
    meeza: display(item.meeza),
    mada: display(item.mada),
    applepay: display(item.applepay),
  }));
