import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import moment from 'moment';

import Slideover from 'components/common/dashboard/Layout/Slideover/Slideover';
import * as translations from 'constants/translations';
import { context as localeContext } from 'context/locale';
import { Text } from 'components/service';
import StatusTag from '../../../../../components/common/StatusTag';
import { Button } from '../../../../../components/kit';
import CASHBACK_PROGRAM_STATUS_ENUM from '../../constants';

function CashbackProgram({ program, onDeactivate }) {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const { lang } = useContext(localeContext);

  const { endsAt, startsAt, deactivatedAt, name, status, id, percentage } = program || {};

  moment.locale(lang);
  const dateFormat = 'DD MMM YYYY';

  const getEndDateText = () => {
    const startDateText = moment(startsAt).format(dateFormat);
    const endDateText = deactivatedAt || endsAt ? moment(deactivatedAt || endsAt).format(dateFormat) : null;

    if (startDateText && endDateText) return translations.CASHBACK_DATE(startDateText, endDateText);

    return translations.CASHBACK_CONTINOUS(startDateText);
  };
  const isActive = status === CASHBACK_PROGRAM_STATUS_ENUM.ACTIVE;
  const isScheduled = status === CASHBACK_PROGRAM_STATUS_ENUM.SCHEDULED;

  return (
    <div
      key={id}
      className={cx(
        `flex flex-col border border-solid rounded-lg border-gray-300 mb-6 py-5 px-5 cursor-pointer ${
          isModalOpened ? 'border-blue-600 bg-blue-50' : 'border-gray-300'
        }`,
      )}
    >
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-col items-start flex-1">
          <StatusTag status={status} />
          <div className="w-full flex mb-2">
            <Text value={[name, name]} className="text-xl font-semibold flex-1" />
            <Text
              value={[`${percentage} %`, `${Number(percentage).toLocaleString('ar-KW')} ٪`]}
              className="text-xl font-semibold w-auto"
            />
          </div>
          <Text value={getEndDateText()} className="text-sm font-normal" />
        </div>
      </div>
      {(isActive || isScheduled) && (
        <Button
          type="button"
          onClick={() =>
            onDeactivate({
              id,
              descriptionText: isActive ? translations.ARE_YOU_SURE_TO_DEACTIVATE : translations.ARE_YOU_SURE_TO_CACNEL,
              actionButtonText: isActive ? translations.DEACTIVATE : translations.YES,
            })
          }
          className="flex self-start mt-4"
        >
          <Text value={isActive ? translations.DEACTIVATE : translations.CANCEL} />
        </Button>
      )}
      <Slideover
        isOpen={isModalOpened}
        setSlideOver={setIsModalOpened}
        title={<Text value={translations.NEW_CASHBACK_DETAILS} />}
        body={<Text value={['hellooo']} />}
      />
    </div>
  );
}

CashbackProgram.propTypes = {
  program: {
    id: PropTypes.string,
    name: PropTypes.string,
    percentage: PropTypes.string,
    status: PropTypes.string,
    startsAt: PropTypes.string,
    endsAt: PropTypes.string,
  },
};

export default CashbackProgram;
