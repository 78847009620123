import { gql } from 'graphql-request';

const deleteDeliveryZoneMutation = gql`
  mutation($input: DeleteDeliveryZoneInput!) {
    deleteDeliveryZone(input: $input) {
      deliveryZoneId
      clientMutationId
    }
  }
`;

export default deleteDeliveryZoneMutation;
