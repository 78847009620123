import React from 'react';
import PropTypes from 'prop-types';

const SlideoverTitle = ({ title }) => (
  <div className="px-2 border-b border-gray-300">
    <div className="text-2xl font-medium text-gray-900">
      <div className="py-4">{title}</div>
    </div>
  </div>
);

SlideoverTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default SlideoverTitle;
