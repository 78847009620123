import React from 'react';
import * as yup from 'yup';
import { Text } from 'components/service';
import * as translations from 'constants/translations';

// Initial values
export const initialValues = {
  phoneNumber: '',
  userName: '',
};

// Validation
export const validationSchema = yup.object().shape({
  userName: yup.string().required(<Text value={translations.NAME_SHOULD_CONTAIN_LETTERS} />),
  phoneNumber: yup
    .string()
    .matches(/^[0-9]+$/, <Text value={translations.NUMBER_TYPE} />)
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
});
