import React, { useContext } from "react"
import moment from "moment"
import cx from "classnames"
import Time from "./Time"
import { Text } from "components/service"
import * as translations from "constants/translations"
import { context as localeContext } from "context/locale"

export default ({ items, update }) => {
  const { lang } = useContext(localeContext)

  return (
    <>
      {items.map((item, i) => {
        const isChecked = !item.offDay

        return (
          <div
            key={i}
            onClick={() => update({ offDay: !item.offDay }, i)}
            className={cx(
              "flex flex-wrap items-center cursor-pointer px-3 py-2",
              i !== items.length - 1 && "border-b border-gray-300"
            )}
          >
            <div className="flex py-1 md:py-0">
              <span
                className={cx(
                  "w-5 h-5 mx-3 rounded border-2 flex items-center justify-center",
                  isChecked
                    ? "border-primary-base bg-primary-base hover:border-gray-300 hover:bg-primary-100 focus:border-gray-300 focus:bg-white active:border-gray-300 active:bg-primary-100"
                    : "border-gray-300 hover:bg-primary-100 active:bg-primary-100"
                )}
              >
                {isChecked && (
                  <i className="material-icons text-sm text-white focus:text-primary-base active:text-primary-base">
                    check
                  </i>
                )}
              </span>
              <div className="w-20">
                {lang === "en"
                  ? moment(item.day, "ddd").format("dddd")
                  : moment(item.day, "ddd")
                      .locale("ar-kw")
                      .format("dddd")}
              </div>
            </div>

            <div className="flex py-1 md:py-0">
              <div className="mx-3">
                <Time
                  title={<Text value={translations.FROM} className="inline" />}
                  value={item.openAt}
                  onChange={openAt => update({ openAt }, i)}
                />
              </div>
              <div className="mx-4">
                <Time
                  title={<Text value={translations.TO} className="inline" />}
                  value={item.closeAt}
                  onChange={closeAt => update({ closeAt }, i)}
                />
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}
