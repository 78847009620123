import React from 'react';

import BranchAvailability from './BranchAvailability';

const EditBranchesAvailability = ({ branches, lang, timeZone, open, close, setIsBusy }) =>
  branches.map(branch => (
    <BranchAvailability
      branch={branch}
      lang={lang}
      timeZone={timeZone}
      open={open}
      close={close}
      setIsBusy={setIsBusy}
    />
  ));

export default EditBranchesAvailability;
