import { gql } from "@apollo/client"

export const OPTION_GROUPS = gql`
  query($storeId: String!) {
    propertySections(restaurantId: $storeId) {
      id
      titleEn
      titleAr
      assignedMenuItemsCount
      propertyValues {
        id
      }
    }
  }
`

export const ALL_OPTIONS = gql`
  query($storeId: String!, $page: Int!, $limit: Int, $title: String) {
    allPropertyValues(
      restaurantId: $storeId
      page: $page
      limit: $limit
      title: $title
    ) {
      totalCount
      totalPage
      allPropertyValues {
        id
        titleEn
        titleAr
        price
        availableBranchIds
        propertyId
        externalId
      }
    }
  }
`
export const OPTIONS = gql`
  query($storeId: String!, $propertySectionId: String!, $title: String) {
    propertyValues(
      restaurantId: $storeId
      propertySectionId: $propertySectionId
      title: $title
    ) {
      id
      titleEn
      titleAr
      price
      externalId
      availableBranchIds
    }
  }
`

export const CHANGE_AVAILABILITY = gql`
  mutation(
    $storeId: String!
    $branchId: String!
    $propertyValueId: String!
    $propertySectionId: String!
    $isAvailable: Boolean!
  ) {
    changePropertyValueAvaialability(
      restaurantId: $storeId
      branchId: $branchId
      propertyValueId: $propertyValueId
      propertySectionId: $propertySectionId
      isAvailable: $isAvailable
    )
  }
`

export const CHANGE_ALL_AVAILABILITY = gql`
  mutation(
    $storeId: String!
    $propertyValueId: String!
    $propertySectionId: String!
    $branchIds: [String]
  ) {
    updatePropertyValue(
      restaurantId: $storeId
      propertyValueId: $propertyValueId
      propertySectionId: $propertySectionId
      branchIds: $branchIds
    )
  }
`

export const SORT_OPTIONS = gql`
  mutation(
    $restaurantId: String!
    $propertySectionId: String!
    $sort: [PropertyValuesPosition]!
  ) {
    sortOptions(
      restaurantId: $restaurantId
      propertySectionId: $propertySectionId
      sort: $sort
    ) {
      id
      titleEn
      titleAr
      price
      externalId
      availableBranchIds
      propertyId
    }
  }
`
