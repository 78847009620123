import { gql } from "@apollo/client"

export const ADD_USER = gql`
  mutation(
    $storeId: String!
    $name: String!
    $email: String!
    $phoneNumber: String!
    $roles: [UserResource]!
  ) {
    addUser(
      restaurantId: $storeId
      name: $name
      email: $email
      phoneNumber: $phoneNumber
      roles: $roles
    ) {
      id
    }
  }
`
