import React, { useContext, useState, useRef } from 'react';
import { context as localeContext } from 'context/locale';
import cx from 'classnames';
import { Button } from 'components/kit';
import { useClickOutside } from 'hooks/index';
import { Text } from 'components/service';

const More = ({
  items,
  kind = 'secondary',
  size = 'baseIcon',
  iconSize,
  iconColor,
  testIdBtn,
  className,
  disabled,
  modalUp,
  moreButtonText,
  stopEventPropagation = false,
  icon = 'more_vert',
}) => {
  const { lang } = useContext(localeContext);
  const [isOpened, setOpened] = useState(false);
  const ref = useRef();
  useClickOutside(ref, () => setOpened(false));
  return (
    <div className="relative" ref={ref}>
      <Button
        onClick={e => {
          stopEventPropagation && e.stopPropagation();
          !disabled && setOpened(!isOpened);
        }}
        type="button"
        kind={kind}
        icon={icon}
        size={size}
        className={className}
        iconSize={iconSize}
        iconColor={iconColor}
        data-testid={testIdBtn}
        disabled={disabled}
        {...(moreButtonText && { isChevron: true })}
      >
        {moreButtonText && <Text className={cx(lang === 'en' ? 'px-2' : 'px-0')} value={moreButtonText} />}
      </Button>
      {isOpened && (
        <div
          className={cx(
            'z-10 absolute w-32 shadow bg-white min-w-max',
            modalUp ? 'bottom-0 mb-10' : 'top-0 mt-10',
            lang === 'en' ? 'right-0' : 'left-0',
          )}
        >
          {items.map((item, i) =>
            item.type === 'separator' ? (
              <div className="h-px w-full bg-zyda-grey-50 my-2" />
            ) : (
              <button
                key={i}
                onClick={() => {
                  setOpened(false);
                  !item.disabled && item.onClick();
                }}
                className={cx(
                  'block w-full py-2 px-4 border-t border-b border-transparent',
                  'hover:bg-primary-50 cursor-pointer text-xs outline-none focus:outline-none',
                  lang === 'en' ? 'text-left' : 'text-right',
                  item.disabled && 'opacity-25 cursor-not-allowed',
                )}
                data-testid="disable-stockManagement"
                disabled={item.disabled}
              >
                {item.title}
              </button>
            ),
          )}
        </div>
      )}
    </div>
  );
};

export default More;
