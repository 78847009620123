import { gql } from "@apollo/client"

export const UPDATE_STORE = gql`
  mutation($id: String!, $published: Boolean!) {
    updateRestaurant(id: $id, published: $published) {
      id
      published
    }
  }
`
