import React from 'react';
import cx from 'classnames';
import { Typography, useTheme } from '@zydalabs/zac-react';
import PropTypes from 'prop-types';
import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { fonts } from './data';

const FontSelector = ({ fontShape, setFieldValue }) => {
  const { colors } = useTheme();

  return (
    <div className="border-b border-gray-300 pb-6">
      <Typography cursor="pointer" variant="heading16" mt={24}>
        <Text value={translations.FONT} />
      </Typography>
      <Typography cursor="pointer" variant="body14" mt={4} color={colors.positive.secondary} mb={24}>
        <Text value={translations.SELECT_FONT} />
      </Typography>
      <div className="flex gap-6 flex-wrap">
        {fonts.map(item => (
          <div
            className={cx(
              'col-span-4 py-4 px-6 cursor-pointer border rounded',
              fontShape === item.id ? 'border-blue-500' : 'border-gray-300',
            )}
            key={item.id}
            role="presentation"
            onClick={() => setFieldValue('fontShape', item.id)}
          >
            <Typography
              cursor="pointer"
              mb={16}
              color={fontShape === item.id ? colors.accent.primary : colors.positive.primary}
            >
              {item.title}
            </Typography>

            {item.icon}
          </div>
        ))}
      </div>
    </div>
  );
};

FontSelector.propTypes = {
  fontShape: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};
export default FontSelector;
