import { USERS } from "pages/team/Users/schemas"

export const removeUser = (usersVariables, userId) => cache => {
  const data = cache.readQuery({
    query: USERS,
    variables: usersVariables,
  })

  if (!data) return

  cache.writeQuery({
    query: USERS,
    data: {
      ...data,
      users: {
        ...data.users,
        users: data.users.users.filter(user => user.id !== userId),
      },
    },
    variables: usersVariables,
  })
}
