import { gql } from "@apollo/client"

export const SETTINGS = gql`
  query($storeId: String!) {
    settings(restaurantId: $storeId) {
      id
      storeTypeId
    }
  }
`

export const UPDATE_SETTINGS = gql`
  mutation($storeId: String!, $storeTypeId: String) {
    updateSettings(restaurantId: $storeId, storeTypeId: $storeTypeId) {
      id
      storeTypeId
    }
  }
`

export const UPDATE_RESTAURANT = gql`
  mutation updateRestaurant(
    $id: String!
    $countryId: String
    $preferredLanguage: String
    $titleEn: String
    $titleAr: String
    $taxPercentage: Float
    $taxInclusive: Boolean
  ) {
    updateRestaurant(
      id: $id
      countryId: $countryId
      preferredLanguage: $preferredLanguage
      titleEn: $titleEn
      titleAr: $titleAr
      taxPercentage: $taxPercentage
      taxInclusive: $taxInclusive
    ) {
      id
      countryId
      preferredLanguage
      titleEn
      titleAr
      taxPercentage
      taxInclusive
    }
  }
`

export const LEGAL_DATA = gql`
  query($storeId: String!) {
    legalData(restaurantId: $storeId) {
      email
      firstName
      lastName
      nationalNumber
      phoneNumber
      nationalBackCopyUrl
      nationalFrontCopyUrl
      companyNameEn
      companyNameAr
      vatIdNumber
      licenseNumber
      licenseCopyUrl
      authorizedSignatoryCopyUrl
      isLicensed
    }
  }
`

export const UPDATE_LEGAL_DATA = gql`
  mutation(
    $storeId: String!
    $email: String
    $firstName: String
    $lastName: String
    $nationalBackCopy: String
    $nationalFrontCopy: String
    $nationalNumber: String
    $phoneNumber: String
    $companyNameEn: String
    $companyNameAr: String
    $vatIdNumber: String
    $licenseNumber: String
    $licenseCopyUrl: String
    $authorizedSignatoryCopyUrl: String
    $isLicensed: Boolean
  ) {
    updateLegalData(
      restaurantId: $storeId
      email: $email
      firstName: $firstName
      lastName: $lastName
      nationalBackCopy: $nationalBackCopy
      nationalFrontCopy: $nationalFrontCopy
      nationalNumber: $nationalNumber
      phoneNumber: $phoneNumber
      companyNameEn: $companyNameEn
      companyNameAr: $companyNameAr
      vatIdNumber: $vatIdNumber
      licenseNumber: $licenseNumber
      licenseCopyUrl: $licenseCopyUrl
      authorizedSignatoryCopyUrl: $authorizedSignatoryCopyUrl
      isLicensed: $isLicensed
    ) {
      email
      firstName
      lastName
      nationalNumber
      phoneNumber
      nationalBackCopyUrl
      nationalFrontCopyUrl
      companyNameEn
      companyNameAr
      vatIdNumber
      licenseNumber
      licenseCopyUrl
      authorizedSignatoryCopyUrl
      isLicensed
    }
  }
`

export const ADD_LEGAL_DATA = gql`
  mutation(
    $storeId: String!
    $email: String
    $firstName: String
    $lastName: String
    $nationalBackCopy: String
    $nationalFrontCopy: String
    $nationalNumber: String
    $phoneNumber: String
    $companyNameEn: String
    $companyNameAr: String
    $vatIdNumber: String
    $licenseNumber: String
    $licenseCopyUrl: String
    $authorizedSignatoryCopyUrl: String
    $isLicensed: Boolean
  ) {
    addLegalData(
      restaurantId: $storeId
      email: $email
      firstName: $firstName
      lastName: $lastName
      nationalBackCopy: $nationalBackCopy
      nationalFrontCopy: $nationalFrontCopy
      nationalNumber: $nationalNumber
      phoneNumber: $phoneNumber
      companyNameEn: $companyNameEn
      companyNameAr: $companyNameAr
      vatIdNumber: $vatIdNumber
      licenseNumber: $licenseNumber
      licenseCopyUrl: $licenseCopyUrl
      authorizedSignatoryCopyUrl: $authorizedSignatoryCopyUrl
      isLicensed: $isLicensed
    ) {
      email
      firstName
      lastName
      nationalNumber
      phoneNumber
      nationalBackCopyUrl
      nationalFrontCopyUrl
      companyNameEn
      companyNameAr
      vatIdNumber
      licenseCopyUrl
      authorizedSignatoryCopyUrl
      isLicensed
    }
  }
`

export const STORE_TYPES = gql`
  query {
    storeTypes {
      id
      titleEn
      titleAr
    }
  }
`
