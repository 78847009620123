import React, { useContext } from "react"
import { Stack } from "components/kit"
import cx from "classnames"
import { context as localeContext } from "context/locale"

export default ({
  name,
  value = [],
  onChange,
  items,
  groupTitle,
  containerStyle,
  placeholder,
  wFull
}) => {
  return (
    <>
      <Stack direction="col" className={cx(wFull && "w-full")}>
        <div style={containerStyle}>
          <div className={cx(groupTitle && "px-4 pb-1 pt-3")}>{groupTitle}</div>
          {!items.length && (
            <span className="text-zyda-black-100 text-md text-center mt-6">{placeholder}</span>
          )}
          {items.map((item, index) => {
            const isChecked = value.includes(item.id)
            return (
              <Checkbox
                key={index}
                onChange={() =>
                  onChange({
                    target: {
                      name,
                      value: !isChecked
                        ? [...value, item.id]
                        : value.filter(id => id !== item.id),
                    },
                  })
                }
                title={item.title}
                icon={item.icon}
                isChecked={isChecked}
              />
            )
          })}
        </div>
      </Stack>
    </>
  )
}

export const Checkbox = ({ title, icon, isChecked, onChange }) => {
  const { lang } = useContext(localeContext)
  return (
    <div
      className={cx(
        "px-4 py-2 text-zyda-black-100 hover:bg-primary-50 rounded cursor-pointer whitespace-nowrap flex items-center",
        lang === "ar" && "flex-row-reverse"
      )}
      onClick={onChange}
    >
      <span
        className={cx(
          "w-5 h-5 rounded border-2 flex items-center justify-center",
          lang === "ar" ? "ml-3" : "mr-3",
          isChecked
            ? "border-primary-base bg-primary-base hover:border-gray-300 hover:bg-primary-100 focus:border-gray-300 focus:bg-white active:border-gray-300 active:bg-primary-100"
            : "border-gray-300 hover:bg-primary-100 active:bg-primary-100"
        )}
        style={{ minWidth: "1.25rem" }}
      >
        {isChecked && (
          <i className="material-icons text-sm text-white focus:text-primary-base active:text-primary-base">
            check
          </i>
        )}
      </span>
      {icon && (
        <div
          className={cx("flex")}
          style={{
            width: "24px",
            margin: lang === "ar" ? "0 0 0 0.5rem" : "0 0.5rem 0 0",
          }}
        >
          {icon}
        </div>
      )}
      {typeof title !== "string" ? (
        title
      ) : (
        <span className="truncate">{title}</span>
      )}
    </div>
  )
}
