import React, { useContext } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { context as localeContext } from 'context/locale';

const Text = ({
  full = true,
  prefix = '',
  prefixElement = undefined,
  selectedLang = '',
  value,
  postfix = '',
  postfixElement = undefined,
  className = '',
  payload = '',
  onClick = () => {},
  testId,
  forcedDirection = '',
}) => {
  const { lang, direction, translate } = useContext(localeContext);

  return (
    <span
      className={cx('block', full && 'w-full', className)}
      style={forcedDirection ? { direction: forcedDirection } : { direction }}
      onClick={onClick}
      data-testid={testId}
    >
      {prefix && `${prefix} `}
      {prefixElement && prefixElement}
      {translate(value, (selectedLang = selectedLang ? selectedLang : lang), payload)}
      {postfix && ` ${postfix}`}
      {postfixElement && postfixElement}
    </span>
  );
};

Text.propTypes = {
  full: PropTypes.bool,
  prefix: PropTypes.string,
  prefixElement: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  selectedLang: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  postfix: PropTypes.string,
  postfixElement: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  className: PropTypes.string,
  payload: PropTypes.string,
  onClick: PropTypes.func,
  testId: PropTypes.string,
  forcedDirection: PropTypes.string,
};

export default Text;
