import React, { useContext, useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { parse } from 'query-string';

import { breadcrumbs } from 'constants/translations';
import { useQueryState, usePriceFormat } from 'hooks/index';
import { Layout, Breadcrumbs } from 'components/common/dashboard';
import { Table, TableFooter, Input } from 'components/form/elements';
import { Text } from 'components/service';
import { Spinner } from 'components/kit';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { DateRange } from 'components/common/analytics';
import { context as notificationsContext } from 'context/notifications';
import { parseRange, stringifyRange, toRangeVariable, getRange } from 'utils/date';
import DATE_RANGES from 'constants/date';
import * as translations from 'constants/translations';
import { ReactComponent as SearchIcon } from 'assets/search.svg';
import GEO_AREAS from './schemas';
import { exportGeoAreasReport } from '../../../../rest';
import { transformGeoAreaData } from '../utils';
import { ANALYTICS_TAB_NAMES } from '../../constants';
import momentLanguage from '../../utils';

const GeoAreas = ({ branchId }) => {
  const { lang, translate, direction } = useContext(localeContext);
  const { selectedStore, branches, settings } = useContext(userContext);
  const notifications = useContext(notificationsContext);
  const searchTimeout = useRef();
  const [searchValue, setSearchValue] = useState();
  const [transformedData, setTransformedData] = useState([]);
  const formatPrice = usePriceFormat();
  const { type_date: typeDate, range } = parse(document.location.search);
  const resettableStates = { page: 1 };
  const [query, setQuery] = useQueryState(
    {
      type_date: typeDate || DATE_RANGES.THIRTY_DAYS,
      ...resettableStates,
      range: range || stringifyRange(getRange(DATE_RANGES.THIRTY_DAYS)),
    },
    {
      page: parseInt,
    },
  );

  moment.locale(momentLanguage(lang));

  const parsedRange = parseRange(query.range);
  const rangeVariable = toRangeVariable(parsedRange);
  const LIMIT = 30;
  const { data, loading } = useQuery(GEO_AREAS, {
    variables: {
      storeId: selectedStore.id,
      range: rangeVariable,
      branchId,
      page: query.page,
      limit: LIMIT,
      filter: searchValue,
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    setTransformedData(transformGeoAreaData(data?.geoAnalyticsAreas?.data?.areas));
  }, [data]);

  const formatData = values =>
    values.map(item => ({
      area: <Text value={item.zoneName ? [item.zoneName, item.zoneName] : item.area} />,
      totalSales: formatPrice(item.totalSales),
      avgOrderValue: formatPrice(item.avgOrderValue),
      totalOrders: item.totalOrders,
      totalCustomers: item.totalCustomers,
    }));

  return (
    <Layout
      top={
        <Breadcrumbs
          path={settings.useDeliveryzonesMs ? breadcrumbs.GEO_ANALYTICS_AREAS_ZONES : breadcrumbs.GEO_ANALYTICS_AREAS}
          right={
            <DateRange
              queryTypeDate={query.type_date}
              range={parsedRange}
              query={query}
              setQuery={setQuery}
              selectedStoreCreatedAt={selectedStore.createdAt}
              direction={direction}
              notifications={notifications}
              resettableStates={resettableStates}
            />
          }
        />
      }
    >
      <div>
        <div className="pb-32 flex flex-col">
          <Text value={branches.find(item => item.id === branchId)} className="text-lg font-bold mb-4" />
          <div>
            <div className="mb-4 flex justify-between items-center" style={{ direction }}>
              <Input
                type="text"
                search
                icon={<SearchIcon />}
                width="full md:w-1/2"
                value={searchValue}
                onChange={e => {
                  clearTimeout(searchTimeout.current);
                  const { value } = e.target;
                  searchTimeout.current = setTimeout(() => {
                    setSearchValue(value);
                  }, 500);
                }}
                placeholder={translate(
                  settings.useDeliveryzonesMs
                    ? translations.PLACEHOLDER_SEARCH_ZONES
                    : translations.PLACEHOLDER_SEARCH_AREAS,
                )}
              />
            </div>
          </div>
          {loading ? (
            <Spinner />
          ) : (
            <div className="w-full">
              <Table
                dataHeader={[
                  {
                    key: ANALYTICS_TAB_NAMES.area.camelCase,
                    name: <Text value={settings.useDeliveryzonesMs ? translations.ZONE : translations.AREA} />,
                    sort: false,
                    inView: false,
                  },
                  {
                    key: ANALYTICS_TAB_NAMES.totalSales.camelCase,
                    name: <Text value={translations.TOTAL_SALES} />,
                    inView: false,
                  },
                  {
                    key: ANALYTICS_TAB_NAMES.averageOrderValue.camelCase,
                    name: <Text value={translations.AVERAGE_ORDER_VALUE} />,
                    inView: false,
                  },
                  {
                    key: ANALYTICS_TAB_NAMES.totalOrders.camelCase,
                    name: <Text value={translations.TOTAL_ORDERS} />,
                    inView: false,
                  },
                  {
                    key: ANALYTICS_TAB_NAMES.totalCustomers.camelCase,
                    name: <Text value={translations.TOTAL_CUSTOMERS} />,
                    inView: false,
                  },
                ]}
                dataBody={transformedData}
                formatData={values => formatData(values)}
                footer={
                  <TableFooter
                    totalRecords={data?.geoAnalyticsAreas?.data.meta.totalCount}
                    pageLimit={LIMIT}
                    currentPage={query.page}
                    onPageChanged={({ currentPage }) => setQuery({ page: currentPage })}
                    totalCount={transformedData.length}
                    exportLink={() =>
                      (window.location.href = exportGeoAreasReport(
                        selectedStore.id,
                        parsedRange[0],
                        parsedRange[1],
                        branchId,
                      ))
                    }
                  />
                }
              />
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

GeoAreas.propTypes = {
  branchId: PropTypes.string.isRequired,
};

export default GeoAreas;
