import { gql } from 'graphql-request';

const deleteBulkDeliveryZonesMutation = gql`
  mutation($input: DeleteBulkDeliveryZonesInput!) {
    deleteBulkDeliveryZones(input: $input) {
      deliveryZones
      errors {
        zoneId
        errorMessage
      }
    }
  }
`;

export default deleteBulkDeliveryZonesMutation;
