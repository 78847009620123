import { gql } from "@apollo/client"

export const CATEGORIES = gql`
  query($storeId: String!) {
    menuSections(restaurantId: $storeId) {
      menuSections {
        id
        titleAr
        titleEn
        photoUrl
        publishedBranchIds
        menuItemsCount
      }
    }
  }
`

export const CHECK_STOCK_ENABLE = gql`
  query($storeId: String!) {
    settings(restaurantId: $storeId) {
      isStockEnabled
      lowStockThreshold
    }
  }
`
export const DISABLE_STOCK = gql`
  mutation($storeId: String!, $isStockEnabled: Boolean!) {
    updateSettings(restaurantId: $storeId, isStockEnabled: $isStockEnabled) {
      isStockEnabled
    }
  }
`

export const STOCKS = gql`
  query(
    $storeId: String!
    $branchId: Int!
    $page: Int
    $limit: Int
    $title: String
    $categoryId: String
    $status: String
  ) {
    stocks(
      restaurantId: $storeId
      branchId: $branchId
      page: $page
      limit: $limit
      title: $title
      categoryId: $categoryId
      status: $status
    ) {
      stocks {
        id
        branchId
        variantId
        variantTitleEn
        variantTitleAr
        barCode
        sku
        variantPhotoUrl
        status
        stockCount
      }
      totalCount
    }
  }
`

export const UPDATE_STOCK_STATUS = gql`
  mutation(
    $storeId: String!
    $id: String!
    $outOfStock: Boolean
    $tracked: Boolean
  ) {
    updateStockStatus(
      restaurantId: $storeId
      id: $id
      outOfStock: $outOfStock
      tracked: $tracked
    ) {
      id
      status
    }
  }
`

export const UPDATE_STOCK_QUANTITY = gql`
  mutation(
    $storeId: String!
    $branchId: Int!
    $variantId: Int!
    $id: String!
    $quantity: Int
  ) {
    updateStockQuantity(
      restaurantId: $storeId
      branchId: $branchId
      variantId: $variantId
      id: $id
      quantity: $quantity
    ) {
      id
      stockCount
    }
  }
`
export const UPDATE_STOCK_BARCODE = gql`
  mutation($storeId: String!, $variantId: Int!, $barCode: String) {
    updateStockBarcode(
      restaurantId: $storeId
      variantId: $variantId
      barCode: $barCode
    ) {
      id
      barCode
    }
  }
`
export const UPDATE_STOCK_SKU = gql`
  mutation($storeId: String!, $variantId: Int!, $sku: String) {
    updateStockSKU(restaurantId: $storeId, variantId: $variantId, sku: $sku) {
      id
      sku
    }
  }
`
