import React, { useContext } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import { BACKEND_GRAPHQL_SERVER } from 'constants/backendGraphqlServer';
import { context as userContext } from 'context/user';
import { context as notificationsContext } from 'context/notifications';
import * as translations from 'constants/translations';
import { Text } from 'components/service';
import { DELETE_ORERFAST_LINK, ORDERFAST_LINKS } from '../schemas';

const useDeleteOrderfastLink = ({ lang }) => {
  const { selectedStore } = useContext(userContext);
  const notifications = useContext(notificationsContext);
  const isArabic = lang === 'ar';

  const { subdomain } = selectedStore || {};

  const { updateQuery } = useQuery(ORDERFAST_LINKS, {
    variables: { subdomain },
    context: { serverName: BACKEND_GRAPHQL_SERVER },
  });

  const [deleteLink] = useMutation(DELETE_ORERFAST_LINK, {
    context: { serverName: BACKEND_GRAPHQL_SERVER },
  });

  const handleDeleteOrderfastLink = async orderfastLink => {
    const { id: orderfastLinkId, titleEn, titleAr } = orderfastLink;
    const { data } = await deleteLink({ variables: { subdomain, orderfastLinkId } });

    if (data?.destroyOrderfastLink?.success) {
      updateQuery?.(prevData => ({
        ...prevData,
        orderfastLinks: prevData.orderfastLinks.filter(link => link.id !== orderfastLinkId),
      }));
      notifications.show(<Text value={translations.THE_LINK_DELETED} payload={(isArabic && titleAr) || titleEn} />);
    }
  };

  return [handleDeleteOrderfastLink];
};

export default useDeleteOrderfastLink;
