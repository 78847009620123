// suggested zones types
export const suggestedZonesTypeUtils = {
  SUGGESTED: 'suggested',
  AREAS: 'areas',
};

// zone controller
export const zoneControllerUtils = {
  isAdd: false,
  isEdit: false,
  deleteZoneNotify: false,
  updateZoneNotify: false,
  addZoneNotify: false,
  drawerMode: null,
  showDrawerMode: false,
  enableDrawerMode: true,
  shapeRef: null,
  selectedBranch: null,
  selectedBranchId: null,
  selectedZone: null,
  prevZone: {
    paths: null,
    center: null,
    radius: null,
  },
  circleShape: null,
  showZoneTemplates: false,
  selectedSuggestedZone: null,
  [suggestedZonesTypeUtils.SUGGESTED]: true,
  [suggestedZonesTypeUtils.AREAS]: false,
  cityId: '',
  countryId: '',
  cityCode: '',
  countryCode: '',
  isNearestZonesAvailable: false,
  isCricle: false,
  cityTitle: '',
  hideZones: false,
  isBulk: false,
  isBulkSelected: false,
  selectedTemplateData: {
    coordinates: null,
    paths: null,
  },
};

// zone types
export const zoneTypeUtils = {
  POLYGON: 'Polygon',
  CIRCLE: 'Circle',
  POINT: 'Point',
};

// zone notifications types
export const zoneNotificationsTypeUtils = {
  SUCCESS: 'success',
  ERROR: 'error',
};

// static colors -> will be used to mark the branches
export const colorsUtils = [
  '#37995D',
  '#B29812',
  '#8659DF',
  '#80460D',
  '#268071',
  '#A5388D',
  '#29B1CC',
  '#D9742B',
  '#80192B',
  '#628026',
  '#661F66',
  '#E5C317',
];
export const primaryColorUtils = '#196EFF';

// zone drawer mode
export const zoneDrawerUtils = {
  FREEDRAWING: 'free',
  TEMPLATES: 'templates',
};

// dataLayer removal function
export const dataLayerRemoval = (dataLayer, map) => {
  dataLayer.forEach(feature => {
    map.data.remove(feature);
  });
};

// delivery time converter
export const convertedDeliveryTimeUtils = (index1, index0, helper) => {
  const timeValue = index1 === 'hrs' ? helper.fromHoursToMinutes(index0) : Number(index0);
  return timeValue;
};

// check if objects are equal
export const checkEqual = (arr, attr) => arr.every(val => val[attr] === arr[0][attr]);

// check if deliveryHours contain only 1 day on
export const checkOnDaysCounter = (arr, attr) => arr.filter(arrItem => arrItem[attr] === false);

// local store selected zone
export const selectedZoneIdUtils = {
  SELECTEDZONEID: 'selectedZoneId',
};

// zones form inputs name
export const zonesInputNames = {
  DELIVERY_TIME: 'delivery_time',
  ZONE_NAME: 'zone_name',
};
// zones CRUD validations
export const dzCrudErrorHandler = (res, setErr, translate, translations) => {
  const graphqlErrors = res?.errors[0]?.message;
  const errorObj = {};
  if (graphqlErrors) {
    Object.entries(graphqlErrors).map(value => {
      if (value[0] === zonesInputNames.DELIVERY_TIME) {
        const time = value[1][0].match(/\(([^\\)]+)\)/g)[0];
        Object.assign(errorObj, { deliveryTime: translate(translations.DELIVERY_TIME_PREPARATION(time)) });
      }
      if (value[0] === zonesInputNames.ZONE_NAME) {
        Object.assign(errorObj, { zoneName: translate(translations.ZONE_NAME_EXIST) });
      }
      return null;
    });
  }
  setErr(errorObj);
};

// static id for all branches
export const allBranchesId = -1;

// opening hours attr
export const openingHoursAttr = {
  OPENAT: 'open-at',
  CLOSEAT: 'close-at',
  OFFDAY: 'off-day',
};

// static all cities
export const allCitiesUtils = {
  id: '-1',
  code: null,
};

// zones pagination page size
export const zonesPageSize = 25;

// viewport
export const viewPortUtils = {
  RESTAURANT_VIEWPORT: 'restaurantViewPort',
  BRANCH_VIEWPORT: 'branchViewPort',
};
