import { gql } from 'graphql-request';

const deliveryZonesQuery = gql`
  query($restaurantReferenceId: ID!, $branchReferenceId: ID) {
    deliveryZones(restaurantReferenceId: $restaurantReferenceId, branchReferenceId: $branchReferenceId) {
      id
      zoneName
    }
  }
`;

export default deliveryZonesQuery;
