import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { parse } from 'query-string';

import DATE_RANGES from 'constants/date';
import { breadcrumbs } from 'constants/translations';
import { useQueryState, usePriceFormat } from 'hooks/index';
import { Layout, Breadcrumbs } from 'components/common/dashboard';
import { Table, TableFooter } from 'components/form/elements';
import { Text } from 'components/service';
import { Spinner, Tabs, DropDown } from 'components/kit';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { DateRange, Period } from 'components/common/analytics';
import { context as notificationsContext } from 'context/notifications';
import { parseRange, stringifyRange, toRangeVariable, getRange } from 'utils/date';
import * as translations from 'constants/translations';
import * as paths from 'paths';
import BEHAVIOR_BREAKDOWN from './schemas';
import { exportBehaviorReport } from '../../../../rest';
import transformDeliveryPickupData from './utils';
import { BEHAVIOR_BREAKDOWN_TABS, DATA_SELECTION } from '../constants';
import { ANALYTICS_TAB_NAMES } from '../../constants';
import momentLanguage from '../../utils';

const DeliveryPickup = () => {
  const { lang, direction } = useContext(localeContext);
  const { selectedStore } = useContext(userContext);
  const notifications = useContext(notificationsContext);
  const formatPrice = usePriceFormat();
  const { type_date: typeDate, behavior_date: behaviorDate, behavior_selection: behaviorSelection, range } = parse(
    document.location.search,
  );
  const [transformedData, setTransformedData] = useState([]);
  const resettableStates = { page: 1 };
  const [
    {
      type_date: queryTypeDate,
      date: queryDate,
      behavior_selection: queryBehaviorSelection,
      page: queryPage,
      range: queryRange,
    },
    setQuery,
  ] = useQueryState(
    {
      type_date: typeDate || DATE_RANGES.THIRTY_DAYS,
      date: behaviorDate || 'day',
      behavior_selection: behaviorSelection || ANALYTICS_TAB_NAMES.totalSales.snakeCaseName,
      ...resettableStates,
      range: range || stringifyRange(getRange(DATE_RANGES.THIRTY_DAYS)),
    },
    {
      page: parseInt,
    },
  );
  useEffect(() => {
    setQuery({ page: 1 });
  }, [queryBehaviorSelection]);

  const display = [
    ANALYTICS_TAB_NAMES.totalSales.snakeCaseName,
    ANALYTICS_TAB_NAMES.averageOrderValue.snakeCaseName,
  ].includes(queryBehaviorSelection)
    ? formatPrice
    : x => x || 0;

  moment.locale(momentLanguage(lang));

  const parsedRange = parseRange(queryRange);
  const rangeVariable = toRangeVariable(parsedRange);
  const LIMIT = 30;
  const { data, loading } = useQuery(BEHAVIOR_BREAKDOWN, {
    variables: {
      storeId: selectedStore.id,
      range: rangeVariable,
      date: queryDate,
      breakdown: BEHAVIOR_BREAKDOWN_TABS.DELIVERY_TYPE,
      metric: queryBehaviorSelection,
      page: queryPage,
      limit: LIMIT,
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (data?.behaviorBreakdown?.data)
      setTransformedData(
        transformDeliveryPickupData(data?.behaviorBreakdown?.data[DATA_SELECTION[queryBehaviorSelection]]),
      );
  }, [data, queryBehaviorSelection]);

  const formatData = values =>
    values.map(item => ({
      date:
        queryDate === 'day'
          ? moment(new Date(item.date)).format('MMM DD, YYYY')
          : moment(new Date(item.date)).format('MMMM, YYYY'),
      delivery: display(item.delivery),
      pickup: display(item.pickup),
    }));

  return (
    <Layout
      top={
        <Breadcrumbs
          path={breadcrumbs.DELIVERY_VS_PICKUP}
          right={
            <DateRange
              queryTypeDate={queryTypeDate}
              range={parsedRange}
              selectedStoreCreatedAt={selectedStore.createdAt}
              notifications={notifications}
              setQuery={setQuery}
              direction={direction}
              resettableStates={resettableStates}
            />
          }
        />
      }
    >
      {loading ? (
        <Spinner />
      ) : (
        <div>
          <div className="pb-32 flex flex-col">
            <Text value={translations.BREAKDOWN_BY} className="text-lg font-bold mb-4" />
            <div>
              <div className="mb-4">
                <Tabs
                  items={[
                    {
                      title: <Text value={translations.DELIVERY_VS_PICKUP} />,
                      to: paths.breakdownDeliveryPickup,
                    },
                    {
                      title: <Text value={translations.PAYMENT_METHODS_ANALYTICS} />,
                      to: paths.breakdownPaymentMethods,
                    },
                    {
                      title: <Text value={translations.NEW_VS_RETURNING} />,
                      to: paths.breakdownNewVsReturning,
                    },
                  ]}
                />
              </div>
            </div>
            <div>
              <div className="mb-4 flex justify-between items-center">
                <DropDown
                  float="left"
                  optionSelected={queryBehaviorSelection}
                  onSelect={option => setQuery({ behavior_selection: option })}
                  data={[
                    {
                      id: ANALYTICS_TAB_NAMES.totalSales.snakeCaseName,
                      title: <Text value={translations.TOTAL_SALES} />,
                    },
                    {
                      id: ANALYTICS_TAB_NAMES.averageOrderValue.snakeCaseName,
                      title: <Text value={translations.AVERAGE_ORDER_VALUE} />,
                    },
                    {
                      id: ANALYTICS_TAB_NAMES.totalOrders.snakeCaseName,
                      title: <Text value={translations.TOTAL_ORDERS} />,
                    },
                    {
                      id: ANALYTICS_TAB_NAMES.totalCustomers.snakeCaseName,
                      title: <Text value={translations.TOTAL_CUSTOMERS} />,
                    },
                  ]}
                  icon="keyboard_arrow_down"
                  position={lang === 'ar' ? 'right' : 'left'}
                />
                <Period value={queryDate} onChange={value => setQuery({ page: 1, date: value })} />
              </div>
            </div>
            <div className="w-full">
              <Table
                dataHeader={[
                  {
                    key: 'date',
                    name: queryDate === 'day' ? <Text value={translations.DAY} /> : <Text value={translations.MONTH} />,
                    sort: false,
                    inView: false,
                  },
                  {
                    key: 'delivery',
                    name: <Text value={translations.DELIVERY} />,
                    sort: false,
                    inView: false,
                  },
                  {
                    key: 'pickup',
                    name: <Text value={translations.PICKUP} />,
                    sort: false,
                    inView: false,
                  },
                ]}
                dataBody={transformedData}
                formatData={values => formatData(values)}
                footer={
                  <TableFooter
                    totalRecords={data?.behaviorBreakdown?.totalCount}
                    pageLimit={LIMIT}
                    currentPage={queryPage}
                    onPageChanged={({ currentPage }) => setQuery({ page: currentPage })}
                    totalCount={transformedData.length}
                    exportLink={() =>
                      (window.location.href = exportBehaviorReport(
                        selectedStore.id,
                        parsedRange[0],
                        parsedRange[1],
                        queryDate,
                        BEHAVIOR_BREAKDOWN_TABS.DELIVERY_TYPE,
                        queryBehaviorSelection,
                      ))
                    }
                  />
                }
              />
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default DeliveryPickup;
