import React, { useContext } from "react"
import { useQuery, useLazyQuery, useMutation } from "@apollo/client"
import { Formik, Form } from "formik"
import * as yup from "yup"
import cx from "classnames"
import { useSelectedStore } from "hooks"
import { Field, Text } from "components/service"
import { Button, Stack, SpinnerAlt, AutoComplete } from "components/kit"
import { Label, Container, Row, Footer } from "components/form/generic"
import { Input } from "components/form/elements"
import { context as userContext } from "context/user"
import * as translations from "constants/translations"
import { context as localeContext } from "context/locale"
import { context as notificationsContext } from "context/notifications"
import * as schemas from "./schemas"
import { handleSubmissionErrors } from "./../OptionGroupModal/utils"
const OptionsModal = ({
  initialValues,
  type,
  onCancel,
  openModel,
  refetchOptions,
  refetchAllOptions,
  refetchAllGroup,
  setOptionGroupSelected,
}) => {
  const { lang, translate } = useContext(localeContext)
  const { selectedStore: selected } = useContext(userContext)
  const currency = selected.currency
  const notifications = useContext(notificationsContext)

  const storeId = useSelectedStore()
  const direction = lang === "ar" ? "rtl" : "ltr"

  const validationSchema = yup.object().shape({
    titleEn: yup
      .string()
      .required(<Text value={translations.TITLE_EN_IS_REQ} />),
    titleAr: yup
      .string()
      .required(<Text value={translations.TITLE_AR_IS_REQ} />),
    price: yup
      .number()
      .typeError(<Text value={translations.OPTION_GROUPS_PRICE_NUMBER} />)
      .required(<Text value={translations.OPTION_GROUPS_PRICE_REQ} />),
    ...(type === "add" && {
      optionGroupName: yup
        .string()
        .required(<Text value={translations.OPTION_GROUPS_GROUP_NAME_REQ} />),
    }),
    ...(initialValues.isFoodics && {
      externalId: yup
        .string()
        .required(<Text value={translations.OPTION_GROUPS_EXTERNALID_REQ} />),
    }),
  })

  const optionGroups = useQuery(schemas.OPTION_GROUPS, {
    variables: {
      restaurantId: storeId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  })
  const [
    loadOptions,
    { loading: optionsLoading, data: optionsData },
  ] = useLazyQuery(schemas.OPTIONS)

  const [createOption, { loading: isStillCreating }] = useMutation(
    schemas.CREATE_OPTION,
    {
      onCompleted: async () => {
        initialValues.optionGroupId !== "all" &&
          (await refetchOptions.refetch())
        await refetchAllGroup.refetch()
        await refetchAllOptions.refetch()
      },
    }
  )

  const [updateOption, { loading: isStillUpdating }] = useMutation(
    schemas.UPDATE_OPTION
  )

  return (
    <Formik
      initialValues={
        type === "edit"
          ? {
              titleEn: initialValues.item.titleEn,
              titleAr: initialValues.item.titleAr,
              externalId: initialValues.item.externalId,
              price: initialValues.item.price,
            }
          : {}
      }
      validationSchema={validationSchema}
      onSubmit={async values => {
        let variables = {
          restaurantId: storeId,
          titleEn: values.titleEn,
          titleAr: values.titleAr,
          price: parseFloat(values.price),
          externalId: values.externalId,

          ...(type === "add"
            ? {
                propertyId: values.optionGroupName.id.toString(),
              }
            : {
                propertyId:
                  initialValues.optionGroupId === "all"
                    ? initialValues.item.propertyId.toString()
                    : initialValues.optionGroupId.toString(),
                id: initialValues.item.id,
              }),
        }
        type === "add"
          ? await createOption({ variables: variables })
              .then(() => {
                setOptionGroupSelected &&
                  setOptionGroupSelected(values.optionGroupName.id)
                notifications.show(
                  <Text value={translations.OPTION_GROUPS_ADD_OPTION_SUCCESS} />
                )
              })
              .catch(err => {
                notifications.show(
                  <Text value={translations.SOMETHING_WENT_WRONG} />,
                  "error"
                )
              })
          : await updateOption({ variables: variables })
              .then(() => {
                notifications.show(
                  <Text
                    value={translations.OPTION_GROUPS_EDIT_OPTION_SUCCESS}
                  />
                )
              })
              .catch((e) => {
                const message = handleSubmissionErrors(e)
                notifications.show(message, "error")
              })

        onCancel()
      }}
      onCancel={onCancel}
    >
      {({ values, setFieldValue, handleChange, ...rest }) => (
        <Form style={{ direction }}>
          <div className="px-4 my-4 w-full">
            <Container>
              {type === "add" && (
                <Row className="flex-col md:flex-row my-4">
                  <Label
                    title={
                      <Text value={translations.OPTION_GROUPS_GROUP_NAME} />
                    }
                  >
                    {!optionGroups.data ? (
                      <SpinnerAlt
                        color="primary-base"
                        borderTopColor={"#d8dae6"}
                      />
                    ) : (
                      <Field
                        component={AutoComplete}
                        name="optionGroupName"
                        testId="optionGroups-add-optionGroupName"
                        data={
                          optionGroups.data &&
                          optionGroups.data.propertySections.map(option => {
                            return {
                              id: option.id,
                              title: translate(option),
                            }
                          })
                        }
                        onChange={e => {
                          handleChange(e)
                          loadOptions({
                            variables: {
                              restaurantId: storeId,
                              propertySectionId: e.target.value,
                            },
                          })
                          let item = optionGroups.data.propertySections.find(
                            item => item.id === e.target.value
                          )
                          setFieldValue("optionGroupName", item)
                        }}
                        placeholder={
                          translations.OPTION_GROUPS_YOUR_CHOISE[
                            lang === "en" ? 0 : 1
                          ]
                        }
                      />
                    )}
                  </Label>
                </Row>
              )}
              {type === "edit" && (
                <Row
                  className="bg-primary-50 border rounded border-primary-base text-primary-base px-4 py-3 mt-3"
                  role="alert"
                >
                  <p className="text-sm">
                    <Text
                      value={translations.OPTION_GROUPS_EDIT_OPTION_NOTE}
                      className="text-xs"
                    />
                  </p>
                </Row>
              )}
              <Text
                value={translations.OPTION_GROUPS_OPTION_NAME}
                className="text-base -mb-4"
              />
              <Row className="flex-col md:flex-row">
                <div className="flex flex-col md:flex-row justify-between items-start">
                  <div
                    className={cx(
                      "w-full mb-6 md:mb-0",
                      lang === "en" ? "mr-0 md:mr-4" : "ml-0 md:ml-4"
                    )}
                  >
                    <Label title={<Text value={translations.ENGLISH} />}>
                      <Field
                        type="text"
                        data-testid="optionGroups-option-nameEn"
                        name="titleEn"
                        component={Input}
                        direction="ltr"
                        placeholder={translations.OPTION_GROUPS_ADD_GROUP_EX[0]}
                      />
                    </Label>
                  </div>
                  <div className="w-full mb-0 md:mb-0">
                    <Label title={<Text value={translations.ARABIC} />}>
                      <Field
                        direction="rtl"
                        type="text"
                        name="titleAr"
                        data-testid="optionGroups-option-nameAr"
                        component={Input}
                        placeholder={translations.OPTION_GROUPS_ADD_GROUP_EX[1]}
                      />
                    </Label>
                  </div>
                </div>
              </Row>
              <Row className="flex-col md:flex-row">
                <Label
                  htmlFor="primaryColor"
                  title={
                    <Text
                      value={translations.PRICE_CURRENCY}
                      payload={
                        lang === "en" ? currency.titleEn : currency.titleAr
                      }
                    />
                  }
                >
                  <Field
                    type="text"
                    data-testid="optionGroups-option-price"
                    name="price"
                    placeholder={
                      translations.OPTION_GROUPS_PRICE_EX[lang === "en" ? 0 : 1]
                    }
                    component={Input}
                    onChange={e => setFieldValue("price", e.target.value)}
                  />
                </Label>
              </Row>

              {initialValues.isFoodics && (
                <Row className="flex-col md:flex-row">
                  <Label
                    htmlFor="primaryColor"
                    title={
                      <Text
                        value={translations.OPTION_GROUPS_EXTERNALID_TITLE}
                      />
                    }
                    subtitle={
                      <Text
                        value={translations.OPTION_GROUPS_EXTERNALID_DESC}
                      />
                    }
                  >
                    <Field
                      type="text"
                      data-testid="optionGroups-option-externalId"
                      name="externalId"
                      placeholder={
                        translations.OPTION_GROUPS_EXTERNALID_PLACEHOLDER[
                          lang === "en" ? 0 : 1
                        ]
                      }
                      onChange={e =>
                        setFieldValue("externalId", e.target.value)
                      }
                      component={Input}
                    />
                  </Label>
                </Row>
              )}
            </Container>
          </div>
          <Footer modalFooter>
            <div className="w-full flex items-center">
              {type === "edit" && (
                <button
                  type="button"
                  data-testid="optionGroups-delete-optionBtn"
                  className="text-red-600 flex items-center outline-none focus:outline-none"
                  onClick={() => {
                    let variables = {
                      restaurantId: storeId,
                      propertyId:
                        initialValues.optionGroupId === "all"
                          ? initialValues.item.propertyId.toString()
                          : initialValues.optionGroupId.toString(),
                      id: initialValues.item.id,
                    }
                    openModel({
                      testId: "product-delete-modal",
                      title: (
                        <Text
                          value={translations.OPTION_GROUPS_DELETE_OPTION}
                        />
                      ),
                      body: (
                        <DeleteOptionBody
                          onCancel={onCancel}
                          optionGroupId={initialValues.optionGroupId}
                          variables={variables}
                          optionNameEn={values.titleEn}
                          optionNameAr={values.titleAr}
                          refetchAllOptions={refetchAllOptions}
                          refetchOptions={refetchOptions}
                          refetchAllGroup={refetchAllGroup}
                        />
                      ),
                      size: "max-w-sm",
                    })
                  }}
                >
                  <Text
                    className="text-sm"
                    value={translations.OPTION_GROUPS_DELETE_OPTION}
                  />
                </button>
              )}
              <div className={cx(lang === "ar" ? "mr-auto" : "ml-auto")}>
                <Stack direction="row">
                  <Button
                    data-testid={
                      type === "add"
                        ? "add-cancel-option-btn"
                        : "edit-cancel-option-btn"
                    }
                    type="button"
                    kind="tertiary"
                    onClick={onCancel}
                  >
                    <Text value={translations.CANCEL} />
                  </Button>
                  {type === "edit" ? (
                    <Button
                      data-testid="edit-save-option-btn"
                      kind="primary"
                      isSpinning={isStillUpdating}
                    >
                      <Text value={translations.SAVE} />
                    </Button>
                  ) : (
                    <Button
                      data-testid="add-option-btn"
                      kind="primary"
                      isSpinning={isStillCreating}
                    >
                      <Text value={translations.ADD} />
                    </Button>
                  )}
                </Stack>
              </div>
            </div>
          </Footer>
        </Form>
      )}
    </Formik>
  )
}

const DeleteOptionBody = ({
  onCancel,
  optionGroupId,
  variables,
  optionNameEn,
  optionNameAr,
  refetchOptions,
  refetchAllOptions,
  refetchAllGroup,
}) => {
  const { lang } = useContext(localeContext)
  const notifications = useContext(notificationsContext)

  const [deleteOption, { loading: isStillDeleting }] = useMutation(
    schemas.DELETE_OPTION,
    {
      onCompleted: async () => await refetchAllOptions.refetch(),
    }
  )
  const handleDeleteProduct = async (onCancel, variables) => {
    try {
      await deleteOption({ variables: variables }).then(() => {
        notifications.show(
          <Text
            value={translations.OPTION_GROUPS_DELETE_OPTION_SUCCESS}
            payload={lang === "en" ? optionNameEn : optionNameAr}
          />
        )
      })
      onCancel()
      refetchAllGroup.refetch()
      optionGroupId !== "all" && refetchOptions.refetch()
    } catch (error) {
      notifications.show(
        <Text value={translations.SOMETHING_WENT_WRONG} />,
        "error"
      )
    }
  }

  return (
    <div>
      <Text
        className="mx-1 p-4"
        value={translations.OPTION_GROUPS_DELETE_OPTION_CONFIRMATION}
      />
      <Footer modalFooter>
        <div className="w-full flex items-center">
          <div className={cx(lang === "ar" ? "mr-auto" : "ml-auto")}>
            <Stack direction="row">
              <Button
                data-testid="dismiss-delete-product"
                onClick={onCancel}
                type="button"
                kind="tertiary"
              >
                <Text value={translations.DISMISS} />
              </Button>
              <Button
                kind="secondaryError"
                onClick={() => handleDeleteProduct(onCancel, variables)}
                data-testid="btn-delete-option"
                isSpinning={isStillDeleting}
              >
                <Text value={translations.DELETE} />
              </Button>
            </Stack>
          </div>
        </div>
      </Footer>
    </div>
  )
}
export default OptionsModal
