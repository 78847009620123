import * as R from "ramda"

export const handleErrors = callback => err => {
  if (R.path(["graphQLErrors", 0, "extensions", "code"], err) === 404) {
    callback("user_not_found")
    return
  }

  callback("generic")
}
