import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

import { useSelectedStore, useMobile } from 'hooks/index';
import { Layout, Breadcrumbs } from 'components/common/dashboard';
import { Button } from 'components/kit';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { Text } from 'components/service';
import cx from 'classnames';
import * as translations from 'constants/translations';
import { ORDERS_LINKS } from 'constants/helperLinks';
import { breadcrumbs } from 'constants/translations';
import ExportOrders from '../ExportOrders';
import OrderRinger from '../../dashboard/Layout/orderRinger';
import OrderFrameFilters from './OrderFrameFilters';

const Frame = ({ children, status, statuses, paymentStatus, showOrderStatus, paymentStatuses }) => {
  const storeId = useSelectedStore();
  const { lang } = useContext(localeContext);
  const { branches } = useContext(userContext);
  const [busyTime, setBusyTime] = useState();
  const [allBranches, setAllBranches] = useState(null);
  const [busyMode, setBusyMode] = useState(null);
  const [externalVariables, setExternalVariables] = useState({});
  const [canExportWFilters, setCanExportWFilters] = useState(false);
  const [isManualOrderSlideoverOpened, setManualOrderSlideoverOpened] = useState(false);

  const isMobile = useMobile();

  return (
    <Layout
      top={
        <Breadcrumbs
          helperLinks={ORDERS_LINKS}
          path={breadcrumbs.ORDERS}
          className={cx('flex-row items-center justify-between', lang === 'ar' && 'flex-row-reverse')}
          right={
            <>
              {isMobile ? (
                <div className="flex">
                  <ExportOrders
                    canExportWFilters={canExportWFilters}
                    ordersVariables={externalVariables}
                    storeId={storeId}
                  />
                </div>
              ) : (
                <div className="flex px-4">
                  <OrderRinger />
                </div>
              )}
              <div className="flex px-4">
                <Button
                  kind="primary"
                  onClick={() => {
                    setManualOrderSlideoverOpened(true);
                  }}
                >
                  <Text value={translations.MANUAL_ORDER} />
                </Button>
              </div>
            </>
          }
        />
      }
    >
      {!branches || !branches.length ? (
        <div className="px-6">
          <Text value={translations.STORE_HAS_NO_BRANCHES} />
        </div>
      ) : (
        <OrderFrameFilters
          status={status}
          statuses={statuses}
          paymentStatus={paymentStatus}
          paymentStatuses={paymentStatuses}
          storeId={storeId}
          showOrderStatus={showOrderStatus}
          setBusyTime={setBusyTime}
          setAllBranches={setAllBranches}
          busyTime={busyTime}
          setBusyMode={setBusyMode}
          busyMode={busyMode}
          setExternalVariables={setExternalVariables}
          setCanExportWFilters={setCanExportWFilters}
          canExportWFilters={canExportWFilters}
          allBranches={allBranches}
          isManualOrderSlideoverOpened={isManualOrderSlideoverOpened}
          setManualOrderSlideoverOpened={setManualOrderSlideoverOpened}
        >
          {children}
        </OrderFrameFilters>
      )}
    </Layout>
  );
};

Frame.propTypes = {
  children: PropTypes.element.isRequired,
  status: PropTypes.string,
  statuses: PropTypes.arrayOf(PropTypes.string),
  paymentStatus: PropTypes.string,
  showOrderStatus: PropTypes.bool,
  paymentStatuses: PropTypes.arrayOf(PropTypes.string),
};

export default Frame;
