export const groupByCity = array =>
  Object.values(
    array.reduce((item, key) => {
      item[key.cityId] = [...(item[key.cityId] || []), key]
      return item
    }, {})
  )

export const calcDistance = (fromLat, fromLng, toLat, toLng) => {
  var radFromLat = (Math.PI * fromLat) / 180
  var radToLat = (Math.PI * toLat) / 180
  var radfromLng = (Math.PI * fromLng) / 180
  var radToLng = (Math.PI * toLng) / 180
  var theta = fromLng - toLng
  var radtheta = (Math.PI * theta) / 180
  var dist =
    Math.sin(radFromLat) * Math.sin(radToLat) +
    Math.cos(radFromLat) * Math.cos(radToLat) * Math.cos(radtheta)
  dist = Math.acos(dist)
  dist = (dist * 180) / Math.PI
  dist = dist * 60 * 1.1515
  dist = dist * 1.609344
  return dist
}
