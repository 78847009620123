export const THIRD_PARTY_TYPE_ENUM = {
  POS: 'POS',
  Delivery: 'Delivery',
};

export const COUNTRY_CODES_ENUM = {
  KUWAIT: 'KW',
  EGYPT: 'EG',
  QATAR: 'QA',
  BAHRIN: 'BH',
  SAUDI_ARABIA: 'SA',
  ARAB_EMIRATES: 'AE',
  OMAN: 'OM',
  JORDON: 'JO',
};
