import React, { useContext, useState, useRef } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';

import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { useSelectedStore, useMobile } from 'hooks';
import { RadioChaine } from 'components/form/elements';
import { context as notificationsContext } from 'context/notifications';
import { Button } from 'components/kit';
import { INITIAL, UPDATE_PRODUCT } from '../../../overview/AdvancedCreation/schemas';
import { handleTrackingItemsDisplayButtons, handleTrackingDesctiption } from '../../../overview/BasicDetails/utils';

const TrackingTypeChange = ({ menuItemDataTrack, productId }) => {
  const { lang } = useContext(localeContext);
  const storeId = useSelectedStore();
  const isMobile = useMobile();
  const fontSizeClass = cx(isMobile && 'text-xs');
  const [itemTrackingMethod, setItemTrackingMethod] = useState(menuItemDataTrack);
  const notifications = useContext(notificationsContext);
  const prevTrackingType = useRef(menuItemDataTrack);
  const { setTrackItemType } = useContext(userContext);

  const { data: menuItemData } = useQuery(INITIAL, {
    variables: {
      restaurantId: storeId,
      id: productId,
    },
    onCompleted: data => {
      setItemTrackingMethod(data?.menuItem.trackingType);
    },
  });

  const { menuItem: { id, titleAr, titleEn } = {} } = menuItemData || {};

  const [updateProduct, { loading: isUpdatingTrackingType }] = useMutation(UPDATE_PRODUCT, {
    onCompleted: ({ updateMenuItem }) => {
      notifications.show(
        <Text
          value={translations.MENU_ITEM_UPDATED(lang === 'en' ? updateMenuItem?.titleEn : updateMenuItem?.titleAr)}
        />,
      );
      if (itemTrackingMethod !== prevTrackingType.current) {
        setTrackItemType(true);
      }
    },
    onError: () => notifications.show(<Text value={translations.SOMETHING_WENT_WRONG} />, 'error'),
  });

  const changeTrackingType = async () => {
    await updateProduct({
      variables: {
        restaurantId: storeId,
        menuItemId: id,
        titleEn,
        titleAr,
        trackingType: itemTrackingMethod,
      },
    });
  };
  return (
    <div>
      <RadioChaine
        name="trackingType"
        onChange={e => {
          setItemTrackingMethod(e.target.value);
        }}
        direction="col"
        value={itemTrackingMethod}
        size="flex_center_popup"
        trackedItem
        trackPopup
        items={handleTrackingItemsDisplayButtons(Text, fontSizeClass)}
      />
      <Text
        className="text-sm font-normal leading-snug tracking-normal text-gray-600 mt-1 mb-4"
        value={handleTrackingDesctiption(itemTrackingMethod)}
      />
      <Button
        size="popup"
        kind="primary"
        onClick={changeTrackingType}
        disabled={isUpdatingTrackingType}
        isSpinning={isUpdatingTrackingType}
      >
        <Text value={translations.SAVE} />
      </Button>
    </div>
  );
};

TrackingTypeChange.propTypes = {
  menuItemDataTrack: PropTypes.string.isRequired,
  productId: PropTypes.string.isRequired,
};

export default TrackingTypeChange;
