import { gql } from "@apollo/client"

export const CATEGORIES = gql`
  query($storeId: String!) {
    menuSections(restaurantId: $storeId) {
      menuSections {
        id
        titleAr
        titleEn
        photoUrl
        publishedBranchIds
        menuItemsCount
      }
    }
  }
`

export const MENU_PAGE = gql`
  query($storeId: String!, $filter: String) {
    menuPages(restaurantId: $storeId, filter: $filter) {
      menuPages {
        id
        titleAr
        titleEn
        published
        position
        publishedBranchIds
        menuItems {
          id
          titleEn
          titleAr
          published
          originalPrice
          photoUrl
          position
          discountEnabled
        }
        totalMenuItemsCount
      }
    }
  }
`

export const FETCH_ALL_MENU = gql`
  query(
    $restaurantId: String!
    $menuSectionId: String
    $limit: String
    $offset: String
  ) {
    fetchAllMenuItems(
      restaurantId: $restaurantId
      menuSectionId: $menuSectionId
      limit: $limit
      offset: $offset
    ) {
      fetchAllMenuItems {
        id
        titleEn
        titleAr
        published
        originalPrice
        photoUrl
        position
        discountEnabled
      }
    }
  }
`

export const SORT_MENU_PAGE_CATEGORIES = gql`
  mutation($storeId: String!, $positions: [MenuPagePositions!]!) {
    updateMenuCategoryPositions(restaurantId: $storeId, positions: $positions)
  }
`

export const SORT_MENU_PAGE_PRODUCTS = gql`
  mutation(
    $storeId: String!
    $menuSectionId: String!
    $positions: [MenuPagePositions!]!
  ) {
    updateMenuProductPositions(
      restaurantId: $storeId
      menuSectionId: $menuSectionId
      positions: $positions
    )
  }
`

export const SINGLE_CATEGORY = gql`
  query($storeId: String!, $menuSectionId: String!) {
    menuSection(restaurantId: $storeId, menuSectionId: $menuSectionId) {
      id
      titleAr
      titleEn
      photoUrl
      publishedBranchIds
      menuItemsCount
    }
  }
`
export const ADD_CATEGORY = gql`
  mutation(
    $storeId: String!
    $titleEn: String!
    $titleAr: String!
    $publishedBranchIds: [String!]
    $position: Int
  ) {
    createMenuSection(
      restaurantId: $storeId
      titleEn: $titleEn
      titleAr: $titleAr
      publishedBranchIds: $publishedBranchIds
      position: $position
    ) {
      id
      titleEn
      titleAr
      position
    }
  }
`
export const UPDATE_CATEGORY = gql`
  mutation(
    $storeId: String!
    $titleEn: String!
    $titleAr: String!
    $id: String!
    $photoUrl: String
    $publishedBranchIds: [String]
  ) {
    updateMenuSection(
      restaurantId: $storeId
      titleEn: $titleEn
      titleAr: $titleAr
      menuSectionId: $id
      photoUrl: $photoUrl
      publishedBranchIds: $publishedBranchIds
    ) {
      id
      titleEn
      titleAr
    }
  }
`

export const DELETE_CATEGORY = gql`
  mutation($storeId: String!, $id: String!) {
    deleteMenuSection(restaurantId: $storeId, menuSectionId: $id) {
      id
    }
  }
`

export const PRODUCTS = gql`
  query(
    $storeId: String!
    $categoryId: String
    $productTitle: String
    $limit: Int
    $page: Int
    $lang: String
  ) {
    menuItems(
      restaurantId: $storeId
      menuSectionId: $categoryId
      productTitle: $productTitle
      limit: $limit
      page: $page
      lang: $lang
    ) {
      menuItems {
        id
        titleEn
        titleAr
        externalId
        photoUrl
        displayPrice
        unpublishedBranchesCount
        publishedBranchIds
        discountEnabled
      }
      totalCount
    }
  }
`

export const UPDATE_PRODUCT = gql`
  mutation(
    $storeId: String!
    $productId: String!
    $publishedBranchIds: [String]
  ) {
    updateMenuItem(
      restaurantId: $storeId
      menuItemId: $productId
      publishedBranchIds: $publishedBranchIds
    ) {
      id
    }
  }
`

export const UPDATE_PRODUCT_BY_BRANCH = gql`
  mutation(
    $storeId: String!
    $productId: String!
    $branchId: String!
    $published: Boolean
  ) {
    updateMenuItemForBranch(
      restaurantId: $storeId
      branchId: $branchId
      menuItemId: $productId
      published: $published
    ) {
      id
    }
  }
`

export const DELETE_PRODUCT = gql`
  mutation($storeId: String!, $id: String!) {
    deleteMenuItem(restaurantId: $storeId, menuItemId: $id) {
      id
    }
  }
`
export const SETTINGS = gql`
  query($storeId: String!) {
    settings(restaurantId: $storeId) {
      id
      menuItemSorting
    }
  }
`

export const UPDATE_SORTING_PRODUCT = gql`
  mutation($storeId: String!, $menuItemSorting: Int) {
    updateProductsSorting(
      restaurantId: $storeId
      menuItemSorting: $menuItemSorting
    ) {
      id
      menuItemSorting
    }
  }
`

export const IMPORT_PRODUCTS_FROM_POS = gql`
  mutation($storeId: String!, $menuGroupId: String!) {
    importProductsFromPOS(
      storeId: $storeId
      menuGroupId: $menuGroupId
    ) {
      success
    }
  }
`
export const GET_LIST_OF_TAGS = gql`
  query($storeId: String!, $posName: String!, $partnerType: String! ) {
    tagsList(
      storeId: $storeId,
      posName: $posName,
      partnerType: $partnerType
    ) {
      titleEn
      titleAr
      results {
        titleEn
        titleAr
        id    
      }
    }
  }
`

export const BULK_UPDATE_POS_BRANCHES = gql`
  mutation($branchesLinks: [BranchesLinks]) {
    linkInternalBranchesToPosBranches(
      branchesLinks: $branchesLinks
    ) {
      branchInternalId
      branchExternalId
      partnerName
    }
  }
`
