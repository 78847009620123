import React from 'react';
import PropTypes from 'prop-types';
import { useQuery, NetworkStatus } from '@apollo/client';
import Carousel from 'nuka-carousel';
import cx from 'classnames';
import { useMediaQuery } from 'react-responsive';

import * as translations from 'constants/translations';
import { Spinner, LoadingContainer } from 'components/kit';
import { Text } from 'components/service';
import * as paths from 'paths';
import Fallback from 'components/common/Fallback';
import { ReactComponent as LeftIcon } from 'assets/icons-chevron-left.svg';
import { ReactComponent as RightIcon } from 'assets/icons-chevron-right.svg';
import { ReactComponent as IconMoney } from 'assets/icon-money.svg';
import { ReactComponent as TopProductsSkeleton } from 'assets/skeleton-top-products.svg';
import EmptyState from 'components/common/EmptyState';
import { usePriceFormat, useSelectedStore } from 'hooks/index';
import * as schemas from '../schemas';
import ReportLink from '../../Main/ReportLink';
import Product from './Product';

const Products = ({ range }) => {
  const xs = useMediaQuery({ maxWidth: 376 });
  const sm = useMediaQuery({ maxWidth: 610 });
  const md = useMediaQuery({ maxWidth: 768 });
  const lg = useMediaQuery({ maxWidth: 1024 });
  const xl = useMediaQuery({ maxWidth: 1280 });
  const xl2 = useMediaQuery({ maxWidth: 1368 });
  const xl3 = useMediaQuery({ maxWidth: 1590 });
  const xl4 = useMediaQuery({ maxWidth: 1920 });
  const xl5 = useMediaQuery({ maxWidth: 2250 });

  const storeId = useSelectedStore();
  const formatPrice = usePriceFormat();

  const styleBtnNav = 'flex items-center justify-center w-full h-full';

  const { data: { topSellingProducts } = {}, loading, error, refetch, networkStatus } = useQuery(
    schemas.TOP_SELLING_PRODUCTS,
    {
      variables: {
        storeId,
        range,
        page: 1,
        limit: 10,
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    },
  );

  const topSellingCategoriesQuery = useQuery(schemas.TOP_SELLING_CATEGORIES, {
    variables: {
      storeId,
      range,
      page: 1,
      limit: 10,
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const slidesToShow = () => {
    return xs ? 1 : sm ? 2 : md ? 3 : lg ? 3 : xl ? 4 : xl2 ? 5 : xl3 ? 5 : xl4 ? 6 : xl5 ? 7 : 8;
  };

  return (
    <div>
      <div>
        <Text className="text-xl font-semibold block" value={translations.BEST_SELLING_PRODUCTS} />
        {error ? (
          <div className="my-5 px-4 text-center">
            <Fallback refetch={refetch} />
          </div>
        ) : !topSellingProducts || networkStatus === NetworkStatus.refetch ? (
          <div className="my-4">
            <Spinner />
          </div>
        ) : (
          <LoadingContainer isLoading={loading}>
            <div className="bg-white border border-gray-300 mt-4">
              {!topSellingProducts?.data.length ? (
                <EmptyState
                  skeletonComponent={<TopProductsSkeleton className="w-full" />}
                  iconComponent={<IconMoney />}
                  emptyStateText={translations.EMPTY_TOP_SELLING_PRODUCTS}
                />
              ) : (
                <Carousel
                  className="py-4"
                  slidesToShow={slidesToShow()}
                  cellSpacing={14}
                  defaultControlsConfig={{
                    containerClassName: 'h-full py-4 w-10',
                  }}
                  renderCenterLeftControls={({ previousSlide, ...rest }) =>
                    rest.currentSlide !== 0 && (
                      <button
                        type="button"
                        style={{ backgroundColor: 'rgba(223, 225, 236, 0.7)' }}
                        className={cx(styleBtnNav)}
                        onClick={previousSlide}
                      >
                        <LeftIcon className="w-10 h-10 fill-current text-black" />
                      </button>
                    )
                  }
                  renderCenterRightControls={({ nextSlide, ...rest }) => {
                    const nextBtn = (
                      <button
                        type="button"
                        style={{ backgroundColor: 'rgba(223, 225, 236, 0.7)' }}
                        className={cx(styleBtnNav)}
                        onClick={nextSlide}
                      >
                        <RightIcon className="w-10 h-10 fill-current text-black" />
                      </button>
                    );
                    return rest.currentSlide !== rest.slideCount - rest.slidesToShow && nextBtn;
                  }}
                  renderBottomCenterControls={() => null}
                >
                  {topSellingProducts?.data.map((item, i) => (
                    <Product
                      index={i + 1}
                      key={`${item.titleEn}_${item.totalSales}`}
                      image={item.photoUrl}
                      title={[item.titleEn, item.titleAr]}
                      totalSales={item.totalSales}
                      totalOrders={item.totalOrders}
                      quantitySold={item.quantitySold}
                      formatPrice={formatPrice}
                    />
                  ))}
                </Carousel>
              )}
            </div>
          </LoadingContainer>
        )}
      </div>
      <div>
        <Text className="text-xl font-semibold block mt-8" value={translations.BEST_SELLING_CATEGORIES} />
        {topSellingCategoriesQuery.error ? (
          <div className="my-5 px-4 text-center">
            <Fallback refetch={() => topSellingCategoriesQuery.refetch()} />
          </div>
        ) : !topSellingCategoriesQuery.data?.topSellingCategories ||
          topSellingCategoriesQuery.networkStatus === NetworkStatus.refetch ? (
          <div className="my-4">
            <Spinner />
          </div>
        ) : (
          <LoadingContainer isLoading={topSellingCategoriesQuery.loading}>
            <div className="bg-white border border-gray-300 mt-4">
              {!topSellingCategoriesQuery.data.topSellingCategories.data.length ? (
                <EmptyState
                  skeletonComponent={<TopProductsSkeleton className="w-full" />}
                  iconComponent={<IconMoney />}
                  emptyStateText={translations.EMPTY_TOP_SELLING_PRODUCTS}
                />
              ) : (
                <Carousel
                  className="py-4"
                  slidesToShow={slidesToShow()}
                  cellSpacing={14}
                  defaultControlsConfig={{
                    containerClassName: 'h-full py-4 w-10',
                  }}
                  renderCenterLeftControls={({ previousSlide, ...rest }) =>
                    rest.currentSlide !== 0 && (
                      <button
                        type="button"
                        style={{ backgroundColor: 'rgba(223, 225, 236, 0.7)' }}
                        className={cx(styleBtnNav)}
                        onClick={previousSlide}
                      >
                        <LeftIcon className="w-10 h-10 fill-current text-black" />
                      </button>
                    )
                  }
                  renderCenterRightControls={({ nextSlide, ...rest }) => {
                    const nextBtn = (
                      <button
                        type="button"
                        className={cx(styleBtnNav, 'bg-primary-80 bg-opacity-70')}
                        onClick={nextSlide}
                      >
                        <RightIcon className="w-10 h-10 fill-current text-black" />
                      </button>
                    );
                    return rest.currentSlide !== rest.slideCount - rest.slidesToShow && nextBtn;
                  }}
                  renderBottomCenterControls={() => null}
                >
                  {topSellingCategoriesQuery.data.topSellingCategories.data.map((item, i) => (
                    <Product
                      index={i + 1}
                      key={`${item.titleEn}_${item.totalSales}`}
                      image={item.photoUrl}
                      title={[item.titleEn, item.titleAr]}
                      totalSales={item.totalSales}
                      totalOrders={item.totalOrders}
                      quantitySold={item.quantitySold}
                      formatPrice={formatPrice}
                    />
                  ))}
                </Carousel>
              )}
              <ReportLink to={paths.productsAnalytics} />
            </div>
          </LoadingContainer>
        )}
      </div>
    </div>
  );
};

Products.propTypes = {
  range: PropTypes.shape({ start: PropTypes.string, end: PropTypes.string }).isRequired,
};

export default Products;
