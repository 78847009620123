import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Text } from 'components/service';
import { Button } from 'components/kit';
import { ReactComponent as CaretRightIcon } from 'assets/caret-right.svg';
import { ReactComponent as CaretLeftIcon } from 'assets/caret-left.svg';

const FacebookActionCard = ({ description, fbLink, buttonText, installed, icon, direction }) => (
  <div className="flex flex-row mb-2 w-full md:w-1/3">
    <div className="pt-2 lg:pt-4">{icon}</div>
    <div
      className={cx(
        'flex flex-col mb-0 md:mb-3 w-full md:w-4/5 px-4 py-1 lg:py-4 justify-between',
        direction === 'ltr' ? 'sm:mr-2' : 'sm:ml-2',
      )}
    >
      <Text value={description} className="text-sm font-normal opacity-75 mb-1" />
      {installed && (
        <div className="mt-4">
          <Button kind="transparent" size="close" onClick={() => window.open(fbLink, '_blank').focus()}>
            <Text className={cx('text-xs font-medium', direction === 'ltr' ? 'pr-2' : 'pl-2')} value={buttonText} />
            {direction === 'ltr' ? <CaretRightIcon /> : <CaretLeftIcon />}
          </Button>
        </div>
      )}
    </div>
  </div>
);

FacebookActionCard.propTypes = {
  description: PropTypes.arrayOf(PropTypes.string).isRequired,
  fbLink: PropTypes.string.isRequired,
  buttonText: PropTypes.arrayOf(PropTypes.string).isRequired,
  installed: PropTypes.bool.isRequired,
  icon: PropTypes.element.isRequired,
  direction: PropTypes.oneOf(['ltr', 'rtl']).isRequired,
};

export default FacebookActionCard;
