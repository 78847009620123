import React from "react"
import { range } from "ramda"
import PropTypes from "prop-types"

const LEFT_PAGE = "LEFT"
const RIGHT_PAGE = "RIGHT"

const Pagination = ({
  currentPage = 1,
  totalRecords,
  pageLimit = 10,
  pageNeighbours = 0,
  onPageChanged,
}) => {
  const totalPages = Math.ceil(totalRecords / pageLimit)

  const gotoPage = page => {
    const currentPage = Math.max(1, Math.min(page, totalPages))
    const paginationData = {
      currentPage,
      totalPages,
      pageLimit,
      totalRecords,
    }
    onPageChanged && onPageChanged(paginationData)
  }

  const handleClick = page => {
    gotoPage(page)
  }

  const handleMovingBackwards = () => {
    gotoPage(currentPage - pageNeighbours * 2 - 1)
  }

  const handleMovingForward = () => {
    gotoPage(currentPage + pageNeighbours * 2 + 1)
  }

  const fetchPageNumbers = () => {
    const totalNumbers = pageNeighbours * 2 + 3
    const totalBlocks = totalNumbers + 2

    if (totalPages > totalBlocks) {
      let pages = []
      const backwardBound = currentPage - pageNeighbours
      const forwardBound = currentPage + pageNeighbours
      const beforeLastPage = totalPages - 1

      const startPage = backwardBound > 2 ? backwardBound : 2
      const endPage =
        forwardBound < beforeLastPage ? forwardBound : beforeLastPage

      pages = range(startPage, endPage + 1)
      const pagesCount = pages.length
      const singleSpillOffset = totalNumbers - pagesCount - 1

      const leftSpill = startPage > 2
      const rightSpill = endPage < beforeLastPage

      const leftSpillPage = LEFT_PAGE
      const rightSpillPage = RIGHT_PAGE

      if (leftSpill && !rightSpill) {
        const extraPages = range(startPage - singleSpillOffset, startPage)
        pages = [leftSpillPage, ...extraPages, ...pages]
      } else if (!leftSpill && rightSpill) {
        const extraPages = range(endPage + 1, endPage + singleSpillOffset + 1)
        pages = [...pages, ...extraPages, rightSpillPage]
      } else if (leftSpill && rightSpill) {
        pages = [leftSpillPage, ...pages, rightSpillPage]
      }
      return [1, ...pages, totalPages]
    }
    return range(1, totalPages + 1)
  }

  if (!totalRecords || totalPages === 1) return null
  const pages = fetchPageNumbers()
  return (
    <nav className="h-full">
      <ul className="flex flex-row justify-end">
        {pages.map((page, index) => {
          if (page === LEFT_PAGE)
            return (
              <li
                key={index}
                className="w-10 h-10 mx-1 flex justify-center items-center mx-1 border border-gray-300 hover:cursor-pointer hover:bg-primary-50 hover:text-primary-base"
                onClick={() => handleMovingBackwards()}
              >
                <span aria-hidden="true">&laquo;</span>
                <span className="sr-only">Previous</span>
              </li>
            )
          if (page === RIGHT_PAGE)
            return (
              <li
                key={index}
                className="w-10 h-10 mx-1 flex justify-center items-center border border-gray-300 hover:cursor-pointer hover:bg-primary-50 hover:text-primary-base"
                onClick={() => handleMovingForward()}
              >
                <span aria-hidden="true">&raquo;</span>
                <span className="sr-only">Next</span>
              </li>
            )

          return (
            <li
              key={index}
              className={`w-10 h-10 mx-1 flex justify-center items-center border border-gray-300 hover:cursor-pointer hover:bg-primary-50 hover:text-primary-base ${
                currentPage === page
                  ? "bg-primary-50 text-primary-base border-none"
                  : ""
              }`}
              onClick={() => handleClick(page)}
              data-testid={`page-${page}`}
            >
              {page}
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

Pagination.propTypes = {
  totalRecords: PropTypes.number.isRequired,
  pageLimit: PropTypes.number,
  pageNeighbours: PropTypes.number,
  onPageChanged: PropTypes.func,
}
export default Pagination
