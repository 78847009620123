import React from "react"
import * as yup from "yup"
import * as translations from "constants/translations"
import { Text } from "components/service"

export const initialValues = {
  titleEn: "",
  titleAr: "",
  descriptionEn: "",
  descriptionAr: "",
  productLang: "english",
  optionsLang: "english",
  storeEn: "",
  storeAr: "",
  specialNotesPlaceholderEn: "",
  specialNotesPlaceholderAr: "",
}

export const validationSchema = yup.object().shape({
  titleEn: yup
    .string()
    .required(<Text value={translations.PRODUCT_TITLE_EN_REQ} />),
  titleAr: yup
    .string()
    .required(<Text value={translations.PRODUCT_TITLE_AR_REQ} />),
  storeEn: yup
    .string()
    .required(<Text value={translations.VR_SECTION_TITLE_EN_REQ} />),
  storeAr: yup
    .string()
    .required(<Text value={translations.VR_SECTION_TITLE_AR_REQ} />),
  categories: yup
    .array()
    .of(yup.string().required())
    .required(<Text value={translations.PRODUCT_CATEGORIES_REQ} />),
})
