import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { SelectionTag } from 'components/form/elements';
import { FRAME_ENUM } from './utils';
import { deliveryTypes } from './data';

const OrderFrameSelectionTags = ({
  setQuery,
  setFilters,
  getQueryTitles,
  getSelectedAreaTitles,
  getSelectedDatesTitles,
  paymentMethods,
  query,
  lang,
  driversList,
}) => (
  <div>
    <div className={cx('flex flex-wrap', lang === 'ar' && 'flex-row-reverse', 'justify-between')}>
      <div className={cx('w-full flex flex-wrap', lang === 'ar' && 'flex-row-reverse')}>
        <SelectionTag
          isRemovable
          onRemoveClick={() => {
            setQuery({ payment_methods: [] });
            setFilters({ payment_methods: [] });
          }}
          items={getQueryTitles(paymentMethods, query.payment_methods)}
        />
        <SelectionTag
          isRemovable
          onRemoveClick={() => {
            setQuery({ date: FRAME_ENUM.ALL });
            setFilters({ date: FRAME_ENUM.ALL });
          }}
          items={query.date !== FRAME_ENUM.ALL && getSelectedDatesTitles()}
        />
        <SelectionTag
          isRemovable
          onRemoveClick={() => {
            setQuery({ areas: [], delivery_zone_in: [] });
            setFilters({ areas: [], delivery_zone_in: [] });
          }}
          items={getSelectedAreaTitles()}
        />
        <SelectionTag
          isRemovable
          onRemoveClick={() => {
            setQuery({ delivery_type: [], is_manual_order: false });
            setFilters({ delivery_type: [], is_manual_order: false });
          }}
          items={getQueryTitles(deliveryTypes, query.delivery_type)}
        />
        {driversList && (
          <SelectionTag
            isRemovable
            onRemoveClick={() => {
              setQuery({ driver_id: [] });
              setFilters({ driver_id: [] });
            }}
            items={getQueryTitles(driversList, query.driver_id)}
          />
        )}
      </div>
    </div>
  </div>
);

export default OrderFrameSelectionTags;

OrderFrameSelectionTags.propTypes = {
  setQuery: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  getQueryTitles: PropTypes.func.isRequired,
  getSelectedAreaTitles: PropTypes.func.isRequired,
  getSelectedDatesTitles: PropTypes.func.isRequired,
  lang: PropTypes.oneOf(['en', 'ar']).isRequired,
  driversList: { id: PropTypes.string.isRequired, title: PropTypes.string.isRequired },
};
