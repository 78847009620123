import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';

import * as translations from 'constants/translations';
import { context as localeContext } from 'context/locale';
import { Row, Label } from 'components/form/generic';
import { Button } from 'components/kit';
import { Field, Text } from 'components/service';
import { Hours } from 'components/form/elements';
import { context as notificationsContext } from 'context/notifications';
import { useUpdateBulkDeliveryZone } from 'service/hooks';
import { useSelectedStore } from 'hooks';
import bulkValidationSchema from '../../BulkEdit/data';
import { bulkEditZones } from '../../utils';

const UpdateWorkingHoursModal = ({ bulkZones, close, setZones, tableViewController }) => {
  const selectedStoreId = useSelectedStore();
  const notifications = useContext(notificationsContext);
  const { translate } = useContext(localeContext);
  const convertedOpeningHours = tableViewController.branchOpeningHours.map(item => ({
    day: item.day,
    openAt: item['open-at'],
    closeAt: item['close-at'],
    offDat: item['off-day'],
  }));

  const initialValues = {
    openingHours: convertedOpeningHours,
  };
  const [pending, setPending] = useState(false);

  const updateBulkDeliveryZones = useUpdateBulkDeliveryZone();

  const handleFormSubmit = async values => {
    setPending(true);

    const response = await updateBulkDeliveryZones({
      deliveryZones: bulkZones.map(bulkItem => ({
        restaurantReferenceId: parseFloat(selectedStoreId),
        id: bulkItem.properties.id,
        openingHours: values.openingHours,
      })),
    });

    if (!response.hasError) {
      setPending(false);
      if (response.updateBulkDeliveryZones) {
        const { deliveryZone, errors } = response.updateBulkDeliveryZones;
        // - All zones failed
        if (deliveryZone.length === 0 && errors.length >= 1) {
          notifications.show(<p>{errors[0].errorMessage}</p>, 'error');
          close();
        }

        // - Some zones success and some failed
        if (deliveryZone.length >= 1 && errors.length >= 1) {
          notifications.show(
            <div className="flex flex-wrap">
              <p>{translate(translations.BULK_EDIT_ISSUE)} &nbsp;</p>
              <ul className="list-none flex flex-wrap">
                {errors.map(errorItem => (
                  <li key={errorItem.zoneName}>{errorItem.zoneName},&nbsp;</li>
                ))}
              </ul>
            </div>,
            'error',
          );
          bulkEditZones(setZones, deliveryZone, 'opening_hours', values.openingHours);
          close();
        }

        // - All zones success
        if (deliveryZone.length >= 1 && errors.length === 0) {
          notifications.show(<Text value={translations.BULK_EDIT_SUCCESS_MSG(deliveryZone.length)} />);
          bulkEditZones(setZones, deliveryZone, 'opening_hours', values.openingHours);
          close();
        }
      }
    } else {
      setPending(false);
      close();
      notifications.show(<Text value={translations.SOMETHING_WENT_WRONG} />, 'error');
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={values => handleFormSubmit(values)}
      validationSchema={bulkValidationSchema}
    >
      <Form>
        <Row className="mt-2 mx-3">
          <Row className="mt-3">
            <Label textSize="text-xs" title={<Text value={translations.DELIVERY_HOURS} />}>
              <Field name="openingHours" component={Hours} />
            </Label>
          </Row>
        </Row>

        <div className="flex items-center justify-end mt-4">
          <Button onClick={close} type="button" kind="tertiary" className="mx-4">
            <Text value={translations.DISMISS} />
          </Button>
          <Button kind="primary" type="submit" disabled={pending}>
            <Text value={translations.CONFIRM} />
          </Button>
        </div>
      </Form>
    </Formik>
  );
};

UpdateWorkingHoursModal.propTypes = {
  bulkZones: PropTypes.arrayOf(
    PropTypes.shape({
      properties: PropTypes.shape({
        id: PropTypes.number,
      }),
    }),
  ),
  close: PropTypes.func.isRequired,
  setZones: PropTypes.func.isRequired,
  tableViewController: PropTypes.shape({
    branchOpeningHours: PropTypes.arrayOf(
      PropTypes.shape({
        day: PropTypes.string,
      }),
    ),
  }),
};

export default UpdateWorkingHoursModal;
