import * as yup from "yup"

export const validationSchema = yup.object().shape({
  titleEn: yup.string().required("Store name is required"),
  titleAr: yup.string().required("Arabic name is required"),
  subdomain: yup.string().required("Store url is required"),
  countryId: yup.string().required("Country is required"),
  instagram: yup.string(),
})

export const initialValues = {
  titleEn: "",
  titleAr: "",
  subdomain: "",
  countryId: "",
  instagram: "",
}
