import { gql } from "@apollo/client"

export const OPTION_GROUPS_PRODUCTS = gql`
  query($restaurantId: String!, $productId: String) {
    propertySections(restaurantId: $restaurantId, productId: $productId) {
      id
      variantIds
      titleEn
      titleAr
      minQuantity
      maxQuantity
      multipleAddOnsEnabled
      propertyValues {
        id
        titleEn
        titleAr
        price
        externalId
      }
      variantProperties {
        id
        minQuantity
        maxQuantity
        multipleAddOnsEnabled
      }
      menuItems {
        id
        titleEn
        titleAr
      }
    }
  }
`

export const DELETE_GROUP = gql`
  mutation($restaurantId: String, $propertyId: String) {
    deleteGroup(restaurantId: $restaurantId, propertyId: $propertyId) {
      propertyId
    }
  }
`
export const CREATE_GROUP = gql`
  mutation(
    $restaurantId: String
    $titleEn: String
    $titleAr: String
    $externalId: String
  ) {
    createOptionGroup(
      restaurantId: $restaurantId
      titleEn: $titleEn
      titleAr: $titleAr
      externalId: $externalId
    ) {
      id
      titleEn
      titleAr
      externalId
    }
  }
`

export const UPDATE_GROUP = gql`
  mutation(
    $restaurantId: String
    $titleEn: String
    $titleAr: String
    $externalId: String
    $propertyId: String
  ) {
    updateOptionGroup(
      restaurantId: $restaurantId
      titleEn: $titleEn
      titleAr: $titleAr
      externalId: $externalId
      propertyId: $propertyId
    ) {
      id
      titleEn
      titleAr
      externalId
    }
  }
`
