import React from 'react';
import PropTypes from 'prop-types';

import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { PAYMENT_METHODS_ENUMS } from '../../Frame/utils';

const PaidThrough = ({ paidThrough }) => {
  switch (paidThrough) {
    case PAYMENT_METHODS_ENUMS.BENEFIT:
      return <Text value={translations.BENEFIT} />;
    case PAYMENT_METHODS_ENUMS.CASH:
      return <Text value={translations.CASH} />;
    case PAYMENT_METHODS_ENUMS.CARD_ON_DELIVERY:
      return <Text value={translations.CARDONDELIVERY} />;
    case PAYMENT_METHODS_ENUMS.CREDIT_CARD:
      return <Text value={translations.CREDIT_CARD} />;
    case PAYMENT_METHODS_ENUMS.MEEZA:
      return <Text value={translations.MEEZA} />;
    case PAYMENT_METHODS_ENUMS.KNET:
      return <Text value={translations.KNET} />;
    case PAYMENT_METHODS_ENUMS.APPLE_PAY:
      return <Text value={translations.APPLE_PAY} />;
    case PAYMENT_METHODS_ENUMS.MADA:
      return <Text value={translations.MADA} />;
    case PAYMENT_METHODS_ENUMS.WALLET:
      return <Text value={translations.ORDERFAST_WALLET} />;
    case PAYMENT_METHODS_ENUMS.WALLET_AND_CREDIT_CARD:
      return <Text value={translations.ORDERFAST_WALLET_AND_CREDIT_CARD} />;
    default:
      return '';
  }
};

PaidThrough.propTypes = {
  paidThrough: PropTypes.oneOf([
    PAYMENT_METHODS_ENUMS.BENEFIT,
    PAYMENT_METHODS_ENUMS.CASH,
    PAYMENT_METHODS_ENUMS.CARD_ON_DELIVERY,
    PAYMENT_METHODS_ENUMS.CREDIT_CARD,
    PAYMENT_METHODS_ENUMS.MEEZA,
    PAYMENT_METHODS_ENUMS.KNET,
    PAYMENT_METHODS_ENUMS.APPLE_PAY,
    PAYMENT_METHODS_ENUMS.MADA,
  ]),
};
export default PaidThrough;
