import React, { useContext } from "react"
import moment from "moment"
import cx from "classnames"
import { context as localeContext } from "context/locale"
import { context as userContext } from "context/user"
import { Text } from "components/service"
import { Row, Section } from "components/form/generic"
import * as translations from "constants/translations"
import { ReactComponent as IconsAr } from "assets/icons-ar.svg"
import { ReactComponent as IconsEn } from "assets/icons-en.svg"
import { ReactComponent as IconsMenu } from "assets/icons-bar-menu.svg"
import { ReactComponent as PlaceholderPreview } from "assets/placeholder-preview.svg"

export default ({ values }) => {
  const { selectedStore } = useContext(userContext)
  const { lang, translate, direction } = useContext(localeContext)
  const discount = {
    percentage: `${values.amount}% ${translate(translations.DISCOUNT)}`,
    amount: `${translate(selectedStore.currency)} ${values.amount} ${translate(
      translations.DISCOUNT
    )}`,
    free_delivery: translate(translations.FREE_DELIVERY),
  }

  const appliedTo = {
    entire_order: translate(translations.ENTIRE_ORDER),
    delivery_type: {
      pickup: translate(translations.PICKUP),
      delivery: translate(translations.DELIVERY),
    },
  }

  const currentDate = moment(new Date()).format("YYYY-MM-DD")
  const expirationDate = moment(values.validUntil).format("YYYY-MM-DD")
  return (
    <div className="flex items-center justify-center">
      <div
        className="bg-gray-200 shadow rounded-lg max-w-xs"
        style={{ direction }}
      >
        <div className="w-full p-2">
          <div className="flex flex-col items-center text-gray-700">
            <div className="mb-2">
              <Text
                value={translations.WELCOME_TO}
                payload={translate(selectedStore)}
              />
            </div>
            <div className="text-sm mb-2 py-1 bg-zyda-grey-50 rounded w-full text-center">
              {selectedStore.fullUrl}
            </div>
          </div>
          <div className="bg-white mb-2">
            <div
              className={cx(
                "flex items-center justify-between",
                lang === "en" ? "pr-2" : "pl-2"
              )}
            >
              <IconsMenu />
              {lang === "en" ? <IconsAr /> : <IconsEn />}
            </div>
            <div className="flex items-center px-2 py-4">
              <div className="">
                <img
                  src={selectedStore.logoUrl}
                  className="rounded-full w-16 h-16"
                  alt=""
                />
              </div>
              <div className="flex flex-col mx-2">
                <Text value={selectedStore} className="" />
                <span className="text-gray-500 text-sm">
                  {lang === "en"
                    ? selectedStore.descriptionEn
                    : selectedStore.descriptionAr}
                </span>
              </div>
            </div>
          </div>
          <div className="p-2">
            <div className="bg-white rounded-lg p-4 mb-4">
              <div className="flex items-center mb-2">
                <div className="w-3/4 flex flex-col text-gray-700 text-xs">
                  <div className="text-sm text-black mb-1">
                    {lang === "en" ? values.description : values.descriptionAr}
                  </div>
                  <div className="mb-1">{`${
                    values.appliedTo === "delivery_type"
                      ? appliedTo[values.appliedTo][values.deliveryType]
                      : appliedTo[values.appliedTo]
                  } | ${discount[values.type]}`}</div>
                  <div className="mb-1">
                    {values.happyHour &&
                      `${values.validTimeFrom} - ${values.validTimeTo}`}
                    {values.happyHour && values.validUntil && " | "}
                    {values.validUntil && (
                      <Text
                        value={translations.EXPIRES_IN_DAYS}
                        payload={moment(expirationDate).diff(
                          currentDate,
                          "days"
                        )}
                        className="inline"
                      />
                    )}
                  </div>
                </div>
                <div className="w-1/4">
                  {/* <img
                    src=""
                    className="w-full h-full rounded"
                    alt=""
                  /> */}
                </div>
              </div>
              <span className="inline-block border border-blue-400 rounded text-blue-400 px-4 py-2 text-xs">
                {translate(translations.APPLY_OFFER)}
              </span>
            </div>
          </div>
          <div className="w-full flex items-center justify-center mb-2 px-6">
            <PlaceholderPreview />
          </div>
        </div>
      </div>
    </div>
  )
}
