import React from "react"
import cx from "classnames"
import { Text } from "components/service"
import * as translations from "constants/translations"

export default ({ lang }) => {
  const headCellClassName = cx(
    "px-6 py-2 text-gray-700 font-normal",
    lang === "ar" ? "text-right" : "text-left"
  )

  return (
    <thead>
      <tr className="border-b border-gray-300 bg-zyda-grey-50">
        <th className={headCellClassName}>
          <Text value={translations.VARIANT} />
        </th>
        <th className={headCellClassName}>
          <Text value={translations.PREPARATION_TIME_FIELD_TITLE} />
        </th>
        <th className={headCellClassName}>
          <Text value={translations.EXTRA_PREP_TIME} />
        </th>
      </tr>
    </thead>
  )
}
