import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { context as localeContext } from 'context/locale';
import { ReactComponent as SearchIcon } from 'assets/search.svg';
import { Input } from 'components/form/elements';
import { SEARCH_AREA_ZONES } from 'constants/translations';

const TemplatesSearch = ({ zoneController, setDzTemplatesParams, dzTemplatesParams, setZonesCollection }) => {
  const { translate, direction } = useContext(localeContext);
  return (
    <>
      <div className="pb-4 flex justify-between items-center" style={{ direction }}>
        <Input
          type="text"
          search
          icon={<SearchIcon />}
          width="full"
          value={dzTemplatesParams.searchValue}
          onChange={e => {
            const { value } = e.target;
            setDzTemplatesParams(prevState => ({
              ...prevState,
              totalPages: null,
              currentPage: 1,
              searchValue: value,
            }));
            setZonesCollection({ type: 'FeatureCollection', features: [] });
          }}
          placeholder={translate(SEARCH_AREA_ZONES(zoneController.cityTitle))}
        />
      </div>
    </>
  );
};
TemplatesSearch.propTypes = {
  zoneController: PropTypes.shape({
    cityTitle: PropTypes.string,
  }).isRequired,
  setDzTemplatesParams: PropTypes.func.isRequired,
  dzTemplatesParams: PropTypes.shape({
    searchValue: PropTypes.string,
  }).isRequired,
  setZonesCollection: PropTypes.func.isRequired,
};
export default TemplatesSearch;
