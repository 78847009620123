import React, { useState, useContext } from "react"
import { navigate } from "@reach/router"
import moment from "moment"
import { context as notificationsContext } from "context/notifications"
import { useQuery, useMutation } from "@apollo/client"
import { useSelectedStore, useReplaceParams, useUTCtimeZone } from "hooks/index"
import { Layout, Breadcrumbs } from "components/common/dashboard"
import { VoucherForm } from "components/common/marketing/vouchers"
import * as translations from "constants/translations"
import { Link, Text } from "components/service"
import { Button, Spinner } from "components/kit"
import * as paths from "paths.js"
import * as schemas from "./schemas"

export default ({ voucherId }) => {
  const replace = useReplaceParams()
  const notifications = useContext(notificationsContext)
  const storeId = useSelectedStore()
  const [isShowPreview, setIsShowPreview] = useState(false)
  const { data } = useQuery(schemas.GET_VOUCHER, {
    variables: { restaurantId: storeId, id: voucherId },
    fetchPolicy: "network-only",
  })
  const [editVoucher, { loading }] = useMutation(schemas.EDIT_VOUCHER, {
    variables: {
      restaurantId: storeId,
      id: voucherId,
    },
    onCompleted: () => {
      notifications.show(
        <Text value={translations.VOUCHER_UPDATED} />,
        "success"
      )
      navigate(replace(paths.allVouchers))
    },
    onError: err => {
      const { body } = err.graphQLErrors[0]?.extensions?.exception || {}

      if (!body.error) {
        for (let key of Object.keys(body)) {
          notifications.show(`${body[key][0]}`, "error")
        }
        return
      }

      return notifications.show(
        <Text value={translations.SOMETHING_WENT_WRONG} />,
        "error"
      )
    },
  })

  return (
    <Layout
      top={
        <div>
          <Breadcrumbs
            links={[paths.marketing, paths.allVouchers]}
            path={translations.breadcrumbs.MARKETING_EDIT_VOUCHER}
          />
        </div>
      }
    >
      <div>
        <div className="pb-32 flex">
          {!data ? (
            <Spinner />
          ) : (
            <VoucherForm
              initialValues={{
                ...data.voucher,
                descriptionEn: data.voucher.description || "",
                descriptionAr: data.voucher.descriptionAr || "",
                minimumRequirements:
                  data.voucher.minSubtotalAmount === 0
                    ? "none"
                    : "minimumOrderAmount",
                redemptionLimitEnabled: !!data.voucher.redemptionLimit,
                redemptionLimitPerUserEnabled: !!data.voucher
                  .redemptionLimitPerUser,
                happyHour:
                  !!data.voucher.validTimeFrom && !!data.voucher.validTimeTo,
                validTimeFrom:
                  data.voucher.validTimeFrom &&
                  useUTCtimeZone({ time: data.voucher.validTimeFrom }),
                validTimeTo:
                  data.voucher.validTimeTo &&
                  useUTCtimeZone({ time: data.voucher.validTimeTo }),
                validFrom: moment(data.voucher.validFrom).format(),
                validFromTime:
                  data.voucher.validFrom &&
                  moment(data.voucher.validFrom).format("h:mm A"),
                validUntil:
                  data.voucher.validUntil &&
                  moment(data.voucher.validUntil).format(),
                validUntilEnabled: !!data.voucher.validUntil,
                validUntilTime:
                  data.voucher.validUntil &&
                  moment(data.voucher.validUntil).format("h:mm A"),
              }}
              isEdit
              isShowPreview={isShowPreview}
              onSubmit={data =>
                editVoucher({
                  variables: { ...data, validUntil: data.validUntil || null },
                })
              }
              bottom={isValid => (
                <div className="flex flex-wrap mt-8">
                  <div className="flex-2 mt-2 md:mt-0 w-full md:w-auto">
                    <Link to={paths.allVouchers}>
                      <Button kind="secondary" size="base" full>
                        <Text value={translations.CANCEL} />
                      </Button>
                    </Link>
                  </div>
                  <div className="flex-1 flex items-stretch flex-row justify-end mt-2 md:mt-0">
                    <div className="mx-2">
                      <Button
                        onClick={e => {
                          e.preventDefault()
                          setIsShowPreview(!isShowPreview)
                        }}
                        type="button"
                        kind="secondary"
                        size="base"
                        disabled={!isValid}
                        full
                      >
                        <Text
                          value={
                            isShowPreview
                              ? translations.BACK
                              : translations.PREVIEW
                          }
                        />
                      </Button>
                    </div>
                    <div className="w-full md:w-auto">
                      <Button
                        full
                        kind="primary"
                        size="base"
                        isSpinning={loading}
                      >
                        <Text value={translations.SAVE} />
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            />
          )}
        </div>
      </div>
    </Layout>
  )
}
