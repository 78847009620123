let timeOutId;

const debounce = (callback, waitTime) => {
  clearTimeout(timeOutId);
  timeOutId = setTimeout(() => {
    callback();
  }, waitTime);
};

export default debounce;
