import { gql } from '@apollo/client';

const ADD_RIDER = gql`
  mutation(
    $storeId: String!
    $name: String!
    $email: String
    $phoneNumber: String!
    $jobType: JobType
    $language: String!
    $branchIds: [String!]
  ) {
    addRider(
      restaurantId: $storeId
      name: $name
      email: $email
      phoneNumber: $phoneNumber
      language: $language
      branchIds: $branchIds
    ) {
      id
    }
  }
`;

export default ADD_RIDER;
