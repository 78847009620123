import React, { useContext } from 'react';
import { useQuery, NetworkStatus } from '@apollo/client';
import cx from 'classnames';
import { useLocation } from '@reach/router';

import SEGMENTS from 'common/schemas/segmentSchema';
import { breadcrumbs, CREATE_CAMPAIGN } from 'constants/translations';
import { CAMPAIGN_PERMISSIONS } from 'constants/users';
import { CUSTOMERS_SEARCH_BY_OPTIONS, CUSTOMERS_SORT_ORDER, CUSTOMER_TABLE_KEYS } from 'constants/customers';
import { useQueryState } from 'hooks';
import { Layout, Breadcrumbs } from 'components/common/dashboard';
import { Table, TableFooter } from 'components/form/elements';
import { Spinner, LoadingContainer, Button } from 'components/kit';
import { exportCustomersTable } from 'rest';
import { Text, Link } from 'components/service';
import Fallback from 'components/common/Fallback';
import * as paths from 'paths';
import { CUSTOMERS_LINKS } from 'constants/helperLinks';
import * as translations from 'constants/translations';
import EmptyState from 'components/common/EmptyState';
import { ReactComponent as IconPieData } from 'assets/icon-pie-data.svg';
import { ReactComponent as SkeletonBars } from 'assets/skeleton-bars.svg';
import { context as userContext } from 'context/user';
import { context as localeContext } from 'context/locale';
import { context as customersContext } from 'context/customers';
import { context as notificationsContext } from 'context/notifications';
import { context as featureBlockingContext } from 'context/feature-blocking';
import SegmentsFilter from 'common/components/SegmentsFilter';
import fuseSegmentContractWithSegmentStatic from 'common/utils/fuseSegmentContractWithSegmentStatic';
import { getCookie } from 'utils';
import { ACCESS_MAP_KEYS } from 'constants/featureAccessMatrix';
import FeatureAccess from 'common/lib/FeatureAccess';
import SearchFilter from './SearchFilter';
import CUSTOMERS from './schemas';
import CustomersTableRow from './CustomersTableRow';

const Customers = () => {
  const {
    selectedStore: { id: selectedStoreId },
    campaignsPermission,
    user,
  } = useContext(userContext);
  const { lang, direction } = useContext(localeContext);
  const { segments: contextSegments, setSegments, setSelectedSegment } = useContext(customersContext);
  const { featureAccess } = useContext(featureBlockingContext);
  const notifications = useContext(notificationsContext);

  const location = useLocation();

  const layoutAccess = featureAccess.get(ACCESS_MAP_KEYS.LAYOUT);
  const layoutFeatureAccess = layoutAccess?.get(FeatureAccess.getFeaturePath(location?.pathname, selectedStoreId));

  const segments = useQuery(SEGMENTS, {
    variables: {
      storeId: selectedStoreId,
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    skip: layoutFeatureAccess?.isBlocked,
    onCompleted: result => {
      setSegments(
        fuseSegmentContractWithSegmentStatic(
          result.segments.segments,
          result.segments.totalCustomerCount,
          contextSegments,
        ),
      );
    },
  });

  const [query, setQuery] = useQueryState({
    page: 1,
    limit: 15,
    segment: null,
    searchValue: '',
    searchBy: CUSTOMERS_SEARCH_BY_OPTIONS.PHONE_NUMBER,
    field: CUSTOMER_TABLE_KEYS.LAST_ORDER,
    order: CUSTOMERS_SORT_ORDER.DESCENDING,
  });

  const { data, loading, networkStatus, refetch, error } = useQuery(CUSTOMERS, {
    variables: {
      storeId: selectedStoreId,
      page: parseInt(query.page),
      limit: parseInt(query.limit),
      segment: parseInt(query.segment),
      field: query.field,
      order: query.order,
      phoneNumber: query.searchBy === CUSTOMERS_SEARCH_BY_OPTIONS.PHONE_NUMBER ? query.searchValue : '',
      userName: query.searchBy === CUSTOMERS_SEARCH_BY_OPTIONS.USER_NAME ? query.searchValue : '',
    },
    skip: layoutFeatureAccess?.isBlocked,
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const customSort = key => {
    let order = '';
    if (query.order === CUSTOMERS_SORT_ORDER.ASCENDING || query.order === '' || query.field !== key)
      order = CUSTOMERS_SORT_ORDER.DESCENDING;
    else order = CUSTOMERS_SORT_ORDER.ASCENDING;
    setQuery({ order, field: key });
  };

  const logCreateCampaignClicked = () => {
    if (process.env.REACT_APP_SEGMENT_WRITE_KEY) {
      if (getCookie('ajs_user_id') !== '') {
        window.analytics.track(
          'Create Campaign Clicked from Customers Tab',
          {
            user_name: user.name,
            user_role: user.roles[0]?.role,
          },
          {
            groupId: selectedStoreId,
            userId: user.id,
          },
        );
      }
    }
  };

  return (
    <Layout
      top={
        <Breadcrumbs
          customTitle={
            <p className="text-2xl font-bold">
              {lang === 'en' ? translations.CUSTOMERS[0] : translations.CUSTOMERS[1]}{' '}
              {!layoutFeatureAccess?.isBlocked && !segments.loading && segments.data && (
                <>
                  {lang === 'en' ? (
                    <span className="text-base font-bold">
                      (&lrm;
                      {segments.data.segments.totalCustomerCount})&lrm;
                    </span>
                  ) : (
                    <span className="text-base font-bold">({segments.data.segments.totalCustomerCount})</span>
                  )}
                </>
              )}
            </p>
          }
          helperLinks={CUSTOMERS_LINKS}
          path={breadcrumbs.CUSTOMERS}
          right={
            ![CAMPAIGN_PERMISSIONS.NOT_SUPPORTED_PLAN, CAMPAIGN_PERMISSIONS.FALSE].includes(campaignsPermission) && (
              <Link to={paths.createCampaign} onClick={logCreateCampaignClicked}>
                <Button size="md" kind="primary">
                  <Text className="font-semibold" value={CREATE_CAMPAIGN} />
                </Button>
              </Link>
            )
          }
        />
      }
    >
      <div className="pb-32 flex flex-col" dir={direction}>
        <div className="w-full">
          {segments.loading ? (
            <Spinner />
          ) : segments.error ? (
            <div className="my-5 mb-6 px-4 text-center">
              <Fallback refetch={segments.refetch} />
            </div>
          ) : (
            <SegmentsFilter
              currentSegment={query.segment}
              handleOnClick={selectedSegment => {
                setQuery({ segment: selectedSegment, page: 1 });
                setSelectedSegment(selectedSegment);
              }}
            />
          )}
        </div>
        <div>
          {loading || networkStatus === NetworkStatus.refetch ? (
            <Spinner />
          ) : error ? (
            <div className="my-5 px-4 text-center">
              <Fallback refetch={refetch} />
            </div>
          ) : (
            <LoadingContainer isLoading={loading}>
              {!data ? (
                <EmptyState
                  skeletonComponent={<SkeletonBars className="w-full" />}
                  iconComponent={<IconPieData />}
                  emptyStateText={translations.EMPTY_BARS_CHART}
                />
              ) : (
                <div className="w-full">
                  <div className="grid grid-cols-6">
                    <div className="col-start-1 col-span-3" dir="ltr">
                      <SearchFilter query={query} setQuery={setQuery} />
                    </div>
                    <div className="col-span-1 col-start-6 justify-end">
                      <div className={cx('w-fit', lang === 'en' ? 'float-right' : 'float-left')}>
                        <Button
                          data-testid="export-customers"
                          kind="tertiary"
                          size="md"
                          onClick={() => {
                            exportCustomersTable(selectedStoreId)
                              .then(() => notifications.show(<Text value={translations.EXPORT_CUSTOMERS} />))
                              .catch(err => notifications.show(err.message));
                          }}
                        >
                          <Text value={translations.EXPORT} />
                        </Button>
                      </div>
                    </div>
                  </div>
                  <Table
                    dataHeader={[
                      {
                        key: CUSTOMER_TABLE_KEYS.USER_NAME,
                        name: <Text value={translations.CUSTOMERS_TABLE_HEADERS.CUSTOMER_NAME} />,
                        inView: false,
                      },
                      {
                        key: CUSTOMER_TABLE_KEYS.PHONE_NUMBER,
                        name: <Text value={translations.CUSTOMERS_TABLE_HEADERS.PHONE_NUMBER} />,
                        inView: false,
                      },
                      {
                        key: CUSTOMER_TABLE_KEYS.FIRST_ORDER,
                        name: <Text value={translations.CUSTOMERS_TABLE_HEADERS.FIRST_ORDER} />,
                        inView: false,
                        sort: true,
                      },
                      {
                        key: CUSTOMER_TABLE_KEYS.LAST_ORDER,
                        name: <Text value={translations.CUSTOMERS_TABLE_HEADERS.LAST_ORDER} />,
                        inView: false,
                        sort: true,
                      },
                      {
                        key: CUSTOMER_TABLE_KEYS.TOTAL_ORDERS,
                        name: <Text value={translations.CUSTOMERS_TABLE_HEADERS.TOTAL_ORDERS} />,
                        inView: false,
                        sort: true,
                      },
                      {
                        key: CUSTOMER_TABLE_KEYS.TOTAL_SALES,
                        name: <Text value={translations.CUSTOMERS_TABLE_HEADERS.TOTAL_SALES} />,
                        inView: false,
                        sort: true,
                      },
                      {
                        key: CUSTOMER_TABLE_KEYS.SEGMENT,
                        name: <Text value={translations.CUSTOMERS_TABLE_HEADERS.SEGMENT} />,
                        inView: false,
                      },
                      {
                        inView: false,
                      },
                    ]}
                    dataBody={data.customers.customers}
                    customSort={customSort}
                    isSingleTable
                    customHeaderClasses="px-6 py-4 whitespace-nowrap"
                    customRow={(item, index) => (
                      <CustomersTableRow
                        key={item.id}
                        index={index}
                        item={item}
                        totalCount={data?.customers?.customers.length}
                      />
                    )}
                    footer={
                      <TableFooter
                        totalRecords={data.customers.totalCount}
                        pageLimit={parseInt(query.limit)}
                        currentPage={parseInt(query.page)}
                        onPageChanged={({ currentPage }) => {
                          setQuery({ page: currentPage });
                        }}
                      />
                    }
                  />
                </div>
              )}
            </LoadingContainer>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Customers;
