import React, { useContext, useState, useRef, useEffect } from "react"
import { useSelectedStore } from "hooks"
import { useQuery, useMutation } from "@apollo/client"
import { Field, Text } from "components/service"
import { Formik, Form } from "formik"
import { Button, Spinner } from "components/kit"
import { RadioList, Select } from "components/form/elements"
import { Label, Container, Row, Footer } from "components/form/generic"
import * as translations from "constants/translations"
import { context as localeContext } from "context/locale"
import { context as notificationsContext } from "context/notifications"
import { context as modal } from "context/modal"

import * as schemas from "./schemas"

export default ({
  areaId,
  branches,
  onCancel,
  refetchAreas,
  refetchZones,
  countryId,
}) => {
  const storeId = useSelectedStore()
  const notifications = useContext(notificationsContext)
  const { setDivRef } = useContext(modal)
  const [selectedBranch, setSelectedBranch] = useState(branches[0].id)
  const ref = useRef()
  useEffect(() => {
    setDivRef(ref)
  }, [])
  const { loading, data: zones, refetch } = useQuery(
    schemas.DELIVERY_ZONES_BRANCH,
    {
      variables: {
        restaurantId: storeId,
        branchId: selectedBranch,
        countryId: parseInt(countryId),
      },
    }
  )
  const [assignToAreaDeliveryZone] = useMutation(
    schemas.ASSIGN_TO_AREA_ZONE_DELIVERY
  )
  const { direction, translate } = useContext(localeContext)

  return (
    <>
      <Formik
        initialValues={{ branchId: branches[0].id, deliveryZone: "" }}
        onSubmit={async data => {
          try {
            await assignToAreaDeliveryZone({
              variables: {
                restaurantId: storeId.toString(),
                branchId: data.branchId,
                deliveryZoneId: data.deliveryZone,
                areaId: areaId,
              },
            })
            refetchAreas()
            refetchZones()
            onCancel()
            notifications.show(
              <Text value={translations.ZONE_HAS_BEEN_ASSIGNED} />
            )
          } catch (error) {
            const { body } = error.graphQLErrors[0].extensions.exception
            notifications.show(body, "error")
          }
        }}
      >
        {({ isSubmitting, values, handleChange, setFieldValue }) => (
          <Form style={{ direction }}>
            <div ref={ref} className="px-6">
              <Container>
                <Row>
                  <Label
                    title={<Text value={translations.BUSINESS_LOCATION} />}
                  >
                    <Field
                      name="branchId"
                      component={Select}
                      type="squared"
                      onChange={e => {
                        handleChange(e)
                        let branchSelected = e.currentTarget.value
                        setSelectedBranch(branchSelected)
                        setFieldValue("deliveryZone", "")
                      }}
                    >
                      {branches.map((item, i) => (
                        <option key={i} value={item.id}>
                          {translate(item)}
                        </option>
                      ))}
                    </Field>
                  </Label>
                </Row>
                {loading ? (
                  <Spinner />
                ) : (
                  <Row>
                    <Label
                      title={
                        <Text
                          value={translations.PICK_FROM_THESE_DELIEVERYZONES}
                        />
                      }
                    >
                      <div className="h-32 w-full overflow-auto">
                        {zones.deliveryZonesByBranch === null ? (
                          <Text value={translations.NO_DELIVERY_ZONE} />
                        ) : (
                          <Field
                            direction="col"
                            wFull
                            name="deliveryZone"
                            items={
                              zones && [
                                ...zones.deliveryZonesByBranch.map(item => {
                                  let objectData = {}
                                  objectData.title = (
                                    <Text
                                      value={translations.ZONE}
                                      postfix={"#" + item.id}
                                    />
                                  )
                                  objectData.value = item.id
                                  return objectData
                                }),
                              ]
                            }
                            component={RadioList}
                          />
                        )}
                      </div>
                    </Label>
                  </Row>
                )}
              </Container>
            </div>
            <Footer modalFooter>
              <div className="w-full flex items-center">
                <div className="ml-auto">
                  <Row>
                    <Button onClick={onCancel} type="button" kind="tertiary">
                      <Text value={translations.CANCEL} />
                    </Button>
                    <Button
                      isSpinning={isSubmitting}
                      kind="primary"
                      disabled={!values.deliveryZone}
                    >
                      <Text value={translations.ASSIGN} />
                    </Button>
                  </Row>
                </div>
              </div>
            </Footer>
          </Form>
        )}
      </Formik>
    </>
  )
}
