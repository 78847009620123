import * as R from 'ramda'
import { LIST } from "../List/schemas"

export const updateCache = (branchId, restaurantId) => (
  cache,
  { data: { updateBranch } }
) => {
  const { branches } = cache.readQuery({
    query: LIST,
    variables: { restaurantId },
  })

  cache.writeQuery({
    query: LIST,
    data: {
      branches: branches.map(item =>
        item.id !== branchId ? item : updateBranch
      ),
    },
    variables: { restaurantId },
  })
}

// Currently, apollo does not strips __typename field from the Query response
// therefore it needs to be done manually in order to do a Mutation
export const omitTypename = R.map(R.omit(['__typename']))
