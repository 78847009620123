import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

import beamerInitialize from './beamerInitialize';

const BeamerHelmet = ({ id, url = 'https://app.getbeamer.com/js/beamer-embed.js', lang }) => (
  <Helmet>{beamerInitialize(id, url, lang)}</Helmet>
);

BeamerHelmet.propTypes = {
  id: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  url: PropTypes.string,
};

export default BeamerHelmet;
