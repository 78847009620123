import React from 'react';
import PropTypes from 'prop-types';

import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { Button } from 'components/kit';
import { ReactComponent as WebLinks } from 'assets/web-links.svg';

const WebLinkEmptyState = ({ handleOnAddClick }) => (
  <div className="flex flex-col items-center mt-24 text-center">
    <WebLinks />
    <Text className="text-lg mb-1 font-semibold mt-4" value={translations.ADD_WEB_LINKS_TO_STORE} />
    <Text className="text-gray-700 mb-6" value={translations.ADD_EXTERNAL_WEB_LINKS} />
    <Button kind="primary" onClick={handleOnAddClick}>
      <Text value={translations.ADD_WEB_LINK} />
    </Button>
  </div>
);

WebLinkEmptyState.propTypes = {
  handleOnAddClick: PropTypes.func.isRequired,
};

export default WebLinkEmptyState;
