import React from "react"
import { useMutation } from "@apollo/client"
import { useSelectedStore } from "hooks"
import { Button, Stack } from "components/kit"
import * as translations from "constants/translations"
import { Text } from "components/service"
import * as schemas from "./schemas"
import * as utils from "./utils"

export default ({ onClose, userId, usersVariables }) => {
  const storeId = useSelectedStore()
  const [deleteUser, { loading }] = useMutation(schemas.DELETE_USER, {
    variables: {
      storeId,
      userId,
    },
    update: utils.removeUser(usersVariables, userId),
  })

  return (
    <div className="px-4">
      <Text value={translations.DELETE_USER_CONFIRMATION} className="mb-6" />
      <Stack direction="row-reverse" expandItem={false} spacing={2}>
        <Button
          isSpinning={loading}
          onClick={async () => {
            await deleteUser()
            onClose()
          }}
          type="button"
          kind="secondaryError"
        >
          <Text value={translations.DELETE} />
        </Button>
        <Button onClick={onClose} type="button" kind="tertiary">
          <Text value={translations.DISMISS} />
        </Button>
      </Stack>
    </div>
  )
}
