import { gql } from "@apollo/client"

export const CREATE_VOUCHER = gql`
  mutation(
    $restaurantId: String!
    $type: VoucherType!
    $code: String!
    $amount: Float!
    $validFrom: String!
    $validUntil: String
    $description: String
    $descriptionAr: String
    $redemptionLimit: Int
    $redemptionLimitPerUser: Int
    $validTimeFrom: String
    $validTimeTo: String
    $isFreeDelivery: Boolean
    $minSubtotalAmount: Float
    $appliedTo: String
    $deliveryType: String
  ) {
    addVoucher(
      restaurantId: $restaurantId
      type: $type
      code: $code
      amount: $amount
      validFrom: $validFrom
      validUntil: $validUntil
      description: $description
      descriptionAr: $descriptionAr
      redemptionLimit: $redemptionLimit
      redemptionLimitPerUser: $redemptionLimitPerUser
      validTimeFrom: $validTimeFrom
      validTimeTo: $validTimeTo
      isFreeDelivery: $isFreeDelivery
      minSubtotalAmount: $minSubtotalAmount
      appliedTo: $appliedTo
      deliveryType: $deliveryType
    ) {
      id
    }
  }
`
