import React, { useContext, useState, useEffect, useRef, Fragment } from "react"
import { equals } from "ramda"
import { useMutation } from "@apollo/client"
import { SortableContainer, SortableElement } from "react-sortable-hoc"
import arrayMove from "array-move"
import { context as localeContext } from "context/locale"
import { context as userContext } from "context/user"
import { context as notificationsContext } from "context/notifications"
import { Text } from "components/service"
import { Input } from "components/form/elements"
import * as translations from "constants/translations"
import {
  Button,
  Modal,
  Spinner,
  More,
  ToggleButton,
  DragHandle,
  Pagination,
} from "components/kit"
import cx from "classnames"
import { useSelectedStore, useMobile } from "hooks"
import * as schemas from "./schemas"
import * as hooks from "./hooks"
import { ReactComponent as SearchIcon } from "assets/search.svg"
import { ReactComponent as ShevronDownIcon } from "assets/icons-chevron-down.svg"
import { ReactComponent as ShevronUpIcon } from "assets/icons-chevron-up.svg"
import DuplicateGroupModal from "../DuplicateGroupModal"
import OptionGroupModal from "../OptionGroupModal/OptionGroupModal"
import OptionsModel from "../OptionsModal/OptionsModal"
import EmptyState from "./EmptyState"
import ZeroResults from "./ZeroResults"

const OptionGroupTable = ({
  query,
  setQuery,
  langSelected,
  allOptionsQuery,
  optionGroupsQuery,
  optionsQuery,
  fetchOptionGroupId,
  setLocalOptions,
  localOptions,
  optionGroupSelected,
}) => {
  const storeId = useSelectedStore()
  
  const { lang, direction, translate } = useContext(localeContext)

  if (!optionGroupsQuery.data || optionGroupsQuery.loading) {
    return <Spinner />
  }
  
  const optionGroups = optionGroupsQuery.data.propertySections || []
  
  if (!optionGroups.length) {
    return (
      <EmptyState
        allOptionsQuery={allOptionsQuery}
        optionGroupsQuery={optionGroupsQuery}
        setOptionGroupSelected={optionGroupSelected}
      />
    )
  }

  return (
    <Modal>
      {({ open, close }) => {
        return (
          <Main
            direction={direction}
            branchId={query.location}
            status={query.status}
            optionGroups={optionGroups}
            langSelected={langSelected}
            lang={lang}
            allOptionsQuery={allOptionsQuery}
            optionGroupsQuery={optionGroupsQuery}
            openModal={open}
            closeModal={close}
            optionsQuery={optionsQuery}
            fetchOptionGroupId={fetchOptionGroupId}
            translate={translate}
            setLocalOptions={setLocalOptions}
            localOptions={localOptions}
            query={query}
            setQuery={setQuery}
            optionGroupSelected={optionGroupSelected}
          />
        )
      }}
    </Modal>
  )
}

const Main = ({
  direction,
  branchId,
  status,
  optionGroups,
  langSelected,
  lang,
  allOptionsQuery,
  openModal,
  closeModal,
  optionGroupsQuery,
  optionsQuery,
  fetchOptionGroupId,
  translate,
  setLocalOptions,
  localOptions,
  query,
  setQuery,
  optionGroupSelected,
}) => {
  const [openedId, setOpenedId] = useState("all")
  const [search, setSearch] = useState("")
  const searchTimeout = useRef()
  const isMobile = useMobile()

  useEffect(() => {
    setOpenedId(optionGroupSelected)
    fetchOptionGroupId(optionGroupSelected)
  }, [optionGroupSelected])

  useEffect(() => {
    setQuery({ page: 1 })
  }, [openedId])

  const totalCountAllOptions =
    allOptionsQuery &&
    allOptionsQuery.data &&
    allOptionsQuery.data.allPropertyValues &&
    allOptionsQuery.data.allPropertyValues.totalCount

  const pagination = totalCountAllOptions > 10 && (
    <div className="mt-4">
      <Pagination
        totalRecords={totalCountAllOptions}
        pageLimit={10}
        currentPage={parseInt(query.page)}
        onPageChanged={({ currentPage }) => {
          setQuery({ page: currentPage })
        }}
      />
    </div>
  )

  return (
    <>
      <div
        className="border rounded flex"
        style={{ direction, maxHeight: "81vh" }}
      >
        <div className="w-full md:w-1/4 pb-6">
          <div className="px-4 py-6">
            <span data-testid="count-groups" className="text-lg font-semibold">
              <Text
                value={translations.OPTION_GROUPS_COUNT}
                payload={optionGroups.length}
              />
            </span>
          </div>
          <div className="px-3 mb-4">
            <Input
              value={search}
              onChange={e => setSearch(e.target.value)}
              placeholder={translate(translations.SEARCH_GROUPS)}
              data-testid="optionGroups-search-option"
            />
          </div>
          <div className="w-full overflow-auto" style={{ maxHeight: "60vh" }}>
            <Groups
              allOptionsQuery={allOptionsQuery}
              totalCountAllOptions={totalCountAllOptions}
              items={optionGroups}
              openedId={openedId}
              onItemClick={setOpenedId}
              branchId={branchId}
              langSelected={langSelected}
              openModal={openModal}
              closeModal={closeModal}
              optionsQuery={optionsQuery}
              fetchOptionGroupId={fetchOptionGroupId}
              optionGroupsQuery={optionGroupsQuery}
              search={search}
              lang={lang}
              status={status}
              setLocalOptions={setLocalOptions}
              localOptions={localOptions}
              query={query}
              setQuery={setQuery}
              setOptionGroupSelected={optionGroupSelected}
            />
          </div>
        </div>
        <div
          className={cx(
            "hidden md:block w-3/4 py-6 px-4 relative",
            lang === "en" ? "border-l" : "border-r",
            allOptionsQuery.loading ? "overflow-hidden" : "overflow-auto"
          )}
          id={(openedId === "all" && "scroll-view-options").toString()}
        >
          {!isMobile && openedId === "all" && (
            <div className="w-full my-2">
              <Input
                type="text"
                icon={<SearchIcon />}
                search
                placeholder={
                  lang === "en"
                    ? translations.SEARCH_OPTION[0]
                    : translations.SEARCH_OPTION[1]
                }
                initialValue={query.title}
                onChange={e => {
                  clearTimeout(searchTimeout.current)
                  const { value } = e.target
                  searchTimeout.current = setTimeout(() => {
                    setQuery({ page: 1, title: value })
                  }, 500)
                }}
              />
            </div>
          )}
          {allOptionsQuery.loading ? (
            openedId === "all" && <Spinner />
          ) : (
            <Options
              branchId={branchId}
              status={status}
              optionGroupId={openedId}
              allOptionsQuery={allOptionsQuery}
              langSelected={langSelected}
              optionGroupName={optionGroups.find(og => og.id === openedId)}
              openModal={openModal}
              closeModal={closeModal}
              optionsQuery={optionsQuery}
              optionGroupsQuery={optionGroupsQuery}
              setLocalOptions={setLocalOptions}
              localOptions={localOptions}
              query={query}
              setOptionGroupSelected={optionGroupSelected}
              setQuery={setQuery}
              onSortEnd={async ({ oldIndex, newIndex }) => {
                const sorted = arrayMove(localOptions, oldIndex, newIndex)
                setLocalOptions(sorted)
              }}
            />
          )}
        </div>
      </div>
      {openedId === "all" && pagination}
    </>
  )
}

const Groups = ({
  items,
  openedId,
  branchId,
  status,
  onItemClick,
  totalCountAllOptions,
  allOptionsQuery,
  langSelected,
  openModal,
  closeModal,
  optionsQuery,
  fetchOptionGroupId,
  optionGroupsQuery,
  search,
  lang,
  setLocalOptions,
  localOptions,
  query,
  setQuery,
  setOptionGroupSelected,
}) => {
  const isMobile = useMobile()

  const groups = [
    {
      id: "all",
      titleAr: translations.ALL_OPTIONS_GROUPS[1],
      titleEn: translations.ALL_OPTIONS_GROUPS[0],
    },
    ...items,
  ]

  return groups
    .filter(item =>
      new RegExp(search.replace(/[\^$\\.*+?()[\]{}|]/g, "\\$&"), "i").test(
        lang === "en" ? item.titleEn : item.titleAr
      )
    )
    .map((item, i) => {
      return (
        <Group
          item={item}
          index={i}
          allOptionsQuery={allOptionsQuery}
          totalCountAllOptions={totalCountAllOptions}
          items={items}
          openedId={openedId}
          onItemClick={onItemClick}
          branchId={branchId}
          langSelected={langSelected}
          openModal={openModal}
          closeModal={closeModal}
          optionsQuery={optionsQuery}
          fetchOptionGroupId={fetchOptionGroupId}
          optionGroupsQuery={optionGroupsQuery}
          search={search}
          lang={lang}
          status={status}
          setLocalOptions={setLocalOptions}
          localOptions={localOptions}
          query={query}
          setQuery={setQuery}
          setOptionGroupSelected={setOptionGroupSelected}
        />
      )
    })
}

const Group = ({
  item,
  index,
  openedId,
  branchId,
  status,
  onItemClick,
  totalCountAllOptions,
  allOptionsQuery,
  langSelected,
  openModal,
  closeModal,
  optionsQuery,
  fetchOptionGroupId,
  optionGroupsQuery,
  setOptionGroupSelected,
  setLocalOptions,
  localOptions,
  query,
  setQuery,
}) => {
  const [collapse, setCollapse] = useState(item.id === "all" ? true : false)
  const isMobile = useMobile()
  return (
    <div
      className={cx(
        "border border-gray-300 rounded m-2 md:rounded-none md:border-none md:m-0 cursor-pointer",
        openedId === item.id ? "bg-zyda-grey-50" : "hover:bg-gray-200"
      )}
      onClick={() => {
        onItemClick(item.id)
        fetchOptionGroupId(item.id)
      }}
      key={item.id}
      data-testid={
        item.id === "all" ? "all-optionGroups" : `groups-list-item-${index}`
      }
    >
      <div
        className="w-full px-4 py-4"
        onClick={() => {
          setCollapse(!collapse)
        }}
      >
        <span className="flex">
          {isMobile ? collapse ? <ShevronDownIcon /> : <ShevronUpIcon /> : null}
          <Text value={item} className="block font-semibold" />
          {isMobile && item.id !== "all" ? (
            <DuplicateGroup
              groupName={item}
              optionGroupsQuery={optionGroupsQuery}
              allOptionsQuery={allOptionsQuery}
              openModal={openModal}
              closeModal={closeModal}
            />
          ) : null}
        </span>
        <span
          data-testid="gourp-count-status"
          className="block text-xs text-gray-700"
        >
          {item.id === "all" && totalCountAllOptions ? (
            <Text
              prefix={totalCountAllOptions.toString()}
              value={translations.OPTIONS}
            />
          ) : !!item.assignedMenuItemsCount ? (
            <Text
              value={translations.OPTIONS_SHARED_WITH_PRODUCT}
              payload={[
                (item.propertyValues || []).length,
                item.assignedMenuItemsCount,
              ]}
            />
          ) : (
            <Text
              prefix={(item.propertyValues || []).length.toString()}
              value={translations.OPTIONS}
            />
          )}
        </span>
      </div>
      {isMobile && openedId === item.id && collapse && (
        <div
          className={cx("mt-3 overflow-auto bg-white")}
          style={{ maxHeight: "50vh" }}
          id={(openedId === "all" && "scroll-view-options").toString()}
        >
          {allOptionsQuery.loading ? (
            openedId === "all" && <Spinner />
          ) : (
            <Options
              collapse={collapse}
              allOptionsQuery={allOptionsQuery}
              branchId={branchId}
              status={status}
              optionGroupId={item.id}
              langSelected={langSelected}
              optionsQuery={optionsQuery}
              setLocalOptions={setLocalOptions}
              localOptions={localOptions}
              query={query}
              setOptionGroupSelected={setOptionGroupSelected}
              setQuery={setQuery}
              useDragHandle
              onSortEnd={async ({ oldIndex, newIndex }) => {
                const sorted = arrayMove(localOptions, oldIndex, newIndex)
                setLocalOptions(sorted)
              }}
            />
          )}
        </div>
      )}
    </div>
  )
}

const Options = ({
  allOptionsQuery,
  branchId,
  status,
  optionGroupId,
  optionGroupName,
  langSelected,
  openModal,
  closeModal,
  optionGroupsQuery,
  optionsQuery,
  setLocalOptions,
  localOptions,
  onSortEnd,
  query,
  setQuery,
  setOptionGroupSelected,
}) => {
  const isMobile = useMobile()
  const [isSorting, setIsSorting] = useState(false)
  const notifications = useContext(notificationsContext)
  const searchTimeout = useRef()

  const [sortOptions, { sortLoading }] = useMutation(schemas.SORT_OPTIONS, {
    onCompleted: () => {
      notifications.show(<Text value={translations.OPTIONS_GROUPS_SORTED} />)
    },
  })

  const options =
    optionGroupId === "all"
      ? (allOptionsQuery &&
          allOptionsQuery.data &&
          allOptionsQuery.data.allPropertyValues.allPropertyValues) ||
        []
      : optionsQuery?.data?.propertyValues || []

  useEffect(() => {
    setLocalOptions(options)
  }, [optionsQuery.data, allOptionsQuery.data, query.title, query.search])

  const {
    selectedStore: { currency },
    branches,
  } = useContext(userContext)
  const storeId = useSelectedStore()
  const { lang, direction } = useContext(localeContext)

  const { hasRole, isPosCourier: isFoodics } = useContext(userContext)

  var groupName = optionGroupName

  const allBranchIds = [...branches.map(item => item.id)].sort()

  const selectedGroup = optionGroupId === "all" ? false : true

  const SearchForOptions = () => {
    return (
      <div className="w-full">
        <Input
          type="text"
          icon={<SearchIcon />}
          search
          placeholder={
            lang === "en"
              ? translations.SEARCH_OPTION[0]
              : translations.SEARCH_OPTION[1]
          }
          initialValue={query.search}
          onChange={e => {
            clearTimeout(searchTimeout.current)
            const { value } = e.target
            searchTimeout.current = setTimeout(() => {
              setQuery({ page: 1, search: value })
            }, 500)
          }}
        />
      </div>
    )
  }

  const SortingActions = () => {
    return (
      <Fragment>
        <div className="mx-2">
          <Button
            kind="tertiary"
            onClick={() => {
              setLocalOptions(optionsQuery?.data?.propertyValues)
              setIsSorting(!isSorting)
            }}
          >
            <Text value={translations.CANCEL} />
          </Button>
        </div>
        <Button
          kind="primary"
          isSpinning={sortLoading}
          onClick={async () => {
            const formattedOptionsArr = localOptions.map((item, i) => ({
              id: item.id,
              attributes: {
                position: i,
              },
            }))

            await sortOptions({
              variables: {
                restaurantId: storeId,
                propertySectionId: optionGroupId,
                sort: formattedOptionsArr,
              },
            })
            setIsSorting(!isSorting)
          }}
        >
          <Text value={translations.SAVE} />
        </Button>
      </Fragment>
    )
  }

  const OptionsHeader = () => {
    return (
      <Fragment>
        {!query.search && !!options.length && (
          <div className="mx-2">
            <Button
              kind="tertiary"
              onClick={() => setIsSorting(!isSorting)}
              className="mx-2"
            >
              <Text value={translations.SORT} />
            </Button>
          </div>
        )}
        {!isMobile && (
          <Button
            onClick={() => {
              openModal({
                title: (
                  <Text
                    value={translations.OPTION_GROUPS_EDIT_GROUP_MODAL}
                    className="text-lg"
                  />
                ),
                body: (
                  <OptionGroupModal
                    type="edit"
                    initialValues={{ isFoodics, groupName }}
                    onCancel={closeModal}
                    openModel={openModal}
                    refetchAllOptions={allOptionsQuery}
                    refetchAllGroup={optionGroupsQuery}
                  />
                ),
              })
            }}
          >
            <Text value={translations.OPTION_GROUPS_EDIT_GROUP} />
          </Button>
        )}
      </Fragment>
    )
  }

  return (
    <>
      {selectedGroup && (
        <div
          className="flex items-center justify-between border-b border-gray-300 "
          style={{ direction }}
        >
          {!isMobile && <SearchForOptions />}
          {!hasRole("operator") && (
            <div className="flex">
              <div
                className={cx(
                  "flex m-2",
                  lang === "en" ? !isMobile && "mr-4" : "ml-4"
                )}
              >
                {isSorting ? <SortingActions /> : <OptionsHeader />}
              </div>

              <div
                className={cx(
                  isMobile ? "hidden" : "flex-2 my-2 ",
                  lang === "en" ? "mr-4" : "ml-4"
                )}
              >
                <DuplicateGroup
                  groupName={optionGroupName}
                  optionGroupsQuery={optionGroupsQuery}
                  allOptionsQuery={allOptionsQuery}
                  openModal={openModal}
                  closeModal={closeModal}
                />
              </div>
            </div>
          )}
        </div>
      )}
      {optionsQuery.loading ? (
        <div className="w-full h-full flex">
          <Spinner />
        </div>
      ) : (
        <div className={cx("flex flex-col max-h-screen pb-12")}>
          {!options.length ? (
            <ZeroResults
              initialValues={{ optionGroupId, isFoodics }}
              refetchAllOptions={allOptionsQuery}
              refetchAllGroup={optionGroupsQuery}
              refetchOptions={optionsQuery}
            />
          ) : (
            <SortableOptions useDragHandle onSortEnd={onSortEnd}>
              {localOptions
                .filter(item =>
                  status === "all"
                    ? item
                    : status === "avaliable"
                    ? item.availableBranchIds.includes(branchId)
                    : !item.availableBranchIds.includes(branchId)
                )
                .map((item, i) => (
                  <SortableOption
                    key={item.id}
                    isFoodics={isFoodics}
                    index={i}
                    sortIndex={i}
                    item={item}
                    allOptionsQuery={allOptionsQuery}
                    branchId={branchId}
                    optionGroupId={optionGroupId}
                    langSelected={langSelected}
                    currency={currency}
                    allBranchIds={allBranchIds}
                    optionsQuery={optionsQuery}
                    optionGroupsQuery={optionGroupsQuery}
                    isDraggable={isSorting}
                  />
                ))}
            </SortableOptions>
          )}
        </div>
      )}
    </>
  )
}

const Option = ({
  item,
  index,
  sortIndex,
  allOptionsQuery,
  branchId,
  optionGroupId,
  langSelected,
  currency,
  allBranchIds,
  optionsQuery,
  optionGroupsQuery,
  isDraggable,
  isFoodics,
}) => {
  const storeId = useSelectedStore()
  const { hasRole } = useContext(userContext)

  const [changeAvailability, { loading: changingAvailability }] = useMutation(
    schemas.CHANGE_AVAILABILITY,
    {
      variables: {
        storeId,
        branchId,
      },
    }
  )
  const [
    changeAllAvailability,
    { loading: changingAllAvailability },
  ] = useMutation(schemas.CHANGE_ALL_AVAILABILITY, {
    variables: { storeId },
  })

  return (
    <Modal>
      {({ open, close }) => (
        <div
          className={cx(
            "flex justify-between items-center w-full border-b border-gray-300 p-4"
          )}
          data-testid={`item-option-${sortIndex}`}
        >
          <div className="flex items-center">
            {isDraggable && <DragHandle />}
            <div className="flex flex-col">
              <Text
                selectedLang={langSelected}
                className="block font-semibold"
                value={item}
              />
              <span className="block text-xs text-gray-700">
                <Text
                  selectedLang={langSelected}
                  value={currency}
                  postfix={item.price.toString()}
                />
              </span>
            </div>
          </div>

          <div
            className={cx(
              "flex flex-row",
              langSelected === "ar" ? "flex-row-reverse" : "justify-end "
            )}
          >
            <div
              className={cx(
                "flex-2 my-3",
                langSelected === "en" ? "mr-4" : "ml-4"
              )}
            >
              <ToggleButton
                btnTestId={"option-toggle-available"}
                statusTestId={"option-status-available"}
                title
                name={item.id}
                loading={changingAvailability || changingAllAvailability}
                value={
                  branchId === "all"
                    ? equals(allBranchIds, [...item.availableBranchIds].sort())
                    : item.availableBranchIds.includes(branchId)
                }
                onChange={async () => {
                  if (branchId === "all") {
                    const enabled = equals(
                      allBranchIds,
                      [...item.availableBranchIds].sort()
                    )

                    await changeAllAvailability({
                      variables: {
                        branchIds: enabled ? [] : allBranchIds,
                        propertyValueId: item.id,
                        propertySectionId:
                          optionGroupId === "all"
                            ? item.propertyId
                            : optionGroupId,
                      },
                    })
                  } else {
                    const enabled = item.availableBranchIds.includes(branchId)
                    await changeAvailability({
                      variables: {
                        propertyValueId: item.id,
                        propertySectionId:
                          optionGroupId === "all"
                            ? item.propertyId
                            : optionGroupId,
                        isAvailable: !enabled,
                      },
                    })
                  }

                  await allOptionsQuery.refetch()
                  optionGroupId !== "all" && (await optionsQuery.refetch())
                }}
              />
            </div>
            {!isDraggable && (
              <div
                className={cx(
                  "flex-2 ",
                  langSelected === "en" ? "mr-4" : "ml-4"
                )}
              >
                {!hasRole("operator") && (
                  <>
                    <Button
                      data-testid="optionGroups-option-edit"
                      onClick={() => {
                        open({
                          title: (
                            <Text
                              value={translations.OPTION_GROUPS_EDIT_OPTION}
                              className="text-lg"
                            />
                          ),
                          body: (
                            <OptionsModel
                              type="edit"
                              initialValues={{ isFoodics, item, optionGroupId }}
                              onCancel={close}
                              openModel={open}
                              refetchAllOptions={allOptionsQuery}
                              refetchOptions={optionsQuery}
                              refetchAllGroup={optionGroupsQuery}
                            />
                          ),
                        })
                      }}
                    >
                      <Text value={translations.EDIT} />
                    </Button>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </Modal>
  )
}

const DuplicateGroup = ({
  groupName,
  openModal,
  closeModal,
  allOptionsQuery,
  optionGroupsQuery,
}) => {
  const { isPosCourier: isFoodics } = useContext(userContext)
  const isMobile = useMobile()

  return (
    <More
      kind="tertiary"
      iconSize="2xl"
      items={[
        {
          title: <Text value={translations.OPTION_GROUPS_DUPLICATE_GROUP} />,
          onClick: () => {
            openModal({
              testId: "edit-category-modal",
              body: (
                <DuplicateGroupModal
                  initialValues={{ groupName, isFoodics }}
                  onCancel={closeModal}
                  refetchAllGroup={optionGroupsQuery}
                  refetchAllOptions={allOptionsQuery}
                />
              ),
              title: (
                <Text
                  value={translations.OPTION_GROUPS_DUPLICATE_GROUP_MODAL}
                  className="text-lg"
                />
              ),
            })
          },
        },
        ...(isMobile === true
          ? [
              {
                title: <Text value={translations.OPTION_GROUPS_EDIT_GROUP} />,
                onClick: () => {
                  openModal({
                    title: (
                      <Text
                        value={translations.OPTION_GROUPS_EDIT_GROUP_MODAL}
                        className="text-lg"
                      />
                    ),
                    body: (
                      <OptionGroupModal
                        type="edit"
                        initialValues={{ isFoodics, groupName }}
                        onCancel={closeModal}
                        openModel={openModal}
                        refetchAllOptions={allOptionsQuery}
                        refetchAllGroup={optionGroupsQuery}
                      />
                    ),
                  })
                },
              },
            ]
          : []),
      ]}
    />
  )
}

const SortableOption = SortableElement(Option)
const SortableOptions = SortableContainer(({ children }) => (
  <div>{children}</div>
))

export default OptionGroupTable
