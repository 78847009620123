import React from 'react';
import cx from 'classnames';
import { Typography, useTheme } from '@zydalabs/zac-react';
import PropTypes from 'prop-types';

import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { homeLayoutShapes, HOME_LAYOUT_ENUMS, productLayout } from './data';

const HomeLayout = ({ setFieldValue, menuSectionLayout, menuItemsLayout }) => {
  const { colors } = useTheme();
  const shouldDisableProductLayoutSelection = menuSectionLayout === HOME_LAYOUT_ENUMS.ONE_PAGE;

  return (
    <div className="border-b border-gray-300 pb-6">
      <Typography variant="heading16" mt={24}>
        <Text value={translations.MENU} />
      </Typography>
      <Typography variant="body14" mt={4} color={colors.positive.secondary} mb={24}>
        <Text value={translations.SELECT_MENU} />
      </Typography>
      <Typography variant="body14" mt={18} mb={18}>
        <Text value={translations.HOME_LAYOUT} />
      </Typography>
      <div className="flex gap-6 flex-wrap xl:flex-nowrap">
        {homeLayoutShapes.map(item => {
          const isLayoutSelected = menuSectionLayout === item.id;
          return (
            <div
              className={cx(
                'col-span-4 py-4 px-6 cursor-pointer border rounded w-56',
                isLayoutSelected ? 'border-blue-500' : 'border-gray-300',
              )}
              role="presentation"
              onClick={() => setFieldValue('menuSectionLayout', item.id)}
              key={item.id}
            >
              <Typography
                cursor="pointer"
                color={isLayoutSelected ? colors.accent.primary : colors.positive.primary}
                mb={16}
              >
                {item.title}
              </Typography>
              {item.icon}
            </div>
          );
        })}
      </div>
      <Typography variant="body14" mt={18} mb={18}>
        <Text value={translations.PRODUCT_LAYOUT} />
      </Typography>
      <div className="flex gap-6 flex-wrap xl:flex-nowrap">
        {productLayout.map(item => {
          const isLayoutSelected = menuItemsLayout === item.id;
          return (
            <div
              className={cx(
                'col-span-4 p-6 cursor-pointer border rounded w-56',
                isLayoutSelected && !shouldDisableProductLayoutSelection ? 'border-blue-500' : 'border-gray-300',
                shouldDisableProductLayoutSelection && 'cursor-not-allowed opacity-40',
              )}
              onClick={() => !shouldDisableProductLayoutSelection && setFieldValue('menuItemsLayout', item.id)}
              key={`${menuSectionLayout}+${item.id}`}
              role="presentation"
            >
              <Typography
                cursor="pointer"
                color={
                  isLayoutSelected && !shouldDisableProductLayoutSelection
                    ? colors.accent.primary
                    : colors.positive.primary
                }
                mb={16}
              >
                {item.title}
              </Typography>
              {item.icon}
            </div>
          );
        })}
      </div>
    </div>
  );
};

HomeLayout.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  menuSectionLayout: PropTypes.number.isRequired,
  menuItemsLayout: PropTypes.number.isRequired,
};

export default HomeLayout;
