import { gql } from '@apollo/client';

const GEO_BRANCHES = gql`
  query($storeId: String!, $range: AnalyticsRange!, $page: Int!, $limit: Int!, $filter: String) {
    geoAnalyticsBranches(restaurantId: $storeId, range: $range, page: $page, limit: $limit, filter: $filter) {
      startDate
      endDate
      data {
        branches {
          id
          titleEn
          titleAr
          totalSales
          totalOrders
          totalCustomers
          avgOrderValue
          totalDeliveryOrders
          totalPickupOrders
        }
        meta {
          totalCount
          totalPages
        }
      }
    }
  }
`;

export default GEO_BRANCHES;
