import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import moment from 'moment';

const SalesRange = ({ startDate, endDate, size = 'base' }) => {
  const format = value => moment(value, 'YYYY-MM-DD').format('MMM D, YYYY');
  const range = `${format(startDate)} - ${format(endDate)}`;

  return startDate && endDate && <span className={cx('text-gray-700', `text-${size}`)}>{range}</span>;
};

SalesRange.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  size: PropTypes.string,
};

export default SalesRange;
