import React, { useContext, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import cx from 'classnames';

import { Layout, Breadcrumbs } from 'components/common/dashboard';
import * as translations from 'constants/translations';
import { Text } from 'components/service';
import EmptyState from 'components/common/EmptyState';
import { Button, Spinner } from 'components/kit';
import LinkForm from 'common/components/LinkForm';
import { webLinksTypes } from 'components/common/WebLink/data';
import * as paths from 'paths';
import { context as userContext } from 'context/user';
import { context as localeContext } from 'context/locale';
import { BACKEND_GRAPHQL_SERVER } from 'constants/backendGraphqlServer';
import LinksTable from 'common/components/LinksTable';
import { ReactComponent as AddIcon } from 'assets/add.svg';
import { ReactComponent as OrderfastLinks } from 'assets/orderfastLand.svg';
import { context as notificationsContext } from 'context/notifications';
import * as utils from 'pages/setup/webLinks/utils';
import { ORDERFAST_LINKS, SORT_ORDERFAST_LINKS } from './schemas';
import OrderfastLinksList from './linksList';
import useDeleteOrderfastLink from './hooks/useDeleteOrderfastLink';
import useUpdateOrderfastLink from './hooks/useUpdateOrderfastLink';
import useAddOrderfastLink from './hooks/useAddOrderfastLink';

const OrderfastLinksPage = () => {
  const [isAddingNewLink, setIsAddingNewLink] = useState(false);
  const { selectedStore } = useContext(userContext);
  const { direction, lang } = useContext(localeContext);
  const notifications = useContext(notificationsContext);
  const { subdomain, id: selectedStoreId } = selectedStore || {};
  const { data, loading: isLinksLoading, updateQuery } = useQuery(ORDERFAST_LINKS, {
    variables: { subdomain },
    context: { serverName: BACKEND_GRAPHQL_SERVER },
  });
  // hooks
  const [handleOnAddClick, { loading: isAddingLoading }] = useAddOrderfastLink({ lang });
  const [handleOnUpdateClick] = useUpdateOrderfastLink({ lang });
  const [handleOnDeleteClick] = useDeleteOrderfastLink({ lang });
  const [sortLinks, { loading: isSortingLoading }] = useMutation(SORT_ORDERFAST_LINKS, {
    context: { serverName: BACKEND_GRAPHQL_SERVER },
    onCompleted: () => {
      notifications.show(<Text value={translations.THE_LINKS_SORTED} />);
    },
  });

  const { orderfastLinks } = data || {};
  const links = orderfastLinks ? orderfastLinks.slice(1) : [];

  const formattedLinks = useMemo(() => links?.map(link => utils.formatLinkToForm(link, 'content')), [links]);

  const handleOnSubmit = async formData => {
    await handleOnAddClick(formData);
    setIsAddingNewLink(false);
  };

  const handleSortingLinks = async sortedItems => {
    const { data: sortedData } = await sortLinks({
      variables: {
        links: sortedItems.map(({ id }, index) => ({
          id,
          position: index + 2,
        })),
        subdomain,
      },
    });
    updateQuery?.(prevData => ({
      ...prevData,
      orderfastLinks: sortedData?.sortOrderfastLinks,
    }));
  };

  return (
    <Layout
      top={
        <Breadcrumbs
          links={[paths.orderfast]}
          path={translations.breadcrumbs.ORDERFAST_LINKS}
          right={
            <Button kind="primary" onClick={() => setIsAddingNewLink(true)}>
              <AddIcon />
              <Text value={translations.ADD_NEW_LINK} />
            </Button>
          }
        />
      }
    >
      {isLinksLoading ? (
        <Spinner />
      ) : (
        <div className="flex h-full justify-between" style={{ direction }}>
          <div className="pb-6 w-2/3">
            <div className="flex flex-col">
              <div>
                {isAddingNewLink && (
                  <div className="flex border border-gray-300 pb-4 mb-4 flex-col rounded-lg">
                    <LinkForm
                      formClassName="px-4"
                      onSubmit={handleOnSubmit}
                      isSubmitting={isAddingLoading}
                      linksTypes={webLinksTypes}
                      renderTopPane={() => (
                        <div className="my-2 py-2 flex justify-between border-b-2 px-4">
                          <div>
                            <Text className="font-medium text-black" value={translations.ADD_NEW_LINK} />
                            <Text value={translations.URL} />
                          </div>
                          <div>
                            <Button type="button" kind="link" onClick={() => setIsAddingNewLink(false)}>
                              <Text value={translations.CANCEL} />
                            </Button>
                          </div>
                        </div>
                      )}
                      renderBottomPane={({ isSubmitting }) => (
                        <div>
                          <Button kind="primary" isSpinning={isSubmitting}>
                            <Text value={translations.SAVE} />
                          </Button>
                        </div>
                      )}
                    />
                  </div>
                )}
                {!isAddingNewLink && links.length === 0 ? (
                  <EmptyState
                    iconComponent={
                      <div className="mt-64">
                        <OrderfastLinks />
                      </div>
                    }
                    emptyStateText={translations.ADD_LINKS_TO_ORDERFAST}
                  />
                ) : (
                  <LinksTable
                    storeId={selectedStoreId}
                    setSortedItems={handleSortingLinks}
                    isSavingSorting={isSortingLoading}
                    items={formattedLinks}
                    onEditClick={handleOnUpdateClick}
                    onDeleteClick={handleOnDeleteClick}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={cx('w-96 h-4/5', lang === 'en' ? 'ml-6' : 'mr-6')}>
            <OrderfastLinksList selectedStore={selectedStore} links={links} />
          </div>
        </div>
      )}
    </Layout>
  );
};

export default OrderfastLinksPage;
