import React, { useContext, useState } from "react"
import { context as userContext } from "context/user"
import { context as localeContext } from "context/locale"
import { Text, Field } from "components/service"
import { Label, Row, Container } from "components/form/generic"
import { Input } from "components/form/elements"
import * as translations from "constants/translations"
import { ReactComponent as Eye } from "assets/eye.svg"

export default () => {
  const { lang } = useContext(localeContext)
  const { branches } = useContext(userContext)
  const [showOldPassword, setShowOldPassword] = useState("password")
  const [showNewPassword, setShowNewPassword] = useState("password")
  const [showConfirmedPassword, setShowConfirmedPassword] = useState("password")

  const showPasswordOld = () => {
    setShowOldPassword(state => (state === "password" ? "input" : "password"))
  }

  const showPasswordNew = () => {
    setShowNewPassword(state => (state === "password" ? "input" : "password"))
  }

  const showPasswordConfirm = () => {
    setShowConfirmedPassword(state =>
      state === "password" ? "input" : "password"
    )
  }

  return (
    <div className="mt-2 pb-32 md:pb-12">
      <Container>
        <Row>
          <Label title={<Text value={translations.Old_PASSWORD} />}>
            <Field
              type={showOldPassword}
              name="old_password"
              icon={<Eye />}
              after={true}
              showPassword={showPasswordOld}
              placeholder={
                lang === "en"
                  ? translations.Old_PASSWORD[0]
                  : translations.Old_PASSWORD[1]
              }
              component={Input}
            />
          </Label>
        </Row>
        <Row>
          <Label title={<Text value={translations.NEW_PASSWORD} />}>
            <div style={{ width: "100%" }}>
              <Field
                type={showNewPassword}
                name="new_password"
                icon={<Eye />}
                after={true}
                showPassword={showPasswordNew}
                placeholder={
                  lang === "en"
                    ? translations.NEW_PASSWORD[0]
                    : translations.NEW_PASSWORD[1]
                }
                component={Input}
              />
              <p
                style={{
                  fontFamily: "Inter",
                  fontSize: "12px",
                  lineHeight: "1.43",
                  color: "#bfc1c6",
                  textAlign: "start",
                  marginTop: "8px",
                }}
              >
                <Text value={translations.PASSWORD_CONSTRAINT} />
              </p>
            </div>
          </Label>
        </Row>
        <Row>
          <Label title={<Text value={translations.REPEAT_NEW_PASSWORD} />}>
            <Field
              type={showConfirmedPassword}
              name="confirm_new_password"
              icon={<Eye />}
              after={true}
              showPassword={showPasswordConfirm}
              placeholder={
                lang === "en"
                  ? translations.REPEAT_NEW_PASSWORD[0]
                  : translations.REPEAT_NEW_PASSWORD[1]
              }
              component={Input}
            />
          </Label>
        </Row>
      </Container>
    </div>
  )
}
