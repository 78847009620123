import { gql } from '@apollo/client';

const UPDATE_BRANCH = gql`
  mutation UpdateBranch(
    $id: String!
    $storeId: String!
    $busyFrom: String
    $busyUntil: String
    $busyMode: BusyMode
    $busy: Boolean
  ) {
    updateBranch(
      id: $id
      restaurantId: $storeId
      busyFrom: $busyFrom
      busyUntil: $busyUntil
      busyMode: $busyMode
      busy: $busy
    ) {
      id
      busyFrom
      busyUntil
      busyMode
      busy
    }
  }
`;

export default UPDATE_BRANCH;
