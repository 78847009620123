import { gql } from '@apollo/client';

export const PAYMENT_CREDENTIALS = gql`
  query($storeId: String!) {
    paymentCredentials(restaurantId: $storeId) {
      id
      merchantId
      countryCode
      transfer
      status
      profileName
      publicApiKey
      remarks
      bankAccountInfo {
        iban
      }
      corporateInfo {
        name
      }
      restaurantPaymentMethods {
        name
        enabled
        id
        referenceId
        gmvText {
          ar
          en
        }
      }
      paymentGateway {
        id
        name
        integrationFromGateway
        extraData {
          title {
            ar
            en
          }
          description {
            ar
            en
          }
          pricingUrl
        }
        logoUrl
        paymentMethods {
          name
          code
          id
        }
      }
    }
    settings(restaurantId: $storeId) {
      id
      onlinePaymentEnabled
      cashPaymentEnabled
      cardondeliveryPaymentEnabled
    }
  }
`;
export const UPDATE_PAYMENT_METHODS_ENABLED = gql`
  mutation($restaurantId: String!, $id: Int!, $enabled: Boolean!) {
    updatePaymentMethods(restaurantId: $restaurantId, id: $id, enabled: $enabled) {
      name
      enabled
      id
      gmvText {
        ar
        en
      }
    }
  }
`;

export const PAYMENT_GATEWAY = gql`
  query($countryId: Int!, $gatewayId: Int!) {
    paymentGateway(countryId: $countryId, gatewayId: $gatewayId) {
      id
      name
      integrationFromGateway
      extraData {
        title {
          ar
          en
        }
        description {
          ar
          en
        }
        steps {
          type
          title {
            ar
            en
          }
          description {
            ar
            en
            url
          }
          fields {
            type
            required
            name
            label {
              ar
              en
            }
            legend {
              ar
              en
            }
          }
        }
        pricingUrl
      }
      logoUrl
      paymentMethods {
        name
        code
        id
        gmvText {
          ar
          en
        }
      }
    }
  }
`;

export const ADD_PAYMENT_CREDENTIALS = gql`
  mutation(
    $restaurantId: String!
    $paymentGatewayId: Int
    $apiKey: String
    $merchantId: String
    $iframeId: String
    $cardIntegrationId: String
  ) {
    addPaymentCredentials(
      restaurantId: $restaurantId
      paymentGatewayId: $paymentGatewayId
      apiKey: $apiKey
      merchantId: $merchantId
      iframeId: $iframeId
      cardIntegrationId: $cardIntegrationId
    ) {
      id
      isLicensed
    }
  }
`;
