import React, { useRef, useContext } from 'react';
import posed from 'react-pose';
import { PoseGroup } from 'react-pose';
import cx from 'classnames';
import { navigate } from '@reach/router';

import { context as userContext } from 'context/user';
import { context as localeContext } from 'context/locale';
import * as paths from 'paths';
import Store from 'common/components/Store';
import { useClickOutside, useReplaceParams } from 'hooks/index';
import Sidebar from '../Sidebar';

export default ({ isVisible, onClose }) => {
  const ref = useRef();
  const replace = useReplaceParams();
  useClickOutside(ref, onClose);
  const { lang } = useContext(localeContext);
  const { selectedStore } = useContext(userContext);

  return (
    <PoseGroup animateOnMount>
      {isVisible && [
        <Wrap
          key="wrap"
          className="fixed top-0 left-0 w-full h-full z-20 flex"
          style={{ backgroundColor: 'rgba(0, 0, 0, 0.62)' }}
        />,
        <Body
          lang={lang}
          key="body"
          className={cx('flex absolute h-screen z-20 top-0', lang === 'ar' ? 'right-0' : 'left-0 ')}
        >
          <div className={cx('flex flex-row', lang === 'ar' && 'flex-row-reverse')}>
            <Sidebar
              header={
                <div className={lang === 'ar' && 'float-right'}>
                  <Store
                    onClick={() => navigate(replace(paths.switchStore, selectedStore.id))}
                    isMain
                    {...selectedStore}
                  />
                </div>
              }
              ref={ref}
            />
            <a className="material-icons cursor-pointer text-white ml-4 mt-2 text-3xl">close</a>
          </div>
        </Body>,
      ]}
    </PoseGroup>
  );
};

const Wrap = posed.div({
  enter: { opacity: 1, transition: { duration: 200 } },
  exit: { opacity: 0, transition: { duration: 200 } },
});

const Body = posed.div({
  enter: { opacity: 1, x: 0, transition: { duration: 200 } },
  exit: {
    opacity: 0,
    x: props => (props.lang === 'ar' ? 200 : -200),
    transition: { duration: 200 },
  },
});
