import React from "react"
import { Stack } from "components/kit"
import { useMobile } from "hooks/index"

export default ({ children, tooltip, ...props }) => {
  const isMobile = useMobile()
  const direction = isMobile && !tooltip ? "col" : "row"
  return (
    <Stack
      direction={direction}
      {...props}
      tooltip={tooltip}
    >
      {children}
    </Stack>
  )
}
