import React from 'react';
import cx from 'classnames';
import { Error } from 'components/form/generic';

const Textarea = ({ error, direction = 'ltr', ...props }) => (
  <div className="w-full">
    <textarea
      style={{ direction }}
      className={cx(
        'block border-gray-300 appearance-none border rounded-none w-full py-2 px-3 text-zyda-black-100 leading-tight hover:border-primary-200 focus:border-primary-base focus:outline-none focus:ring',
        error && 'border-zyda-red-500 bg-zyda-red-50',
      )}
      {...props}
    />
    {error && <Error>{error}</Error>}
  </div>
);
Textarea.displayName = 'Textarea';
export default Textarea;
