import { gql } from "@apollo/client"

export const VOUCHERS = gql`
  query($storeId: String!, $status: String!, $page: Int!, $limit: Int!) {
    vouchersList(
      restaurantId: $storeId
      page: $page
      limit: $limit
      status: $status
    ) {
      vouchers {
        id
        createdAt
        code
        description
        descriptionAr
        isShowcased
        validFrom
        validUntil
        type
        voucherRedemptionsCount
        redemptionLimit
        active
        expired
        amount
        minSubtotalAmount
        validTimeFrom
        validTimeTo
        redemptionLimitPerUser
        isFreeDelivery
        deliveryType
        status
      }
      totalCount
    }
  }
`

export const UPDATE_VOUCHER = gql`
  mutation(
    $storeId: String!
    $voucherId: String!
    $isActive: Boolean
    $isShowcased: Boolean
  ) {
    updateVoucher(
      restaurantId: $storeId
      id: $voucherId
      isActive: $isActive
      isShowcased: $isShowcased
    ) {
      id
      isShowcased
      active
    }
  }
`
