import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-responsive-modal';
import cx from 'classnames';

import { Button } from 'components/kit';
import { Text } from 'components/service';
import * as translations from 'constants/translations';

const BulkChangeConfirmationModal = ({
  changeBulkOrdersStatus,
  showConfirmationModal,
  setShowConfirmatioModal,
  lang,
}) => (
  <Modal center open={showConfirmationModal} className="rounded-lg" onClose={() => setShowConfirmatioModal(false)}>
    <div className={cx('flex flex-col px-4 mb-4 py-2', lang === 'ar' && 'flex-row-reverse')}>
      <div className="font-semibold text-lg mb-4">
        <Text value={translations.APPLY_UPDATES} />
      </div>
      <div className="mb-4">
        <Text value={translations.BULK_CHANGE_CONFIRMATION_MESSAGE} />
      </div>
    </div>
    <div className={cx('flex', lang === 'ar' && 'flex-row-reverse')}>
      <Button onClick={changeBulkOrdersStatus} kind="primary" isRounded className={cx(lang === 'ar' ? 'ml-4' : 'mr-4')}>
        <Text value={translations.CONFIRM} />
      </Button>
      <Button
        onClick={() => setShowConfirmatioModal(false)}
        kind="tertiary"
        isRounded
        textColor="text-primary-base"
        borderColor="border-primary-base"
      >
        <Text value={translations.CANCEL} />
      </Button>
    </div>
  </Modal>
);

BulkChangeConfirmationModal.propTypes = {
  changeBulkOrdersStatus: PropTypes.func.isRequired,
  showConfirmationModal: PropTypes.bool.isRequired,
  setShowConfirmatioModal: PropTypes.func.isRequired,
  lang: PropTypes.oneOf(['en', 'ar']).isRequired,
};
export default BulkChangeConfirmationModal;
