import { gql } from '@apollo/client';

export const UPDATE_RINGER = gql`
  mutation($storeId: String!, $userId: String!, $ringerType: Int) {
    userUpdateSelf(restaurantId: $storeId, userId: $userId, ringerType: $ringerType) {
      storeRingerSetting {
        ringerType
      }
    }
  }
`;

export const USER_RING = gql`
  query($storeId: String!, $id: String!) {
    user(restaurantId: $storeId, id: $id) {
      storeRingerSetting {
        ringerType
      }
    }
  }
`;
