import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { ReactComponent as CollapseIcon } from 'assets/collapse.svg';
import { ReactComponent as ExpandIcon } from 'assets/expand.svg';

const CollapseCategoryButton = ({ lang, handleOnCollapse, collapseItems }) => (
  <div
    className={cx(
      'collapse-btn flex justify-end cursor-pointer items-center',
      lang == 'ar' ? 'flex-row-reverse mr-auto ml-0' : 'ml-auto mr-0',
    )}
    onClick={handleOnCollapse}
  >
    {collapseItems ? (
      <div className="material-icons flex items-end justify-end text-primary-base font-medium">
        <ExpandIcon />
      </div>
    ) : (
      <div className="material-icons flex items-end justify-end text-primary-base font-medium">
        <CollapseIcon />
      </div>
    )}
    <span className="text-gray-700 text-base font-medium pl-2">
      <Text value={!collapseItems ? translations.COLLAPSE_ALL : translations.EXPAND_ALL} />
    </span>
  </div>
);

CollapseCategoryButton.propTypes = {
  lang: PropTypes.string.isRequired,
  handleOnCollapse: PropTypes.func.isRequired,
  collapseItems: PropTypes.bool.isRequired,
};

export default CollapseCategoryButton;
