import React from 'react';
import * as yup from 'yup';

import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { CANCEL_REASON } from 'constants/cancel';

export const validationSchema = yup.object().shape({
  cancellationReason: yup.number().required(<Text value={translations.CANCELLATION_REASON_REQ} />),
  otherReason: yup.string().when('cancellationReason', {
    is: cancellationReason => cancellationReason === CANCEL_REASON.OTHER,
    then: yup.string().required(<Text value={translations.CANCELLATION_REASON_REQ} />),
    otherwise: yup.string(),
  }),
});

export const initialValues = {
  cancellationReason: '',
  otherReason: '',
};

export const REFUND_TYPE_ENUM = {
  ORDERFAST_WALLET: 'wallet',
  CREDIT_CARD: 'credit_card',
  WALLET_AND_CREDIT_CARD: 'wallet_and_credit_card',
};

export const REFUND_STATUS_ENUM = {
  PENDING: 'pending',
  SUCCEEDED: 'succeeded',
  FAILED: 'failed',
  EXPIRED: 'expired',
};
