import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { ReactComponent as HomeIcon } from 'assets/home-icon.svg';
import { ReactComponent as ApartementIcon } from 'assets/apartement.svg';
import { ReactComponent as BriefcaseIcon } from 'assets/briefcase.svg';

import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { CUSTOMER_ADDRESS_ENUMS, CUSTOMER_ADDRESS_FIELDS } from './data';

const PlaceSelection = ({ selectedPlace, setFieldValue }) => {
  const commonClassName = 'py-2 border hover:border-black rounded items-center justify-center cursor-pointer';

  return (
    <div className="flex justify-between">
      <div
        role="presentation"
        className={cx(
          commonClassName,
          'px-6',
          selectedPlace === CUSTOMER_ADDRESS_ENUMS.HOUSE ? ' border-black' : 'border-gray-300',
        )}
        onClick={() => setFieldValue(CUSTOMER_ADDRESS_FIELDS.SELECTED_PLACE, CUSTOMER_ADDRESS_ENUMS.HOUSE)}
      >
        <HomeIcon
          className={cx('h-10 w-10', selectedPlace === CUSTOMER_ADDRESS_ENUMS.HOUSE ? 'text-black' : 'text-gray-500')}
        />
        <Text
          className={cx(
            'py-2 text-sm',
            selectedPlace === CUSTOMER_ADDRESS_ENUMS.HOUSE ? 'text-black' : 'text-gray-500',
          )}
          value={translations.HOUSE}
        />
      </div>

      <div
        role="presentation"
        className={cx(
          commonClassName,
          'px-6',
          selectedPlace === CUSTOMER_ADDRESS_ENUMS.APARTMENT ? 'border-black' : 'border-gray-300',
        )}
        onClick={() => setFieldValue(CUSTOMER_ADDRESS_FIELDS.SELECTED_PLACE, CUSTOMER_ADDRESS_ENUMS.APARTMENT)}
      >
        <ApartementIcon
          className={cx(
            'h-10 w-16',
            selectedPlace === CUSTOMER_ADDRESS_ENUMS.APARTMENT ? 'hover:text-black' : 'text-gray-500',
          )}
        />
        <Text
          className={cx(
            'py-2 text-sm',
            selectedPlace === CUSTOMER_ADDRESS_ENUMS.APARTMENT ? ' text-black' : 'text-gray-500',
          )}
          value={translations.APARTMENT}
        />
      </div>
      <div
        role="presentation"
        className={cx(
          commonClassName,
          ' px-6',
          selectedPlace === CUSTOMER_ADDRESS_ENUMS.OFFICE ? 'border-black' : 'border-gray-300',
        )}
        onClick={() => setFieldValue(CUSTOMER_ADDRESS_FIELDS.SELECTED_PLACE, CUSTOMER_ADDRESS_ENUMS.OFFICE)}
      >
        <BriefcaseIcon
          className={cx('h-10 w-10', selectedPlace === CUSTOMER_ADDRESS_ENUMS.OFFICE ? 'text-black' : 'text-gray-500')}
        />
        <Text
          className={cx(
            'py-2 text-sm',
            selectedPlace === CUSTOMER_ADDRESS_ENUMS.OFFICE ? 'text-black' : 'text-gray-500',
          )}
          value={translations.OFFICE}
        />
      </div>
    </div>
  );
};

PlaceSelection.propTypes = {
  selectedPlace: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default PlaceSelection;
