import { gql } from "@apollo/client"

export const STOCK_SETTINGS = gql`
  query($storeId: String!) {
    settings(restaurantId: $storeId) {
      lowStockThreshold
      lowStockMessage
      isStockEnabled
    }
  }
`

export const UPDATE_STOCK_SETTINGS = gql`
  mutation(
    $storeId: String!
    $lowStockThreshold: Int!
    $lowStockMessage: String!
  ) {
    updateSettings(
      restaurantId: $storeId
      lowStockThreshold: $lowStockThreshold
      lowStockMessage: $lowStockMessage
    ) {
      lowStockThreshold
      lowStockMessage
    }
  }
`
