import { useContext } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import { context as userContext } from 'context/user';
import { CASHBACK_PROGRAMS_LIST, DEACTIVATE_CASHBACK_PROGRAM } from '../schemas';
import { BACKEND_GRAPHQL_SERVER } from '../../../../constants';

const useDeactivateProgram = () => {
  const { selectedStore } = useContext(userContext);
  const { subdomain } = selectedStore || {};

  const [deactivateProgram] = useMutation(DEACTIVATE_CASHBACK_PROGRAM, {
    context: { serverName: BACKEND_GRAPHQL_SERVER },
  });
  const { updateQuery } = useQuery(CASHBACK_PROGRAMS_LIST, {
    context: { serverName: BACKEND_GRAPHQL_SERVER },
    variables: { subdomain },
  });

  const handleDeactivateProgram = async id => {
    const { data } = await deactivateProgram({
      variables: {
        subdomain,
        id,
      },
    });

    if (data?.deactivateCashbackProgram?.success)
      updateQuery?.(prevData => ({
        ...prevData,
        cashbackPrograms: prevData.cashbackPrograms.map(program =>
          program.id === id ? { ...program, status: 'ended', deactivatedAt: new Date() } : program,
        ),
      }));
  };

  return [handleDeactivateProgram];
};

export default useDeactivateProgram;
