module.exports = {
  fromMinutesToDays: time => {
    return parseInt(Number(time) / (24 * 60))
  },

  fromMinutesToHours: time => {
    return parseInt(Number(time) / 60)
  },

  fromHoursToMinutes: time => {
    return parseInt(Number(time) * 60)
  },

  fromHoursToDays: time => {
    return parseInt(Number(time) / 24)
  },

  fromDaysToMinutes: time => {
    return parseInt(Number(time) * 24 * 60)
  },

  fromDaysToHours: time => {
    return parseInt(Number(time) * 24)
  },
}
