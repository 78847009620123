import React, { useEffect, useState, useContext, useCallback } from 'react';

import { context as userContext } from 'context/user';
import { context as localeContext } from 'context/locale';
import * as translations from 'constants/translations';
import { Text } from 'components/service';
import {
  useFetchDeliveryZones,
  useFetchSuggestedDeliveryZones,
  useFetchDeliveryZoneTemplates,
  useFetchBranches,
} from 'service/hooks';
import Map from './GoogleMaps/Map';
import Overlay from './Overlay';
import {
  zoneControllerUtils,
  colorsUtils,
  suggestedZonesTypeUtils,
  allBranchesId,
  openingHoursAttr,
  allCitiesUtils,
  zonesPageSize,
  viewPortUtils,
} from './utils';
import Edition from './Edition';
import Creation from './Creation';
import SuggestedZones from './Overlay/SuggestedZones';
import TemplatesSearch from './Overlay/TemplatesSearch';
import ZonesBulkSelect from './Overlay/ZonesBulkSelect';
import BulkCreation from './BulkCreation';

const Zones = () => {
  const { selectedStore, dzmsSelectedBranch, setDzmsSelectedBranch } = useContext(userContext);
  const { direction, translate } = useContext(localeContext);

  const { id: selectedStoreId } = selectedStore;
  const { data, isLoading } = useFetchDeliveryZones({ restaurantReferenceId: selectedStoreId });
  const [bulkZones, setBulkZones] = useState([]);
  const [geoPoint, setGeoPoint] = useState(null);
  const [dzSuggestedTemplatesParams, setDzSuggestedTemplatesParams] = useState({
    pageSize: zonesPageSize,
    totalPages: null,
    currentPage: 1,
  });
  const {
    data: suggestedDataFetch,
    isLoading: suggestedDataLoading,
    error: suggestedDataError,
  } = useFetchSuggestedDeliveryZones({
    geoPoint,
    pageNumber: dzSuggestedTemplatesParams.currentPage,
    pageSize: dzSuggestedTemplatesParams.pageSize,
  });

  // - zones [Static]
  const [zones, setZones] = useState([]);

  // - branches [Static]
  const [branches, setBranches] = useState([]);
  const { data: dzmsBranches, isLoading: dzmsBranchesLoading } = useFetchBranches({
    restaurantReferenceId: parseFloat(selectedStoreId),
  });

  // - zone controller [Static]
  const [zoneController, setZoneController] = useState(zoneControllerUtils);

  // - zones feature collections
  const [zonesCollection, setZonesCollection] = useState({ type: 'FeatureCollection', features: [] });
  const [zonesNearestCollection, setZonesNearestCollection] = useState({ type: 'FeatureCollection', features: [] });

  // - zone collection all areas
  const [dzTemplatesParams, setDzTemplatesParams] = useState({
    pageSize: zonesPageSize,
    totalPages: null,
    currentPage: 1,
    searchValue: '',
  });
  const {
    data: zoneCollectionFetch,
    isLoading: zoneCollectionLoading,
    error: zoneCollectionError,
  } = useFetchDeliveryZoneTemplates({
    countryCode: zoneController.countryCode.toUpperCase(),
    cityCode: zoneController.cityCode ? zoneController.cityCode.toUpperCase() : null,
    pageNumber: dzTemplatesParams.currentPage,
    pageSize: dzTemplatesParams.pageSize,
    searchParam: dzTemplatesParams.searchValue,
  });

  useEffect(() => {
    if (!isLoading) {
      if (data.featureCollection.features !== null && branches.length >= 1) {
        setZones(
          data.featureCollection.features.map(zoneItem => ({
            ...zoneItem,
            properties: {
              ...zoneItem.properties,
              fillColor: branches.find(branchItem => branchItem.id === zoneItem.properties.branch_reference_id)?.color,
              fillOpacity: 0.3,
              strokeOpacity: 1,
              branchName: branches.find(branchItem => branchItem.id === zoneItem.properties.branch_reference_id).title,
              visibility: true,
            },
          })),
        );
      }
    }
  }, [data, branches, isLoading]);

  useEffect(() => {
    if (!suggestedDataLoading) {
      if (suggestedDataFetch) {
        setZonesNearestCollection(prevState => ({
          ...prevState,
          features:
            dzSuggestedTemplatesParams.currentPage >= 2
              ? prevState.features.concat(suggestedDataFetch.featureCollection.features)
              : suggestedDataFetch.featureCollection.features,
        }));
        setDzSuggestedTemplatesParams({
          ...dzSuggestedTemplatesParams,
          currentPage: suggestedDataFetch.meta.current_page,
          totalPages: suggestedDataFetch.meta.total_pages,
        });
      }
    }
  }, [suggestedDataFetch, suggestedDataLoading, dzSuggestedTemplatesParams.currentPage]);

  const sortFeatures = useCallback(
    features => features.sort((a, b) => (a?.properties?.area_title_en > b?.properties?.area_title_en ? 1 : -1)),
    [zoneCollectionFetch],
  );

  useEffect(() => {
    if (!zoneCollectionLoading) {
      if (zoneCollectionFetch) {
        setZonesCollection(prevState => ({
          ...prevState,
          features:
            dzTemplatesParams.currentPage >= 2
              ? sortFeatures(prevState.features.concat(zoneCollectionFetch.featureCollection.features))
              : sortFeatures(zoneCollectionFetch.featureCollection.features),
        }));
        setDzTemplatesParams({
          ...dzTemplatesParams,
          currentPage: zoneCollectionFetch.meta.current_page,
          totalPages: zoneCollectionFetch.meta.total_pages,
        });
      }
    }
  }, [zoneCollectionFetch, zoneCollectionLoading, dzTemplatesParams.currentPage]);

  // - change opacity of zones
  useEffect(() => {
    if (zoneController.selectedBranch) {
      if (zoneController.selectedBranch.id === allBranchesId) {
        setZones(
          zones.map(zoneItem => ({
            ...zoneItem,
            properties: { ...zoneItem.properties, fillOpacity: 0.3, strokeOpacity: 1, visibility: true },
          })),
        );
        setZoneController(prevState => ({ ...prevState, selectedBranch: zoneController.selectedBranch }));
        setDzmsSelectedBranch(null);
      } else {
        const reColorZones = zones.map(zoneItem =>
          zoneItem.properties.branch_reference_id === zoneController.selectedBranch.id
            ? {
                ...zoneItem,
                properties: { ...zoneItem.properties, fillOpacity: 0.5, strokeOpacity: 1, visibility: true },
              }
            : {
                ...zoneItem,
                properties: {
                  ...zoneItem.properties,
                  fillOpacity: 0.2,
                  strokeOpacity: 0.2,
                  visibility: !zoneController.hideZones,
                },
              },
        );
        setZones(reColorZones);
        setGeoPoint([zoneController.selectedBranch.lng, zoneController.selectedBranch.lat]);
        setDzmsSelectedBranch(zoneController.selectedBranch);
      }

      setDzTemplatesParams({ pageSize: zonesPageSize, currentPage: 1, totalPages: null, searchValue: '' });
      setDzSuggestedTemplatesParams({ pageSize: zonesPageSize, currentPage: 1, totalPages: null });
    }
  }, [zoneController.selectedBranch, zoneController.hideZones]);

  // - get dzms selected branch
  useEffect(() => {
    if (dzmsSelectedBranch && branches.length >= 1 && !isLoading && data.featureCollection.features !== null) {
      setZoneController(prevState => ({
        ...prevState,
        selectedBranch: branches.find(branchItem => branchItem.id === dzmsSelectedBranch.id),
      }));
    }
  }, [branches, dzmsSelectedBranch, data, isLoading]);

  // - set branches and zones colors
  // - [TODO] will enhance after integration
  useEffect(() => {
    if (!dzmsBranchesLoading) {
      if (dzmsBranches.length >= 1) {
        const convertedBranches = dzmsBranches.map((branch, i) => ({
          id: branch.referenceId,
          title: <Text value={[branch.titleEn, branch.titleAr]} />,
          lat: branch.lonlat.coordinates[1],
          lng: branch.lonlat.coordinates[0],
          titleEn: branch.titleEn,
          titleAr: branch.titleAr,
          viewPort: branch[
            branch.restaurantViewPort ? viewPortUtils.RESTAURANT_VIEWPORT : viewPortUtils.BRANCH_VIEWPORT
          ].coordinates[0].map(coord => ({ lat: coord[1], lng: coord[0] })),
          color: colorsUtils[i % colorsUtils.length],
          branchViewPort: branch.branchViewPort.coordinates[0].map(coord => ({ lat: coord[1], lng: coord[0] })),
          openingHours: branch.openingHours.map(time => ({
            day: time.day,
            openAt: time[openingHoursAttr.OPENAT],
            closeAt: time[openingHoursAttr.CLOSEAT],
            offDay: time[openingHoursAttr.OFFDAY],
          })),
          deliveryOrdersEnabled: branch.deliveryOrdersEnabled,
        }));
        setBranches([
          {
            id: allBranchesId,
            title: <Text value={translations.ALL_BRANCHES_TEXT} />,
            lat: 0,
            lng: 0,
            titleEn: translations.ALL_BRANCHES_TEXT[0],
            titleAr: translations.ALL_BRANCHES_TEXT[1],
            viewPort: convertedBranches[0].viewPort,
            branchViewPort: convertedBranches[0].viewPort,
            deliveryOrdersEnabled: true,
          },
          ...convertedBranches,
        ]);
      }
    }
  }, [dzmsBranchesLoading, dzmsBranches]);

  // - select branch once a zone clicked
  useEffect(() => {
    if (zoneController.selectedZone) {
      setZoneController(prevState => ({
        ...prevState,
        selectedBranch: branches.find(
          branchItem => branchItem.id === zoneController.selectedZone.properties.branch_reference_id,
        ),
      }));
    }
  }, [zoneController.selectedZone]);

  // - check if nearest zones avaiable
  // - get country and city codes
  useEffect(() => {
    if (
      suggestedDataFetch &&
      !!suggestedDataFetch.featureCollection.features.length &&
      !zoneController.showZoneTemplates
    ) {
      setZoneController(prevState => ({
        ...prevState,
        countryId: suggestedDataFetch.featureCollection.features[0].properties.country_id.toString(),
        countryCode: suggestedDataFetch.featureCollection.features[0].properties.country_code,
        cityId: allCitiesUtils.id,
        cityCode: allCitiesUtils.code,
        cityTitle: translate(translations.ALL_CITIES),
        isNearestZonesAvailable: true,
      }));
    } else {
      setZoneController(prevState => ({
        ...prevState,
        isNearestZonesAvailable: false,
      }));
    }
  }, [suggestedDataFetch, zoneController.showZoneTemplates]);

  // - update bulk status
  useEffect(() => {
    setZoneController(prevState => ({ ...prevState, isBulk: bulkZones.length >= 1 }));
  }, [bulkZones]);

  return (
    <>
      <div style={{ direction }}>
        <div className="p-4 w-343 relative z-50">
          <Overlay
            branches={branches}
            zoneController={zoneController}
            setZoneController={setZoneController}
            setZonesCollection={setZonesCollection}
            setDzTemplatesParams={setDzTemplatesParams}
            setBulkZones={setBulkZones}
            setDzSuggestedTemplatesParams={setDzSuggestedTemplatesParams}
          />
          {zoneController.isEdit && (
            <Edition
              zoneController={zoneController}
              setZoneController={setZoneController}
              setZones={setZones}
              branches={branches}
              setDzTemplatesParams={setDzTemplatesParams}
              setDzSuggestedTemplatesParams={setDzSuggestedTemplatesParams}
            />
          )}

          {zoneController.isAdd && (
            <Creation zoneController={zoneController} setZoneController={setZoneController} setZones={setZones} />
          )}

          {zoneController.isBulkSelected && (
            <BulkCreation
              zoneController={zoneController}
              setZoneController={setZoneController}
              setZones={setZones}
              bulkZones={bulkZones}
              setBulkZones={setBulkZones}
              setDzTemplatesParams={setDzTemplatesParams}
              setDzSuggestedTemplatesParams={setDzSuggestedTemplatesParams}
            />
          )}

          {zoneController.showZoneTemplates && (
            <div className="bg-white p-4 mb-4 shadow">
              <TemplatesSearch
                zoneController={zoneController}
                dzTemplatesParams={dzTemplatesParams}
                setDzTemplatesParams={setDzTemplatesParams}
                setZonesCollection={setZonesCollection}
              />

              {zoneController.isBulk && (
                <ZonesBulkSelect
                  bulkZones={bulkZones}
                  setBulkZones={setBulkZones}
                  setZoneController={setZoneController}
                  setDzTemplatesParams={setDzTemplatesParams}
                  setDzSuggestedTemplatesParams={setDzSuggestedTemplatesParams}
                />
              )}
              {zonesNearestCollection && (
                <SuggestedZones
                  title={<Text className="text-xs font-semibold mx-2" value={translations.SUGGESTED_ZONES} />}
                  type={suggestedZonesTypeUtils.SUGGESTED}
                  suggestedData={zonesNearestCollection}
                  setSuggestedData={setZonesNearestCollection}
                  zoneController={zoneController}
                  setZoneController={setZoneController}
                  templatesParams={dzSuggestedTemplatesParams}
                  setTemplatesParams={setDzSuggestedTemplatesParams}
                  bulkZones={bulkZones}
                  setBulkZones={setBulkZones}
                  fetchError={suggestedDataError}
                  fetchLoading={suggestedDataLoading}
                />
              )}

              {zonesCollection && (
                <SuggestedZones
                  title={<Text className="text-xs font-semibold mx-2" value={translations.ALL_ZONES} />}
                  type={suggestedZonesTypeUtils.AREAS}
                  suggestedData={zonesCollection}
                  setSuggestedData={setZonesCollection}
                  zoneController={zoneController}
                  setZoneController={setZoneController}
                  templatesParams={dzTemplatesParams}
                  setTemplatesParams={setDzTemplatesParams}
                  bulkZones={bulkZones}
                  setBulkZones={setBulkZones}
                  fetchError={zoneCollectionError}
                  fetchLoading={zoneCollectionLoading}
                />
              )}
            </div>
          )}
        </div>

        <div className="w-full h-screen fixed top-0 left-0 right-0 z-10">
          <Map
            branches={branches}
            zoneController={zoneController}
            setZoneController={setZoneController}
            zones={zones}
            zonesCollection={zonesCollection}
            zonesNearestCollection={zonesNearestCollection}
            bulkZones={bulkZones}
          />
        </div>
      </div>
    </>
  );
};

export default Zones;
