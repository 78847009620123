import { gql } from '@apollo/client';

export const TOP_SELLING_PRODUCTS = gql`
  query($storeId: String!, $range: AnalyticsRange!, $page: Int!, $limit: Int!) {
    topSellingProducts(restaurantId: $storeId, range: $range, page: $page, limit: $limit) {
      startDate
      endDate
      data {
        menuSectionId
        titleEn
        titleAr
        photoUrl
        totalSales
        totalOrders
        quantitySold
      }
      totalCount
      totalPages
    }
  }
`;
export const TOP_SELLING_CATEGORIES = gql`
  query($storeId: String!, $range: AnalyticsRange!, $page: Int!, $limit: Int!) {
    topSellingCategories(restaurantId: $storeId, range: $range, page: $page, limit: $limit) {
      startDate
      endDate
      data {
        menuSectionId
        titleEn
        titleAr
        photoUrl
        totalSales
        totalOrders
        quantitySold
      }
      totalCount
      totalPages
    }
  }
`;
