import React from "react"
import * as yup from "yup"
import * as translations from "constants/translations"
import { Text } from "components/service"

export const initialValues = {
  titleEn: "",
  titleAr: "",
  descriptionEn: "",
  descriptionAr: "",
  price: 0,
  productLang: "english",
  storeEn: "",
  storeAr: "",
  isPublish: false,
  isCustomize: false,
}

export const validationSchema = yup.object().shape({
  titleEn: yup
    .string()
    .required(<Text value={translations.PRODUCT_TITLE_EN_REQ} />),
  titleAr: yup
    .string()
    .required(<Text value={translations.PRODUCT_TITLE_AR_REQ} />),
  price: yup.number().required(<Text value={translations.PRODUCT_PRICE_REQ} />),
  categories: yup
    .array()
    .of(yup.string().required())
    .required(<Text value={translations.PRODUCT_CATEGORIES_REQ} />),
})
