import { gql } from "@apollo/client"

export const BANKS = gql`
  query($gatewayId: Int!,$countryId: Int!) {
    banksList(gatewayId: $gatewayId,countryId: $countryId) {
        Value
        Text
    }
  }
`

export const DEPOSIT_TERMS = gql`
  query($gatewayId: Int!,$countryId: Int!) {
    depositTerms(gatewayId: $gatewayId,countryId: $countryId) {
        id
        name
    }
  }
`
