import React, { useContext, useRef, useState, useEffect } from 'react';
import * as R from 'ramda';
import axios from 'axios';
import { useQuery, useMutation } from '@apollo/client';

import { uploadProductImage, deleteProductImage } from 'rest.js';
import { Text } from 'components/service';
import { Spinner, Modal, TabPanels, Button } from 'components/kit';
import * as translations from 'constants/translations';
import { context as notificationsContext } from 'context/notifications';
import { context as localeContext } from 'context/locale';
import { Footer, Row } from 'components/form/generic';
import { Formik, Form } from 'formik';
import { OptionGroups, Variants, Notes, PreparationTime, Fulfillment, ProductImages } from 'components/common/product';
import BasicDetails from '../BasicDetails/BasicDetails';
import * as schemas from './schemas';
import * as data from './data';
import * as utils from './utils';
import cx from 'classnames';
import { context as userContext } from 'context/user';
import ProductFormFrame from './ProductFormFrame';
import { useMobile } from 'hooks';
import DeleteProduct from './DeleteProductModal';
import { TRACKING_TYPE_ENUM } from 'components/common/product/ItemTracking/utils';

const ProductForm = ({
  id,
  storeId,
  reset: resetState,
  isMenu = false,
  productCategory,
  refetchMenuPageData,
  setSidePanelComponent,
  sidePanelComponent,
  topPanel,
}) => {
  const isMobile = useMobile();
  const CREATE = 'create';
  const [productId, setProductId] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const submitting = useRef();
  const { lang, direction } = useContext(localeContext);
  const [initial, setInitial] = useState(null);
  const [postCreation, setPostCreation] = useState(null);
  const notifications = useContext(notificationsContext);
  const { selectedStore: selected } = useContext(userContext);
  const imageTokenSource = axios.CancelToken.source();
  const handleErrorMessage = utils.handleErrors(msg => notifications.show(msg, 'error'));

  const initialData = useQuery(schemas.INITIAL, {
    variables: {
      restaurantId: storeId,
      id: productId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    setProductId(id);
    setPostCreation(false);
  }, [id, categoryId]);

  useEffect(() => {
    setInitial(initialData);
    setCategoryId(productCategory);
  }, [initialData, productId, productCategory]);

  const variantsInitial = useQuery(schemas.VARIANTS, {
    variables: {
      restaurantId: storeId,
      id: productId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  const [createAdvancedProduct, { loading: isCreatingProduct }] = useMutation(schemas.CREATE_PRODUCT, {
    onError: handleErrorMessage,
  });
  const [updateProduct, { loading: isUpdatingProduct }] = useMutation(schemas.UPDATE_PRODUCT, {
    onError: handleErrorMessage,
  });

  const productInitialVlaues =
    initial && data.handleProductInitialValue(productId, initial, categoryId, selected?.defaultProductPrepTime);

  const variantInitialValues =
    variantsInitial && data.handleVariantInitialValue(productId, variantsInitial, selected?.defaultProductPrepTime);

  const onSubmit = async (values, { setErrors, setSubmitting }) => {
    if (submitting.current) return;

    submitting.current = true;
    const publishedInStringArray = values?.publishIn ? values.publishIn.map(String) : [];
    if (values.allowAttachNotes) {
      let obj = {};
      if (!values.specialNotesPlaceholderAr) {
        obj.specialNotesPlaceholderAr = <Text value={translations.NOTE_AR_REQ} />;
      }
      if (!values.specialNotesPlaceholderEn) {
        obj.specialNotesPlaceholderEn = <Text value={translations.NOTE_EN_REQ} />;
      }
      if (!R.isEmpty(obj)) {
        setErrors(obj);
        submitting.current = false;
        return setSubmitting(false);
      }
    }

    if (productId) {
      await updateProduct({
        variables: {
          restaurantId: storeId,
          menuItemId: productId,
          menuSectionIds: values.categories,
          publishedBranchIds: publishedInStringArray,
          titleEn: values.titleEn,
          titleAr: values.titleAr,
          descriptionEn: values.descriptionEn,
          descriptionAr: values.descriptionAr,
          externalId: values.externalId,
          specialNotesEnabled: values.allowAttachNotes,
          specialNotesRequired: values.allowAttachNotes === true ? values.specialNotesRequired : false,
          specialNotesPlaceholderAr: values.specialNotesPlaceholderAr,
          specialNotesPlaceholderEn: values.specialNotesPlaceholderEn,
          fulfillmentMode: values.fulfillmentMode,
          trackingType: values.trackingType,
        },
      }).then(res => {
        if (res) {
          notifications.show(
            <Text
              value={translations.PRODUCT_UPDATED}
              payload={lang === 'en' ? res?.data?.updateMenuItem?.titleEn : res?.data?.updateMenuItem?.titleAr}
            />,
          );
          refetchMenuPageData && refetchMenuPageData();
        }
      });
    } else {
      await createAdvancedProduct({
        variables: {
          restaurantId: storeId,
          menuSectionIds: values.categories,
          publishedBranchIds: publishedInStringArray,
          titleEn: values.titleEn,
          titleAr: values.titleAr,
          externalId: values.externalId,
          descriptionEn: values.descriptionEn,
          descriptionAr: values.descriptionAr,
          price: values.price + '',
          trackingType: values.trackingType,
        },
      }).then(res => {
        if (res) {
          notifications.show(
            <Text
              value={translations.PRODUCT_CREATED}
              payload={lang === 'en' ? res?.data?.createMenuItem?.titleEn : res?.data?.createMenuItem?.titleAr}
            />,
          );
          const menuItemId = res?.data?.createMenuItem?.id;
          setSidePanelComponent({ ...sidePanelComponent, id: menuItemId });
          setProductId(menuItemId);
          !productId && setPostCreation(true);
          refetchMenuPageData && refetchMenuPageData();
        }
      });
    }

    if (values.photoUrl instanceof File && productId) {
      await uploadProductImage(
        storeId,
        productId,
        {
          productImage: values.photoUrl,
        },
        imageTokenSource.token,
      ).then(() => {
        refetchMenuPageData && refetchMenuPageData();
      });
    }
    setSubmitting(false);
    submitting.current = false;
  };

  const ref = useRef(null);
  return (
    <Modal isMenu>
      {({ open, close }) => (
        <div className="w-full" ref={ref}>
          <div
            className={cx(isMenu && topPanel && 'fixed')}
            style={{ width: ref.current ? ref.current.offsetWidth : 0 }}
          >
            {!productInitialVlaues || initial?.loading ? (
              <div className="mt-3">
                <Spinner />
              </div>
            ) : (
              <div className={cx('pb-6 flex', lang === 'ar' && 'flex-row-reverse')}>
                <div className="w-full" style={{ direction }}>
                  {productInitialVlaues && (
                    <Formik
                      key="product-form"
                      initialValues={productInitialVlaues}
                      validationSchema={data.validationSchema}
                      onSubmit={onSubmit}
                    >
                      {({ values, setFieldValue, setErrors, setSubmitting, ...rest }) => (
                        <Form className="mt-2 pb-32 md:pb-6" key="product-form">
                          <ProductFormFrame
                            titleEn={values.titleEn}
                            titleAr={values.titleAr}
                            open={open}
                            close={close}
                            resetState={resetState}
                            refetchMenuPageData={refetchMenuPageData}
                            isUpdatingProduct={isUpdatingProduct}
                            isCreatingProduct={isCreatingProduct}
                            storeId={storeId}
                            productId={productId}
                          />
                          <TabPanels
                            preCreation={!productId}
                            createMenuItem={() => !productId && rest.submitForm()}
                            postCreation={postCreation}
                            items={[
                              {
                                title: <Text value={translations.GENERAL} />,
                                body: (
                                  <div className="mt-2 mb-4">
                                    <BasicDetails
                                      pageType={CREATE}
                                      isError={rest.errors}
                                      updateProduct={updateProduct}
                                      titleEn={values.titleEn}
                                      titleAr={values.titleAr}
                                      variants={variantInitialValues}
                                      isSubmitting={rest.isSubmitting}
                                      isDescriptionVisible={!!(values.descriptionEn || values.descriptionAr)}
                                      trackingType={values.trackingType ? values.trackingType : TRACKING_TYPE_ENUM.NONE}
                                      open={open}
                                      productId={productId}
                                      createMenuItem={() => !productId && rest.submitForm()}
                                      close={close}
                                      setFieldValue={setFieldValue}
                                      productImage={values.photoUrl}
                                      deleteProductImage={() =>
                                        deleteProductImage(storeId, productId, imageTokenSource.token).then(
                                          () => refetchMenuPageData && refetchMenuPageData(),
                                        )
                                      }
                                    />
                                  </div>
                                ),
                              },
                              {
                                title: <Text value={translations.MODIFIERS} />,
                                body: productId && (
                                  <div className="w-full" style={{ direction }}>
                                    <div className="mt-4 mb-4 px-4">
                                      <Variants
                                        isMenu
                                        pageType={CREATE}
                                        storeId={storeId}
                                        productId={productId}
                                        variants={variantInitialValues}
                                        variantsLoading={productId && variantsInitial?.loading}
                                        variantsRefetch={productId && variantsInitial?.refetch}
                                        variantsTitleEn={productInitialVlaues.variantsTitleEn}
                                        variantsTitleAr={productInitialVlaues.variantsTitleAr}
                                        productLang={values.productLang}
                                        query={schemas.INITIAL}
                                        isError={rest.errors}
                                        titleEn={values.titleEn}
                                        titleAr={values.titleAr}
                                        setFieldValue={setFieldValue}
                                        updateProduct={updateProduct}
                                        setErrorField={rest.setFieldError}
                                      />
                                    </div>
                                    <div className="mt-4 mb-4 px-4">
                                      <ProductImages
                                        pageType={CREATE}
                                        storeId={storeId}
                                        productId={productId}
                                        variants={variantInitialValues}
                                        query={schemas.INITIAL}
                                        open={open}
                                        closeModal={close}
                                      />
                                    </div>
                                    <div className="mt-4 mb-4 px-4">
                                      <OptionGroups
                                        isMenu
                                        storeId={storeId}
                                        variants={variantInitialValues}
                                        productId={productId}
                                        refetchProduct={initial.refetch}
                                        open={open}
                                        query={schemas.INITIAL}
                                        closeModal={close}
                                      />
                                    </div>
                                    <div className="mt-4 mb-4 px-4">
                                      <Fulfillment
                                        isMenu
                                        fulfillmentMode={values.fulfillmentMode}
                                        setValueField={setFieldValue}
                                      />
                                    </div>
                                    <div className="mt-4 mb-4 px-4">
                                      <PreparationTime
                                        isMenu
                                        pageType={CREATE}
                                        variants={variantInitialValues}
                                        productId={productId}
                                        storeId={storeId}
                                        enablePrepTime={values.enablePrepTime}
                                        setValueField={setFieldValue}
                                      />
                                    </div>
                                    <div className="mt-4 mb-4 px-4">
                                      <Notes
                                        pageType={CREATE}
                                        isError={rest.errors}
                                        isRequired={values.specialNotesRequired}
                                        isAllowedToAttach={values.allowAttachNotes}
                                        setValueField={setFieldValue}
                                        lang={lang}
                                      />
                                    </div>
                                  </div>
                                ),
                              },
                            ]}
                          />
                          {isMobile && (
                            <Row className="px-4">
                              <Footer isMenu>
                                <div className="w-full flex items-center ">
                                  <div className="flex flex-col w-full">
                                    <Button kind="primary" isSpinning={isCreatingProduct || isUpdatingProduct}>
                                      <Text value={translations.SAVE} />
                                    </Button>
                                    <Button
                                      kind="borderlessButton"
                                      textColor="text-black"
                                      size="full"
                                      onClick={e => {
                                        e.preventDefault();
                                        open({
                                          testId: 'delete-product-modal',
                                          size: 'max-w-md',
                                          title: <Text value={translations.DELETE_PRODUCT} className="text-xl" />,
                                          body: (
                                            <DeleteProduct
                                              id={productId}
                                              storeId={storeId}
                                              close={close}
                                              title={lang === 'en' ? values.titleEn : values.titleAr}
                                              refetchMenuPageData={refetchMenuPageData}
                                            />
                                          ),
                                        });
                                      }}
                                    >
                                      <Text value={translations.DELETE} className="whitespace-nowrap" />
                                    </Button>
                                  </div>
                                </div>
                              </Footer>
                            </Row>
                          )}
                        </Form>
                      )}
                    </Formik>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </Modal>
  );
};
export default ProductForm;
