import React from "react"

const SettingsBox = ({ direction, title, description, children }) => {
  return (
    <div style={{ direction }} className="border border-gray-300 p-4">
      <p className="text-lg font-medium px-4 py-1 -mx-4">{title}</p>
      <p className="text-gray-700 py-1">{description}</p>
      <div className="py-2">{children}</div>
    </div>
  )
}

export default SettingsBox
