import { LINK_TYPES } from 'common/components/LinkForm/constants';

export const prependLink = linkData => {
  switch (linkData.linkType) {
    case LINK_TYPES.INSTAGRAM:
    case LINK_TYPES.TWITTER:
      return `https://${linkData.linkType}.com/${linkData.url}`;
    case LINK_TYPES.FACEBOOK:
      return `https://fb.com/${linkData.url}`;
    case LINK_TYPES.SNAPCHAT:
      return `https://snapchat.com/add/${linkData.url}`;
    case LINK_TYPES.WHATSAPP:
      return `https://api.whatsapp.com/send?phone=${linkData.phoneNumber.replace('+', '')}`;
    case LINK_TYPES.SMS:
      return `sms:${linkData.phoneNumber}${linkData.smsBody ? `;?&body=${linkData.smsBody}` : ''}`;
    case LINK_TYPES.PHONE:
      return `tel:${linkData.phoneNumber}`;
    case LINK_TYPES.EMAIL:
      return `mailTo:${linkData.url}`;
    case LINK_TYPES.URL:
      return `https://${linkData.url}`;

    default:
      return linkData.url;
  }
};

export const removePrependedText = (linkType, value) => {
  switch (linkType) {
    case LINK_TYPES.INSTAGRAM:
    case LINK_TYPES.TWITTER: {
      const urlParts = value.split(`https://${linkType}.com/`);
      return urlParts[urlParts.length - 1];
    }
    case LINK_TYPES.FACEBOOK: {
      const urlParts = value.split('https://fb.com/');
      return urlParts[urlParts.length - 1];
    }
    case LINK_TYPES.SNAPCHAT: {
      const urlParts = value.split('https://snapchat.com/add/');
      return urlParts[urlParts.length - 1];
    }
    case LINK_TYPES.WHATSAPP: {
      const urlParts = value.split('https://api.whatsapp.com/send?phone=');
      return urlParts[urlParts.length - 1];
    }
    case LINK_TYPES.URL: {
      return value.slice(8);
    }
    case LINK_TYPES.SMS:
    case LINK_TYPES.PHONE:
    case LINK_TYPES.EMAIL: {
      const urlParts = value.split(':');
      return urlParts[urlParts.length - 1];
    }
    default:
      return value;
  }
};

export const formatWebLinksArr = webLinksArr => webLinksArr.map(({ __typename, ...attr }) => attr);

export const formatLinkToForm = (link, contentAttr) => {
  const content = removePrependedText(link.linkType, link[contentAttr]);
  const addPhoneNumberField = [LINK_TYPES.PHONE, LINK_TYPES.WHATSAPP].includes(link.linkType);
  const isSMSType = link.linkType === LINK_TYPES.SMS;
  const smsBody = isSMSType && content.split('&body=')[1];
  const smsNumber = isSMSType && content.split(';')[0];

  return {
    ...link,
    url: content,
    ...(addPhoneNumberField && { phoneNumber: content }),
    ...(isSMSType && { smsBody, phoneNumber: smsNumber }),
  };
};
