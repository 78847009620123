import React, { useState, useEffect } from 'react';
import cx from 'classnames';

import { Error } from 'components/form/generic';

export default ({
  items,
  onChange,
  value,
  name,
  direction = 'col',
  disabled,
  border,
  wFull,
  testId,
  groupTitle,
  containerStyle,
  elementClassName,
  bodyElementClassName,
  hoverHighlight,
  elementStyle,
  tooltip,
  selectedTitleColor = '',
  ringer = false,
  error,
}) => {
  const [selectedValue, setSelectedValue] = useState();

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const handleSelectItem = ({ value }) => {
    setSelectedValue(value);
    onChange({
      target: {
        name,
        value,
      },
    });
  };
  return (
    <div
      data-testid={testId}
      className={cx('flex flex-' + direction + ' -my-1', wFull && 'w-full')}
      style={containerStyle}
    >
      <div>{groupTitle}</div>
      {items.map((item, i) => {
        const isSelected = item.value === selectedValue;
        return (
          <div key={i} data-for="main-radio-tooltip" data-tip={item.tooltip} data-class="max-w-xs">
            <label
              data-testid={i == '0' ? 'payments-register-individual' : 'payments-register-company '}
              className={cx(
                'flex cursor-pointer text-gray-800',
                item.body ? 'items-start' : 'items-center',
                direction === 'col' ? 'my-2' : 'ml-2 my-2',
                (disabled || item.disabled) && 'border-gray-300 opacity-50 cursor-not-allowed pointer-events-none ',
                border &&
                  (isSelected
                    ? (item.error ? 'border-zyda-red-300' : 'border-primary-base') + ' border p-2'
                    : (item.error ? 'border-zyda-red-300' : 'border-gray-300') + ' border p-2'),
                wFull && 'w-full',
                hoverHighlight && 'hover:bg-primary-50',
                item.className,
                item.title ? [elementClassName] : item.body ? [bodyElementClassName] : '',
              )}
              style={elementStyle}
              onClick={e => {
                if (selectedValue === item.value) {
                  e.preventDefault();
                }
                handleSelectItem(item);
              }}
            >
              <span className={cx(ringer && 'flex items-center')}>
                {!item.hideRadioBtn && (
                  <span
                    className={cx(
                      'flex items-center justify-center w-3 h-3 border-2',
                      (disabled || item.disabled) &&
                        (isSelected ? 'border-gray-300 bg-zyda-grey-200' : 'border-gray-200 bg-white'),
                      isSelected
                        ? (item.error ? 'border-zyda-red-300 bg-primary-base' : 'border-primary-base bg-primary-base') +
                            ' hover:border-gray-300 hover:bg-primary-100 focus:border-gray-300 active:border-gray-300 active:bg-primary-100'
                        : (item.error ? 'border-zyda-red-300' : 'border-gray-300') +
                            ' hover:border-primary-200 hover:bg-primary-200 focus:border-gray-300 active:border-gray-300 active:bg-primary-100',
                      'rounded-full mx-2',
                      item.body && 'my-1',
                    )}
                    style={{ minWidth: '0.75rem', minHeight: '0.75rem' }}
                  >
                    <span
                      className={cx(
                        'w-1 h-1 rounded-full',
                        isSelected
                          ? 'bg-white active:bg-primary-base '
                          : 'bg-white hover:bg-primary-200 focus:bg-white active:bg-primary-100',
                      )}
                    />
                  </span>
                )}
                {ringer && (
                  <span className={cx(!item.body && isSelected && (selectedTitleColor || 'text-primary-base'))}>
                    {item.title}
                  </span>
                )}
              </span>
              {item.img && (
                <span className="w-3 h-3 mr-1">
                  <img className="rounded-sm" src={item.img} alt="" />
                </span>
              )}
              <span
                className={cx(item.body && 'flex-grow', item.subTitle && 'flex flex-col items-start', ringer && 'ml-5')}
              >
                {!ringer && (
                  <span className={cx(!item.body && isSelected && (selectedTitleColor || 'text-primary-base'))}>
                    {(item.body && item.body) || item.title}
                  </span>
                )}
                {item.subTitle && <span className="text-xs text-gray-700">{item.subTitle}</span>}
              </span>
            </label>
            {item.note && <span>{item.note}</span>}
            {tooltip}
          </div>
        );
      })}
      {error && <Error direction={direction}>{error}</Error>}
    </div>
  );
};
