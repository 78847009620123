import { gql } from 'graphql-request';

const updateDeliveryZoneMutation = gql`
  mutation($input: UpdateDeliveryZoneInput!) {
    updateDeliveryZone(input: $input) {
      deliveryZone {
        id
        branchId
        zoneName
      }
      clientMutationId
    }
  }
`;

export default updateDeliveryZoneMutation;
