import React, { useState, useRef, useContext } from "react"
import cx from "classnames"
import { useClickOutside } from "hooks/index"
import { context as localeContext } from "context/locale"
import { Text } from "components/service"
import { Error, Label } from "components/form/generic"
import * as translations from "constants/translations"
import CheckboxAlt from "../CheckboxAlt"

export default ({
  title,
  subtitle,
  error,
  placeholder,
  items,
  name,
  font,
  onChange,
  value = [],
  testIdOpenList,
  testIdOptionsList,
  testIdClearAll,
  testIdSelectAll,
}) => {
  const { lang, direction } = useContext(localeContext)
  const [isOpened, setOpened] = useState(false)
  const ref = useRef()
  useClickOutside(ref, () => setOpened(false))

  const selected = value
    ?.map(id => items.find(item => item.id === id))
    .filter(Boolean)
  const unselected = items.filter(item => !value.includes(item.id))
  const update = value =>
    onChange({
      target: {
        name,
        value,
      },
    })
  const add = id => {
    update([...value, id])
  }
  const remove = deletingId => update(value.filter(id => deletingId !== id))

  const selecteAll = () => {
    return onChange({
      target: {
        name,
        value: [...items.map(val => val.id)],
      },
    })
  }
  const clearAll = () => {
    let itemTemp = items.map(val => val.id)
    return onChange({
      target: {
        name,
        value: value.filter(val => !itemTemp.includes(val)),
      },
    })
  }
  return (
    <Label
      title={title && title}
      font={font && font}
      subtitle={
        subtitle && <span className={cx(error && "mt-4")}>{subtitle} </span>
      }
    >
      <div className="relative w-full h-auto" ref={ref}>
        <div
          onClick={() => setOpened(!isOpened)}
          className={cx(
            "flex justify-between items-center appearance-none border border-gray-300 rounded-none w-full p-3 text-gray-700 leading-tight hover:border-primary-200 focus:border-transparent focus:outline-none focus:ring cursor-text",
            error && "border-red-500 bg-zyda-red-50"
          )}
          style={{ direction }}
          data-testid={testIdOpenList}
        >
          <div>
            <Selection
              placeholder={placeholder}
              items={selected}
              onItemClick={remove}
              lang={lang}
            />
          </div>
          <i
            className={cx(
              "material-icons text-lg text-gray-500 group-hover:text-primary-base flex-2 cursor-pointer",
              lang === "ar" ? "mr-auto" : "ml-auto"
            )}
          >
            {isOpened? "keyboard_arrow_up" : "keyboard_arrow_down"}
          </i>
        </div>
        {error && <Error direction={direction}>{error}</Error>}
        {isOpened && (
          <Dropdown
            testIdOptionsList={testIdOptionsList}
            items={items}
            selected={selected}
            unselected={unselected}
            onItemAdd={add}
            onItemRemove={remove}
            clearAll={clearAll}
            selecteAll={selecteAll}
            testIdClearAll={testIdClearAll}
            testIdSelectAll={testIdSelectAll}
            lang={lang}
          />
        )}
      </div>
    </Label>
  )
}

const Dropdown = ({
  items,
  onItemAdd,
  onItemRemove,
  clearAll,
  testIdOptionsList,
  selecteAll,
  testIdSelectAll,
  testIdClearAll,
  selected,
  unselected,
  lang,
}) => {
  const { direction } = useContext(localeContext)
  return (
    <div
      className="absolute z-10 left-0 rounded-none w-full bg-white border border-gray-200 overflow-y-auto"
      style={{ maxHeight: "10rem", direction }}
    >
      <div className="flex border border-b justify-between p-2">
        <span
          className="cursor-pointer text-sm  inline"
          data-testid={testIdSelectAll}
        >
          <CheckboxAlt
            onChange={() => selecteAll()}
            title={<Text value={translations.SELECT_ALL} />}
            value={selected.length > 0}
            customCheck={selected.length > 0 && unselected.length > 0}
          />
        </span>
        <span
          className="mx-2 cursor-pointer text-sm font-medium inline-flex items-center"
          onClick={() => clearAll()}
          data-testid={testIdClearAll}
        >
          <i className="material-icons font-medium text-sm mx-2">close</i>
          <Text value={translations.CLEAR_ALL} />
        </span>
      </div>
      {items.map((item, i) => {
        const isSelected = selected.find(
          selectedItem => item.id === selectedItem.id
        )
        return (
          <div className="block w-full px-2 py-1 text-sm" data-testid={testIdOptionsList+i}>
            <CheckboxAlt
              onChange={() =>
                isSelected ? onItemRemove(item.id) : onItemAdd(item.id)
              }
              title={item.title || <Text value={item} />}
              value={isSelected}
              key={i}
            />
          </div>
        )
      })}
    </div>
  )
}

const Selection = ({ items, placeholder, onItemClick, lang }) =>
  !items.length ? (
    <span className="text-gray-500 text-sm">{placeholder}</span>
  ) : (
    <div className={cx("-m-1", items.length > 10 && "h-40 overflow-y-auto")}>
      {items.map((item, i) => (
        <span
          onClick={e => {
            e.stopPropagation()
            onItemClick(item.id)
          }}
          key={i}
          className={cx(
            "m-1 text-sm text-zyda-black-100 rounded-sm py-1 bg-zyda-grey-15 cursor-pointer inline-flex items-center",
            lang === "en" ? "pr-0 pl-2" : "pl-0 pr-2"
          )}
        >
          {item.title || <Text value={item} />}
          <i className="material-icons text-zyda-grey-400 text-sm mx-2">close</i>
        </span>
      ))}
    </div>
  )
