import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

import { context as localContext } from 'context/locale';
import { Spinner } from 'components/kit';
import { Error } from 'components/form/generic';
import SegmentsFilter from 'common/components/SegmentsFilter';
import { SEGMENT_TITLE_MAP } from 'constants/customers';

const TargetedSegmentRecipient = ({
  setTargetedSegment,
  currentSelectedSegment = null,
  loading,
  targetedSegmentError,
}) => {
  const { direction } = useContext(localContext);
  const [selectedSegment, setSelectedSegment] = useState(currentSelectedSegment);

  const handleOnClick = segment => {
    setTargetedSegment(segment);
    setSelectedSegment(segment);
  };

  return loading ? (
    <Spinner />
  ) : (
    <div>
      <SegmentsFilter currentSegment={selectedSegment} handleOnClick={handleOnClick} hasAllCustomers />
      {targetedSegmentError && <Error direction={direction}>{targetedSegmentError}</Error>}
    </div>
  );
};

TargetedSegmentRecipient.propTypes = {
  setTargetedSegment: PropTypes.func.isRequired,
  currentSelectedSegment: PropTypes.oneOf([
    SEGMENT_TITLE_MAP.ALL_CUSTOMERS,
    SEGMENT_TITLE_MAP.CHAMPIONS,
    SEGMENT_TITLE_MAP.LOYAL,
    SEGMENT_TITLE_MAP.PROMISING,
    SEGMENT_TITLE_MAP.NEW,
    SEGMENT_TITLE_MAP.LOW_VALUE,
    SEGMENT_TITLE_MAP.NEEDS_ATTENTION,
  ]),
  loading: PropTypes.bool.isRequired,
  targetedSegmentError: PropTypes.shape({ props: PropTypes.shape({ value: PropTypes.arrayOf(PropTypes.string) }) }),
};

export default TargetedSegmentRecipient;
