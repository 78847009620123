import { gql } from '@apollo/client';

const SEGMENTS = gql`
  query($storeId: String!) {
    segments(restaurantId: $storeId) {
      segments {
        id
        customersCount
        titleEn
        titleAr
        descriptionEn
        descriptionAr
      }
      totalCustomerCount
    }
  }
`;

export default SEGMENTS;
