import { gql } from '@apollo/client';

const Period = gql`
  fragment Period on AnalyticsPeriod {
    startDate
    endDate
    data
    total
    diff
  }
`;

export const MAIN_VIEW = gql`
  query($storeId: String!, $range: AnalyticsRange!) {
    analyticsMainView(restaurantId: $storeId, range: $range) {
      totalSales
      salesPercentDiff
      totalOrders
      ordersPercentDiff
      avgOrderValue
      avgOrderPercentDiff
      totalCustomers
      customersPercentDiff
    }
  }
`;

export const TOTAL_SALES = gql`
  query($storeId: String!, $date: AnalyticsPrecision!, $range: AnalyticsRange!) {
    analyticsTotalSales(restaurantId: $storeId, date: $date, range: $range) {
      currentPeriod {
        ...Period
      }
      previousPeriod {
        ...Period
      }
      previousYear {
        ...Period
      }
    }
  }
  ${Period}
`;

export const AVG_ORDER_VALUE = gql`
  query($storeId: String!, $date: AnalyticsPrecision!, $range: AnalyticsRange!) {
    analyticsAvgOrderValue(restaurantId: $storeId, date: $date, range: $range) {
      currentPeriod {
        ...Period
      }
      previousPeriod {
        ...Period
      }
      previousYear {
        ...Period
      }
    }
  }
  ${Period}
`;

export const TOTAL_ORDERS = gql`
  query($storeId: String!, $date: AnalyticsPrecision!, $range: AnalyticsRange!) {
    analyticsTotalOrders(restaurantId: $storeId, date: $date, range: $range) {
      currentPeriod {
        ...Period
      }
      previousPeriod {
        ...Period
      }
      previousYear {
        ...Period
      }
    }
  }
  ${Period}
`;

export const TOTAL_CUSTOMERS = gql`
  query($storeId: String!, $date: AnalyticsPrecision!, $range: AnalyticsRange!) {
    analyticsTotalCustomers(restaurantId: $storeId, date: $date, range: $range) {
      currentPeriod {
        ...Period
      }
      previousPeriod {
        ...Period
      }
      previousYear {
        ...Period
      }
    }
  }
  ${Period}
`;

export const GEO_MAP_ANALYTICS = gql`
  query($storeId: String!, $range: AnalyticsRange!) {
    geoMapAnalytics(restaurantId: $storeId, range: $range) {
      startDate
      endDate
      data {
        branches {
          id
          titleEn
          titleAr
          lat
          lng
          totalSales
          totalOrders
          totalCustomers
          avgOrderValue
        }
        areas {
          id
          totalOrders
          points {
            lat
            lng
            weight
          }
        }
        meta {
          totalOrders
        }
      }
    }
  }
`;
