import React, { useContext } from "react"
import cx from "classnames"
import { context as localeContext } from "context/locale"

export default ({
  items,
  onChange,
  value = [],
  name,
  disabled,
  direction = "col",
  border,
  img,
  size = "base",
}) => {
  const { lang } = useContext(localeContext)

  return (
    <div className={cx(`flex flex-${direction} -my-1`)}>
      {items.map((item, i) => {
        const isSelected = value.includes(item.value)
        const bgColor = item.bgColor
          ? item.bgColor
          : "bg-primary-base border-primary-base"
        return (
          <label
            key={i}
            className={cx(
              "flex items-center cursor-pointer ",
              direction === "col" ? "my-1" : "mx-1",
              (disabled || item.disabled) &&
                "border-gray-300 opacity-50 cursor-not-allowed pointer-events-none ",
              "text-gray-800"
            )}
            onClick={() => {
              onChange({
                target: {
                  name,
                  value: isSelected
                    ? value.filter(el => el !== item.value)
                    : [...value, item.value],
                },
              })
            }}
          >
            <span
              className={cx(
                "flex items-center justify-center w-4 h-4 border rounded-sm",
                lang === "en" ? "mr-2" : "ml-2",
                isSelected
                  ? bgColor +
                      " hover:border-gray-300 hover:bg-primary-100 focus:border-gray-300 active:border-gray-300 active:bg-primary-100"
                  : "border-gray-300 hover:border-primary-200 hover:bg-primary-200 focus:border-gray-300 active:border-gray-300 active:bg-primary-100"
              )}
            >
              {isSelected && (
                <span className="material-icons text-white text-xs">done</span>
              )}
            </span>
            <span>{item.title}</span>
          </label>
        )
      })}
    </div>
  )
}
