import React, { useContext } from "react"
import cx from "classnames"
import { Error } from "components/form/generic"
import { context as localeContext } from "context/locale"

export default ({ items, onChange, value, name, size = "base", error }) => {
  const { lang, direction } = useContext(localeContext)
  return (
    <>
      <div
        className={cx(
          "flex items-center justify-between border border-zyda-grey-80",
          lang === "ar" && "flex-row-reverse"
        )}
      >
        {items.map((item, i) => {
          const isSelected = item.value === value

          return (
            <label
              key={i}
              className={cx(
                "flex items-center justify-center text-center w-1/3 p-1 cursor-pointer hover:text-white hover:bg-primary-base",
                sizes[size],
                i < items.length - 1 &&
                  (lang === "en" ? "border-r" : "border-l"),
                isSelected ? "bg-primary-base text-white" : "bg-transparent",
                item.disabled &&
                  "text-zyda-grey-20 pointer-events-none opacity-75 cursor-not-allowed"
              )}
              onClick={() =>
                onChange({
                  target: {
                    name,
                    value: item.value,
                  },
                })
              }
            >
              <div
                className={cx(
                  "inline-flex justify-center items-center",
                  lang === "ar" && "flex-row-reverse"
                )}
              >
                {isSelected && (
                  <i
                    className={cx(
                      "material-icons text-sm text-white focus:text-primary-base active:text-primary-base pt-1",
                      lang === "ar" ? "ml-2" : "mr-2"
                    )}
                  >
                    check
                  </i>
                )}
                {item.title}
              </div>
            </label>
          )
        })}
      </div>
      {error && <Error direction={direction}>{error}</Error>}
    </>
  )
}

const sizes = {
  xs: "py-1 px-2 text-xs",
  sm: "py-1 px-3 text-sm",
  base: "p-2 ",
}
