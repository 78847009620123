import React, { useContext } from "react"
import { Mutation } from "@apollo/client/react/components"
import { useQuery } from "@apollo/client"
import { navigate } from "@reach/router"
import { replaceParams, convertTime } from "utils/index"
import { getBackUrl } from "utils/history"
import { Spinner } from "components/kit"
import { Text } from "components/service"
import { context as userContext } from "context/user"
import { context as notificationsContext } from "context/notifications"
import { context as localeContext } from "context/locale"
import cx from "classnames"
import * as translations from "constants/translations"
import { Layout } from "components/common/dashboard"
import { DeliveryForm } from "components/common/delivery"
import * as paths from "paths.js"
import * as schemas from "./schemas"
import * as utils from "./utils"
import { concat } from "ramda"
import TopPane from "./TopPane"
import { DELIVERY_ZONES } from "./../List/schemas"

export default ({ branchId, storeId, deliveryZoneId }) => {
  const { selectedStore } = useContext(userContext)
  const { lang } = useContext(localeContext)
  const notifications = useContext(notificationsContext)

  const { loading: isLoading, data: dataQuery } = useQuery(
    schemas.DELIVERY_ZONE,
    {
      variables: {
        branchId: branchId,
        storeId: storeId,
        deliveryZoneId: deliveryZoneId.toString(),
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
    }
  )

  const isMultiCountry =
    !isLoading &&
    dataQuery &&
    dataQuery.deliveryZone[0].countryId !== selectedStore.countryId
  const selectedCountryId = isMultiCountry
    ? dataQuery.deliveryZone[0].countryId
    : selectedStore.countryId
  let errorGph

  return (
    <Mutation
      mutation={schemas.UPDATE_DELIVERY_ZONE}
      onError={({ graphQLErrors, networkError, response, operation }) => {
        if (graphQLErrors)
          graphQLErrors.map(({ message, locations, path, extensions }) => {
            errorGph = extensions.exception.body
          })
      }}
      refetchQueries={[
        {
          query: DELIVERY_ZONES,
          variables: {
            restaurantId: selectedStore.id,
            countryId: parseInt(selectedCountryId),
          },
        },
      ]}
      awaitRefetchQueries
    >
      {(update, { loading: isUpdating }) => (
        <Layout>
          <div
            className={cx(
              "pb-6 flex",
              lang === "ar" && "flex-row-reverse"
            )}
          >
            <div className="w-full md:w-2/3">
              {isLoading && !isUpdating ? (
                <Spinner />
              ) : (
                <>
                  <DeliveryForm
                    isSubmitting={isUpdating}
                    renderTopPane={({ isSubmitting }) => (
                      <TopPane
                        zoneId={dataQuery.deliveryZone[0].id}
                        storeId={selectedStore.id}
                        isSubmitting={isSubmitting}
                        branchId={branchId}
                      />
                    )}
                    initialValues={{
                      branchId: branchId,
                      countryId: dataQuery.deliveryZone[0].countryId,
                      deliveryFee: dataQuery.deliveryZone[0].deliveryFee,
                      deliveryTimeDays: isMultiCountry
                        ? convertTime.fromMinutesToDays(
                            dataQuery.deliveryZone[0].deliveryTime
                          )
                        : 0,
                      deliveryTime: [
                        dataQuery.deliveryZone[0].deliveryTime,
                        "mins",
                      ],
                      minimumOrder: dataQuery.deliveryZone[0].minimumOrder,
                      scheduleMaxPeriod: convertTime.fromHoursToDays(
                        dataQuery.deliveryZone[0].scheduleMaxPeriod
                      ),
                      openingHours: utils.omitTypename(
                        dataQuery.deliveryZone[0].openingHours
                      ),
                      areas: dataQuery.deliveryZone[0].areas.map(
                        ({ id }) => id
                      ),
                    }}
                    submitTitle={<Text value={translations.SAVE_CHANGE} />}
                    onSubmit={(data, { setErrors }) => {
                      let deliveryTime
                      let scheduleMaxPeriod
                      let areasSelected = concat(
                        data.areas,
                        data.searchedAreas
                      ).map(v => parseInt(v, 10))

                      if (isMultiCountry) {
                        deliveryTime = convertTime.fromDaysToMinutes(
                          data.deliveryTimeDays
                        )
                        scheduleMaxPeriod = convertTime.fromDaysToHours(
                          data.scheduleMaxPeriod
                        )
                      } else {
                        deliveryTime =
                          data.deliveryTime[1] === "hrs"
                            ? convertTime.fromHoursToMinutes(
                                data.deliveryTime[0]
                              )
                            : Number(data.deliveryTime[0])
                      }
                      let variables = isMultiCountry
                        ? {
                            zoneId: dataQuery.deliveryZone[0].id.toString(),
                            restaurantId: storeId,
                            branchId: data.branchId,
                            countryId: data.countryId,
                            deliveryTime: deliveryTime,
                            deliveryFee: data.deliveryFee,
                            scheduleMaxPeriod: scheduleMaxPeriod,
                            minimumOrder: data.minimumOrder,
                            openingHours: utils.omitTypename(data.openingHours),
                            areasIds: areasSelected.filter(
                              (v, i) => areasSelected.indexOf(v) === i
                            ),
                          }
                        : {
                            zoneId: dataQuery.deliveryZone[0].id.toString(),
                            restaurantId: storeId,
                            branchId: data.branchId,
                            countryId: data.countryId,
                            deliveryTime: deliveryTime,
                            deliveryFee: data.deliveryFee,
                            minimumOrder: data.minimumOrder,
                            openingHours: utils.omitTypename(data.openingHours),
                            areasIds: areasSelected.filter(
                              (v, i) => areasSelected.indexOf(v) === i
                            ),
                          }
                      update({
                        variables: variables,
                      }).then(res => {
                        if (errorGph) {
                          let obj = {}
                          let num
                          let text
                          Object.entries(errorGph).map((value, index) => {
                            text = value[1][0].match(/.+(\S+\s)/)[0]
                            num = parseInt(
                              value[1][0].match(/[-]?(\d+)(?!.*\d)/)[0]
                            )

                            if (data.countryId !== selectedStore.countryId) {
                              if (value[0] === "delivery_time") {
                                return (obj.deliveryTimeDays =
                                  text + convertTime.fromMinutesToDays(num))
                              }
                            } else {
                              if (value[0] === "delivery_time")
                                return (obj.deliveryTime = value[1][0])
                            }
                            if (value[0] === "schedule_max_period") {
                              return (obj.scheduleMaxPeriod =
                                text + convertTime.fromHoursToDays(num))
                            }
                            if (value[0] === "delivery-fee")
                              return (obj.deliveryFee = value[1][0])
                          })
                          setErrors(obj)
                        } else {
                          notifications.show(
                            <Text value={translations.DELIVERY_ZONE_EDITED} />
                          )
                          navigate(
                            getBackUrl() ||
                              replaceParams(paths.deliveryList, {
                                storeId: selectedStore.id,
                              })
                          )
                        }
                      })
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </Layout>
      )}
    </Mutation>
  )
}
