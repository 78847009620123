import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import * as translations from 'constants/translations';
import { Text } from 'components/service';
import { InfoIcon } from '@zydalabs/zac-icons-react';
import { context as localeContext } from 'context/locale';
import Navigate from './Navigate';
import BranchSelection from './BranchSelection';
import ZoneNotification from './Notifications';
import { zoneNotificationsTypeUtils } from '../utils';
import ZoneCreator from './ZoneCreator';
import TemplatesFilter from './TemplatesFilter';

const Overlay = ({
  branches,
  zoneController,
  setZoneController,
  setZonesCollection,
  setDzTemplatesParams,
  setBulkZones,
  setDzSuggestedTemplatesParams,
}) => {
  const { direction, translate } = useContext(localeContext);
  const { deliveryOrdersEnabled } = zoneController.selectedBranch || { deliveryOrdersEnabled: true };
  return (
    <>
      <div className="bg-white p-4 mb-4 shadow">
        <Navigate
          zoneController={zoneController}
          setZoneController={setZoneController}
          setBulkZones={setBulkZones}
          setDzTemplatesParams={setDzTemplatesParams}
          setDzSuggestedTemplatesParams={setDzSuggestedTemplatesParams}
        />
        {!zoneController.isEdit && (
          <>
            <BranchSelection
              branches={branches}
              zoneController={zoneController}
              setZoneController={setZoneController}
            />
            {zoneController.selectedBranch && !deliveryOrdersEnabled && (
              <div className="py-1 px-2 mt-2 bg-zyda-yellow-25 flex" style={{ direction }}>
                <InfoIcon width="16" color="#B48A00" className={direction === 'rtl' ? 'ml-2' : 'mr-2'} />
                <span className="text-xs text-yellow-600">{translate(translations.BRANCH_DELIVERY_DISABLED)}</span>
              </div>
            )}
          </>
        )}
        <ZoneCreator zoneController={zoneController} setZoneController={setZoneController} />
        {zoneController.showZoneTemplates && (
          <TemplatesFilter
            zoneController={zoneController}
            setZoneController={setZoneController}
            setZonesCollection={setZonesCollection}
            setDzTemplatesParams={setDzTemplatesParams}
          />
        )}
      </div>
      {zoneController.deleteZoneNotify && (
        <ZoneNotification
          title={<Text value={translations.DELIVERY_ZONE_DELETED} />}
          type={zoneNotificationsTypeUtils.ERROR}
          setZoneController={setZoneController}
        />
      )}
      {zoneController.updateZoneNotify && (
        <ZoneNotification
          title={<Text value={translations.DELIVERY_ZONE_EDITED} />}
          type={zoneNotificationsTypeUtils.SUCCESS}
          setZoneController={setZoneController}
        />
      )}
      {zoneController.addZoneNotify && (
        <ZoneNotification
          title={<Text value={translations.DELIVERY_ZONE_ADDED} />}
          type={zoneNotificationsTypeUtils.SUCCESS}
          setZoneController={setZoneController}
        />
      )}
    </>
  );
};
Overlay.propTypes = {
  branches: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ).isRequired,
  zoneController: PropTypes.shape({
    enableDrawerMode: PropTypes.bool,
    showDrawerMode: PropTypes.bool,
    selectedBranch: PropTypes.shape({
      id: PropTypes.number,
    }),
    deleteZoneNotify: PropTypes.bool,
    updateZoneNotify: PropTypes.bool,
    addZoneNotify: PropTypes.bool,
    showZoneTemplates: PropTypes.bool,
    isEdit: PropTypes.bool,
  }).isRequired,
  setZoneController: PropTypes.func.isRequired,
  setZonesCollection: PropTypes.func.isRequired,
  setBulkZones: PropTypes.func.isRequired,
  setDzTemplatesParams: PropTypes.func.isRequired,
  setDzSuggestedTemplatesParams: PropTypes.func.isRequired,
};

export default Overlay;
