import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { parse } from 'query-string';
import { navigate } from '@reach/router';
import cx from 'classnames';

import { Modal, Spinner } from 'components/kit';
import { Text } from 'components/service';
import { Layout, Breadcrumbs } from 'components/common/dashboard';
import * as translations from 'constants/translations';
import { breadcrumbs } from 'constants/translations';
import * as paths from 'paths.js';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { useReplaceParams, useSelectedStore } from 'hooks/index';
import * as schemas from './schemas';
import convertMultiLanguagesFromObjectToArray from '../../integrations/utils';
import DeleteModal from '../Edition/DeleteModal';
import GatewayModal from './GatewayModal';
import OnlinePaymentSection from './OnlinePaymentSection';
import ActionButton from './ActionButton';

export const PaymentGateway = () => {
  const storeId = useSelectedStore();
  const { lang } = useContext(localeContext);
  const { selectedStore } = useContext(userContext);
  const { data: { paymentCredentials, settings } = {}, refetch, loading } = useQuery(schemas.PAYMENT_CREDENTIALS, {
    variables: { storeId },
    fetchPolicy: 'cache-and-network',
  });
  const { gatewayId } = parse(document.location.search);
  const { data: { paymentGateway } = {}, loading: loadingGateway } = useQuery(schemas.PAYMENT_GATEWAY, {
    variables: {
      countryId: parseInt(selectedStore.countryId),
      gatewayId: parseInt(gatewayId),
    },
    fetchPolicy: 'cache-and-network',
  });

  const replace = useReplaceParams();

  const hasManualPaymentMethod = settings?.cashPaymentEnabled || settings?.cardondeliveryPaymentEnabled;

  return (
    <Modal>
      {({ open, close }) => (
        <Layout
          top={
            <>
              <Breadcrumbs
                links={[paths.setup, paths.paymentGateways]}
                path={breadcrumbs.INTEGRATE_PAYMENT_GATEWAY({
                  ...paymentGateway?.extraData?.title,
                })}
                right={
                  <>
                    {paymentCredentials?.paymentGateway?.id === gatewayId && (
                      <ActionButton
                        testId="paymentMethods-online-btn"
                        onClick={() =>
                          open({
                            title: <Text value={translations.DELETED_PAYMENT_GATEWAY} />,
                            body: <DeleteModal id={paymentCredentials.id} storeId={storeId} onClose={close} />,
                            size: 'max-w-md',
                          })
                        }
                      >
                        <Text value={translations.DISCONNECT} />
                      </ActionButton>
                    )}
                    {!paymentCredentials && (
                      <ActionButton
                        onClick={() => {
                          paymentGateway?.integrationFromGateway
                            ? open({
                                title: (
                                  <Text
                                    value={[paymentGateway.extraData?.title?.en, paymentGateway.extraData?.title?.ar]}
                                  />
                                ),
                                body: <GatewayModal paymentGateway={paymentGateway} modalClose={close} />,
                                size: 'max-w-xl',
                              })
                            : navigate(
                                replace(paths.onlinePaymentCreationForm, {
                                  appendQuery: true,
                                  omitQuery: false,
                                }),
                              );
                        }}
                        testId="paymentMethods-register-now"
                      >
                        <Text value={translations.CONNECT_NOW} />
                      </ActionButton>
                    )}
                  </>
                }
              />
            </>
          }
        >
          <div className={cx('pb-6 flex', lang === 'ar' && 'flex-row-reverse')}>
            {loading || loadingGateway ? (
              <Spinner />
            ) : (
              <div className="w-full md:w-2/3">
                <img
                  src={paymentGateway.logoUrl}
                  alt={paymentGateway?.name}
                  className={cx('w-24 l:-mt-3', lang === 'ar' && 'float-right')}
                />
                {paymentGateway?.extraData?.description && (
                  <Text
                    value={convertMultiLanguagesFromObjectToArray(paymentGateway?.extraData?.description)}
                    className="clear-right my-4"
                  />
                )}
                <OnlinePaymentSection
                  selectedStore={selectedStore}
                  storeId={storeId}
                  refetch={refetch}
                  paymentCredentials={paymentCredentials}
                  paymentGateway={paymentGateway}
                  hasManualPaymentMethod={hasManualPaymentMethod}
                />
              </div>
            )}
          </div>
        </Layout>
      )}
    </Modal>
  );
};

export default PaymentGateway;
