import React, { useContext } from 'react';

import { context as customersContext } from 'context/customers';
import { Tag } from 'components/kit';

const SegmentLabel = ({ segmentId, lang }) => {
  const { segments } = useContext(customersContext);
  const segmentTemp = segments[segmentId];
  return (
    <Tag
      icon={segmentTemp.segmentIcon}
      title={lang === 'en' ? segmentTemp.titleEn : segmentTemp.titleAr}
      baseCss={segmentTemp.segmentBaseCss}
      labelCss={segmentTemp.segmentLabelCss}
      lang={lang}
    />
  );
};

export default SegmentLabel;
