import { useDeliveryZoneMsToken } from 'hooks';
import { DeliveryZoneTemplate, Scalars } from '../../types/generated';
import { useQuery } from '../../utils';
import { QueryHookResponse } from '../../types/internal';
import { deliveryZoneTemplatesQuery } from '../../queries/deliveryZones';

type useFetchDeliveryZoneTemplatesType = ({
  countryCode,
  cityCode,
  areaId,
  pageSize,
  pageNumber,
  searchParam,
}?: {
  countryCode?: Scalars['String'];
  cityCode?: Scalars['String'];
  areaId?: Scalars['ID'];
  pageSize?: Scalars['Int'];
  pageNumber?: Scalars['Int'];
  searchParam?: Scalars['String'];
}) => QueryHookResponse<DeliveryZoneTemplate>;

const useFetchDeliveryZoneTemplates: useFetchDeliveryZoneTemplatesType = ({
  countryCode,
  cityCode,
  areaId,
  pageSize,
  pageNumber,
  searchParam,
}) => {
  const deliveryZoneMsToken = useDeliveryZoneMsToken();
  const variables = countryCode ? { countryCode, cityCode, areaId, pageSize, pageNumber, searchParam } : null;
  return useQuery<DeliveryZoneTemplate>({
    query: deliveryZoneTemplatesQuery,
    variables,
    requestHeaders: { token: deliveryZoneMsToken },
    handler: (res: any) => res?.deliveryZoneTemplates,
    config: { revalidateOnMount: true },
  });
};

export default useFetchDeliveryZoneTemplates;
