import * as R from "ramda"
import { useContext } from "react"
import { useLocation } from "@reach/router"
import { context as userContext } from "context/user"
import TagManager from "react-gtm-module"

TagManager.initialize({
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
  auth: process.env.REACT_APP_GOOGLE_TAG_MANAGER_AUTH_ID,
})

export default () => {
  const location = useLocation()
  const { user, getRole, isAuthenticated, selectedStore } = useContext(
    userContext
  )
  const payload = user &&
    selectedStore && {
      ...filterFields(user),
      storeId: selectedStore.id,
      storeName: selectedStore.titleEn,
      role: getRole(selectedStore.id),
    }

  return {
    pageView: pageTitle => {
      const event = {
        event: "page_view",
        page: location.pathname,
        pageTitle,
      }
      const dataLayer = isAuthenticated ? { ...payload, ...event } : event

      TagManager.dataLayer({ dataLayer })
    },
    signIn: user => {
      TagManager.dataLayer({
        dataLayer: {
          event: "sign_in",
          ...filterFields(user),
        },
      })
    },
    signOut: () => {
      TagManager.dataLayer({
        dataLayer: {
          event: "sign_out",
          ...filterFields(user),
        },
      })
    },
  }
}

const filterFields = R.pick(["id", "name", "email", "phoneNumber"])
