import React from 'react';
import { Text } from 'components/service';
import { ReactComponent as Grid } from 'assets/layouts/grid.svg';
import { ReactComponent as Scrollable } from 'assets/layouts/scrollable.svg';
import { ReactComponent as OnePage } from 'assets/layouts/one-page.svg';
import { ReactComponent as Modern } from 'assets/fonts/modern.svg';
import { ReactComponent as Clean } from 'assets/fonts/clean.svg';
import { ReactComponent as Fancy } from 'assets/fonts/fancy.svg';
import { ReactComponent as Bold } from 'assets/fonts/bold.svg';
import { ReactComponent as Light } from 'assets/fonts/light.svg';
import { ReactComponent as Mono } from 'assets/fonts/mono.svg';

import * as translations from 'constants/translations';

export const FONT_ENUM = {
  MODERN: 'typography_cabin_robotoCondensed',
  CLEAN: 'typography_openSans_Raleway',
  FANCY: 'typography_sourceSansPro_playfairDisplay',
  BOLD: 'typography_poppins_abril',
  LIGHT: 'typography_serif_oxygen',
  MONO: 'typography_crimson_unica',
};

export const HOME_LAYOUT_ENUMS = {
  ONE_PAGE: 1,
  GRID: 2,
  SCROLLABLE: 3,
};

export const fonts = [
  {
    id: FONT_ENUM.MODERN,
    icon: <Modern />,
    title: <Text value={translations.MODERN} />,
  },
  {
    id: FONT_ENUM.CLEAN,
    icon: <Clean />,
    title: <Text value={translations.CLEAN} />,
  },
  {
    id: FONT_ENUM.FANCY,
    icon: <Fancy />,
    title: <Text value={translations.FANCY} />,
  },
  {
    id: FONT_ENUM.BOLD,
    icon: <Bold />,
    title: <Text value={translations.BOLD} />,
  },
  {
    id: FONT_ENUM.LIGHT,
    icon: <Light />,
    title: <Text value={translations.LIGHT} />,
  },
  {
    id: FONT_ENUM.MONO,
    icon: <Mono />,
    title: <Text value={translations.MONO} />,
  },
];

export const homeLayoutShapes = [
  {
    id: HOME_LAYOUT_ENUMS.GRID,
    title: <Text value={translations.GRID} />,
    icon: <Grid />,
  },
  {
    id: HOME_LAYOUT_ENUMS.SCROLLABLE,
    title: <Text value={translations.SCROLLABLE} />,
    icon: <Scrollable />,
  },
  {
    id: HOME_LAYOUT_ENUMS.ONE_PAGE,
    title: <Text value={translations.ONE_PAGE} />,
    icon: <OnePage />,
  },
];

export const productLayout = [
  {
    id: HOME_LAYOUT_ENUMS.GRID,
    title: <Text value={translations.GRID} />,
    icon: <Grid />,
  },
  {
    id: HOME_LAYOUT_ENUMS.ONE_PAGE,
    title: <Text value={translations.ONE_PAGE} />,
    icon: <OnePage />,
  },
];
