import React from 'react';
import PropTypes from 'prop-types';
import { useLoadScript } from '@react-google-maps/api';

import { Spinner } from 'components/kit';
import useFetchDeliveryZoneTemplates from 'service/hooks/deliveryZones/useFetchDeliveryZoneTemplates';
import { Text } from 'components/service';
import { SOMETHING_WENT_WRONG } from 'constants/translations';
import TemplatesMap from './TemplatesMap';

const { REACT_APP_GOOGLE_MAPS_KEY } = process.env;

const Templates = ({ countryCode, cityCode }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: REACT_APP_GOOGLE_MAPS_KEY,
  });
  const { data: deliveryZoneTemplates, isLoading, error } = useFetchDeliveryZoneTemplates({
    countryCode: countryCode.toUpperCase(),
    cityCode: cityCode?.toUpperCase(),
  });

  // TODO :: Add in Actual Loading State, Empty State and Error State
  return error ? (
    <div className="w-full h-screen justify-center flex overflow-hidden">
      <Text className="w-fit my-auto" value={SOMETHING_WENT_WRONG} />
    </div>
  ) : isLoading ? (
    <div className="w-full h-screen justify-center flex overflow-hidden">
      <Spinner />
    </div>
  ) : (
    <TemplatesMap zones={deliveryZoneTemplates?.featureCollection} isLoaded={isLoaded} loadError={loadError} />
  );
};

Templates.propTypes = {
  countryCode: PropTypes.string,
  cityCode: PropTypes.string,
};

export default Templates;
