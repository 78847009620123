import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';

import { context as localeContext } from 'context/locale';
import { Text } from 'components/service';
import convertMultiLanguagesFromObjectToArray from '../../../../../utils';

const FirstStep = ({ courierIntegrationInfo }) => {
  const { lang } = useContext(localeContext);
  const { sanitize } = DOMPurify;

  return (
    <>
      {courierIntegrationInfo.map(info => (
        <Fragment key={`text${info.header?.en}`}>
          <Text className="font-bold mb-3" value={convertMultiLanguagesFromObjectToArray(info.header)} />
          <span
            className="block w-full mb-4 whitespace-pre-wrap"
            dangerouslySetInnerHTML={{
              __html: sanitize(lang === 'en' ? info.body.en : info.body.ar, { ADD_ATTR: ['target'] }),
            }}
          />
        </Fragment>
      ))}
    </>
  );
};
FirstStep.propTypes = {
  courierIntegrationInfo: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.shape({
        ar: PropTypes.string.isRequired,
        en: PropTypes.string.isRequired,
      }),
      body: PropTypes.shape({
        ar: PropTypes.string.isRequired,
        en: PropTypes.string.isRequired,
      }),
    }),
  ),
};

export default FirstStep;
