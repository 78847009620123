import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Text } from 'components/service';
import { DropDown } from 'components/kit';
import * as translations from 'constants/translations';

const ViewInLanguageDropdown = ({ lang, langSelected, setLangSelected, setQuery, productsLang }) => (
  <DropDown
    testId_openDropDown="list-products-select-view-lang"
    testId_selectedData="list-products-view-lang-name"
    testId_listData="list-products-view-lang-list"
    float={lang === 'ar' ? 'left' : 'right'}
    label={<Text value={translations.VIEW_IN} />}
    responsiveLabel
    full
    optionSelected={langSelected}
    onSelect={productsLang => {
      setLangSelected(productsLang);
      setQuery({ viewIn: productsLang });
    }}
    data={productsLang}
    icon="keyboard_arrow_down"
    wFull={true}
    responsiveLabel
    labelClassNames="text-gray-700"
  />
);

ViewInLanguageDropdown.propTypes = {
  lang: PropTypes.string.isRequired,
  langSelected: PropTypes.string.isRequired,
  productsLang: PropTypes.string.isRequired,
  setLangSelected: PropTypes.func.isRequired,
  setQuery: PropTypes.func.isRequired,
};

export default ViewInLanguageDropdown;
