export const getBehaviorBreakdownData = result => {
  const { data } = result || {};
  if (!data) return {};
  const key = Object.keys(data)[0];

  return data[key] || {};
};

export const dataCustomers = (customerData, selection) => {
  const { data, keys } = customerData || {};
  if (!data || !keys) return {};
  return {
    currentPeriod:
      {
        ...(customerData && {
          ...customerData,
          keys: keys[selection],
          data: data ? data[selection] : null,
        }),
      } || {},
  };
};
