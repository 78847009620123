import React, { useContext, useState, useEffect } from 'react';
import { Query } from '@apollo/client/react/components';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';

import { context as userContext } from 'context/user';
import { Spinner } from 'components/kit';
import Table from './Table';
import * as schemas from './schemas';

const BranchAuthorization = ({ setIsLoading, courier, setIsDisabled, setAuthData }) => {
  const { selectedStore } = useContext(userContext);
  const { name: courierName } = courier;
  const [validAPIKeys, setValidAPIKeys] = useState({});

  useEffect(() => {
    setIsDisabled(true);
  }, []);

  useEffect(() => {
    setAuthData(validAPIKeys);
  }, [validAPIKeys]);

  return (
    <Query
      query={schemas.BRANCHES}
      variables={{ restaurantId: selectedStore.id }}
      notifyOnNetworkStatusChange
      fetchPolicy="network-only"
    >
      {({ loading: loadingBranch, data: branches }) => (
        <Formik initialValues={{}}>
          <Form>
            {loadingBranch ? (
              <Spinner />
            ) : (
              <Table
                branches={branches.branches}
                courierName={courierName}
                setValidAPIKeys={setValidAPIKeys}
                validAPIKeys={validAPIKeys}
                setIsValidating={setIsLoading}
                setIsDisabled={setIsDisabled}
              />
            )}
          </Form>
        </Formik>
      )}
    </Query>
  );
};

BranchAuthorization.propTypes = {
  setIsLoading: PropTypes.func.isRequired,
  courier: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  setIsDisabled: PropTypes.func.isRequired,
  setAuthData: PropTypes.func.isRequired,
};

export default BranchAuthorization;
