import React, { useContext } from 'react';
import cx from 'classnames';
import { Mutation } from '@apollo/client/react/components';
import { replaceParams } from 'utils/index';
import { withBackParam } from 'utils/history';
import { context as modalContext } from 'context/modal';
import { context as userContext } from 'context/user';
import { context as localeContext } from 'context/locale';
import { Modal, Button } from 'components/kit';
import { Text, Link } from 'components/service';
import * as paths from 'paths.js';
import * as schemas from '../schemas';
import * as utils from './utils';
import * as translations from 'constants/translations';
import SetAsBusy from 'components/common/branch/Busy/SetAsBusy';
import Map from './Map';
import moment from 'moment';

export default ({ storeId, items }) => {
  const modal = useContext(modalContext);
  const { selectedStore } = useContext(userContext);
  const { lang } = useContext(localeContext);

  const locationCardPadding = lang === 'ar' ? 'lg:pl-4' : 'lg:pr-4';
  return (
    <Mutation update={utils.updateCache(selectedStore.id)} mutation={schemas.DELETE_LOCATION} onCompleted={modal.close}>
      {remove => (
        <Modal>
          {({ open, close }) => (
            <div className="flex flex-wrap">
              {items.map((item, index) => {
                return (
                  <div
                    key={item.id}
                    className={cx('w-full lg:w-1/2 pb-4', (index === 0 || index % 2 == 0) && locationCardPadding)}
                  >
                    <div className="flex-1 flex flex-wrap rounded border border-gray-300">
                      <div className="w-full order-2 md:order-none md:w-2/3 flex flex-col">
                        <div className="p-4 flex items-center">
                          <span>
                            <Text value={item} className="text-lg" />
                          </span>
                          <span className="mx-2 w-1 h-1 rounded-full bg-black" />
                          <Link
                            omitQuery
                            to={
                              replaceParams(paths.branchEdition, {
                                storeId,
                                branchId: item.id,
                              }) + `?${withBackParam()}`
                            }
                            className="text-xs text-primary-base"
                            data-testid="edit-business-location"
                          >
                            <Text value={translations.EDIT} />
                          </Link>
                        </div>
                        <div className={cx('px-4 pb-4 flex flex-row justify-between items-center')}>
                          <div className="flex">
                            <div className={cx(lang === 'ar' ? 'pl-2' : 'pr-2', 'flex-2 flex flex-col')}>
                              <span className="text-xs text-gray-700">
                                <Text value={translations.PICKUP} />
                              </span>
                              <span className="text-xs">
                                <Availability
                                  value={item.pickupOrdersEnabled}
                                  isBusy={item.busy && (item.busyMode === 'pickup' || item.busyMode === 'both')}
                                />
                              </span>
                            </div>
                            <div className="flex-1 flex flex-col ml-2">
                              <span className="text-xs text-gray-700">
                                <Text value={translations.DELIVERY} />
                              </span>
                              <span className="text-xs">
                                <Availability
                                  value={item.deliveryOrdersEnabled}
                                  isBusy={item.busy && (item.busyMode === 'delivery' || item.busyMode === 'both')}
                                />
                              </span>
                            </div>
                          </div>
                          <div className="flex items-center">
                            <Business
                              {...item}
                              storeId={storeId}
                              isDisabled={!item.deliveryOrdersEnabled && !item.pickupOrdersEnabled}
                            />
                          </div>
                        </div>
                        <Publicity value={item.dineIn} lang={lang} />
                      </div>
                      <div className="w-full order-1 h-32 md:h-auto md:order-none md:w-1/3 overflow-hidden">
                        <Map lat={item.lat} lng={item.lng} />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </Modal>
      )}
    </Mutation>
  );
};

const Availability = ({ value, isBusy }) => {
  return isBusy ? (
    <Text value={translations.BUSY} className="font-medium text-red-600" />
  ) : value ? (
    <Text value={translations.AVAILABLE} />
  ) : (
    <Text value={translations.UNAVAILABLE} />
  );
};

const Publicity = ({ value, lang }) => (
  <div className="flex items-center px-4 pb-3">
    <i
      className={cx(
        'material-icons text-base',
        value ? 'text-green-500' : 'text-gray-500',
        lang === 'ar' ? 'ml-2' : 'mr-2',
      )}
    >
      {value ? 'visibility' : 'visibility_off'}
    </i>
    <Text className="text-xs" value={value ? translations.PUBLIC_LOCATION : translations.PRIVATE_LOCATION} />
  </div>
);

const Business = ({ id, titleEn, titleAr, storeId, busy, isDisabled, busyFrom, busyUntil, busyMode }) => {
  const { selectedStore } = useContext(userContext);
  const { lang, translate } = useContext(localeContext);

  const calcBusyTime = () => {
    if (busyFrom && moment(busyUntil).isAfter()) {
      const busyFromMoment = moment(busyFrom)
        .tz(selectedStore.timeZone)
        .startOf('minute');
      const busyUntilMoment = moment(busyUntil)
        .tz(selectedStore.timeZone)
        .startOf('minute');
      return [busyFromMoment, busyUntilMoment];
    } else if (busyUntil && moment(busyUntil).isAfter()) {
      return moment(busyUntil)
        .tz(selectedStore.timeZone)
        .startOf('minute');
    }
    return 0;
  };

  const translateBusyMode = () => {
    return busyMode === 'delivery'
      ? translate(translations.THE_DELIVERY)
      : busyMode === 'pickup'
      ? translate(translations.THE_PICKUP)
      : busyMode === 'both'
      ? translate(translations.STORE)
      : null;
  };
  const busyTime = calcBusyTime();

  return (
    <Modal>
      {({ open, close }) => (
        <Button
          size="sm"
          kind="tertiary"
          weight="medium"
          borderColor="border-gray-300"
          textColor="text-primary-base"
          className={cx('h-5 text-xs', isDisabled ? 'text-zyda-grey-200 cursor-not-allowed' : 'text-primary-base')}
          disabled={isDisabled}
          onClick={() =>
            open({
              title: (
                <div className={cx('flex justify-between items-center', lang === 'ar' && 'flex-row-reverse')}>
                  <div className="flex flex-col">
                    <Text
                      value={translations.BRANCH_AVAILABILITY({
                        titleEn,
                        titleAr,
                      })}
                    />
                    <Text className="text-xs text-gray-700 font-normal" value={translations.SET_AVAILABILITY} />
                  </div>
                </div>
              ),
              body: (
                <SetAsBusy
                  branchId={id}
                  onCancel={close}
                  busyMode={busyMode}
                  busyModeTranslated={translateBusyMode()}
                />
              ),
              size: 'max-w-md',
            })
          }
          data-testid="set-busy-button"
        >
          <Text value={!!busyTime ? translations.EDIT_BUSY_MODE : translations.SET_AS_BUSY} />
        </Button>
      )}
    </Modal>
  );
};
