import React from "react"
import * as yup from "yup"
import { Text } from "components/service"
import * as translations from "constants/translations"

export const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email(<Text value={translations.INVALID_EMAIL_FORMAT} />)
    .required(<Text value={translations.EMAIL_IS_REQ} />),
  password: yup
    .string()
    .required(<Text value={translations.PASSWORD_REQUIRED} />),
})

export const initialValues = {
  email: "",
  password: "",
}
