import { SEGMENT_ATTRIBUTES, SEGMENT_TITLE_MAP } from 'constants/customers';

const fuseSegmentContractWithSegmentStatic = (segmentContract, totalCustomerCount, segments) => {
  const tempSegments = segments;
  if (segmentContract.length > 0) {
    const segmentKeys = Object.keys(segments).map(segmentKey => parseInt(segmentKey));
    segmentContract.forEach(segment => {
      if (segmentKeys.includes(segment.id)) {
        const segmentTemp = segments[segment.id];
        tempSegments[segment.id] = {
          ...segmentTemp,
          ...segment,
          segmentId: segment.id,
          titleEn: segment.titleEn ? segment.titleEn : segmentTemp.titleEn,
          titleAr: segment.titleAr ? segment.titleAr : segmentTemp.titleAr,
          customerPercentage: (
            (parseInt(segment[SEGMENT_ATTRIBUTES.CUSTOMERS_COUNT]) / parseInt(totalCustomerCount)) *
            100
          ).toFixed(0),
        };
      }
    });
    tempSegments[SEGMENT_TITLE_MAP.ALL_CUSTOMERS].customersCount = parseInt(totalCustomerCount);
  }

  return tempSegments;
};

export default fuseSegmentContractWithSegmentStatic;
