import React from 'react';
import PropTypes from 'prop-types';
import { useQuery, NetworkStatus } from '@apollo/client';
import { isEmpty } from 'ramda';

import { usePriceFormat, useSelectedStore } from 'hooks/index';
import * as translations from 'constants/translations';
import { Spinner, LoadingContainer } from 'components/kit';
import { Text } from 'components/service';
import Fallback from 'components/common/Fallback';
import { getCookie } from 'utils';
import * as paths from 'paths';
import { ReactComponent as IconPieData } from 'assets/icon-pie-data.svg';
import { ReactComponent as SkeletonBars } from 'assets/skeleton-bars.svg';
import EmptyState from 'components/common/EmptyState';
import { percentDiff, percentDir, getSalesData } from '../utils';
import * as schemas from '../schemas';
import ReportLink from '../ReportLink';
import { ANALYTICS_TAB_NAMES } from '../../constants';
import SalesBody from './SalesBody';
import SalesTab from './SalesTab';

const Sales = ({ type, date, range, header, selected, onSelect, onPrecionChange }) => {
  const formatPrice = usePriceFormat();
  const storeId = useSelectedStore();
  const query = {
    total_sales: schemas.TOTAL_SALES,
    avg_order_value: schemas.AVG_ORDER_VALUE,
    total_orders: schemas.TOTAL_ORDERS,
    total_customers: schemas.TOTAL_CUSTOMERS,
  };

  const SALES_ANALYTICS_TEXT_ELEMENTS = {
    total_sales: <Text value={translations.TOTAL_SALES} />,
    avg_order_value: <Text value={translations.AVERAGE_ORDER_VALUE} />,
    total_orders: <Text value={translations.SALES_TOTAL_ORDERS} />,
    total_customers: <Text value={translations.TOTAL_CUSTOMERS} />,
  };

  const { data, error, loading, refetch, networkStatus } = useQuery(query[selected], {
    variables: {
      storeId,
      range,
      date,
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const onTabClick = tabName => {
    onSelect(tabName.snakeCaseName);
    if (process.env.REACT_APP_SEGMENT_WRITE_KEY) {
      if (getCookie('ajs_user_id') !== '') {
        window.analytics.track(`${tabName.name} Selected`, {
          range,
        });
      }
    }
  };

  return (
    <div className="border border-gray-300 rounded-sm">
      <div className="flex overflow-x-auto">
        <SalesTab
          title={SALES_ANALYTICS_TEXT_ELEMENTS.total_sales}
          value={formatPrice(header.totalSales)}
          percent={percentDiff(header.salesPercentDiff)}
          dir={percentDir(header.salesPercentDiff)}
          isSelected={selected === ANALYTICS_TAB_NAMES.totalSales.snakeCaseName}
          onClick={() => onTabClick(ANALYTICS_TAB_NAMES.totalSales)}
        />
        <SalesTab
          title={SALES_ANALYTICS_TEXT_ELEMENTS.avg_order_value}
          value={formatPrice(header.avgOrderValue)}
          percent={percentDiff(header.avgOrderPercentDiff)}
          dir={percentDir(header.avgOrderPercentDiff)}
          isSelected={selected === ANALYTICS_TAB_NAMES.averageOrderValue.snakeCaseName}
          onClick={() => onTabClick(ANALYTICS_TAB_NAMES.averageOrderValue)}
        />
        <SalesTab
          title={SALES_ANALYTICS_TEXT_ELEMENTS.total_orders}
          value={header.totalOrders}
          percent={percentDiff(header.ordersPercentDiff)}
          dir={percentDir(header.ordersPercentDiff)}
          isSelected={selected === ANALYTICS_TAB_NAMES.totalOrders.snakeCaseName}
          onClick={() => onTabClick(ANALYTICS_TAB_NAMES.totalOrders)}
        />
        <SalesTab
          title={SALES_ANALYTICS_TEXT_ELEMENTS.total_customers}
          value={header.totalCustomers}
          percent={percentDiff(header.customersPercentDiff)}
          dir={percentDir(header.customersPercentDiff)}
          isSelected={selected === ANALYTICS_TAB_NAMES.totalCustomers.snakeCaseName}
          onClick={() => onTabClick(ANALYTICS_TAB_NAMES.totalCustomers)}
        />
      </div>
      {error ? (
        <div className="my-5 px-4 text-center">
          <Fallback refetch={refetch} />
        </div>
      ) : !data || networkStatus === NetworkStatus.refetch ? (
        <div className="my-4">
          <Spinner />
        </div>
      ) : (
        <LoadingContainer isLoading={loading}>
          {isEmpty(data) ? (
            <EmptyState
              skeletonComponent={<SkeletonBars className="w-full" />}
              iconComponent={<IconPieData />}
              emptyStateText={translations.EMPTY_BARS_CHART}
            />
          ) : (
            <SalesBody
              type={type}
              date={date}
              data={getSalesData(data)}
              selected={selected}
              selectedTitle={SALES_ANALYTICS_TEXT_ELEMENTS[selected]}
              range={range}
              onPrecionChange={onPrecionChange}
            />
          )}
        </LoadingContainer>
      )}
      <ReportLink to={paths.salesBreakdown} />
    </div>
  );
};

Sales.propTypes = {
  type: PropTypes.oneOf(['today', '7_day', '30_day', '90_day', 'all_time', 'custom']).isRequired,
  date: PropTypes.oneOf(['day', 'month']).isRequired,
  range: PropTypes.shape({ start: PropTypes.string, end: PropTypes.string }).isRequired,
  header: PropTypes.shape({
    totalSales: PropTypes.number,
    salesPercentDiff: PropTypes.string,
    avgOrderValue: PropTypes.number,
    avgOrderPercentDiff: PropTypes.string,
    totalOrders: PropTypes.number,
    ordersPercentDiff: PropTypes.string,
    totalCustomers: PropTypes.number,
    customersPercentDiff: PropTypes.string,
  }).isRequired,
  selected: PropTypes.oneOf([
    ANALYTICS_TAB_NAMES.totalSales.snakeCaseName,
    ANALYTICS_TAB_NAMES.averageOrderValue.snakeCaseName,
    ANALYTICS_TAB_NAMES.totalOrders.snakeCaseName,
    ANALYTICS_TAB_NAMES.totalCustomers.snakeCaseName,
  ]).isRequired,
  onSelect: PropTypes.func.isRequired,
  onPrecionChange: PropTypes.func.isRequired,
};

export default Sales;
