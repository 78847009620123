import { Handler } from '../types/internal';
import getClient from './client';

type FetcherArgs<T> = {
  query: string;
  variables?: string;
  clientName?: string;
  requestHeaders?: Record<string, string>;
  handler?: Handler<T>;
};

export async function fetcher<T>({
  query,
  variables = JSON.stringify({}),
  requestHeaders = {},
  clientName,
  handler = response => response as T,
}: FetcherArgs<T>): Promise<T> {
  const response = await getClient(clientName).request(query, JSON.parse(variables), {
    ...requestHeaders,
  });

  return handler(response);
}
