import logger from 'common/utils/logger';
import { Handler } from '../types/internal';
import { stringify } from './misc';
import { fetcher } from './fetch';

type MutateParams<Data> = {
  mutation: string;
  variables: unknown;
  handler?: Handler<Data>;
  clientName?: string;
  initialData?: Data;
  requestHeaders?: any;
};
// TODO check if we need default value for requestHeaders
export async function apiMutate<Data>({
  mutation,
  variables,
  handler,
  clientName,
  requestHeaders,
}: MutateParams<Data>): Promise<Data> {
  let response;
  let localError;
  try {
    response = await fetcher({
      query: mutation,
      variables: stringify(variables),
      handler,
      clientName,
      requestHeaders,
    });

    return { ...response, hasError: false };
  } catch (error) {
    const err = JSON.stringify({ ...error, requestSchema: mutation, variables }, undefined, 2);
    // TODO  :: Replace with ErrorHandler
    const parsedErr = JSON.parse(err);
    const errorResponse = parsedErr?.response;
    logger.error({
      error: errorResponse,
      extraData: { requestError: parsedErr, error: errorResponse, technicalIssue: true },
    });
    localError = { hasError: true, ...errorResponse };
    return localError;
  }
}
