import React, { useState } from "react"
import cx from "classnames"
import { useMobile } from "hooks"
import styled from "styled-components"

export default ({
  children,
  content,
  direction = "right",
  width = "w-40",
  margin = "0px",
  delay,
}) => {
  const isMobile = useMobile()
  const [active, setActive] = useState(false)
  let timeout

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true)
    }, delay || 400)
  }

  const hideTip = () => {
    clearInterval(timeout)
    setActive(false)
  }

  return (
    <TooltipWrap style={{}} onMouseEnter={showTip} onMouseLeave={hideTip}>
      {children}
      {active && (
        <Tooltip
          isMobile={isMobile}
          margin={margin}
          direction={direction}
          className={cx(
            "absolute rounded p-2 shadow z-50 bg-white whitespace-normal",
            width
          )}
        >
          {content}
        </Tooltip>
      )}
    </TooltipWrap>
  )
}

const TooltipWrap = styled.div`
  display: inline;
  position: relative;
  margin: -0.5px 0;
`
const Tooltip = styled.div`
  padding: 6px;
  font-size: 8px;
  max-width: ${props => (props.isMobile ? "7rem" : "9rem")};
  ${props => {
    return clx[props.direction](props.margin)
  }}
`

const clx = {
  top: margin => `top: calc(${margin} * -1); left: 50%;`,
  right: margin => `left: calc(100% + ${margin});
    top: 50%;
    transform: translateX(0) translateY(-50%);`,
  bottom: margin => `left: 0`,
  left: margin => `left: auto;
    right: calc(100% + ${margin});
    top: 50%;
    transform: translateX(0) translateY(-50%);`,
}
