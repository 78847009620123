import { gql } from '@apollo/client';

const webLinksSettings = gql`
  fragment WebLinkSettings on Settings {
    id
    restaurantId
    webLinks {
      links {
        id
        linkType
        titleAr
        titleEn
        url
        instagram
        facebook
        twitter
        snapchat
        phoneNumber
        smsBody
        email
      }
    }
  }
`;

export const SETTINGS = gql`
  query($storeId: String!) {
    settings(restaurantId: $storeId) {
      ...WebLinkSettings
    }
  }
  ${webLinksSettings}
`;
export const ADD_WEB_LINK = gql`
  mutation AddWebLink($storeId: String!, $link: webLinkInput!, $links: [webLinkInput]) {
    addWebLink(restaurantId: $storeId, link: $link, links: $links) {
      ...WebLinkSettings
    }
  }
  ${webLinksSettings}
`;

export const UPDATE_WEB_LINK = gql`
  mutation UpdateWebLink($storeId: String!, $link: webLinkInput!, $links: [webLinkInput]!) {
    updateWebLink(restaurantId: $storeId, link: $link, links: $links) {
      ...WebLinkSettings
    }
  }
  ${webLinksSettings}
`;

export const DELETE_WEB_LINK = gql`
  mutation DeleteWebLink($storeId: String!, $id: String!, $links: [webLinkInput]!) {
    deleteWebLink(restaurantId: $storeId, id: $id, links: $links) {
      ...WebLinkSettings
    }
  }
  ${webLinksSettings}
`;

export const UPDATE_WEB_LINKS = gql`
  mutation UpdateWebLinks($storeId: String!, $links: [webLinkInput]!) {
    updateWebLinks(restaurantId: $storeId, links: $links) {
      ...WebLinkSettings
    }
  }
  ${webLinksSettings}
`;
