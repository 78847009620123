import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Formik, Form } from 'formik';
import { isEmpty } from 'ramda';

import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { Field, Text } from 'components/service';
import { Row, Section, Label } from 'components/form/generic';
import {
  Input,
  Textarea,
  RadioGroup,
  InfoCheckbox,
  CheckboxAlt,
  DatePicker,
  TimePicker,
  Stepper,
} from 'components/form/elements';
import * as translations from 'constants/translations';
import { APPLIES_TO, VOUCHER_TYPE, MINIMUM_REQUIREMENTS } from 'pages/marketing/constants';
import { initialValues as voucherFormInitialValues, validationSchemaCreation } from './data';
import PreviewVoucher from '../PreviewVoucher';
import { formatDate, utcFormatTime } from './utils';

const VoucherForm = ({
  bottom,
  isEdit = false,
  initialValues = voucherFormInitialValues,
  onSubmit,
  isShowPreview = false,
}) => {
  const { translate, lang, direction } = useContext(localeContext);
  const { selectedStore } = useContext(userContext);

  const handleOnSubmit = data => {
    onSubmit({
      code: data.code,
      amount: data.type !== VOUCHER_TYPE.FREE_DELIVERY ? data.amount : 0.0,
      type: data.type,
      description: data.descriptionEn,
      descriptionAr: data.descriptionAr,
      isFreeDelivery: data.type === VOUCHER_TYPE.FREE_DELIVERY,
      minSubtotalAmount: data.minimumRequirements === MINIMUM_REQUIREMENTS.NONE ? 0 : data.minSubtotalAmount,
      redemptionLimit: data.redemptionLimitEnabled ? (data.redemptionLimit === '' ? 0 : data.redemptionLimit) : 0,
      redemptionLimitPerUser: data.redemptionLimitPerUserEnabled
        ? data.redemptionLimitPerUser === ''
          ? 0
          : data.redemptionLimitPerUser
        : 0,
      validFrom: formatDate(data.validFrom, data.validFromTime),
      validUntil: data.validUntilEnabled ? formatDate(data.validUntil, data.validUntilTime) : undefined,
      validTimeFrom: data.happyHour ? utcFormatTime(data.validTimeFrom, selectedStore.timeZone) : null,
      validTimeTo: data.happyHour ? utcFormatTime(data.validTimeTo, selectedStore.timeZone) : null,
      appliedTo:
        data.appliedTo === APPLIES_TO.DELIVERY || data.appliedTo === APPLIES_TO.PICKUP
          ? APPLIES_TO.DELIVERY_TYPE
          : data.appliedTo,
      deliveryType:
        data.appliedTo === APPLIES_TO.DELIVERY || data.appliedTo === APPLIES_TO.PICKUP ? data.appliedTo : undefined,
    });
  };

  return (
    <Formik
      initialValues={{
        type: VOUCHER_TYPE.PERCENTAGE,
        amount: '',
        appliedTo: APPLIES_TO.ENTIRE_ORDER,
        minimumRequirements: MINIMUM_REQUIREMENTS.NONE,
        descriptionAr: '',
        descriptionEn: '',
        happyHour: false,
        validUntilEnabled: false,
        ...voucherFormInitialValues,
        ...initialValues,
      }}
      validationSchema={!isEdit && validationSchemaCreation}
      onSubmit={handleOnSubmit}
    >
      {({ values, handleChange, setFieldValue, errors }) => (
        <Form className={cx('w-full md:w-2/3', direction === 'rtl' && 'ml-auto')}>
          {!isShowPreview ? (
            <>
              <Section title={<Text value={translations.VOUCHER_CODE} />}>
                <Row>
                  <Label subtitle={<Text value={translations.VOUCHER_CODE_CHECKOUT} />}>
                    <Field
                      type="text"
                      name="code"
                      disabled={isEdit}
                      autoFocus
                      placeholder={translate(translations.VOUCHER_CODE_PLACEHOLDER)}
                      component={Input}
                    />
                  </Label>
                </Row>
                <div>
                  <Text value={translations.DESCRIPTION} className="text-base mb-2" />
                  <Row className="flex-col md:flex-row">
                    <div className="flex flex-col md:flex-row justify-between items-center">
                      <div className={cx('w-full mb-6 md:mb-0', lang === 'en' ? 'mr-0 md:mr-4' : 'ml-0 md:ml-4')}>
                        <Label
                          title={<Text value={translations.ENGLISH} />}
                          subtitle={<Text value={translations.VOUCHER_DESC_SUBTITLE} />}
                        >
                          <Field
                            name="descriptionEn"
                            placeholder={translations.VOUCHER_DESC_PLACEHOLDER[0]}
                            component={Textarea}
                            rows="6"
                          />
                        </Label>
                      </div>
                      <div className="w-full mb-0 md:mb-0">
                        <Label
                          title={<Text value={translations.ARABIC} />}
                          subtitle={<Text value={translations.VOUCHER_DESC_SUBTITLE} />}
                        >
                          <Field
                            name="descriptionAr"
                            direction="rtl"
                            placeholder={translations.VOUCHER_DESC_PLACEHOLDER[1]}
                            component={Textarea}
                            rows="6"
                          />
                        </Label>
                      </div>
                    </div>
                  </Row>
                </div>
              </Section>
              {!isEdit && (
                <>
                  <Section title={<Text value={translations.VOUCHER_TYPE} />}>
                    <Label title={<Text value={translations.TYPE} />}>
                      <Field
                        name="type"
                        items={[
                          {
                            title: <Text value={translations.PERCENTAGE} />,
                            value: VOUCHER_TYPE.PERCENTAGE,
                          },
                          {
                            title: <Text value={translations.AMOUNT} />,
                            value: VOUCHER_TYPE.AMOUNT,
                          },
                          {
                            title: <Text value={translations.FREE_DELIVERY} />,
                            value: VOUCHER_TYPE.FREE_DELIVERY,
                          },
                        ]}
                        component={RadioGroup}
                        onChange={e => {
                          handleChange(e);
                          if (e.target.value === VOUCHER_TYPE.FREE_DELIVERY) {
                            setFieldValue('appliedTo', APPLIES_TO.ENTIRE_ORDER);
                          }
                        }}
                      />
                    </Label>
                  </Section>
                  <Section title={<Text value={translations.VOUCHER_VALUE} />}>
                    {(values.type === VOUCHER_TYPE.PERCENTAGE || values.type === VOUCHER_TYPE.AMOUNT) && (
                      <div className="max-w-sm w-full">
                        <Field
                          name="amount"
                          left={values.type === VOUCHER_TYPE.PERCENTAGE && '%'}
                          max={values.type === VOUCHER_TYPE.PERCENTAGE && 100}
                          min={1}
                          step={parseFloat((0).toFixed(selectedStore.currency.decimals - 1) + 1)}
                          placeholder={0}
                          component={Stepper}
                          validation={n => n >= 0}
                        />
                      </div>
                    )}
                    <Row>
                      <Label title={<Text value={translations.APPLIES_TO} />}>
                        <Field
                          name="appliedTo"
                          items={[
                            {
                              title: <Text value={translations.EXPORT_ALL_ORDERS} />,
                              value: APPLIES_TO.ENTIRE_ORDER,
                            },
                            ...(values.type !== VOUCHER_TYPE.FREE_DELIVERY
                              ? [
                                  {
                                    title: <Text value={translations.DELIVERY_ORDERS} />,
                                    value: APPLIES_TO.DELIVERY,
                                  },
                                  {
                                    title: <Text value={translations.PICKUP_ORDERS} />,
                                    value: APPLIES_TO.PICKUP,
                                  },
                                ]
                              : []),
                          ]}
                          component={RadioGroup}
                        />
                      </Label>
                    </Row>
                  </Section>
                </>
              )}
              <Section title={<Text value={translations.MINIMUM_REQUIREMENTS} />}>
                <Field
                  name="minimumRequirements"
                  items={[
                    {
                      title: <Text value={translations.NONE} />,
                      value: MINIMUM_REQUIREMENTS.NONE,
                    },
                    {
                      title: <Text value={translations.MINIMUM_ORDER_AMOUNT} />,
                      value: MINIMUM_REQUIREMENTS.MINIMUM_ORDER_AMOUNT,
                    },
                  ]}
                  component={RadioGroup}
                />
                {values.minimumRequirements === MINIMUM_REQUIREMENTS.MINIMUM_ORDER_AMOUNT && (
                  <div className="w-64">
                    <Field
                      name="minSubtotalAmount"
                      left={translate(selectedStore.currency)}
                      min={0}
                      step={parseFloat((0).toFixed(selectedStore.currency.decimals - 1) + 1)}
                      placeholder={0}
                      component={Stepper}
                      validation={n => n >= 0}
                    />
                  </div>
                )}
              </Section>
              <Section title={<Text value={translations.USAGE_LIMITS} />}>
                <Label title={<Text value={translations.USAGE_LIMITS_DESC} />}>
                  <div className="mt-2">
                    <Field
                      name="redemptionLimitEnabled"
                      title={<Text value={translations.USAGE_LIMITS_OPTION_1} />}
                      component={CheckboxAlt}
                    />
                    {values.redemptionLimitEnabled && (
                      <div className="mt-3 w-64 mb-6">
                        <Field
                          name="redemptionLimit"
                          min={0}
                          step={1}
                          placeholder={0}
                          component={Stepper}
                          validation={n => n >= 0}
                        />
                      </div>
                    )}
                    <div className="mt-2">
                      <Field
                        name="redemptionLimitPerUserEnabled"
                        title={<Text value={translations.USAGE_LIMITS_OPTION_2} />}
                        component={CheckboxAlt}
                      />
                      {values.redemptionLimitPerUserEnabled && (
                        <div className="mt-3 w-64">
                          <Field
                            name="redemptionLimitPerUser"
                            max={
                              values.redemptionLimitEnabled &&
                              values.redemptionLimit !== (null || 0) &&
                              values.redemptionLimit
                            }
                            min={0}
                            step={1}
                            placeholder={0}
                            component={Stepper}
                            validation={n => n >= 0}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </Label>
              </Section>
              <div className="mb-6">
                <Field
                  name="happyHour"
                  title={<Text value={translations.HAPPY_HOUR} />}
                  description={<Text value={translations.HAPPY_HOUR_DESC} />}
                  body={
                    values.happyHour && (
                      <div className="px-4 -mt-2 mb-2">
                        <Row>
                          <Label title={<Text value={translations.START_TIME} />}>
                            <Field name="validTimeFrom" component={TimePicker} />
                          </Label>
                          <Label title={<Text value={translations.END_TIME} />}>
                            <Field name="validTimeTo" component={TimePicker} />
                          </Label>
                        </Row>
                      </div>
                    )
                  }
                  component={InfoCheckbox}
                />
              </div>
              <Section title={<Text value={translations.TIME_PERIOD} />}>
                <Row>
                  <Label
                    title={<Text value={translations.START_DATE} />}
                    subtitle={
                      values.voucherRedemptionsCount > 0 && (
                        <Text value={translations.VOUCHER_START_DATE_EDIT_MESSAGE} />
                      )
                    }
                  >
                    <Field
                      name="validFrom"
                      disabled={values.voucherRedemptionsCount > 0}
                      minDate={new Date()}
                      dayClassName=""
                      component={DatePicker}
                      dateFormat={lang === 'en' ? 'MMM d, yyyy' : 'MMMM d, yyyy'}
                    />
                  </Label>
                  <Label title={<Text value={translations.START_TIME} />}>
                    <Field name="validFromTime" disabled={values.voucherRedemptionsCount > 0} component={TimePicker} />
                  </Label>
                </Row>
                <Field
                  name="validUntilEnabled"
                  title={<Text value={translations.SET_END_DATE} />}
                  component={CheckboxAlt}
                />
                {values.validUntilEnabled && (
                  <Row>
                    <Label title={<Text value={translations.END_DATE} />}>
                      <Field
                        name="validUntil"
                        minDate={new Date()}
                        dayClassName=""
                        component={DatePicker}
                        dateFormat={lang === 'en' ? 'MMM d, yyyy' : 'MMMM d, yyyy'}
                      />
                    </Label>
                    <Label title={<Text value={translations.END_TIME} />}>
                      <Field name="validUntilTime" component={TimePicker} />
                    </Label>
                  </Row>
                )}
              </Section>
            </>
          ) : (
            <>
              <PreviewVoucher values={values} />
            </>
          )}
          {bottom(isEmpty(errors))}
        </Form>
      )}
    </Formik>
  );
};

VoucherForm.propTypes = {
  bottom: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  initialValues: PropTypes.shape({
    descriptionEn: PropTypes.string,
    descriptionAr: PropTypes.string,
    minimumRequirements: PropTypes.string,
    redemptionLimitEnabled: PropTypes.bool,
    redemptionLimitPerUserEnabled: PropTypes.bool,
    happyHour: PropTypes.bool,
    validTimeFrom: PropTypes.string,
    validTimeTo: PropTypes.string,
    validFrom: PropTypes.string,
    validFromTime: PropTypes.string,
    validUntil: PropTypes.string,
    validUntilEnabled: PropTypes.bool,
    validUntilTime: PropTypes.string,
  }),
  onSubmit: PropTypes.func.isRequired,
  isShowPreview: PropTypes.bool,
};

export default VoucherForm;
