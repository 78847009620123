import { gql } from "@apollo/client"

export const CREATE_OPTION_GROUP = gql`
  mutation(
    $restaurantId: String!
    $variantIds: [Int]
    $titleEn: String
    $titleAr: String
    $minQuantity: Int
    $maxQuantity: Int
    $multipleAddOnsEnabled: Boolean
    $propertyValues: [propertyValueInput]
  ) {
    createPropertySection(
      restaurantId: $restaurantId
      variantIds: $variantIds
      titleEn: $titleEn
      titleAr: $titleAr
      minQuantity: $minQuantity
      maxQuantity: $maxQuantity
      multipleAddOnsEnabled: $multipleAddOnsEnabled
      propertyValues: $propertyValues
    ) {
      id
      variantIds
      titleEn
      titleAr
      minQuantity
      maxQuantity
      multipleAddOnsEnabled
      propertyValues {
        id
        titleEn
        titleAr
        price
        externalId
      }
      variantProperties {
        id
        minQuantity
        maxQuantity
        multipleAddOnsEnabled
      }
      menuItems {
        id
        titleEn
        titleAr
      }
    }
  }
`

export const UPDATE_OPTION_GROUP = gql`
  mutation(
    $restaurantId: String!
    $propertyId: String!
    $variantIds: [Int!]!
    $productId: String!
    $titleEn: String
    $titleAr: String
    $minQuantity: Int
    $maxQuantity: Int
    $multipleAddOnsEnabled: Boolean
    $propertyValues: [propertyValueInput]
  ) {
    updatePropertySection(
      restaurantId: $restaurantId
      propertyId: $propertyId
      variantIds: $variantIds
      productId: $productId
      titleEn: $titleEn
      titleAr: $titleAr
      minQuantity: $minQuantity
      maxQuantity: $maxQuantity
      multipleAddOnsEnabled: $multipleAddOnsEnabled
      propertyValues: $propertyValues
    ) {
      id
      variantIds
      titleEn
      titleAr
      minQuantity
      maxQuantity
      multipleAddOnsEnabled
      propertyValues {
        id
        titleEn
        titleAr
        price
        externalId
      }
    }
  }
`

export const DELETE_OPTION_GROUP_VARIANT = gql`
  mutation($restaurantId: String!, $propertyId: String!, $variantId: String!) {
    deletePropertySectionForVariant(
      restaurantId: $restaurantId
      propertyId: $propertyId
      variantId: $variantId
    ) {
      id
    }
  }
`

export const OPTION_GROUPS = gql`
  query($restaurantId: String!, $productId: String) {
    propertySections(restaurantId: $restaurantId, productId: $productId) {
      id
      variantIds
      titleEn
      titleAr
      minQuantity
      maxQuantity
      multipleAddOnsEnabled
      propertyValues {
        id
        titleEn
        titleAr
        price
        externalId
      }
      variantProperties {
        id
        minQuantity
        maxQuantity
        multipleAddOnsEnabled
      }
      menuItems {
        id
        titleEn
        titleAr
      }
    }
  }
`
export const OPTIONS = gql`
  query($restaurantId: String!, $propertySectionId: String) {
    propertyValues(
      restaurantId: $restaurantId
      propertySectionId: $propertySectionId
    ) {
      id
      titleEn
      titleAr
      price
      externalId
    }
  }
`

export const UPDATE_POSITIONS = gql`
  mutation updatePropertySectionsPositions(
    $restaurantId: String!
    $variantId: String!
    $positions: [PropertySectionPosition]!
  ) {
    updatePropertySectionsPositions(
      restaurantId: $restaurantId
      variantId: $variantId
      positions: $positions
    )
  }
`

export const UPDATE_OPTIONS_POSITIONS = gql`
  mutation updatePropertyValuesPositions(
    $restaurantId: String!
    $propertyId: String!
    $positions: [PropertyValuePosition]!
  ) {
    updatePropertyValuesPositions(
      restaurantId: $restaurantId
      propertyId: $propertyId
      positions: $positions
    )
  }
`
export const SORT_OPTIONS = gql`
  mutation(
    $restaurantId: String!
    $propertySectionId: String!
    $sort: [PropertyValuesPosition]!
  ) {
    sortOptions(
      restaurantId: $restaurantId
      propertySectionId: $propertySectionId
      sort: $sort
    ) {
      id
      titleEn
      titleAr
      price
      externalId
      availableBranchIds
      propertyId
    }
  }
`
