import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'components/kit';
import { context as notificationsContext } from 'context/notifications';

const ActionButton = ({ testId, children, ...props }) => {
  const notifications = useContext(notificationsContext);

  return (
    <Button
      {...props}
      type="button"
      data-testid={testId}
      kind="tertiary"
      weight="thin"
      size="base"
      textColor="text-primary-base"
      onClick={async (...args) => {
        if (!props.onClick) return;

        try {
          await props.onClick(...args);
        } catch (err) {
          if (!err.graphQLErrors) throw err;

          Object.keys(err.graphQLErrors[0].extensions.exception.body).forEach(error => {
            const value = err.graphQLErrors[0].extensions.exception.body[error];

            notifications.show(value[0], 'error');
          });
        }
      }}
    >
      {children}
    </Button>
  );
};

ActionButton.propTypes = {
  testId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};

ActionButton.defaultProps = {
  onClick: () => {
    // Do Nothing
  },
};

export default ActionButton;
