import React, { useContext } from "react"
import { path } from "ramda"
import { Formik } from "formik"
import { context as notificationsContext } from "context/notifications"
import { Text } from "components/service"
import { validationSchema } from "components/common/team/UserForm/data"
import * as translations from "constants/translations"

export default ({
  initialValues = { role: "owner", branches: [] },
  children,
  onSubmit,
}) => {
  const notifications = useContext(notificationsContext)

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async data => {
        if (!data.name.trim()) {
          return notifications.show(
            <Text value={translations.NAME_SHOULD_CONTAIN_LETTERS} />,
            "error"
          )
        }

        const variables = {
          name: data.name,
          email: data.email,
          phoneNumber: data.phoneNumber,
        }

        try {
          await onSubmit(variables)
          window.location.reload()
        } catch (err) {
          const errors = path(
            ["graphQLErrors", 0, "extensions", "exception", "body"],
            err
          )

          if (errors.phone_number) {
            notifications.show(
              <Text value={translations.PHONE_IS_INVALID} />,
              "error"
            )
          }
        }
      }}
    >
      {children}
    </Formik>
  )
}
