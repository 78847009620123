import React from "react"
import { Field, Text } from "components/service"
import * as translations from "constants/translations"
import { Input } from "components/form/elements"
import { Label, Section } from "components/form/generic"

export default ({ lang, values, close, open, setFieldValue }) => {
  return (
    <Section title={<Text value={translations.INTEGRATION_INFO} />}>
      <Label title={<Text value={translations.MERCHANT_ID} />}>
        <Field
          type="text"
          name="merchantId"
          placeholder={
            translations.MERCHANT_ID_PLACEHOLDER[lang === "en" ? 0 : 1]
          }
          component={Input}
          data-testid="payment-register-merchantId"
        />
      </Label>

      <Label title={<Text value={translations.API_KEY} />}>
        <Field
          type="text"
          name="apiKey"
          placeholder={
            translations.API_KEY_PLACEHOLDER[lang === "en" ? 0 : 1]
          }
          component={Input}
          data-testid="payment-register-apiKey"
        />
      </Label>
        
      <Label title={<Text value={translations.IFRAME_ID} />}>
        <Field
          type="text"
          name="iframeId"
          placeholder={
            translations.IFRAME_ID_PLACEHOLDER[lang === "en" ? 0 : 1]
          }
          component={Input}
          data-testid="payment-register-iframeId"
        />
      </Label>

      <Label title={<Text value={translations.CARD_INTEGRATION_ID} />}>
        <Field
          type="text"
          name="cardIntegrationId"
          placeholder={
            translations.CARD_INTEGRATION_ID_PLACEHOLDER[lang === "en" ? 0 : 1]
          }
          component={Input}
          data-testid="payment-register-cardIntegrationId"
        />
      </Label>
    </Section>
  )
}
