import React, { useState, useEffect, useRef, useContext } from "react"
import { useQuery, useMutation } from "@apollo/client"
import { useApolloClient } from "@apollo/client"
import cx from "classnames"
import { navigate } from "@reach/router"

import { Text } from "components/service"
import { Stack, Button, Modal, DropDown, Spinner, More } from "components/kit"
import { Input } from "components/form/elements"
import { Layout, Breadcrumbs } from "components/common/dashboard"
import AddCategory from "./AddCategory"
import Products from "./Items/Products"
import { breadcrumbs } from "constants/translations"
import * as schemas from "./schemas"
import * as translations from "constants/translations"
import * as paths from "paths.js"
import { exportCatalog } from "rest.js"
import { context as userContext } from "context/user"
import { context as localeContext } from "context/locale"
import { context as notificationsContext } from "context/notifications"
import {
  useSelectedStore,
  useMobile,
  useReplaceParams,
  useQueryState,
} from "hooks"
import { ReactComponent as SearchIcon } from "assets/search.svg"
import { withBackParam } from "utils/history"
import ImportCatalog from "./ImportCatalog"
import EmptyState from "./EmptyState"
import ZeroResults from "./ZeroResults"
import SortCatalog from "./SortCatalog"
import Categories from "./Categories"
import * as utils from "./utils"
import CatalogBox from "components/kit/CatalogBox/index"
import ImportFromFoodicsModal from "pages/menu/overview/List/ImportFromFoodicsModal"

export default () => {
  const client = useApolloClient()
  const { lang, translate, direction } = useContext(localeContext)
  const notifications = useContext(notificationsContext)
  const isMobile = useMobile()
  const replace = useReplaceParams()
  const {
    branches,
    isPosCourier,
    pos,
  } = useContext(userContext)
  const posName = lang === 'en' ? pos?.courierDetails?.name : pos?.courierDetails?.nameAr ;

  const prodcutsLang = [
    { id: "en", title: "English" },
    { id: "ar", title: "Arabic" },
  ]
  const storeId = useSelectedStore()
  const [products, setProducts] = useState([])
  const [categories, setCategories] = useState([])
  const [langSelected, setLangSelected] = useState("en")

  const [query, setQuery] = useQueryState(
    {
      page: 1,
      location: "all",
      categoryId: "all",
      viewIn: "en",
    },
    {
      page: parseInt,
    }
  )

  const [selectedCategory, setSelectedCategory] = useState({})
  const searchTimeout = useRef()
  const [isCategoryChange, setIsCategoryChange] = useState(false)
  const LIMIT = 10

  const { data: productsRes, loading, refetch } = useQuery(schemas.PRODUCTS, {
    variables: {
      storeId,
      businessLocationId: query.location === "all" ? null : query.location,
      categoryId: query.categoryId === "all" ? null : query.categoryId,
      productTitle: query.search ? query.search : null,
      limit: LIMIT,
      page: query.page,
      lang: langSelected === "en" ? "english" : "arabic",
    },
    fetchPolicy: "cache-and-network",
  })

  const {
    data: categoriesRes,
    loading: loadingCat,
    refetch: refetchCat,
  } = useQuery(schemas.CATEGORIES, {
    variables: {
      storeId,
    },
    fetchPolicy: "cache-and-network",
  })

  const { data: setting, loading: loadingSetting } = useQuery(
    schemas.SETTINGS,
    {
      variables: {
        storeId,
      },
    }
  )

  const [updateSortingProduct, { loadingSortingProduct }] = useMutation(
    schemas.UPDATE_SORTING_PRODUCT
  )

  useEffect(() => {
    !loadingCat &&
      categoriesRes &&
      categoriesRes.menuSections &&
      setCategories(categoriesRes.menuSections.menuSections)
  }, [isCategoryChange, categoriesRes])

  useEffect(() => {
    const selectedCategory = categories.find(
      ({ id }) => id === query.categoryId
    )
    !loading &&
      productsRes &&
      productsRes.menuItems &&
      setProducts(productsRes.menuItems.menuItems)

    setSelectedCategory(selectedCategory)
    setLangSelected(query.viewIn)
  }, [productsRes, loading, query, categories])


  const handleCurrentPageChange = page => setQuery({ page })
  const handleProductChange = () => setProducts([])

  const branchesData =
    branches === null
      ? [
          {
            id: "all",
            title: isMobile ? (
              <Text value={translations.BRANCHES_ALL} />
            ) : (
              <Text value={translations.ALL} />
            ),
          },
        ]
      : [
          {
            id: "all",
            titleEn: isMobile
              ? translations.BRANCHES_ALL[0]
              : translations.ALL[0],
            titleAr: isMobile
              ? translations.BRANCHES_ALL[1]
              : translations.ALL[1],
          },
          ...branches,
        ].map(branch => ({
          id: branch.id,
          title: <Text value={branch} />,
        }))

  const categoriesData = [
    {
      id: "all",
      titleEn: isMobile ? translations.ALL_CATEGORIES[0] : translations.ALL[0],
      titleAr: isMobile ? translations.ALL_CATEGORIES[1] : translations.ALL[1],
    },
    ...categories,
  ].map(category => ({
    id: category.id,
    title: <Text value={category} />,
  }))

    



  return (
    <Modal>
      {({ open, close }) => {
        return (!loadingCat &&
          categoriesRes &&
          categoriesRes.menuSections &&
          !categoriesRes.menuSections.menuSections.length &&
          query.categoryId === "all") ||
          branches === null ? (
          <Layout
            top={
              <Breadcrumbs
                links={[paths.productsCatalog]}
                path={breadcrumbs.SETUP_PRODUCTS_CATALOG}
              />
            }
          >
            <div className="flex justify-center m-3">
              <EmptyState
                refetchProduct={refetch}
                refetchCat={refetchCat}
                setSelectedCat={categoryId => setQuery({ categoryId })}
              />
            </div>
          </Layout>
        ) : (
          <Layout
            top={
              <Breadcrumbs
                links={[paths.products, paths.productsCatalog]}
                path={breadcrumbs.SETUP_PRODUCTS_CATALOG}
                right={
                  <div style={{ direction }}>
                    <Stack expandItem={false} direction="wrap">
                      <Button
                        data-testid="add-product-btn"
                        kind="primary"
                        size="baseGroup"
                        className="w-40"
                        disabled={loading}
                        style={{
                          direction,
                        }}
                        onClick={() => {
                          if (branches === null) {
                            notifications.show(
                              <Text
                                value={
                                  translations.YOU_HAVE_ADD_BUSINESS_LOCATION_FIRST
                                }
                              />,
                              "info"
                            )
                          } else {
                            navigate(
                              replace(paths.baseProductCreation) +
                                `&${withBackParam()}`
                            )
                          }
                        }}
                      >
                        <Text
                          value={translations.ADD_PRODUCT}
                          className="font-semibold"
                        />
                      </Button>
                      <Button
                        data-testid="list-products-add-category-btn"
                        onClick={() => {
                          if (branches === null) {
                            notifications.show(
                              <Text
                                value={
                                  translations.YOU_HAVE_ADD_BUSINESS_LOCATION_FIRST
                                }
                              />,
                              "info"
                            )
                          } else {
                            open({
                              testId: "list-products-add-category",
                              title: (
                                <Text
                                  value={translations.ADD_CATEGORY}
                                  className="text-lg"
                                />
                              ),
                              body: (
                                <AddCategory
                                  onCategoryChange={() =>
                                    setIsCategoryChange(true)
                                  }
                                  type="add"
                                  initialValues={{}}
                                  onCancel={close}
                                  refetch={refetchCat}
                                  setSelectedCat={categoryId =>
                                    setQuery({ categoryId })
                                  }
                                />
                              ),
                            })
                          }
                        }}
                        size="baseGroup"
                        kind="secondary"
                        className="W-40"
                        style={{
                          direction,
                        }}
                      >
                        <Text
                          value={translations.ADD_CATEGORY}
                          className="font-semibold"
                        />
                      </Button>
                      <More
                        testIdBtn="list-products-more-btn"
                        size={"option"}
                        items={[
                          {
                            testId: "list-products-import-catalog-btn",
                            title: <Text value={translations.IMPORT_CATALOG} />,
                            onClick: () => {
                              if (branches === null) {
                                notifications.show(
                                  <Text
                                    value={
                                      translations.YOU_HAVE_ADD_BUSINESS_LOCATION_FIRST
                                    }
                                  />,
                                  "info"
                                )
                              } else {
                                open({
                                  testId: "list-products-import-catalog-modal",
                                  title: (
                                    <Text value={translations.IMPORT_CATALOG} />
                                  ),
                                  body: (
                                    <ImportCatalog
                                      onCancel={close}
                                      refetchProduct={refetch}
                                      refetchCat={refetchCat}
                                    />
                                  ),
                                })
                              }
                            },
                          },
                          {
                            testId: "export-catalog-btn",
                            title: <Text value={translations.EXPORT_CATALOG} />,
                            onClick: () =>{
                              exportCatalog(storeId).then(() => {
                                notifications.show(
                                  <Text
                                    value={translations.EXPORT_CATALOG_MSG}
                                  />,
                                  "success"
                                )
                              })
                            }
                          },
                          (isPosCourier && pos?.isMenuPull &&  {
                            testId: "list-products-import-catalog-from-foodics-btn",
                            title: <Text value={translations.IMPORT_CATALOG_FROM_POS(posName)} />,
                            onClick: () => {
                              if (branches === null) {
                                notifications.show(
                                  <Text
                                    value={
                                      translations.YOU_HAVE_ADD_BUSINESS_LOCATION_FIRST
                                    }
                                  />,
                                  "info"
                                )
                              } else {
                                open({
                                  testId: "list-products-sort-catalog-import-foodics-modal",
                                  title: (
                                    <Text value={translations.IMPORT_CATALOG_FROM_POS(posName)} />
                                  ),
                                  body: (
                                    <ImportFromFoodicsModal onCancel={close}/> 
                                  ),
                                })
                              }
                            },
                          }),
                          {
                            type: "separator",
                          },
                          {
                            testId: "list-products-sort-catalog-btn",
                            title: <Text value={translations.SORT_PRODUCT} />,
                            onClick: () => {
                              if (branches === null) {
                                notifications.show(
                                  <Text
                                    value={
                                      translations.YOU_HAVE_ADD_BUSINESS_LOCATION_FIRST
                                    }
                                  />,
                                  "info"
                                )
                              } else {
                                open({
                                  testId: "list-products-sort-catalog-modal",
                                  title: (
                                    <Text
                                      value={translations.SORT_PRODUCT_TITLE}
                                    />
                                  ),
                                  body: (
                                    <SortCatalog
                                      onCancel={close}
                                      refetchProduct={refetch}
                                      updateSortingProduct={
                                        updateSortingProduct
                                      }
                                      loading={loadingSortingProduct}
                                      setting={setting && setting}
                                      langSelected={langSelected}
                                    />
                                  ),
                                })
                              }
                            },
                          },
                        ].filter(item => item)}
                      />
                    </Stack>
                  </div>
                }
              />
            }
          >
            <div>
              <div className="my-2">
                <div
                  className={cx(
                    "flex flex-wrap justify-between mx-3 border-gray-300",
                    lang === "ar" && "flex-row-reverse"
                  )}
                >
                  <div className="md:hidden w-full">
                    <div className={cx("my-2 h-10 w-full")}>
                      <Input
                        data-testid="search-products"
                        type="text"
                        icon={<SearchIcon />}
                        placeholder={
                          lang === "en"
                            ? translations.SEARCH_PRODUCT[0]
                            : translations.SEARCH_PRODUCT[1]
                        }
                        search
                        initialValue={query.search}
                        onChange={e => {
                          clearTimeout(searchTimeout.current)
                          const { value } = e.target
                          searchTimeout.current = setTimeout(() => {
                            setQuery({ page: 1, search: value })
                          }, 500)
                        }}
                      />
                    </div>
                  </div>
                  <div className={cx("w-full md:w-auto my-2")}>
                    <DropDown
                      testId_openDropDown="list-products-select-business-location"
                      testId_selectedData="list-products-business-location-name"
                      testId_listData="list-products-business-location-list"
                      float={"left"}
                      label={<Text value={translations.BUSINESS_LOCATION} />}
                      optionSelected={query.location}
                      onSelect={option => setQuery({ location: option })}
                      data={branchesData}
                      icon="keyboard_arrow_down"
                      position={lang === "ar" ? "right" : "left"}
                      scrollable
                      wFull={true}
                      responsiveLabel
                    />
                  </div>
                  <div className="flex items-center w-full md:w-auto">
                    <div className="md:hidden w-1/2">
                      <DropDown
                        testId_openDropDown="list-products-select-category"
                        testId_selectedData="list-products-category-name"
                        testId_listData="list-products-category-list"
                        float={"left"}
                        label={<Text value={translations.CATEGORY} />}
                        optionSelected={query.categoryId}
                        onSelect={categoryId => {
                          setQuery({ page: 1, categoryId })
                        }}
                        data={categoriesData}
                        icon="keyboard_arrow_down"
                        position={lang === "ar" ? "right" : "left"}
                        scrollable
                        wFull={true}
                        responsiveLabel
                      />
                    </div>
                    <div className={cx("my-2 w-1/2 md:w-auto ml-4")}>
                      <DropDown
                        testId_openDropDown="list-products-select-view-lang"
                        testId_selectedData="list-products-view-lang-name"
                        testId_listData="list-products-view-lang-list"
                        float={lang === "ar" ? "left" : "right"}
                        label={<Text value={translations.VIEW_IN} />}
                        responsiveLabel
                        full
                        optionSelected={langSelected}
                        onSelect={prodcutsLang => {
                          setLangSelected(prodcutsLang)
                          setQuery({ viewIn: prodcutsLang })
                        }}
                        data={prodcutsLang}
                        icon="keyboard_arrow_down"
                        wFull={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <Categories
                selectedCategoryId={query.categoryId}
                selectedBranchId={query.location}
                langSelected={langSelected}
                onSelect={id => setQuery({ categoryId: id, page: 1 })}
                setQuery={setQuery}
                onSort={items =>
                  utils.updateCategories(
                    client,
                    schemas.CATEGORIES,
                    { storeId },
                    items
                  )
                }
                items={categoriesRes?.menuSections?.menuSections}
              >
                <CatalogBox
                  title={
                    query.categoryId !== "all" ? (
                      selectedCategory ? (
                        lang === "en" ? (
                          selectedCategory.titleEn
                        ) : (
                          selectedCategory.titleAr
                        )
                      ) : (
                        ""
                      )
                    ) : (
                      <Text value={translations.ALL_CATEGORIES} />
                    )
                  }
                  subtitle={
                    !loading &&
                    !loadingCat &&
                    `${
                      query.categoryId !== "all"
                        ? selectedCategory?.menuItemsCount
                        : productsRes?.menuItems?.totalCount
                    } ${translate(translations.PRODUCTS, lang)}`
                  }
                  action={
                    selectedCategory && (
                      <Button
                        data-testid="edit-category-btn"
                        kind="secondary"
                        onClick={() => {
                          if (branches === null) {
                            notifications.show(
                              <Text
                                value={
                                  translations.YOU_HAVE_ADD_BUSINESS_LOCATION_FIRST
                                }
                              />,
                              "info"
                            )
                          } else {
                            open({
                              testId: "edit-category-modal",
                              title: (
                                <Text
                                  value={translations.EDIT_CATEGORY}
                                  className="text-lg"
                                />
                              ),
                              body: (
                                <AddCategory
                                  openModal={open}
                                  type="edit"
                                  initialValues={{
                                    ...selectedCategory,
                                  }}
                                  onCancel={close}
                                  refetch={refetchCat}
                                  refetchProduct={refetch}
                                  setSelectedCat={categoryId =>
                                    setQuery({ categoryId })
                                  }
                                />
                              ),
                            })
                          }
                        }}
                      >
                        <Text value={translations.EDIT_CATEGORY} />
                      </Button>
                    )
                  }
                  category
                  body={
                    <>
                      {!isMobile && (
                        <div
                          className="flex justify-between items-center px-6 py-4"
                          style={{ direction }}
                        >
                          <div className="w-2/3">
                            <Input
                              data-testid="search-products"
                              type="text"
                              icon={<SearchIcon />}
                              placeholder={
                                lang === "en"
                                  ? translations.SEARCH_PRODUCT[0]
                                  : translations.SEARCH_PRODUCT[1]
                              }
                              search
                              initialValue={query.search}
                              onChange={e => {
                                clearTimeout(searchTimeout.current)
                                const { value } = e.target
                                searchTimeout.current = setTimeout(() => {
                                  setQuery({ page: 1, search: value })
                                }, 500)
                              }}
                            />
                          </div>
                        </div>
                      )}

                      {loading || loadingSetting ? (
                        <Spinner />
                      ) : (
                        <div>
                          {!loading && !products.length ? (
                            <div className="flex justify-center m-3">
                              <ZeroResults />
                            </div>
                          ) : (
                            <div className="px-0">
                              <Products
                                langSelected={langSelected}
                                selectedLocation={
                                  query.location === "all" ? "" : query.location
                                }
                                products={products}
                                currentPage={query.page}
                                totalCount={
                                  !loading &&
                                  productsRes &&
                                  productsRes.menuItems &&
                                  productsRes.menuItems.totalCount
                                }
                                refetchProduct={refetch}
                                onCurrentPageChange={handleCurrentPageChange}
                                onProductChange={handleProductChange}
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  }
                />
              </Categories>
            </div>
          </Layout>
        )
      }}
    </Modal>
  )
}
