/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { BoxLegendSvg } from '@nivo/legends';

const BarLegend = ({ height, legends, width }) => (
  <>
    {legends.map(legend => (
      <BoxLegendSvg
        key={JSON.stringify(legend.data.map(({ id }) => id))}
        {...legend}
        containerHeight={height}
        containerWidth={width}
      />
    ))}
  </>
);

BarLegend.propTypes = {
  height: PropTypes.number.isRequired,
  legends: PropTypes.arrayOf(PropTypes.object).isRequired,
  width: PropTypes.number.isRequired,
};

export default BarLegend;
