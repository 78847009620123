import React from 'react';
import PropTypes from 'prop-types';

import CustomerInfo from './CustomerNumber/CustomerInfo';
import CustomerAddress from './CustomerLocation/CustomerAddress';
import StorefrontWebView from './StorefrontWebView';
import CreateCustomer from './CustomerNumber/CreateCustomer';
import CustomerDeliveryType from './CustomerLocation/CustomerDeliveryType';

const SlideOverWizard = props => {
  const SLIDE_OVER_COMPONENTS_TYPES = {
    customerInfo: CustomerInfo,
    createCustomer: CreateCustomer,
    customerAddress: CustomerAddress,
    customerDeliveryType: CustomerDeliveryType,
    sfWebview: StorefrontWebView,
  };

  const { slideOverComponent } = props;

  if (slideOverComponent && slideOverComponent.component !== null) {
    const RenderedComponent = SLIDE_OVER_COMPONENTS_TYPES[slideOverComponent.component];
    return (
      <div>
        <RenderedComponent {...props} />
      </div>
    );
  }
  return <div>No items</div>;
};

SlideOverWizard.propTypes = {
  slideOverComponent: PropTypes.element.isRequired,
};

export default SlideOverWizard;
