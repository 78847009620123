import React, { useContext } from 'react';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { parse } from 'query-string';
import cx from 'classnames';
import numeral from 'numeral';

import { breadcrumbs } from 'constants/translations';
import { useQueryState } from 'hooks';
import { Layout, Breadcrumbs } from 'components/common/dashboard';
import { Table, TableFooter } from 'components/form/elements';
import { Text } from 'components/service';
import { Spinner } from 'components/kit';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { context as notificationsContext } from 'context/notifications';
import DATE_RANGES from 'constants/date';
import * as translations from 'constants/translations';
import { DateRange, Period } from 'components/common/analytics';
import { parseRange, stringifyRange, toRangeVariable, getRange } from 'utils/date';
import { getCookie } from 'utils';
import { SALES_BREAKDOWN_TABLE_KEYS } from 'constants/salesReport';
import { exportReport } from '../../../../rest';
import SALES_BREAKDOWN from './schemas';
import momentLanguage, { formatData, customSort } from '../../utils';

const SalesBreakdown = () => {
  const { lang, direction } = useContext(localeContext);
  const { selectedStore } = useContext(userContext);
  const notifications = useContext(notificationsContext);
  const { type_date: typeDate, date, range } = parse(document.location.search);
  const resettableStates = { page: 1 };
  const [query, setQuery] = useQueryState(
    {
      type_date: typeDate || DATE_RANGES.THIRTY_DAYS,
      date: date || 'day',
      ...resettableStates,
      range: range || stringifyRange(getRange(DATE_RANGES.THIRTY_DAYS)),
      sort: '',
    },
    {
      page: parseInt,
    },
  );

  moment.locale(momentLanguage(lang));

  const parsedRange = parseRange(query.range);
  const rangeVariable = toRangeVariable(parsedRange);
  const LIMIT = 30;
  const { data, loading } = useQuery(SALES_BREAKDOWN, {
    variables: {
      storeId: selectedStore.id,
      range: rangeVariable,
      date: query.date,
      page: query.page,
      limit: LIMIT,
      sort: query.sort,
    },
  });
  const listData = data?.salesBreakdown?.data?.map(item => ({
    ...item,
    date: item.date,
    totalSales: numeral(item.totalSales).format(`0,0[.]${'0'.repeat(selectedStore.currency.decimals)}`),
    avgOrderValue: numeral(item.avgOrderValue).format(`0,0[.]${'0'.repeat(selectedStore.currency.decimals)}`),
  }));

  const onClickExport = () => {
    if (process.env.REACT_APP_SEGMENT_WRITE_KEY) {
      if (getCookie('ajs_user_id') !== '') {
        window.analytics.track('Sales Breakdown Export Clicked', {
          range: { start: parsedRange[0], end: parsedRange[1] },
        });
      }
    }
    window.location.href = exportReport(selectedStore.id, parsedRange[0], parsedRange[1], query.date);
  };

  const customSortFn = key => customSort(key, query, setQuery);

  const formatDataFn = values => formatData(values, selectedStore, query);
  return (
    <Layout
      top={
        <Breadcrumbs
          path={breadcrumbs.SALES_BREAKDOWN}
          right={
            <DateRange
              queryTypeDate={query.type_date}
              range={parsedRange}
              query={query}
              setQuery={setQuery}
              selectedStoreCreatedAt={selectedStore.createdAt}
              direction={direction}
              notifications={notifications}
              resettableStates={resettableStates}
            />
          }
        />
      }
    >
      {loading ? (
        <Spinner />
      ) : (
        <div>
          <div className="pb-32 flex flex-col">
            <div>
              <div className={cx('mb-4 flex justify-between items-center', lang === 'en' ? '' : 'flex-row-reverse')}>
                <Text value={translations.SALES_BREAKDOWN} className="text-lg font-bold" />
                <Period value={query.date} onChange={value => setQuery({ date: value, page: 1 })} />
              </div>
            </div>

            <div className="w-full">
              <Table
                dataHeader={[
                  {
                    key: SALES_BREAKDOWN_TABLE_KEYS.DATE,
                    name: (
                      <Text value={translations.DATE} className={cx(lang === 'en' ? 'float-left' : 'float-right')} />
                    ),
                    inView: false,
                    sort: true,
                  },
                  {
                    key: SALES_BREAKDOWN_TABLE_KEYS.TOTAL_SALES,
                    name: <Text value={translations.TOTAL_SALES} />,
                    inView: false,
                    sort: true,
                  },
                  {
                    key: SALES_BREAKDOWN_TABLE_KEYS.AVG_ORDER_VALUE,
                    name: <Text value={translations.AVERAGE_ORDER_VALUE} />,
                    inView: false,
                    sort: true,
                  },
                  {
                    key: SALES_BREAKDOWN_TABLE_KEYS.TOTAL_ORDERS,
                    name: <Text value={translations.TOTAL_ORDERS} />,
                    inView: false,
                    sort: true,
                  },
                  {
                    key: SALES_BREAKDOWN_TABLE_KEYS.TOTAL_CUSTOMERS,
                    name: <Text value={translations.TOTAL_CUSTOMERS} />,
                    inView: false,
                    sort: true,
                  },
                ]}
                customSort={customSortFn}
                dataBody={listData}
                formatData={formatDataFn}
                footer={
                  <TableFooter
                    totalRecords={data?.salesBreakdown?.totalCount}
                    pageLimit={LIMIT}
                    currentPage={query.page}
                    onPageChanged={({ currentPage }) => setQuery({ page: currentPage })}
                    totalCount={listData?.length}
                    exportLink={onClickExport}
                  />
                }
              />
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default SalesBreakdown;
