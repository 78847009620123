import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import { COUNTRY, CITY_GOVERNORATE, ALL_CITIES } from 'constants/translations';
import { context as localeContext } from 'context/locale';
import { Label } from 'components/form/generic';
import { DropDown, Spinner } from 'components/kit';
import { Text } from 'components/service';
import { useFetchCitiesList, useFetchCountriesList } from 'service/hooks';
import { allCitiesUtils, suggestedZonesTypeUtils } from '../../utils';

const TemplatesFilter = ({ zoneController, setZoneController, setZonesCollection, setDzTemplatesParams }) => {
  const { translate, direction } = useContext(localeContext);

  const [localCities, setLocalCities] = useState([]);

  useEffect(() => {
    setZoneController(prevState => ({
      ...prevState,
      [suggestedZonesTypeUtils.AREAS]: true,
      [suggestedZonesTypeUtils.SUGGESTED]: false,
    }));
  }, [zoneController.cityId, zoneController.countryId]);

  // - countries
  const { data: countries, isLoading: countriesLoading } = useFetchCountriesList();
  const { data: cities, isLoading: citiesLoading } = useFetchCitiesList({ countryId: zoneController.countryId });

  useEffect(() => {
    if (!citiesLoading) {
      const convertedCities = cities.map(city => ({
        ...city,
        title: <Text value={[city.titleEn, city.titleAr]} />,
      }));
      setLocalCities([
        {
          id: allCitiesUtils.id,
          code: allCitiesUtils.code,
          title: <Text value={ALL_CITIES} />,
          titleEn: ALL_CITIES[0],
          titleAr: ALL_CITIES[1],
        },
        ...convertedCities,
      ]);
    }
  }, [citiesLoading, cities]);

  const resetZonesCollection = (optionId, code) => {
    if (zoneController[code] !== optionId) {
      setZonesCollection({ type: 'FeatureCollection', features: [] });
      setDzTemplatesParams(prevState => ({ ...prevState, currentPage: 1, totalPages: null }));
    }
  };

  return (
    <>
      <div className="mt-3 border-t border-gray-300 pt-3" />
      {countriesLoading || citiesLoading ? (
        <Spinner />
      ) : (
        <div className="flex justify-between" style={{ direction }}>
          <div className={cx('w-1/2', direction === 'ltr' ? 'mr-4' : 'ml-4')}>
            <Label title={<Text value={COUNTRY} />}>
              <DropDown
                onSelect={option => {
                  resetZonesCollection(option, 'countryId');
                  setZoneController(prevState => ({
                    ...prevState,
                    countryId: option,
                    countryCode: countries.find(country => country.id === option).code,
                  }));
                }}
                optionSelected={zoneController.countryId}
                placeholder={<Text value={COUNTRY} className="text-sm text-gray-500" />}
                data={countries.map(country => ({
                  ...country,
                  title: <Text value={[country.titleEn, country.titleAr]} />,
                }))}
                icon="keyboard_arrow_down"
                full
                scrollable
                className="w-full"
              />
            </Label>
          </div>
          <div className="w-1/2">
            <Label title={<Text value={CITY_GOVERNORATE} />}>
              <DropDown
                onSelect={option => {
                  setZoneController(prevState => ({
                    ...prevState,
                    cityId: option,
                    cityCode: localCities.find(country => country.id === option).code,
                    cityTitle: translate(localCities.find(country => country.id === option)),
                  }));
                  resetZonesCollection(option, 'cityId');
                }}
                optionSelected={zoneController.cityId}
                placeholder={<Text value={CITY_GOVERNORATE} className="text-sm text-gray-500" />}
                data={localCities}
                icon="keyboard_arrow_down"
                full
                scrollable
                className="w-full"
              />
            </Label>
          </div>
        </div>
      )}
    </>
  );
};

TemplatesFilter.propTypes = {
  setZoneController: PropTypes.func.isRequired,
  zoneController: PropTypes.shape({
    cityId: PropTypes.string,
    countryId: PropTypes.string,
    countryCode: PropTypes.string,
    cityCode: PropTypes.string,
  }).isRequired,
  setZonesCollection: PropTypes.func.isRequired,
  setDzTemplatesParams: PropTypes.func.isRequired,
};

export default TemplatesFilter;
