import React, { useContext } from 'react';
import { Typography, useTheme } from '@zydalabs/zac-react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { Text } from 'components/service';
import { context as userContext } from 'context/user';
import * as translations from 'constants/translations';

const LogoShapeSelector = ({ logoShape, setFieldValue }) => {
  const { colors } = useTheme();
  const { selectedStore } = useContext(userContext);

  const roundedLogo = selectedStore?.logoUrl ? (
    <img src={selectedStore?.logoUrl} className="rounded-full w-16 h-16" alt="store url" />
  ) : (
    <div className="rounded-full w-16 h-16" style={{ background: colors.positive.quaternary }} />
  );

  const sharpedLogo = selectedStore?.logoUrl ? (
    <img src={selectedStore?.logoUrl} className="w-16 h-16" alt="store url" />
  ) : (
    <div className="rounded-none w-16 h-16" style={{ background: colors.positive.quaternary }} />
  );
  return (
    <div className="border-b border-gray-300 pb-6">
      <Typography variant="heading16" mt={24}>
        <Text value={translations.LOGO} />
      </Typography>
      <Typography variant="body14" mt={4} color={colors.positive.secondary} mb={24}>
        <Text value={translations.SELECT_LOGO} />
      </Typography>
      <div className="flex gap-6 flex-wrap xl:flex-nowrap">
        <div
          className={cx(
            'col-span-4 py-4 px-6 cursor-pointer border rounded w-56',
            logoShape ? 'border-blue-500' : 'border-gray-300',
          )}
          role="presentation"
          onClick={() => setFieldValue('isLogoRounded', true)}
        >
          <Typography cursor="pointer" color={logoShape ? colors.accent.primary : colors.positive.primary} mb={16}>
            <Text value={translations.CIRCLE} />
          </Typography>
          {roundedLogo}
        </div>
        <div
          className={cx(
            'col-span-4 py-4 px-6 cursor-pointer border rounded w-56',
            !logoShape ? 'border-blue-500' : 'border-gray-300',
          )}
          role="presentation"
          onClick={() => setFieldValue('isLogoRounded', false)}
        >
          <Typography cursor="pointer" color={!logoShape ? colors.accent.primary : colors.positive.primary} mb={16}>
            <Text value={translations.SHARP} />
          </Typography>
          {sharpedLogo}
        </div>
      </div>
    </div>
  );
};

LogoShapeSelector.propTypes = {
  logoShape: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};
export default LogoShapeSelector;
