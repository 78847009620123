import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { context as userContext } from 'context/user';
import { Button } from 'components/kit';
import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { RESTAURANT_COURIER_AUTHENTICATION_TYPE } from 'constants/restaurant';
import { POS, PARTNER, COURIER } from 'constants/integrations';

const { REACT_APP_API_GATEWAY_URL } = process.env;

const SubmitButton = ({
  onClick,
  courier,
  courierType,
  isLoading,
  isDisabled,
  authorizeCourierLoading,
  authorizeCourierBranchesLoading,
}) => {
  const { selectedStore, isCustomerSupport, user } = useContext(userContext);

  const {
    integrationData: { csOnly },
  } = courier;

  const submitButton = () => {
    const partnerType = courierType === POS ? PARTNER : COURIER;
    if (courier.authenticationType === RESTAURANT_COURIER_AUTHENTICATION_TYPE.OAUTH2) {
      return (
        ((isCustomerSupport(user) && csOnly) || !csOnly) && (
          <a
            onClick={e => onClick(e)}
            href={`${REACT_APP_API_GATEWAY_URL}/v1/${courierType}/oauth/authorize?${partnerType}=${courier.name}&restaurant_id=${selectedStore.id}`}
          >
            <Button kind="primary">
              <Text value={translations.CONNECT_NOW} />
            </Button>
          </a>
        )
      );
    }
    return (
      ((isCustomerSupport(user) && csOnly) || !csOnly) && (
        <Button
          type="button"
          onClick={e => onClick(e)}
          kind="primary"
          isSpinning={isLoading || authorizeCourierLoading || authorizeCourierBranchesLoading}
          disabled={isLoading || isDisabled}
        >
          <Text value={translations.CONNECT_NOW} />
        </Button>
      )
    );
  };

  return submitButton();
};

SubmitButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  courier: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    authenticationType: PropTypes.string.isRequired,
    integrationData: PropTypes.shape({
      csOnly: PropTypes.bool.isRequired,
    }),
  }),
  courierType: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  authorizeCourierLoading: PropTypes.bool.isRequired,
  authorizeCourierBranchesLoading: PropTypes.bool.isRequired,
};

export default SubmitButton;
