import { VOUCHER_TYPE, MINIMUM_REQUIREMENTS as VOUCHER_MINIMUM_REQUIREMENTS } from 'pages/marketing/constants';
import { CAMPAIGN_TYPES, CREDIT_BALANCE_STATE } from './constants';

export const calculateCreditBalanceRatio = (currentCredits, estimatedCredits) => {
  if (!currentCredits || !estimatedCredits || Number.isNaN(currentCredits) || Number.isNaN(estimatedCredits))
    return CREDIT_BALANCE_STATE.BELOW_MINIMUM;
  const creditBalanceRatio = currentCredits / estimatedCredits;
  if (creditBalanceRatio >= 0.5 && creditBalanceRatio < 1) return CREDIT_BALANCE_STATE.MEETS_MINIMUM_BELOW_REQUIRED;
  if (creditBalanceRatio >= 1) return CREDIT_BALANCE_STATE.MEETS_REQUIRED;
  return CREDIT_BALANCE_STATE.BELOW_MINIMUM;
};

export const prepareCampaignObject = (data, state) => {
  const campaignBaseData = {
    state,
    title: data.title,
    type: data.type,
    startsAt: data.startsAt,
    campaignLanguage: data.campaignLanguage,
    smsBody: data.smsBody,
  };
  const campaignVoucherData =
    data.voucherType === VOUCHER_TYPE.NO_VOUCHER
      ? {}
      : {
          voucherType: data.voucherType,
          voucherValidFor: data.validFor,
          voucherAmount: data.voucherType !== VOUCHER_TYPE.FREE_DELIVERY ? data.voucherAmount : 0.0,
          voucherMinSubtotalAmount:
            data.voucherMinSubtotalAmount === VOUCHER_MINIMUM_REQUIREMENTS.NONE ? 0.0 : data.voucherMinSubtotalAmount,
          voucherRedemptionLimitPerUser: data.voucherRedemptionLimitPerUserEnabled
            ? data.voucherRedemptionLimitPerUser === undefined
              ? 0
              : data.voucherRedemptionLimitPerUser
            : 0,
        };
  const campaignConditionalData =
    data.type === CAMPAIGN_TYPES.RETENTION
      ? {
          expiresAt: data.expiresAt || null,
          inactivityPeriod: parseInt(data.inactivityPeriod),
          lookbackWindow: data.lookbackWindow.toString(),
          smsSendingLimit: data.smsSendingLimit || null,
        }
      : {
          targetedSegment: data.targetedSegment,
          campaignGoal: data.campaignGoal,
          uploadedFileName: data.uploadedFileName || undefined,
        };
  return { ...campaignBaseData, ...campaignConditionalData, ...campaignVoucherData };
};
