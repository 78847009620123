import { gql } from "@apollo/client"

export const CATEGORIES = gql`
  query($storeId: String!) {
    menuSections(restaurantId: $storeId) {
      menuSections {
        id
        titleAr
        titleEn
        photoUrl
        publishedBranchIds
        menuItemsCount
      }
    }
  }
`

export const CHECK_STOCK_ENABLE = gql`
  query($storeId: String!) {
    settings(restaurantId: $storeId) {
      isStockEnabled
      lowStockThreshold
    }
  }
`

export const STOCKS = gql`
  query(
    $storeId: String!
    $branchId: Int!
    $page: Int
    $limit: Int
    $title: String
    $categoryId: String
    $status: String
  ) {
    stocks(
      restaurantId: $storeId
      branchId: $branchId
      page: $page
      limit: $limit
      title: $title
      categoryId: $categoryId
      status: $status
    ) {
      stocks {
        id
        branchId
        variantId
        variantTitleEn
        variantTitleAr
        barCode
        sku
        variantPhotoUrl
        status
        stockCount
      }
      totalCount
    }
  }
`

export const STOCKS_HISTORY = gql`
  query(
    $storeId: String!
    $branchId: Int!
    $limit: Int
    $page: Int
    $title: String
    $categoryId: String
    $range: HistoryRange
  ) {
    stockHistory(
      restaurantId: $storeId
      branchId: $branchId
      limit: $limit
      page: $page
      title: $title
      categoryId: $categoryId
      range: $range
    ) {
      stockHistory {
        id
        variantTitleEn
        variantTitleAr
        quantity
        createdAt
        variantPhotoUrl
        updatedBy
        updatedByType
      }
      totalCount
    }
  }
`
