import { gql } from '@apollo/client';

const UNINSTALL_FB = gql`
  mutation uninstallFB($restaurantId: String!) {
    uninstallFB(restaurantId: $restaurantId) {
      connection
    }
  }
`;

export default UNINSTALL_FB;
