import React from "react"
import * as yup from "yup"
import * as translations from "constants/translations"
import { Text } from "components/service"

export const initialValues = {
  minQuantity: 1,
  maxQuantity: 10,
  lang: "english",
  titleEn: "",
  titleAr: "",
  multipleAddOnsEnabled: false,
}

export const validationSchema = yup.object().shape({
  titleEn: yup.string().required(<Text value={translations.OPTION_TITLE_EN} />),
  titleAr: yup.string().required(<Text value={translations.OPTION_TITLE_AR} />),
  minQuantity: yup.number().required(<Text value={translations.MIN_QTE_REQ} />),
  maxQuantity: yup.number().required(<Text value={translations.MAX_QTE_REQ} />),
  variantIds: yup
    .array()
    .of(yup.string().required())
    .required(<Text value={translations.CHOOSE_VARIANT} />),
})
