import React from "react"
import { ReactComponent as Sort } from "assets/sort.svg"
import { SortableHandle } from "react-sortable-hoc"

export default SortableHandle((isMenu = false) => (
  <span className="cursor-move mx-2 text-zyda-grey-30 flex">
    {isMenu ? (
      <i className="material-icons text-zyda-grey-30 text-xl pt-1 pr-1 -m-1 mb-0 mr-0 ml-0">
        menu
      </i>
    ) : (
      <Sort />
    )}
  </span>
))
