import React, { useState, useContext } from "react"
import { useMutation } from "@apollo/client"
import { SortableContainer } from "react-sortable-hoc"
import cx from "classnames"
import arrayMove from "array-move"
import { LoadingContainer } from "components/kit"
import { Text } from "components/service"
import { Row } from "components/form/generic"
import { context as localeContext } from "context/locale"
import * as translations from "constants/translations"
import TableHead from "./TableHead"
import TableRow from "./TableRow"
import * as schemas from "./schemas"
import * as utils from "./utils"

const VariantsTable = ({
  items = [],
  query,
  open,
  close,
  onCreate,
  productLang,
  storeId,
  productId,
  setErrorField,
  onSort,
  pageType,
  isEdit,
  setFieldValue,
  variantsLoading,
  name,
  isMenu,
  onRemove,
}) => {
  const { lang, direction } = useContext(localeContext)
  const [deleteVariant, { loading: loadingDelete }] = useMutation(
    schemas.DELETE_VARIANT,
    {
      variables: {
        restaurantId: storeId,
        menuItemId: productId,
      },
      update: utils.updateCacheAfterDelete(query, storeId, productId),
    }
  )
  const [isSorting, setSorting] = useState(false)

  return (
    <table
      style={{
        direction,
      }}
      className={cx(
        "table-auto border-collapse inline-block ",
        isEdit ? "w-auto" : "w-full overflow-auto",
        isMenu ? "variants-table" : "catalog-variants-table"
      )}
    >
      <TableHead lang={lang} isEdit={isEdit} isMenu={isMenu} />
      <LoadingContainer isLoading={isSorting || variantsLoading}>
        <Rows
          direction={direction}
          isEdit={isEdit}
          query={query}
          pageType={pageType}
          open={open}
          close={close}
          items={items}
          name={name}
          onCreate={onCreate}
          useDragHandle
          productLang={productLang}
          setErrorField={setErrorField}
          setFieldValue={setFieldValue}
          storeId={storeId}
          productId={productId}
          isMenu={isMenu}
          onSortEnd={async ({ oldIndex, newIndex }) => {
            setSorting(true)
            const sorted = arrayMove(items, oldIndex, newIndex)
            await onSort(sorted)
            setSorting(false)
          }}
          loadingDelete={loadingDelete}
          onRemove={onRemove}
        />
      </LoadingContainer>
    </table>
  )
}

const Rows = SortableContainer(
  ({
    direction,
    storeId,
    productId,
    open,
    close,
    loadingDelete,
    items,
    productLang,
    onRemove,
    setErrorField,
    pageType,
    isEdit,
    onCreate,
    setFieldValue,
    query,
    name,
    isMenu,
  }) => {
    return (
      <>
        <tbody>
          {items.map((item, i) => (
            <TableRow
              isMenu={isMenu}
              query={query}
              isEdit={isEdit}
              key={
                "" +
                item.titleEn +
                item.titleAr +
                item.price +
                item.images +
                item.id
              }
              {...item}
              variant={item}
              storeId={storeId}
              productId={productId}
              index={i}
              idx={i}
              open={open}
              close={close}
              name={name}
              variantsArray={items}
              setFieldValue={setFieldValue}
              loadingDelete={loadingDelete}
              productLang={productLang}
              setErrorField={setErrorField}
              shouldRemove={items.length > 1}
              onRemove={() => onRemove(i)}
              pageType={pageType}
            />
          ))}
        </tbody>
        {isEdit && (
          <Row>
            <CreateVariants
              direction={direction}
              onCreate={onCreate}
              pageType={pageType}
            />
          </Row>
        )}
      </>
    )
  }
)

const CreateVariants = ({ direction, onCreate, pageType }) => {
  return (
    <div
      className="flex-2 border-b border-gray-300"
      style={{
        direction,
      }}
    >
      <button
        data-testid={`${pageType}-variant-btn-add`}
        className={"my-2 px-2 text-primary-base"}
        type="button"
        onClick={() => {
          onCreate()
        }}
      >
        <Text value={translations.P_ADD_VARIANT} />
      </button>
    </div>
  )
}
export default VariantsTable
