import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { context as localeContext } from 'context/locale';
import CheckBox from 'components/kit/CheckBox';
import { Button, DropDown } from 'components/kit';
import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { useMobile } from 'hooks';
import getOrderStatusTranslation from 'utils/getOrderStatusTranslation';

const OrderSelectionRow = ({
  setBulkChangeStatus,
  setShowConfirmatioModal,
  selectAll,
  numberOfSelectedOrders,
  updateAllOrdersSelectedState,
  getNextStatuses,
}) => {
  const { lang } = useContext(localeContext);
  const isMobile = useMobile();
  const selectTypes = [
    { id: true, title: <Text value={translations.SELECT_ALL} /> },
    { id: false, title: <Text value={translations.CLEAR_ALL} /> },
  ];
  const nextStatuses = getNextStatuses();
  const isNextStatus = !!nextStatuses.length;

  return (
    <div
      className={cx(
        'flex mb-4 px-5 pb-4 border-b border-gray-300',
        isMobile ? 'flex-col' : 'items-center justify-between',
      )}
    >
      <div
        className={cx(
          'flex items-center',
          isMobile && 'pb-4 justify-between border-b border-gray-300',
          (lang === 'ar' || isMobile) && 'flex-row-reverse',
        )}
      >
        <div className={cx('flex items-center', lang === 'ar' && 'flex-row-reverse')}>
          <CheckBox
            value={selectAll}
            lang={lang}
            checkboxClassName="flex items-start sm:items-center justify-center"
            onChange={updateAllOrdersSelectedState}
            customCheck={selectAll}
          />
          <DropDown
            icon="keyboard_arrow_down"
            onSelect={selectedOption => {
              updateAllOrdersSelectedState(selectedOption);
            }}
            testId_openDropDown="select-order-selection-type"
            testId_selectedData="order-select-type"
            testId_listData="order-select-types-list"
            data={selectTypes}
            noPlaceholder
            wFull
            noLabel
            noBorder
            noPadding
            noMargin
          />
        </div>
        <Text
          className={cx(!isMobile && lang === 'en' ? 'ml-3' : 'mr-3')}
          value={translations.SELECTED_ORDERS}
          payload={numberOfSelectedOrders}
        />
      </div>
      {isNextStatus && (
        <div className={cx(isMobile && 'pt-4', 'flex items-center', lang === 'ar' && 'flex-row-reverse')}>
          <Text value={translations.CHANGE_TO} className={cx(lang === 'en' && 'mr-3')} />
          {nextStatuses.map(status => (
            <Button
              className="mr-3"
              onClick={() => {
                setBulkChangeStatus(status);
                setShowConfirmatioModal(true);
              }}
              kind="outline"
              borderColor="border-primary-base"
              textColor="text-primary-base"
              isRounded
              isPillShape
            >
              <Text value={getOrderStatusTranslation(status)} />
            </Button>
          ))}
        </div>
      )}
    </div>
  );
};

OrderSelectionRow.propTypes = {
  setBulkChangeStatus: PropTypes.func.isRequired,
  setShowConfirmatioModal: PropTypes.func.isRequired,
  selectAll: PropTypes.bool.isRequired,
  numberOfSelectedOrders: PropTypes.number.isRequired,
  updateAllOrdersSelectedState: PropTypes.func.isRequired,
  getNextStatuses: PropTypes.func.isRequired,
};
export default OrderSelectionRow;
