import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ResponsiveLine } from '@nivo/line';
import cx from 'classnames';

import { context as localeContext } from 'context/locale';
import * as translations from 'constants/translations';
import { useMobile } from 'hooks/index';
import { lineChartDataTransform, generateTickValues } from '../utils';

const SalesAnalyticsLineChart = ({ data, range, display, selectedTitle, type: selectedType, selectedPeriod }) => {
  const { translate, lang } = useContext(localeContext);
  const isMobile = useMobile();
  const type = !data.currentPeriod.data
    ? selectedType
    : Object.keys(data.currentPeriod.data)[0].split('-').length === 3
    ? 'day'
    : 'month';
  const format = type === 'day' ? '%Y-%m-%d' : '%Y-%m';
  const currentPeriod = lineChartDataTransform(data.currentPeriod, type);
  const previousPeriod = lineChartDataTransform(data.previousPeriod, type, data.currentPeriod);
  const previousYear = lineChartDataTransform(data.previousYear, type, data.currentPeriod);
  const dataPeriod = {
    previousYear: {
      id: translate(translations.PREVIOUS_YEAR),
      data: previousYear,
      color: '#90929c',
    },
    previousPeriod: {
      id: translate(translations.PREVIOUS_PERIOD),
      data: previousPeriod,
      color: '#a0b1fa',
    },
    currentPeriod: {
      id: translate(translations.SELECTED_PERIOD),
      data: currentPeriod,
      color: '#3852ca',
    },
  };

  const [dataLines, setDataLines] = useState(selectedPeriod.map(item => dataPeriod[item]));

  useEffect(() => {
    setDataLines(selectedPeriod.map(item => dataPeriod[item]));
  }, [selectedPeriod, selectedType]);

  return (
    <ResponsiveLine
      animate
      height={330}
      enableSlices="x"
      enablePoints={false}
      curve="monotoneX"
      colors={selectedPeriod.map(item => dataPeriod[item].color)}
      margin={{ top: 10, right: 30, bottom: 80, left: 90 }}
      data={dataLines}
      theme={{
        textColor: '#92929d',
        fontSize: isMobile ? 12 : 14,
        grid: {
          line: {
            stroke: '#F1F1F1',
          },
        },
        axis: {
          ticks: {
            line: {
              stroke: '#f1f1f1',
            },
            text: {
              fontSize: 12,
              fontWeight: 500,
            },
          },
        },
      }}
      axisLeft={{
        orient: 'left',
        tickSize: 12,
        tickPadding: 10,
      }}
      axisBottom={{
        format: type === 'day' ? '%b %d' : currentPeriod.length > 13 && type === 'month' ? '%b %Y' : '%b',
        orient: 'bottom',
        tickSize: 12,
        tickPadding: 10,
        tickValues: generateTickValues(currentPeriod.length, type),
      }}
      xScale={{
        type: 'time',
        format,
        useUTC: false,
        precision: 'day',
      }}
      xFormat={`time:${format}`}
      sliceTooltip={({ slice }) => {
        const localFormat = type === 'day' ? 'MMM DD, YYYY' : 'MMM YYYY';
        const diffDate = moment(range.end).diff(range.start, 'days');
        const [amount, unit] = [diffDate, 'days'];
        const periodsDate = {
          '#3852ca': dateP =>
            moment(dateP)
              .locale(lang)
              .format(localFormat),
          '#a0b1fa': dateP =>
            moment(dateP)
              .locale(lang)
              .subtract(amount, unit)
              .format(localFormat),
          '#90929c': dateP =>
            moment(dateP)
              .locale(lang)
              .subtract(1, 'years')
              .format(localFormat),
        };

        return (
          <div className="bg-white rounded shadow py-3">
            <div className="mb-3 pb-1 border-b border-gray-300 px-3">{selectedTitle}</div>
            <div className="flex flex-col">
              {slice.points.map((point, i) => (
                <div
                  key={point.id}
                  className={cx('flex-shrink-0 mb-1 px-3', i !== 0 && 'border-t border-gray-300 pt-2')}
                >
                  <div
                    className="mr-2 w-3 h-3 inline-block rounded-full"
                    style={{ backgroundColor: point.serieColor }}
                  />
                  <div className="inline-block mr-2 text-gray-700">
                    {periodsDate[point.serieColor](point.data.xFormatted)}
                  </div>
                  <span className="font-semibold mr-2">{display(point.data.y)}</span>
                </div>
              ))}
            </div>
          </div>
        );
      }}
    />
  );
};

SalesAnalyticsLineChart.propTypes = {
  data: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string])).isRequired,
  range: PropTypes.shape({ start: PropTypes.string, end: PropTypes.string }).isRequired,
  display: PropTypes.func.isRequired,
  selectedTitle: PropTypes.element.isRequired,
  type: PropTypes.oneOf(['today', '7_day', '30_day', '90_day', 'all_time', 'custom']).isRequired,
  selectedPeriod: PropTypes.arrayOf(PropTypes.oneOf(['currentPeriod', 'previousPeriod', 'previousYear'])).isRequired,
};

export default SalesAnalyticsLineChart;
