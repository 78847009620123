/* global google */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, useLoadScript, DrawingManager, StandaloneSearchBox } from '@react-google-maps/api';

import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { useMobile } from 'hooks/index';
import * as translations from 'constants/translations';
import { Spinner } from 'components/kit';
import { Text } from 'components/service';
import {
  DELIVERY_ZONE_GEO_JSON_FEATURE_POLYGON_TEMPLATE,
  DELIVERY_ZONE_GEO_JSON_FEATURE_COLLECTION_TEMPLATE,
} from 'constants/index';
import { Input } from 'components/form/elements';
import PolygonItem from '../Polygon';
import CircleItem from '../Circle';
import { centerMocks } from '../../mocks';
import { dataLayerRemoval, primaryColorUtils, zoneTypeUtils } from '../../utils';
import StaticCircle from '../Circle/StaticCircle';
import SelectedZonePolygon from '../Polygon/SelectedZonePolygon';
import BranchesMarker from '../BranchesMarker';

const { REACT_APP_GOOGLE_MAPS_KEY } = process.env;
const libraries = ['drawing', 'places'];

const Map = ({
  branches,
  zoneController,
  setZoneController,
  zones,
  zonesCollection,
  zonesNearestCollection,
  bulkZones,
}) => {
  const { direction, translate } = useContext(localeContext);
  const { dzmsSelectedBranch } = useContext(userContext);

  const isMobile = useMobile();

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: REACT_APP_GOOGLE_MAPS_KEY,
    libraries,
  });
  const [searchBox, setSearchBox] = useState();
  const [map, setMap] = useState(null);
  const [drawer, setDrawer] = useState(null);
  const [center] = useState(centerMocks);

  // - handle search box
  const onPlacesChanged = () => {
    if (map && searchBox) {
      const places = searchBox.getPlaces();
      const bounds = new window.google.maps.LatLngBounds();

      places.forEach(place => {
        if (place.geometry.viewport) {
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      map.fitBounds(bounds);
    }
  };

  // - handle map viewport (not Utils because of map usage)
  const handleViewPort = lnglat => {
    const bounds = new google.maps.LatLngBounds();
    for (let i = 0; i < lnglat.length; i++) {
      bounds.extend(lnglat[i]);
    }
    map.fitBounds(bounds);
    map.setCenter(bounds.getCenter());
  };

  // - Get polygon shape once completed
  const onPolygonComplete = polygon => {
    const zonesCoord = [];
    const geoJsonFeatureCollection = DELIVERY_ZONE_GEO_JSON_FEATURE_COLLECTION_TEMPLATE;
    const geoJsonPolygonFeature = DELIVERY_ZONE_GEO_JSON_FEATURE_POLYGON_TEMPLATE;
    geoJsonPolygonFeature.geometry.coordinates[0] = [];
    polygon
      .getPath()
      .getArray()
      .forEach(coord => {
        const lat = coord.lat();
        const lng = coord.lng();
        geoJsonPolygonFeature.geometry.coordinates[0].push([lng, lat]);
        zonesCoord.push({ lat, lng });
      });
    // must be three points to pass
    if (zonesCoord.length < 3 || zoneController.drawerMode === null) {
      polygon.getPath().clear();
      return;
    }
    geoJsonFeatureCollection.features = [geoJsonPolygonFeature];
    geoJsonFeatureCollection.properties = {
      branch_reference_id: zoneController.selectedBranch.id,
      fillColor: zoneController.selectedBranch.color,
    };

    const createdZone = {
      geometry: geoJsonPolygonFeature.geometry,
      properties: {
        branch_reference_id: zoneController.selectedBranch.id,
        fillColor: zoneController.selectedBranch.color,
        branchName: zoneController.selectedBranch.title,
      },
      paths: zonesCoord,
      geoShape: geoJsonFeatureCollection,
    };

    setZoneController({
      ...zoneController,
      isAdd: true,
      drawerMode: null,
      showDrawerMode: false,
      enableDrawerMode: false,
      shapeRef: polygon,
      selectedZone: createdZone,
    });
  };

  // - apply drawer mode
  useEffect(() => {
    if (drawer) {
      if (zoneController.drawerMode === zoneTypeUtils.POLYGON) {
        drawer.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
      }

      if (zoneController.drawerMode === zoneTypeUtils.CIRCLE) {
        drawer.setDrawingMode(google.maps.drawing.OverlayType.CIRCLE);
        drawer.setDrawingMode(null);
      }

      if (zoneController.drawerMode === null) {
        drawer.setDrawingMode(null);
      }
    }
  }, [zoneController.drawerMode, drawer]);

  // - move map position on branch select
  useEffect(() => {
    if (zoneController.selectedBranch && zoneController.enableDrawerMode && !zoneController.showDrawerMode && map) {
      handleViewPort(zoneController.selectedBranch.branchViewPort);
    }
  }, [zoneController.selectedBranch, map]);

  useEffect(() => {
    if (map) {
      const { data: dataLayer } = map;
      dataLayerRemoval(dataLayer, map);

      dataLayer.addGeoJson({ type: 'FeatureCollection', features: bulkZones });
      dataLayer.addGeoJson(zonesCollection);
      dataLayer.addGeoJson(zonesNearestCollection);

      dataLayer.setStyle(feature => ({
        visible: feature.getProperty('visible') || false,
        editable: feature.getProperty('editable') || false,
        strokeColor: primaryColorUtils,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: primaryColorUtils,
        fillOpacity: 0.4,
      }));
    }
  }, [map, zonesCollection, zonesNearestCollection, bulkZones]);

  // - view port
  useEffect(() => {
    if (map && branches.length && !dzmsSelectedBranch) {
      handleViewPort(branches[0].viewPort);
    }
  }, [map, branches]);

  if (loadError) {
    return <Text value={translations.MAP_FAILED_TO_LOAD} />;
  }

  return isLoaded ? (
    <GoogleMap
      center={center}
      onLoad={ref => {
        setMap(ref);
      }}
      mapContainerClassName="h-full w-full"
      options={{
        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControl: false,
        zoomControlOptions: {
          position: direction === 'ltr' ? google.maps.ControlPosition.RIGHT_TOP : google.maps.ControlPosition.LEFT_TOP,
        },
      }}
    >
      {!isMobile && (
        <StandaloneSearchBox onLoad={ref => setSearchBox(ref)} onPlacesChanged={() => onPlacesChanged()}>
          <div className="flex items-center relative z-50 pt-4 w-fit">
            <div className="w-343" />
            <div className="bg-white w-343 shadow">
              <Input
                type="text"
                icon="search"
                width="full"
                noBorder
                placeholder={translate(translations.SEARCH_GOOGLE_MAPS)}
              />
            </div>
          </div>
        </StandaloneSearchBox>
      )}

      <DrawingManager
        onPolygonComplete={onPolygonComplete}
        onLoad={ref => setDrawer(ref)}
        options={{
          drawingControl: false,
          drawingControlOptions: {
            drawingModes: [google.maps.drawing.OverlayType.POLYGON],
          },
          polygonOptions: {
            editable: false,
            fillColor: zoneController.selectedBranch?.color,
            strokeColor: zoneController.selectedBranch?.color,
            fillOpacity: 0.5,
            strokeWeight: 2,
            zIndex: 1,
          },
        }}
      />

      {branches.map(branchItem => (
        <BranchesMarker
          item={branchItem}
          key={branchItem.id}
          zoneController={zoneController}
          setZoneController={setZoneController}
        />
      ))}

      {!!zones.length &&
        map &&
        zones.map(zoneItem =>
          zoneItem.geometry.type === zoneTypeUtils.POLYGON ? (
            <PolygonItem
              key={zoneItem.properties.id}
              item={zoneItem}
              zoneController={zoneController}
              setZoneController={setZoneController}
              handleViewPort={handleViewPort}
            />
          ) : (
            <CircleItem
              key={zoneItem.properties.id}
              item={zoneItem}
              zoneController={zoneController}
              setZoneController={setZoneController}
              handleViewPort={handleViewPort}
              map={map}
            />
          ),
        )}

      {zoneController.selectedSuggestedZone !== null && (
        <SelectedZonePolygon
          item={zoneController.selectedSuggestedZone}
          zoneController={zoneController}
          setZoneController={setZoneController}
          handleViewPort={handleViewPort}
        />
      )}

      {zoneController.circleShape && (
        <StaticCircle
          key={`${zoneController.circleShape.type + zoneController.circleShape.id}`}
          item={zoneController.circleShape}
          zoneController={zoneController}
          setZoneController={setZoneController}
        />
      )}
    </GoogleMap>
  ) : (
    <div className="h-full w-full d-flex align-center justify-center">
      <Spinner />
    </div>
  );
};

Map.propTypes = {
  branches: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      lat: PropTypes.number,
      lng: PropTypes.number,
      viewPort: PropTypes.arrayOf(
        PropTypes.shape({
          lat: PropTypes.number,
          lng: PropTypes.number,
        }),
      ),
    }),
  ).isRequired,
  zones: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.string,
    }),
  ).isRequired,
  zoneController: PropTypes.shape({
    enableDrawerMode: PropTypes.bool,
    showDrawerMode: PropTypes.bool,
    selectedBranch: PropTypes.shape({
      id: PropTypes.number,
      color: PropTypes.string,
      lat: PropTypes.number,
      lng: PropTypes.number,
      branchViewPort: PropTypes.arrayOf(
        PropTypes.shape({
          lat: PropTypes.number,
          lng: PropTypes.number,
        }),
      ),
      title: PropTypes.shape({}),
    }),
    drawerMode: PropTypes.string,
    circleShape: PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.string,
    }),
    selectedSuggestedZone: PropTypes.shape({
      id: PropTypes.number,
    }),
  }).isRequired,
  setZoneController: PropTypes.func.isRequired,
  zonesCollection: PropTypes.shape({
    type: PropTypes.string,
    properties: PropTypes.shape({ restaurantName: PropTypes.string }),
    features: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        geometry: PropTypes.shape({
          type: PropTypes.string,
          coordinates: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))),
        }),
      }),
    ),
  }),
  zonesNearestCollection: PropTypes.shape({
    type: PropTypes.string,
    properties: PropTypes.shape({ restaurantName: PropTypes.string }),
    features: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        geometry: PropTypes.shape({
          type: PropTypes.string,
          coordinates: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))),
        }),
      }),
    ),
  }),
  bulkZones: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
    }),
  ).isRequired,
};

export default Map;
