import React, { useContext, useState } from "react"
import { Query } from "@apollo/client/react/components"
import { useQuery, NetworkStatus } from "@apollo/client"
import { useQueryState } from "hooks/index"
import { context as userContext } from "context/user"
import { context as localeContext } from "context/locale"
import { Text } from "components/service"
import * as translations from "constants/translations"
import { breadcrumbs } from "constants/translations"
import { Button, Spinner, Modal, DropDown, Stack } from "components/kit"
import { Link } from "components/service"
import { Label } from "components/form/generic"
import { Layout, Breadcrumbs } from "components/common/dashboard"
import * as paths from "paths.js"
import Table from "./Table"
import AssignZone from "./AssignZone"
import * as schemas from "./schemas"
import { groupBy } from "ramda"
import { withBackParam } from "utils/history"
import * as utils from "./utils"
import cx from "classnames"
import { Countries } from "components/data"
import EmptyState from "./EmptyState"
import { DELIVERY_LINKS } from "constants/helperLinks"

export default () => {
  const { selectedStore } = useContext(userContext)
  const { lang, direction } = useContext(localeContext)
  const [query, setQuery] = useQueryState(
    { countryId: selectedStore.countryId },
    { countryId: parseInt }
  )

  const {
    loading: loadingAreas,
    data: dataAreas,
    refetch: refetchAreas,
  } = useQuery(schemas.REMAINING_COUNTRY_AREAS, {
    variables: {
      restaurantId: selectedStore.id,
      countryId: parseInt(query.countryId),
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  })


  return (
    <Query
      query={schemas.BRANCHES}
      variables={{ restaurantId: selectedStore.id }}
    >
      {({ loading: loadingBranch, data: branches }) => (
        <Query
          query={schemas.DELIVERY_ZONES}
          variables={{
            restaurantId: selectedStore.id,
            countryId: parseInt(query.countryId),
          }}
          fetchPolicy="cache-and-network"
          notifyOnNetworkStatusChange={true}
        >
          {({
            loading: loadingZones,
            data: zones,
            refetch,
            error,
            networkStatus,
          }) => (
            <Modal>
              {({ open, close }) => (
                <Layout
                  top={
                    <Breadcrumbs
                    helperLinks={DELIVERY_LINKS}
                      path={breadcrumbs.SETUP_DELIVERY_LIST}
                      right={
                        zones?.deliveryZones && (
                          <Link
                              to={paths.deliveryCreation + `?${withBackParam()}`}
                              omitQuery
                          >
                            <Button kind="primary">
                              <Text value={translations.ADD_DELIVERY_ZONE} />
                            </Button>
                          </Link>
                          
                        )
                      }
                    />
                  }
                >
                  <div
                    className={cx(
                      "pb-6 flex",
                      lang === "ar" && "flex-row-reverse"
                    )}
                  >
                    <div className="w-full md:w-2/3" style={{ direction }}>
                      <Stack direction="row" className="mb-4">
                        <div style={{ direction: "ltr" }}>
                          <Countries>
                            {countries => (
                              <DropDown
                                float={lang === "ar" ? "right" : "left"}
                                label={<Text value={translations.COUNTRY} />}
                                optionSelected={parseInt(query.countryId)}
                                onSelect={countryId => {
                                  setQuery({ countryId })
                                }}
                                data={countries.map((country, i) => {
                                  return {
                                    id: parseInt(country.id),
                                    title: <Text value={country} />,
                                  }
                                })}
                                icon="keyboard_arrow_down"
                                full
                                height
                                scrollable
                              />
                            )}
                          </Countries>
                        </div>
                      </Stack>
                      {loadingZones &&
                      networkStatus !== NetworkStatus.refetch ? (
                        <Spinner />
                      ) : !zones.deliveryZones ||
                        zones.deliveryZones === null ||
                        branches.branches === null ? (
                        <EmptyState
                          branches={branches.branches}
                          storeId={selectedStore.id}
                        />
                      ) : (
                        !loadingBranch && (
                          <>
                            <Table
                              currency={selectedStore.currency}
                              isMultiCountry={
                                selectedStore.countryId !== query.countryId
                                  ? true
                                  : false
                              }
                              branches={branches.branches}
                              zones={groupBy(
                                d => d.branch && d.branch.id,
                                zones.deliveryZones
                              )}
                              open={open}
                              close={close}
                              refetch={refetch}
                            />

                            <Label
                              title={
                                <Text
                                  value={translations.REMAINING_AREAS}
                                  className="text-lg my-3"
                                />
                              }
                            />
                            {loadingAreas ? (
                              <Spinner />
                            ) : (
                              <div className="w-full pb-32 flex flex-wrap justify-between">
                                {dataAreas &&
                                dataAreas.remainingAreas === null ? (
                                  <Text
                                    value={translations.NO_REMAINING_AREAS}
                                  />
                                ) : (
                                  utils
                                    .groupByCity(dataAreas.remainingAreas)
                                    .map((items, i) => (
                                      <RemainingArea
                                        key={i}
                                        items={items}
                                        branches={branches.branches}
                                        countryId={query.countryId}
                                        close={close}
                                        open={open}
                                        refetchAreas={refetchAreas}
                                        refetchZones={refetch}
                                      />
                                    ))
                                )}
                              </div>
                            )}
                          </>
                        )
                      )}
                    </div>
                  </div>
                </Layout>
              )}
            </Modal>
          )}
        </Query>
      )}
    </Query>
  )
}

const RemainingArea = ({
  items,
  branches,
  close,
  open,
  refetchAreas,
  refetchZones,
  countryId,
}) => {
  const [isOpened, setIsOpened] = useState(false)
  const { lang, direction } = useContext(localeContext)
  var isSafari =
    /Safari/.test(navigator.userAgent) &&
    /Apple Computer/.test(navigator.vendor)

  return (
    <div className="w-full mb-3 pb-2 bg-white border border-gray-300">
      <div className="flex items-center">
        <div
          className="py-3 px-4 w-full cursor-pointer flex items-center select-none"
          onClick={() => setIsOpened(!isOpened)}
        >
          <div>
            <span className="block font-semibold mx-2">
              {lang === "en" ? items[0].cityTitleEn : items[0].cityTitleAr}
              <span className="mx-1" style={{ direction: "ltr" }}>
                ({items.length})
              </span>
            </span>
          </div>
          <i
            className={cx(
              "material-icons",
              lang === "ar" ? "mr-auto" : "ml-auto"
            )}
          >
            {!isOpened ? "expand_more" : "expand_less"}
          </i>
        </div>
      </div>
      {isOpened && (
        <div
          className={cx(
            "flex flex-col overflow-auto",
            items.length > 5 && "h-40"
          )}
        >
          {items.map((item, index) => (
            <div
              key={index}
              className={cx(
                "flex-1 flex flex-row justify-between items-start m-0 px-6",
                isSafari ? "py-5" : "py-4 md:py-2"
              )}
            >
              <span className="text-xs text-gray-700">
                <Text value={item} />
              </span>
              <span
                className={cx(
                  "text-xs cursor-pointer whitespace-nowrap",
                  lang === "ar" ? "mr-auto" : "ml-auto"
                )}
                onClick={() => {
                  open({
                    title: <Text value={translations.ASSIGN_TO_ZONE} />,
                    body: (
                      <AssignZone
                        areaId={item.id}
                        branches={branches}
                        countryId={countryId}
                        onCancel={close}
                        refetchAreas={refetchAreas}
                        refetchZones={refetchZones}
                      />
                    ),
                  })
                }}
              >
                <Text
                  value={translations.ASSIGN_TO_ZONE}
                  className="text-primary-base"
                />
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
