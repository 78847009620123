import React, { useState, useContext, useRef, useEffect } from "react"
import { useQuery, useMutation } from "@apollo/client"
import { Layout, Breadcrumbs } from "components/common/dashboard"
import { Text } from "components/service"
import { Button, Stack, More, Tabs, DropDown } from "components/kit"
import * as translations from "constants/translations"
import { navigate } from "@reach/router"
import { replaceParams } from "utils/index"
import { exportStockUrl } from "rest.js"
import * as paths from "paths.js"
import { TableFooter, Input } from "components/form/elements"
import { Modal, Spinner } from "components/kit"
import { breadcrumbs } from "constants/translations"
import { context as localeContext } from "context/locale"
import { ReactComponent as SearchIcon } from "assets/search.svg"
import { context as userContext } from "context/user"
import * as schemas from "../../schemas"
import EmptyState from "../../EmptyState"
import cx from "classnames"
import { useSelectedStore, useQueryState, useMobile } from "hooks"
import ImportStock from "../../ImportStock"
import Table from "./Table"
import { Footer } from "components/form/generic"

const StockVariants = () => {
  const { lang, direction } = useContext(localeContext)
  const { branches } = useContext(userContext)
  const isMobile = useMobile()
  const storeId = useSelectedStore()
  const [langSelected, setLangSelected] = useState("en")
  const [stocks, setStocks] = useState([])
  const [categories, setCategories] = useState([])
  const searchTimeout = useRef()
  const stockLang = [
    { id: "en", title: "English" },
    { id: "ar", title: "Arabic" },
  ]
  const LIMIT = 10
  const [query, setQuery] = useQueryState({
    page: 1,
    limit: LIMIT,
    branchId: branches ? branches[0].id : null,
    categoryId: "all",
    status: "all",
  })
  const stockVariables = {
    storeId,
    branchId: parseInt(query.branchId),
    page: parseInt(query.page),
    limit: parseInt(query.limit),
    title: query.title ? query.title : null,
    categoryId: query.categoryId === "all" ? null : query.categoryId,
    status: query.status === "all" ? null : query.status,
  }
  const { data: dataStocks, loading: loadingStocks } = useQuery(
    schemas.STOCKS,
    {
      variables: stockVariables,
      skip: !branches,
    }
  )
  const { data: categoriesRes, loading: loadingCat } = useQuery(
    schemas.CATEGORIES,
    {
      variables: {
        storeId,
      },
    }
  )

  useEffect(() => {
    !loadingStocks &&
      dataStocks &&
      dataStocks.stocks &&
      setStocks(dataStocks.stocks.stocks)
  }, [
    stocks,
    dataStocks,
    query.branchId,
    query.categoryId,
    query.title,
    query.page,
    query.status,
  ])

  useEffect(() => {
    !loadingCat &&
      categoriesRes &&
      categoriesRes.menuSections &&
      setCategories(categoriesRes.menuSections.menuSections)
  }, [categoriesRes])

  const { loading: loadingSettings, data: settings } = useQuery(
    schemas.CHECK_STOCK_ENABLE,
    {
      variables: { storeId },
      fetchPolicy: "no-cache",
    }
  )

  const [disableStock, { loading: isStillUpdating }] = useMutation(
    schemas.DISABLE_STOCK
  )

  const categoriesData = [
    {
      id: "all",
      titleEn: isMobile ? translations.ALL_CATEGORIES[0] : translations.ALL[0],
      titleAr: isMobile ? translations.ALL_CATEGORIES[1] : translations.ALL[1],
    },
    ...categories,
  ].map(category => ({
    id: category.id,
    title: <Text value={category} />,
  }))

  const branchesData =
    branches &&
    [...branches].map(branch => ({
      id: branch.id,
      title: <Text value={branch} />,
    }))
  const statusData = [
    {
      id: "all",
      title: isMobile ? (
        <Text value={translations.ALL_STATUS} />
      ) : (
        <Text value={translations.ALL} />
      ),
    },
    {
      id: "IN_STOCK",
      title: <Text value={translations.IN_STOCK} />,
    },
    {
      id: "OUT_OF_STOCK",
      title: <Text value={translations.OUT_STOCK} />,
    },
    {
      id: "TRACKED",
      title: <Text value={translations.TRACK_QUANTITY} />,
    },
  ]

  const tableFooter = dataStocks?.stocks?.totalCount > LIMIT && (
    <TableFooter
      totalRecords={dataStocks?.stocks?.totalCount}
      pageLimit={LIMIT}
      currentPage={parseInt(query.page)}
      onPageChanged={({ currentPage }) => setQuery({ page: currentPage })}
      totalCount={stocks.length}
    />
  )
  const DisableStockbtn = async data => {
    let variables = {
      storeId: storeId,
      isStockEnabled: false,
    }
    await disableStock({
      variables: variables,
    }).then(res => {
      navigate(
        replaceParams(paths.stockManagement, {
          storeId: storeId,
        })
      )
    })
  }
  const DisableStock = ({ onCancel, data }) => {
    const { lang } = useContext(localeContext)
    return (
      <div>
        <Text
          className="mx-1 px-4 mb-3"
          value={translations.DISABLE_STOCK_CONFIRM}
        />
        <Text
          value={translations.DISABLE_STOCK_NOTE}
          className="mb-6 mx-1 px-4 text-gray-700 text-xs"
        />
        <Footer modalFooter>
          <div className="w-full flex items-center">
            <div className={cx(lang === "ar" ? "mr-auto" : "ml-auto")}>
              <Stack direction="row">
                <Button
                  data-testid="dismiss-disable-stock"
                  onClick={onCancel}
                  type="button"
                  kind="tertiary"
                >
                  <Text value={translations.DISMISS} />
                </Button>
                <Button
                  kind="secondaryError"
                  onClick={() => DisableStockbtn(data)}
                  data-testid="btn-disable-stock"
                >
                  <Text value={translations.DISABLE} />
                </Button>
              </Stack>
            </div>
          </div>
        </Footer>
      </div>
    )
  }
  return (
    <Modal>
      {({ open, close }) => {
        return loadingCat && loadingSettings && loadingStocks ? (
          <Layout
            top={
              <Breadcrumbs
                reverse
                isMenu
                isOptions
                isOverview
                customTitle={
                  <Text
                    value={translations.STOCK_MANAGEMENT}
                    className="text-2xl font-bold"
                  />
                }
              />
            }
          >
            <Spinner />
          </Layout>
        ) : (
          <Layout
            top={
              <Breadcrumbs
                isMenu
                reverse
                isOptions
                isOverview
                refLink={
                  <button
                    className={cx(
                      "w-full md:w-1/4 md:px-2 text-zyda-grey-400 underline",
                      lang === "en" ? "text-left" : "text-right"
                    )}
                    onClick={() =>
                      navigate(
                        replaceParams(paths.stockHistoryLog, {
                          storeId: storeId,
                        })
                      )
                    }
                  >
                    <Text className="text-xs" value={translations.VIEW_HISTORY} />
                  </button>
                }
                customTitle={
                  <Text
                    value={translations.STOCK_MANAGEMENT}
                    className="text-2xl font-bold"
                    full={false}
                  />
                }
                right={
                  <div style={{ direction }}>
                    {settings && settings.settings.isStockEnabled && (
                      <Stack expandItem={false} direction="wrap">
                        <Button
                          data-testid="stockManagement-import-stock"
                          kind="primary"
                          size="baseGroup"
                          className="w-40"
                          style={{
                            direction,
                          }}
                          onClick={() => {
                            open({
                              title: <Text value={translations.IMPORT_STOCK} />,
                              body: <ImportStock onCancel={close} />,
                            })
                          }}
                        >
                          <Text
                            value={translations.IMPORT_STOCK}
                            className="font-semibold"
                          />
                        </Button>
                        <Button
                          data-testid="stockManagement-export-stock"
                          size="baseGroup"
                          kind="secondary"
                          className="W-40"
                          style={{
                            direction,
                          }}
                          onClick={() =>
                            (window.location.href = exportStockUrl(storeId))
                          }
                        >
                          <Text
                            value={translations.EXPORT_STOCK}
                            className="font-semibold"
                          />
                        </Button>
                        <More
                          testIdBtn="stock-moreOptions"
                          size={"option"}
                          iconColor="text-zyda-black"
                          iconSize="2xl"
                          items={[
                            {
                              title: <Text value={translations.SETTINGS} />,
                              onClick: () => {
                                navigate(
                                  replaceParams(
                                    paths.stockManagementGeneralSettings,
                                    {
                                      storeId: storeId,
                                    }
                                  )
                                )
                              },
                            },

                            {
                              title: (
                                <Text value={translations.DISABLE_STOCK_BTN} />
                              ),
                              onClick: data => {
                                open({
                                  title: (
                                    <Text
                                      value={translations.DISABLE_STOCK_BTN}
                                    />
                                  ),
                                  body: (
                                    <DisableStock
                                      onCancel={close}
                                      data={data}
                                    />
                                  ),
                                  size: "max-w-sm",
                                })
                              },
                            },
                          ]}
                        />
                      </Stack>
                    )}
                  </div>
                }
              />
            }
          >
            {settings && settings.settings.isStockEnabled && (
              <div className="px-5 lg:px-8 py-1">
                {isMobile ? (
                  <>
                    <div className="w-full my-2">
                      <Input
                        type="text"
                        icon={<SearchIcon />}
                        search
                        placeholder={
                          lang === "en"
                            ? translations.SEARCH_STOCK[0]
                            : translations.SEARCH_STOCK[1]
                        }
                        style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                        initialValue={query.title}
                        onChange={e => {
                          clearTimeout(searchTimeout.current)
                          const { value } = e.target
                          searchTimeout.current = setTimeout(() => {
                            setQuery({ page: 1, title: value })
                          }, 500)
                        }}
                      />
                    </div>
                    <div className="flex items-center w-full md:w-auto">
                      <div className="w-1/2">
                        <DropDown
                          float={"left"}
                          label={
                            <Text value={translations.BUSINESS_LOCATION} />
                          }
                          optionSelected={query.branchId}
                          onSelect={option => setQuery({ branchId: option })}
                          data={branchesData}
                          icon="keyboard_arrow_down"
                          position={lang === "ar" ? "right" : "left"}
                          scrollable
                          wFull={true}
                          responsiveLabel
                        />
                      </div>
                      <div className={cx("my-2 w-1/2 md:w-auto ml-4 md:ml-0")}>
                        <DropDown
                          label={<Text value={translations.STATUS_STOCK} />}
                          data={statusData}
                          icon="keyboard_arrow_down"
                          position={direction === "rtl" ? "right" : "left"}
                          optionSelected={query.status}
                          onSelect={option => {
                            setQuery({ status: option })
                          }}
                          wFull={true}
                          responsiveLabel
                        />
                      </div>
                    </div>
                    <div className="flex items-center w-full md:w-auto">
                      <div className="w-1/2">
                        <DropDown
                          label={<Text value={translations.CATEGORY} />}
                          data={categoriesData}
                          optionSelected={query.categoryId}
                          onSelect={categoryId => {
                            setQuery({ page: 1, categoryId })
                          }}
                          icon="keyboard_arrow_down"
                          position={"left"}
                          scrollable
                          wFull={true}
                          responsiveLabel
                        />
                      </div>
                      <div className={cx("my-2 w-1/2 md:w-auto ml-4 md:ml-0")}>
                        <DropDown
                          label={<Text value={translations.VIEW_IN} />}
                          float={lang === "ar" ? "left" : "right"}
                          position={direction === "rtl" ? "right" : "left"}
                          optionSelected={langSelected}
                          onSelect={stockLang => {
                            setLangSelected(stockLang)
                          }}
                          data={stockLang}
                          icon="keyboard_arrow_down"
                          responsiveLabel
                          wFull={true}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <div
                    className={cx(
                      "flex-wrap flex justify-between items-center mb-4",
                      lang == "ar" && "flex-row-reverse"
                    )}
                  >
                    <div
                      className={cx(
                        "flex md:flex-row flex-col justify-between items-center",
                        lang == "ar" && "lg:flex-row-reverse"
                      )}
                    >
                      <div className="md:w-auto w-full">
                        <DropDown
                          testId_openDropDown="stockManagement-locs-open"
                          testId_selectedData="stockManagement-locs-data"
                          testId_listData="stockManagement-list-locs"
                          float={"left"}
                          label={
                            <Text value={translations.BUSINESS_LOCATION} />
                          }
                          optionSelected={query.branchId}
                          onSelect={option => setQuery({ branchId: option })}
                          data={branchesData}
                          icon="keyboard_arrow_down"
                          position={lang === "ar" ? "right" : "left"}
                          scrollable
                        />
                      </div>
                      <div className="my-2 md:px-4 w-full md:w-auto">
                        <DropDown
                          testId_openDropDown="stockManagement-categs-open"
                          testId_selectedData="stockManagement-categs-data"
                          testId_listData="stockManagement-list-categs"
                          label={<Text value={translations.CATEGORY} />}
                          data={categoriesData}
                          optionSelected={query.categoryId}
                          onSelect={categoryId => {
                            setQuery({ page: 1, categoryId })
                          }}
                          icon="keyboard_arrow_down"
                          position={direction === "rtl" ? "right" : "left"}
                          scrollable
                        />
                      </div>
                      <div className="md:w-auto w-full search-stock">
                        <Input
                          isMenu
                          isStock
                          data-testid="stockManagement-search-stock"
                          type="text"
                          icon={<SearchIcon />}
                          search
                          placeholder={
                            lang === "en"
                              ? translations.SEARCH_STOCK[0]
                              : translations.SEARCH_STOCK[1]
                          }
                          initialValue={query.title}
                          onChange={e => {
                            clearTimeout(searchTimeout.current)
                            const { value } = e.target
                            searchTimeout.current = setTimeout(() => {
                              setQuery({ page: 1, title: value })
                            }, 500)
                          }}
                        />
                      </div>
                    </div>
                    <div
                      className={cx(
                        "flex md:flex-row flex-col justify-between items-center",
                        lang == "ar" && "md:flex-row-reverse"
                      )}
                    >
                      <div
                        className={cx(
                          "md:px-4 my-2 md:w-auto w-full",
                          lang == "ar" ? "md:pr-0" : "md:pl-0"
                        )}
                      >
                        <DropDown
                          testId_openDropDown="stockManagement-open-statusList"
                          testId_selectedData="stockManagement-status-data"
                          testId_listData="stockManagement-list-status"
                          label={<Text value={translations.STATUS_STOCK} />}
                          data={statusData}
                          icon="keyboard_arrow_down"
                          position={direction === "rtl" ? "right" : "left"}
                          optionSelected={query.status}
                          onSelect={option => {
                            setQuery({ status: option })
                          }}
                        />
                      </div>
                      <div>
                        <DropDown
                          label={<Text value={translations.VIEW_IN} />}
                          float={lang === "ar" ? "left" : "right"}
                          position={direction === "rtl" ? "right" : "left"}
                          optionSelected={langSelected}
                          onSelect={stockLang => {
                            setLangSelected(stockLang)
                          }}
                          data={stockLang}
                          icon="keyboard_arrow_down"
                        />
                      </div>
                      <div />
                    </div>
                  </div>
                )}
              </div>
            )}
            {!branches || !branches.length ? (
              <Text
                value={translations.STORE_HAS_NO_BRANCHES}
                className="px-5 py-1"
              />
            ) : !settings || loadingSettings || loadingStocks ? (
              <Spinner />
            ) : !settings.settings.isStockEnabled ? (
              <EmptyState />
            ) : !stocks || !stocks.length ? (
              <Text
                value={translations.NO_PRODUCT_CATEGORY}
                className="px-5 py-1"
              />
            ) : (
              <div className={cx("px-5 lg:px-8 pb-20 flex flex-col")}>
                <div className="w-full">
                  <Table
                    stocks={stocks}
                    langSelected={langSelected}
                    stockVariables={stockVariables}
                    lowStock={settings.settings.lowStockThreshold}
                    footer={tableFooter}
                  />
                </div>
              </div>
            )}
          </Layout>
        )
      }}
    </Modal>
  )
}
export default StockVariants
