import React from 'react';
import moment from 'moment';
import * as yup from 'yup';

import { Text } from 'components/service';
import * as translations from 'constants/translations';

export const validationSchemaCreation = yup.object().shape({
  code: yup.string().required(<Text value={translations.VOUCHER_CODE_IS_REQUIRED} />),
  descriptionEn: yup.string().required(<Text value={translations.DESCRIPTION_REQUIRED} />),
  descriptionAr: yup.string().required(<Text value={translations.DESCRIPTION_REQUIRED} />),
  amount: yup.number().when('type', {
    is: type => type !== 'free_delivery' && true,
    then: yup.number().when('type', {
      is: type => type === 'percentage' && true,
      then: yup
        .number()
        .required(<Text value={translations.VOUCHER_AMOUNT_IS_REQUIRED} />)
        .min(1)
        .moreThan(0, <Text value={translations.AMOUNT_MORE_THAN_0} />)
        .when('type', (type, schema) => type === 'percentage' && schema.max(100, 'percentage max is 100')),
      otherwise: yup
        .number()
        .required(<Text value={translations.VOUCHER_AMOUNT_IS_REQUIRED} />)
        .min(1)
        .moreThan(0, <Text value={translations.AMOUNT_MORE_THAN_0} />),
    }),
    otherwise: yup.number(),
  }),
  redemptionLimitPerUser: yup.number().when('redemptionLimitEnabled', {
    is: true,
    then: yup
      .number()
      .when(
        'redemptionLimit',
        (redemptionLimit, schema) =>
          redemptionLimit !== (0 || null) && schema.max(redemptionLimit, `max is ${redemptionLimit}`),
      ),
    otherwise: yup.number(),
  }),
  validTimeFrom: yup.string().when('happyHour', {
    is: true,
    then: yup.string().required(<Text value={translations.START_TIME_IS_REQUIRED} />),
    otherwise: yup.string(),
  }),
  validTimeTo: yup.string().when('happyHour', {
    is: true,
    then: yup.string().required(<Text value={translations.END_TIME_IS_REQUIRED} />),
    otherwise: yup.string(),
  }),
  validFrom: yup.string().required(<Text value={translations.START_DATE_IS_REQUIRED} />),
  validFromTime: yup.string().required(<Text value={translations.START_TIME_IS_REQUIRED} />),
  validUntil: yup.string().when('validUntilEnabled', {
    is: true,
    then: yup.string().required(<Text value={translations.END_DATE_IS_REQUIRED} />),
    otherwise: yup.string(),
  }),
  validUntilTime: yup.string().when('validUntilEnabled', {
    is: true,
    then: yup.string().required(<Text value={translations.END_TIME_IS_REQUIRED} />),
    otherwise: yup.string(),
  }),
});

export const initialValues = {
  validFromTime: '07:00',
  validUntilTime: '',
  redemptionLimit: 0,
  validFrom: moment().format(),
  validUntil: '',
};
