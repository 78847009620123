import React, { useContext } from 'react';
import 'react-datetime/css/react-datetime.css';

import { context } from 'components/common/orders/Frame/OrderFrameFilters';
import OrderItemView from './Order/OrderItemView';

const Table = () => {
  const {
    status,
    statuses,
    paymentStatus,
    paymentStatuses,
    query,
    branches,
    orders,
    ordersVariables,
    showOrderStatus,
  } = useContext(context);
  const key = JSON.stringify(query);
  return (
    <OrderItemView
      key={key}
      query={query}
      status={status}
      statuses={statuses}
      paymentStatus={paymentStatus}
      paymentStatuses={paymentStatuses}
      branches={branches}
      ordersVariables={ordersVariables}
      orders={orders}
      showOrderStatus={showOrderStatus}
    />
  );
};
export default Table;
