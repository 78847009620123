import React from "react"
import * as yup from "yup"
import { Text } from "components/service"
import * as translations from "constants/translations"

export const validationSchema = yup.object().shape({
  password: yup
    .string()
    .min(8, <Text value={translations.PASSWORD_MINIMUM_8_DIGITS} />)
    .required(<Text value={translations.PASSWORD_REQUIRED} />),
  passwordConfirmation: yup
    .string()
    .oneOf(
      [yup.ref("password")],
      <Text value={translations.PASSWORDS_MUST_MATCH} />
    )
    .required(<Text value={translations.PASSWORD_CONF_REQUIRED} />),
})

export const initialValues = {
  password: "",
  passwordConfirmation: "",
}
