import { STH_WENT_WRONG } from "constants/constant-errors"

export const updateCacheAfterCreate = (query, restaurantId, productId) => (
  cache,
  { data: { createPropertySection: propertySection } }
) => {
  const variables = { restaurantId, id: productId }
  const cacheData = cache.readQuery({
    query,
    variables,
  })

  cache.writeQuery({
    query,
    data: {
      ...cacheData,
      variants: cacheData.variants.map(variant =>
        !propertySection.variantIds.includes(+variant.id)
          ? variant
          : {
              ...variant,
              propertySections: [
                ...(variant.propertySections || []),
                propertySection,
              ],
            }
      ),
    },
    variables,
  })
}

export const updateCacheAfterDelete = (query, restaurantId, productId) => (
  cache,
  {
    data: {
      deletePropertySectionForVariant: { id },
    },
  }
) => {
  const variables = { restaurantId, id: productId }
  const cacheData = cache.readQuery({
    query,
    variables,
  })

  cache.writeQuery({
    query,
    data: {
      ...cacheData,
      variants: cacheData.variants.map(variant => ({
        ...variant,
        propertySections: (variant.propertySections || []).filter(
          section => section.id !== id
        ),
      })),
    },
    variables,
  })
}

export const replaceOptionGroups = (
  client,
  query,
  restaurantId,
  productId,
  variantId,
  items
) => {
  const variables = { restaurantId, id: productId }
  const cacheData = client.readQuery({
    query,
    variables,
  })

  client.writeQuery({
    query,
    data: {
      ...cacheData,
      variants: cacheData.variants.map(variant =>
        variant.id !== variantId
          ? variant
          : {
              ...variant,
              propertySections: items,
            }
      ),
    },
    variables,
  })
}

export const replaceOptions = (
  client,
  queryOptions,
  restaurantId,
  optionGroupId,
  items
) => {
  const variables = { restaurantId, propertySectionId: optionGroupId }
  const cacheData = client.readQuery({
    query: queryOptions,
    variables,
  })
  client.writeQuery({
    query: queryOptions,
    data: {
      propertyValues: items,
    },
    variables,
  })
}

export const handleSubmissionErrors = err => {
  const { body } = err?.graphQLErrors[0].extensions.exception

  if (body.external_id) {
    return "External Id is required"
  }
  if (body["pos"]){
    return "Menu is being synced from partner"
  }

  if (body.property_values_attributes) {
    let exists = body.property_values_attributes.filter((property_value) => {
      return property_value.hasOwnProperty('external_id');
    }).length > 0;

    if (exists) {
      return "External Id is required"
    }
  }

  if (body["property_values.external_id"]) {
    return "External Id is required"
  }

  if (body["property_values.title_en"]) {
    return "Title en is required"
  }

  if (body["property_values.title_ar"]) {
    return "Title ar is required"
  }
  if (body["min_quantity"]) {
    return "MIN quantity must be less than MAX quantity"
  }
  if (body.error) {
    return body.error
  }

  if (body["max_quantity"]) {
    return "Max quantity should be less than or equal options' count"
  }
  return STH_WENT_WRONG
}
