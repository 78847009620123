export const handleErrors = callback => err => {
  const body = err.graphQLErrors[0].extensions.exception.body
  for (let key of Object.keys(body)) {
    callback({ key: mapKey[key], msg: `${mapKey[key] ? mapTitle[key] : ""} ${body[key][0]}` })
  }
}

const mapKey = {
  title_en: "titleEn",
  title_ar: "titleAr",
}
const mapTitle = {
  title_en: "English title",
  title_ar: "Arabic title",
}
