import React from 'react';
import PropTypes from 'prop-types';

import { Text } from 'components/service';
import { DropDown } from 'components/kit';
import * as translations from 'constants/translations';

const FilterCategoriesDropdown = ({ query, setQuery, branchesData, lang, isSingleBranch }) => (
  <DropDown
    testId_openDropDown="list-products-select-business-location"
    testId_selectedData="list-products-business-location-name"
    testId_listData="list-products-business-location-list"
    float={lang === 'ar' ? 'right' : 'left'}
    label={<Text value={translations.BUSINESS_LOCATION} />}
    optionSelected={query.location}
    onSelect={option => setQuery({ location: option })}
    data={branchesData}
    icon="keyboard_arrow_down"
    position={lang === 'ar' ? 'right' : 'left'}
    scrollable
    wFull={true}
    responsiveLabel
    labelClassNames="text-gray-700"
    isDisabled={isSingleBranch}
  />
);

FilterCategoriesDropdown.propTypes = {
  query: PropTypes.element.isRequired,
  setQuery: PropTypes.func.isRequired,
  branchesData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ),
  lang: PropTypes.string.isRequired,
  isSingleBranch: PropTypes.string.isRequired,
};

export default FilterCategoriesDropdown;
