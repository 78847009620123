import React, { useContext, useState, useEffect } from "react"
import ItemTrackingTableHead from "./ItemTrackingTableHead"
import ItemTrackingTableRows from "./ItemTrackingTableRows"
import { context as localeContext } from "context/locale"
import { useQuery } from "@apollo/client"
import { BRANCH_VARIANTS_STOCKS } from "./schemas"
import { Spinner } from "components/kit"
import { useMobile } from "hooks"
import cx from "classnames"

const ItemTrackingTable = ({
  branch,
  storeId,
  variants,
  productId,
  branchIndex,
  trackingType,
}) => {
  const { id } = branch || {}
  const isMobile = useMobile()
  const { lang, direction } = useContext(localeContext)
  const [branchVariant, setBranchVariants] = useState(null)
  const variantIds =
    variants &&
    variants.map(variant => {
      return variant.id
    })

  const {
    data: branchVariantStockData,
    loading: loadingBranchVariantStockData,
  } = useQuery(BRANCH_VARIANTS_STOCKS, {
    variables: {
      storeId: storeId,
      branchId: parseInt(id),
      variantIds: variantIds,
    },
    fetchPolicy: "network-only",
    skip: !productId,
  })

  useEffect(() => {
    if (branchVariantStockData) {
      setBranchVariants(
        branchVariantStockData?.branchVariantsStock?.branchVariantsStock
      )
    }
  }, [branchVariantStockData])

  return loadingBranchVariantStockData ? (
    <Spinner />
  ) : (
    <table
      style={{
        direction,
      }}
      className="table-auto border-collapse w-full overflow-auto"
      key={branchIndex}
    >
      <ItemTrackingTableHead
        lang={lang}
        isMobile={isMobile}
        trackingType={trackingType}
      />
      <ItemTrackingTableRows
        branchVariantsData={branchVariant}
        lang={lang}
        isMobile={isMobile}
        trackingType={trackingType}
      />
    </table>
  )
}

export default ItemTrackingTable
