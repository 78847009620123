import React from 'react';
import PropTypes from 'prop-types';

import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { Input } from 'components/form/elements';
import { ReactComponent as SearchIcon } from 'assets/search.svg';
import { DropDown } from 'components/kit';
import { OPTION_GROUPS_FRAME_ENUMS } from './constants';

const OptiongroupMobileFrame = ({
  emptyGroups,
  query,
  setQuery,
  optionGroupId,
  isSingleBranch,
  branchesData,
  lang,
  statusData,
  prodcutLang,
  setLangSelected,
  langSelected,
  searchTimeout,
}) =>
  !emptyGroups && (
    <>
      <div className="w-full">
        <Input
          type="text"
          search
          icon={<SearchIcon />}
          placeholder={lang === 'en' ? translations.SEARCH_OPTION[0] : translations.SEARCH_OPTION[1]}
          initialValue={optionGroupId === OPTION_GROUPS_FRAME_ENUMS.ALL ? query.title : query.search}
          onChange={e => {
            clearTimeout(searchTimeout.current);
            const { value } = e.target;
            searchTimeout.current = setTimeout(() => {
              optionGroupId === OPTION_GROUPS_FRAME_ENUMS.ALL
                ? setQuery({ page: 1, title: value })
                : setQuery({ page: 1, search: value });
            }, 500);
          }}
        />
      </div>
      <div className="w-full md:w-auto my-2">
        <DropDown
          float={'left'}
          label={<Text value={isSingleBranch ? translations.BRANCH : translations.BUSINESS_LOCATION} />}
          optionSelected={query.location}
          onSelect={option => setQuery({ location: option })}
          data={branchesData}
          icon="keyboard_arrow_down"
          position={lang === 'ar' ? 'right' : 'left'}
          scrollable
          testId_openDropDown="list-optionGroups-select-business-location"
          testId_selectedData="list-optionGroups-business-location-name"
          testId_listData="list-optionGroups-business-location-list"
          wFull={true}
          responsiveLabel
          labelClassNames="text-gray-700"
          isDisabled={isSingleBranch}
        />
      </div>
      <div className="flex items-center w-full md:w-auto">
        <div className="md:hidden w-1/2">
          <DropDown
            float={'left'}
            label={<Text value={translations.OPTION_GROUPS_STATUS} />}
            optionSelected={query.status}
            onSelect={option => setQuery({ status: option })}
            data={statusData}
            icon="keyboard_arrow_down"
            position={lang === 'ar' ? 'right' : 'left'}
            scrollable
            testId_openDropDown="list-optionGroups-select-status"
            testId_selectedData="list-optionGroups-status-name"
            testId_listData="list-optionGroups-status-list"
            wFull={true}
            responsiveLabel
            labelClassNames="text-gray-700"
          />
        </div>
        <div className="my-2 w-1/2 md:w-auto ml-4">
          <DropDown
            float={lang === 'ar' ? 'left' : 'right'}
            label={<Text value={translations.VIEW_IN} />}
            responsiveLabel
            full
            optionSelected={langSelected}
            onSelect={prodcutLang => setLangSelected(prodcutLang)}
            data={prodcutLang}
            icon="keyboard_arrow_down"
            testId_openDropDown="list-optionGroups-select-view-lang"
            testId_selectedData="list-optionGroups-view-lang-name"
            testId_listData="list-optionGroups-view-lang-list"
            wFull={true}
            responsiveLabel
            labelClassNames="text-gray-700"
          />
        </div>
      </div>
    </>
  );

OptiongroupMobileFrame.propTypes = {
  emptyGroups: PropTypes.bool.isRequired,
  query: PropTypes.element.isRequired,
  setQuery: PropTypes.func.isRequired,
  isSingleBranch: PropTypes.bool.isRequired,
  branchesData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ),
  lang: PropTypes.string.isRequired,
  statusData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ),
  prodcutLang: PropTypes.string.isRequired,
  setLangSelected: PropTypes.func.isRequired,
  langSelected: PropTypes.string.isRequired,
  searchTimeout: PropTypes.func.isRequired,
};
export default OptiongroupMobileFrame;
