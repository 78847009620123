import React, { useContext, useState, useRef } from "react"
import { useMutation } from "@apollo/client"
import { context as localeContext } from "context/locale"
import { context as notificationsContext } from "context/notifications"
import { useSelectedStore } from "hooks"
import { DropDown, SpinnerAlt } from "components/kit"
import { Stepper } from "components/form/elements"
import { useClickOutside } from "hooks/index"
import * as schemas from "../schemas"
import { Text } from "components/service"
import cx from "classnames"
import * as translations from "constants/translations"
import { ReactComponent as VariantsIcon } from "assets/product-place-holder.svg"
import LowStock from "components/common/inventory/LowStock"

export default ({ stocks, langSelected, stockVariables, lowStock, footer }) => {
  const { lang, direction } = useContext(localeContext)

  const headCellClassName =
    "w-1/5 text-justify font-semibold border-b border-gray-300 py-3 px-5 whitespace-nowrap"

  return (
    <>
      <table
        style={{ direction, maxWidth: "93.5vw" }}
        className="w-full table-auto border-collapse border shadow inline-block overflow-auto pb-24"
      >
        <thead>
          <tr className="bg-gray-200">
            <th className={headCellClassName}>
              <Text
                value={translations.VARIANT_PRODUCT}
                className="whitespace-nowrap inline-block"
              />
            </th>
            <th className={headCellClassName}>
              <Text
                value={translations.SKU}
                className="whitespace-nowrap inline-block"
              />
            </th>
            <th className={headCellClassName}>
              <Text
                value={translations.BARCODE}
                className="whitespace-nowrap inline-block"
              />
            </th>
            <th className={headCellClassName}>
              <Text
                value={translations.STATUS_STOCK}
                className="whitespace-nowrap inline-block"
              />
            </th>
            <th className={headCellClassName}>
              <Text
                value={translations.QUANTITY}
                className="whitespace-nowrap inline-block"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {stocks.map((item, i) => (
            <RowTable
              count={stocks.length}
              index={i}
              item={item}
              langSelected={langSelected}
              lowStock={lowStock}
              stockVariables={stockVariables}
            />
          ))}
        </tbody>
        {footer && (
          <div
            className={cx(
              "py-2 w-full md:w-auto flex items-center border-b border-l border-gray-300"
            )}
          >
            {!!stocks.length && footer}
          </div>
        )}
      </table>
    </>
  )
}

const RowTable = ({ item, lowStock, langSelected, stockVariables }) => {
  const { lang, direction } = useContext(localeContext)
  const notifications = useContext(notificationsContext)
  const storeId = useSelectedStore()
  const cellClassName = cx(
    "px-4 border-b border-gray-300 whitespace-nowrap last:w-full"
  )
  const statusTable = [
    {
      id: "IN_STOCK",
      title: <Text value={translations.IN_STOCK} />,
    },
    {
      id: "OUT_OF_STOCK",
      title: <Text value={translations.OUT_STOCK} />,
    },
    {
      id: "TRACKED",
      title: <Text value={translations.TRACK_QUANTITY} />,
    },
  ]

  const [updateStockStatus, { loading: loadingStockStatus }] = useMutation(
    schemas.UPDATE_STOCK_STATUS
  )
  const [updateStockQuantity, { loading: loadingStockQuantity }] = useMutation(
    schemas.UPDATE_STOCK_QUANTITY
  )
  const [updateStockBarcode, { loading: loadingStockBarcode }] = useMutation(
    schemas.UPDATE_STOCK_BARCODE,
    {
      update: (
        cache,
        {
          data: {
            updateStockBarcode: { id, barCode },
          },
        }
      ) => {
        const stocksData = cache.readQuery({
          query: schemas.STOCKS,
          variables: stockVariables,
        })

        let stock = stocksData.stocks.stocks.filter(
          stock => stock.variantId === parseInt(id)
        )

        let newStockData = {
          ...stock[0],
          barCode: barCode,
        }
        cache.writeQuery({
          query: schemas.STOCKS,
          variables: stockVariables,
          data: {
            stocks: {
              ...stocksData.stocks.stocks,
              newStockData,
            },
          },
        })
      },
    }
  )
  const [updateStockSKU, { loading: loadingStockSKU }] = useMutation(
    schemas.UPDATE_STOCK_SKU,
    {
      update: (
        cache,
        {
          data: {
            updateStockSKU: { id, sku },
          },
        }
      ) => {
        const stocksData = cache.readQuery({
          query: schemas.STOCKS,
          variables: stockVariables,
        })

        let stock = stocksData.stocks.stocks.filter(
          stock => stock.variantId === parseInt(id)
        )
        let newStockData = {
          ...stock[0],
          sku: sku,
        }

        cache.writeQuery({
          query: schemas.STOCKS,
          variables: stockVariables,
          data: {
            stocks: {
              ...stocksData.stocks.stocks,
              newStockData,
            },
          },
        })
      },
    }
  )

  return (
    <tr key={item.id}>
      <td className={cx("px-4 border-b border-gray-300 truncate py-3 ")}>
        <div className="cursor-default flex items-center border border-transparent">
          {item.variantPhotoUrl ? (
            <div className={cx(lang === "ar" ? "ml-4" : "mr-4", "w-12 h-12")}>
              <img
                className={cx(
                  lang === "ar" ? "ml-10" : "mr-10",
                  "h-full w-full rounded overflow-hidden"
                )}
                src={item.variantPhotoUrl}
                alt={item.variantTitleEn}
              />
            </div>
          ) : (
            <div className={cx(lang === "ar" ? "ml-4" : "mr-4")}>
              <VariantsIcon />
            </div>
          )}
          <div className="flex flex-col">
            <LowStock langSelected={langSelected} lowStock={lowStock} stockItem={item} />
          </div>
        </div>
      </td>
      <td className={cx(cellClassName)}>
        <InputStock
          onSave={value =>
            updateStockSKU({
              variables: {
                storeId,
                variantId: item.variantId,
                id: item.id,
                sku: value,
              },
            })
          }
          isSaving={loadingStockSKU}
          title={item.sku}
          renderInput={({ value, onChange }) => (
            <input
              type="text"
              className="w-40 outline-none px-2 -mx-2 h-10 border border-gray-300 rounded"
              value={value}
              data-testid="stockManagement-sku"
              onChange={onChange}
              onKeyPress={e => {
                e.key === "Enter" && e.preventDefault()
              }}
            />
          )}
        />
      </td>
      <td className={cx(cellClassName)}>
        <InputStock
          onSave={value => {
            updateStockBarcode({
              variables: {
                storeId,
                variantId: item.variantId,
                id: item.id,
                barCode: value,
              },
            })
          }}
          isSaving={loadingStockBarcode}
          title={item.barCode}
          renderInput={({ value, onChange }) => (
            <input
              type="text"
              className="w-40 outline-none px-2 -mx-2 h-10 border border-gray-300 rounded"
              data-testid="stockManagement-barcode"
              value={value}
              onChange={onChange}
              onKeyPress={e => {
                e.key === "Enter" && e.preventDefault()
              }}
            />
          )}
        />
      </td>
      <td className={cx(cellClassName)}>
        <div className="flex items-center">
          <DropDown
            testId_openDropDown="stockManagement-choose-status"
            testId_selectedData="stockManagement-chosen-status"
            testId_listData="stockManagement-status-data"
            data={statusTable}
            icon="keyboard_arrow_down"
            position={direction === "rtl" ? "right" : "left"}
            float={lang === "ar" && "right"}
            scrollable
            optionSelected={item.status}
            onSelect={option => {
              if (option === "TRACKED") {
                updateStockStatus({
                  variables: {
                    storeId,
                    id: item.id,
                    outOfStock: false,
                    tracked: true,
                  },
                })
              }
              if (option === "IN_STOCK") {
                updateStockStatus({
                  variables: {
                    storeId,
                    id: item.id,
                    outOfStock: false,
                    tracked: false,
                  },
                })
              }
              if (option === "OUT_OF_STOCK") {
                updateStockStatus({
                  variables: {
                    storeId,
                    id: item.id,
                    outOfStock: true,
                    tracked: true,
                  },
                })
              }
            }}
          />
          {loadingStockStatus && (
            <div className="ml-3">
              <SpinnerAlt color="primary-base" />
            </div>
          )}
        </div>
      </td>
      <td className={cx(cellClassName)}>
        {item.status === "TRACKED" ? (
          <div className="flex items-center h-10">
            <InputStockQuantity
              onSave={value =>
                updateStockQuantity({
                  variables: {
                    storeId,
                    branchId: item.branchId,
                    variantId: item.variantId,
                    id: item.id,
                    quantity: parseInt(value),
                  },
                })
                  .then()
                  .catch(error => {
                    error.graphQLErrors &&
                    error.graphQLErrors[0]?.extensions?.exception?.body
                      ? Object.keys(
                          error.graphQLErrors[0].extensions.exception.body
                        ).forEach(err => {
                          const value =
                            error.graphQLErrors[0].extensions.exception.body[
                              err
                            ]
                          notifications.show(`${err} ${value}`, "error")
                        })
                      : notifications.show(
                          <Text value={translations.SOMETHING_WENT_WRONG} />,
                          "error"
                        )
                  })
              }
              isSaving={loadingStockQuantity}
              title={item.stockCount}
              renderInput={({ value, onChange }) => (
                <Stepper
                  testId="stockManagement-quantity"
                  min={0}
                  step={1}
                  value={value}
                  onChange={onChange}
                  validation={n => n >= 0}
                  width="32"
                />
              )}
            />
          </div>
        ) : (
          "--"
        )}
      </td>
    </tr>
  )
}

const InputStockQuantity = ({ isSaving, title, onSave, renderInput }) => {
  const [value, setValue] = useState(title)
  const ref = useRef()
  useClickOutside(ref, async () => {
    if (parseInt(value) !== parseInt(title)) {
      await onSave(value)
    }
  })

  return (
    <div ref={ref}>
      <div className="flex items-center">
        {renderInput({ value, onChange: e => setValue(e.target.value) })}
        {isSaving && (
          <div className="ml-3">
            <SpinnerAlt color="primary-base" />
          </div>
        )}
      </div>
    </div>
  )
}

const InputStock = ({ isSaving, title, onSave, renderInput }) => {
  const [value, setValue] = useState(title)
  const ref = useRef()
  useClickOutside(ref, async () => {
    if (value !== title) {
      await onSave(value)
    }
  })

  return (
    <div ref={ref}>
      <div className="flex items-center">
        {renderInput({ value, onChange: e => setValue(e.target.value) })}
        {isSaving && (
          <div className="ml-3">
            <SpinnerAlt color="primary-base" />
          </div>
        )}
      </div>
    </div>
  )
}
