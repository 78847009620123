import React from "react"
import moment from "moment"
import { Text } from "components/service"
import { PaidMethodsIcon } from "utils/imagesResolver"
import * as translations from "constants/translations"
import { dateQueryFormat } from "utils/date"
import { PAYMENT_METHODS_MAPPING } from "constants/salesReport"

export const getSubmittedAt = value => {
  if (value === "all") return

  const [amount, unit] = value.split("_")

  return [
    moment()
      .subtract(amount, unit)
      .format(),
    moment().format(),
  ]
}


export const getSorting = value => ({
  order: "descend",
  field: value,
})

export const validateRange = range => {
  if (
    moment(range[0], dateQueryFormat).isAfter(moment(range[1], dateQueryFormat))
  ) {
    return <Text value={translations.DATES_CONFLICT} />
  }
}

export const cleanFetchedPaymentMethods = fetchedPaymentMethods => {
  let filtered = []
  fetchedPaymentMethods.map(
    option =>
      PAYMENT_METHODS_MAPPING[option] &&
      filtered.push(PAYMENT_METHODS_MAPPING[option])
  )
  const uniqueValues = [...new Set(filtered)]
  const normalizedPaymentMethods = uniqueValues.map(pm => {
    switch (pm) {
      case "CARD ON DELIVERY":
        return {
          id: "CARDONDELIVERY",
          title: <Text full={false} value={translations["CARDONDELIVERY"]} />,
          icon: <PaidMethodsIcon paidThrough={"CARDONDELIVERY"} />,
        }
      default:
        return {
          id: pm,
          title: <Text full={false} value={translations[pm]} />,
          icon: <PaidMethodsIcon paidThrough={pm} />,
        }
    }
  })
  return normalizedPaymentMethods
}

export const getQueryTitles = (filterElements, selectedKeys) => {
  return filterElements.filter(element =>
    selectedKeys.includes(element.id || element.value)
  )
}

export const cleanPaymentMethods = chosenPayments => {
  const cashIndex = chosenPayments.indexOf("Cash")
  const onlineIndex = chosenPayments.indexOf("Online")
  if (cashIndex > -1) chosenPayments.splice(cashIndex, 1)
  if (onlineIndex > -1) chosenPayments.splice(onlineIndex, 1)
  if (chosenPayments.includes("CASH")) chosenPayments.push("Cash")
  if (chosenPayments.includes("ONLINE")) chosenPayments.push("Online")

  return chosenPayments
}
