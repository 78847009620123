import React from 'react';

import { Button, Modal, GoogleMapContainer } from 'components/kit';
import { Error } from 'components/form/generic';
import { Text } from 'components/service';
import * as translations from 'constants/translations';
import PinModal from './PinModal';

const PinLocation = ({ error, name, value, onChange }) => {
  return (
    <>
      <Modal
        clickOutsideOptions={{
          shouldEnvoke: el => !el.closest('.pac-container'),
        }}
      >
        {({ open, close }) => (
          <div className="relative">
            <GoogleMapContainer
              center={value}
              zoom={14}
              mapContainerClassName="h-40"
              options={{
                disableDefaultUI: true,
              }}
            ></GoogleMapContainer>
            <div
              className="absolute top-0 left-0 w-full h-full flex items-center justify-center"
              style={{ backgroundColor: 'rgba(255, 255, 255, 0.6)' }}
            >
              <Button
                onClick={() =>
                  open({
                    title: <Text value={translations.PIN_LOCATION_ON_MAP} />,
                    body: (
                      <PinModal
                        onConfirm={coords => {
                          close();
                          onChange({ target: { name, value: coords } });
                        }}
                        onCancel={close}
                        coords={value}
                      />
                    ),
                  })
                }
                kind="tertiary"
                type="button"
              >
                <Text value={translations.PIN_LOCATION} />
              </Button>
            </div>
          </div>
        )}
      </Modal>
      {error && <Error>{error}</Error>}
    </>
  );
};

export default PinLocation;
