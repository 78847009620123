import React from 'react';
import PropTypes from 'prop-types';

import { Text } from 'components/service';
import { TOTAL_REFUND, COMPENSATION } from 'constants/translations';

const RefundConfirmationDetails = ({ refundTo, orderWithCurrency, compensationAmount = 0, orderTotal, currency }) => (
  <div className="pb-4">
    <div className="flex flex-row py-1 justify-between text-sm text-gray-400">
      <div className="flex items-start">{refundTo}</div>
      <div className="flex items-end">{orderWithCurrency}</div>
    </div>
    {compensationAmount && compensationAmount > 0 && (
      <div className="flex flex-row justify-between py-1 text-sm text-gray-400">
        <div className="flex items-start">
          <Text value={COMPENSATION(compensationAmount)} />
        </div>
        <div className="flex items-end">
          {currency} {(orderTotal * compensationAmount) / 100}
        </div>
      </div>
    )}
    <div className="flex flex-row justify-between py-1 text-black font-medium">
      <div className="flex items-start">
        <Text value={TOTAL_REFUND} />
      </div>
      <div className="flex items-end">
        {currency} {orderTotal + (orderTotal * compensationAmount) / 100}
      </div>
    </div>
  </div>
);

RefundConfirmationDetails.propTypes = {
  refundTo: PropTypes.string.isRequired,
  orderWithCurrency: PropTypes.string.isRequired,
  compensationAmount: PropTypes.number,
  orderTotal: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
};
export default RefundConfirmationDetails;
