export const DELIVERY_ENUMS = {
  OTHER: 'other',
  DELIVERY: 'Delivery',
  DELIVERY_SMALL: 'delivery',
};

export const USER_TYPES_ENUMS = {
  USER: 'user',
  COURIER: 'courier',
  DRIVER: 'driver',
};

export const TIMELINE_ENTITY_TYPES_ENUMS = {
  ORDER_TYPE: 'order_type',
  DELIVERY_REQUEST_TYPE: 'delivery_request_type',
};

export const EXTERNAL_ORDER_IDENTIFIER_TYPE = {
  QRCODE: 'qrcode',
  AWB: 'awb',
};
