import React, { createContext, useEffect, useState } from "react"
import { usePersistedState } from "hooks/index"
import { navigate } from "@reach/router"
import { parse, stringify } from "query-string"
import { Text } from "components/service"
import * as translations from "constants/translations"

export const context = createContext()

export const Provider = ({ children }) => {
  const query = parse(document.location.search)
  const [lang, changeLang] = usePersistedState("en", "lang", Array.isArray(query.lang) ? query.lang[0] : query.lang)
  const [isDisconnected, setDisconnected] = useState(false)

  useEffect(() => {
    navigate(`?${stringify({ ...query, lang })}`)
  }, [lang, query])

  setInterval(function() {
    navigator.onLine ? setDisconnected(false) : setDisconnected(true)
  }, 3000)

  return (
    <context.Provider
      value={{
        lang,
        direction: lang === "en" ? "ltr" : "rtl",
        changeLang,
        isDisconnected,
        translate: (value, selectedLang = lang, payload) => {
          const [en, ar] =
            typeof value === "function"
              ? value(payload)
              : value instanceof Array
              ? value
              : [value?.titleEn, value?.titleAr]
          return selectedLang === "en" ? en : selectedLang === "ar" ? ar : ""
        },
      }}
    >
      {isDisconnected && <NoConnection />}
      {children}
    </context.Provider>
  )
}

const NoConnection = () => (
  <div
    className="absolute left-0 w-full text-white bg-zyda-grey-500 text-xs px-4 py-3 z-10"
    style={{ top: "4rem" }}
  >
    <Text value={translations.CURRENTLY_OFFLINE} />
  </div>
)
