import React, { useContext, useState, useEffect } from "react"
import { Field, Text } from "components/service"
import { Toggle, Add } from "components/kit"
import { Label, Section, Row, TogglesWrap } from "components/form/generic"
import { Select, Stepper } from "components/form/elements"
import * as translations from "constants/translations"
import { context as localeContext } from "context/locale"
import { Countries } from "components/data"

export default ({
  values,
  currency,
  branches,
  handleChange,
  countryId,
  isMultiCountry,
  setSelectedCountry,
  setSelectedBranch,
  openingFrom
}) => {
  const { lang, translate } = useContext(localeContext)
  return (
    <Section title={<Text value={translations.BASIC_DETAILS} />}>
      <Row>
        <Label title={<Text value={translations.BUSINESS_LOCATION} />}>
         <Field
              name="branchId"
              disabled={openingFrom == 'add-delivery' ? false : !!values.branchId}
              onChange={e => {
                handleChange(e)
                let branchSelected = e.currentTarget.value
                setSelectedBranch(branchSelected)
              }}
              component={Select}
              type="squared"
            >
              {branches.map((item, i) => (
                <option key={i} value={item.id}>
                  {translate(item)}
                </option>
              ))}
            </Field>
        </Label>
      </Row>
      <Row>
        <Label title={<Text value={translations.COUNTRY} />}>
          <Countries>
            {countries => (
              <Field
                name="countryId"
                onChange={e => {
                  handleChange(e)
                  let countrySelected = e.currentTarget.value
                  setSelectedCountry(countrySelected)
                }}
                placeholder={
                  lang === "en"
                    ? translations.SELECT_COUNTRY[0]
                    : translations.SELECT_COUNTRY[1]
                }
                component={Select}
              >
                {countries.map((c, i) => (
                  <option key={i} value={c.id}>
                    {translate(c)}
                  </option>
                ))}
              </Field>
            )}
          </Countries>
        </Label>
      </Row>
      <Row>
        <Label title={<Text value={translations.DELIVERY_TIME} />}>
          {isMultiCountry ? (
            <Field
              min={0}
              step={1}
              name="deliveryTimeDays"
              right={<Text value={translations.DAYS} />}
              component={Stepper}
            />
          ) : (
            <Field
              min={0}
              step={1}
              name="deliveryTime"
              options={[
                {
                  id: "mins",
                  title: <Text value={translations.MINUTES} />,
                },
                {
                  id: "hrs",
                  title: <Text value={translations.HOURS} />,
                },
              ]}
              dropdown
              component={Stepper}
            />
          )}
        </Label>
        <Label title={<Text value={translations.DELIVERY_FEES} />}>
          <Field
            min={0}
            step={parseFloat((0).toFixed(currency.decimals - 1) + "1")}
            name="deliveryFee"
            left={<Text value={currency} className="inline" />}
            component={Stepper}
          />
        </Label>
      </Row>
      {isMultiCountry && (
        <Row>
          <Label title={<Text value={translations.MAXIMUM_SCHEDULE_TIME} />}>
            <Field
              min={0}
              step={1}
              name="scheduleMaxPeriod"
              right={<Text value={translations.DAYS} />}
              component={Stepper}
            />
          </Label>
        </Row>
      )}
      <TogglesWrap>
        <Toggle
          isvisible={values.minimumOrder ? true : false}
          link={
            <Text
              value={translations.ADD_MIN_ORDER}
              className="text-primary-base"
            />
          }
          content={
            <Row>
              <Label title={<Text value={translations.MINIMUM_ORDER} />}>
                <Field
                  min={0}
                  step={parseFloat((0).toFixed(currency.decimals - 1) + "1")}
                  name="minimumOrder"
                  left={<Text value={currency} className="inline" />}
                  component={Stepper}
                />
              </Label>
            </Row>
          }
        />
      </TogglesWrap>
    </Section>
  )
}
