import { parse } from "query-string"

export const withBackParam = () => {
  const pathname = document.location.pathname + document.location.search

  return `back=${btoa(pathname)}`
}

export const getBackUrl = () => {
  try {
    const { back } = parse(document.location.search)

    return back && atob(back)
  } catch (err) {
    // Returning undefined when "back" parameter was corrupted and could not parsed in order not to throw an error to the user
    return undefined
  }
}
