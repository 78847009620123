import React, { useContext } from "react"
import { navigate } from "@reach/router"
import moment from "moment"
import cx from "classnames"
import { context as localeContext } from "context/locale"
import { context as userContext } from "context/user"
import { context as customersContext } from "context/customers"
import SegmentLabel from "../common/components/SegmentLabel"
import VerticalEllipsisActionButton from "./VerticalEllipsisActionButton"
import { SEGMENT_TITLE_MAP } from "constants/customers"
import { ArabicNumbers } from "react-native-arabic-numbers"
import { useReplaceParams } from "hooks"
import * as paths from "paths.js"

const CustomersTableRow = ({ item, index, totalCount }) => {
  const { selectedStore } = useContext(userContext)
  const { segments } = useContext(customersContext)
  const { translate, lang, direction } = useContext(localeContext)
  const replace = useReplaceParams()
  const cellClassName = cx(
    "px-6 border-b border-gray-300",
    totalCount <= 2 ? (totalCount - 1 > index ? "py-4" : "pt-5 pb-40") : "py-4"
  )

  return (
    <tr
      className={cx('hover:shadow hover:bg-zyda-grey-15', !item.userName && !item.phoneNumber ? 'cursor-not-allowed' : 'cursor-pointer')}
      onClick={() => {
        if (!item.userName && !item.phoneNumber) return;
        navigate(
          replace(paths.customerProfile, {
            params: { customerId: item.id },
          }) + `&phoneNumber=${item.phoneNumber.replace("+", "")}`
        )
      }}
    >
      <td className={cx(cellClassName, "truncate max-w-10")}>
        {item.userName ? item.userName : "-"}
      </td>
      <td className={cellClassName}>
        &lrm;{item.phoneNumber ? item.phoneNumber : "-"}
      </td>
      <td className={cx(cellClassName, "whitespace-nowrap")}>
        {item.firstOrder
          ? moment(item.firstOrder)
              .locale(lang)
              .format(lang === "en" ? "MMM DD, YYYY" : "DD MMM YYYY")
          : "-"}
      </td>
      <td className={cx(cellClassName, "whitespace-nowrap")}>
        {item.lastOrder
          ? moment(item.lastOrder)
              .locale(lang)
              .fromNow()
          : "-"}
      </td>
      <td className={cellClassName}>
        {lang === "en" ? item.totalOrders : ArabicNumbers(item.totalOrders)}
      </td>
      <td className={cx(cellClassName, "whitespace-nowrap")}>
        <span className={cx(lang === "en" ? "pr-1" : "pl-1")}>
          {lang === "en" ? item.totalSales : ArabicNumbers(item.totalSales)}
        </span>
        {translate(selectedStore.currency)}
      </td>
      <td className={cx(cellClassName, "w-fit")}>
        <div className={cx(direction === "ltr" ? "float-left" : "float-right")}>
          <SegmentLabel
            segmentId={
              item.segmentId &&
              Object.keys(segments).includes(item.segmentId.toString())
                ? item.segmentId.toString()
                : SEGMENT_TITLE_MAP.NEW
            }
            lang={lang}
          />
        </div>
      </td>
      <td className={cellClassName}>
        {item.userName && item.phoneNumber ? (
          <div className={cx(direction === 'ltr' ? 'float-right' : 'float-left')}>
            <VerticalEllipsisActionButton
              totalCount={totalCount}
              index={index}
              customerId={item.id}
              phoneNumber={item.phoneNumber}
              direction={direction}
            />
          </div>
        ) : (
          '-'
        )}
      </td>
    </tr>
  )
}

export default CustomersTableRow
