import React, { useContext } from "react"
import { navigate } from "@reach/router"
import { useMutation } from "@apollo/client"
import { Button, Stack } from "components/kit"
import * as translations from "constants/translations"
import { Text } from "components/service"
import * as schemas from "./schemas"
import * as productSchemas from "pages/setup/product/catalog/List/schemas"
import { context as notificationsContext } from "context/notifications"

const DeleteProduct = ({
  id,
  storeId,
  resetState,
  close,
  title,
  refetchMenuPageData,
}) => {
  const notifications = useContext(notificationsContext)
  const [deleteProduct, { loading: isDeleting }] = useMutation(
    schemas.DELETE_PRODUCT,
    {
      onCompleted: () => {
        close()
        resetState && resetState()
        refetchMenuPageData && refetchMenuPageData()
        notifications.show(
          <Text value={translations.PRODUCT_DELETED} payload={title} />
        )
      },
    }
  )
  const [updateProduct] = useMutation(productSchemas.UPDATE_PRODUCT, {
    onCompleted: () => {
      notifications.show(
        <Text value={translations.UNPUBLISH_PRODUCT_SUCCESS} />
      )
      close()
      resetState && resetState()
    },
  })
  const unPublishButton = async id => {
    try {
      await updateProduct({
        variables: {
          storeId,
          productId: id,
          publishedBranchIds: [],
        },
      })
    } catch (error) {
      notifications.show(
        <Text value={translations.SOMETHING_WENT_WRONG} />,
        "error"
      )
    }
  }
  return (
    <div className="px-4">
      <Text value={translations.DELETE_PRODUCT_CONFIRMATION} className="mb-3" />
      <Text
        value={translations.DELETE_PRODUCT_NOTE}
        className="mb-6 text-gray-700 text-xs"
      />
      <Stack direction="row-reverse" expandItem={false} spacing={2}>
        <Button
          isSpinning={isDeleting}
          onClick={() =>
            deleteProduct({
              variables: {
                id,
                storeId,
              },
            })
          }
          type="button"
          kind="secondaryError"
          data-testid="delete-product"
        >
          <Text value={translations.DELETE} />
        </Button>
        <Button
          data-testid="cancel-delete-product"
          onClick={() => unPublishButton(id)}
          type="button"
          kind="tertiary"
        >
          <Text value={translations.UNPUBLISH} />
        </Button>
      </Stack>
    </div>
  )
}

export default DeleteProduct
