import React, { useContext } from 'react';
import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import _ from 'lodash';

import { Spinner } from 'components/kit';
import { context as localeContext } from 'context/locale';
import * as translations from 'constants/translations';

const libraries = ['places', 'visualization', 'geometry'];

const GoogleMapContainer = ({ children, ...props }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
    libraries,
  });
  const { translate } = useContext(localeContext);

  if (!isLoaded) {
    return (
      <div className="w-full h-full flex items-center">
        <Spinner />
      </div>
    );
  }

  if (loadError) {
    return translate(translations.MAP_FAILED_TO_LOAD);
  }

  const google = window.google;

  return (
    <GoogleMap id="g-map" key="google_map" {...props}>
      {props.google ? children(google) : children}
    </GoogleMap>
  );
};

export default GoogleMapContainer;
