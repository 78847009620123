import React, { useState } from "react"

export default ({ link, content, ...props }) => {
  const [isVisible, setVisible] = useState(props.isvisible || false)

  return !isVisible ? (
    <button
      className="text-primary-base"
      onClick={() => setVisible(true)}
      {...props}
      data-testid={props.testId}
    >
      {link}
    </button>
  ) : (
    content
  )
}
