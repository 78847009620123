import React from 'react';
import PropTypes from 'prop-types';

import { RESTAURANT_COURIER_AUTHENTICATION_TYPE } from 'constants/restaurant';
import BranchAuthorization from './BranchAuthorization';
import MerchantAuthorization from './MerchantAuthorization';

const IntegrationSection = ({ setIsLoading, courier, setIsDisabled, setAuthData }) => (
  <>
    {courier.authenticationType === RESTAURANT_COURIER_AUTHENTICATION_TYPE.BRANCH_KEY && (
      <BranchAuthorization
        setAuthData={setAuthData}
        setIsDisabled={setIsDisabled}
        setIsLoading={setIsLoading}
        courier={courier}
      />
    )}
    {courier.authenticationType === RESTAURANT_COURIER_AUTHENTICATION_TYPE.SINGLE_KEY && (
      <MerchantAuthorization setAuthData={setAuthData} setIsDisabled={setIsDisabled} />
    )}
  </>
);

IntegrationSection.propTypes = {
  setIsLoading: PropTypes.func.isRequired,
  courier: PropTypes.shape({
    courierName: PropTypes.string.isRequired,
    authenticationType: PropTypes.string.isRequired,
  }),
  setIsDisabled: PropTypes.func.isRequired,
  setAuthData: PropTypes.func.isRequired,
};

export default IntegrationSection;
