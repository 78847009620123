import { useDeliveryZoneMsToken } from 'hooks';
import { DeliveryZoneMapView, QueryDeliveryZoneMapViewArgs } from '../../types/generated';
import { useQuery } from '../../utils';
import { QueryHookResponse } from '../../types/internal';
import { deliveryZoneMapViewQuery } from '../../queries/deliveryZones';

type useFetchDeliveryZonesType = (args: QueryDeliveryZoneMapViewArgs) => QueryHookResponse<DeliveryZoneMapView>;

const useFetchDeliveryZones: useFetchDeliveryZonesType = args => {
  const deliveryZoneMsToken = useDeliveryZoneMsToken();
  return useQuery<DeliveryZoneMapView>({
    query: deliveryZoneMapViewQuery,
    variables: args ? { ...args } : undefined,
    requestHeaders: { token: deliveryZoneMsToken },
    handler: (res: any) => res?.deliveryZoneMapView,
    config: { revalidateOnMount: true },
  });
};

export default useFetchDeliveryZones;
