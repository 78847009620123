import React from "react"
import { Text } from "components/service"
import * as translations from "constants/translations"
export const AVAILABLE = "AVAILABLE"
export const UNAVAILABLE = "UNAVAILABLE"
export const ALL = "all"
export const TRACKED = "TRACKED"
export const IN_STOCK = "IN_STOCK"
export const OUT_OF_STOCK = "OUT_OF_STOCK"
export const INVENTORY = "stock"
export const CAPACITY = "daily_capacity"
export const AVAILABILITY = "none"

export const itemTrackingStatusData = (isMobile = false) => [
  {
    id: ALL,
    title: isMobile ? (
      <Text value={translations.ALL_STATUS} />
    ) : (
      <Text value={translations.ALL} />
    ),
  },
  {
    id: AVAILABLE,
    title: <Text value={translations.AVAILABLE} />,
  },
  {
    id: UNAVAILABLE,
    title: <Text value={translations.UNAVAILABLE} />,
  },
]

export const trackingData = (isMobile = false) => [
  {
    id: ALL,
    title: isMobile ? (
      <Text value={translations.ALL_STATUS} />
    ) : (
      <Text value={translations.ALL} />
    ),
  },
  {
    id: INVENTORY,
    title: <Text value={translations.INVENTORY} />,
  },
  {
    id: CAPACITY,
    title: <Text value={translations.CAPACITY} />,
  },
  {
    id: AVAILABILITY,
    title: <Text value={translations.STATUS_STOCK} />,
  },
]

export const stockStatusData = (isMobile = false) => [
  {
    id: ALL,
    title: isMobile ? (
      <Text value={translations.ALL_STATUS} />
    ) : (
      <Text value={translations.ALL} />
    ),
  },
  {
    id: AVAILABLE,
    title: <Text value={translations.IN_STOCK} />,
  },
  {
    id: UNAVAILABLE,
    title: <Text value={translations.OUT_STOCK} />,
  }
]
