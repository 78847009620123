import { gql } from '@apollo/client';

export const REFUND = gql`
  mutation($storeId: String!, $orderId: Int!, $compensation: Float, $typeOfRefund: RefundEnum!) {
    refundOrder(restaurantId: $storeId, orderId: $orderId, compensation: $compensation, typeOfRefund: $typeOfRefund) {
      refundedAmount
      compensation {
        percentage
        amount
      }
      totalRefund
      typeOfRefund
      id
      paymentStatus
      refundTransactionsHistory {
        refundId
        updatedAt
        status
      }
    }
  }
`;

export const PAYMENT_GATEWAY = gql`
  query($countryId: Int!, $gatewayId: Int!) {
    paymentGateway(countryId: $countryId, gatewayId: $gatewayId) {
      id
      name
      paymentMethods {
        name
        code
        id
        refundPolicy {
          en
          ar
        }
      }
    }
  }
`;

export const GET_PAYMENT_CREDENTIALS = gql`
  query($restaurantId: String!) {
    paymentCredentials(restaurantId: $restaurantId) {
      id
      paymentGateway {
        id
        paymentMethods {
          name
          code
          id
          refundPolicy {
            en
            ar
          }
        }
      }
    }
  }
`;
