import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Field, Text } from 'components/service';
import * as translations from 'constants/translations';
import { Stepper } from 'components/form/elements';

const RefundCompensationStepper = ({ handleSettingCompensationAmount, orderTotal, currency }) => {
  const [priceAfterCompensation, setPriceAfterCompensation] = useState(0.0);
  const [compensatedAmount, setCompensatedAmount] = useState(0);

  /**
   * Display the total price meant to be refunded after adding a compensation
   * Compensation is in form of a percentage amount of the order.
   */
  useEffect(() => {
    setPriceAfterCompensation(compensatedAmount > 0 ? (parseInt(orderTotal) * compensatedAmount) / 100 : 0);
  }, [compensatedAmount, orderTotal, setPriceAfterCompensation]);
  return (
    <div className="py-3">
      <Text value={translations.ADD_COMPENSATION} className="flex text-black text-xs" />
      <div className="flex flex-row w-full py-4">
        <div className="flex w-2/3">
          <Field
            name="compensatedAmount"
            min={0}
            step={1}
            left="%"
            value={compensatedAmount}
            placeholder={0}
            validation={n => n >= 0}
            max={100}
            onChange={e => {
              const { value } = e.target;
              setCompensatedAmount(value);
              handleSettingCompensationAmount(value);
            }}
            component={Stepper}
          />
        </div>
        <div className="flex w-1/3 px-4 py-3 truncate">
          +{currency} {priceAfterCompensation}
        </div>
      </div>
    </div>
  );
};

RefundCompensationStepper.propTypes = {
  handleSettingCompensationAmount: PropTypes.func.isRequired,
  orderTotal: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
};

export default RefundCompensationStepper;
