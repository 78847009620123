import React, { useContext, useRef } from 'react';
import cx from 'classnames';

import * as paths from 'paths.js';
import { Text, Link } from 'components/service';
import { ReactComponent as Avatar } from 'assets/avatar.svg';
import { context as userContext } from 'context/user';
import { context as localeContext } from 'context/locale';
import * as translations from 'constants/translations';
import LangSwitch from '../LangSwitch';

const UserMenu = () => {
  const { user, setSidebarOpened, isNavBarCollapsed } = useContext(userContext);
  const { lang } = useContext(localeContext);
  return isNavBarCollapsed ? (
    <span className="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100">
      <Link omitQuery to={paths.editUserInfo} onClick={() => setSidebarOpened(false)}>
        <Avatar />
      </Link>
    </span>
  ) : (
    <>
      <div className="w-full flex items-center pb-2">
        <LangSwitch />
      </div>
      <div className="flex items-center">
        <div className="flex-shrink-0 w-full group block">
          <div className={cx('flex flex-row items-center', lang === 'ar' && 'flex-row-reverse')}>
            <span className="inline-block h-8 w-8 rounded-full overflow-hidden bg-gray-100">
              <Avatar />
            </span>
            <div className={lang === 'en' ? 'ml-3' : 'mr-3'}>
              <p className="text-sm text-gray-800">{user.name}</p>
              <div className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
                <Link omitQuery to={paths.editUserInfo} onClick={() => setSidebarOpened(false)}>
                  <Text value={translations.MY_ACCOUNT} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default UserMenu;
