import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { navigate } from '@reach/router';

import * as translations from 'constants/translations';
import { context as localeContext } from 'context/locale';
import { Button, Modal } from 'components/kit';
import { Text } from 'components/service';
import { ReactComponent as BackButton } from 'assets/back-button.svg';
import * as paths from 'paths.js';
import { useReplaceParams, useSelectedStore } from 'hooks';
import BackToTableModal from './BackToTableModal';
import { zonesPageSize, zoneTypeUtils } from '../../utils';

const Navigate = ({
  zoneController,
  setZoneController,
  setBulkZones,
  setDzTemplatesParams,
  setDzSuggestedTemplatesParams,
}) => {
  const { direction } = useContext(localeContext);
  const selectedStoreId = useSelectedStore();
  const replace = useReplaceParams();

  const resetBulk = () => {
    setBulkZones([]);
    setDzTemplatesParams({ pageSize: zonesPageSize, currentPage: 1, totalPages: null, searchValue: '' });
    setDzSuggestedTemplatesParams({ pageSize: zonesPageSize, currentPage: 1, totalPages: null });
  };

  const handleBranchOptions = () => {
    setZoneController(prevState => ({
      ...prevState,
      showZoneTemplates: false,
      showDrawerMode: false,
      circleShape: null,
      isAdd: false,
      drawerMode: null,
      isBulk: false,
      isBulkSelected: false,
      selectedSuggestedZone: null,
      selectedTemplateData: {
        coordinates: null,
        paths: null,
      },
    }));
    resetBulk();
  };

  return (
    <Modal>
      {({ open, close }) => (
        <>
          {zoneController.selectedZone && (
            <Button
              kind="tertiaryLink"
              type="button"
              isMenu
              onClick={e => {
                e.preventDefault();
                open({
                  size: 'max-w-md',
                  body: (
                    <BackToTableModal
                      close={close}
                      navigateBack={() => {
                        if (zoneController.isEdit && !zoneController.selectedSuggestedZone) {
                          if (zoneController.selectedZone.geometry.type === zoneTypeUtils.POLYGON) {
                            zoneController.shapeRef.setPaths(zoneController.prevZone.paths);
                          }
                          if (zoneController.selectedZone.geometry.type === zoneTypeUtils.POINT) {
                            zoneController.shapeRef.setCenter(zoneController.prevZone.center);
                            zoneController.shapeRef.setRadius(zoneController.prevZone.radius);
                          }
                        }
                        if (zoneController.isAdd) {
                          zoneController.shapeRef.setMap(null);
                        }
                        if (zoneController.isBulkSelected) {
                          resetBulk();
                        }
                        setZoneController({
                          ...zoneController,
                          isAdd: false,
                          isEdit: false,
                          drawerMode: null,
                          showDrawerMode: false,
                          enableDrawerMode: true,
                          shapeRef: null,
                          prevZone: {
                            paths: null,
                            center: null,
                            radius: null,
                          },
                          selectedSuggestedZone: null,
                          selectedZone: null,
                          showZoneTemplates: false,
                          isBulk: false,
                          isBulkSelected: false,
                          circleShape: null,
                          selectedTemplateData: {
                            coordinates: null,
                            paths: null,
                          },
                        });
                      }}
                    />
                  ),
                });
              }}
            >
              <BackButton className={cx('h-10 text-lg', direction === 'rtl' ? 'ml-2 rotate-180' : 'mr-2')} />
              <Text className="text-primary-base text-sm" value={translations.BRANCH_OPTIONS} />
            </Button>
          )}
          {(zoneController.showZoneTemplates || zoneController.showDrawerMode || zoneController.isBulkSelected) && (
            <Button kind="tertiaryLink" type="button" isMenu onClick={handleBranchOptions}>
              <BackButton className={cx('h-10 text-lg', direction === 'rtl' ? 'ml-2 rotate-180' : 'mr-2')} />
              <Text className="text-primary-base text-sm" value={translations.BRANCH_OPTIONS} />
            </Button>
          )}
          {!zoneController.selectedZone &&
            !zoneController.showZoneTemplates &&
            !zoneController.showDrawerMode &&
            !zoneController.isBulkSelected && (
              <Button
                kind="tertiaryLink"
                type="button"
                isMenu
                onClick={() => navigate(replace(paths.zonesTableList, selectedStoreId))}
              >
                <BackButton className={cx('h-10 text-lg', direction === 'rtl' ? 'ml-2 rotate-180' : 'mr-2')} />
                <Text className="text-primary-base text-sm" value={translations.TABLE_VIEW} />
              </Button>
            )}
          {!zoneController.isEdit && <div className="mt-2 mb-3 border-b border-gray-300" />}
        </>
      )}
    </Modal>
  );
};

Navigate.propTypes = {
  zoneController: PropTypes.shape({
    selectedZone: PropTypes.shape({
      id: PropTypes.number,
    }),
    showZoneTemplates: PropTypes.bool,
    showDrawerMode: PropTypes.bool,
    isEdit: PropTypes.bool,
    isBulkSelected: PropTypes.bool,
    isAdd: PropTypes.bool,
    selectedSuggestedZone: PropTypes.shape({}),
  }).isRequired,
  setZoneController: PropTypes.func.isRequired,
  setBulkZones: PropTypes.func.isRequired,
  setDzTemplatesParams: PropTypes.func.isRequired,
  setDzSuggestedTemplatesParams: PropTypes.func.isRequired,
};

export default Navigate;
