import React, { useState, useContext } from "react"
import { Query } from "@apollo/client/react/components"
import { Spinner } from "components/kit"
import { useSelectedStore } from "hooks/index"
import { context as userContext } from "context/user"
import moment from "moment"
import * as schemas from "../schemas"
import * as translations from "constants/translations"
import { Text } from "components/service"

export default ({ status }) => {
  const selectedStoreId = useSelectedStore()
  const { selectedStore: { currency } = {} } = useContext(userContext)
  const cellClassName = "p-3 px-5 border-gray-400"
  const headCellClassName = "text-left font-semibold p-3 px-5"

  const [visibility, setVisibility] = useState(3)

  const loadmore = event => {
    event.preventDefault()
    setVisibility(visibility + 3)
  }

  return (
    <Query
      query={schemas.ORDERS}
      variables={{ storeId: selectedStoreId.toString() }}
    >
      {res =>
        res.loading ? (
          <Spinner />
        ) : (
          <table className="w-full p-2 rounded bg-white shadow text-md">
            <tbody>
              <tr className="border-b">
                <th className={headCellClassName}>
                  <Text value={translations.CUSTOMER_NAME} className="inline" />
                </th>
                <th className={headCellClassName}>
                  <Text value={translations.TOTAL_ORDERS} className="inline" />
                </th>
                <th className={headCellClassName}>
                  <Text value={translations.TOTAL_VALUE} className="inline" />
                </th>
                <th className={headCellClassName}>
                  <Text
                    value={translations.LAST_ORDER_DATE}
                    className="inline"
                  />
                </th>
                <th className={headCellClassName}>
                  <Text
                    value={translations.FIRST_ORDER_DARE}
                    className="inline"
                  />
                </th>
              </tr>
              {res.data.orders.orders.slice(0, visibility).map((item, i) => (
                <tr
                  className={
                    "border-b hover:bg-orange-100" +
                    (i % 2 ? " bg-white " : " bg-gray-100 ")
                  }
                  key={i}
                >
                  <td className={cellClassName}>{item.number}</td>
                  <td className={cellClassName}>
                    {moment(item.createdAt).format("hh:mm A - DD MMM YYYY")}
                  </td>
                  <td className={cellClassName + " font-semibold"}>
                    {item.total.toFixed(currency.decimals)}
                    <Text value={currency} className="inline" />
                  </td>
                  <td className={cellClassName}>{item.paidThrough}</td>
                  <td className={cellClassName}>
                    <span className="capitalize py-1 px-3 rounded-full border border-blue-200 text-sm bg-blue-100 text-primary-base">
                      {item.status}
                    </span>
                  </td>
                </tr>
              ))}
              {visibility < res.data.orders.orders.length && (
                <tr className="">
                  <td
                    colSpan={5}
                    className="bg-gray-100 text-center p-3"
                    onClick={loadmore}
                  >
                    <span className="capitalize p-auto text-sm text-grey-600 w-full block cursor-pointer">
                      <Text value={translations.SHOWING} className="inline" />{" "}
                      {visibility}{" "}
                      <Text value={translations.OF} className="inline" />{" "}
                      {res.data.orders.orders.length}{" "}
                      <Text value={translations.RESULTS} className="inline" />
                    </span>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )
      }
    </Query>
  )
}
