const DATE_RANGES = {
  ZERO: '0_day',
  TODAY: 'today',
  SEVEN_DAYS: '7_day',
  THIRTY_DAYS: '30_day',
  NINETY_DAYS: '90_day',
  ALL_TIME: 'all_time',
  CUSTOM: 'custom',
  ALL: 'all',
};

export default DATE_RANGES;
