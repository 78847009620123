import { gql } from 'graphql-request';

const assignDriverToOrderMutation = gql`
  mutation(
    $driverId: Int
    $orderNumber: String!
    $courierId: Int
    $userId: Int
    $scheduledDeliveryAt: String
    $notifyUser: Boolean
  ) {
    updateOrder(
      driverId: $driverId
      orderNumber: $orderNumber
      courierId: $courierId
      userId: $userId
      scheduledDeliveryAt: $scheduledDeliveryAt
      notifyUser: $notifyUser
    ) {
      order {
        number
      }
    }
  }
`;

export default assignDriverToOrderMutation;
