import React, { useState, useContext } from "react"
import cx from "classnames"
import { Text } from "components/service"
import { DragHandle } from "components/kit"
import * as translations from "constants/translations"
import { ReactComponent as ProductIcon } from "assets/product-place-holder.svg"
import ProductForm from "../AdvancedCreation/ProductForm"
import { useMobile } from "hooks"
import { Modal } from "react-responsive-modal"
import "react-responsive-modal/styles.css"
import { SortableContainer, SortableElement } from "react-sortable-hoc"
import { context } from "./CategoryList"

const ProductsList = SortableContainer(
  ({
    products,
    lang,
    direction,
    branches,
    setSidePanelComponent,
    notifications,
    handleOnItemClick,
    open,
    close,
    storeId,
    showMore,
    setProductCategory,
    categoryID,
    langSelected,
    refetchMenuPageData,
    productCategory,
    currency,
    sidePanelComponent,
  }) => {
    return (
      <div>
        <AddItem
          branches={branches}
          setSidePanelComponent={setSidePanelComponent}
          sidePanelComponent={sidePanelComponent}
          notifications={notifications}
          lang={lang}
          open={open}
          close={close}
          refetchMenuPageData={refetchMenuPageData}
          productCategory={productCategory}
          setProductCategory={setProductCategory}
          storeId={storeId}
          categoryID={categoryID}
        />
        {products?.map((item, i) => (
          <Product
            langSelected={langSelected}
            i={i}
            key={`product-${item.id}-nameEn${item.titleEn}-nameAr${item.titleAr}-ofIndex${i}-ofCat-${categoryID}`}
            item={item}
            index={i}
            lang={lang}
            direction={direction}
            handleOnItemClick={handleOnItemClick}
            open={open}
            close={close}
            storeId={storeId}
            refetchMenuPageData={refetchMenuPageData}
            productCategory={productCategory}
            currency={currency}
          />
        ))}
        {showMore}
      </div>
    )
  }
)

const AddItem = ({
  branches,
  notifications,
  setSidePanelComponent,
  lang,
  close,
  storeId,
  setProductCategory,
  categoryID,
  direction,
  refetchMenuPageData,
  productCategory,
  sidePanelComponent,
}) => {
  const isMobile = useMobile()
  const [open, setOpen] = useState(false)
  const onOpenModal = () => setOpen(true)
  const onCloseModal = () => setOpen(false)
  const closeIcon = (
    <i
      className="material-icons text-zyda-grey-30 text-base cursor-pointer"
      onClick={() => setOpen(false)}
    >
      close
    </i>
  )
  return (
    <>
      <div
        className={cx(
          "flex justify-between bg-white  border-t border-gray-300 px-6 cursor-pointer align-center py-4 hover:bg-gray-100",
          lang === "ar" && "flex-reverse",
          "items-center",
        )}
        style={{ direction }}
        onClick={() => {
          setProductCategory(categoryID)

          if (branches === null) {
            notifications.show(
              <Text
                value={translations.YOU_HAVE_ADD_BUSINESS_LOCATION_FIRST}
              />,
              "info"
            )
          } else {
            setSidePanelComponent({ component: 2, id: null })
            if (isMobile) {
              onOpenModal()
            }
          }
        }}
      >
        <div
          className={cx(
            lang === "ar" ? "ml-4 mr-2" : "ml-2 mr-3",
            "w-10 h-10",
            "relative text-center"
          )}
        >
          <i className="material-icons absolute flex items-center h-full w-full justify-center text-zyda-grey-400 font-medium">
            add
          </i>
        </div>
        <Text value={translations.ADD_PRODUCT} className="font-medium hover:text-primary-base" />
      </div>
      <Modal
        open={open}
        onClose={onCloseModal}
        classNames={{
          modal: "customModal",
          closeButton: lang == "ar" && "customClose",
        }}
        center
        closeIcon={closeIcon}
      >
        <header>
          <div
            className={cx(
              "flex items-center justify-between px-4 mb-4 py-2 ",
              lang === "ar" && "flex-row-reverse"
            )}
          >
            <div>
              <div className="font-semibold text-lg">
                <Text value={translations.ADD_PRODUCT} />
              </div>
            </div>
          </div>
        </header>
        <ProductForm
          id={null}
          storeId={storeId}
          setSidePanelComponent={setSidePanelComponent}
          onCancel={close}
          refetchMenuPageData={refetchMenuPageData}
          productCategory={productCategory}
          sidePanelComponent={sidePanelComponent}
        />
      </Modal>
    </>
  )
}

const Product = SortableElement(
  ({
    i,
    lang,
    handleOnItemClick,
    item,
    direction,
    close,
    storeId,
    langSelected,
    refetchMenuPageData,
    productCategory,
    currency,
  }) => {
    const { selectedProductId } = useContext(context)
    const isMobile = useMobile()
    const [open, setOpen] = useState(false)
    const onOpenModal = () => setOpen(true)
    const onCloseModal = () => setOpen(false)
    const closeIcon = (
      <i
        className="material-icons text-zyda-grey-30 text-base cursor-pointer"
        onClick={() => setOpen(false)}
      >
        close
      </i>
    )
    return (
      <>
        <div
          key={i}
          className={cx(
            "flex justify-between bg-white  border-t border-gray-300 px-6 cursor-pointer align-center py-4 hover:bg-gray-100",
            lang === "ar" && "flex-reverse",
            "items-center",
            selectedProductId === item.id && "bg-gray-200"
          )}
          onClick={() => {
            handleOnItemClick(item.id)
            if (isMobile) {
              onOpenModal()
            }
          }}
        >
          <DragHandle isMenu />
          <div className="flex w-full items-center">
            <div className={cx(lang === "ar" ? "ml-4" : "mr-4", "w-10 h-10")}>
              {item.photoUrl ? (
                <img
                  className={cx("h-full w-full rounded-none overflow-hidden")}
                  src={item.photoUrl}
                  alt={item.title}
                />
              ) : (
                <ProductIcon />
              )}
            </div>
            <div className="flex justify-between items-center w-full">
              <div className="flex flex-col justify-start">
                <div
                  style={{
                    direction,
                  }}
                  className="font-medium text-sm"
                >
                  {langSelected === "en" ? item.titleEn : item.titleAr}
                </div>
                <span className="text-xs text-zyda-grey-400 pt-1">
                  <Text
                    value={
                      item.published
                        ? translations.PUBLISHED
                        : translations.UNPUBLISHED
                    }
                  />
                </span>
              </div>
              <div
                className={cx(
                  "flex text-sm font-medium text-right",
                  langSelected === "ar" && "flex-reverse"
                )}
                style={{
                  direction,
                }}
              >
                <div>
                  {langSelected === "en" ? currency.titleEn : currency.titleAr}
                </div>
                <div className="px-1">{item.originalPrice}</div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          open={open}
          onClose={onCloseModal}
          closeIcon={closeIcon}
          classNames={{
            modal: "customModal",
            closeButton: lang == "ar" && "customClose",
          }}
          className="text-xs text-right font-medium"
        >
          <header>
            <div
              className={cx(
                "flex items-center justify-between px-4 mb-4 py-2 ",
                lang === "ar" && "flex-row-reverse"
              )}
            >
              <div>
                <div className="font-semibold text-lg">
                  <Text value={translations.EDIT_PRODUCT} />
                </div>
              </div>
            </div>
          </header>
          <ProductForm
            id={item.id}
            storeId={storeId}
            onCancel={close}
            refetchMenuPageData={refetchMenuPageData}
            productCategory={productCategory}
          />
        </Modal>
      </>
    )
  }
)

export default ProductsList
