import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { context as localeContext } from 'context/locale';
import { ReactComponent as SearchIcon } from 'assets/search.svg';
import { Input } from 'components/form/elements';
import { SEARCH_ZONE_NAME } from 'constants/translations';

const ZonesSearch = ({ tableViewController, setTableViewController }) => {
  const { translate, direction } = useContext(localeContext);
  return (
    <>
      <div className="pb-2 mb-2 flex justify-between items-center" style={{ direction }}>
        <Input
          type="text"
          search
          icon={<SearchIcon />}
          width="full"
          value={tableViewController.zoneSearchValue}
          onChange={e => {
            const { value } = e.target;
            setTableViewController(prevState => ({ ...prevState, zoneSearchValue: value }));
          }}
          placeholder={translate(SEARCH_ZONE_NAME)}
        />
      </div>
    </>
  );
};
ZonesSearch.propTypes = {
  tableViewController: PropTypes.shape({
    zoneSearchValue: PropTypes.string,
  }).isRequired,
  setTableViewController: PropTypes.func.isRequired,
};
export default ZonesSearch;
