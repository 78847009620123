export const PAYMENT_ENUMS = {
  CREDIT_CARD: 1,
  KNET: 2,
  BENEFIT: 3,
  MADA: 4,
  APPLE_PAY: 5,
  MEEZA: 6,
};

export const PAYMENT_STATUS_ENUMS = {
  PENDING: 'pending',
  REJECTED: 'rejected',
  APPROVED: 'approved',
};
