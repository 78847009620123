import React from "react"
import * as yup from "yup"
import * as translations from "constants/translations"
import { Text } from "components/service"

export const initialValues = {
  asap: true,
  later: false,
  timeInterval: [60, "mins"],
  maxScheduleTime: [7, "days"],
  isAutoAccept: false,
  sendAsGiftEnabled: false,
}

export const validationSchema = yup.object().shape({
  timeInterval: yup
    .string()
    .required(<Text value={translations.TIME_SLOT_INTERVAL_REQ} />)
    .nullable(),
  maxScheduleTime: yup
    .string()
    .required(<Text value={translations.MAX_SCHEDULE_TIME_REQ} />)
    .nullable(),
})
