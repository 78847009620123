import { gql } from "@apollo/client"

export const DELIVERY_ZONE = gql`
  query($storeId: String!, $branchId: String!, $deliveryZoneId: String!) {
    deliveryZone(
      restaurantId: $storeId
      branchId: $branchId
      deliveryZoneId: $deliveryZoneId
    ) {
      id
      countryId
      deliveryTime
      deliveryFee
      minimumOrder
      scheduleMaxPeriod
      areas {
        id
      }
      openingHours {
        day
        offDay
        openAt
        closeAt
      }
    }
  }
`

export const UPDATE_DELIVERY_ZONE = gql`
  mutation(
    $zoneId: String!
    $restaurantId: String!
    $branchId: String!
    $countryId: String
    $deliveryTime: Int
    $deliveryFee: Float
    $minimumOrder: Float
    $scheduleMaxPeriod: Int
    $openingHours: [WorkingDay]
    $areasIds: [Int]
  ) {
    updateDeliveryZone(
      id: $zoneId
      restaurantId: $restaurantId
      branchId: $branchId
      countryId: $countryId
      deliveryTime: $deliveryTime
      deliveryFee: $deliveryFee
      minimumOrder: $minimumOrder
      scheduleMaxPeriod: $scheduleMaxPeriod
      openingHours: $openingHours
      areasIds: $areasIds
    ) {
      id
      countryId
      deliveryTime
      deliveryFee
      minimumOrder
      scheduleMaxPeriod
      openingHours {
        day
        openAt
        closeAt
        offDay
      }
      areas {
        id
      }
    }
  }
`
export const ADD_AREAS_TO_ZONE = gql`
  mutation(
    $ids: [Int!]
    $branchId: String!
    $restaurantId: String!
    $deliveryZoneId: String!
  ) {
    addAreasToDeliveryZone(
      ids: $ids
      restaurantId: $restaurantId
      branchId: $branchId
      deliveryZoneId: $deliveryZoneId
    ) {
      id
    }
  }
`
