import React from "react"
import cx from "classnames"

export default ({ children, modalFooter, isMenu = false, refund = false }) => (
  <div
    className={cx(
      refund || "flex flex-row-reverse",
      isMenu && "pb-0 pt-4",
      modalFooter
        ? " border-t border-gray-300 mx-4 mt-4 " +
            (!isMenu && " pb-8 mx-4 pt-4 ")
        : isMenu
        ? "mt-0 pt-4 "
        : refund || "mt-8 px-4 pt-3",
        refund && "border-t border-gray-300 mx-4 mt-4"
    )}
  >
    {children}
  </div>
)
