import cache from '../cache';
import { ORDER } from 'components/common/orders/Table/schemas';

const updateDeliveryCourierCache = (orderVariables, updatedOrder) => {
  cache.writeQuery({
    query: ORDER,
    variables: orderVariables,
    data: {
      order: updatedOrder,
    },
  });
};

export default updateDeliveryCourierCache;
