import { gql } from '@apollo/client';

export const CANCEL = gql`
  mutation($storeId: String!, $orderId: Int!, $status: OrderStatus!, $cancellationReason: Int, $otherReason: String) {
    updateOrderStatus(
      restaurantId: $storeId
      id: $orderId
      status: $status
      cancellationReason: $cancellationReason
      otherReason: $otherReason
    ) {
      id
      status
      cancellationReason
      otherReason
      stateHistories {
        state
        createdAt
        actionBy
      }
    }
  }
`;
export const CANCEL_ASYNC = gql`
  mutation($storeId: String!, $orderId: Int!, $status: OrderStatus!, $cancellationReason: Int, $otherReason: String) {
    updateOrderStatusAsync(
      restaurantId: $storeId
      id: $orderId
      status: $status
      cancellationReason: $cancellationReason
      otherReason: $otherReason
    ) {
      id
      status
      cancellationReason
      otherReason
      inBetweenTransitions
      stateHistories {
        state
        createdAt
        actionBy
      }
    }
  }
`;
