import React from "react"
import cx from "classnames"
import { Text } from "components/service"
import * as translations from "constants/translations"
import { handleTrackingType } from "./utils"

const ItemTrackingTableHead = ({ lang, isMobile, trackingType }) => {
  
  const hasATrackingTypeValue = handleTrackingType(trackingType)
  const headCellClassName = cx(
    hasATrackingTypeValue ? "w-3/10" : "w-1/2",
    "p-3 flex-1 text-gray-700 font-normal text-justify whitespace-nowrap border-b border-gray-300",
    lang === "ar" ? "text-right" : "text-left",
    isMobile && "text-sm"
  )
  return (
    <thead>
      <tr className="bg-zyda-grey-15">
        <th className={headCellClassName}>
          <Text
            value={translations.VARIANT_NAME}
            className="whitespace-nowrap inline-block"
          />
        </th>
        {hasATrackingTypeValue && (
          <th className={headCellClassName}>
            <Text
              value={translations.QUANTITY}
              className="whitespace-nowrap inline-block"
            />
          </th>
        )}
        <th className={headCellClassName}>
          <Text
            value={translations.STATUS_STOCK}
            className="whitespace-nowrap inline-block"
          />
        </th>
        <th className={cx(headCellClassName, 'w-1/10')}>
        </th>
      </tr>
    </thead>
  )
}

export default ItemTrackingTableHead
