import React from 'react';
import PropTypes from 'prop-types';

import * as translations from 'constants/translations';
import { Text } from 'components/service';

const LearnMore = ({ link }) => (
  <div className="flex flex-row">
    <Text full={false} value={translations.FOR_MORE_INFORMATION} className="hidden sm:inline" />
    <a href={link} target="_blank" className="flex-shrink-0" rel="noreferrer">
      <Text className="underline text-primary-base px-2" value={translations.LEARN_MORE} />
    </a>
  </div>
);

LearnMore.propTypes = {
  link: PropTypes.string.isRequired,
};

export default LearnMore;
