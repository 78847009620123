import React, { useContext } from 'react';
import { context as localeContext } from 'context/locale';
import cx from 'classnames';
import Container from '../Container';

const Section = ({ title, description, children, bottomOffset = 6, disableBorder = false }) => {
  const { lang } = useContext(localeContext);
  const direction = lang === 'ar' ? 'rtl' : 'ltr';
  return (
    <div style={{ direction }} className={cx('border border-gray-300 p-4', bottomOffset && `mb-${bottomOffset}`)}>
      <p
        className={cx('text-lg font-semibold px-4 -mx-4', disableBorder ? 'pb-2' : 'pb-4 border-b border-gray-300')}
      >
        {title}
      </p>
      {description && <p className={cx('text-gray-600', disableBorder ? 'mb-1' : 'mt-2 mb-3 ')}>{description}</p>}
      <div className="mt-3">
        <Container>{children}</Container>
      </div>
    </div>
  );
};

export default Section;
