import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import cx from 'classnames';
import { debounce } from 'lodash';

import * as translations from 'constants/translations';
import { Breadcrumbs, Layout } from 'components/common/dashboard';
import { context as localeContext } from 'context/locale';
import { Text } from 'components/service';
import EmptyState from 'components/common/EmptyState';
import { ReactComponent as WalletEmpty } from 'assets/wallet-empty-state.svg';
import { context as userContext } from 'context/user';
import SearchInput from '../../../common/components/SearchInput';
import { useScrollBottom } from '../../../hooks';
import WalletDataSummary from './components/WalletDataSummary';
import WalletTransactionsTable from './components/WalletTransactionsTable';
import { WALLET_TRANSACTIONS_QUERY } from './schema';
import { BACKEND_GRAPHQL_SERVER } from '../../../constants';
import { Spinner } from '../../../components/kit';

const Wallet = () => {
  const { lang, direction, translate } = useContext(localeContext);
  const { selectedStore } = useContext(userContext);
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  const [searchValue, setSearchValue] = useState();

  const transactionsVariables = {
    subdomain: selectedStore?.subdomain,
  };

  const { data, loading, fetchMore: transactionsFetch } = useQuery(WALLET_TRANSACTIONS_QUERY, {
    variables: {
      ...transactionsVariables,
      phoneNumber: searchValue,
    },
    context: {
      serverName: BACKEND_GRAPHQL_SERVER,
    },
  });
  const { walletTransactions, hasNextPage } = data?.storeWalletTransactions || {};
  const isEmpty = !walletTransactions?.length && !searchValue;

  useEffect(() => {
    if (!isFetchingMore) return;

    transactionsFetch({
      variables: {
        ...transactionsVariables,
        lastRecordId: parseInt(walletTransactions[walletTransactions.length - 1].id),
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          storeWalletTransactions: {
            ...prev.storeWalletTransactions,
            hasNextPage: fetchMoreResult.storeWalletTransactions.hasNextPage,
            walletTransactions: [
              ...prev.storeWalletTransactions.walletTransactions,
              ...fetchMoreResult.storeWalletTransactions.walletTransactions,
            ],
          },
        };
      },
    }).finally(() => {
      setIsFetchingMore(false);
    });
  }, [isFetchingMore]);

  useScrollBottom(() => {
    if (loading || isFetchingMore || !hasNextPage) return;

    setIsFetchingMore(true);
  });

  const debouncedSearch = debounce(value => setSearchValue(value), 500);

  const handleSearchValueChange = e => {
    debouncedSearch(e.target.value);
  };

  return (
    <Layout top={<Breadcrumbs path={translations.breadcrumbs.WALLET} />} direction={direction}>
      <WalletDataSummary selectedStore={selectedStore} />
      {isEmpty && !loading && (
        <EmptyState
          iconComponent={
            <>
              <div className="mt-64">
                <WalletEmpty />
              </div>
              <Text value={translations.WALLET_EMPTY} className="font-semibold text-base" />
              <Text value={translations.WALLET_EMPTY2} className="text-sm" />
            </>
          }
        />
      )}
      {!isEmpty && (
        <>
          <div className="flex my-4" style={{ height: '40px' }}>
            <Text value={translations.TRANSACTIONS_HISTORY} className="text-xl font-medium" />
            <div
              className={cx(
                'w-full flex',
                lang === 'ar' && 'flex-row-reverse',
                'border rounded-none border-gray-300 px-2',
              )}
            >
              <SearchInput
                lang={lang}
                setSearchValue={handleSearchValueChange}
                placeholder={translate(translations.PHONE_NUMBER_ORDER)}
              />
            </div>
          </div>
          {loading ? (
            <Spinner />
          ) : (
            <WalletTransactionsTable
              transactions={walletTransactions}
              lang={lang}
              translate={translate}
              selectedStore={selectedStore}
            />
          )}
        </>
      )}
    </Layout>
  );
};

export default Wallet;
