export const maxAcceptanceNotificationType = {
  EMAIL: 'email_notification',
  EMAIL_AND_SMS: 'email_and_sms_notification',
};

export const timeIntervalType = {
  MINUTES: 'mins',
  HOURS: 'hrs',
  DAYS: 'days',
};
