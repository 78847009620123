import React, { useContext } from "react"
import { Text } from "components/service"
import { Formik, Form } from "formik"
import { Field } from "components/service"
import { Label, Container, Footer } from "components/form/generic"
import { RadioList } from "components/form/elements"
import { context as userContext } from "context/user"
import { Layout, Breadcrumbs } from "components/common/dashboard"
import { Progress, Button } from "components/kit"
import * as translations from "constants/translations"
import { breadcrumbs } from "constants/translations"
import * as paths from "paths.js"

export default () => {
  const { selectedStore } = useContext(userContext)
  const currency = selectedStore.currency

  return (
    <Layout
      top={
        <Breadcrumbs
          links={[paths.previewProductCreation]}
          path={breadcrumbs.SETUP_PRODUCT_CREATION}
        />
      }
    >
      <div className="flex m-3">
        <div className="p-4 rounded bg-white w-full max-w-4xl">
          <Progress
            active={2}
            items={[
              <Text value={translations.BASIC} />,
              <Text value={translations.CUSTOMIZE} />,
              <Text value={translations.PREVIEW} />,
            ]}
          />

          <Formik>
            <Form className="mt-4">
              <div className="max-w-xs mx-auto p-4 rounded bg-white shadow">
                <Container>
                  <Label
                    title={<Text value={translations.PREVIEW} />}
                    subtitle={
                      <Text value={translations.YOU_CAN_PREVIEW_PRODUCT} />
                    }
                  />
                  <img
                    src="https://images.pexels.com/photos/35793/burger-cheese-food-hamburger.jpg"
                    className="w-30 h-30"
                  />

                  <Label
                    title="Cheese Burger"
                    subtitle="Juicy Burger, sandwich and combo"
                  ></Label>
                  <Label title="Size"></Label>

                  <div className="flex flex-col">
                    <div className="p-2 flex-1 flex flex-row justify-between">
                      <Field
                        name="required"
                        disabled
                        items={[
                          {
                            title: <Text value={translations.VARIANTS} />,

                            value: true,
                          },
                        ]}
                        component={RadioList}
                      />
                      <Text
                        value={currency}
                        className="ml-5 inline-block text-right"
                      />
                    </div>
                    <div className="p-2 flex-1 flex flex-row justify-between">
                      <Field
                        name="required"
                        disabled
                        items={[
                          {
                            title: <Text value={translations.VARIANTS} />,

                            value: true,
                          },
                        ]}
                        component={RadioList}
                      />
                      <Text
                        value={currency}
                        className="ml-5 inline-block text-right"
                      />
                    </div>
                    <div className="p-2 flex-1 flex flex-row justify-between">
                      <Field
                        name="required"
                        disabled
                        items={[
                          {
                            title: <Text value={translations.VARIANTS} />,

                            value: true,
                          },
                        ]}
                        component={RadioList}
                      />
                      <Text
                        value={currency}
                        className="ml-5 inline-block text-right"
                      />
                    </div>
                  </div>
                </Container>
              </div>
              <Footer>
                <div className="flex-2">
                  <Button kind="secondary" size="base">
                    <Text value={translations.CANCEL} />
                  </Button>
                </div>
                <div className="flex-1 flex items-stretch flex-row justify-end">
                  <div className="mr-3">
                    <Button kind="secondary" size="base">
                      <Text value={translations.BACK} />
                    </Button>
                  </div>
                  <Button kind="primary" size="base">
                    <Text value={translations.SAVE} />
                  </Button>
                </div>
              </Footer>
            </Form>
          </Formik>
        </div>
      </div>
    </Layout>
  )
}
