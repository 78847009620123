import React, { useContext } from 'react';
import cx from 'classnames';

import { context as userContext } from 'context/user';
import { context as localeContext } from 'context/locale';
import { useGtm } from 'hooks/index';
import { Text, Field, Link } from 'components/service';
import { Label, Row, Container } from 'components/form/generic';
import * as paths from 'paths.js';
import { Input, PhoneInput, RadioList, CheckboxList } from 'components/form/elements';
import * as translations from 'constants/translations';
import { Button } from 'components/kit';

export default ({ isOperator, comingFromDropDown = false }) => {
  const { lang } = useContext(localeContext);
  const { branches, signOut, user } = useContext(userContext);
  const events = useGtm();

  return (
    <div className="pb-32 pt-4 md:pb-12">
      <Container>
        <Row>
          <Label title={<Text value={translations.FULL_NAME} />}>
            <Field
              type="text"
              name="name"
              placeholder={lang === 'en' ? translations.ENTER_FULL_NAME[0] : translations.ENTER_FULL_NAME[1]}
              component={Input}
            />
          </Label>
        </Row>
        <Row>
          <Label title={<Text value={translations.EMAIL} />}>
            <div className="w-full">
              <Field
                type="text"
                name="email"
                placeholder={lang === 'en' ? translations.ENTER_EMAIL[0] : translations.ENTER_EMAIL[1]}
                component={Input}
              />
              {comingFromDropDown && (
                <div className="flex justify-end font-medium text-sm text-primary-base ">
                  <Link to={paths.changePassword}>
                    <Text value={translations.CHANGE_PASSWORD} />
                  </Link>
                </div>
              )}
            </div>
          </Label>
        </Row>
        <Row>
          <Label title={<Text value={translations.PHONE_NUMBER} />}>
            <Field
              type="text"
              name="phoneNumber"
              placeholder={lang === 'en' ? translations.ENTER_PHONE[0] : translations.ENTER_PHONE[1]}
              component={PhoneInput}
            />
          </Label>
        </Row>

        {comingFromDropDown || (
          <Row>
            <Label title={<Text value={translations.USER_ROLE} />}>
              <Field
                name="role"
                items={[
                  {
                    value: 'owner',
                    title: <Text value={translations.OWNER} />,
                  },
                  {
                    value: 'ops_manager',
                    title: <Text value={translations.MANAGER} />,
                  },
                  {
                    value: 'operator',
                    title: <Text value={translations.OPERATOR} />,
                  },
                ]}
                component={RadioList}
              />
            </Label>
          </Row>
        )}
        {!comingFromDropDown && isOperator && branches && (
          <Row>
            <Label title={<Text value={translations.OPERATORS_BRANCHES} />}>
              <div className={cx('w-full', branches.length > 7 && 'overflow-y-scroll overflow-x-hidden h-48')}>
                <Field
                  name="branches"
                  component={CheckboxList}
                  items={branches.map(branch => ({
                    value: branch.id,
                    title: <Text value={branch} />,
                  }))}
                />
              </div>
            </Label>
          </Row>
        )}
        {comingFromDropDown && (
          <Button
            type="button"
            kind="secondaryError"
            onClick={() => {
              signOut();
              events.signOut();
            }}
          >
            <Text value={translations.SIGN_OUT} />
          </Button>
        )}
      </Container>
    </div>
  );
};
