import React, { createContext, useState } from 'react';
import { apollo, getCookie } from 'utils';
import * as userService from 'services/user';
import { THIRD_PARTY_TYPE_ENUM } from 'utils/enums';
import { ORDER_RING_OPTIONS } from 'constants/orderRingerEnums';
import { accessObject } from 'constants/featureAccessMatrix';
import * as Sentry from '@sentry/react';

export const context = createContext();

export const Provider = ({ children }) => {
  const [isAuthenticated, setAuthenticated] = useState(userService.isAuthenticated());
  const [user, setUser] = useState();
  const [isSidebarOpened, setSidebarOpened] = useState(false);
  const [stopRing, setStopRing] = useState(ORDER_RING_OPTIONS.STOP);
  const [branches, setBranches] = useState();
  const [unpaidInvoices, setUnpaidInvoices] = useState();
  const [stores, setStores] = useState();
  const [trackItemType, setTrackItemType] = useState(false);
  const [selectedStore, setSelectedStore] = useState();
  const [legalData, setLegalData] = useState();
  const [settings, setSettings] = useState();
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState('active');
  const [campaignsPermission, setCampaignsPermission] = useState();
  const [showSlideOver, setSlideOver] = useState(false);
  const [isNavBarCollapsed, setCollapseNavBar] = useState(false);
  const [facebookConnect, setFacebookConnect] = useState({ connecting: false, query: '' });
  const pos =
    selectedStore?.restaurantCourierSetting?.find(_c => _c.businessType === THIRD_PARTY_TYPE_ENUM.POS) || null;
  const courier =
    selectedStore?.restaurantCourierSetting?.find(_c => _c.businessType === THIRD_PARTY_TYPE_ENUM.Delivery) || null;
  const [dzmsSelectedBranch, setDzmsSelectedBranch] = useState(null);

  return (
    <context.Provider
      value={{
        isAuthenticated,
        user,
        passwordChanged,
        setUser,
        branches,
        stopRing,
        setBranches,
        setStopRing,
        settings,
        setSettings,
        isSidebarOpened,
        setSidebarOpened,
        unpaidInvoices,
        setTrackItemType,
        trackItemType,
        setUnpaidInvoices,
        stores,
        setStores,
        setSelectedStore,
        selectedStore,
        setLegalData,
        legalData,
        showSlideOver,
        subscriptionStatus,
        setSlideOver,
        facebookConnect,
        setFacebookConnect,
        isNavBarCollapsed,
        setCollapseNavBar,
        subscriptionStatusSetting: status => {
          setSubscriptionStatus(status);
        },
        campaignsPermission,
        setCampaignsPermission,
        hasRole: role => user && !!user?.roles?.find(item => item.role === role),
        getRole: selectedStoreId => {
          const found = user.roles && user.roles.find(({ resourceId }) => resourceId === selectedStoreId);

          return found && found.role;
        },
        signIn: user => {
          setUser(user);
          if (process.env.REACT_APP_SEGMENT_WRITE_KEY) {
            if (getCookie('ajs_user_id') !== '') {
              window.analytics.identify(
                user.id,
                {
                  createdAt: user.createdAt,
                  name: user.name,
                  email: user.email,
                  phone: user.phoneNumber,
                  roles: user.roles,
                  isSupport: user.isSupport,
                },
                {},
                () => {
                  if (window.Vitally) {
                    window.Vitally.account({
                      accountId: selectedStore.id,
                      traits: {
                        name: selectedStore.titleEn,
                      },
                    });
                    window.Vitally.user({
                      userId: user.id,
                      accountId: selectedStore.id,
                      traits: {
                        name: user.name,
                        email: user.email,
                        createdAt: user.createdAt && new Date(user.createdAt).toISOString().split('T')[0],
                      },
                    });
                    window.Vitally.nps('survey', {
                      userId: user.id,
                      productName: 'Zyda',
                    });
                  }
                },
              );
            }
          }
          userService.signIn(user.accessToken, user.id);
          Sentry.setUser({ id: user.id });
          setAuthenticated(true);
        },
        userUpdatedPassword: hasPasswordChanged => {
          setPasswordChanged(hasPasswordChanged);
        },
        signOut: () => {
          apollo.resetStore();
          apollo.onResetStore(() => {
            setAuthenticated(false);
            setUser();
            Sentry.configureScope(scope => scope.setUser(null));
            if (process.env.REACT_APP_SEGMENT_WRITE_KEY) window.analytics.reset();
            setTimeout(() => userService.signOut(), 100);
          });
        },
        isCustomerSupport: user => user && user.isSupport,
        isPosCourier: !!pos,
        isDeliveryCourier: !!courier,
        pos,
        courier,
        dzmsSelectedBranch,
        setDzmsSelectedBranch,
      }}
    >
      {children}
    </context.Provider>
  );
};
