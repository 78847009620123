import React, { useContext } from "react"
import cx from "classnames"
import { Error } from "components/form/generic"
import { context as localeContext } from "context/locale"
import { useMobile } from "hooks/index"

export default ({
  items,
  onChange,
  value,
  name,
  size = "base",
  trackPopup = false,
  error,
  trackedItem,
}) => {
  const { direction } = useContext(localeContext)

  return (
    <div>
      <div className="flex flex-row items-center">
        {items.map((item, i) => {
          const isSelected = item.value === value
          return (
            <label
              key={i}
              data-testid={`product-fulfillment-${item.value}`}
              className={cx(
                "mt-2 inline-flex items-center cursor-pointer border hover:bg-primary-base hover:text-white hover:border-primary-base",
                trackedItem && "w-1/3 text-center",
                trackPopup || 'inline-flex items-center',
                sizes[size],
                isSelected
                  ? "bg-primary-base text-white border-primary-base"
                  : "border-gray-300"
              )}
              onClick={() =>
                onChange({
                  target: {
                    name,
                    value: item.value,
                  },
                })
              }
            >
              {item.title}
            </label>
          )
        })}
      </div>
      {error && <Error direction={direction}>{error}</Error>}
    </div>
  )
}

const sizes = {
  sm: "py-1 px-3 text-sm",
  base: "py-3 px-6 text-sm",
  flex_center: "py-3 flex justify-center items-center text-sm",
  flex_center_popup: 'h-10 flex justify-center items-center text-sm',
}
