import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import * as translations from 'constants/translations';
import { context as localeContext } from 'context/locale';
import { Button } from 'components/kit';
import { Text } from 'components/service';
import { zonesPageSize } from '../../utils';

const ZonesBulkSelect = ({
  bulkZones,
  setBulkZones,
  setZoneController,
  setDzTemplatesParams,
  setDzSuggestedTemplatesParams,
}) => {
  const { direction } = useContext(localeContext);
  const handleBulkCancel = () => {
    setBulkZones([]);
    setDzTemplatesParams({ pageSize: zonesPageSize, currentPage: 1, totalPages: null, searchValue: '' });
    setDzSuggestedTemplatesParams({ pageSize: zonesPageSize, currentPage: 1, totalPages: null });
  };

  const handleSelectBulk = () => {
    setDzTemplatesParams({ pageSize: zonesPageSize, currentPage: 1, totalPages: null, searchValue: '' });
    setDzSuggestedTemplatesParams({ pageSize: zonesPageSize, currentPage: 1, totalPages: null });
    setZoneController(prevState => ({ ...prevState, isBulkSelected: true, showZoneTemplates: false }));
  };

  return (
    <div className="flex justify-between items-center mt-3 mb-3" style={{ direction }}>
      <Button
        kind="tertiary"
        type="button"
        onClick={handleBulkCancel}
        className={cx('w-1/2', direction === 'ltr' ? 'mr-4' : 'ml-4')}
      >
        <Text className="text-sm text-gray-500 font-light" value={translations.CANCEL} />
      </Button>
      <Button type="button" kind="primary" className="w-1/2" onClick={handleSelectBulk}>
        <Text className="text-sm" value={translations.ADD_MULTI_ZONES(bulkZones.length)} />
      </Button>
    </div>
  );
};

ZonesBulkSelect.propTypes = {
  bulkZones: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
    }),
  ).isRequired,
  setBulkZones: PropTypes.func.isRequired,
  setZoneController: PropTypes.func.isRequired,
  setDzTemplatesParams: PropTypes.func.isRequired,
  setDzSuggestedTemplatesParams: PropTypes.func.isRequired,
};

export default ZonesBulkSelect;
