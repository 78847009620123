import { gql } from '@apollo/client';

export const CASHBACK = gql`
  query($restaurantId: String!) {
    cashBack(restaurantId: $restaurantId) {
      totalCashbackAmount
      totalSales
    }
  }
`;

export const RESTAURANT = gql`
  query($restaurantId: String!) {
    restaurant(id: $restaurantId) {
      isCommissionBased
    }
  }
`;
