import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { NODE_ENV_PROD } from 'constants/environments';
import { Button } from 'components/kit';
import { copyToClipboard } from 'components/common/orders/Table/Order/helpers';
import { getStoreOrderfastURL, getStoreURl } from 'components/common/dashboard/Layout/utils';

const OrderfastLinksList = ({ selectedStore, links }) => {
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const { subdomain } = selectedStore;
  const orderFastLinksLink =
    process.env.REACT_APP_ENV === NODE_ENV_PROD
      ? `https://orderfa.st/${subdomain}`
      : `${getStoreURl(selectedStore)}/links`;
  const tapHereToOrderLink =
    process.env.REACT_APP_ENV === NODE_ENV_PROD ? getStoreOrderfastURL(selectedStore) : getStoreURl(selectedStore);

  useEffect(() => {
    isLinkCopied && setTimeout(() => setIsLinkCopied(false), 5000);
  }, [isLinkCopied]);

  const onOrderfastButtonClick = (e, url) => {
    e.preventDefault();
    window.open(url, '_blank');
  };

  return (
    <div className="w-full h-4/5 w-height border rounded-md">
      <div className="flex justify-between align-middle pl-4 py-3 pr-3 border-b">
        <Text className="text-gray-500 underline text-sm" value={[orderFastLinksLink, orderFastLinksLink]} />
        {isLinkCopied ? (
          <Text className="text-zyda-green-600 flex-1" value={translations.COPIED} />
        ) : (
          <Button
            size="tiny"
            kind="link"
            onClick={() => {
              copyToClipboard(orderFastLinksLink);
              setIsLinkCopied(true);
            }}
          >
            <Text className="text-primary-base" value={translations.COPY_LINK} />
          </Button>
        )}
      </div>
      <div className="bg-gray-200 w-full h-full flex items-center justify-center py-4 px-12">
        <div className="relative overflow-y-auto w-full border overflow-auto overflow-y-hidden rounded-lg border-8 border-black h-full  pt-7 bg-white">
          <div className="h-full flex flex-col items-center">
            <img src={selectedStore?.logoUrl} className="rounded-full w-16 h-16" alt="store url" />
            <Text className="text-lg text-center font-semibold" value={selectedStore} />
            <Text
              className="text-sm text-gray-400 text-center pb-5"
              value={[selectedStore?.descriptionEn, selectedStore?.descriptionAr]}
            />
            <div className="w-full px-5">
              {links?.map(link => (
                <Button
                  full
                  className="mb-4"
                  kind="quartary"
                  key={link.id}
                  onClick={e => onOrderfastButtonClick(e, link.content)}
                >
                  <Text className="text-gray-600 text-sm truncate" value={link} />
                </Button>
              ))}
            </div>
            <div className="sticky bottom-0 bg-white w-full border-t flex flex-col items-start justify-center px-5 pt-5 pb-2">
              <Button kind="primary" full onClick={e => onOrderfastButtonClick(e, tapHereToOrderLink)}>
                <Text value={translations.TAP_HERE_TO_ORDER} className="truncate" />
              </Button>
              <Text className="w-auto self-center mt-3 font-normal text-xs" value={translations.POWERED_BY_ZYDA} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

OrderfastLinksList.propTypes = {
  selectedStore: PropTypes.shape({
    fullUrl: PropTypes.string.isRequired,
    subdomain: PropTypes.string,
  }),
  links: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      linkType: PropTypes.string.isRequired,
      titleAr: PropTypes.string.isRequired,
      titleEn: PropTypes.string.isRequired,
    }),
  ),
};

export default OrderfastLinksList;
