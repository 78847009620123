import React, { useContext } from "react"
import { context as localeContext } from "context/locale"
import cx from "classnames"

export default ({
  name,
  title,
  subTitle,
  value,
  onChange,
  errorCheckBox,
  disabled,
  testId,
  containerClassName,
  checkboxClassName,
  customCheck,
}) => {
  const { lang } = useContext(localeContext)
  return (
    <div
      onClick={() =>
        onChange({
          target: {
            name,
            value: !value,
          },
        })
      }
      className={cx(
        "cursor-pointer select-none ",
        disabled && "cursor-not-allowed pointer-events-none",
        containerClassName ? containerClassName : `flex items-center`
      )}
    >
      <span
        className={cx(
          "w-4 h-4 p-2 rounded-none border flex items-center justify-center",
          disabled &&
            (!!value
              ? "border-gray-300 bg-zyda-grey-50"
              : "border-gray-300"),
          !!value
            ? (errorCheckBox ? "border-zyda-red-300" : "border-primary-base") +
                " bg-primary-base hover:border-gray-300 hover:bg-primary-100 focus:border-gray-300 focus:bg-white active:border-gray-300 active:bg-primary-100 "
            : (errorCheckBox ? "border-zyda-red-300" : "border-gray-300") +
                " hover:bg-primary-100 active:bg-primary-100",
          checkboxClassName
            ? checkboxClassName
            : `${lang === "ar" ? "ml-3" : "mr-3"}`
        )}
        data-testid={testId}
      >
        {!!value && (
          <i
            className={cx(
              "material-icons text-sm",
              disabled
                ? "text-zyda-grey-100"
                : "text-white focus:text-primary-base active:text-primary-base"
            )}
          >
            {customCheck? 'remove': 'check'}
          </i>
        )}
      </span>
      {title && (
        <span className="flex flex-col">
          <span className=""> {title} </span>
          {subTitle && subTitle}
        </span>
      )}
    </div>
  )
}
