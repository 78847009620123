import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import PropTypes from 'prop-types';

import SortableItem, { linkShape } from '../SortableItem';

const Items = ({ items, isDraggable, storeId, onEditClick, onDeleteClick }) => (
  <div>
    {items.map((item, i) => (
      <SortableItem
        key={item.id}
        index={i}
        sortIndex={i}
        storeId={storeId}
        item={item}
        onEditClick={onEditClick}
        onDeleteClick={onDeleteClick}
        disabled={!isDraggable}
      />
    ))}
  </div>
);

Items.propTypes = {
  storeId: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(linkShape).isRequired,
  onEditClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  isDraggable: PropTypes.bool,
};

const SortableItems = SortableContainer(Items);

export default SortableItems;
