import { getStoreURl } from '../components/common/dashboard/Layout/utils';


export function fbRedirectUrl (selectedStore) {
  const storeUrl = getStoreURl(selectedStore);
  const supportedCurrencies = ['USD', 'EGP', 'SAR', 'GBP', 'AED', 'QAR']
  const currency = supportedCurrencies.includes(selectedStore.currency?.titleEn) ? selectedStore.currency?.titleEn : 'USD'
  return `&extras={"setup":{"external_business_id":"${selectedStore.id}","timezone":"${selectedStore.timeZone}","currency":"${currency}","business_vertical":"FOOD_AND_DRINK"},"business_config":{"business":{"name":"${selectedStore.titleEn}"},"page_cta":{"enabled":true,"cta_button_text":"Order Food","cta_button_url":"${storeUrl}/?utm_source=fb_page_cta","below_button_text":"Powered by Zyda"},"ig_cta":{"enabled": true,"cta_button_text":"Order Food","cta_button_url":"${storeUrl}/?utm_source=ig_button_cta"},"messenger_menu":{"enabled": true,"cta_button_text":"Order Food","cta_button_url":"${storeUrl}/?utm_source=fb_msgr_cta"}},"repeat":false}`
}

