import { gql } from 'graphql-request';

const updateBulkDeliveryZoneMutation = gql`
  mutation($input: UpdateBulkDeliveryZonesInput!) {
    updateBulkDeliveryZones(input: $input) {
      deliveryZone {
        id
        zoneName
        deliveryFee
        minimumOrder
        published
        branchId
      }
      errors {
        zoneId
        errorMessage
      }
    }
  }
`;

export default updateBulkDeliveryZoneMutation;
