import { gql } from "@apollo/client"

export const USERS = gql`
  query($storeId: String!, $page: Int!, $limit: Int!, $role: RoleName) {
    users(restaurantId: $storeId, page: $page, limit: $limit, role: $role) {
      users {
        id
        name
        email
        phoneNumber
        createdAt
        roles {
          id
          role
        }
        storeRingerSetting {
          ringerType
        }
      }
      total
    }
  }
`
