import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { CreationBox, Button, Modal, More } from 'components/kit';
import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { context as userContext } from 'context/user';
import { context as localeContext } from 'context/locale';
import { context as notificationsContext } from 'context/notifications';
import { ReactComponent as EmptyMenuDesktop } from 'assets/empty-menu-desktop.svg';
import { ReactComponent as EmptyMenuMobile } from 'assets/empty-menu-mobile.svg';
import { useMobile } from 'hooks';
import CategoryForm from '../CategoryForm';
import ImportCatalog from '../ImportCatalog';
import ImportFromFoodicsModal from '../ImportFromFoodicsModal';
import ImportFromFoodicsNoBranchesModal from '../ImportFromFoodicsNoBranchesModal';

const MenuOverviewListEmptyState = ({
  refetchProduct,
  refetchCat,
  refetchMenuPageData,
  handleUpdatingCategories,
  handleDeletingCategories,
  handleConcatenatingCategories,
}) => {
  const { lang } = useContext(localeContext);
  const { branches, isPosCourier, settings, pos } = useContext(userContext);
  const posName = lang === 'en' ? pos?.courierDetails?.name : pos?.courierDetails?.nameAr;
  const notifications = useContext(notificationsContext);
  const isMobile = useMobile();

  return (
    <div className="flex flex-col mx-auto items-center">
      {isMobile ? <EmptyMenuMobile className="w-full" /> : <EmptyMenuDesktop className="w-full" />}
      <CreationBox
        title={<Text value={translations.CREATE_PRODUCT_CATALOG} />}
        description={<Text value={translations.START_ADDING_CATEGORIES_IMPORT_EXISTING_CATALOG} />}
        emptyState
        actions={[
          <Modal emptyState>
            {({ open, close }) => (
              <div className="w-full flex items-center md:flex-row">
                <div className="flex flex-col w-full md:flex-row justify-center">
                  <div className="mx-2 inline-block py-2 md:py-0">
                    <Button
                      data-testid="add-category-btn"
                      size="full"
                      onClick={() => {
                        if (branches === null) {
                          notifications.show(
                            <Text value={translations.YOU_HAVE_ADD_BUSINESS_LOCATION_FIRST} />,
                            'info',
                          );
                        } else {
                          open({
                            testId: 'add-category-modal',
                            title: <Text value={translations.ADD_CATEGORY} />,
                            body: (
                              <CategoryForm
                                emptyState
                                onCancel={close}
                                refetchMenuPageData={refetchMenuPageData}
                                handleConcatenatingCategories={handleConcatenatingCategories}
                                handleDeletingCategories={handleDeletingCategories}
                                handleUpdatingCategories={handleUpdatingCategories}
                              />
                            ),
                          });
                        }
                      }}
                      kind="primary"
                    >
                      <Text value={translations.ADD_CATEGORY} />
                    </Button>
                  </div>

                  {!isPosCourier && (
                    <div className="mx-2 inline-block">
                      <Button
                        data-testid="import-catalog-btn"
                        size="full"
                        onClick={() => {
                          if (branches === null) {
                            notifications.show(
                              <Text value={translations.YOU_HAVE_ADD_BUSINESS_LOCATION_FIRST} />,
                              'info',
                            );
                          } else {
                            open({
                              testId: 'import-catalog-modal',
                              title: <Text value={translations.IMPORT_CATALOG} />,
                              body: (
                                <ImportCatalog
                                  onCancel={close}
                                  refetchProduct={refetchProduct}
                                  refetchCat={refetchCat}
                                />
                              ),
                            });
                          }
                        }}
                        kind="secondary"
                      >
                        <Text value={translations.IMPORT_CATALOG} />
                      </Button>
                    </div>
                  )}
                  {isPosCourier && pos?.isMenuPull && !settings?.isImportingPosMenu && (
                    <More
                      kind="secondary"
                      size="full"
                      icon="keyboard_arrow_down"
                      moreButtonText={translations.IMPORT}
                      items={[
                        {
                          testId: 'list-products-import-catalog-btn',
                          title: <Text value={translations.IMPORT_CATALOG} />,
                          onClick: () => {
                            if (branches === null) {
                              notifications.show(
                                <Text value={translations.YOU_HAVE_ADD_BUSINESS_LOCATION_FIRST} />,
                                'info',
                              );
                            } else {
                              open({
                                testId: 'list-products-import-catalog-modal',
                                title: <Text value={translations.IMPORT_CATALOG} />,
                                body: <ImportCatalog onCancel={close} refetchMenuPageData={refetchMenuPageData} />,
                              });
                            }
                          },
                        },
                        {
                          testId: 'list-products-import-catalog-from-foodics-btn',
                          title: <Text value={translations.IMPORT_CATALOG_FROM_POS(posName)} />,
                          onClick: () => {
                            if (branches === null) {
                              open({
                                testId: 'list-products-sort-catalog-import-foodics-no-branches-modal',
                                body: <ImportFromFoodicsNoBranchesModal onCancel={close} />,
                              });
                            } else {
                              open({
                                testId: 'list-products-sort-catalog-import-foodics-modal',
                                title: <Text value={translations.IMPORT_CATALOG_FROM_POS(posName)} />,
                                body: <ImportFromFoodicsModal onCancel={close} />,
                              });
                            }
                          },
                        },
                      ]}
                    />
                  )}
                </div>
              </div>
            )}
          </Modal>,
        ]}
      />
    </div>
  );
};

MenuOverviewListEmptyState.propTypes = {
  refetchProduct: PropTypes.func,
  refetchCat: PropTypes.func,
  refetchMenuPageData: PropTypes.func.isRequired,
  handleUpdatingCategories: PropTypes.func.isRequired,
  handleDeletingCategories: PropTypes.func.isRequired,
  handleConcatenatingCategories: PropTypes.func.isRequired,
};

export default MenuOverviewListEmptyState;
