import gql from "graphql-tag"

export const ESTIMATE_REACH = gql`
  mutation(
    $storeId: String!
    $type: String!
    $inactivityPeriod: Int!
    $lookbackWindow: String!
  ) {
    estimateReach(
      restaurantId: $storeId
      type: $type
      inactivityPeriod: $inactivityPeriod
      lookbackWindow: $lookbackWindow
    ) {
      estimateReach
    }
  }
`
