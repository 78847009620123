// @ts-nocheck
import React from 'react';
import { Text } from 'components/service';
import {
  OCIMS_INVALID_MERCHANT_ID,
  OCIMS_INVALID_EXTERNAL_ID,
  OCIMS_INVALID_MENU_ID,
  OCIMS_INVALID_DISOCUNT_ID,
  OCIMS_INVALID_BRAND_NAME,
} from 'constants/translations';

export const OCIMS_ENUMS = {
  OK: 'OK',
};

export const validateOcimsForm = (ocimsValues, notifications) => {
  if (ocimsValues.merchant_id === '') {
    notifications.show(<Text value={OCIMS_INVALID_MERCHANT_ID} />, 'error');
    return false;
  }
  if (ocimsValues.external_store_id === '') {
    notifications.show(<Text value={OCIMS_INVALID_EXTERNAL_ID} />, 'error');
    return false;
  }
  if (ocimsValues.menu_id === '') {
    notifications.show(<Text value={OCIMS_INVALID_MENU_ID} />, 'error');
    return false;
  }
  if (ocimsValues.discount_id === '') {
    notifications.show(<Text value={OCIMS_INVALID_DISOCUNT_ID} />, 'error');
    return false;
  }
  if (ocimsValues.brand_name === '') {
    notifications.show(<Text value={OCIMS_INVALID_BRAND_NAME} />, 'error');
    return false;
  }
  return true;
};
