import { getCookie } from 'utils';

// - table view controller
export const tableViewControllerUtils = {
  selectedBranch: null,
  zoneSearchValue: '',
  selectedZoneId: '',
  branchViewPort: null,
  branchOpeningHours: null,
};

export const openingHoursUtils = {
  OPEN_AT: 'openAt',
  CLOSE_AT: 'closeAt',
  OFF_DAY: 'offDay',
};

// - vitally event
export const trackDeliveryZoneEvents = (selectedStoreId, userId, eventTitle, values) => {
  if (process.env.REACT_APP_SEGMENT_WRITE_KEY) {
    if (getCookie('ajs_user_id') !== '') {
      try {
        window &&
          window.analytics &&
          window.analytics.track(
            eventTitle,
            {
              ...values,
              userId,
            },
            {
              groupId: selectedStoreId,
              userId,
            },
          );
      } catch (err) {
        // empty
      }
    }
  }
};

// - edit bulk zones helper
export const bulkEditZones = (setZones, updatedZones, label, value) => {
  setZones(prevState =>
    prevState.map(zoneItem =>
      updatedZones.find(item => parseFloat(item.id) === zoneItem.properties.id)
        ? {
            ...zoneItem,
            properties: {
              ...zoneItem.properties,
              [label]: value,
            },
          }
        : zoneItem,
    ),
  );
};

// bulk re assign helper
export const bulkReassignZones = (setZones, deliveryZone) => {
  setZones(prevState =>
    prevState.filter(zoneItem => !deliveryZone.find(zone => zoneItem.properties.id === parseFloat(zone.id))),
  );
};

// bulk failed zones
export const failedBulkZones = (bulkZones, errors) => {
  const filteredZones = bulkZones.filter(elem =>
    errors.find(({ zoneId }) => elem.properties.id === parseFloat(zoneId)),
  );
  return filteredZones;
};
