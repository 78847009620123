import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import * as translations from 'constants/translations';
import { context as localeContext } from 'context/locale';
import { Text } from 'components/service';
import { CheckboxAlt } from 'components/form/elements';

import { DELIVERY_ZONE_GEO_JSON_FEATURE_COLLECTION_TEMPLATE } from 'constants/index';

const SuggestedZoneItem = ({ item, setSuggestedData, zoneController, setZoneController, bulkZones, setBulkZones }) => {
  const { lang, translate } = useContext(localeContext);
  const [isChecked, setIsChecked] = useState(false);
  const [hide, setHide] = useState(false);
  const handleMouseEnter = () => {
    if (!zoneController.isBulk) {
      setHide(true);
    }
    setSuggestedData(prevState => ({
      ...prevState,
      features: prevState.features.map(zoneItem =>
        zoneItem.properties.id === item.properties.id
          ? {
              ...zoneItem,
              properties: {
                ...zoneItem.properties,
                visible: true,
              },
            }
          : {
              ...zoneItem,
              properties: {
                ...zoneItem.properties,
                visible: false,
              },
            },
      ),
    }));
  };

  const handleMouseLeave = () => {
    setHide(false);
    setSuggestedData(prevState => ({
      ...prevState,
      features: prevState.features.map(zoneItem => ({
        ...zoneItem,
        properties: {
          ...zoneItem.properties,
          visible: false,
        },
      })),
    }));
  };

  const handleZoneSelect = () => {
    const cords = item.geometry.coordinates[0].map(cordItem => ({ lat: cordItem[1], lng: cordItem[0] }));
    setZoneController({
      ...zoneController,
      enableDrawerMode: false,
      isEdit: true,
      showZoneTemplates: false,
      prevZone: {
        paths: null,
        center: null,
        radius: null,
      },
      selectedZone: {
        ...item,
        paths: cords,
        geoShape: {
          ...DELIVERY_ZONE_GEO_JSON_FEATURE_COLLECTION_TEMPLATE,
          features: [item],
        },
        properties: {
          ...item.properties,
          branch_reference_id: zoneController.selectedBranch.id,
          fillColor: zoneController.selectedBranch.color,
          zone_name: item.properties.area_title_en,
          published: true,
          branchName: zoneController.selectedBranch.title,
        },
      },
      selectedSuggestedZone: {
        paths: cords,
      },
    });
    setSuggestedData(prevState => ({
      ...prevState,
      features: prevState.features.map(zoneItem => ({
        ...zoneItem,
        properties: {
          ...zoneItem.properties,
          visible: false,
        },
      })),
    }));
  };

  useEffect(() => {
    const isItemChecked = bulkZones.find(bulkItem => bulkItem.properties.id === item.properties.id);
    setIsChecked(!!isItemChecked);
  }, [bulkZones]);

  return (
    <div
      className="flex-1 flex flex-row justify-between items-start m-0 px-4 py-3"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <CheckboxAlt
        onChange={e => {
          const { value } = e.target;
          setIsChecked(value);
          if (value) {
            setBulkZones(prevState => prevState.concat(item));
            setHide(false);
          } else {
            const filteredBulkZones = bulkZones.filter(bulkItem => bulkItem.properties.id !== item.properties.id);
            setBulkZones(filteredBulkZones);
          }
        }}
        value={isChecked}
        title={
          <span className="text-xs text-gray-700">
            {lang === 'en' ? item.properties.area_title_en : item.properties.area_title_en}
            {item.properties.distance !== undefined &&
              ` (${item.properties?.distance?.toFixed(2)} ${translate(translations.KM)})`}
          </span>
        }
      />

      {hide && (
        <span
          onClick={handleZoneSelect}
          aria-hidden="true"
          className={cx('text-xs cursor-pointer whitespace-nowrap', lang === 'ar' ? 'mr-auto' : 'ml-auto')}
        >
          <Text value={translations.SELECT_TEXT} className="text-primary-base" />
        </span>
      )}
    </div>
  );
};

SuggestedZoneItem.propTypes = {
  setSuggestedData: PropTypes.func.isRequired,
  zoneController: PropTypes.shape({
    enableDrawerMode: PropTypes.bool,
    showDrawerMode: PropTypes.bool,
    selectedBranch: PropTypes.shape({
      id: PropTypes.number,
      color: PropTypes.string,
      position: PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number,
      }),
    }),
    drawerMode: PropTypes.string,
    circleShape: PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.string,
    }),
    isBulk: PropTypes.bool,
  }).isRequired,
  setZoneController: PropTypes.func.isRequired,
  bulkZones: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
    }),
  ).isRequired,
  setBulkZones: PropTypes.func.isRequired,
};
export default SuggestedZoneItem;
