import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { Button } from '@zydalabs/zac-react';

import { ReactComponent as UpgradeRocketIcon } from 'assets/upgrade-rocket.svg';
import { Box } from 'components/kit';
import { Text } from 'components/service';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import * as translations from 'constants/translations';
import { ReactComponent as Cash } from 'assets/cash-delivery.svg';
import * as schemas from '../schemas';
import ActionButton from '../ActionButton';
import ManualPaymentMethodCard from './ManualPaymentMethodCard';

const CashDeliverySection = ({ settings, storeId, refetch, numOfEnabledOnlineMethods, isBlocked = false }) => {
  const { lang, translate } = useContext(localeContext);
  const { user, selectedStore } = useContext(userContext);

  const [updateEnabled, { loading: updating }] = useMutation(schemas.UPDATE_CASH_ON_DELIVERY_ENABLED, {
    variables: { storeId },
    onCompleted: refetch,
  });

  const disabled = settings.cashPaymentEnabled && !settings.cardondeliveryPaymentEnabled && !numOfEnabledOnlineMethods;

  return (
    <div className="w-full lg:w-2/5 p-3 pl-0">
      <Box
        title={<Text value={translations.CASH_DELEVERY} />}
        description={<Text className="mt-3" value={translations.CASH_DELEVERY_DESCRIPTION} />}
        icon={<Cash className={lang === 'en' ? 'mr-2' : 'ml-2'} />}
        displayBorderBottom={false}
        action={
          isBlocked ? (
            <Button
              onClick={() => {
                if (window.Intercom) window.Intercom('show');
              }}
              startIcon={<UpgradeRocketIcon />}
              testId="cash-delivery-chat-with-us"
              variant="tertiary"
              type="button"
              size="small"
              rounded
              text={translate(translations.UPGRADE_YOUR_FREE_PLAN)}
            />
          ) : (
            <ActionButton
              isSpinning={updating}
              testId="enable-cash-delivery"
              onClick={() =>
                updateEnabled({
                  variables: { enabled: !settings.cashPaymentEnabled },
                })
              }
              disabled={(!user.isSupport && !!selectedStore.isCommissionBased) || disabled}
            >
              <Text value={settings.cashPaymentEnabled ? translations.DISABLE : translations.ENABLE} />
            </ActionButton>
          )
        }
        body={<ManualPaymentMethodCard />}
      />
    </div>
  );
};

CashDeliverySection.propTypes = {
  settings: PropTypes.shape({
    cardondeliveryPaymentEnabled: PropTypes.bool.isRequired,
    cashPaymentEnabled: PropTypes.bool.isRequired,
  }).isRequired,
  isBlocked: PropTypes.bool,
  storeId: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
  numOfEnabledOnlineMethods: PropTypes.number.isRequired,
};

export default CashDeliverySection;
