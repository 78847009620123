import React from "react"
import * as Sentry from "@sentry/react"
import { parse } from "query-string"
import Button from "../../kit/Button"
import Stack from "../../kit/Stack"
import { ReactComponent as Illustration } from "assets/something-wrong.svg"
import { ReactComponent as Unathorization } from "assets/Unauthorized.svg"
import * as translations from "constants/translations"

const ErrorPage = (err) => {
  const translate = ([en, ar]) => {
    const { lang } = parse(document.location.search)
    if (lang === "en") return en
    return ar
  }
  const isUnauthorized =
    err.error.message.includes("Request failed with status code 401") &&
    err.error.graphQLErrors[0].extensions.exception.body.error.includes(
      "You are not authorized to access this store"
    );
  
  return (
    <div className="h-screen flex items-center justify-center">
      <div className="px-6 pb-4 flex flex-col items-center text-center pt-4">
        { isUnauthorized? <Unathorization /> : <Illustration /> }
        <span className="text-xl mb-1 font-semibold mt-5">
          { isUnauthorized ? translate(translations.UNAUTHORIZED_PAGE) : translate(translations.SOMETHING_WENT_WRONG) }
        </span>
        <span className="text-gray-700">
          { isUnauthorized ? translate(translations.UNAUTHORIZED_PAGE_DESC) : translate(translations.SOMETHING_WENT_WRONG_DESC) }
        </span>
        <Stack direction="row" className="mt-5">
          <Button kind="primary" onClick={() => (document.location.href = "/")}>
            {translate(translations.GO_TO_HOMEPAGE)}
          </Button>
        </Stack>
      </div>
    </div>
  )
}

export default ({ children }) => {
  return (
    <Sentry.ErrorBoundary fallback={ErrorPage}>{children}</Sentry.ErrorBoundary>
  )
}
