import React, { useContext, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { useMobile } from 'hooks';
import { Text } from 'components/service';
import * as translations from 'constants/translations';
import OptiongroupMobileFrame from './OptiongroupMobileFrame';
import OptiongroupDesktopFrame from './OptiongroupDesktopFrame';
import { OPTION_GROUPS_FRAME_ENUMS } from './constants';

const Frame = ({ children, query, setQuery, optionGroupId, emptyGroups }) => {
  const { lang } = useContext(localeContext);
  const { branches } = useContext(userContext);
  const isMobile = useMobile();
  const [langSelected, setLangSelected] = useState(OPTION_GROUPS_FRAME_ENUMS.EN);
  const searchTimeout = useRef();
  const isSingleBranch = branches && branches.length === 1;

  const prodcutLang = [
    { id: OPTION_GROUPS_FRAME_ENUMS.EN, title: OPTION_GROUPS_FRAME_ENUMS.ENGLISH },
    { id: OPTION_GROUPS_FRAME_ENUMS.AR, title: OPTION_GROUPS_FRAME_ENUMS.ARABIC },
  ];

  const branchesData =
    branches &&
    [...branches].map(branch => ({
      id: branch.id,
      title: <Text value={branch} />,
    }));

  const statusData = [
    {
      id: OPTION_GROUPS_FRAME_ENUMS.ALL,
      title: isMobile ? <Text value={translations.ALL_STATUS} /> : <Text value={translations.ALL} />,
    },
    { id: OPTION_GROUPS_FRAME_ENUMS.AVAILABLE, title: <Text value={translations.AVALIABLE} /> },
    {
      id: OPTION_GROUPS_FRAME_ENUMS.UNAVAILABLE,
      title: <Text value={translations.UNAVALIABLE} />,
    },
  ];

  return (
    <>
      {branches === null ? (
        <Text value={translations.YOU_HAVE_ADD_BUSINESS_LOCATION_FIRST} />
      ) : (
        <div className="pb-32 md:pb-16">
          {isMobile ? (
            <OptiongroupMobileFrame
              emptyGroups={emptyGroups}
              query={query}
              setQuery={setQuery}
              optionGroupId={optionGroupId}
              isSingleBranch={isSingleBranch}
              branchesData={branchesData}
              lang={lang}
              statusData={statusData}
              prodcutLang={prodcutLang}
              setLangSelected={setLangSelected}
              searchTimeout={searchTimeout}
              langSelected={langSelected}
            />
          ) : (
            <OptiongroupDesktopFrame
              emptyGroups={emptyGroups}
              query={query}
              setQuery={setQuery}
              isSingleBranch={isSingleBranch}
              branchesData={branchesData}
              lang={lang}
              statusData={statusData}
              prodcutLang={prodcutLang}
              setLangSelected={setLangSelected}
              langSelected={langSelected}
            />
          )}
          <div className="mt-4">{children(query, setQuery, langSelected)}</div>
        </div>
      )}
    </>
  );
};

Frame.propTypes = {
  children: PropTypes.shape({ props: PropTypes.func.isRequired }),
  query: PropTypes.element.isRequired,
  setQuery: PropTypes.func.isRequired,
  optionGroupId: PropTypes.string.isRequired,
  emptyGroups: PropTypes.bool.isRequired,
};
export default Frame;
