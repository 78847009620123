import { INVOICE_STATUS } from 'constants/billing';
import { RECEIPT, PAY_NOW, CANCELLED } from 'constants/translations';

export const getInvoiceStatusActionText = status => {
  switch (status) {
    case INVOICE_STATUS.PAID:
      return RECEIPT;
    case INVOICE_STATUS.UNPAID:
      return PAY_NOW;
    case INVOICE_STATUS.CANCELLED:
      return CANCELLED;
    default:
      return ['-', '-'];
  }
};
