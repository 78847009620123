import { gql } from "@apollo/client"

export const USER = gql`
  query($userId: String!, $storeId: String!) {
    user(id: $userId, restaurantId: $storeId) {
      name
      email
      phoneNumber
    }
  }
`

export const UPDATE_USER_PASSWORD = gql`
  mutation(
    $userId: String!
    $storeId: String!
    $oldPassword: String!
    $newPassword: String!
  ) {
    updateUserPassword(
      userId: $userId
      restaurantId: $storeId
      oldPassword: $oldPassword
      newPassword: $newPassword
    ) {
      email
    }
  }
`

export const USER_UPDATE_SELF = gql`
  mutation(
    $userId: String!
    $storeId: String!
    $name: String!
    $email: String!
    $phoneNumber: String
  ) {
    userUpdateSelf(
      userId: $userId
      restaurantId: $storeId
      name: $name
      email: $email
      phoneNumber: $phoneNumber
    ) {
      id
    }
  }
`
