import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { useNumeralFormat } from 'hooks/index';
import { context as localeContext } from 'context/locale';

const SalesSection = ({ title, value = '0', percent, dir, isSelected = false, valueColor = 'black' }) => {
  const { direction } = useContext(localeContext);
  const formatNumber = useNumeralFormat();
  return (
    <div>
      <span className="block uppercase text-xs text-gray-700 mb-2 whitespace-nowrap">{title}</span>
      <div className="flex items-center whitespace-nowrap">
        <span
          className={cx('uppercase text-sm font-semibold', isSelected ? 'text-primary-base' : `text-${valueColor}`)}
        >
          {value}
        </span>
        {percent && (
          <span
            className={cx(
              'text-xs',
              dir === '+' ? 'text-green-600' : 'text-red-600',
              direction === 'rtl' ? 'mr-3' : 'ml-3',
              isSelected && 'font-semibold',
            )}
          >
            {dir === '+' ? dir : ''}
            {formatNumber(percent)}%
          </span>
        )}
      </div>
    </div>
  );
};

SalesSection.propTypes = {
  title: PropTypes.element.isRequired,
  value: PropTypes.string,
  percent: PropTypes.string.isRequired,
  dir: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  valueColor: PropTypes.string,
};

export default SalesSection;
