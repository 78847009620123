import { gql } from 'graphql-request';

const branchesListQuery = gql`
  query($restaurantReferenceId: Float!) {
    branches(restaurantReferenceId: $restaurantReferenceId) {
      restaurantViewPort
      titleAr
      titleEn
      lonlat
      referenceId
      branchViewPort
      openingHours
      deliveryOrdersEnabled
    }
  }
`;

export default branchesListQuery;
