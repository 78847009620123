import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { Text } from 'components/service';
import * as translations from 'constants/translations';

const PAYMENT_TRANSACTION_STATUS_ENUMS = {
  INITIATED: 'INITIATED',
  FAILED: 'FAILED',
  DECLINED: 'DECLINED',
  CAPTURED: 'CAPTURED',
  ABANDONED: 'ABANDONED',
};
const PaymentTransactionStatus = ({ status, lang }) => {
  switch (status) {
    case PAYMENT_TRANSACTION_STATUS_ENUMS.INITIATED:
      return (
        <div className="flex items-center">
          <div className="w-2 h-2 rounded-full bg-primary-base" />
          <div>
            <Text
              value={translations.PAYMENT_TRANSACTION_INITIATED}
              className={cx(lang === 'en' ? 'ml-2' : 'mr-2', 'text-primary-base')}
            />
          </div>
        </div>
      );
    case PAYMENT_TRANSACTION_STATUS_ENUMS.FAILED:
      return (
        <div className="flex items-center">
          <div className="w-2 h-2 rounded-full bg-zyda-red-500" />
          <div>
            <Text
              value={translations.PAYMENT_TRANSACTION_FAILED}
              className={cx(lang === 'en' ? 'ml-2' : 'mr-2', 'text-red-600')}
            />
          </div>
        </div>
      );
    case PAYMENT_TRANSACTION_STATUS_ENUMS.DECLINED:
      return (
        <div className="flex items-center">
          <div className="w-2 h-2 rounded-full bg-zyda-red-500" />
          <div>
            <Text
              value={translations.PAYMENT_TRANSACTION_DECLINED}
              className={cx(lang === 'en' ? 'ml-2' : 'mr-2', 'text-red-600')}
            />
          </div>
        </div>
      );
    case PAYMENT_TRANSACTION_STATUS_ENUMS.CAPTURED:
      return (
        <div className="flex items-center">
          <div className="w-2 h-2 rounded-full bg-zyda-green-500" />
          <div>
            <Text
              value={translations.PAYMENT_TRANSACTION_CAPTURED}
              className={cx(lang === 'en' ? 'ml-2' : 'mr-2', 'text-green-600')}
            />
          </div>
        </div>
      );
    case PAYMENT_TRANSACTION_STATUS_ENUMS.ABANDONED:
      return (
        <div className="flex items-center">
          <div className="w-2 h-2 rounded-full bg-zyda-yellow-300" />
          <div>
            <Text
              value={translations.PAYMENT_TRANSACTION_ABANDONED}
              className={cx(lang === 'en' ? 'ml-2' : 'mr-2', 'text-yellow-600')}
            />
          </div>
        </div>
      );
    default:
      return '';
  }
};

PaymentTransactionStatus.propTypes = {
  lang: PropTypes.string,
  status: PropTypes.oneOf([
    PAYMENT_TRANSACTION_STATUS_ENUMS.INITIATED,
    PAYMENT_TRANSACTION_STATUS_ENUMS.FAILED,
    PAYMENT_TRANSACTION_STATUS_ENUMS.DECLINED,
    PAYMENT_TRANSACTION_STATUS_ENUMS.CAPTURED,
    PAYMENT_TRANSACTION_STATUS_ENUMS.ABANDONED,
  ]),
};
export default PaymentTransactionStatus;
