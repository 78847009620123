import DELIVERY_TYPE_ENUM from 'components/common/orders/OrderInvoice/A4OrderInvoice/utils';
import { BEACH, DELIVERY, PICKUP } from 'constants/translations';

export const getDeliveryTypeText = (orderDeliveryType, translate) => {
  let deliveryText = translate(PICKUP);
  if (orderDeliveryType === DELIVERY_TYPE_ENUM.DELIVERY) {
    deliveryText = translate(DELIVERY);
  } else if (orderDeliveryType === DELIVERY_TYPE_ENUM.BEACH) {
    deliveryText = translate(BEACH);
  }
  return deliveryText;
};
