import gql from "graphql-tag"

export const UPDATE_CAMPAIGN = gql`
  mutation(
    $restaurantId: String!
    $id: String!
    $startsAt: String
    $expiresAt: String
  ) {
    updateCampaign(
      restaurantId: $restaurantId
      id: $id
      startsAt: $startsAt
      expiresAt: $expiresAt
    ) {
      id
      startsAt
      expiresAt
    }
  }
`

export const GET_CAMPAIGN = gql`
  query getCampaign($restaurantId: String!, $id: String!) {
    campaign(restaurantId: $restaurantId, id: $id) {
      id
      title
      smsBody
      startsAt
      expiresAt
      state
      sentSmsCount
      type
    }
  }
`
