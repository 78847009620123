import React, { useState, useContext } from "react"
import { Container, Section, Row } from "components/form/generic"
import { Button, Spinner, Title, DropDown } from "components/kit"
import { Link, Text } from "components/service"
import Table from "./Table"
import Overview from "./Table/Overview"
import { Layout, Breadcrumbs } from "components/common/dashboard"
import * as translations from "constants/translations"
import cx from "classnames"
import { context as localeContext } from "context/locale"
import { breadcrumbs } from "constants/translations"

export default () => {
  const locations = [
    { id: "1", title: "option 0" },
    { id: "2", title: "option 1" },
    { id: "3", title: "option 2" },
  ]
  const filterDays = [
    { id: "1", title: "option 0" },
    { id: "2", title: "option 1" },
    { id: "3", title: "option 2" },
  ]

  const [locationsSelected, setLocationsSelected] = useState("1")
  const [filterDaysSelected, setFilterDaysSelected] = useState("1")

  const { lang } = useContext(localeContext)

  return (
    <Layout
      top={<Breadcrumbs path={breadcrumbs.REPORTS} />}
    >
      <div className="px-6 pb-6">
        <div
          className={cx(
            "w-full my-3 flex-1 flex flex-row",
            lang === "ar" && "flex-row-reverse"
          )}
        >
          <DropDown
            float={"right"}
            label={<Text value={translations.BUSINESS_LOCATION} />}
            optionSelected={locationsSelected}
            onSelect={option => setLocationsSelected(option)}
            data={locations}
            icon="keyboard_arrow_down"
            full
          />
          <DropDown
            float={"right"}
            optionSelected={filterDaysSelected}
            onSelect={option => setFilterDaysSelected(option)}
            data={filterDays}
            icon="keyboard_arrow_down"
            full
          />
        </div>
        <div className="flex flex-col">
          <Text
            value={translations.OVERVIEW}
            className="inline text-xl font-medium"
          />

          <Overview />
        </div>

        <div className="flex flex-col">
          <div
            className={cx(
              "flex flex-row",
              lang === "ar" && "flex-row-reverse",
              " items-center my-3"
            )}
          >
            <div className="flex-1">
              <Text
                value={translations.ORDERS}
                className="text-xl font-medium"
              />
            </div>
            <div
              className={cx(
                "flex-1 flex flex-row ",
                lang === "en" && "flex-row-reverse"
              )}
            >
              <Button kind="tertiary">
                <Text value={translations.EXPORT} className="mx-1" />
                CSV
              </Button>
            </div>
          </div>

          <Table />
        </div>
      </div>
    </Layout>
  )
}
