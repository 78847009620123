const transformDeliveryPickupData = data => {
  const listData = !data
    ? []
    : Object.keys(data).reduce(
        (acc, x) => [
          ...acc,
          {
            date: x,
            delivery: data[x].delivery || 0,
            pickup: data[x].pickup || 0,
          },
        ],
        [],
      );
  return listData;
};

export default transformDeliveryPickupData;
