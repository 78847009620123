import { Text } from "components/service"
import React from "react"
import { createGlobalStyle } from "styled-components"
import * as translations from "constants/translations"
import cx from "classnames"

export default ({ children, image, logo, lang }) => {
  return (
    <>
      <GlobalStyle />
      <div className="flex flex-row">
        <div className="relative h-screen w-1/2 hidden sm:hidden md:block lg:block xl:block">
          <div>
            <img src={image} alt="banner" className="object-cover h-full" />
            <div className="absolute px-20" style={{ top: "40%" }}>
              <img
                src={logo}
                alt=""
                className={cx(lang === "ar" && "float-right py-8")}
              />
              <Text
                value={translations.REBRANDING_TITLE}
                className="text-white mt-5 flex w-1/2"
              />
            </div>
          </div>
        </div>
        <div className="h-screen md:w-1/2 w-full h-full px-8 flex flex-1 items-center">
          <div className="m-auto w-full flex justify-center">{children}</div>
        </div>
      </div>
    </>
  )
}

const GlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }
`
