import React from "react"
import * as yup from "yup"
import { Text } from "components/service"
import * as translations from "constants/translations"

export const validationSchema = yup.object().shape({
  old_password: yup
    .string()
    .required(<Text value={translations.PASSWORD_REQUIRED} />),
  new_password: yup
    .string()
    .required(<Text value={translations.PASSWORD_REQUIRED} />)
    .matches(
      /^[A-Za-z\d@$!%*#?&.<>';:",]{8,}$/,
      <Text value={translations.PASSWORD_8_CHARACTERS} />
    ),
  confirm_new_password: yup
    .string()
    .oneOf(
      [yup.ref("new_password"), null],
      <Text value={translations.PASSWORDS_MUST_MATCH} />
    ),
})
