import { useDeliveryZoneMsToken } from 'hooks';
import { Branch, QueryBranchesArgs } from '../../types/generated';
import { useQuery } from '../../utils';
import { QueryHookResponse } from '../../types/internal';
import { branchesListQuery } from '../../queries/deliveryZones';

type useFetchBranchesType = (args: QueryBranchesArgs) => QueryHookResponse<Branch>;

const useFetchBranches: useFetchBranchesType = ({ restaurantReferenceId }) => {
  const deliveryZoneMsToken = useDeliveryZoneMsToken();
  return useQuery<Branch>({
    query: branchesListQuery,
    variables: { restaurantReferenceId },
    requestHeaders: { token: deliveryZoneMsToken },
    handler: (res: any) => res.branches,
    config: { revalidateOnMount: true },
  });
};

export default useFetchBranches;
