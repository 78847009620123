import React, { useContext } from "react"
import { useMutation } from "@apollo/client"
import { Formik, Form } from "formik"
import * as yup from "yup"
import cx from "classnames"
import { useSelectedStore } from "hooks"
import { Field, Text } from "components/service"
import { Button, Stack } from "components/kit"
import {
  Container,
  Row,
  LangSwitch,
  Footer,
  Label,
} from "components/form/generic"
import { Input } from "components/form/elements"

import * as translations from "constants/translations"
import { context as localeContext } from "context/locale"
import { context as notificationsContext } from "context/notifications"
import * as schemas from "./schemas"

const DuplicateGroupModal = ({
  initialValues,
  type,
  onCancel,
  refetchAllGroup,
}) => {
  const { lang } = useContext(localeContext)
  const notifications = useContext(notificationsContext)

  const storeId = useSelectedStore()
  const direction = lang === "ar" ? "rtl" : "ltr"

  const validationSchema = yup.object().shape({
    titleEn: yup
      .string()
      .required(<Text value={translations.TITLE_EN_IS_REQ} />),
    titleAr: yup
      .string()
      .required(<Text value={translations.TITLE_AR_IS_REQ} />),
  })

  const [cloneProperty, { loading: isStillCloning }] = useMutation(
    schemas.CLONE_PROPERTY,
    {
      onCompleted: async data => {
        await refetchAllGroup.refetch()
      },
    }
  )

  return (
    <Formik
      initialValues={{
        titleEn: `Copy of ${initialValues.groupName.titleEn}`,
        titleAr: `نسخة من ${initialValues.groupName.titleAr}`,
      }}
      validationSchema={validationSchema}
      onSubmit={async values => {
        let variables = {
          restaurantId: storeId,
          propertyId: initialValues.groupName.id.toString(),
          titleEn: values.titleEn,
          titleAr: values.titleAr,
          externalId: initialValues.isFoodics ? values.externalId : "",
        }
        await cloneProperty({ variables: variables })
          .then(res => {
            notifications.show(
              <Text
                value={translations.OPTION_GROUPS_DUPLICATE_SUCCESS}
                payload={
                  lang === "en"
                    ? initialValues.groupName.titleEn
                    : initialValues.groupName.titleAr
                }
              />
            )
          })
          .catch(() => {
            notifications.show(
              <Text value={translations.SOMETHING_WENT_WRONG} />,
              "error"
            )
          })
        onCancel()
      }}
      onCancel={onCancel}
    >
      {({ values, setFieldValue, ...rest }) => (
        <Form style={{ direction }}>
          <div className="px-4 my-4 w-full">
            <Container>
              <Row className="flex-col md:flex-row">
                <div className="flex flex-col md:flex-row justify-between items-start">
                  <div
                    className={cx(
                      "w-full mb-6 md:mb-0",
                      lang === "en" ? "mr-0 md:mr-4" : "ml-0 md:ml-4"
                    )}
                  >
                    <Label
                      title={
                        <Text
                          value={translations.TITLE}
                          postfix={translations.EN[0]}
                        />
                      }
                    >
                      <Field
                        type="text"
                        name="titleEn"
                        component={Input}
                        direction="ltr"
                      />
                    </Label>
                  </div>
                  <div className="w-full mb-0 md:mb-0">
                    <Label
                      title={
                        <Text
                          value={translations.TITLE}
                          postfix={translations.AR[0]}
                        />
                      }
                    >
                      <Field
                        direction="rtl"
                        type="text"
                        name="titleAr"
                        component={Input}
                      />
                    </Label>
                  </div>
                </div>
              </Row>
            </Container>
          </div>
          <Footer modalFooter>
            <div className="w-full flex items-center">
              <div className={cx(lang === "ar" ? "mr-auto" : "ml-auto")}>
                <Stack direction="row">
                  <Button
                    data-testid={
                      type === "add"
                        ? "add-cancel-category-btn"
                        : "edit-cancel-category-btn"
                    }
                    type="button"
                    kind="tertiary"
                    onClick={onCancel}
                  >
                    <Text value={translations.CANCEL} />
                  </Button>
                  <Button
                    data-testid="add-category-btn"
                    kind="primary"
                    isSpinning={isStillCloning}
                  >
                    <Text value={translations.OPTION_GROUPS_DUPLICATE_BTN} />
                  </Button>
                </Stack>
              </div>
            </div>
          </Footer>
        </Form>
      )}
    </Formik>
  )
}
export default DuplicateGroupModal
