import { ApolloClient, split } from '@apollo/client';
import { HttpLink } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import * as userService from "services/user"
import Cache from "./cache"
import { BACKEND_GRAPHQL_SERVER } from '../constants/backendGraphqlServer';

const dashboardGraphqlLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_SERVER,
})

const backendGraphqlLink = new HttpLink({
  uri: process.env.REACT_APP_BACKEND_GRAPHQL_SERVER,
});

const authLink = setContext((_, { headers }) => {
  const token = userService.getToken();

  return {
    headers: {
      ...headers,
      ...(token && {
        authorization: `Bearer ${token}`,
        APPVERSION: 2,
      }),
    },
  }
})

const httpLink = split(
  operation => operation.getContext().serverName === BACKEND_GRAPHQL_SERVER,
  authLink.concat(backendGraphqlLink),
  authLink.concat(dashboardGraphqlLink),
);

export default new ApolloClient({
  link: httpLink,
  cache: Cache,
})
