import { gql } from '@apollo/client';

export const WALLET_TRANSACTIONS_QUERY = gql`
  query($subdomain: String!, $pageSize: Int, $lastRecordId: Int, $phoneNumber: String) {
    storeWalletTransactions(
      subdomain: $subdomain
      pageSize: $pageSize
      lastRecordId: $lastRecordId
      phoneNumber: $phoneNumber
    ) {
      walletTransactions {
        id
        createdAt
        amount
        orderNumber
        userName
        restaurantCustomerId
        userPhoneNumber
        transactionType
        transactionReason
      }
      hasNextPage
    }
  }
`;

export const WALLET_AMOUNTS_QUERY = gql`
  query($subdomain: String!) {
    storeWalletAmount(subdomain: $subdomain) {
      cashback
      compensation
      id
      refund
      totalWalletAmounts
      totalRedemptions
      totalCustomer
      totalOrdersValue
      ordersPaidByWallet
    }
  }
`;
