import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import * as translations from 'constants/translations';
import { context as localeContext } from 'context/locale';
import { Text } from 'components/service';

const Period = ({ value, onChange, disabled = false }) => {
  const { lang } = useContext(localeContext);
  const selectedClassName = 'font-semibold text-primary-base bg-primary-50 border border-primary-base';
  const baseClassName = cx('px-3 py-1', disabled ? 'cursor-not-allowed' : 'cursor-pointer');

  return (
    <div className={cx('flex text-gray-700', disabled && 'opacity-25')}>
      <Text
        value={translations.DAY}
        className={cx(
          baseClassName,
          value === 'day'
            ? selectedClassName
            : `border border-gray-300  ${lang === 'ar' ? 'border-l-0' : 'border-r-0'}  `,
        )}
        onClick={() => !disabled && onChange('day')}
      />
      <Text
        value={translations.MONTH}
        className={cx(
          baseClassName,
          value === 'month'
            ? selectedClassName
            : `border border-gray-300  ${lang === 'ar' ? 'border-r-0' : 'border-l-0'}  `,
        )}
        onClick={() => !disabled && onChange('month')}
      />
    </div>
  );
};

Period.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default Period;
