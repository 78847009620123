import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Circle, InfoBox } from '@react-google-maps/api';

import * as translations from 'constants/translations';
import { context as localeContext } from 'context/locale';

const StaticCircle = ({ item, zoneController, setZoneController }) => {
  const defCenter = item.center;
  const [circle, setCircle] = useState(null);
  const { translate } = useContext(localeContext);

  const [distance, setDistance] = useState(item.radius / 1000);
  const [showInfo, setShowInfo] = useState(false);

  const getBackToDefCenter = () => {
    if (circle) {
      if (circle.getCenter().lat() !== defCenter.lat) {
        circle.setCenter(defCenter);
      }
    }
  };

  useEffect(() => {
    if (circle) {
      setZoneController({
        ...zoneController,
        enableDrawerMode: false,
        shapeRef: circle,
        prevZone: {
          paths: null,
          center: item.center,
          radius: item.radius,
        },
      });
    }
  }, [circle]);

  return (
    <>
      {showInfo && (
        <InfoBox
          position={{ lat: defCenter.lat, lng: defCenter.lng }}
          options={{ closeBoxURL: '', enableEventPropagation: true }}
        >
          <div className="bg-white p-2 shadow min-w-max">
            <div className="flex justify-between text-xs">
              <span>{`${translate(translations.DISTANCE)} ${distance.toFixed(1)} ${translate(translations.KM)}`}</span>
            </div>
          </div>
        </InfoBox>
      )}
      <Circle
        onLoad={ref => setCircle(ref)}
        center={item.center}
        editable
        onCenterChanged={getBackToDefCenter}
        draggable={false}
        radius={item.radius}
        onRadiusChanged={() => circle && setDistance(parseInt(circle.radius) / 1000)}
        onMouseOver={() => circle && setShowInfo(true)}
        onMouseOut={() => circle && setShowInfo(false)}
        options={{
          strokeColor: item.fillColor,
          strokeOpacity: item.strokeOpacity,
          strokeWeight: 2,
          fillColor: item.fillColor,
          fillOpacity: item.fillOpacity,
          zIndex: 2,
        }}
      />
    </>
  );
};

StaticCircle.propTypes = {
  item: PropTypes.shape({
    fillColor: PropTypes.string,
    strokeOpacity: PropTypes.number,
    fillOpacity: PropTypes.number,
    radius: PropTypes.number,
    center: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
  }).isRequired,
  zoneController: PropTypes.shape({
    isEdit: PropTypes.bool,
    enableDrawerMode: PropTypes.bool,
    showDrawerMode: PropTypes.bool,
    isCircleAdded: PropTypes.bool,
  }).isRequired,
  setZoneController: PropTypes.func.isRequired,
};

export default StaticCircle;
