import React, { useContext } from "react"
import { Formik, Form } from "formik"
import cx from "classnames"
import { context as notificationsContext } from "context/notifications"
import { context as localeContext } from "context/locale"
import { Button, Stack } from "components/kit"
import { useSelectedStore } from "hooks"
import { Field, Text } from "components/service"
import { Footer } from "components/form/generic"
import { RadioList } from "components/form/elements"
import * as translations from "constants/translations"

export default ({
  onCancel,
  refetchProduct,
  updateSortingProduct,
  loading,
  setting,
  langSelected,
}) => {
  const notifications = useContext(notificationsContext)
  const { lang, direction } = useContext(localeContext)
  const storeId = useSelectedStore()

  const onSubmit = async values => {
    await updateSortingProduct({
      variables: {
        storeId,
        menuItemSorting: values.menuItemSorting,
        lang: langSelected === "en" ? "english" : "arabic",
      },
    })
    notifications.show(<Text value={translations.SORT_PRODUCT_SUCCESS} />)
    await refetchProduct()
    onCancel()
  }
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{ menuItemSorting: setting.settings.menuItemSorting }}
    >
      {({ isSubmitting }) => (
        <Form>
          <div className="px-4">
            <Text value={translations.DESC_SORT_PRODUCT} className="mb-4" />
            <div style={{ direction }}>
              <Field
                testId="list-sort-products"
                testIdOption="option-sort"
                name="menuItemSorting"
                direction="col"
                items={[
                  {
                    title: <Text value={translations.SORT_PRODUCT_DATE} />,
                    value: 4,
                  },
                  {
                    title: <Text value={translations.SORT_PRODUCT_AZ} />,
                    value: 1,
                  },
                  {
                    title: <Text value={translations.SORT_PRODUCT_ZA} />,
                    value: 2,
                  },
                  {
                    title: (
                      <Text value={translations.SORT_PRODUCT_STORE_OWNER} />
                    ),
                    value: 0,
                    note: (
                      <Text
                        value={translations.SORT_PRODUCT_STORE_OWNER_NOTE}
                        className="text-xs text-gray-700 mx-6 px-1"
                      />
                    ),
                  },
                ]}
                component={RadioList}
              />
            </div>
          </div>
          <Footer modalFooter>
            <div className={cx(lang === "ar" ? "mr-auto" : "ml-auto")}>
              <Stack direction="row">
                <Button
                  data-testid="cancel-sort-catalog-btn"
                  onClick={onCancel}
                  type="button"
                  kind="tertiary"
                >
                  <Text value={translations.CANCEL} />
                </Button>
                <Button
                  data-testid="save-sort-catalog-btn"
                  kind="primary"
                  isSpinning={isSubmitting}
                  disabled={isSubmitting && loading}
                >
                  <Text value={translations.SAVE} />
                </Button>
              </Stack>
            </div>
          </Footer>
        </Form>
      )}
    </Formik>
  )
}
