import { gql } from '@apollo/client';

export const ADD_CASHBACK_PROGRAM = gql`
  mutation($subdomain: String!, $name: String!, $percentage: String, $startsAt: ISO8601Date, $endsAt: ISO8601Date) {
    createCashbackProgram(
      subdomain: $subdomain
      name: $name
      percentage: $percentage
      startsAt: $startsAt
      endsAt: $endsAt
    ) {
      name
      status
      startsAt
      endsAt
      percentage
      deactivatedAt
    }
  }
`;

export const CASHBACK_PROGRAMS_LIST = gql`
  query($subdomain: String!) {
    cashbackPrograms(subdomain: $subdomain) {
      startsAt
      endsAt
      status
      percentage
      name
      id
      deactivatedAt
    }
  }
`;

export const DEACTIVATE_CASHBACK_PROGRAM = gql`
  mutation($id: ID!, $subdomain: String!) {
    deactivateCashbackProgram(id: $id, subdomain: $subdomain) {
      success
    }
  }
`;
