import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { context as userContext } from 'context/user';
import {
  ZONES_SELECTED,
  BULK_EDIT_TEXT,
  SET_TO_ACTIVE,
  SET_TO_INACTIVE,
  REASIGN_BRANCH,
  DELETE,
  UPDATE_ASSIGN_BRANCH,
  UPDATE_ASSIGN_BRANCH_MSG,
  SET_ZONES_TO_ACTIVE,
  SET_ZONES_TO_INACTIVE,
  DELETE_ZONES_TITLE,
  DELETE_ZONES_MSG,
  SET_DELIVERY_FEES,
  UPDATE_DELIVERY_FEES,
  UPDATE_DELIVERY_FEES_MSG,
  SET_MIN_ORDER,
  UPDATE_MIN_ORDER,
  UPDATE_MIN_ORDER_MSG,
  SET_DELIVER_TIME,
  UPDATE_DELIVERY_TIME,
  UPDATE_DELIVERY_TIME_MSG,
  SET_DELIVERY_HOURS,
  UPDATE_WORKING_HOURS,
  UPDATE_WORKING_HOURS_MSG,
} from 'constants/translations';
import { Text } from 'components/service';
import { More, Modal } from 'components/kit';
import {
  DeleteBulkZonesModal,
  UpdateAssignBranchModal,
  UpdateDeliveryFeesModal,
  UpdateDeliveryTimeModal,
  UpdateMinOrderModal,
  UpdateWorkingHoursModal,
} from '../BulkModals';
import ActivateDeactivateBulkModal from '../BulkModals/ActivateDeactivateBulkModal';

const BulkEdit = ({ bulkZones, setZones, setBulkZones, branches, tableViewController }) => {
  const { length } = bulkZones;
  const { settings } = useContext(userContext);
  const { defaultProductPrepTime } = settings;

  return (
    <Modal isDzmsTableView>
      {({ open, close }) => (
        <div className="flex items-center pb-2 mb-2">
          <Text value={ZONES_SELECTED(length)} className="mx-4" />
          <More
            kind="outline"
            icon={false}
            moreButtonText={BULK_EDIT_TEXT}
            items={[
              {
                title: <Text value={SET_TO_ACTIVE} />,
                onClick: () =>
                  open({
                    title: <Text value={SET_ZONES_TO_ACTIVE(length)} />,
                    body: (
                      <div className="px-4">
                        <ActivateDeactivateBulkModal bulkZones={bulkZones} setZones={setZones} close={close} status />
                      </div>
                    ),
                    size: 'max-w-md',
                  }),
              },
              {
                title: <Text value={SET_TO_INACTIVE} />,
                onClick: () =>
                  open({
                    title: <Text value={SET_ZONES_TO_INACTIVE(length)} />,
                    body: (
                      <div className="px-4">
                        <ActivateDeactivateBulkModal
                          bulkZones={bulkZones}
                          setZones={setZones}
                          close={close}
                          status={false}
                        />
                      </div>
                    ),
                    size: 'max-w-md',
                  }),
              },
              {
                title: <Text value={SET_DELIVERY_FEES} />,
                onClick: () =>
                  open({
                    title: <Text value={UPDATE_DELIVERY_FEES} />,
                    subTitle: <Text value={UPDATE_DELIVERY_FEES_MSG(length)} />,
                    body: (
                      <div className="px-4">
                        <UpdateDeliveryFeesModal bulkZones={bulkZones} close={close} setZones={setZones} />
                      </div>
                    ),
                    size: 'max-w-2xl',
                  }),
              },
              {
                title: <Text value={SET_MIN_ORDER} />,
                onClick: () =>
                  open({
                    title: <Text value={UPDATE_MIN_ORDER} />,
                    subTitle: <Text value={UPDATE_MIN_ORDER_MSG(length)} />,
                    body: (
                      <div className="px-4">
                        <UpdateMinOrderModal bulkZones={bulkZones} close={close} setZones={setZones} />
                      </div>
                    ),
                    size: 'max-w-2xl',
                  }),
              },
              {
                title: <Text value={SET_DELIVER_TIME} />,
                onClick: () =>
                  open({
                    title: <Text value={UPDATE_DELIVERY_TIME} />,
                    subTitle: <Text value={UPDATE_DELIVERY_TIME_MSG(defaultProductPrepTime)} />,
                    body: (
                      <div className="px-4">
                        <UpdateDeliveryTimeModal
                          bulkZones={bulkZones}
                          close={close}
                          setZones={setZones}
                          defaultProductPrepTime={defaultProductPrepTime}
                        />
                      </div>
                    ),
                    size: 'max-w-2xl',
                  }),
              },
              {
                title: <Text value={SET_DELIVERY_HOURS} />,
                onClick: () =>
                  open({
                    title: <Text value={UPDATE_WORKING_HOURS} />,
                    subTitle: <Text value={UPDATE_WORKING_HOURS_MSG} />,
                    body: (
                      <div className="px-4">
                        <UpdateWorkingHoursModal
                          bulkZones={bulkZones}
                          close={close}
                          setZones={setZones}
                          tableViewController={tableViewController}
                        />
                      </div>
                    ),
                    size: 'max-w-2xl',
                  }),
              },
              {
                title: <Text value={REASIGN_BRANCH} />,
                onClick: () =>
                  open({
                    title: <Text value={UPDATE_ASSIGN_BRANCH} />,
                    subTitle: <Text value={UPDATE_ASSIGN_BRANCH_MSG(length)} />,
                    body: (
                      <div className="px-4">
                        <UpdateAssignBranchModal
                          bulkZones={bulkZones}
                          setBulkZones={setBulkZones}
                          close={close}
                          setZones={setZones}
                          branches={branches}
                          tableViewController={tableViewController}
                        />
                      </div>
                    ),
                    size: 'max-w-2xl',
                  }),
              },
              {
                title: <Text value={DELETE} className="text-red-600" />,
                onClick: () =>
                  open({
                    title: <Text value={DELETE_ZONES_TITLE(length)} />,
                    subTitle: <Text value={DELETE_ZONES_MSG} />,
                    body: (
                      <div className="px-4">
                        <DeleteBulkZonesModal
                          bulkZones={bulkZones}
                          setBulkZones={setBulkZones}
                          setZones={setZones}
                          close={close}
                        />
                      </div>
                    ),
                    size: 'max-w-lg',
                  }),
              },
            ]}
          />
        </div>
      )}
    </Modal>
  );
};

BulkEdit.propTypes = {
  bulkZones: PropTypes.arrayOf(
    PropTypes.shape({
      properties: PropTypes.shape({
        id: PropTypes.number,
      }),
    }),
  ),
  setZones: PropTypes.func.isRequired,
  branches: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ).isRequired,
  tableViewController: PropTypes.shape({
    selectedBranch: PropTypes.number,
  }),
  setBulkZones: PropTypes.func.isRequired,
};

export default BulkEdit;
