import React, { useEffect, useState, createContext, useContext } from "react"
import ProductsList from "./ProductsList"
import { Text } from "components/service"
import { ReactComponent as ShevronUpIcon } from "assets/icons-chevron-up.svg"
import { ReactComponent as ShevronDownIcon } from "assets/icons-chevron-down.svg"
import { ReactComponent as BlueShevronDownIcon } from "assets/icons-chevron-blue-down.svg"
import { Button, DragHandle, LoadingContainer } from "components/kit"
import { useMobile } from "hooks"
import CategoryForm from "./CategoryForm"
import * as translations from "constants/translations"
import {
  arrayMove,
  SortableContainer,
  SortableElement,
} from "react-sortable-hoc"
import { useQuery, useMutation } from "@apollo/client"
import * as schemas from "./schemas"
import { getCookie } from "utils"
import cx from "classnames"
import { context as userContext } from "context/user"
export const context = createContext()

const CategoryList = SortableContainer(
  ({
    categories,
    direction,
    lang,
    langSelected,
    setSidePanelComponent,
    branches,
    notifications,
    handleOnItemClick,
    storeId,
    refetchCat,
    open,
    close,
    reset,
    menuPageMap,
    setMenuPageMap,
    collapseItems,
    setProductCategory,
    handleConcatenatingCategories,
    handleDeletingCategories,
    handleUpdatingCategories,
    refetchMenuPageData,
    productCategory,
    currency,
    sidePanelComponent,
  }) => {
    return (
      <div>
        <context.Provider value={{ selectedProductId: sidePanelComponent.id }}>
          {categories &&
            categories.map((category, i) => {
              return (
                <Category
                  langSelected={langSelected}
                  direction={direction}
                  category={category}
                  key={`category-${category.id}-nameEn${category.titleEn}-nameAr${category.titleAr}-ofIndex${i}`}
                  index={i}
                  lang={lang}
                  idx={i}
                  menuPageMap={menuPageMap}
                  storeId={storeId}
                  setSidePanelComponent={setSidePanelComponent}
                  branches={branches}
                  notifications={notifications}
                  handleOnItemClick={handleOnItemClick}
                  open={open}
                  close={close}
                  refetchCat={refetchCat}
                  reset={reset}
                  setMenuPageMap={setMenuPageMap}
                  collapseItems={collapseItems}
                  setProductCategory={setProductCategory}
                  handleConcatenatingCategories={handleConcatenatingCategories}
                  handleDeletingCategories={handleDeletingCategories}
                  handleUpdatingCategories={handleUpdatingCategories}
                  refetchMenuPageData={refetchMenuPageData}
                  productCategory={productCategory}
                  currency={currency}
                  sidePanelComponent={sidePanelComponent}
                />
              )
            })}
        </context.Provider>
      </div>
    )
  }
)

const Category = SortableElement(
  ({
    open,
    close,
    direction,
    lang,
    setSidePanelComponent,
    branches,
    notifications,
    handleOnItemClick,
    category,
    key,
    storeId,
    refetchCat,
    menuPageMap,
    setMenuPageMap,
    collapseItems,
    setProductCategory,
    refetchMenuPageData,
    productCategory,
    handleConcatenatingCategories,
    handleDeletingCategories,
    handleUpdatingCategories,
    langSelected,
    currency,
    sidePanelComponent,
  }) => {
    const [isSortingProducts, setSortingProducts] = useState(false)
    const { user } = useContext(userContext)
    const isMobile = useMobile()
    const [collapse, setCollapse] = useState(false)
    const [selectedProducts, setSelectedProducts] = useState([])
    const [shouldSkipMenueFetch, setShouldSkipMenueFetch] = useState(true)
    const OFFSET = "15"
    const LIMIT = category?.totalMenuItemsCount - selectedProducts?.length + ""
    const stillHasMoreItems =
      category?.totalMenuItemsCount > selectedProducts?.length

    useEffect(() => {
      setSelectedProducts(...[menuPageMap.get(category.id)])
    }, [menuPageMap])

    useEffect(() => {
      setCollapse(collapseItems)
    }, [collapseItems])
    const { loading: loadingMenuItems } = useQuery(
      schemas.FETCH_ALL_MENU,
      {
        variables: {
          restaurantId: storeId,
          menuSectionId: category.id,
          limit: LIMIT,
          offset: OFFSET,
        },
        skip: shouldSkipMenueFetch,
        onCompleted: data => {
          const fetchedMenuItems = data.fetchAllMenuItems?.fetchAllMenuItems
          const allCategoryProducts = selectedProducts.concat(fetchedMenuItems)
          setSelectedProducts(allCategoryProducts)
          setMenuPageMap(menuPageMap.set(category.id, allCategoryProducts))
          setShouldSkipMenueFetch(true)
        },
        fetchPolicy: "network-only",
      }
    )

    const [updateProductsPosition] = useMutation(
      schemas.SORT_MENU_PAGE_PRODUCTS,
      {
        variables: {
          restaurantId: storeId,
          menuSectionId: category.id,
        },
      }
    )

    const handleSortingProducts = async sortedProducts => {
      if (sortedProducts) {
        setSelectedProducts(sortedProducts)
        setMenuPageMap(menuPageMap.set(category.id, sortedProducts))
      }
      const positions = sortedProducts.map((product, i) => ({
        id: product.id,
        position: i + 1,
      }))
      await updateProductsPosition({
        variables: { storeId, positions },
      })
    }

    return (
      <div className={cx("pb-4")} key={key}>
        <div
          className={cx(
            "w-full border-gray-300 justify-between",
            collapse && "border-b"
          )}
        >
          <div className="flex align-center items-center ">
            <div className="text-lg font-semibold py-2 flex items-center">
              <DragHandle isMenu />
              {langSelected === "en" ? category.titleEn : category.titleAr}
            </div>

            <div
              className={cx(
                "text-base font-semibold text-gray-600 hover:text-primary-base m-px mb-0",
                lang == "ar" ? "pr-4" : "pl-4"
              )}
            >
              <button
                onClick={e => {
                  e.preventDefault()
                  if (process.env.REACT_APP_SEGMENT_WRITE_KEY) {
                    if (getCookie("ajs_user_id") !== "") {
                        window.analytics.track(`Edit Category Button Clicked`, {
                          userId:user.id,
                          categoryId: category.id,
                      })
                    }
                  }
                  setSidePanelComponent({ component: 1, id: category.id })
                  if (isMobile) {
                    open({
                      testId: "edit-category-modal",
                      title: (
                        <Text
                          value={translations.EDIT_CATEGORY}
                          className="text-lg"
                        />
                      ),
                      size: "w-full",
                      body: (
                        <CategoryForm
                          id={category.id}
                          onCancel={close}
                          refetchMenuPageData={refetchCat}
                          close={close}
                          open={open}
                          handleConcatenatingCategories={
                            handleConcatenatingCategories
                          }
                          handleDeletingCategories={handleDeletingCategories}
                          handleUpdatingCategories={handleUpdatingCategories}
                        />
                      ),
                    })
                  }
                }}
              >
                <Text
                  className="text-sm font-semibold"
                  value={translations.EDIT}
                />
              </button>
            </div>
            <div
              className={cx(
                lang == "ar" ? "mr-auto" : "ml-auto",
                "flex flex-row"
              )}
            >
              <div className="flex-row m-px mb-0 px-2">
                <Text
                  className="text-gray-600 text-sm"
                  value={translations.ITEMS_COUNT}
                  payload={category.totalMenuItemsCount}
                />
              </div>

              <Button
                isMenu
                onClick={e => {
                  e.preventDefault()
                  setCollapse(!collapse)
                }}
              >
                {collapse ? <ShevronDownIcon /> : <ShevronUpIcon />}
              </Button>
            </div>
          </div>
        </div>
        {!collapse && (
          <LoadingContainer isLoading={isSortingProducts}>
            <ProductsList
              products={selectedProducts}
              lang={lang}
              langSelected={langSelected}
              open={open}
              close={close}
              storeId={storeId}
              direction={direction}
              branches={branches}
              setSidePanelComponent={setSidePanelComponent}
              notifications={notifications}
              categoryID={category.id}
              handleOnItemClick={handleOnItemClick}
              refetchMenuPageData={refetchMenuPageData}
              productCategory={productCategory}
              sidePanelComponent={sidePanelComponent}
              setProductCategory={setProductCategory}
              currency={currency}
              useDragHandle
              onSortEnd={async ({ oldIndex, newIndex }) => {
                setSortingProducts(true)
                const sortedProducts = arrayMove(
                  selectedProducts,
                  oldIndex,
                  newIndex
                )
                await handleSortingProducts(sortedProducts)
                setSortingProducts(false)
              }}
              showMore={
                stillHasMoreItems && (
                  <Button
                    isChevron
                    isSpinning={loadingMenuItems}
                    icon={<BlueShevronDownIcon />}
                    kind="transparent"
                    className="text-primary-base text-xs px-4"
                    onClick={e => {
                      e.stopPropagation()
                      setShouldSkipMenueFetch(false)
                    }}
                  >
                    <Text value={translations.SHOW_ALL} />
                  </Button>
                )
              }
            />
          </LoadingContainer>
        )}
      </div>
    )
  }
)

export default CategoryList
