import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Polygon } from '@react-google-maps/api';
import { primaryColorUtils } from '../../utils';

const SelectedZonePolygon = ({ item, zoneController, setZoneController, handleViewPort }) => {
  const [polygon, setPolygon] = useState(null);
  const handlePolygonPaths = shape => {
    const coordinates = [[]];
    const nextPath = shape
      .getPath()
      .getArray()
      .map(latLng => {
        coordinates[0].push([latLng.lng(), latLng.lat()]);
        return { lat: latLng.lat(), lng: latLng.lng() };
      });
    setZoneController({
      ...zoneController,
      selectedTemplateData: {
        coordinates,
        paths: nextPath,
      },
    });
  };

  return (
    <>
      <Polygon
        onLoad={ref => {
          setPolygon(ref);
          handlePolygonPaths(ref);
          handleViewPort(item.paths);
        }}
        paths={item.paths}
        onMouseOut={() => polygon && handlePolygonPaths(polygon)}
        editable
        options={{
          strokeColor: primaryColorUtils,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: primaryColorUtils,
          fillOpacity: 0.4,
          zIndex: 1,
        }}
      />
    </>
  );
};

SelectedZonePolygon.propTypes = {
  item: PropTypes.shape({
    paths: PropTypes.arrayOf(
      PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number,
      }),
    ),
  }).isRequired,
  zoneController: PropTypes.shape({
    isEdit: PropTypes.bool,
  }).isRequired,
  setZoneController: PropTypes.func.isRequired,
  handleViewPort: PropTypes.func.isRequired,
};

export default SelectedZonePolygon;
