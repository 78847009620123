import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';

import { Field, Text } from 'components/service';
import { Button } from 'components/kit';
import { Input } from 'components/form/elements';
import { context as localContext } from 'context/locale';
import * as translations from 'constants/translations';
import SlideoverTitle from 'components/common/dashboard/Layout/Slideover/SlideoverTitle';
import { Label, Row } from 'components/form/generic';
import { initialValues, validationSchema, CUSTOMER_ADDRESS_FIELDS } from './data';
import PlaceSelection from './PlaceSelection';
import { SLIDE_OVER_COMPONENTS_ENUMS } from '../utils';

const CustomerAddress = ({ setSlideOverComponent }) => {
  const { translate } = useContext(localContext);
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => {
        setSlideOverComponent({ component: SLIDE_OVER_COMPONENTS_ENUMS.SF_WEBVIEW });
      }}
      validationSchema={validationSchema}
    >
      {({ values, setFieldValue }) => (
        <>
          <SlideoverTitle title={<Text value={translations.NEW_ADDRESS} />} />
          <Form key="manual-order-user-address-form" className="p-6">
            <PlaceSelection setFieldValue={setFieldValue} selectedPlace={values.selectedPlace} />
            <div>
              <Row className="pt-6">
                <Label title={<Text value={translations.AREA} />}>
                  <Field
                    type="text"
                    name={CUSTOMER_ADDRESS_FIELDS.AREA}
                    placeholder={translate(translations.AREA_PLACEHOLDER)}
                    component={Input}
                    data-testid="customer-address-area"
                  />
                </Label>
              </Row>
              <Row className="pt-4">
                <Label title={<Text value={translations.STREET} />}>
                  <Field
                    type="text"
                    name={CUSTOMER_ADDRESS_FIELDS.STREET}
                    placeholder={translate(translations.STREET_PLACEHOLDER)}
                    component={Input}
                    data-testid="customer-address-street"
                  />
                </Label>
              </Row>
              <Row className="pt-4">
                <Label title={<Text value={translations.BLOCK} />}>
                  <Field
                    type="text"
                    name={CUSTOMER_ADDRESS_FIELDS.BLOCK}
                    placeholder={translate(translations.BLOCK_PLACEHOLDER)}
                    component={Input}
                    data-testid="customer-address-block"
                  />
                </Label>
              </Row>
              <Row className="pt-4">
                <Label title={<Text value={translations.AVENUE_OPTIONAL} />}>
                  <Field
                    type="text"
                    name={CUSTOMER_ADDRESS_FIELDS.AVENUE}
                    placeholder={translate(translations.AVENUE_PLACEHOLDER)}
                    component={Input}
                    data-testid="customer-address-avenue"
                  />
                </Label>
              </Row>
              <Row className="pt-4">
                <Label title={<Text value={translations.BUILDING} />}>
                  <Field
                    type="text"
                    name={CUSTOMER_ADDRESS_FIELDS.BUILDING}
                    placeholder={translate(translations.BUILDING_PLACEHOLDER)}
                    component={Input}
                    data-testid="customer-address-building"
                  />
                </Label>
              </Row>
              <Row className="pt-4">
                <Label title={<Text value={translations.FLOOR} />}>
                  <Field
                    type="text"
                    name={CUSTOMER_ADDRESS_FIELDS.FLOOR}
                    placeholder={translate(translations.FLOOR_PLACEHOLDER)}
                    component={Input}
                    data-testid="customer-address-floor"
                  />
                </Label>
              </Row>
              <Row className="pt-4">
                <Label title={<Text value={translations.SPECIAL_DIRECTIONS_OPTIONAL} />}>
                  <Field
                    type="text"
                    name={CUSTOMER_ADDRESS_FIELDS.SPECIAL_DIRECTION}
                    placeholder={translate(translations.SPECIAL_DIRECTIONS_PLACEHOLDER)}
                    component={Input}
                    data-testid="customer-address-specialDirections"
                  />
                </Label>
              </Row>
            </div>
            <div className="py-8">
              <Button kind="primary" full isRounded>
                <Text value={translations.SAVE_AND_PROCEED} />
              </Button>
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
};

CustomerAddress.propTypes = {
  setSlideOverComponent: PropTypes.func.isRequired,
  selectedStorefrontUser: PropTypes.shape({
    phoneNumber: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
  }),
  createdStorefrontUser: PropTypes.shape({
    id: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    addresses: PropTypes.arrayOf(
      PropTypes.shape({
        block: PropTypes.string.isRequired,
        street: PropTypes.string.isRequired,
        avenue: PropTypes.string,
        building: PropTypes.string.isRequired,
        floor: PropTypes.string.isRequired,
        unitType: PropTypes.string.isRequired,
        notes: PropTypes.string.isRequired,
        area: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            titleEn: PropTypes.string.isRequired,
            titleAr: PropTypes.string.isRequired,
            cityId: PropTypes.string.isRequired,
            lat: PropTypes.string.isRequired,
            lng: PropTypes.string.isRequired,
            createdAt: PropTypes.string.isRequired,
            countryId: PropTypes.string.isRequired,
            cityTitleEn: PropTypes.string.isRequired,
            cityTitleAr: PropTypes.string.isRequired,
          }),
        ).isRequired,
      }),
    ),
  }),
};

export default CustomerAddress;
