import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { ReactComponent as CloseIcon } from 'assets/close.svg';
import { Button } from 'components/kit';
import { Text } from 'components/service';

const TrackingIdentifiersSlideoverTemplate = ({
  imageSrc = '',
  imageAlt = '',
  title,
  subtitle,
  input,
  inputSubtitle,
  action,
  setShowSlideover,
  direction,
}) => (
  <div className="px-6">
    <div className={cx('flex flex-row mt-6', direction === 'rtl' && 'justify-between')}>
      <Button
        size="xs"
        kind="transparent"
        onClick={() => {
          setShowSlideover(false);
        }}
        icon={<CloseIcon fill="black" className="self-center text-base text-black" />}
      />
      <div className={cx('flex', direction === 'ltr' ? 'flex-row' : 'flex-row-reverse')}>
        {imageSrc && (
          <img src={imageSrc} alt={imageAlt} className={cx('w-12', direction === 'ltr' ? 'ml-5 mr-3' : 'ml-3')} />
        )}
        <Text className="self-center flex" value={title} />
      </div>
    </div>
    <hr className="self-center px-2 my-4 bg-gray-400" />
    <div className="flex flex-col justify-start">
      <Text className="text-xs font-normal mb-2" value={subtitle} />
      {input}
      <Text className="text-xs font-normal opacity-30 mt-2 mb-6" value={inputSubtitle} />
      {action}
    </div>
  </div>
);

TrackingIdentifiersSlideoverTemplate.propTypes = {
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string,
  title: PropTypes.arrayOf(PropTypes.string).isRequired,
  subtitle: PropTypes.arrayOf(PropTypes.string).isRequired,
  input: PropTypes.element.isRequired,
  inputSubtitle: PropTypes.arrayOf(PropTypes.string).isRequired,
  action: PropTypes.element.isRequired,
  setShowSlideover: PropTypes.func.isRequired,
  direction: PropTypes.oneOf(['rtl', 'ltr']).isRequired,
};

export default TrackingIdentifiersSlideoverTemplate;
