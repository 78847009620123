import { CATEGORIES } from "../schemas"

export const handleErrors = callback => err => {
  const body = err.graphQLErrors[0].extensions.exception.body
  for (let key of Object.keys(body)) {
    callback(`${map[key]} ${body[key][0]}`)
  }
}

const map = {
  title_en: "English title",
  title_ar: "Arabic title",
}
