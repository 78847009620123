import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import * as translations from 'constants/translations';
import { context as localeContext } from 'context/locale';
import { Text } from 'components/service';
import { CheckboxAlt } from 'components/form/elements';
import { Modal } from 'components/kit';
import TableRow from './TableRow';

const Table = ({ zones, currency, setTableViewController, setZones, branches, bulkZones, setBulkZones }) => {
  const { direction } = useContext(localeContext);
  const [isAllChecked, setIsAllChecked] = useState(false);

  useEffect(() => {
    if (zones.length >= 1) {
      if (bulkZones.length === zones.length) {
        setIsAllChecked(true);
      } else {
        setIsAllChecked(false);
      }
    }
  }, [bulkZones, zones]);

  const headCellClassName = 'text-justify font-semibold pb-3 pt-3 px-5 whitespace-nowrap';
  return (
    <Modal isDzmsTableView top>
      {({ open, close }) => (
        <table
          style={{ direction }}
          className="w-full table-auto border-collapse border overflow-auto md:overflow-visible"
        >
          <thead>
            <tr className="bg-gray-200">
              {zones && zones.length >= 1 && (
                <th className={headCellClassName}>
                  <CheckboxAlt
                    onChange={e => {
                      const { value } = e.target;
                      setIsAllChecked(value);
                      if (value) {
                        setBulkZones(zones);
                      } else {
                        setBulkZones([]);
                      }
                    }}
                    value={isAllChecked}
                  />
                </th>
              )}
              <th className={headCellClassName}>
                <Text value={translations.ZONE_NAME} className="whitespace-nowrap" />
              </th>
              <th className={headCellClassName}>
                <Text value={translations.DELIVERY_FEES} className="whitespace-nowrap" />
              </th>
              <th className={headCellClassName}>
                <Text value={translations.MINIMUM_ORDER} className="whitespace-nowrap" />
              </th>
              <th className={headCellClassName}>
                <Text value={translations.STATUS} className="whitespace-nowrap" />
              </th>
              <th className={headCellClassName}>
                <Text value={translations.DELIVERY_TIME} className="whitespace-nowrap" />
              </th>
              <th className={headCellClassName}>
                <Text value={translations.DELIVERY_HOURS} className="whitespace-nowrap" />
              </th>
            </tr>
          </thead>
          <tbody>
            {zones &&
              (zones.length === 0 ? (
                <tr>
                  <td colSpan="6" className="py-3 px-5 text-xs border-b border-gray-300 whitespace-nowrap">
                    <Text value={translations.AREA_NOT_MATCHING} />
                  </td>
                </tr>
              ) : (
                zones.map(item => (
                  <TableRow
                    key={item.properties.id}
                    item={item}
                    currency={currency}
                    open={open}
                    close={close}
                    setTableViewController={setTableViewController}
                    setZones={setZones}
                    branches={branches}
                    bulkZones={bulkZones}
                    setBulkZones={setBulkZones}
                    setIsAllChecked={setIsAllChecked}
                  />
                ))
              ))}
          </tbody>
        </table>
      )}
    </Modal>
  );
};

Table.propTypes = {
  zones: PropTypes.arrayOf(
    PropTypes.shape({
      properties: PropTypes.shape({
        id: PropTypes.number,
      }),
    }),
  ),
  currency: PropTypes.shape({
    titleEn: PropTypes.string,
  }).isRequired,
  setTableViewController: PropTypes.func.isRequired,
  setZones: PropTypes.func.isRequired,
  branches: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ).isRequired,
  bulkZones: PropTypes.arrayOf(
    PropTypes.shape({
      properties: PropTypes.shape({
        id: PropTypes.number,
      }),
    }),
  ),
  setBulkZones: PropTypes.func.isRequired,
};

export default Table;
