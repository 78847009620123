import React from 'react';
import PropTypes from 'prop-types';

import { Button, Stack } from 'components/kit';
import * as translations from 'constants/translations';
import { Text } from 'components/service';

const DeleteWebLink = ({ item, onDelete, onClose }) => (
  <div className="px-4">
    <div className="mb-6 flex row flex-wrap">
      <Text className="inline-flex w-auto whitespace-pre-wrap" value={translations.YOU_ARE_ABOUT_TO_DELETE_LINK} />
      <Text className="inline-flex w-auto text-black font-semibold" value={item} />
    </div>
    <Stack direction="row-reverse" expandItem={false} spacing={2}>
      <Button onClick={onDelete} type="button" kind="secondaryError">
        <Text value={translations.DELETE} />
      </Button>
      <Button onClick={onClose} type="button" kind="tertiary">
        <Text value={translations.DISMISS} />
      </Button>
    </Stack>
  </div>
);

DeleteWebLink.propTypes = {
  item: PropTypes.shape({
    titleEn: PropTypes.string.isRequired,
    titleAr: PropTypes.string.isRequired,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DeleteWebLink;
