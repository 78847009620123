import React, { useState, useEffect, useContext, useRef } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import cx from 'classnames';
import { arrayMove } from 'react-sortable-hoc';

import { Text } from 'components/service';
import { Stack, Button, Modal, More, Spinner } from 'components/kit';
import { Layout, Breadcrumbs } from 'components/common/dashboard';
import * as translations from 'constants/translations';
import { exportCatalog } from 'rest.js';
import { context as userContext } from 'context/user';
import { context as localeContext } from 'context/locale';
import { context as notificationsContext } from 'context/notifications';
import { useSelectedStore, useMobile, useQueryState } from 'hooks';
import CategoryForm from './CategoryForm';
import * as schemas from './schemas';
import ImportCatalog from './ImportCatalog';
import EmptyState from './EmptyState';
import SidePanel from './SidePanel';
import MenuPageFrame from './MenuPageFrame';
import CategoryList from './CategoryList';
import ImportFromFoodicsModal from './ImportFromFoodicsModal';
import ImportFromFoodicsNoBranchesModal from './ImportFromFoodicsNoBranchesModal';
import { ReactComponent as Add } from 'assets/add.svg';
import { MENU_OVERVIEW_LINKS } from 'constants/helperLinks';

const MenuOverviewPage = () => {
  const searchTimeout = useRef();
  const ALL = 'all';
  const { lang, direction, translate } = useContext(localeContext);
  const notifications = useContext(notificationsContext);
  const isMobile = useMobile();
  const { branches, isPosCourier, selectedStore, pos, settings } = useContext(userContext);
  const posName = lang === 'en' ? pos?.courierDetails?.name : pos?.courierDetails?.nameAr;

  const productsLang = [
    { id: 'en', title: 'English' },
    { id: 'ar', title: 'Arabic' },
  ];
  const storeId = useSelectedStore();
  const [langSelected, setLangSelected] = useState('en');
  const [sidePanelComponent, setSidePanelComponent] = useState({
    component: null,
    id: null,
  });
  const LIMIT = 10;
  const [menuItems, setMenuItems] = useState([]);
  const [collapseItems, setCollapseItems] = useState(false);
  const [menuPageMap, setMenuPageMap] = useState(new Map());
  const [productCategory, setProductCategory] = useState(null);
  const [topPanel, setTopPanel] = useState(false);
  const isSingleBranch = branches && branches.length === 1;
  const [query, setQuery] = useQueryState({
    location: isSingleBranch ? branches[0].id : ALL,
    viewIn: 'en',
  });

  const { data: menuPageData, loading: loadingMenuPageData, refetch: refetchMenuPageData } = useQuery(
    schemas.MENU_PAGE,
    {
      variables: {
        storeId,
        ...(query.filter && {
          filter: query.filter,
        }),
      },
      fetchPolicy: 'network-only',
    },
  );

  const [updateCategoriesPosition] = useMutation(schemas.SORT_MENU_PAGE_CATEGORIES, {
    variables: {
      restaurantId: storeId,
    },
  });

  useEffect(() => {
    const menuPageResult =
      !loadingMenuPageData &&
      menuPageData &&
      menuPageData.menuPages &&
      menuPageData.menuPages.menuPages.map(category => {
        menuPageMap.set(category.id, category.menuItems);
        return {
          id: category.id,
          titleEn: category.titleEn,
          titleAr: category.titleAr,
          position: category.position,
          totalMenuItemsCount: category.totalMenuItemsCount,
        };
      });
    setMenuItems(menuPageResult);
    setMenuPageMap(new Map(menuPageMap));
  }, [menuPageData]);

  useEffect(() => {
    if (query?.filter) {
      setCollapseItems(false);
    }
  }, [query]);

  const handleResetSidePanel = () => {
    setSidePanelComponent({ component: null, id: null });
  };

  const totalNumberOfItems =
    menuItems.length && menuItems.reduce((accumulator, element) => accumulator + element.totalMenuItemsCount, 0);

  const handleConcatenatingCategories = (newItemMap, newCategory) => {
    const concatenated = new Map([...newItemMap].concat([...menuPageMap]));
    const items = newCategory.concat(menuItems);
    setMenuPageMap(concatenated);
    setMenuItems(items);
  };

  const handleUpdatingCategories = updatedCategory => {
    const changedItemIndex = menuItems.findIndex(item => item.id === updatedCategory.id);
    menuItems[changedItemIndex] = updatedCategory;
  };

  const handleDeletingCategories = deletedCategoryId => {
    const filteredCategoryItems = menuItems.filter(item => item.id !== deletedCategoryId);
    setMenuItems(filteredCategoryItems);
  };

  const branchesData =
    branches &&
    [
      ...branches,
      !isSingleBranch && {
        id: ALL,
        titleEn: translations.ALL[0],
        titleAr: translations.ALL[1],
      },
    ].map(branch => ({
      id: branch.id,
      title: <Text value={branch} />,
    }));

  const AddCategoryButton = ({ open, close }) => (
    <Button
      data-testid="list-products-add-category-btn"
      isMenu
      isCategory
      icon={<Add />}
      onClick={() => {
        if (branches === null) {
          open({
            testId: 'list-products-sort-catalog-import-foodics-no-branches-modal',
            body: <ImportFromFoodicsNoBranchesModal onCancel={close} />,
          });
        } else {
          setSidePanelComponent({ component: 1, id: null });
          if (isMobile) {
            open({
              testId: 'list-products-add-category',
              title: <Text value={translations.ADD_CATEGORY} className="text-lg" />,
              size: 'w-full',
              body: (
                <CategoryForm
                  onCancel={close}
                  refetchMenuPageData={refetchMenuPageData}
                  handleConcatenatingCategories={handleConcatenatingCategories}
                  handleDeletingCategories={handleDeletingCategories}
                  handleUpdatingCategories={handleUpdatingCategories}
                />
              ),
            });
          }
        }
      }}
      size="md"
      kind="transparent"
      className="W-40 pl-0"
      iconClassNames={lang === 'ar' ? '-mr-1 ml-2' : '-ml-1 mr-2'}
      style={{
        direction,
      }}
    >
      <Text value={translations.ADD_CATEGORY} className="font-medium text-base" />
    </Button>
  );

  const MoreButtons = ({ open, close }) => (
    <More
      testIdBtn="list-products-more-btn"
      iconSize="2xl"
      size="xs"
      iconColor="text-zyda-black"
      items={[
        {
          testId: 'list-products-import-catalog-btn',
          title: <Text value={translations.IMPORT_CATALOG} />,
          onClick: () => {
            if (branches === null) {
              notifications.show(<Text value={translations.YOU_HAVE_ADD_BUSINESS_LOCATION_FIRST} />, 'info');
            } else {
              open({
                testId: 'list-products-import-catalog-modal',
                title: <Text value={translations.IMPORT_CATALOG} />,
                body: <ImportCatalog onCancel={close} refetchMenuPageData={refetchMenuPageData} />,
              });
            }
          },
        },
        {
          testId: 'export-catalog-btn',
          title: <Text value={translations.EXPORT_CATALOG} />,
          onClick: () => {
            exportCatalog(storeId).then(() => {
              notifications.show(<Text value={translations.EXPORT_CATALOG_MSG} />, 'success');
            });
          },
        },
        isPosCourier && pos?.isMenuPull && !settings?.isImportingPosMenu && {
            testId: 'list-products-import-catalog-from-foodics-btn',
            title: <Text value={translations.IMPORT_CATALOG_FROM_POS(posName)} />,
            onClick: () => {
              if (branches === null) {
                open({
                  testId: 'list-products-sort-catalog-import-foodics-no-branches-modal',
                  body: <ImportFromFoodicsNoBranchesModal onCancel={close} />,
                });
              } else {
                open({
                  testId: 'list-products-sort-catalog-import-foodics-modal',
                  title: (
                    <div>
                      <Text value={translations.IMPORT_FOODICS_MENU} />
                      <Text
                        className="text-gray-600 text-sm font-normal pt-1 pb-2"
                        value={translations.IMPORT_FOODICS_MENU_DESCRIPTION}
                      />
                    </div>
                  ),
                  body: <ImportFromFoodicsModal onCancel={close} />,
                });
              }
            },
          },
      ].filter(item => item)}
    />
  );

  const handleOnItemClick = id => {
    setSidePanelComponent({ component: 2, id });
  };

  const handleOnCollapse = () => {
    setCollapseItems(!collapseItems);
  };

  const handleOnSortingCategories = async sortedCategories => {
    sortedCategories && setMenuItems(sortedCategories);
    const positions = sortedCategories.map((category, i) => ({
      id: category.id,
      position: i + 1,
    }));
    await updateCategoriesPosition({ variables: { storeId, positions } });
  };

  return (
    <Modal isMenu>
      {({ open, close }) => (
        <Layout setTopPanel={setTopPanel}>
          <div className={cx('grid grid-cols-12', lang == 'ar' ? 'xl:pl-0' : 'xl:pr-0')} style={{ direction }}>
            <div
              className={cx(
                'col-span-12 lg:col-span-6 xl:col-span-8 border-gray-300',
                lang === 'en' ? 'md:border-r xl:pr-6 md:pr-4 sm:pr-0' : 'md:border-l xl:pl-6 md:pl-4 sm:pl-0',
              )}
            >
              <div className="w-full md:w-auto my-2 xl:mb-0 sticky z-10 top-0">
                <Breadcrumbs
                  isOverview
                  helperLinks={MENU_OVERVIEW_LINKS}
                  className={cx('flex items-center justify-between h-10', lang === 'ar' && 'flex-row-reverse')}
                  customTitle={<Text value={translations.MENU} className="text-2xl font-bold" />}
                  right={
                    <div style={{ direction }}>
                      <Stack expandItem={false} direction="wrap py-2">
                        <MoreButtons open={open} close={close} />
                      </Stack>
                    </div>
                  }
                />
              </div>

              <MenuPageFrame
                setQuery={setQuery}
                query={query}
                totalNumberOfItems={totalNumberOfItems}
                totalNumberOfCategories={menuItems.length}
                translate={translate}
                lang={lang}
                branchesData={branchesData}
                langSelected={langSelected}
                setLangSelected={setLangSelected}
                productsLang={productsLang}
                handleOnCollapse={handleOnCollapse}
                collapseItems={collapseItems}
                searchTimeout={searchTimeout}
                AddCategoryButton={AddCategoryButton}
                open={open}
                close={close}
                isSingleBranch={isSingleBranch}
              />
              {loadingMenuPageData ? (
                <Spinner />
              ) : (menuPageData && menuPageData.menuPages && !menuPageData.menuPages.menuPages.length) ||
                branches === null ? (
                <div className="flex justify-center m-3">
                  <EmptyState
                    refetchMenuPageData={refetchMenuPageData}
                    handleConcatenatingCategories={handleConcatenatingCategories}
                    handleDeletingCategories={handleDeletingCategories}
                    handleUpdatingCategories={handleUpdatingCategories}
                  />
                </div>
              ) : (
                <CategoryList
                  testId="category-list"
                  lang={lang}
                  direction={direction}
                  categories={menuItems}
                  menuPageMap={menuPageMap}
                  open={open}
                  currency={selectedStore.currency}
                  close={close}
                  storeId={storeId}
                  setSidePanelComponent={setSidePanelComponent}
                  setQuery={setQuery}
                  branches={branches}
                  notifications={notifications}
                  handleOnItemClick={handleOnItemClick}
                  langSelected={langSelected}
                  setLangSelected={setLangSelected}
                  LIMIT={LIMIT}
                  sidePanelComponent={sidePanelComponent}
                  reset={handleResetSidePanel}
                  setMenuPageMap={setMenuPageMap}
                  collapseItems={collapseItems}
                  setProductCategory={setProductCategory}
                  refetchMenuPageData={refetchMenuPageData}
                  productCategory={productCategory}
                  handleConcatenatingCategories={handleConcatenatingCategories}
                  handleDeletingCategories={handleDeletingCategories}
                  handleUpdatingCategories={handleUpdatingCategories}
                  useDragHandle
                  onSortEnd={async ({ oldIndex, newIndex }) => {
                    const sortedCategories = arrayMove(menuItems, oldIndex, newIndex);
                    await handleOnSortingCategories(sortedCategories);
                  }}
                />
              )}
            </div>
            
            <div className={cx('hidden lg:block col-span-12 lg:col-span-6 xl:col-span-4 h-screen')}>
              <SidePanel
                topPanel={topPanel}
                storeId={storeId}
                sidePanelComponent={sidePanelComponent}
                setQuery={setQuery}
                refetchMenuPageData={refetchMenuPageData}
                open={open}
                close={close}
                id={sidePanelComponent.id}
                reset={handleResetSidePanel}
                productCategory={productCategory}
                handleConcatenatingCategories={handleConcatenatingCategories}
                handleUpdatingCategories={handleUpdatingCategories}
                handleDeletingCategories={handleDeletingCategories}
                menuPageMap={menuPageMap}
                setSidePanelComponent={setSidePanelComponent}
              />
            </div>
          </div>
        </Layout>
      )}
    </Modal>
  );
};
export default MenuOverviewPage;
