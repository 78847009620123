import React from "react"
import { Text } from "components/service"
import * as translations from "constants/translations"
import cx from "classnames"

export default ({ value, onChange }) => {
  const selectedClassName =
    "font-semibold text-primary-base bg-primary-50 border border-primary-base"
  const baseClassName = "px-3 py-1 cursor-pointer"

  return (
    <div className="flex text-gray-700">
      <Text
        value={translations.DAY}
        onClick={() => onChange("day")}
        className={cx(
          baseClassName,
          value === "day"
            ? selectedClassName
            : "border border-gray-300 border-r-0"
        )}
      />
      <Text
        value={translations.MONTH}
        onClick={() => onChange("month")}
        className={cx(
          baseClassName,
          value === "month"
            ? selectedClassName
            : "border border-gray-300 border-l-0"
        )}
      />
    </div>
  )
}
