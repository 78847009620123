export const transformUser = x => ({
  ...x,
  initials: x.name[0],
  role: x.roles[0]?.role,
  branches: x.roles[0].role === 'operator' ? x.roles.map(role => role.resourceId) : [],
  phoneNumber: x.phoneNumber?.substring(1),
});

export const roles = [
  {
    id: 'all',
    title: 'All',
  },
  {
    id: 'owner',
    title: 'Owner',
  },
  {
    id: 'ops_manager',
    title: 'Manager',
  },
  {
    id: 'operator',
    title: 'Operator',
  },
];
