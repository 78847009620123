import React, { useContext } from 'react';
import { navigate } from '@reach/router';
import cx from 'classnames';
import PropTypes from 'prop-types';

import * as translations from 'constants/translations';
import { Text } from 'components/service';
import { Box, Button } from 'components/kit';
import { context as localeContext } from 'context/locale';
import { useReplaceParams } from 'hooks/index';
import * as paths from 'paths.js';
import convertMultiLanguagesFromObjectToArray from '../../../integrations/utils';
import PaymentMethodsSymbols from './PaymentMethodsSymbols';
import PaymentStatusSign from '../PaymentStatusSign';
import ActionButton from '../ActionButton';

const OnlinePaymentGatewayCard = ({ gateway, paymentCredentials }) => {
  const { lang } = useContext(localeContext);
  const replace = useReplaceParams();
  const title = convertMultiLanguagesFromObjectToArray(gateway.extraData?.title);
  const description = convertMultiLanguagesFromObjectToArray(gateway.extraData?.description);

  const actionButton = gatewayId => {
    if (paymentCredentials) {
      if (paymentCredentials.paymentGateway?.id === gatewayId) {
        return (
          <div data-testid="payMethods-status-container">
            <PaymentStatusSign status={paymentCredentials.status} />
          </div>
        );
      }
      return null;
    }
    return (
      <ActionButton
        testId={`get-started-${gateway.name}`}
        onClick={() => navigate(`${replace(paths.paymentGateway)}&gatewayId=${gatewayId}`)}
      >
        <Text value={translations.GET_STARTED} />
      </ActionButton>
    );
  };

  return (
    <div className="w-full lg:w-1/3 p-3 pl-0">
      <Box
        title={<Text value={title} />}
        description={<Text className="mt-3" value={description} />}
        icon={<img src={gateway.logoUrl} alt={gateway.name} className={cx('w-6', lang === 'en' ? 'mr-2' : 'ml-2')} />}
        displayBorderBottom={false}
        action={actionButton(gateway.id)}
        body={
          <>
            {gateway?.paymentMethods?.length && <PaymentMethodsSymbols methods={gateway.paymentMethods} />}
            {paymentCredentials?.paymentGateway?.id && (
              <div className="px-4 mb-2 mt-4">
                <Button
                  size="base"
                  weight="medium"
                  full
                  onClick={() => navigate(`${replace(paths.paymentGateway)}&gatewayId=${gateway.id}`)}
                >
                  <Text value={translations.MORE_DETAILS} />
                </Button>
              </div>
            )}
          </>
        }
      />
    </div>
  );
};

OnlinePaymentGatewayCard.propTypes = {
  gateway: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    logoUrl: PropTypes.string.isRequired,
    extraData: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
    }).isRequired,
    paymentMethods: PropTypes.arrayOf(),
  }).isRequired,
  paymentCredentials: PropTypes.shape({
    paymentGateway: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
};

export default OnlinePaymentGatewayCard;
