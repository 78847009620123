import React, { useState, useContext } from "react"
import { navigate } from "@reach/router"
import moment from "moment"
import cx from "classnames"
import { useMutation } from "@apollo/client"

import { Text } from "components/service"
import { context as userContext } from 'context/user';
import { useReplaceParams } from "hooks"
import { context as notificationsContext } from "context/notifications"
import { context as localeContext } from "context/locale"
import { More, Modal } from "components/kit"
import * as translations from "constants/translations"
import DetailsVoucher from "../DetailsVoucher"
import DeleteVoucher from "../../DeleteVoucher"
import * as schemas from "./schemas"
import * as paths from "paths.js"
import { copyToClipboard } from "pages/setup/integrations/utils"
import { getStoreURl } from "components/common/dashboard/Layout/utils"

const VouchersTableRow = ({
  item,
  index,
  totalCount,
  vouchersVariables,
  storeId,
}) => {
  const { direction, lang, translate } = useContext(localeContext)
  const [voucherStatus, setVoucherStatus] = useState(item.status)
  const cellClassName = cx(
    "px-4 border-b border-gray-300 whitespace-nowrap last:w-full",
    totalCount <= 2 ? (totalCount - 1 > index ? "py-5" : "pt-5 pb-40") : "py-5"
  )

  const status = {
    Active: (
      <div className="flex items-center">
        <span className="w-2 h-2 rounded-full bg-zyda-yellow-300 mx-2" />
        {translate(translations.ACTIVE)}
      </div>
    ),
    Deactivated: (
      <div className="flex items-center">
        <span className="w-2 h-2 rounded-full bg-zyda-grey-500 mx-2" />
        {translate(translations.DEACTIVATED)}
      </div>
    ),
    Expired: (
      <div className="flex items-center">
        <span className="w-2 h-2 rounded-full bg-zyda-red-500 mx-2" />
        {translate(translations.EXPIRED)}
      </div>
    ),
    Scheduled: (
      <div className="flex items-center">
        <span className="w-2 h-2 rounded-full bg-primary-base mx-2" />
        {translate(translations.SCHEDULED_VOUCHER)}
      </div>
    ),
  }

  const type = {
    percentage: translate(translations.PERCENTAGE),
    amount: translate(translations.AMOUNT),
    free_delivery: translate(translations.FREE_DELIVERY),
  }

  return (
    <tr>
      <td className={cx(cellClassName)}>{type[item.voucherType]}</td>
      <td className={cx(cellClassName)}>{item.codeUsage}</td>
      <td className={cx(cellClassName)}>
        {moment(item.validFrom).format("DD MMMM YYYY")}
      </td>
      <td className={cx(cellClassName)}>
        {item.validTo
          ? moment(item.validTo).format("DD MMMM YYYY")
          : translate(translations.UNLIMITED)}
      </td>
      <td className={cx(cellClassName)}>{status[voucherStatus]}</td>
      <td className={cx(cellClassName)}>
        <ShowCased
          index={index}
          totalCount={totalCount}
          direction={direction}
          voucherId={item.id}
          vouchersVariables={vouchersVariables}
          storeId={storeId}
          item={item}
          setVoucherStatus={setVoucherStatus}
        />
      </td>
    </tr>
  )
}

export default VouchersTableRow

const ShowCased = ({
  direction,
  voucherId,
  item,
  vouchersVariables,
  storeId,
  setVoucherStatus,
  index,
  totalCount,
}) => {
  const notifications = useContext(notificationsContext)
  const [publish, setPublish] = useState(item.publish)
  const replace = useReplaceParams()
  const { selectedStore } = useContext(userContext);
  const storeUrl = getStoreURl(selectedStore);


  const [updateVoucher, { loading }] = useMutation(schemas.UPDATE_VOUCHER, {
    variables: {
      storeId,
      voucherId,
    },
    onError: err => {
      let body = err.graphQLErrors[0].extensions.exception.body
      let map = {
        title_ar: "Title Arabic",
        title_en: "Title English",
        valid_until: "Valid to",
      }
      for (let key of Object.keys(body)) {
        notifications.show(
          `${map[key] !== undefined ? map[key] : key} ${body[key][0]}`,
          "error"
        )
      }
    },
    onCompleted: data => {
      notifications.show(<Text value={translations.VOUCHER_UPDATED} />)
      setPublish(data.updateVoucher.isShowcased)
    },
  })

  return (
    <div className="flex items-center justify-between -my-2">
      <Publish
        value={publish}
        disabled={item.status === ("Expired" || "Deactivated")}
        onChange={value => {
          updateVoucher({ variables: { isShowcased: value } })
        }}
      />

      <div className={cx(direction === "ltr" ? "ml-2" : "mr-2")}>
        <Modal>
          {({ open, close }) => (
            <More
              modalUp={
                totalCount > 2 &&
                (totalCount - 1 === index || totalCount - 2 === index)
              }
              kind="secondary"
              size="smIcon"
              items={[
                {
                  title: <Text value={translations.VIEW_DETAILS} />,
                  onClick: () => {
                    open({
                      title: item.code,
                      body: (
                        <DetailsVoucher
                          item={item}
                          voucherId={voucherId}
                          onClose={close}
                        />
                      ),
                      size: "max-w-sm",
                    })
                  },
                },
                {
                  title: <Text value={translations.COPY_VOUCHER_CODE} />,
                  onClick: () => {
                    copyToClipboard(`${storeUrl}/?voucher=${item.code}`);
                    notifications.show(<Text value={translations.VOUCHER_CODE_COPIED} />, 'success');
                  },
                },
                {
                  title: <Text value={translations.EDIT} />,
                  onClick: () => {
                    navigate(
                      replace(paths.editVoucher, {
                        params: { voucherId },
                      })
                    )
                  },
                },
                {
                  title: item.active ? (
                    <Text value={translations.DEACTIVATE} />
                  ) : (
                    <Text value={translations.ACTIVATE} />
                  ),
                  onClick: () => {
                    updateVoucher({ variables: { isActive: !item.active } })
                    setVoucherStatus(item.active ? "Deactivated" : "Active")
                  },
                  disabled: item.status === "Expired",
                },
                {
                  title: <Text value={translations.DELETE} />,
                  onClick: () => {
                    open({
                      title: item.code,
                      body: (
                        <DeleteVoucher
                          voucherId={voucherId}
                          onClose={close}
                          vouchersVariables={vouchersVariables}
                        />
                      ),
                      size: "max-w-md",
                    })
                  },
                  disabled: item.voucherRedemptionsCount > 0,
                },
              ]}
            />
          )}
        </Modal>
      </div>
    </div>
  )
}

const Publish = ({ value, onChange, disabled }) => {
  const selectedClassName =
    "font-semibold text-primary-base bg-primary-50 border border-primary-base"
  const baseClassName = cx(
    "px-3 py-1",
    disabled ? "cursor-not-allowed" : "cursor-pointer"
  )

  return (
    <div className={cx("flex text-gray-700", disabled && "opacity-25")}>
      <Text
        value={translations.PUBLIC}
        onClick={() => !disabled && onChange(true)}
        className={cx(
          baseClassName,
          value ? selectedClassName : "border border-gray-300 border-r-0"
        )}
      />
      <Text
        value={translations.PRIVATE}
        onClick={() => !disabled && onChange(false)}
        className={cx(
          baseClassName,
          !value ? selectedClassName : "border border-gray-300 border-l-0"
        )}
      />
    </div>
  )
}
